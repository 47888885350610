import { useEffect, useMemo, useState } from "react";
import { COMMUNITY_CLUP, USER } from "../api";
import { useParams } from "react-router-dom";
import { differenceInDays, format } from "date-fns";
import Modal from "../components/modal";
import close from "../image/close.svg";
import SvgIcon from "../components/SvgIcon";

const CommentReplyer = ({ onSubmit }: any) => {
	const [text, setText] = useState<any>("");
	const [open, setOpen] = useState<any>(false);

	return (
		<div>
			{!open && (
				<div onClick={() => setOpen(true)} className="text-success cursor-pointer text-xs font-normal mt-4">
					{" "}
					글 달기
				</div>
			)}
			{open && (
				<div className="w-full mt-2.5">
					<div className="border border-[#dadada] rounded-sm bg-[#fff] p-3">
						<textarea
							value={text}
							onChange={(e) => setText(e.target.value)}
							placeholder="댓글을 입력해주세요"
							className="resize-none text-[#777] text-xs w-full bg-transparent outline-none"
						/>
					</div>

					<div className="flex justify-end gap-2">
						<div
							onClick={() => {
								setOpen(false);
							}}
							className="text-red-500 border border-red-500 px-2.5 py-0.5 text-xs font-medium rounded-md mt-2">
							취소
						</div>
						<div
							onClick={() => {
								onSubmit(text).then(() => setText(""));
							}}
							className="text-success border border-success px-2.5 py-0.5 text-xs font-medium rounded-md mt-2">
							작성
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const KakaoShareButton = ({ image, title, text, url }: any) => {
	useEffect(() => {
		// @ts-ignore
		Kakao.Link.createDefaultButton({
			container: "#main_kakao",
			objectType: "feed",
			installTalk: true,
			content: {
				title,
				description: text,
				imageUrl: image,
				link: {
					webUrl: url,
					mobileWebUrl: url,
				},
			},
		});
	}, []);

	return (
		<>
			<div id="main_kakao" className="w-16 h-16">
				{IconKakao}
			</div>
			<div className="text-center text-black font-medium text-sm">카카오톡</div>
		</>
	);
};

export default function CommunityDetailPage() {
	const [community, setCommunity] = useState<any>(null);
	const [participants, setParticipants] = useState<any>(null);
	const [comments, setComments] = useState<any>(null);
	const [text, setText] = useState<any>("");
	const [isConfrimed, setIsConfirmed] = useState<any>(false);
	const [isRejected, setIsRejected] = useState<any>(false);
	const params = useParams();
	const [modal, setModal] = useState<any>("");
	const [onModal, setOnModal] = useState<any>("");

	const [me, setMe] = useState<any>(null);

	useEffect(() => {
		const { community_id } = params;

		if (community_id) {
			COMMUNITY_CLUP.getOne(+community_id).then((res: any) => {
				setCommunity(res);
			});
			COMMUNITY_CLUP.getParticipants(+community_id).then((res: any) => {
				setParticipants(res);
			});
			COMMUNITY_CLUP.getComments(+community_id).then((res: any) => {
				setComments(res);
			});
			COMMUNITY_CLUP.getMyStatus(+community_id).then((res: any) => {
				setIsConfirmed(res.isConfirmed);
				setIsRejected(res.isRejected);
			});
		}

		USER.getUser().then((res: any) => {
			setMe(res);
		});
	}, []);

	const handleClickCreateComment = () => {
		const { community_id } = params;

		if (community_id) {
			COMMUNITY_CLUP.createComment(+community_id, text).then((res: any) => {
				COMMUNITY_CLUP.getComments(+community_id).then((res: any) => {
					setComments(res);
				});
				setText("");
			});
		}
	};

	const handleClickCreateReply = async (parentId: any, comment: string) => {
		const { community_id } = params;

		if (community_id) {
			COMMUNITY_CLUP.createComment(+community_id, comment, parentId).then((res: any) => {
				COMMUNITY_CLUP.getComments(+community_id).then((res: any) => {
					setComments(res);
				});
				setText("");
			});
		}
	};

	const chatRoomId = useMemo(() => {
		if (community) {
			const chatroom = community?.ChatRoom[0];
			return chatroom.id;
		} else {
			return "";
		}
	}, [community]);

	const createdAt = useMemo(() => {
		if (community) {
			return format(new Date(community?.createdAt), "yyyy-MM-dd");
		} else {
			return "";
		}
	}, [community]);

	const recruitGenderType = useMemo(() => {
		if (community) {
			if (community?.recruitGenderType === "Male") {
				return "남성";
			} else if (community?.recruitGenderType === "Female") {
				return "여성";
			} else if (community?.recruitGenderType === "All") {
				return "남녀무관";
			}
		} else {
			return "";
		}
	}, [community]);

	const type = useMemo(() => {
		if (community) {
			if (community?.type === "RECRUITMENT") {
				return "모집형";
			} else {
				return "계획형";
			}
		} else {
			return "";
		}
	}, [community]);

	const periodString = useMemo(() => {
		if (community) {
			const startedAt = format(new Date(community?.startedAt), "yyyy-MM-dd");
			const endedAt = format(new Date(community?.endedAt), "yyyy-MM-dd");
			const period = differenceInDays(new Date(community?.endedAt), new Date(community?.startedAt));
			return `${startedAt} ~ ${endedAt} (${period}박 ${period + 1}일)`;
		} else {
			return "";
		}
	}, [community]);

	const tripStyles = useMemo(() => {
		if (community) {
			return community?.tripStyles.map((v: any) => `#${v}`).join(" ");
		}
		return "";
	}, [community]);

	const tripPreferences = useMemo(() => {
		if (community) {
			return community?.tripPreferences.map((v: any) => `#${v}`).join(" ");
		}
		return "";
	}, [community]);

	const image = useMemo(() => {
		if (community) {
			const communityClupFiles = community?.CommunityClupFile;
			const promotion = communityClupFiles.find((v: any) => v.type === "PROMOTION");

			return promotion?.File.url;
		}
		return "";
	}, [community]);

	// convert plain to href
	const convertLink = (inputText: string) => {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;

		//URLs starting with http://, https://, or ftp://
		replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&#\/%?=~_|!:,.;]*[-A-Z0-9+&#\/%=~_|])/gim;
		if (replacePattern1.test(inputText)) {
			inputText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" style="color:blue">$1</a>');
		}

		//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		if (replacePattern2.test(inputText)) {
			inputText = inputText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" style="color:blue">$2</a>');
		}

		//Change email addresses to mailto:: links.
		replacePattern3 = /(([a-zA-Z0-9\-\_\.])+[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		if (replacePattern3.test(inputText)) {
			replacedText = inputText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
		}
		return inputText;
	}

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-[512px] flex justify-center flex-col items-center">
				<div
					className="h-[230px] bg-cover bg-center w-full"
					style={{
						backgroundImage: `url(${image})`,
					}}></div>
				<div>
					<div className="mt-[20px] px-[12px] ">{community?.title}</div>
					<div className="px-3 mt-[5px] flex justify-between ">
						<div className=" text-[14px] font-normal text-[#777]">
							{createdAt} · 조회수 {community?.viewCount} · 댓글 {community?.commentCount}
						</div>
						<div
							onClick={() => {
								setOnModal("SHARE");
							}}>
							{IconShare}
						</div>
					</div>

					<div className="px-[12px] pt-[20px]">
						<div className="border border-[#dadada] grid gap-[20px] grid-cols-3 px-[14px] py-[10px]  ">
							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">국가</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.nation}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">도시</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.city}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">인원</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.numberOfRecruits}명</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">동행 연령대</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.ageGroup}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">동행 성별</span>
								<span className="text-xs text-[#0e1111] font-medium ">{recruitGenderType}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">모집유형</span>
								<span className="text-xs text-[#0e1111] font-medium ">{type}</span>
							</div>

							<div className="flex gap-[5px] col-span-3">
								<span className="text-xs text-[#777] font-normal ">여행기간</span>
								<span className="text-xs text-[#0e1111] font-medium ">{periodString}</span>
							</div>

							<div className="flex gap-[5px] col-span-3">
								<span className="text-xs text-[#777] font-normal ">여행기호</span>
								<span className="text-xs text-[#0e1111] font-medium ">{tripPreferences}</span>
							</div>

							<div className="flex gap-[5px] col-span-3">
								<span className="text-xs text-[#777] font-normal ">여행성향</span>
								<span className="text-xs text-[#0e1111] font-medium ">{tripStyles}</span>
							</div>
						</div>
					</div>

					{isConfrimed ? (
						<div className="cursor-pointer px-3 flex justify-center" onClick={() => (window.location.href = `/community/${chatRoomId}/chat`)}>
							<div className="bg-[#0057FF] rounded-[2px] font-bold text-[16px] w-full   text-[#f5f5f5] h-[40px] flex justify-center items-center mt-[20px] 88px] flex-none">
								커뮤니티 대화방 입장하기
							</div>
						</div>
					) : isRejected ? (
						<div className="cursor-pointer px-3 flex justify-center">
							<div className="bg-[#0057FF] rounded-[2px] font-bold text-[16px] w-full   text-[#f5f5f5] h-[40px] flex justify-center items-center mt-[20px] 88px] flex-none">
								커뮤니티 대화방 승인거절
							</div>
						</div>
					) :
					(
						<div
							className="px-3 flex flex-col justify-center"
							onClick={() => {
								if (community?.numberOfRecruits === community?.participants.length) return;
								if (community?.communityClupParticipants?.find((e: any) => e.userId === me.id)) return;
								window.location.href = `/community/${params.community_id}/participate`;
							}}>
							<div
								className={`rounded-[2px] font-bold text-[16px] w-full   h-[40px] flex justify-center items-center mt-[20px] 88px] flex-none ${
									community?.numberOfRecruits === community?.participants.length
										? "bg-white text-[#0057FF] border-2"
										: "bg-[#0057FF] text-[#f5f5f5] cursor-pointer "
								}`}>
								{community?.numberOfRecruits === community?.participants.length
									? "모집 완료"
									: community?.communityClupParticipants?.find((e: any) => e.userId === me?.id)
									? "참가 승인 대기중"
									: community?.type === "RECRUITMENT"
									? "모집 참여하기"
									: "계획 참여하기"}
							</div>
						</div>
					)}
					{isConfrimed && community?.order?.OrderSchedule?.length > 0 && (
						<div className="cursor-pointer px-3 flex justify-center" onClick={() => (window.location.href = `/order/schedule/${community?.order?.id}`)}>
							<div className="bg-[#0057FF] rounded-[2px] font-bold text-[16px] w-full   text-[#f5f5f5] h-[40px] flex justify-center items-center mt-[12px] 88px] flex-none">
								일정표 보기
							</div>
						</div>
					)}

					<div className="h-[1px] w-full border border-[#999] mt-[20px]"></div>

					<div className="px-3 mt-[20px]">함께하는 동행자들</div>
					<div className=" px-3 mt-[10px]">
						<div className="px-[14px]  py-[10px]  flex flex-col gap-[10px]  border border-[#dadada] rounded-[2px] bg-[#f6f7f9]">
							{(participants ?? [])
								.filter((e: any) => e.confirmUserId)
								.map((participant: any) => {
									const user = participant.User;
									const file = participant?.User?.UserFiles?.find((file: any) => file.type === "PROFILE")?.File?.url;
									const age = Math.floor(user?.birthday ? differenceInDays(new Date(), new Date(user?.birthday)) / 365 : 0);

									return (
										<div className="flex gap-[5px] ">
											<div className="flex-none w-[50px] h-[50px] overflow-hidden bg-gray-300 rounded-full">
												{file && <img className="w-full" src={file} alt="" />}
											</div>
											<div className="flex flex-col justify-center gap-[2.5px] ">
												<div className=" text-[#0e1111] text-[14px] font-medium flex items-center gap-[2px] ">
													{user?.nickname}
													{participant?.isLeader && IconLeader}
												</div>
												<div className="flex gap-[10px]">
													<div className="flex text-[#777]  text-xs font-normal items-center gap-[4px]">
														{IconGender}
														{user?.genderText}
													</div>

													<div className="flex text-[#777]  text-xs font-normal items-center gap-[4px]">
														{IconAge}
														{age}
													</div>

													<div className="flex text-[#777]  text-xs font-normal items-center gap-[4px]">
														{IconParty}
														{participant?.count === 0 ? "첫 동행" : `${participant?.count + 1}번째 동행`}
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>

					<div className="h-[1px] w-full border border-[#999] mt-[20px] "></div>
					<div className="px-3 mt-[20px] ">여행 소개</div>

					<div
						className="mt-2.5  px-3 text-sm font-medium  leading-6  min-h-[300px]"
						dangerouslySetInnerHTML={{
							__html: convertLink(community?.description?.replace(/(?:\r\n|\r|\n)/g, "<br/>"))
						}}></div>

					<div className="h-[1px] w-full border border-[#999] mt-[20px] "></div>
					<div className="px-3 mt-[20px] ">댓글 ({comments?.length})</div>

					{(comments ?? []).map((chat: any) => {
						const user = chat?.user;
						const image = user?.UserFiles?.find((file: any) => file.type === "PROFILE")?.File.url;
						const createdAt = format(new Date(chat?.createdAt), "yyyy.MM.dd");
						const isLeader = participants?.find((v: any) => v.userId === user?.id)?.isLeader;

						return (
							<div className="px-3 mt-[20px] ">
								<div className="border border-[#dadada] rounded-[5px] bg-[#f6f7f9] flex flex-col pb-[16px]">
									<div className="flex px-[13px] py-[11px]   gap-[6px]">
										<div className="flex-none w-[40px] h-[40px] bg-gray-300 rounded-full overflow-hidden">
											<img className="w-full h-full" src={image} alt="" />
										</div>
										<div className="flex flex-col w-full">
											<div className="flex justify-between">
												<div className="text-[#0e1111]  text-[14px] font-normal tracknig-[-0.32px] flex gap-1">
													{chat?.user?.nickname}
													{isLeader && IconLeader}
												</div>
												{me?.id === chat?.user?.id && (
													<div
														onClick={() => {
															COMMUNITY_CLUP.deleteComment(+community.id, +chat.id).then((res: any) => {
																COMMUNITY_CLUP.getComments(+community.id).then((res: any) => {
																	setComments(res);
																});
															});
														}}
														className="text-red-500 text-xs">
														삭제
													</div>
												)}
											</div>

											<div className="text-[#777]  text-[10px] font-normal mt-[5px] ">{createdAt}</div>
											<div
												className="text-[#0e1111]  text-xs font-normal mt-[13px] "
												dangerouslySetInnerHTML={{ __html: convertLink(chat?.content?.replace(/\n/g, "<br />")) }}></div>
											<CommentReplyer onSubmit={(v: string) => handleClickCreateReply(chat.id, v)} />
										</div>
									</div>
									<div className="flex flex-col gap-[4px]">
										{chat?.childComments?.map((child: any) => {
											const user = child?.user;
											const image = user?.profileImageUrl;
											const isLeader = participants?.find((v: any) => v.userId === user?.id)?.isLeader;
											const createdAt = format(new Date(child?.createdAt), "yyyy.MM.dd");

											return (
												<div className="pl-[24px]  pr-[16px] ">
													<div className="flex gap-[5px]  rounded-[5px] border border-[#dadada] bg-[#ececec] px-[9px] py-[15px]  ">
														<div className="flex-none w-[40px] h-[40px] overflow-hidden rounded-full bg-gray-300">
															<img className="w-full h-full" src={image} alt="" />
														</div>
														<div className="flex flex-col flex-1">
															<div className="flex items-center w-full justify-start gap-[3px]">
																<div className="flex justify-between">
																	<span className="text-[#0e1111]  text-[14px] font-medium">{child.user?.nickname}</span>
																</div>

																{isLeader && IconLeader}
																<span className="ml-[4px]   text-[10px] font-normal text-[#777]">{createdAt}</span>
															</div>
															<div
																className=" text-xs  font-normal mt-[5px]"
																dangerouslySetInnerHTML={{ __html: convertLink(child?.content?.replace(/\n/g, "<br />")) }}></div>
														</div>

														{me?.id === child?.user?.id && (
															<div className="flex-none">
																<div
																	onClick={() => {
																		COMMUNITY_CLUP.deleteComment(+community.id, +child.id).then((res: any) => {
																			COMMUNITY_CLUP.getComments(+community.id).then((res: any) => {
																				setComments(res);
																			});
																		});
																	}}
																	className="text-red-500 text-xs  text-right w-full ">
																	삭제
																</div>
															</div>
														)}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						);
					})}

					<div className="px-3 mt-[30px] ">
						<textarea
							value={text}
							onChange={(e) => setText(e.target.value)}
							className="border border-[#dadada] w-full resize-none py-[13px] px-[12px] text-xs  "
							placeholder="댓글을 입력해주세요"
						/>
					</div>
					<div className="px-3 mt-[8px] flex justify-end" onClick={handleClickCreateComment}>
						<div className="cursor-pointer border-[#0057ff] rounded-[5px] text-xs font-normal border px-[10px]  py-[4px]  text-[#0057ff]">작성</div>
					</div>
				</div>
			</div>
			<Modal open={onModal === "SHARE"} onClose={() => setOnModal("")}>
				<div className="flex items-center gap-1 font-medium">
					{IconShare}
					공유하기
				</div>
				<div className="flex justify-between mt-8">
					<div className="flex flex-col">
						<KakaoShareButton url={window.location.href} title={community?.title} text={community?.description} image={image} />
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16"
							onClick={() => {
								window.open(`https://twitter.com/intent/tweet?text=${community?.title}+${window.location.href}`);
							}}>
							<img src="/images/20230725081132224942 1.png" />
						</div>
						<div className="text-center text-black font-medium text-sm">X</div>
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16 flex justify-center items-center"
							onClick={() => {
								//@ts-ignore
								try {
									navigator.clipboard.writeText(window.location.href).then(() => {
										alert("복사 완료");
									});
								} catch (e) {}
							}}>
							{IconCopy}
						</div>
						<div className="text-center text-black font-medium text-sm">링크 복사</div>
						<input id="community_clup_id_copy" className="hidden" value={`${community?.title}\nhttps://www.ordermtrip.com`} />
					</div>
				</div>
				<div className="mt-5 font-bold text-white bg-success text-sm h-[30px] flex justify-center items-center">공유하기</div>
			</Modal>
		</div>
	);
}

const IconGender = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 5.6875C5.8269 5.6875 5.28125 6.23315 5.28125 6.90625C5.28125 7.57935 5.8269 8.125 6.5 8.125C7.1731 8.125 7.71875 7.57935 7.71875 6.90625C7.71875 6.23315 7.1731 5.6875 6.5 5.6875ZM4.46875 6.90625C4.46875 5.78442 5.37817 4.875 6.5 4.875C7.62183 4.875 8.53125 5.78442 8.53125 6.90625C8.53125 8.02808 7.62183 8.9375 6.5 8.9375C5.37817 8.9375 4.46875 8.02808 4.46875 6.90625Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.50002 8.9375C6.09008 8.9375 5.68577 9.03295 5.3191 9.21628C4.95244 9.39961 4.63349 9.66579 4.38752 9.99375C4.2529 10.1732 3.99827 10.2096 3.81877 10.075C3.63928 9.94038 3.6029 9.68574 3.73752 9.50625C4.05917 9.07739 4.47625 8.7293 4.95574 8.48956C5.43522 8.24981 5.96394 8.125 6.50002 8.125C7.0361 8.125 7.56482 8.24981 8.04431 8.48956C8.52379 8.7293 8.94088 9.07739 9.26252 9.50625C9.39714 9.68574 9.36077 9.94038 9.18127 10.075C9.00178 10.2096 8.74714 10.1732 8.61252 9.99375C8.36656 9.66579 8.04761 9.39961 7.68095 9.21628C7.31428 9.03295 6.90997 8.9375 6.50002 8.9375Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.1562 1.21875C10.605 1.21875 10.9688 1.58252 10.9688 2.03125V10.9688C10.9688 11.4175 10.605 11.7812 10.1562 11.7812H2.84375C2.39502 11.7812 2.03125 11.4175 2.03125 10.9688V2.03125C2.03125 1.58252 2.39502 1.21875 2.84375 1.21875L10.1562 1.21875ZM10.1562 10.9688V2.03125L2.84375 2.03125V10.9688H10.1562Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.46875 3.25C4.46875 3.02563 4.65063 2.84375 4.875 2.84375H8.125C8.34937 2.84375 8.53125 3.02563 8.53125 3.25C8.53125 3.47437 8.34937 3.65625 8.125 3.65625H4.875C4.65063 3.65625 4.46875 3.47437 4.46875 3.25Z"
			fill="#777777"
		/>
	</svg>
);

const IconShare = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.5 7.3125C3.56802 7.3125 2.8125 8.06802 2.8125 9C2.8125 9.93198 3.56802 10.6875 4.5 10.6875C5.43198 10.6875 6.1875 9.93198 6.1875 9C6.1875 8.06802 5.43198 7.3125 4.5 7.3125ZM1.6875 9C1.6875 7.4467 2.9467 6.1875 4.5 6.1875C6.0533 6.1875 7.3125 7.4467 7.3125 9C7.3125 10.5533 6.0533 11.8125 4.5 11.8125C2.9467 11.8125 1.6875 10.5533 1.6875 9Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.375 12.375C11.443 12.375 10.6875 13.1305 10.6875 14.0625C10.6875 14.9945 11.443 15.75 12.375 15.75C13.307 15.75 14.0625 14.9945 14.0625 14.0625C14.0625 13.1305 13.307 12.375 12.375 12.375ZM9.5625 14.0625C9.5625 12.5092 10.8217 11.25 12.375 11.25C13.9283 11.25 15.1875 12.5092 15.1875 14.0625C15.1875 15.6158 13.9283 16.875 12.375 16.875C10.8217 16.875 9.5625 15.6158 9.5625 14.0625Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.375 2.25C11.443 2.25 10.6875 3.00552 10.6875 3.9375C10.6875 4.86948 11.443 5.625 12.375 5.625C13.307 5.625 14.0625 4.86948 14.0625 3.9375C14.0625 3.00552 13.307 2.25 12.375 2.25ZM9.5625 3.9375C9.5625 2.3842 10.8217 1.125 12.375 1.125C13.9283 1.125 15.1875 2.3842 15.1875 3.9375C15.1875 5.4908 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 5.4908 9.5625 3.9375Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.9571 4.85027C11.125 5.11162 11.0493 5.45963 10.788 5.62758L6.69576 8.25727C6.43441 8.42521 6.0864 8.3495 5.91845 8.08815C5.75051 7.8268 5.82623 7.47878 6.08758 7.31084L10.1798 4.68115C10.4411 4.5132 10.7891 4.58892 10.9571 4.85027Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.91845 9.91277C6.0864 9.65142 6.43441 9.5757 6.69576 9.74365L10.788 12.3733C11.0493 12.5413 11.125 12.8893 10.9571 13.1506C10.7891 13.412 10.4411 13.4877 10.1798 13.3198L6.08758 10.6901C5.82623 10.5221 5.75051 10.1741 5.91845 9.91277Z"
			fill="#777777"
		/>
	</svg>
);

const IconLeader = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path
			d="M11.1802 12.6527H4.82016C4.54016 12.6527 4.28683 12.4727 4.19349 12.2127L1.42016 4.44604C1.20016 3.82604 1.90683 3.29937 2.43349 3.67937L5.10016 5.58604C5.45349 5.83938 5.96016 5.68604 6.11349 5.27938L7.37349 1.91938C7.58683 1.33938 8.40683 1.33938 8.62016 1.91938L9.88016 5.27938C10.0335 5.69271 10.5335 5.83938 10.8935 5.58604L13.5602 3.67937C14.0935 3.29937 14.7935 3.83271 14.5735 4.44604L11.8002 12.2127C11.7135 12.4727 11.4602 12.6527 11.1802 12.6527Z"
			fill="#202C4A"
		/>
		<path
			d="M11.3332 14.666H4.6665C4.39317 14.666 4.1665 14.4393 4.1665 14.166C4.1665 13.8927 4.39317 13.666 4.6665 13.666H11.3332C11.6065 13.666 11.8332 13.8927 11.8332 14.166C11.8332 14.4393 11.6065 14.666 11.3332 14.666Z"
			fill="#F5B826"
		/>
		<path
			d="M9.66683 9.83398H6.3335C6.06016 9.83398 5.8335 9.60732 5.8335 9.33398C5.8335 9.06065 6.06016 8.83398 6.3335 8.83398H9.66683C9.94016 8.83398 10.1668 9.06065 10.1668 9.33398C10.1668 9.60732 9.94016 9.83398 9.66683 9.83398Z"
			fill="#F5B826"
		/>
	</svg>
);

const IconAge = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 2.03125C4.03198 2.03125 2.03125 4.03198 2.03125 6.5C2.03125 8.96802 4.03198 10.9688 6.5 10.9688C8.96802 10.9688 10.9688 8.96802 10.9688 6.5C10.9688 4.03198 8.96802 2.03125 6.5 2.03125ZM1.21875 6.5C1.21875 3.58325 3.58325 1.21875 6.5 1.21875C9.41675 1.21875 11.7812 3.58325 11.7812 6.5C11.7812 9.41675 9.41675 11.7812 6.5 11.7812C3.58325 11.7812 1.21875 9.41675 1.21875 6.5Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 4.46875C5.60254 4.46875 4.875 5.19629 4.875 6.09375C4.875 6.99121 5.60254 7.71875 6.5 7.71875C7.39746 7.71875 8.125 6.99121 8.125 6.09375C8.125 5.19629 7.39746 4.46875 6.5 4.46875ZM4.0625 6.09375C4.0625 4.74756 5.15381 3.65625 6.5 3.65625C7.84619 3.65625 8.9375 4.74756 8.9375 6.09375C8.9375 7.43994 7.84619 8.53125 6.5 8.53125C5.15381 8.53125 4.0625 7.43994 4.0625 6.09375Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.50048 8.53125C5.9003 8.53125 5.31185 8.69744 4.80034 9.01141C4.28883 9.32537 3.87426 9.77484 3.60256 10.31C3.50099 10.5101 3.25647 10.5899 3.05642 10.4883C2.85636 10.3868 2.77651 10.1422 2.87808 9.94219C3.2177 9.27324 3.73592 8.7114 4.37531 8.31895C5.01469 7.92649 5.75026 7.71875 6.50048 7.71875C7.2507 7.71875 7.98627 7.92649 8.62565 8.31895C9.26503 8.7114 9.78325 9.27324 10.1229 9.94219C10.2244 10.1422 10.1446 10.3868 9.94454 10.4883C9.74448 10.5899 9.49996 10.5101 9.39839 10.31C9.1267 9.77484 8.71212 9.32537 8.20061 9.01141C7.68911 8.69744 7.10065 8.53125 6.50048 8.53125Z"
			fill="#777777"
		/>
	</svg>
);

const IconParty = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 5.48438C5.60254 5.48438 4.875 6.21191 4.875 7.10938C4.875 8.00684 5.60254 8.73438 6.5 8.73438C7.39746 8.73438 8.125 8.00684 8.125 7.10938C8.125 6.21191 7.39746 5.48438 6.5 5.48438ZM4.0625 7.10938C4.0625 5.76318 5.15381 4.67188 6.5 4.67188C7.84619 4.67188 8.9375 5.76318 8.9375 7.10938C8.9375 8.45557 7.84619 9.54688 6.5 9.54688C5.15381 9.54688 4.0625 8.45557 4.0625 7.10938Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.1346 6.57458C10.768 6.39129 10.3637 6.29619 9.9538 6.29688C9.72944 6.29725 9.54725 6.11567 9.54688 5.89131C9.5465 5.66694 9.72808 5.48476 9.95245 5.48438C10.4889 5.48348 11.0181 5.60795 11.4979 5.84786C11.9778 6.08777 12.3949 6.43648 12.716 6.86617C12.8504 7.04589 12.8135 7.30047 12.6338 7.43479C12.4541 7.56911 12.1995 7.5323 12.0652 7.35259C11.8199 7.02429 11.5012 6.75787 11.1346 6.57458Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.04622 6.29688C2.63637 6.29619 2.23202 6.39129 1.86544 6.57458C1.49885 6.75787 1.18017 7.02429 0.934807 7.35259C0.800488 7.5323 0.545911 7.56911 0.366193 7.43479C0.186476 7.30047 0.149673 7.04589 0.283992 6.86617C0.605144 6.43648 1.02226 6.08777 1.50208 5.84786C1.98189 5.60795 2.51113 5.48348 3.04758 5.48438C3.27194 5.48476 3.45352 5.66694 3.45315 5.89131C3.45277 6.11567 3.27059 6.29725 3.04622 6.29688Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.50029 9.54883C5.96667 9.54883 5.44378 9.6987 4.99118 9.98137C4.53859 10.264 4.17448 10.6682 3.94034 11.1477C3.8419 11.3493 3.59865 11.4329 3.39703 11.3345C3.19542 11.236 3.11179 10.9928 3.21023 10.7911C3.51115 10.1749 3.9791 9.65553 4.56078 9.29224C5.14246 8.92895 5.81448 8.73633 6.50029 8.73633C7.18609 8.73633 7.85811 8.92895 8.43979 9.29224C9.02147 9.65553 9.48943 10.1749 9.79034 10.7911C9.88879 10.9928 9.80515 11.236 9.60354 11.3345C9.40193 11.4329 9.15868 11.3493 9.06023 11.1477C8.82609 10.6682 8.46199 10.264 8.00939 9.98137C7.55679 9.6987 7.0339 9.54883 6.50029 9.54883Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.34761 3.08506C3.12354 3.02761 2.88774 3.03512 2.66778 3.1067C2.44782 3.17828 2.25277 3.31098 2.10542 3.4893C1.95807 3.66762 1.86452 3.88418 1.83568 4.1137C1.80684 4.34321 1.84392 4.57619 1.94258 4.78542C2.04123 4.99464 2.19739 5.17147 2.39281 5.29524C2.58823 5.41901 2.81484 5.48461 3.04616 5.48437C3.27052 5.48415 3.45259 5.66585 3.45282 5.89021C3.45305 6.11458 3.27135 6.29665 3.04698 6.29687C2.66145 6.29727 2.28377 6.18793 1.95807 5.98165C1.63237 5.77536 1.3721 5.48065 1.20768 5.13194C1.04325 4.78324 0.981458 4.39493 1.02952 4.01241C1.07758 3.62989 1.23351 3.26894 1.47908 2.97175C1.72466 2.67455 2.04974 2.45338 2.41635 2.33408C2.78296 2.21478 3.17595 2.20227 3.5494 2.29802C3.92285 2.39377 4.26134 2.59382 4.52532 2.87479C4.7893 3.15577 4.96786 3.50607 5.04015 3.88476C5.08222 4.10515 4.93766 4.31791 4.71728 4.35998C4.49689 4.40205 4.28413 4.2575 4.24206 4.03711C4.19869 3.80989 4.09155 3.59971 3.93316 3.43113C3.77477 3.26254 3.57168 3.14251 3.34761 3.08506Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.3318 3.1067C10.1118 3.03512 9.87604 3.02761 9.65197 3.08506C9.4279 3.14251 9.2248 3.26254 9.06642 3.43113C8.90803 3.59971 8.80089 3.80989 8.75752 4.03711C8.71545 4.2575 8.50269 4.40205 8.2823 4.35998C8.06191 4.31791 7.91736 4.10515 7.95943 3.88476C8.03171 3.50607 8.21028 3.15577 8.47426 2.87479C8.73824 2.59382 9.07673 2.39377 9.45018 2.29802C9.82363 2.20227 10.2166 2.21478 10.5832 2.33408C10.9498 2.45338 11.2749 2.67455 11.5205 2.97175C11.7661 3.26894 11.922 3.62989 11.9701 4.01241C12.0181 4.39493 11.9563 4.78324 11.7919 5.13194C11.6275 5.48065 11.3672 5.77536 11.0415 5.98165C10.7158 6.18793 10.3381 6.29727 9.95259 6.29687C9.72823 6.29665 9.54653 6.11458 9.54675 5.89021C9.54698 5.66585 9.72905 5.48415 9.95342 5.48437C10.1847 5.48461 10.4113 5.41901 10.6068 5.29524C10.8022 5.17147 10.9583 4.99464 11.057 4.78542C11.1557 4.57619 11.1927 4.34321 11.1639 4.1137C11.1351 3.88418 11.0415 3.66762 10.8942 3.4893C10.7468 3.31098 10.5518 3.17828 10.3318 3.1067Z"
			fill="#777777"
		/>
	</svg>
);

const IconKakao = (
	<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
		<rect width="64" height="64" rx="17" fill="#FFE812" />
		<path
			d="M31.9571 13C18.7259 13 8 21.2246 8 31.37C8 37.9292 12.4841 43.6846 19.2294 46.9346C18.8625 48.1654 16.8713 54.8525 16.792 55.3778C16.792 55.3778 16.7444 55.7726 17.0072 55.9231C17.27 56.0737 17.5792 55.9567 17.5792 55.9567C18.3329 55.8543 26.3196 50.3984 27.702 49.451C29.083 49.6412 30.505 49.74 31.9571 49.74C45.1884 49.74 55.9143 41.5156 55.9143 31.37C55.9143 21.2246 45.1884 13 31.9571 13Z"
			fill="black"
		/>
		<path
			d="M19.1946 36.3428C18.4079 36.3428 17.7682 35.8079 17.7682 35.1502V27.7313H15.5424C14.7705 27.7313 14.1426 27.1826 14.1426 26.5085C14.1426 25.8343 14.7707 25.2857 15.5424 25.2857H22.8468C23.6188 25.2857 24.2467 25.8343 24.2467 26.5085C24.2467 27.1826 23.6185 27.7313 22.8468 27.7313H20.6211V35.1502C20.6211 35.8079 19.9813 36.3428 19.1946 36.3428ZM31.7028 36.3266C31.1079 36.3266 30.6529 36.1151 30.5157 35.775L29.8094 34.1562L25.4596 34.1559L24.7528 35.7759C24.6161 36.1153 24.1613 36.3266 23.5665 36.3266C23.2536 36.3269 22.9443 36.2681 22.6597 36.1542C22.2665 35.9954 21.8885 35.5588 22.3217 34.3809L25.7337 26.5185C25.9741 25.9205 26.7042 25.3044 27.6333 25.2859C28.565 25.3042 29.2951 25.9205 29.536 26.5197L32.9466 34.3787C33.3807 35.5592 33.0027 35.9961 32.6095 36.1545C32.3248 36.2681 32.0156 36.3268 31.7028 36.3266ZM29.0593 31.9441L27.6345 28.4006L26.2097 31.9441H29.0593ZM35.2423 36.1607C34.4884 36.1607 33.8752 35.647 33.8752 35.016V26.5345C33.8752 25.8458 34.5286 25.2857 35.3314 25.2857C36.1343 25.2857 36.7876 25.8458 36.7876 26.5345V33.8712H39.8188C40.5727 33.8712 41.1858 34.3849 41.1858 35.016C41.1858 35.647 40.5727 36.1607 39.8188 36.1607H35.2423ZM43.1672 36.3266C42.3805 36.3266 41.7407 35.7665 41.7407 35.0778V26.5345C41.7407 25.8458 42.3805 25.2857 43.1672 25.2857C43.9539 25.2857 44.5937 25.8458 44.5937 26.5345V29.2186L48.5735 25.7344C48.7782 25.5552 49.0594 25.4566 49.3647 25.4566C49.7208 25.4566 50.0784 25.591 50.3463 25.8254C50.5962 26.0439 50.7453 26.3251 50.7657 26.6171C50.7864 26.9116 50.6744 27.1816 50.4509 27.3774L47.2003 30.2228L50.7115 34.2952C50.9405 34.5591 51.0391 34.8923 50.9854 35.2203C50.934 35.5486 50.7347 35.8452 50.4319 36.0439C50.1852 36.2081 49.8837 36.2967 49.5739 36.296C49.1257 36.2978 48.703 36.1137 48.4344 35.7996L45.0891 31.9191L44.5941 32.3525V35.0772C44.5932 35.7668 43.9549 36.3257 43.1672 36.3266Z"
			fill="#FFE812"
		/>
	</svg>
);

const IconCopy = (
	<svg className="absolute" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="vuesax/bold/link-square">
			<g id="link-square">
				<path
					id="Vector"
					d="M51.269 6.33334H24.7323C13.2057 6.33334 6.33398 13.205 6.33398 24.7317V51.2367C6.33398 62.795 13.2057 69.6667 24.7323 69.6667H51.2373C62.764 69.6667 69.6357 62.795 69.6357 51.2683V24.7317C69.6673 13.205 62.7957 6.33334 51.269 6.33334ZM25.904 53.105C25.8407 53.105 25.7457 53.105 25.6823 53.105C22.6107 52.82 19.729 51.395 17.5757 49.115C12.509 43.795 12.509 35.15 17.5757 29.83L24.5107 22.5467C26.9806 19.95 30.3057 18.4933 33.8523 18.4933C37.399 18.4933 40.724 19.9183 43.194 22.5467C48.2607 27.8667 48.2607 36.5117 43.194 41.8317L39.7423 45.4733C38.824 46.4233 37.3357 46.455 36.3857 45.5683C35.4357 44.65 35.404 43.1617 36.2907 42.2117L39.7423 38.57C43.099 35.055 43.099 29.3233 39.7423 25.84C36.6073 22.5467 31.0973 22.5467 27.9307 25.84L20.9957 33.1233C17.639 36.6383 17.639 42.37 20.9957 45.8533C22.3573 47.31 24.194 48.1967 26.1257 48.3867C27.424 48.5133 28.374 49.685 28.2473 50.9833C28.1523 52.1867 27.1073 53.105 25.904 53.105ZM58.4257 46.2017L51.4907 53.485C49.0207 56.0817 45.6957 57.5383 42.149 57.5383C38.6023 57.5383 35.2773 56.1133 32.8073 53.485C27.7407 48.165 27.7407 39.52 32.8073 34.2L36.259 30.5583C37.1773 29.6083 38.6657 29.5767 39.6157 30.4633C40.5657 31.3817 40.5973 32.87 39.7107 33.82L36.259 37.4617C32.9023 40.9767 32.9023 46.7083 36.259 50.1917C39.394 53.485 44.904 53.5167 48.0707 50.1917L55.0057 42.9083C58.3623 39.3933 58.3623 33.6617 55.0057 30.1783C53.644 28.7217 51.8073 27.835 49.8757 27.645C48.5773 27.5183 47.6273 26.3467 47.754 25.0483C47.8807 23.75 49.0207 22.7683 50.3507 22.9267C53.4223 23.2433 56.304 24.6367 58.4573 26.9167C63.4923 32.205 63.4923 40.8817 58.4257 46.2017Z"
					fill="#65737E"
				/>
			</g>
		</g>
	</svg>
);
