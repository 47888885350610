import { useState } from "react";

interface Props {
	list: {
		label: string;
		value: string | number;
	}[];
	value: string | number;
	onChange: (value: string | number) => void;
	contentAlign?: "justify-between" | "justify-start";
	className?: string;
	disableText?: boolean;
	type: string;
	text: string;
}
export default function InputListAndText({ list, value, onChange, className = "", contentAlign = "justify-between", type = "text", text = "기타" }: Props) {
	const handleChange = (newValue: string | number) => {
		setIsCustom(false);
		onChange(newValue);
	};

	const handleCustomChange = (newValue: string) => {
		setIsCustom(true);
		onChange(newValue);
	};

	const [isCustom, setIsCustom] = useState(false);

	return (
		<div className={className}>
			<div className={["flex w-full gap-[10px]", contentAlign, contentAlign === "justify-start" && "gap-3"].join(" ")}>
				{list.map((item) => {
					const isSelected = value === item.value;
					return (
						<div
							key={item.value}
							className={`min-w-[33px] flex-1 whitespace-nowrap  justify-center transition-all text-[14px] font-normal font-spoqa tracking-[-0.32px] flex rounded-[2px] items-center py-[8px] px-[5px]  gap-2 border ${
								isSelected ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0e1111]"
							} ${contentAlign === "justify-start" ? "w-16" : ""} cursor-pointer `}
							onClick={() => handleChange(item.value)}>
							{/* 숨겨진 라디오 버튼 */}
							<input
								className="hidden" // Tailwind를 사용하여 라디오 버튼 숨기기
								type="radio"
								name="radio"
								value={item.value}
								checked={isSelected}
								onChange={() => handleChange(item.value)}
							/>
							<label className="text-[14px] font-medium tracking-[-0.32px] font-spoqa cursor-pointer">{item.label}</label>
						</div>
					);
				})}
			</div>
			<div className="mt-[10px] flex">
				<div
					className={[
						"w-[80px] h-[33px] justify-center items-center flex rounded-tl-[2px] rounded-bl-[2px] border-l border-t border-b border-[#DADADA] text-[#0e1111] text-[14px] font-normal tracking-[-0.32px] font-spoqa ",
						(isCustom || text === "주소") && "border-[#0057FF] text-[#0057FF]",
					].join(" ")}
					onClick={() => handleCustomChange("")}>
					{text}
				</div>
				<input
					value={isCustom ? value : ""}
					className={[
						"py-[6px] bg-transparent px-[7px] outline-none w-full h-[33px] rounded-tr-[2px] rounded-br-[2px] border  text-[14px] font-medium tracking-[-0.32px] font-spoqa",
						isCustom ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0e1111]",
					].join(" ")}
					type={type}
					defaultValue={list.some((e): any => e.value === value) ? "" : value}
					onFocus={() => handleCustomChange("")}
					onChange={(e) => onChange(e.target.value)}
				/>
			</div>
		</div>
	);
}
