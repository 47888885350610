import { useEffect, useMemo, useRef, useState } from "react";
import Footer from "../components/Footer";
import { CHATROOMS, COMMUNITY_CLUP, FILE, USER } from "../api";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import ChatRoomCalendar from "../components/modal/ChatRoomCalendar";
import Modal from "../components/modal";
import briefcase from "../image/briefcase.png";
import parse from 'html-react-parser';

function transformDate(datestring: string) {
	try {
		return format(new Date(datestring), "yyyy-MM-dd");
	} catch (e) {
		return "";
	}
}

export default function CommunityChat() {
	const { chat_room_id } = useParams();
	const [my, setMy] = useState<any>({});
	const [chats, setChats] = useState<any[]>([]);
	const [community, setCommunity] = useState<any>({});
	const [participnats, setParticipants] = useState<any[]>([]);
	const [openCalendar, setOpenCalendar] = useState(false);
	const [onModal, setOnModal] = useState("");

	useEffect(() => {
		if (community && community.id) {
			COMMUNITY_CLUP.getParticipants(community.id).then((res) => {
				setParticipants(res);
			});
		}
	}, [community]);

	useEffect(() => {
		let interval: any = null;
		const syncMessages = () => {
			CHATROOMS.getMessages(+(chat_room_id ?? 0)).then((res) => {
				res.sort((a: any, b: any) => {
					if (a.createdAt < b.createdAt) {
						return 1;
					} else if (a.createdAt > b.createdAt) {
						return -1;
					} else {
						return 0;
					}
				});
				setChats(res);
			});
		};

		if (chat_room_id) {
			CHATROOMS.getCommuntyClup(+chat_room_id).then((res) => {
				setCommunity(res.CommunityClup);
			});
			USER.getUser().then((res) => {
				setMy(res);
			});
			syncMessages();
			interval = setInterval(syncMessages, 3000);
		}
		return () => {
			clearInterval(interval);
		};
	}, []);

	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const chatroomRef = useRef<HTMLDivElement>(null);

	const isMobile = useMemo(() => {
		return width < 600;
	}, [width]);

	const containerHeight = useMemo(() => {
		if (containerRef.current) {
			const { y } = containerRef.current.getBoundingClientRect();
			return height - y;
		}
	}, [height]);

	const handleResize = () => {
		setHeight(window.innerHeight);
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [message, setMessage] = useState("");

	const send = (e: any) => {
		e.preventDefault();
		if (chat_room_id) {
			CHATROOMS.sendMessage(+chat_room_id, message).then((res) => {
				setChats([res, ...chats]);
				setMessage("");
			});
		}
	};

	const clupAccompany = () => {
		console.log(community, my);
		if (my.id !== community.userId) {
			alert("리더만 동행신청이 가능합니다.");
			return;
		}

		if (community?.type !== "PLANNED") {
			alert("계획된 커뮤니티클럽만 동행신청이 가능합니다.");
			return;
		}

		window.location.href = `/travel_Order?community_clup_id=${community.id}`;
	};

	// send image
	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;

		if (file) {
			FILE.upload(file).then((res) => {
				if (!chat_room_id) return;

				CHATROOMS.sendMessage(+chat_room_id, res.url, "IMAGE");
			});
		}
	};

	// convert plain to href
	const convertLink = (inputText: string) => {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;

		//URLs starting with http://, https://, or ftp://
		replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&#\/%?=~_|!:,.;]*[-A-Z0-9+&#\/%=~_|])/gim;
		if (replacePattern1.test(inputText)) {
			inputText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" style="color:blue">$1</a>');
		}

		//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		if (replacePattern2.test(inputText)) {
			inputText = inputText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" style="color:blue">$2</a>');
		}

		//Change email addresses to mailto:: links.
		replacePattern3 = /(([a-zA-Z0-9\-\_\.])+[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		if (replacePattern3.test(inputText)) {
			replacedText = inputText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
		}
		return inputText;
	}

	const [sidebar, setSidebar] = useState(false);

	return (
		<div className="relative flex justify-center items-center flex-col overflow-hidden">
			<div
				className={["flex relative flex-col w-full max-w-lg", !isMobile ? "min-h-[500px]" : ""].join(" ")}
				ref={containerRef}
				style={{
					height: isMobile ? containerHeight : "",
				}}>
				<div className="flex-none relative flex justify-center items-center py-[20px] border-b border-[#999]">
					<div className="absolute left-[12px] top-[22px] cursor-pointer" onClick={() => window.history.back()}>
						{IconLeft}
					</div>
					<div className="">{community.title}-</div>
					<div className="absolute right-[12px] top-[23px]" onClick={() => setSidebar(true)}>
						{IconMore}
					</div>
				</div>

				<div ref={chatroomRef} className="flex-grow overflow-y-scroll flex flex-col-reverse gap-[8px]">
					<div className="h-[25px] w-full"></div>
					{chats.map((chat) => {
						const createdAt = format(new Date(chat.createdAt), "aa hh:mm");
						const isMine = chat.userId === my.id;

						const userImage = chat.user?.UserFiles.find((v: any) => v.type === "PROFILE")?.File.url;
						let schedule = null;
						let scheduleDate = "";
						let scheduleTime = "";
						try {
							schedule = JSON.parse(chat.content)[0];
							const start = format(new Date(schedule?.startedAt), "MM월 dd일");
							const end = format(new Date(schedule?.endedAt), "MM월 dd일");

							if (start === end) {
								scheduleDate = start;
							} else {
								scheduleDate = `${start} ~ ${end}`;
							}

							scheduleTime = `${format(new Date(schedule?.startedAt), "aa hh:mm")} ~ ${format(new Date(schedule?.endedAt), "aa hh:mm")}`;
						} catch (e) {
							//
						}
						return isMine ? (
							<div className="flex flex-col items-end px-[16px]">
								<div
									className={[
										"flex items-center max-w-[247px] rounded-[18px] font-normal text-[17px] text-[#F5F5F5] break-all",
										(chat.type === "TEXT" || chat.type === "IMAGE") && "bg-[#0057ff]",
									].join(" ")}>
									{chat.type === "TEXT" && <div id="chat-content" className=" rounded-[20px] py-[4px] px-[12px]"> {parse(convertLink(chat.content))} </div>}
									{chat.type === "IMAGE" && /\.png|\.jpg|\.jpeg/.test(chat.content) && (
										<div className=" bg-center bg-cover w-32 h-32 overflow-hidden rounded-sm" style={{ backgroundImage: `url(${chat.content})` }}></div>
									)}
									{chat.type === "IMAGE" && !/\.png|\.jpg|\.jpeg/.test(chat.content) && (
										<a
											href={chat.content}
											className=" bg-center bg-cover w-32 h-12 overflow-hidden cursor-pointer flex justify-center items-center bg-white text-black text-sm gap-2 border-gray-300 border rounded-sm">
											{IconDownload}파일 다운로드
										</a>
									)}
									{chat.type === "SCHEDULE" && (
										<div className="rounded-[20px] py-[4px] px-[12px] flex-col w-[247px] bg-white border border-gray-200">
											<div className="text-black font-medium my-3">📢 일정이 공유됐어요!</div>
											<div className="border-t border-gray-200"></div>
											<div className="flex">
												<div
													className="w-[35px] h-[35px] rounded-full bg-gray-200 mt-6"
													style={{
														backgroundImage: `url(${schedule?.User?.UserFiles?.find((v: any) => v)?.File.url})`,
													}}></div>
												<div className="text-black mt-6 text-sm font-bold ml-4 mb-5">
													<div>{scheduleDate}</div>
													<div>{schedule?.title}</div>
												</div>
											</div>
											<div className="border-t border-gray-200"></div>
											<div className="text-black text-sm font-bold mt-2.5">
												<div className="flex">
													<div className="flex-none w-12">시간</div> {scheduleTime}
												</div>
												<div className="flex mt-2.5 mb-2.5">
													<div className="flex-none w-12">장소</div> {schedule?.location}
												</div>
											</div>
											<div className="border-t border-gray-200"></div>
											<div className="text-black text-sm font-bold mt-2.5 mb-8">
												<div className="flex">
													<div className="flex-none w-12">설명</div> {schedule?.description}
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="flex items-center mt-[8px]">
									<div className="text-[#777] text-[12px]  font-medium font-spoqa ">{createdAt}</div>
								</div>
							</div>
						) : (
							<div className="flex items-end gap-[8px]">
								{/* <div>
									<div
										className="w-[32px] h-[32px] rounded-full ml-[8px] mb-[14px] bg-center bg-cover bg-gray-300"
										style={{
											backgroundImage: `url(${userImage})`,
										}}>
									</div>
									<div>{chat.user?.nickname}</div>
								</div> */}
								<div className="flex text-sm text-black font-medium items-center w-[130px] gap-2.5">
									<div className="w-9 h-9 bg-cover bg-center bg-gray-200 rounded-full" style={{ backgroundImage: `url(${userImage})` }}></div>
									<div>{chat.user?.nickname}</div>
								</div>
								<div className="flex flex-col items-start">
									{chat.type === "TEXT" && (
										<div
											className={[
												"flex items-center max-w-[247px] bg-[#ECECEC] rounded-[18px] font-medium text-[17px] text-[#0E1111] py-[4px] px-[12px] break-all",
											].join(" ")}>
											{parse(convertLink(chat.content))}
										</div>
									)}
									{chat.type === "IMAGE" && /\.png|\.jpg|\.jpeg/.test(chat.content) && (
										<div className=" bg-center bg-cover w-32 h-32 overflow-hidden rounded-sm" style={{ backgroundImage: `url(${chat.content})` }}></div>
									)}
									{chat.type === "IMAGE" && !/\.png|\.jpg|\.jpeg/.test(chat.content) && (
										<a
											href={chat.content}
											className=" bg-center bg-cover w-32 h-12 overflow-hidden cursor-pointer flex justify-center items-center bg-white text-black text-sm gap-2 border-gray-300 border rounded-sm">
											{IconDownload}파일 다운로드
										</a>
									)}
									{chat.type === "SCHEDULE" && (
										<div className="rounded-[20px] py-[4px] px-[12px] flex-col w-[247px] bg-white border border-gray-200">
											<div className="text-black font-medium my-3">📢 일정이 공유됐어요!</div>
											<div className="border-t border-gray-200"></div>
											<div className="flex">
												<div
													className="w-[35px] h-[35px] rounded-full bg-gray-200 mt-6"
													style={{
														backgroundImage: `url(${schedule?.User?.UserFiles?.find((v: any) => v)?.File.url})`,
													}}></div>
												<div className="text-black mt-6 text-sm font-bold ml-4 mb-5">
													<div>{scheduleDate}</div>
													<div>{schedule?.title}</div>
												</div>
											</div>
											<div className="border-t border-gray-200"></div>
											<div className="text-black text-sm font-bold mt-2.5">
												<div className="flex">
													<div className="flex-none w-12">시간</div> {scheduleTime}
												</div>
												<div className="flex mt-2.5 mb-2.5">
													<div className="flex-none w-12">장소</div> {schedule?.location}
												</div>
											</div>
											<div className="border-t border-gray-200"></div>
											<div className="text-black text-sm font-bold mt-2.5 mb-8">
												<div className="flex">
													<div className="flex-none w-12">설명</div> {schedule?.description}
												</div>
											</div>
										</div>
									)}

									<div className="text-[#777] text-[12px] font-medium font-spoqa">{createdAt}</div>
								</div>
							</div>
						);
					})}
					<div className="text-center text-[#777] font-spoqa text-[18px] font-medium hidden mt-[25px] mb-[50px]">2023년 11월 7일 (화요일)</div>
					<div className="h-[25px] w-full"></div>
				</div>
				<div className="brder-[#999] flex h-[66px] max-w-full flex-none items-center gap-[14px] border-t px-[12px]">
					<div className="flex gap-[14px]">
						<label>
							{IconClip} <input type="file" className="hidden" onChange={handleImageChange} />
						</label>
						<div
							onClick={() => {
								window.location.href = `/community/${chat_room_id}/vote`;
							}}>
							{IconVote}
						</div>
						<div onClick={() => (window.location.href = `/community/${chat_room_id}/calendar`)}>{IconCalendar}</div>
					</div>

					<form onSubmit={send} className="flex flex-1 border rounded-[12px] border-[#dadada] h-[40px] items-center px-[4px]">
						<input value={message} onChange={(v) => setMessage(v.target.value)} className="w-full flex-1 bg-transparent px-[6px] py-[2px]" />
						<button type="submit">{IconUpArrow}</button>
					</form>
				</div>

				<div className="flex-none hidden  pt-[30px] border-t border-[#dadada] mt-[100px] h-[190px] flex-col">
					<div className="border border-[#dadada] w-full h-full flex flex-col p-4 ">
						<textarea value={message} onChange={(v) => setMessage(v.target.value)} className="resize-none flex-1 outline-none" />
						<div className="flex justify-end cursor-pointer" onClick={send}>
							<div className="border-[#777] border rounded-[5px] text-[16px] text-[#777] px-[16px] py-[8px] font-medium">보내기</div>
						</div>
					</div>
				</div>
			</div>

			<div className={["absolute w-full h-full bg-black bg-opacity-30 top-0 left-0", sidebar ? "left-0" : "left-full"].join(" ")}>
				<div className="absolute right-0 w-[350px] bg-white h-full">
					<div className="mt-7 flex items-center justify-between px-4">
						<div className="font-medium text-black">{community.title}</div>
						<div onClick={() => setSidebar(false)}>{IconClose}</div>
					</div>
					<div className="text-[#777] text-xs font-normal px-3 mt-2.5">
						개설 {transformDate(community.createdAt)}·참가자{participnats.length}명
					</div>
					<div className="px-3 py-5">
						<div className="w-full border border-b border-[#999] "></div>
					</div>

					<div className="px-3 flex flex-col gap-6">
						<div
							className="text-black text-sm font-medium gap-1 flex items-center cursor-pointer"
							onClick={() => {
								setOnModal("with");
							}}>
							{IconWith}크루와 동행하기
						</div>
						<div
							onClick={() => (window.location.href = `/community/${chat_room_id}/calendar`)}
							className="text-black text-sm font-medium gap-1 flex items-center cursor-pointer">
							{IconCalendar}크루 일정 확인하기
						</div>
						<div
							className="text-black text-sm font-medium gap-1 flex items-center cursor-pointer"
							onClick={() => {
								window.location.href = `/community/${chat_room_id}/vote`;
							}}>
							{IconVote}투표하기
						</div>
					</div>

					<div className="px-3 py-5">
						<div className="w-full border border-b border-[#999] "></div>
					</div>

					<div className="font-medium text-black px-3">동행자 목록</div>

					<div className="px-3 mt-5 flex flex-col gap-5">
						{participnats.map((participant) => {
							if (participant?.confirmedAt) {
								const userImage = participant?.User?.UserFiles?.find((v: any) => v.type === "PROFILE")?.File.url;
								return (
									<div className="flex text-sm text-black font-medium items-center gap-2.5">
										<div className="w-9 h-9 bg-cover bg-center bg-gray-200 rounded-full" style={{ backgroundImage: `url(${userImage})` }}></div>
										<div>{participant?.User?.nickname}</div>
									</div>
								);
							}
						})}
					</div>

					<div className="px-3 py-5">
						<div className="w-full border border-b border-[#999]"></div>
					</div>

					<div className="px-3 mt-20">
						<div
							onClick={() => {
								if (+community.userId === +my.id) {
									setOnModal("withdraw");
								} else {
									COMMUNITY_CLUP.withdrawCommunityClup(community.id).then(() => {
										alert("커뮤니티클럽에서 나가졌습니다.");
										window.location.href = "/";
									});
								}
							}}
							className="rounded-sm border border-[#dadada] text-[#777] font-bold text-center py-2.5">
							커뮤니티 나가기
						</div>
					</div>
				</div>
			</div>

			<Modal
				open={onModal === "withdraw"}
				onClose={() => {
					setOnModal("");
				}}>
				<div>
					<div>다음 리더를 지정해주세요.</div>
					<div className="px-3 mt-5 flex flex-col gap-5">
						{participnats
							.filter((participant) => participant?.User?.id !== +my.id)
							.map((participant) => {
								if (participant?.confirmedAt) {
									const userImage = participant?.User?.UserFiles?.find((v: any) => v.type === "PROFILE")?.File.url;
									return (
										<div
											onClick={() => {
												COMMUNITY_CLUP.transferOwner(community.id, participant?.User?.id).then(() => {
													COMMUNITY_CLUP.withdrawCommunityClup(community.id).then(() => {
														alert("커뮤니티클럽에서 나가졌습니다.");
														window.location.href = "/";
													});
												});
											}}
											className="flex text-sm text-black font-medium items-center gap-2.5">
											<div className="w-9 h-9 bg-cover bg-center bg-gray-200 rounded-full" style={{ backgroundImage: `url(${userImage})` }}></div>
											<div>{participant?.User?.nickname}</div>
										</div>
									);
								}
							})}
					</div>
				</div>
			</Modal>

			<Modal
				open={onModal === "with"}
				onClose={() => {
					setOnModal("");
				}}>
				<div className="flex flex-col gap-[25px] w-full items-center">
					<img className="w-[80px] aspect-square" src={briefcase} />
					<div className="text-[16px] font-bold">해당 크루와 동행하시겠어요?</div>
					<div className="text-[14px] font-medium text-center">
						주문 및 견적확정시
						<br />
						크루와 확정 주문서 및 결제가 공유됩니다.
					</div>
				</div>
				<div
					className="w-full flex bg-[#0057FF] text-white h-[40px] justify-center items-center mt-[20px]"
					onClick={() => {
						clupAccompany();
					}}>
					주문하기
				</div>
			</Modal>

			{!isMobile && <Footer />}
		</div>
	);
}

const IconLeft = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M11.2509 14.9405L6.36086 10.0505C5.78336 9.47305 5.78336 8.52805 6.36086 7.95055L11.2509 3.06055"
			stroke="#0E1111"
			strokeWidth="1.5"
			stroke-miterlimit="10"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconMore = (
	<svg className="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M14 5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5Z" stroke="#0E1111" />
		<path d="M14 19C14 17.9 13.1 17 12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19Z" stroke="#0E1111" />
		<path d="M14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z" stroke="#0E1111" />
	</svg>
);

const IconClip = (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.0438 5.52624L5.29382 13.4065C5.03724 13.6897 4.89939 14.0606 4.90879 14.4426C4.9182 14.8246 5.07414 15.1884 5.34434 15.4586C5.61454 15.7288 5.9783 15.8847 6.3603 15.8941C6.7423 15.9036 7.11329 15.7657 7.39646 15.5091L16.635 6.14029C17.1482 5.57395 17.4239 4.83197 17.4051 4.06797C17.3863 3.30397 17.0744 2.57646 16.534 2.03606C15.9936 1.49567 15.2661 1.18378 14.5021 1.16497C13.7381 1.14615 12.9961 1.42186 12.4298 1.93501L3.19118 11.3039C2.3547 12.1403 1.88477 13.2748 1.88477 14.4578C1.88477 15.6408 2.3547 16.7753 3.19118 17.6118C4.02766 18.4482 5.16217 18.9182 6.34514 18.9182C7.5281 18.9182 8.66261 18.4482 9.4991 17.6118L17.1374 9.99203"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconVote = (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.5 14.731L8.56029 15.9914C8.56029 15.9914 8.60239 16.0108 8.6177 15.9914L12.5 13"
			stroke="#0E1111"
			stroke-miterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M13.3732 3.55573L11.2287 1.86635C10.6213 1.38788 9.74103 1.49237 9.26256 2.09975L6.16227 6.03527C5.6838 6.64264 5.7883 7.52289 6.39567 8.00136L8.54017 9.69074C9.14755 10.1692 10.0278 10.0647 10.5063 9.45734L13.6066 5.52182C14.085 4.91445 13.9805 4.0342 13.3732 3.55573Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			stroke-miterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M14.96 9.23242C14.59 9.23242 14.26 9.44242 14.08 9.76242C13.44 10.9724 11.7 11.8424 9.65 11.8424C7.6 11.8424 5.86 10.9724 5.22 9.76242C5.05 9.43242 4.71 9.23242 4.34 9.23242H3C2.45 9.23242 2 9.68242 2 10.2324V16.6724C2 17.7324 2.86 18.5824 3.91 18.5824H15.38C16.44 18.5824 17.29 17.7224 17.29 16.6724V10.2324C17.29 9.68242 16.84 9.23242 16.29 9.23242H14.95H14.96Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			stroke-miterlimit="10"
		/>
	</svg>
);

const IconCalendar = (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.66602 1.66602V4.16602" stroke="#0E1111" strokeWidth="1.2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M13.334 1.66602V4.16602" stroke="#0E1111" strokeWidth="1.2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M2.91602 7.57422H17.0827" stroke="#0E1111" strokeWidth="1.2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
		<path
			d="M17.5 7.08268V14.166C17.5 16.666 16.25 18.3327 13.3333 18.3327H6.66667C3.75 18.3327 2.5 16.666 2.5 14.166V7.08268C2.5 4.58268 3.75 2.91602 6.66667 2.91602H13.3333C16.25 2.91602 17.5 4.58268 17.5 7.08268Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			stroke-miterlimit="10"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M9.99607 11.4167H10.0036" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M6.91209 11.4167H6.91957" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M6.91209 13.9167H6.91957" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
	</svg>
);

const IconUpArrow = (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="arrow-square-right">
			<g id="Icon">
				<path d="M18.75 13.75L15 10L11.25 13.75" fill="#0057FF" />
				<path
					d="M9 25C7.59987 25 6.8998 25 6.36502 24.7275C5.89462 24.4878 5.51217 24.1054 5.27248 23.635C5 23.1002 5 22.4001 5 21L5 9C5 7.59987 5 6.8998 5.27248 6.36502C5.51217 5.89462 5.89462 5.51217 6.36502 5.27248C6.8998 5 7.59987 5 9 5L21 5C22.4001 5 23.1002 5 23.635 5.27248C24.1054 5.51217 24.4878 5.89462 24.7275 6.36502C25 6.8998 25 7.59987 25 9L25 21C25 22.4001 25 23.1002 24.7275 23.635C24.4878 24.1054 24.1054 24.4878 23.635 24.7275C23.1002 25 22.4001 25 21 25L9 25Z"
					fill="#0057FF"
				/>
				<path
					d="M18.75 13.75L15 10M15 10L11.25 13.75M15 10L15 20M25 21L25 9C25 7.59987 25 6.8998 24.7275 6.36502C24.4878 5.89462 24.1054 5.51217 23.635 5.27248C23.1002 5 22.4001 5 21 5L9 5C7.59987 5 6.8998 5 6.36502 5.27248C5.89462 5.51217 5.51217 5.89462 5.27248 6.36502C5 6.8998 5 7.59987 5 9L5 21C5 22.4001 5 23.1002 5.27248 23.635C5.51217 24.1054 5.89462 24.4878 6.36502 24.7275C6.8998 25 7.59987 25 9 25L21 25C22.4001 25 23.1002 25 23.635 24.7275C24.1054 24.4878 24.4878 24.1054 24.7275 23.635C25 23.1002 25 22.4001 25 21Z"
					stroke="#F5F5F5"
					strokeWidth="2"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</g>
	</svg>
);

const IconWith = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M19.7992 15.18V15.78C20.1306 15.78 20.3992 15.5114 20.3992 15.18H19.7992ZM19.7992 10.34H20.3992C20.3992 10.0086 20.1306 9.74 19.7992 9.74V10.34ZM6.71476 5.87529C6.43602 6.05448 6.35532 6.42571 6.53451 6.70445C6.7137 6.9832 7.08493 7.0639 7.36367 6.88471L6.71476 5.87529ZM13.1992 2.42L13.6728 2.05163C13.4822 1.80651 13.136 1.74736 12.8748 1.91529L13.1992 2.42ZM15.8056 6.74836C16.009 7.00993 16.386 7.05705 16.6476 6.85361C16.9092 6.65017 16.9563 6.2732 16.7528 6.01163L15.8056 6.74836ZM19.7992 14.58H16.9392V15.78H19.7992V14.58ZM16.9392 10.94H19.7992V9.74H16.9392V10.94ZM19.1992 10.34V15.18H20.3992V10.34H19.1992ZM15.1192 12.76C15.1192 11.7548 15.9341 10.94 16.9392 10.94V9.74C15.2713 9.74 13.9192 11.0921 13.9192 12.76H15.1192ZM16.9392 14.58C15.9341 14.58 15.1192 13.7652 15.1192 12.76H13.9192C13.9192 14.4279 15.2713 15.78 16.9392 15.78V14.58ZM7.36367 6.88471L13.5237 2.92471L12.8748 1.91529L6.71476 5.87529L7.36367 6.88471ZM12.7256 2.78836L15.8056 6.74836L16.7528 6.01163L13.6728 2.05163L12.7256 2.78836ZM3.07922 6.98H18.0392V5.78H3.07922V6.98ZM18.0392 18.98H3.07922V20.18H18.0392V18.98ZM2.79922 18.7V7.26H1.59922V18.7H2.79922ZM3.07922 18.98C2.92458 18.98 2.79922 18.8546 2.79922 18.7H1.59922C1.59922 19.5174 2.26184 20.18 3.07922 20.18V18.98ZM18.3192 18.7C18.3192 18.8546 18.1939 18.98 18.0392 18.98V20.18C18.8566 20.18 19.5192 19.5174 19.5192 18.7H18.3192ZM18.0392 6.98C18.1939 6.98 18.3192 7.10536 18.3192 7.26H19.5192C19.5192 6.44262 18.8566 5.78 18.0392 5.78V6.98ZM3.07922 5.78C2.26184 5.78 1.59922 6.44262 1.59922 7.26H2.79922C2.79922 7.10536 2.92458 6.98 3.07922 6.98V5.78ZM18.3192 15.84V18.7H19.5192V15.84H18.3192ZM18.3192 7.26V9.57H19.5192V7.26H18.3192Z"
			fill="#0E1111"
		/>
	</svg>
);

const IconDownload = (
	<svg className="text-white w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path d="M15 10h4l-7 8-7-8h4v-10h6v10zm3.213-8.246l-1.213 1.599c2.984 1.732 5 4.955 5 8.647 0 5.514-4.486 10-10 10s-10-4.486-10-10c0-3.692 2.016-6.915 5-8.647l-1.213-1.599c-3.465 2.103-5.787 5.897-5.787 10.246 0 6.627 5.373 12 12 12s12-5.373 12-12c0-4.349-2.322-8.143-5.787-10.246z" />
	</svg>
);
