import { useState } from "react";
import "./OMTTab.scss";

interface Props {
	tabs: {
		value: string;
		node: any;
	}[];
	onChange: (tab: string) => void;
}

function OMTTab({ tabs, onChange }: Props) {
	const [activeTab, setActiveTab] = useState<string>(tabs[0]?.value ?? null);

	const handleClick = (tab: string) => {
		setActiveTab(tab);
		onChange(tab);
	};

	return (
		<div className="omt_tab">
			{tabs.map((tab) => (
				<div key={tab.value} className={["omt_tab_item", activeTab === tab.value ? "active" : ""].join(" ")} onClick={() => handleClick(tab.value)}>
					{tab.node}
				</div>
			))}
		</div>
	);
}

export default OMTTab;
