import { FormEvent, useEffect, useState } from "react";
import IconGoogle from "../image/googleLogo.svg";
import IconKakao from "../image/kakao.png";
import { AUTH } from "../api";
import { getToken, login } from "../auth";
import InputCheckbox from "../components/input/checkbox";
import Modal from "../components/modal";
import InputLabel from "../components/input/labe";
import InputText from "../components/input/text";
import InputPassword from "../components/input/password";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import client from "../axios";

const GOOGLE_CLIENT_ID = "261282872391-5e2a1rovhg3iufekftia812iv58dk2dd.apps.googleusercontent.com";
// TODO
const GOOGLE_REDIRECT_URI = "http://api.ordermtrip.com/auth/google";
// const GOOGLE_REDIRECT_URI = `${process.env.REACT_APP_BASE_URL}}/auth/google`;

const KAKAO_CLIENT_ID = "b8c6e03bb799bcd0e83c486ee1db7665";
// TODO
const KAKAO_REDIRECT_URI = "http://api.ordermtrip.com/auth/kakao";
// const KAKAO_REDIRECT_URI = `${process.env.REACT_APP_BASE_URL}/auth/kakao`;

export default function LoginPage() {
	const [isPartner, setIsPartner] = useState(false);
	const [error, setError] = useState(false);
	const [modal, setModal] = useState<string>(""); // user-password, partner-password, email
	const [searchParam] = useSearchParams();

	const [findForm, setFindForm] = useState({
		email: "",
		name: "",
		phone: "",
		password: "",
		passwordConform: "",
	});

	const handleKakaoLogin = () => {
		const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;
		window.sessionStorage.setItem("redirect", searchParam.get("redirect") || "");
		window.location.href = kakaoAuthUrl;
	};

	const handleGoogleLogin = () => {
		const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=code&scope=openid%20profile%20email`;
		window.sessionStorage.setItem("redirect", searchParam.get("redirect") || "");
		window.location.href = googleAuthUrl;
	};

	const searchParams = new URLSearchParams(window.location.search);

	useEffect(() => {
		const isLoggedIn = getToken();

		if (isLoggedIn) {
			window.location.href = "/mypage";
		}
	}, []);

	const [form, setForm] = useState({
		email: "",
		password: "",
		passwordConfirm: "",
		isRemember: false,
	});

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			if (!isPartner) {
				const response = await AUTH.loginUser(form.email, form.password);
				login(response.accessToken, form.isRemember, false);
			} else {
				const response = await AUTH.loginPartner(form.email, form.password);
				login(response.accessToken, form.isRemember, true);
			}

			const redirect = searchParams.get("redirect");
			if (redirect) {
				window.location.href = redirect;
			} else {
				window.location.href = "/";
			}
		} catch (e) {
			setError(true);
		}
	};

	const [foundEmail, setFoundEmail] = useState<string>("");

	const findEmail = () => {
		if (foundEmail.length > 0) {
			setModal("");
			setFoundEmail("");
			return;
		}

		if (isPartner) {
			AUTH.findPartnerEmail(findForm.name, findForm.phone).then((data) => {
				if (data) {
					setFoundEmail(data?.partner?.email);
				}
			});
		} else {
			AUTH.findUserEmail(findForm.name, findForm.phone).then((data) => {
				if (data) {
					setFoundEmail(data?.user?.email);
				} else {
					alert("이메일을 찾을 수 없습니다");
				}
			});
		}
	};

	const [token, setToken] = useState<string>("");

	const findPassword = () => {
		if (token.length > 0) {
			setModal("");
			setToken("");
			return;
		}

		if (isPartner) {
			AUTH.findPartnerPassword(findForm.email, findForm.name, findForm.phone).then((data) => {
				if (data) {
					setToken(data?.token);
					setModal("change-password");
				} else {
					alert("사용자를 찾을 수 없습니다");
				}
			});
		} else {
			AUTH.findUserPassword(findForm.email, findForm.name, findForm.phone).then((data) => {
				if (data) {
					setToken(data?.token);
					setModal("change-password");
				} else {
					alert("사용자를 찾을 수 없습니다");
				}
			});
		}
	};

	const changePassword = async () => {
		if (token) {
			if (isPartner) {
				await axios.patch(
					`${client.defaults.baseURL}/auth/partner/find-password`,
					{
						newPassword: findForm?.password,
					},
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
			} else {
				await axios.patch(
					`${client.defaults.baseURL}/auth/user/find-password`,
					{
						newPassword: findForm?.password,
					},
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
			}
			alert("비밀번호가 정상변경 되었습니다");
			setModal("");
			setToken("");
		} else {
			alert("오류가 발생했습니다.");
		}
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="mt-[57px] text-center font-spoqa text-xl font-bold text-black">“ Order Made trip ”</div>
				<div className="mt-5 text-center font-spoqa text-sm font-medium">
					여행을 요리처럼 쉽게 주문하고, 새로운 친구들과 함께 하는
					<br />
					지속 가능한 커뮤니티 여행 플랫폼
				</div>

				<div className="mt-8 flex px-3">
					<div className={["flex w-full border-b", !isPartner ? "bg-success" : "bg-[#d95830]"].join(" ")}>
						<div
							onClick={() => setIsPartner(false)}
							className={[
								"flex h-12 flex-1 items-center justify-center text-sm font-medium cursor-pointer",
								!isPartner ? "bg-success text-white" : "bg-[#ececec] text-[#999]",
							].join(" ")}>
							일반 회원
						</div>
						<div
							onClick={() => setIsPartner(true)}
							className={[
								"flex h-12 flex-1 items-center justify-center text-sm font-medium cursor-pointer",
								isPartner ? "bg-[#D95830] text-white" : "bg-[#ececec] text-[#999]",
							].join(" ")}>
							파트너 회원
						</div>
					</div>
				</div>

				<form onSubmit={handleSubmit} className="mt-16">
					<div className="bg-bg flex w-full flex-col justify-center px-3">
						<input
							value={form.email}
							onChange={(e) => setForm({ ...form, email: e.target.value })}
							className="h-10 w-full rounded-[2px] border border-[#999] px-[14px] py-[11px] text-sm font-normal text-black placeholder:text-[#777]"
							type="email"
							placeholder="이메일"
						/>
					</div>

					<div className="bg-bg mt-2.5 flex h-10 w-full justify-center px-3">
						<input
							value={form.password}
							onChange={(e) => setForm({ ...form, password: e.target.value })}
							type="password"
							className="w-full rounded-[2px] border border-[#999] px-[14px] py-[11px] text-sm font-normal  text-[#777]"
							placeholder="비밀번호"
						/>
					</div>

					<div className="mt-2.5 px-3">
						<InputCheckbox
							checked={form.isRemember}
							onChange={(e) => setForm({ ...form, isRemember: e.target.checked })}
							labelText="로그인상태유지"
							labelPosition="right"
						/>
					</div>

					<div className="mt-2.5 px-3.5">
						<button
							type="submit"
							className={[
								"flex h-10 w-full items-center justify-center rounded-sm text-sm font-bold text-white",
								!isPartner ? "bg-success" : "bg-[#d95830]",
							].join(" ")}>
							로그인
						</button>
						{error && <div className="text-red-500 text-xs font-normal mt-1">아이디 혹은 비밀번호가 잘못됐습니다.</div>}
					</div>

					<div className="flex justify-between px-3">
						<div className="py-3 text-xs font-normal text-black cursor-pointer" onClick={() => (window.location.href = "/registerSelect")}>
							회원가입
						</div>
						<div className="py-3 text-xs font-normal text-black cursor-pointer">
							<span
								onClick={() => {
									setModal("email");
								}}>
								아이디
							</span>
							&nbsp;&nbsp;|&nbsp;&nbsp;
							<span
								onClick={() => {
									setModal("password");
								}}>
								비밀번호 찾기
							</span>
						</div>
					</div>
				</form>

				<div className="relative mt-10 px-3">
					<div className="w-full border-t border-[#999]"></div>
					<div className="absolute -bottom-2.5 -left-0 flex w-full items-center justify-center">
						<div className="bg-white px-4 text-sm font-normal">간편 로그인</div>
					</div>
				</div>

				<div className="mt-7 px-3" onClick={handleGoogleLogin}>
					<div className="flex items-center justify-center gap-2.5 rounded-sm border border-[#999] py-2.5">
						<img className="w-5" src={IconGoogle} alt="google" />
						<div className="text-sm font-normal">Google로 시작하기</div>
					</div>
				</div>

				<div className="mt-4 px-3" onClick={handleKakaoLogin}>
					<div className="flex w-full justify-center rounded-xl bg-[#FEE504]">
						<img src={IconKakao} alt="kakao" />
					</div>
				</div>
			</div>

			<Modal open={modal === "email"} onClose={() => setModal("")}>
				<div className="text-lg">이메일 찾기</div>
				<div className="mt-4">
					<InputLabel title="이름" />
					<InputText
						className="mt-2.5"
						value={findForm.name}
						onChange={(e) =>
							setFindForm({
								...findForm,
								name: e,
							})
						}
					/>

					<InputLabel title="전화번호" className="mt-5" />
					<InputText
						className="mt-2.5"
						value={findForm.phone}
						placeholder="010-0000-0000"
						onChange={(e) =>
							setFindForm({
								...findForm,
								phone: e,
							})
						}
					/>
				</div>

				<div className="font-normal mt-4">{foundEmail}</div>

				<div onClick={findEmail} className="bg-success text-white rounded-sm py-2 flex justify-center items-center mt-5">
					{foundEmail ? "확인" : "이메일 찾기"}
				</div>
			</Modal>

			<Modal open={modal === "password"} onClose={() => setModal("")}>
				<div className="text-lg">비밀번호 찾기</div>
				<div className="mt-4">
					<InputLabel title="이메일" />
					<InputText
						className="mt-2.5"
						value={findForm.email}
						onChange={(e) =>
							setFindForm({
								...findForm,
								email: e,
							})
						}
					/>

					<InputLabel title="이름" className="mt-5" />
					<InputText
						className="mt-2.5"
						value={findForm.name}
						onChange={(e) =>
							setFindForm({
								...findForm,
								name: e,
							})
						}
					/>

					<InputLabel title="전화번호" className="mt-5" />
					<InputText
						className="mt-2.5"
						value={findForm.phone}
						placeholder="010-0000-0000"
						onChange={(e) =>
							setFindForm({
								...findForm,
								phone: e,
							})
						}
					/>
				</div>

				<div onClick={findPassword} className="bg-success text-white rounded-sm py-2 flex justify-center items-center mt-5">
					{foundEmail ? "확인" : "비밀번호 찾기"}
				</div>
			</Modal>

			<Modal open={modal === "change-password"} onClose={() => setModal("")}>
				<div className="text-lg">비밀번호 변경</div>

				<div className="mt-4">
					<InputLabel title="비밀번호" />
					<InputPassword
						className="mt-2.5"
						unConfirmText="비밀번호가 올바르지 않습니다."
						value={findForm.password}
						confirmText="등록가능한 비밀번호"
						showConfirmText={true}
						onChange={(e) => {
							setFindForm({
								...findForm,
								password: e,
							});
						}}
					/>

					<InputLabel title="비밀번호" className="mt-5" />
					<InputPassword
						className="mt-2.5"
						unConfirmText="비밀번호가 올바르지 않습니다."
						value={findForm.passwordConform}
						confirmText="등록가능한 비밀번호"
						showConfirmText={true}
						onChange={(e) =>
							setFindForm({
								...findForm,
								passwordConform: e,
							})
						}
					/>

					<div onClick={changePassword} className="bg-success text-white rounded-sm py-2 flex justify-center items-center mt-5">
						비밀번호 변경
					</div>
				</div>
			</Modal>
		</div>
	);
}
