import { useEffect, useState } from "react";
import EasyTable from "../../components/EasyTable";
import { FILE, PARTNER, PARTNER_QUOTATION, USER } from "../../admin_pages/api";
import EasyPagination from "../../components/EasyPagination";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";
import { DEFAULT } from "../../api";

export default function AdminPartnersPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [search, setSearch] = useState("");

	const [modal, setModal] = useState("update"); // 'add', 'edit', 'delete'
	const [selected, setSelected] = useState<any>(null);

	useEffect(() => {
		getData();
	}, [page]);

	const columns = [
		{
			header: "ID",
			key: "id",
		},
		{
			header: "이메일",
			key: "email",
			render: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
		},
		{
			header: "이름",
			key: "name",
		},
		{
			header: "국가",
			key: "nation",
		},
		{
			header: "도시",
			key: "city",
		},
		{
			header: "사업자명",
			key: "corporateBusinessName",
		},
		{
			header: "주소",
			key: "address",
		},
		{
			header: "직급",
			key: "position",
		},
		{
			header: "휴대폰",
			key: "mobilePhone",
		},
		{
			header: "전화번호",
			key: "landlinePhone",
		},
		{
			header: "사업자등록번호",
			key: "businessNumber",
		},
		{
			header: "회사 소개",
			key: "description",
		},
		{
			header: "견적수",
			key: "countPartnerQuotation",
		},
		{
			header: "삭제 여부",
			key: "deletedAt",
			render: (deletedAt: string) => `${deletedAt ? "Y" : "N"}`,
		},
		{
			header: "사업자등록증",
			key: "businessRegistrationFile",
			render: (value: any, row: any, index: any) => {
				const link = row?.PartnerFiles?.find((link: any) => link.type === "BUSINESS_REGISTRATION_CERTIFICATE");
				return link ? (
					<button
						onClick={() => {
							window.open(link.File.url, "_blank");
						}}
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
						사업자등록증
					</button>
				) : (
					"<없음>"
				);
			},
		},
		{
			header: "사업자등록증 요청",
			key: "businessRegistrationFileRequest",
			render: (value: any, row: any, index: any) => {
				const request = row?.PartnerRequest?.find((request: any) => request.type === "CHANGE_BUSINESS_REGISTRATION_CERTIFICATE");

				return request && request.acceppedAt === null && request.rejectedAt === null ? (
					<>
						<button
							onClick={() => handleShowRequestFile(request.targetId)}
							className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
							보기
						</button>
						<button
							onClick={() => handleConfirmRequest(row.id, request.id)}
							className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
							컨펌
						</button>
					</>
				) : (
					"<없음>"
				);
			},
		},
		{
			header: "계좌",
			key: "bankAccountFile",
			render: (value: any, row: any, index: any) => {
				const link = row?.PartnerFiles?.find((link: any) => link.type === "BANK_ACCOUNT");
				return link ? (
					<button
						onClick={() => {
							window.open(link.File.url, "_blank");
						}}
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
						계좌
					</button>
				) : (
					"<없음>"
				);
			},
		},
		{
			header: "계좌 요청",
			key: "bankAccountFileRequest",
			render: (value: any, row: any, index: any) => {
				const request = row?.PartnerRequest?.find((request: any) => request.type === "CHANGE_BANK_ACCOUNT");

				return request && request.acceppedAt === null && request.rejectedAt === null ? (
					<>
						<button
							onClick={() => handleShowRequestFile(request.targetId)}
							className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
							보기
						</button>
						<button
							onClick={() => handleConfirmRequest(row.id, request.id)}
							className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
							컨펌
						</button>
					</>
				) : (
					"<없음>"
				);
			},
		},
		{
			header: "수정",
			key: "edit",
			render: (value: any, row: any, index: any) => (
				<button onClick={() => handleUpdateModal(value, row, index)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			),
		},
		{
			header: "삭제",
			key: "delete",
			render: (value: any, row: any) => (
				<button onClick={() => handleDelete(row.id)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
			),
		},
		{
			header: "비밀번호 초기화",
			key: "id",
			render: (id: string) => {
				return (
					<button
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm"
						onClick={() => {
							alert('"123456789"로 초기화됩니다.');
							PARTNER.resetPassword(+id);
						}}>
						초기화
					</button>
				);
			},
		},
	];

	const handleConfirmRequest = async (partnerId: number, requestId: number) => {
		await PARTNER.confirm(partnerId, requestId);
		getData();
	};

	const handleShowRequestFile = async (fileId: number) => {
		const file = await FILE.findOne(fileId);
		window.open(file.url, "_blank");
	};

	const handleUpdateModal = (value: any, row: any, index: any) => {
		setModal("update");
		setSelected(row);
	};

	const getData = async () => {
		const response = await PARTNER.paginate(page.page, page.perPage, search);

		setData(response.nodes);
		setCount(response.total);
	};

	const handleDelete = async (id: number) => {
		await PARTNER.delete(id);
		getData();
	};

	const handleCreate = async (data: any) => {
		await PARTNER.create(
			data.email,
			data.password,
			data.name,
			data.nation,
			data.city,
			data.corporateBusinessName,
			data.address,
			data.position,
			data.mobilePhone,
			data.landlinePhone,
			data.businessNumber,
			data.description,
			data.businessRegistrationFileId?.id,
			data.accountFileId?.id
		);
		setModal("");
		getData();
	};

	const handleUpdate = async (data: any) => {
		await PARTNER.update(
			selected.id,
			data.email,
			data.password,
			data.name,
			data.nation,
			data.city,
			data.corporateBusinessName,
			data.address,
			data.position,
			data.mobilePhone,
			data.landlinePhone,
			data.businessNumber,
			data.description,
			data.businessRegistrationFileId?.id,
			data.accountFileId?.id
		);
		setSelected(null);
		setModal("");
		getData();
	};

	const handleGetPartners = async () => {
		getData();
	};

	return (
		<div>
			<h1>파트너 관리</h1>
			<div className="flex justify-end">
				<div className="border px-2.5 py-1 flex rounded-sm border-[#dadada]">
					<input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className="w-56 text-sm text-[#999] outline-none"
						type="search"
						placeholder="필터: 회사명/이메일/사업자/핸드폰"
					/>
					<button onClick={handleGetPartners}
						className="px-2 py-1 bg-indigo-500 text-sm text-white font-semibold rounded-md hover:bg-indigo-600 focus:outline-none">
						조회
					</button>
				</div>
				<button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable data={data} columns={columns} />
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>

				<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
					<div>추가</div>
					<div>
						<FormFactory
							schema={[
								{ label: "이메일", key: "email", method: "text", validation: "required" },
								{ label: "비밀번호", key: "password", method: "text", validation: "required" },
								{ label: "이름", key: "name", method: "text", validation: "required" },
								{ label: "국가", key: "nation", method: "text", validation: "required" },
								{ label: "도시", key: "city", method: "text", validation: "required" },
								{ label: "법인사업자명", key: "corporateBusinessName", method: "text", validation: "required" },
								{ label: "주소", key: "address", method: "text", validation: "required" },
								{ label: "직급", key: "position", method: "text", validation: "required" },
								{
									label: "핸드폰",
									key: "mobilePhone",
									method: "text",
									validation: (v: string) => /\d\d\d-\d\d\d\d-\d\d\d\d/.test(v),
									placeholder: "010-1234-5678",
								},
								{ label: "전화번호", key: "landlinePhone", method: "text", validation: "required" },
								{ label: "사업자등록번호", key: "businessNumber", method: "text", validation: "required" },
								{ label: "설명", key: "description", method: "text", validation: "required" },
								{ label: "사업자파일", key: "businessRegistrationFileId", method: "file", validation: "required" },
								{ label: "계좌파일", key: "accountFileId", method: "file", validation: "required" },
							]}
							onSubmit={handleCreate}
						/>
					</div>
				</Modal>

				{selected && (
					<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
						<div className="mb-6">유저 수정</div>

						<FormFactory
							schema={[
								{ label: "이름", key: "name", method: "text", validation: "required", defaultValue: selected.name },
								{ label: "국가", key: "nation", method: "text", validation: "required", defaultValue: selected.nation },
								{ label: "도시", key: "city", method: "text", validation: "required", defaultValue: selected.city },
								{ label: "사업자번호", key: "corporateBusinessName", method: "text", validation: "required", defaultValue: selected.corporateBusinessName },
								{ label: "주소", key: "address", method: "text", validation: "required", defaultValue: selected.address },
								{ label: "직급", key: "position", method: "text", validation: "required", defaultValue: selected.position },
								{
									label: "핸드폰",
									key: "mobilePhone",
									method: "text",
									validation: (v: string) => /\d\d\d-\d\d\d\d-\d\d\d\d/.test(v),
									placeholder: "010-1234-5678",
									defaultValue: selected.mobilePhone,
								},
								{ label: "전화번호", key: "landlinePhone", method: "text", validation: "required", defaultValue: selected.landlinePhone },
								{ label: "사업자등록번호", key: "businessNumber", method: "text", validation: "required", defaultValue: selected.businessNumber },
								{ label: "설명", key: "description", method: "text", validation: "required", defaultValue: selected.description },
							]}
							onSubmit={handleUpdate}
						/>
					</Modal>
				)}
			</div>
		</div>
	);
}
