import { useEffect, useMemo, useState } from "react";
import { DEFAULT, EVENT, FAQ, FRANCHISE, NOTICE } from "../api";
import { format, isAfter, set } from "date-fns";
import { start } from "repl";

export default function PartnershipsPage() {
	const [event, setEvent] = useState<any>([]);
	const [serviceConfig, setServiceConfig] = useState<any>(null);
	const [tab, setTab] = useState<any>("전체");
	const [tabManues, setTabManues] = useState<any>(["전체"]);

	useEffect(() => {
		EVENT.getMany().then((res: any) => {
			setEvent(res);
		});
		DEFAULT.getServiceConfig().then((res) => {
			setServiceConfig(res);
			setTabManues(["전체", ...res.eventCategories]);
		});
	}, []);

	const [selectedFranchise, setSelectedFranchise] = useState<any>(null);
	const handleClickNotice = (id: any) => {
		if (selectedFranchise === id) {
			setSelectedFranchise(null);
			return;
		}
		setSelectedFranchise(id);
	};

	const filtered = useMemo(() => {
		if (tab === "전체") return event;

		return event.filter((e: any) => e.category === tab);
	}, [tab, event]);

	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full">
				<div className="text-black text-2xl font-bold mt-12 text-center">이벤트</div>

				<div className="mt-5 px-3 flex gap-4 shadow-lg overflow-x-scroll flex-nowrap scrollbar-hide">
					{tabManues.map((_tab: any) => {
						const selected = tab === _tab;
						return (
							<div
								onClick={() => setTab(_tab)}
								className={[
									"flex-none transition-all w-20 py-3.5 text-xs font-medium text-center cursor-pointer",
									selected ? "border-b border-success text-success" : "text-black",
								].join(" ")}>
								{_tab}
							</div>
						);
					})}
				</div>
				<div className="flex flex-col gap-4 mt-8 px-3">
					{filtered.map((event: any) => {
						const image = event.imageFile?.url;

						const startedAt = format(new Date(event.startedAt), "yyyy.MM.dd");
						const endedAt = format(new Date(event.endedAt), "yyyy.MM.dd");

						const status = isAfter(new Date(), new Date(event.endedAt)) ? "종료" : "진행중";

						return (
							<div>
								<a href={`/event/${event.id}`} className="flex border p-2.5 border-[#dadada]">
									<div
										className="w-[130px] h-[130px] flex-none bg-center bg-cover"
										style={{
											backgroundImage: `url(${image})`,
										}}></div>

									<div className="flex flex-col justify-between">
										<div className="text-black text-lg font-bold line-clamp-1 pl-6 flex w-full flex-none justify-between">
											<div className="line-clamp-1 flex-shrink mr-1">{event.title}</div>
											<div className="flex flex-1 justify-end items-center ">
												{status === "진행중" ? (
													<div className="h-4 flex justify-center items-center flex-none text-[10px] bg-success px-2 text-white rounded-xl">진행중</div>
												) : (
													<div className="h-4 flex justify-center items-center flex-none text-[10px] px-2 bg-[#ececec] text-[#777] rounded-xl">종료</div>
												)}
											</div>
										</div>
										<div className="text-black text-sm font-medium line-clamp-2 px-6 mt-4">{event.content}</div>

										<div className="mt-4 text-xs font-medium px-6">이벤트 기간</div>

										<div className="text-black text-xs font-medium px-6 tracking-tighter mt-1">
											{startedAt} ~ {endedAt}까지
										</div>
									</div>
								</a>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
