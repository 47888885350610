import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./cmu_recruit.scss";
import questionMark from "../image/Question_Mark_Icon_UIA.svg";
import filterIcon from "../image/filter.svg";
import notebookIcon from "../image/Notebook.svg";
import sampleImage from "../image/exampleImage/destSampleImage.png";
import sampleProfileImage from "../image/exampleImage/sampleProfileImage.png";
import viewIcon from "../image/Eye.svg";
import commentIcon from "../image/ChatTeardropText.svg";
import club from "../image/community_club.svg";
import search from "../image/search.svg";
import usersIcon from "../image/UsersThree-white.svg";

import { TravelPostProps, Comment, CommentsSectionProps } from "../types";
import { COMMUNITY_CLUP } from "../api";
import { differenceInCalendarDays, format } from "date-fns";
import CommunityClupFilter from "../components/CommunityClupFilter";
import Pagination from "../components/pagination";

const IconZoom = (
	<svg className="w-4 h-4 xl:w-6 xl:h-6" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
		<path
			d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
			stroke="#999999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M13.75 13.75L12.5 12.5" stroke="#999999" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconBook = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.28125 5.6875C5.28125 5.46313 5.46313 5.28125 5.6875 5.28125H8.9375C9.16187 5.28125 9.34375 5.46313 9.34375 5.6875C9.34375 5.91187 9.16187 6.09375 8.9375 6.09375H5.6875C5.46313 6.09375 5.28125 5.91187 5.28125 5.6875Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.28125 7.3125C5.28125 7.08813 5.46313 6.90625 5.6875 6.90625H8.9375C9.16187 6.90625 9.34375 7.08813 9.34375 7.3125C9.34375 7.53687 9.16187 7.71875 8.9375 7.71875H5.6875C5.46313 7.71875 5.28125 7.53687 5.28125 7.3125Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.625 2.4375C1.625 1.98877 1.98877 1.625 2.4375 1.625H10.5625C11.0112 1.625 11.375 1.98877 11.375 2.4375V10.5625C11.375 11.0112 11.0112 11.375 10.5625 11.375H2.4375C1.98877 11.375 1.625 11.0112 1.625 10.5625V2.4375ZM10.5625 2.4375H2.4375V10.5625H10.5625V2.4375Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.0625 1.625C4.28687 1.625 4.46875 1.80688 4.46875 2.03125V10.9688C4.46875 11.1931 4.28687 11.375 4.0625 11.375C3.83813 11.375 3.65625 11.1931 3.65625 10.9688V2.03125C3.65625 1.80688 3.83813 1.625 4.0625 1.625Z"
			fill="#F5F5F5"
		/>
	</svg>
);

const CommunityPlanning: React.FC = () => {
	const [totalPages, setTotalPages] = useState(2); // 페이지의 총 개수
	const itemsPerPage = 8; // 한 페이지에 표시할 아이템 수
	const maxVisiblePages = 4; // 한 번에 보여줄 페이지 넘버의 수
	const [currentPage, setCurrentPage] = useState(1);
	const [showOverlay, setShowOverlay] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [rangeButtons, setRangeButtons] = useState(Array(10).fill(false));
	const [ageButtons, setAgeButtons] = useState(Array(5).fill(false));
	const [optionButtons, setOptionButtons] = useState(Array(6).fill(false));
	const [preferenceButtons, setPreferenceButtons] = useState(Array(4).fill(false));
	const [count, setCount] = useState(0);

	const [search, setSearch] = useState("");
	const [timer, setTimer] = useState(null);
	const [filter, setFilter] = useState<any>({});

	// 백엔드로부터 정보 받아오기
	const [sampleData, setData] = useState(null);
	useEffect(() => {
		if (timer) clearTimeout(timer);

		const newTimer = setTimeout(() => {
			COMMUNITY_CLUP.getMany("PLANNED", currentPage, 8, search, filter).then((res: any) => {
				setData(res.nodes);
				setCount(res.total);
			});
		}, 500);

		return () => clearTimeout(newTimer);
	}, [currentPage, search, filter]);

	const toggleButton = (category: string, index: number) => {
		let newButtons;
		if (category === "range") {
			newButtons = [...rangeButtons];
			newButtons[index] = !newButtons[index];
			setRangeButtons(newButtons);
		}
		if (category === "age") {
			newButtons = [...ageButtons];
			newButtons[index] = !newButtons[index];
			setAgeButtons(newButtons);
		}
		if (category === "option") {
			newButtons = [...optionButtons];
			newButtons[index] = !newButtons[index];
			setOptionButtons(newButtons);
		}
		if (category === "preference") {
			newButtons = [...preferenceButtons];
			newButtons[index] = !newButtons[index];
			setPreferenceButtons(newButtons);
		}
	};

	const getPageNumbers = (): number[] => {
		const pageNumbers: number[] = [];
		const startPage = Math.max(1, 1 + maxVisiblePages * Math.floor((currentPage - 1) / maxVisiblePages));
		const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}
		return pageNumbers;
	};
	const pageNumbers = getPageNumbers();

	const handlePageChange = (page: number) => {
		if (page < 1) {
			setCurrentPage(1);
		} else if (page > totalPages) {
			setCurrentPage(totalPages);
		} else {
			setCurrentPage(page);
		}
	};

	const Images = ({ data }: any) => {
		const image = data?.CommunityClupFile?.find((v: any) => v.type === "PROMOTION")?.File.url;

		const leader = data?.participants?.find((participant: any) => participant.isGroupLeader);

		const leaderImage = leader && leader?.UserFiles?.length > 0 && leader?.UserFiles[0].File.url;

		// const period = calculateStayDays(data.createdAt, data.endedAt);
		const period = differenceInCalendarDays(new Date(data.endedAt), new Date(data.startedAt));
		const periodText = `${period}박 ${period + 1}일`;

		const startDate = format(new Date(data.startedAt), "yyyy.MM.dd");
		const endDate = format(new Date(data.endedAt), "yyyy.MM.dd");
		const statusText = data.status === "RECRUITMENT" ? "모집중" : "모집완료";

		return (
			<div
				onClick={() => (window.location.href = `/community/${data.id}/details`)}
				className="border borer-[#DADADA] rounded-[2px] shadow-[0_0_8px_0_#0E11110D] cursor-pointer">
				<div className="flex justify-between items-center p-[5px] box-border xl:pt-[10px] xl:pb-[16px] xl:ml-[5px] xl:mr-[9px] ">
					<div className="flex items-center gap-[12px]">
						<div
							className="rounded-full w-[21px] h-[21px] xl:w-[32px] overflow-hidden bg-cover bg-center"
							style={{
								backgroundImage: `url(${leaderImage})`,
							}}></div>
						<div className="text-[10px] xl:text-[14px] font-medium">{leader?.nickname || ""}</div>
					</div>
					<div className="flex gap-[5px] px-[4px] xl:px-[6px] text-white justify-between items-center bg-[#a870b1] xl:w-[58px] h-[21px] rounded-[2px]">
						{IconBook}
						<div className="text-[10px] xl:text-[12px] ">계획</div>
					</div>
				</div>

				<div className="w-full h-[186px] relative bg-cover" style={{ backgroundImage: `url(${image})` }}>
					<div className="absolute bg-[#355C7D] text-[10px] xl:text-[12px] px-[9px] py-[4px] left-[8px] top-[8px] text-[#F5F5F5] rounded-[2px]">
						{data.nation.slice(0, 8)} / {periodText}
					</div>
				</div>

				<div className="px-[8px]">
					<div className="text-[12px] xl:text-[20px] mt-[10px] xl:mt-[24px] font-bold line-clamp-2 break-keep text-[#202c4a]">{data.title}</div>
					<div className="text-[12px] xl:text-[18px] font-medium text-[#777777] mt-2.5 xl:mt-[21px] line-clamp-1">
						{data.numberOfRecruits}명 {statusText} {data.recruitGenderTypeText} {data.ageGroup}
					</div>
					<div className="text-[12px] xl:text-[18px] font-medium text-[#777777] mt-[4px]">
						{startDate} ~ {endDate}
					</div>
					<div className="text-[12px] xl:text-[18px] text-[#0057FF] font-bold mt-[4px]">
						{data?.participants?.length === data.numberOfRecruits ? '모집완료' : <span>{data?.participants?.length}명 / {data.numberOfRecruits}명 모집중</span>}
					</div>
				</div>

				<div className="flex justify-end gap-[8px] xl:gap-[16px] mt-[16px] mb-[10px] xl:mb-4 px-[8px]">
					<div className="flex gap-[5px] xl:gap-[8px]">
						<div>
							<img className="w-[16px] xl:w-[18px]" src={viewIcon}></img>
						</div>
						<div className="text-[10px] xl:text-[14px] font-medium">{data.viewCount}</div>
					</div>
					<div className="flex gap-[5px] xl:gap-[8px]">
						<div>
							<img className="w-[16px] xl:w-[18px]" src={commentIcon}></img>
						</div>
						<div className="text-[10px] xl:text-[14px] font-medium">{data.commentCount}</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="w-full flex flex-col items-center">
			<div className="communityRecruitWrapper max-w-[512px] xl:max-w-[1200px] px-[14px]">
				<div className="upperBanner w-full xl:text-[24px] font-bold">
					<img className="xl:w-[34px] mr-[8px]" src={club}></img>커뮤니티 클럽 - 계획편
					<img src={questionMark} alt="help" onClick={() => setShowOverlay(true)} style={{ cursor: "pointer" }} />
					{showOverlay && (
						<div className="overlay" style={{ maxWidth: "300px" }} onClick={() => setShowOverlay(false)}>
							<div className="overlay-inner">
								<p>
									- 여행일정 및 상품가가 미지정된 상품으로 모집인원이
									<br />
									함께 일정을 계획 및 견적 채택 후 출발하는 상품입니다
								</p>
								<p>
									- 커뮤니티 참여 요청 시 팩리더의 승인후 입장이 가능합니다.
									<br />
									(기존 모집하기부터 참여한 크루는 승인 없이 입장 가능)
								</p>
								<div style={{ color: "#999999" }}>이 창을 다시 눌러 닫습니다.</div>
							</div>
						</div>
					)}
				</div>
				<div className="flex justify-between  gap-[5px] mb-[20px] xl:mb-[32px] xl:gap-[24px]">
					<form className="flex-1 flex border border-[#DADADA] relative bg-white">
						<div className="flex justify-center items-center pl-3">{IconZoom}</div>

						<input
							className="outline-none h-[30px] bg-transparent flex items-center xl:h-[50px] px-[30px] xl:px-5 w-full text-[12px] xl:text-[18px] font-normal "
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							type="search"
							placeholder="Search"
							aria-label="검색"
						/>
					</form>
					<button className="border border-[#DADADA] w-[30px] xl:w-[50px] flex justify-center items-center bg-white" onClick={() => setShowFilter(!showFilter)}>
						<img className="w-[14px] xl:w-[34px]" src={filterIcon} />
					</button>
				</div>

				{showFilter && <CommunityClupFilter onChange={(v: any) => setFilter(v)} />}

				<div className="grid grid-cols-2 xl:grid-cols-4  gap-[23px]">
					{(sampleData ?? []).map((e: any) => {
						return <Images data={e} />;
					})}
				</div>

				<Pagination className="mt-8" page={currentPage} perPage={8} count={count} onChange={(v) => setCurrentPage(v)} />
			</div>
		</div>
	);
};

export default CommunityPlanning;
