import { differenceInCalendarDays, format } from "date-fns";
import { useMemo, useState } from "react";
import Modal from "../../modal";
import { FILE, PARTNER_QUOTATION } from "../../../api";

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
	</svg>
);

const IconReciept = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V8.5M9 9.5V8.5M9 9.5H11.0001M9 9.5C7.88279 9.49998 7.00244 9.62616 7.0001 10.8325C6.99834 11.7328 7.00009 12 9.00009 12C11.0001 12 11.0001 12.2055 11.0001 13.1667C11.0001 13.889 11 14.5 9 14.5M9 15.5V14.5M9 14.5L7.0001 14.5M14 10H17M14 20L16.025 19.595C16.2015 19.5597 16.2898 19.542 16.3721 19.5097C16.4452 19.4811 16.5147 19.4439 16.579 19.399C16.6516 19.3484 16.7152 19.2848 16.8426 19.1574L21 15C21.5523 14.4477 21.5523 13.5523 21 13C20.4477 12.4477 19.5523 12.4477 19 13L14.8426 17.1574C14.7152 17.2848 14.6516 17.3484 14.601 17.421C14.5561 17.4853 14.5189 17.5548 14.4903 17.6279C14.458 17.7102 14.4403 17.7985 14.405 17.975L14 20Z"
			stroke="#0E1111"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

interface Props {
	quotation: any;
	onPopupFinished: () => void;
}

export default function PartnerMyPagePartnerQuotationCard({ quotation, onPopupFinished }: Props) {
	const [modal, setModal] = useState<string | null>(null);
	const [file, setFile] = useState<any>(null);
	const order = useMemo(() => {
		return quotation.Order;
	}, [quotation]);

	const rangeText = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt))}박 ${
			differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt)) + 1
		}일`;
	}, [order]);

	const rangeText2 = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");
		return `${startedAt}~${endedAt}`;
	}, [order]);

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;

		// 파일을 읽고 미리보기 URL을 설정합니다.
		if (file) {
			FILE.upload(file).then(async (res: any) => {
				setFile(res);
				alert("업로드 완료");
			});
		}
	};

	// requestPayment
	const handlePaymentRequest = async () => {
		await PARTNER_QUOTATION.updateInvoice(quotation.id, file.id);
		alert("결제 요청");
		setModal(null);
		onPopupFinished();
	};

	const type = useMemo(() => {
		if (order.type === "RECRUITMENT") return "모집 주문";
		else if (order.type === "ALONE") return "단독 주문";
		else if (order.type === "BUSINESS_TRIP") return "출장 주문";
		return "";
	}, [order]);

	const date = useMemo(() => {
		const start = format(new Date(order.startedAt), "yyyy-MM-dd");
		const end = format(new Date(order.endedAt), "yyyy-MM-dd");
		return `${start} ~ ${end}`;
	}, []);

	return (
		<div className="border border-[#dadada] rounded-sm flex p-2.5 gap-3">
			<div
				className={["relative bg-cover bg-center flex-none w-[171px]", "h-[194px]"].join(" ")}
				style={{
					backgroundImage: `url(/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg)`,
				}}>
				<div className="absolute left-1 top-1 text-white font-medium text-[10px] px-2 py-0.5 rounded-sm bg-[#355c7d]">
					{order.nation}/{rangeText}
				</div>
				<div className="absolute text-white flex justify-center items-center py-1 w-full bg-[#202C4A] bg-opacity-70 left-0 bottom-0">
					{order.status === "QUOTATION_WAITING" && "답변서 대기중"}
					{order.status === "QUOTATION_COMPLETE" && <span className="text-[#F5B826]">답변서 작성완료</span>}
				</div>
			</div>
			<div className="flex-1 text-black text-[13px] font-medium pt-2 flex flex-col gap-1">
				<div className="truncate max-w-[142px]">여행 형태:&nbsp; {type}</div>
				<div className="flex justify-between pr-7 max-w-[142px] truncate">
					<div className="whitespace-nowrap">국가: {order.nation}</div>
					<div className="whitespace-nowrap">도시: {order.city}</div>
				</div>
				<div>
					{order.numberOfRecruits}명·{order.guideAvailability ? "가이드필요" : "가이드필요없음"}
				</div>
				<div className="truncate max-w-[142px]">
					{order.accommodation}·{order.transportation}
				</div>
				<div className="tracking-[-1px]">{date}</div>
				<div>
					{quotation.invoiceStatus === "WATING" && (
						<button onClick={() => setModal("INVOICE")} className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center">
							인보이스 업로드
						</button>
					)}
					{quotation.invoiceStatus === "UPLOAD_COMPLETED" && (
						<button onClick={() => setModal("INVOICE")} className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center">
							인보이스 업로드 완료
						</button>
					)}
					{quotation.invoiceStatus === "CHECKING" && (
						<button className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center">인보이스 확인중</button>
					)}
					{quotation.invoiceStatus === "COMPLETED" && (
						<button className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center">인보이스 완료</button>
					)}
					{quotation.invoiceStatus === "REJECTED" && (
						<button onClick={() => setModal("INVOICE")} className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center">
							인보이스 반료
						</button>
					)}
				</div>
				<div className="mt-1">
					<button
						onClick={(event) => {
							window.location.href = `/partner/my/create-quotation?order_id=${order.id}`;
						}}
						className="bg-white border border-[#dadada] w-full h-[30px] text-[#777] text-sm flex justify-center items-center">
						주문서 상세보기
					</button>
				</div>
			</div>
			{modal === "INVOICE" && (
				<Modal open={true} onClose={() => setModal(null)}>
					<div className="flex justify-end">
						{/* TODO 주문서로 이동해야하는데 정웅이 개발하는 곳에 같은 페이지가 있어서 해당페이지개발여부 보고 작업 */}
						<div onClick={() => setModal(null)}>{IconClose}</div>
					</div>
					<div
						className="flex mt-2.5 justify-end text-[10px] text-success font-bold cursor-pointer"
						onClick={(event) => {
							window.location.href = `/partner/my/create-quotation?order_id=${order.id}`;
						}}>
						주문서 보기
					</div>
					<div className="flex gap-1.5 items-center">
						<div>{IconReciept}</div>
						<div>
							<div className="text-xs text-black font-medium">
								{order.type === "RECRUITMENT" && " 모집 주문"}
								{order.type === "ALONE" && " 단독 주문"}
								{order.type === "BUSINESS_TRIP" && " 출장 주문"}
							</div>
							<div className="text-black font-bold truncate w-64">{order.title}</div>
						</div>
					</div>
					<div className="border-b border-[#dadada] my-5"></div>
					<div
						className="h-[135px] bg-cover bg-center"
						style={{
							backgroundImage: `url(/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg)`,
						}}></div>
					<div className="grid grid-cols-2 gap-5 mt-5">
						<div className="text-black text-sm font-medium truncate">국가:{order.nation}</div>
						<div className="text-black text-sm font-medium truncate">도시:{order.city}</div>
						<div className="text-black text-sm font-medium truncate">인원:{order.numberOfRecruits}</div>
						<div className="text-black text-sm font-medium truncate">가이드여부:{order.guideAvailability ? "있음" : "없음"}</div>
						<div className="text-black text-sm font-medium truncate">숙박:{order.accommodation}</div>
						<div className="text-black text-sm font-medium truncate">교통:{order.transportation}</div>
						<div className="text-black text-sm font-medium col-span-2">여행기간:{rangeText2}</div>
					</div>

					{file && (
						<div
							className="w-full h-40 bg-center bg-cover mt-4"
							style={{
								backgroundImage: `url(${file.url})`,
							}}></div>
					)}

					<div className="mt-7">
						{quotation.invoiceFileId ? (
							<a
								href={quotation.invoiceFile.url}
								target="_blank"
								className="bg-white border border-[#dadada] w-full h-[30px] text-[#777] text-sm flex justify-center items-center"
								rel="noreferrer">
								인보이스 파일 확인하기
							</a>
						) : (
							<label className="bg-white border border-[#dadada] w-full h-[30px] text-[#777] text-sm flex justify-center items-center">
								인보이스 업로드
								<input type="file" className="hidden" onChange={handleImageChange} />
							</label>
						)}
					</div>
					{quotation.invoiceFileId ? null : (
						<div className="mt-2.5">
							<button onClick={handlePaymentRequest} className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center">
								결제 요청하기
							</button>
						</div>
					)}
				</Modal>
			)}
		</div>
	);
}
