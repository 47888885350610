import SvgIcon from "../components/SvgIcon";
import voteIcon from "../image/vote.svg";
import closeIcon from "../image/close.svg";
import plusIcon from "../image/plus.svg";
import clockIcon from "../image/clock.svg";
import textalignIcon from "../image/textalign-left.svg";

import { useEffect, useState } from "react";
import { EventNames } from "@toast-ui/react-editor";
import { options } from "@fullcalendar/core/preact";
import { CHATROOMS } from "../api";
import { useParams } from "react-router-dom";
import { addWeeks, parseISO } from "date-fns";

export default function CreateVote() {
	const { chat_room_id } = useParams();

	const [vote, setVote] = useState({
		chatRoomId: Number(chat_room_id),
		title: "",
		options: ["", "", ""],
		description: "",
		endedAt: addWeeks(new Date(), 1).toISOString().substring(0, 16),
		isAllowMultiple: false,
		isAnonymous: false,
	});

	const isActivate = () => {
		if (vote.title.length === 0) {
			return false;
		}

		if (vote.options.filter((v) => v !== "").length === 0) {
			return false;
		}

		if (vote.description.length === 0) {
			return false;
		}

		return true;
	};

	const handleInput = (key: string, evt: any) => {
		setVote({
			...vote,
			[key]: evt.target.value,
		});
	};

	const submit = () => {
		const data: any = vote;
		const options = data.options.filter((v: any) => v !== "");

		const optionsSet = new Set(options);
		// @ts-ignore
		const optionsArray = [...optionsSet];
		if (optionsArray.length !== options.length) {
			alert("중복된 옵션이 있습니다.");
			return;
		}

		data.endedAt = parseISO(data.endedAt);
		CHATROOMS.createCommunityClupVote(chat_room_id, vote).then((res: any) => {
			window.history.back();
		});
	};

	const VoteList = ({ value, idx }: any) => {
		return (
			<div className="border border-[#DADADA] h-[40px] rounded-[10px] flex px-[20px] py-[10px] items-center gap-[15px]">
				<input
					className="text-[14px] font-medium bg-[#F6F7F9] w-full"
					placeholder="항목을 입력해주세요."
					defaultValue={value}
					onBlur={(v) => {
						const option = vote.options;
						option.splice(idx, 1, v.target.value);
						setVote({
							...vote,
							options: option,
						});
					}}></input>
			</div>
		);
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="px-[10px] py-[20px]">
					<div className="border-b pb-[20px] border-[#999999]">
						<div className="flex gap-[30px]">
							<input
								className="text-[16px] flex-1 text-[#0E1111] font-bold bg-[#F6F7F9]"
								value={vote.title}
								placeholder="투표 제목"
								onChange={(v: any) => {
									handleInput("title", v);
								}}></input>
							<div onClick={() => window.history.back()}>
								<SvgIcon className="w-[18px] h-[18px] bg-black" icon={closeIcon} />
							</div>
						</div>
					</div>
					<div></div>

					<div className="px-[10px] pt-[20px] pb-[30px]  bo">
						<div className="flex flex-col gap-[10px] mb-[20px]">
							{vote.options.map((v: any, i: any) => {
								return <VoteList value={vote.options[i]} idx={i}></VoteList>;
							})}
							<div
								className="border border-[#DADADA] h-[40px] rounded-[10px] flex px-[20px] py-[10px] items-center justify-center gap-[10px] text-[14px]"
								onClick={() => {
									const option = vote.options;
									option.push("");
									setVote({
										...vote,
										options: option,
									});
								}}>
								<SvgIcon className="w-[16px] aspect-square bg-black" icon={plusIcon} />
								항목 추가하기
							</div>
						</div>

						<hr />

						<div className="flex gap-[20px] py-[20px]">
							<SvgIcon className="w-[22px] h-[22px] bg-black" icon={textalignIcon} />
							<textarea
								className="text-[16px] flex-1 text-[#0E1111] font-bold bg-[#F6F7F9] break-keep resize-none"
								value={vote.description}
								placeholder="추가 설명"
								onChange={(v: any) => {
									handleInput("description", v);
								}}></textarea>
						</div>

						<hr />

						<div className="flex gap-[20px] py-[20px] border-b">
							<SvgIcon className="w-[22px] h-[22px] bg-black" icon={clockIcon} />
							<input
								type="datetime-local"
								className="text-[16px] flex-1 text-[#0E1111] font-bold bg-[#F6F7F9] break-keep resize-none"
								value={vote.endedAt}
								placeholder="추가 설명"
								onChange={(v: any) => {
									handleInput("endedAt", v);
								}}></input>
						</div>

						<div className="flex gap-[20px] py-[20px] justify-between border-b">
							<div>중복선택</div>
							<div className="flex gap-[20px]">
								<div
									className="flex gap-[5px]"
									onClick={() => {
										setVote({
											...vote,
											isAllowMultiple: true,
										});
									}}>
									<input type="radio" id="isAllowMultiple" checked={vote.isAllowMultiple}></input>
									<div>가능</div>
								</div>
								<div
									className="flex gap-[5px]"
									onClick={() => {
										setVote({
											...vote,
											isAllowMultiple: false,
										});
									}}>
									<input type="radio" id="isAllowMultiple" checked={!vote.isAllowMultiple}></input>
									<div>불가능</div>
								</div>
							</div>
						</div>

						<div className="flex gap-[20px] py-[20px] justify-between border-b">
							<div>익명투표</div>
							<div className="flex gap-[20px]">
								<div
									className="flex gap-[5px]"
									onClick={() => {
										setVote({
											...vote,
											isAnonymous: true,
										});
									}}>
									<input type="radio" id="isAllowMultiple" checked={vote.isAnonymous}></input>
									<div>가능</div>
								</div>
								<div
									className="flex gap-[5px]"
									onClick={() => {
										setVote({
											...vote,
											isAnonymous: false,
										});
									}}>
									<input type="radio" id="isAllowMultiple" checked={!vote.isAnonymous}></input>
									<div>불가능</div>
								</div>
							</div>
						</div>

						<div
							className={[
								"w-full mt-[30px] h-[40px] border  flex justify-center items-center text-[16px] font-bold",
								isActivate() ? "text-success border-success" : "border-[#DADADA] text-[#777]",
							].join(" ")}
							onClick={() => {
								submit();
							}}>
							투표 등록하기
						</div>
					</div>
				</div>
				<hr />
			</div>
		</div>
	);
}
