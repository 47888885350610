// @ts-nocheck

import React, { useRef, useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";

interface Poi {
	lat: number;
	lng: number;
}

export default function GoogleMapWithPoi({ location, pois, className = "" }: { location: string; pois?: Poi[]; className?: string }) {
	const googleMapRef = useRef(null);
	const googleMap = useRef(null);

	const [markers, setMarkers] = useState<any>([]);
	const [polylines, setPolylines] = useState<any>([]);

	// 지도를 초기화합니다. 기본 위치로 서울을 설정합니다.
	useEffect(() => {
		googleMap.current = new window.google.maps.Map(googleMapRef.current, {
			zoom: 5,
			center: { lat: 37.5665, lng: 126.978 },
			disableDefaultUI: true,
		});
	}, []);

	useEffect(() => {
		if (pois && googleMap.current) {
			clearMarkers();
			pois.forEach((poi, index) => {
				const position = new window.google.maps.LatLng(poi.lat, poi.lng);
				const customMarker = new CustomMarker(position, index + 1);
				customMarker.setMap(googleMap.current);
				setMarkers((markers) => [...markers, customMarker]);
			});

			if (pois && pois.length > 0) {
				drawPoiLine(pois);
			}
		}
	}, [pois]);

	const drawPoiLine = useCallback((pois: Poi[]) => {
		const poiPath = pois.map((poi) => new window.google.maps.LatLng(poi.lat, poi.lng));
		const poiLine = new window.google.maps.Polyline({
			path: poiPath,
			geodesic: true,
			strokeColor: "#0057FF",
			strokeOpacity: 1.0,
			strokeWeight: 2,
		});

		poiLine.setMap(googleMap.current);
	}, []);

	const clearMarkers = () => {
		markers.forEach((marker) => {
			marker.setMap(null);
		});
		setMarkers([]);

		polylines.forEach((polyline) => {
			polyline.setMap(null);
		});
		setPolylines([]);
	};

	// 위치로 지도의 위치를 변경하는 함수입니다.
	const moveToLocation = async (query) => {
		try {
			const { location, zoomLevel } = await getCoordinates(query);
			googleMap.current.panTo(new window.google.maps.LatLng(location.lat, location.lng));
			googleMap.current.setZoom(zoomLevel);
		} catch (error) {
			console.error("Error finding location: ", error);
		}
	};

	const debouncedMoveToLocation = useCallback(debounce(moveToLocation, 500), []);

	useEffect(() => {
		if (location) {
			debouncedMoveToLocation(location);
		}
	}, [location, debouncedMoveToLocation]);

	return (
		<div className={className}>
			<div ref={googleMapRef} className="w-full h-full" />
		</div>
	);
}

// 주소로 좌표를 검색하는 함수입니다.
const getCoordinates = async (query) => {
	const response = await fetch(
		// TODO 키 교체
		// `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=AIzaSyD5eK-zT8fqMv4P7yHa_XG7sygt6KSIwVY`
		`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o`
	);
	const data = await response.json();
	if (data.results.length > 0) {
		const location = data.results[0].geometry.location;
		const types = data.results[0].types;
		const zoomLevel = determineZoomLevel(types);
		return { location, zoomLevel };
	} else {
		throw new Error("Location not found", query);
	}
};

// 타입 배열을 검사하여 줌 레벨을 결정하는 함수입니다.
const determineZoomLevel = (types) => {
	if (types.includes("locality")) {
		return 10; // 도시 레벨
	} else if (types.includes("sublocality") || types.includes("neighborhood")) {
		return 13; // 구 또는 동네 레벨
	} else if (types.includes("route")) {
		return 15; // 도로 레벨
	} else if (types.includes("street_address")) {
		return 17; // 구체적인 주소 레벨
	}
	return 8; // 기본 줌 레벨
};

class CustomMarker extends window.google.maps.OverlayView {
	private pos: google.maps.LatLng;
	private div: HTMLElement | null = null;
	private index: number;

	constructor(position: google.maps.LatLng, index: number) {
		super();
		this.pos = position;
		this.index = index;
	}

	onAdd() {
		const div = document.createElement("div");
		div.style.position = "absolute";
		div.style.userSelect = "none";
		div.style.width = "24px"; // Adjust the size as needed
		div.style.height = "24px"; // Adjust the size as needed
		div.style.backgroundColor = "#fff"; // Background color
		div.style.border = "2px solid #0057FF"; // Border color
		div.style.borderRadius = "50%"; // Circular shape
		div.style.display = "flex";
		div.style.alignItems = "center";
		div.style.justifyContent = "center";
		div.style.color = "#0057FF"; // Text color
		div.style.fontSize = "15px"; // Text size
		div.innerHTML = this.index.toString();

		this.div = div;

		const panes = this.getPanes();
		panes.overlayMouseTarget.appendChild(div);
	}

	draw() {
		const overlayProjection = this.getProjection();
		const position = overlayProjection.fromLatLngToDivPixel(this.pos);

		if (this.div) {
			this.div.style.left = `${position.x - 20}px`; // Adjust the position x offset
			this.div.style.top = `${position.y - 20}px`; // Adjust the position y offset
		}
	}

	onRemove() {
		if (this.div) {
			(this.div.parentNode as HTMLElement).removeChild(this.div);
			this.div = null;
		}
	}
}
