import { useEffect, useMemo, useState } from "react";
import { DEFAULT, NOTICE } from "../api";
import { format, set } from "date-fns";

const IconNext = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M6.71289 3.06L11.6029 7.95C12.1804 8.5275 12.1804 9.4725 11.6029 10.05L6.71289 14.94"
			stroke="#0E1111"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export default function Notice() {
	const [notices, setNotices] = useState<any>([]);
	const [tabManues, setTabManues] = useState<any>(["전체"]);

	useEffect(() => {
		NOTICE.getMany().then((res: any) => {
			setNotices(res);
		});
		DEFAULT.getServiceConfig().then((res) => {
			setTabManues(["전체", ...res.noticeCategories]);
		});
	}, []);

	const [tab, setTab] = useState<any>("전체");

	const [selectedNotice, setSelectedNotice] = useState<any>(null);
	const handleClickNotice = (id: any) => {
		if (selectedNotice === id) {
			setSelectedNotice(null);
			return;
		}
		setSelectedNotice(id);
	};

	const filtered = useMemo(() => {
		if (tab === "전체") return notices;
		return notices.filter((notice: any) => notice.type === tab);
	}, [tab, notices]);

	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full">
				<div className="text-black text-2xl font-bold my-12 text-center">공지사항</div>

				<div className="px-3 flex gap-4 shadow-xl">
					{tabManues.map((_tab: any) => {
						const selected = tab === _tab;
						return (
							<div
								onClick={() => setTab(_tab)}
								className={[
									"transition-all w-14 py-3.5 text-xs font-medium text-center cursor-pointer",
									selected ? "border-b border-success text-success" : "text-black",
								].join(" ")}>
								{_tab}
							</div>
						);
					})}
				</div>

				<div className="flex flex-col gap-4 mt-8 px-3">
					{filtered.map((notice: any) => {
						const date = format(new Date(notice.createdAt), "yyyy.MM.dd");
						const selected = selectedNotice === notice.id;
						return (
							<div className="flex flex-col border border-[#dadada] rounded-2xl">
								<div onClick={() => handleClickNotice(notice.id)} className="flex justify-between py-4 px-5 items-center gap-8 cursor-pointer">
									<div>
										<div className="text-black font-medium line-clamp-1">{notice.title}</div>
										<div className="text-sm text-[#999] font-medium">{date}</div>
									</div>
									<div className={["transition-all", selected ? "rotate-90" : ""].join(" ")}>{IconNext}</div>
								</div>

								{selected && (
									<div
										className="text-[#777] w-full text-sm font-medium px-5 pb-4 whitespace-pre-line break-all box-border"
										dangerouslySetInnerHTML={{ __html: notice.content }}></div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
