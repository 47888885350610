import { useState } from "react";

interface Props {
	list: {
		label: string;
		value: string | boolean;
	}[];
	value: string | boolean;
	subValue: string;
	onChange: (value: string | boolean) => void;
	onChangeDesc: (value: string) => void;
	contentAlign?: "justify-around" | "justify-start";
	className?: string;
}
export default function InputListAndText({ list, value, subValue, onChange, onChangeDesc, className = "", contentAlign = "justify-around" }: Props) {
	const handleChange = (newValue: string | boolean) => {
		setIsCustom(false);
		onChange(newValue);
	};

	const [isCustom, setIsCustom] = useState(false);

	return (
		<div className={className}>
			<div className={["flex w-full", contentAlign, contentAlign === "justify-start" && "gap-3"].join(" ")}>
				{list.map((item) => {
					const isSelected = value === item.value;
					return (
						<div
							className={`min-w-[30px] whitespace-nowrap  justify-center transition-all text-[14px] font-normal font-spoqa tracking-[-0.32px] flex rounded-[2px] items-center py-[8px] px-[5px] gap-2 border ${
								isSelected ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0e1111]"
							} ${contentAlign === "justify-start" ? "w-16" : ""} cursor-pointer `}
							onClick={() => handleChange(item.value)}>
							{/* 숨겨진 라디오 버튼 */}
							<input
								className="hidden" // Tailwind를 사용하여 라디오 버튼 숨기기
								type="radio"
								name="radio"
								checked={isSelected}
								onChange={() => handleChange(item.value)}
							/>
							<label className="text-[14px] font-medium tracking-[-0.32px] font-spoqa cursor-pointer">{item.label}</label>
						</div>
					);
				})}
			</div>
			<div className="mt-[10px] flex">
				<div
					className={[
						"w-[80px] justify-center items-center flex rounded-tl-[2px] rounded-bl-[2px] border-l border-t border-b  border-[#DADADA] text-[#0e1111] text-[14px] font-normal tracking-[-0.32px] font-spoqa ",
						isCustom && "border-[#0057FF] text-[#0057FF]",
					].join(" ")}>
					기타
				</div>
				<textarea
					defaultValue={subValue}
					className={[
						"bg-transparent py-[6px] px-[7px] w-full min-h-[100px] resize-none rounded-tr-[2px] rounded-br-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] font-spoqa ",
						isCustom && "border-[#0057FF] text-[#0057FF]",
					].join(" ")}
					onChange={(e) => onChangeDesc(e.target.value)}
				/>
			</div>
		</div>
	);
}
