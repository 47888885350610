import { useState } from "react";

export default function InputTextareaAndAGree({ className, text, onChange = () => {} }: any) {
	const [agree, setAgree] = useState(false);

	
	function f_agree () {
		setAgree(!agree)
		onChange(!agree)
	}

	return (
		<div className="mt-[10px]  xl:mt-[16px]">
			<div className="px-3 mt-[5px]">
				<textarea
					disabled
					className="w-full font-normal border-[#dadada] border rounded-[2px] font-spoqa h-[128px] p-[8px] text-[11px] text-[#425449]"
					cols={5}
					value={text}
				/>
			</div>
			<div className="px-3 flex justify-end gap-2 xl:mt-[16px]" onClick={() => {f_agree()}}>
				<div className={["border rounded-[4px] xl:w-[24px] xl:h-[24px] flex justify-center items-center cursor-pointer", agree && "border-[#0057FF]"].join(" ")}>
					<svg
						className={["relative top-[1px]", agree ? "opacity-100" : "opacity-0"].join(" ")}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.0791 3.08687C12.307 3.31468 12.307 3.68402 12.0791 3.91183L5.66248 10.3285C5.43467 10.5563 5.06533 10.5563 4.83752 10.3285L1.92085 7.41183C1.69305 7.18402 1.69305 6.81468 1.92085 6.58687C2.14866 6.35906 2.51801 6.35906 2.74581 6.58687L5.25 9.09106L11.2542 3.08687C11.482 2.85906 11.8513 2.85906 12.0791 3.08687Z"
							fill="#0057FF"
						/>
					</svg>
				</div>
				<span className="text-[12px] font-medium font-spoqa text-[#0E1111] xl:text-[18px] cursor-pointer ">확인했습니다.</span>
			</div>
		</div>
	);
}
