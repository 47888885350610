import { useEffect, useMemo, useRef, useState } from "react";
import { DEFAULT, FAQ, NOTICE } from "../api";
import { format, set } from "date-fns";

const IconNext = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M6.71289 3.06L11.6029 7.95C12.1804 8.5275 12.1804 9.4725 11.6029 10.05L6.71289 14.94"
			stroke="#0E1111"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconMessagae = (
	<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
		<path
			d="M46.666 28.4667V32.4334C46.666 39.7834 42.466 42.9334 36.166 42.9334H15.166C8.86602 42.9334 4.66602 39.7834 4.66602 32.4334V19.8333C4.66602 12.4833 8.86602 9.33333 15.166 9.33333H21.466C21.1627 10.22 20.9993 11.2 20.9993 12.25V21.3501C20.9993 23.6134 21.746 25.5267 23.076 26.8567C24.406 28.1867 26.3193 28.9334 28.5827 28.9334V32.1766C28.5827 33.3666 29.936 34.09 30.9393 33.4367L37.6827 28.9334H43.7493C44.7993 28.9334 45.7793 28.77 46.666 28.4667Z"
			fill="#F5F5F5"
		/>
		<path
			opacity="0.4"
			d="M51.3333 12.25V21.3501C51.3333 24.8267 49.56 27.44 46.6667 28.4667C45.78 28.77 44.8 28.9334 43.75 28.9334H37.6833L30.94 33.4367C29.9367 34.09 28.5833 33.3666 28.5833 32.1766V28.9334C26.32 28.9334 24.4067 28.1867 23.0767 26.8567C21.7467 25.5267 21 23.6134 21 21.3501V12.25C21 11.2 21.1633 10.22 21.4667 9.33334C22.4933 6.44 25.1067 4.66667 28.5833 4.66667H43.75C48.3 4.66667 51.3333 7.7 51.3333 12.25Z"
			fill="#F5F5F5"
		/>
		<path
			opacity="0.4"
			d="M34.0676 49.5833H27.4176V42.9334C27.4176 41.9767 26.6242 41.1834 25.6676 41.1834C24.7109 41.1834 23.9176 41.9767 23.9176 42.9334V49.5833H17.2676C16.3109 49.5833 15.5176 50.3767 15.5176 51.3333C15.5176 52.29 16.3109 53.0833 17.2676 53.0833H34.0676C35.0242 53.0833 35.8176 52.29 35.8176 51.3333C35.8176 50.3767 35.0242 49.5833 34.0676 49.5833Z"
			fill="#F5F5F5"
		/>
		<path
			d="M36.0273 18.6667C35.0473 18.6667 34.2773 17.8733 34.2773 16.9167C34.2773 15.96 35.0707 15.1667 36.0273 15.1667C36.984 15.1667 37.7773 15.96 37.7773 16.9167C37.7773 17.8733 36.984 18.6667 36.0273 18.6667Z"
			fill="#F5F5F5"
		/>
		<path
			d="M42.584 18.6667C41.604 18.6667 40.834 17.8733 40.834 16.9167C40.834 15.96 41.6273 15.1667 42.584 15.1667C43.5407 15.1667 44.334 15.96 44.334 16.9167C44.334 17.8733 43.5407 18.6667 42.584 18.6667Z"
			fill="#F5F5F5"
		/>
		<path
			d="M29.4473 18.6667C28.4673 18.6667 27.6973 17.8733 27.6973 16.9167C27.6973 15.96 28.4906 15.1667 29.4473 15.1667C30.4039 15.1667 31.1973 15.96 31.1973 16.9167C31.1973 17.8733 30.4273 18.6667 29.4473 18.6667Z"
			fill="#F5F5F5"
		/>
	</svg>
);

const IconArrow = (
	<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
		<path
			d="M15.3224 13.0296L15.3224 7.30677L9.59951 7.30677"
			stroke="#F5F5F5"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M7.30845 15.3206L15.2422 7.3869" stroke="#F5F5F5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconSearch = (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
		<path
			d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
			stroke="#999999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M13.75 13.75L12.5 12.5" stroke="#999999" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

export default function Notice() {
	const [faqs, setFaqs] = useState<any>([]);
	const [tabMenues, setTabMenues] = useState<any>(["전체"]);

	useEffect(() => {
		FAQ.getMany().then((res: any) => {
			setFaqs(res);
		});
		DEFAULT.getServiceConfig().then((res) => {
			setTabMenues(["전체", ...res.faqCategories]);
		});
	}, []);

	const [tab, setTab] = useState<any>("전체");

	const [selectedNotice, setSelectedNotice] = useState<any>(null);
	const handleClickNotice = (id: any) => {
		if (selectedNotice === id) {
			setSelectedNotice(null);
			return;
		}
		setSelectedNotice(id);
	};

	const [search, setSearch] = useState<any>("");

	const filtered = useMemo(() => {
		if (search && search.length > 0) {
			return faqs.filter((faq: any) => faq.title.includes(search) || faq.content.includes(search));
		}

		if (tab === "전체") return faqs;

		if (tab) {
			return faqs.filter((e: any) => e.type === tab);
		}

		// if (tab === "전체") return faqs;
		// if (tab === "커뮤니티관련") return faqs.filter((faq: any) => faq.type === "COMMUNITY_CLUP");
		// if (tab === "주문 관련") return faqs.filter((faq: any) => faq.type === "ORDER");
		// if (tab === "모집관련") return faqs.filter((faq: any) => faq.type === "RECRUITMENT");
		// if (tab === "결제관련") return faqs.filter((faq: any) => faq.type === "PAYMENT");
		// if (tab === "제휴서비스 관련") return faqs.filter((faq: any) => faq.type === "FRANCHISE");
		// if (tab === "기타") return faqs.filter((faq: any) => faq.type === "ETC");

		return faqs;
	}, [tab, faqs, search]);

	const toHTML = (text: any) => {
		return { __html: text.replace(/(?:\r\n|\r|\n)/g, "<br>") };
	};
	const scrollRef: any = useRef();
	const [isDrag, setIsDrag]: any = useState(false);
	const [startX, setStartX]: any = useState();

	const onDragStart = (e: any) => {
		e.preventDefault();
		setIsDrag(true);
		setStartX(e.pageX + scrollRef.current.scrollLeft);
	};
	const onDragEnd = () => {
		setIsDrag(false);
	};
	const onDragMove = (e: any) => {
		if (isDrag) {
			const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;

			scrollRef.current.scrollLeft = startX - e.pageX;

			if (scrollLeft === 0) {
				setStartX(e.pageX);
			} else if (scrollWidth <= clientWidth + scrollLeft) {
				setStartX(e.pageX + scrollLeft);
			}
		}
	};

	const throttle = (func: any, ms: any) => {
		let throttled = false;
		return (...args: any) => {
			if (!throttled) {
				throttled = true;
				setTimeout(() => {
					func(...args);
					throttled = false;
				}, ms);
			}
		};
	};

	const delay = 10;
	const onThrottleDragMove = throttle(onDragMove, delay);

	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full">
				<div className="text-black text-2xl font-bold mt-12 text-center">자주 묻는 질문</div>
				<div className="text-center text-sm font-medium mt-2.5 mb-5">
					찾는 내용이 없을 경우,
					<br />
					카카오톡 채널 또는 1:1 문의하기를 통해 문의해 주세요.
				</div>
				<div className="px-3">
					<div
						className="bg-success rounded-2xl flex justify-between p-5 items-center cursor-pointer"
						onClick={() => window.open("http://pf.kakao.com/_bLUZG/chat", "__blank")}>
						<div className="flex flex-col">
							<div className="text-white text-sm font-medium">찾는 질문이 없다면? 실시간 상담</div>
							<div className="text-white font-bold mt-2">
								오더메이드트립
								<br />
								<span className="flex">카카오톡 채널 바로가기{IconArrow}</span>
							</div>
						</div>
						<div>{IconMessagae}</div>
					</div>
				</div>

				<div className="px-3 mt-5">
					<div className="border border-[#dadada] gap-1 items-center rounded-[20px] py-3 px-5 flex  text-sm font-medium">
						{IconSearch}{" "}
						<input
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							className="w-full placeholder:text-[#999] outline-none"
							placeholder="궁금하신 내용을 검색해 주세요."
						/>
					</div>
				</div>

				<div
					className="mt-5 px-3 flex gap-4 shadow-lg overflow-x-scroll flex-nowrap scrollbar-hide"
					onMouseDown={onDragStart}
					onMouseMove={isDrag ? onThrottleDragMove : () => {}}
					onMouseUp={onDragEnd}
					onMouseLeave={onDragEnd}
					ref={scrollRef}>
					{tabMenues.map((_tab: any) => {
						const selected = tab === _tab;
						return (
							<div
								onClick={() => setTab(_tab)}
								className={[
									"flex-none transition-all w-20 py-3.5 text-xs font-medium text-center cursor-pointer",
									selected ? "border-b border-success text-success" : "text-black",
								].join(" ")}>
								{_tab}
							</div>
						);
					})}
				</div>

				<div className="flex flex-col gap-4 mt-8 px-3">
					{filtered.map((notice: any) => {
						const date = format(new Date(notice.createdAt), "yyyy.MM.dd");
						const selected = selectedNotice === notice.id;
						return (
							<div className="flex flex-col border border-[#dadada] rounded-2xl">
								<div onClick={() => handleClickNotice(notice.id)} className="flex justify-between py-4 px-5 items-center gap-8 cursor-pointer">
									<div>
										<div className="text-black font-medium line-clamp-1">{notice.title}</div>
										<div className="text-sm text-[#999] font-medium">{date}</div>
									</div>
									<div className={["transition-all", selected ? "rotate-90" : ""].join(" ")}>{IconNext}</div>
								</div>

								{selected && <div dangerouslySetInnerHTML={toHTML(notice.content)} className="text-[#777] text-sm font-medium px-5 pb-4"></div>}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
