import mammoth from "mammoth";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import "./mem_customerRegister.scss";
import okLogo from "../image/o.svg";
import noLogo from "../image/x.svg";
import InputLabel from "../components/input/labe";
import InputEmail from "../components/input/email";
import InputText from "../components/input/text";
import InputPassword from "../components/input/password";
import InputBirthday from "../components/input/birthday";
import InputList from "../components/input/list";
import InputListFlex1 from "../components/input/list-flex-1";
import InputListCol2 from "../components/input/list-col-2-multi";
import InputCheckbox from "../components/input/checkbox";
import { AUTH, DEFAULT, USER } from "../api";
// @ts-ignore
import { debounce } from "lodash";
import { emailDomainOptions } from "../contants";
import Modal from "../components/modal";

function parseDate(dateStr: string) {
	// 'YYYYMMDD' 형태의 문자열을 'YYYY-MM-DD'로 변환
	return dateStr.replace(/^(\d{4})(\d{2})(\d{2})$/, (match, year, month, day) => `${year}-${month}-${day}`);
}

function formatPhoneNumber(phoneNumber: string) {
	// 전화번호를 'XXX-XXXX-XXXX' 형식으로 변환합니다.
	var cleaned = ("" + phoneNumber).replace(/\D/g, ""); // 숫자가 아닌 문자를 제거합니다.
	var match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/); // 정규표현식으로 그룹을 나눕니다.
	if (match) {
		return [match[1], match[2], match[3]].join("-"); // 나눈 그룹을 '-'로 연결합니다.
	}
	return null; // 형식에 맞지 않는 번호인 경우 null을 반환합니다.
}

interface NewUserData {
	oauthType: string;
	oauthId: string;
	email: string;
	nickname: string;
	password: string;
	confirmPassword?: string; // 전송하면 안 됨
	name: string;
	birthday: string;
	gender: string;
	phone: string;
	tripInfo: string[];
	info: string[];
	mbti: string;
	termsOfServiceAgreement?: boolean | null;
	privacyPolicyAgreement?: boolean | null;
	marketingAgreement?: boolean | null;
	ageAgreement?: boolean | null;
}

const CustomerRegister = () => {
	const [modal, setModal] = useState("");

	const [terms, setTerms] = useState({
		privacy: "",
		termsOfPolicy: "",
	});

	const [serviceConfig, setServiceConfig] = useState<any>(null);

	const getTerms = async () => {
		const docFilePath = "/policies/B2C_개인정보처리방침_1차.docx";
		const privacy = await fetch(docFilePath)
			.then((response) => response.arrayBuffer())
			.then((arrayBuffer) =>
				mammoth.convertToHtml({ arrayBuffer: arrayBuffer }).catch((err) => {
					console.error(err);
				})
			)
			.catch((err) => {
				console.error("Failed to fetch the docx file", err);
			});
		const docFilePath2 = "/policies/B2C_이용약관_1차.docx";

		const terms = await fetch(docFilePath2)
			.then((response) => response.arrayBuffer())
			.then((arrayBuffer) =>
				mammoth.convertToHtml({ arrayBuffer: arrayBuffer }).catch((err) => {
					console.error(err);
				})
			)
			.catch((err) => {
				console.error("Failed to fetch the docx file", err);
			});

		setTerms({
			privacy: privacy?.value ?? "",
			termsOfPolicy: terms?.value ?? "",
		});
	};

	useEffect(() => {
		getTerms();

		DEFAULT.getServiceConfig().then((res) => {
			console.log(res);
			setServiceConfig(res);
		});
	}, []);

	const [newUserData, setNewUserData] = useState<NewUserData>({
		oauthType: "NORMAL",
		oauthId: "",
		email: "",
		nickname: "",
		password: "",
		confirmPassword: "", // 전송하면 안 됨
		name: "",
		birthday: "",
		gender: "",
		phone: "",
		tripInfo: [],
		info: [],
		mbti: "INTJ",
		termsOfServiceAgreement: false,
		privacyPolicyAgreement: false,
		marketingAgreement: false,
		ageAgreement: false,
	});

	const [errors, setErrors] = useState({
		email: "",
		nickname: "",
	});

	const [disableds, setDisableds] = useState({
		email: false,
		phone: false,
		userName: false,
		userBirthday: false,
	});

	// 중복검사 로직
	useEffect(() => {
		debounce(() => {
			USER.checkEmail(newUserData.email)
				.then((response) => {
					return setErrors({
						...errors,
						email: "",
					});
				})
				.catch((e) => {
					if (e.response.status === 409) {
						return setErrors({
							...errors,
							email: "이미 사용중인 이메일입니다.",
						});
					}
				});
		}, 500)();
	}, [newUserData.email]);
	// 중복검사 로직
	useEffect(() => {
		debounce(
			() =>
				USER.checkNickname(newUserData.nickname)
					.then((response) => {
						return setErrors({
							...errors,
							nickname: "",
						});
					})
					.catch((e) => {
						if (e.response.status === 409) {
							return setErrors({
								...errors,
								nickname: "이미 사용중인 닉네임입니다.",
							});
						}
					}),
			500
		)();
	}, [newUserData.nickname]);

	// inicis 통합인증 최초 데이터
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const phone = searchParams.get("phone") ?? "";
		const userName = searchParams.get("userName") ?? "";
		const userBirthday = searchParams.get("userBirthday") ?? "";

		if (phone && userName && userBirthday) {
			setNewUserData({
				...newUserData,
				phone: formatPhoneNumber(phone) ?? "",
				name: userName,
				birthday: parseDate(userBirthday),
			});
			setDisableds({
				...disableds,
				phone: true,
				userName: true,
				userBirthday: true,
			});
		}
	}, []);

	// oauth로 입력되는 최초 데이터
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const email = searchParams.get("email") ?? "";
		const oauthType = searchParams.get("oauth_type") ?? "";
		const oauthId = searchParams.get("oauth_id") ?? "";

		if (email && oauthType && oauthId) {
			setNewUserData({
				...newUserData,
				email: email,
				oauthType: oauthType,
				oauthId: oauthId,
			});
			setDisableds({
				...disableds,
				email: true,
			});
		}
	}, []);

	const travelOptions = [
		{ label: "휴양형", value: "휴양형" },
		{ label: "액티비티형", value: "액티비티형" },
		{ label: "도시형", value: "도시형" },
		{ label: "로컬형", value: "로컬형" },
		{ label: "랜드마크형", value: "랜드마크형" },
		{ label: "모험형", value: "모험형" },
	];

	const preferenceOptions = [
		{ label: "가벼운 음주 좋아요", value: "가벼운 음주 좋아요" },
		{ label: "흡연 괜찮아요", value: "흡연 괜찮아요" },
		{ label: "둘 다 상관없어요", value: "둘 다 상관없어요" },
		{ label: "둘 다 싫어요", value: "둘 다 싫어요" },
	];

	const genderOptions = [
		{ label: "남자", value: "Male" },
		{ label: "여자", value: "Female" },
	];

	const handleChange = (key: string, value: any) => {
		setNewUserData({
			...newUserData,
			[key]: value,
		});
	};

	const checkPassword = useCallback((value: string) => {
		if (value.length < 8) {
			return false;
		} else if (!/[a-zA-Z]/.test(value) || !/[0-9]/.test(value)) {
			return false;
		}
		return true;
	}, []);

	const confirmPassword = useCallback(
		(value: string) => {
			if (value === "") {
				return false;
			} else if (value === newUserData.password) {
				return true;
			}
			return false;
		},
		[newUserData.password]
	);

	const isDisabled = useMemo(() => {
		const requiredFields: (keyof NewUserData)[] = [
			"email",
			"nickname",
			"password",
			"confirmPassword",
			"name",
			"birthday",
			"gender",
			"phone",
			"termsOfServiceAgreement",
			"privacyPolicyAgreement",
			"marketingAgreement",
		];

		for (const field of requiredFields) {
			if (newUserData[field] === "" || newUserData[field] === null || newUserData[field] === undefined) {
				return true;
			}
		}

		for (const error of Object.values(errors)) {
			if (error !== "") {
				return true;
			}
		}
		return false;
	}, [newUserData, errors]);

	const allAgreeChecked = useMemo(() => {
		const agreeFields: (keyof NewUserData)[] = ["termsOfServiceAgreement", "privacyPolicyAgreement", "marketingAgreement", "ageAgreement"];

		let isAllAgree = true;
		for (const field of agreeFields) {
			if (!newUserData[field]) {
				isAllAgree = false;
				break;
			}
		}
		return isAllAgree;
	}, [newUserData]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (newUserData.phone.replace(/-/g, "").length < 10) {
			alert("핸드폰 번호가 올바르지 않습니다.");
			return;
		}
		const phone = newUserData.phone;

		try {
			const response = await AUTH.userSignup(
				newUserData.oauthType,
				newUserData.oauthId,
				newUserData.email,
				newUserData.nickname,
				newUserData.password,
				newUserData.name,
				newUserData.birthday,
				newUserData.gender,
				phone,
				newUserData.tripInfo,
				newUserData.info,
				newUserData.mbti,
				!!newUserData.marketingAgreement
			);
			// window.location.href = "/login";
			setModal("success");
		} catch (e) {
			alert("회원가입에 문제가 발생하였습니다.");
		}
	};

	const hanldeAllAgree = useCallback(() => {
		if (allAgreeChecked) {
			setNewUserData({
				...newUserData,
				termsOfServiceAgreement: false,
				privacyPolicyAgreement: false,
				marketingAgreement: false,
				ageAgreement: false,
			});
			return;
		}

		setNewUserData({
			...newUserData,
			termsOfServiceAgreement: true,
			privacyPolicyAgreement: true,
			marketingAgreement: true,
			ageAgreement: true,
		});
	}, [allAgreeChecked, newUserData]);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="mt-14 text-center text-xl font-bold">오더 메이드 트립 [일반] 회원가입</div>
				<form onSubmit={handleSubmit} className="mt-14 px-3">
					<InputLabel title="이메일" required={true} />
					<InputEmail
						disabled={disableds.email}
						domainOptions={emailDomainOptions}
						value={newUserData.email}
						onChange={(v) => handleChange("email", v)}
						className="mt-2.5"
					/>
					{errors.email !== "" && <div className="flex items-center text-[10px] font-medium text-red-500">{errors.email}</div>}

					<InputLabel title="닉네임" required={true} className="mt-5" />
					<InputText value={newUserData.nickname} onChange={(v) => handleChange("nickname", v)} className="mt-2.5" />
					{errors.nickname !== "" && <div className="flex items-center text-[10px] font-medium text-red-500">{errors.nickname}</div>}

					<InputLabel title="비밀번호" required={true} className="mt-5" />
					<InputPassword
						showConfirmText={checkPassword(newUserData.password)}
						confirmText="사용가능한 비밀번호입니다."
						unConfirmText="사용가능이 불가능한 비밀번호입니다."
						value={newUserData.password}
						className="mt-2.5"
						onChange={(v) => handleChange("password", v)}
					/>

					<InputLabel title="비밀번호확인" required={true} className="mt-5" />
					<InputPassword
						showConfirmText={confirmPassword(newUserData.confirmPassword ?? "")}
						confirmText="비밀번호가 일치합니다."
						unConfirmText="비밀번호가 일치하지않습니다."
						value={newUserData.confirmPassword ?? ""}
						className="mt-2.5"
						onChange={(v) => handleChange("confirmPassword", v)}
					/>

					<InputLabel title="이름" required={true} className="mt-5" />
					<InputText disabled={disableds.userName} value={newUserData.name} onChange={(v) => handleChange("name", v)} className="mt-2.5" />

					<InputLabel title="생년월일" required={true} className="mt-5" />
					<InputBirthday disabled={disableds.userBirthday} value={newUserData.birthday} onChange={(v) => handleChange("birthday", v)} className="mt-2.5" />

					<InputLabel title="성별" required={true} className="mt-5" />
					<InputListFlex1 value={newUserData.gender} list={genderOptions} onChange={(v) => handleChange("gender", v)} className="mt-2.5" />

					<InputLabel title="휴대폰 번호" required={true} className="mt-5" />
					<InputText disabled={disableds.phone} value={newUserData.phone} onChange={(v) => handleChange("phone", v)} className="mt-2.5" />

					<InputLabel title="여행 성향" description="중복선택 가능합니다." className="mt-5" />
					<InputListCol2 value={newUserData.tripInfo} list={travelOptions} onChange={(v) => handleChange("tripInfo", v)} className="mt-2.5" />

					<InputLabel title="기호 선택" description="중복선택 가능합니다." className="mt-5" />
					<InputListCol2 value={newUserData.info} list={preferenceOptions} onChange={(v) => handleChange("info", v)} className="mt-2.5" />

					<InputLabel title="나의 MBTI는?" className="mt-5" />
					<select
						className="h-[30px] w-full mt-2 rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777] "
						onChange={(v) => handleChange("mbti", v.target.value)}>
						<option value="ISTJ">ISTJ</option>
						<option value="ISFJ">ISFJ</option>
						<option value="INFJ">INFJ</option>
						<option value="INTJ">INTJ</option>
						<option value="ISTP">ISTP</option>
						<option value="ISFP">ISFP</option>
						<option value="INFP">INFP</option>
						<option value="INTP">INTP</option>
						<option value="ESTP">ESTP</option>
						<option value="ESFP">ESFP</option>
						<option value="ENFP">ENFP</option>
						<option value="ENTP">ENTP</option>
						<option value="ESTJ">ESTJ</option>
						<option value="ESFJ">ESFJ</option>
						<option value="ENFJ">ENFJ</option>
						<option value="ENTJ">ENTJ</option>
						<option value="모름">모름</option>
					</select>

					<InputCheckbox checked={allAgreeChecked} onChange={hanldeAllAgree} labelText="이용약관 전체 동의" className="mt-5" />

					<div className="mt-5 w-full border-t border-[#dadada]"></div>

					<InputCheckbox
						checked={!!newUserData.termsOfServiceAgreement}
						onChange={(v) => handleChange("termsOfServiceAgreement", v.target.checked)}
						labelText="(필수) 서비스 이용약관 동의"
						className="mt-5"
					/>
					<div
						className="mt-2.5 h-32 overflow-y-scroll border border-[#dadada] p-4"
						dangerouslySetInnerHTML={{
							__html: terms.termsOfPolicy || "",
						}}></div>

					<InputCheckbox
						checked={!!newUserData.privacyPolicyAgreement}
						onChange={(v) => handleChange("privacyPolicyAgreement", v.target.checked)}
						labelText="(필수) 개인정보 수집 및 이용 동의"
						className="mt-5"
					/>
					<div
						className="mt-2.5 h-32 overflow-y-scroll border border-[#dadada] p-4"
						dangerouslySetInnerHTML={{
							__html: terms.privacy || "",
						}}></div>

					<InputCheckbox
						checked={!!newUserData.marketingAgreement}
						onChange={(v) => handleChange("marketingAgreement", v.target.checked)}
						labelText="(선택) 마케팅 활용 동의 및 광고 수신 동의"
						className="mt-5"
					/>

					<InputCheckbox
						checked={!!newUserData.ageAgreement}
						onChange={(v) => handleChange("ageAgreement", v.target.checked)}
						labelText="(필수) 만 14세 이상입니다."
						className="mt-5"
					/>

					<div className="mt-10">
						<button
							disabled={isDisabled}
							type="submit"
							className="bg-success flex h-10 w-full items-center justify-center rounded-sm text-sm font-bold text-white disabled:bg-[#777]">
							회원가입
						</button>
					</div>
				</form>
			</div>
			<Modal
				open={modal === "success"}
				onClose={() => {
					window.location.href = "/login";
				}}>
				<div
					className="flex justify-end"
					onClick={() => {
						window.location.href = "/login";
					}}>
					{IconClose}
				</div>
				<div className="text-center text-[80px]">🎊</div>
				<div className="font-bold text-black text-center">회원가입이 완료 되었습니다🤗</div>
				<div className="text-sm font-medium text-center leading-8 mt-6">
					지금부터 나만의 여행을 만들고
					<br />
					새로운 친구들과 함께 여행을 떠나보세요!
				</div>
			</Modal>
		</div>
	);
};

export default CustomerRegister;

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);
