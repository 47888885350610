import { useEffect, useMemo, useState } from "react";
import { ORDER, PARTNER, PARTNER_QUOTATION } from "../../../api";
import IconEarth from "../../../components/icon/earth";
import InputList from "../../../components/input/list";
import { add, format, parse } from "date-fns";
import PartnerMyPagePartnerCard from "../../../components/mypage/partner/card";
import Pagination from "../../../components/pagination";

export default function PartnerMyOrderStatusPage() {
	const [data, setData] = useState<any[]>([]);
	const [count, setCount] = useState(0);
	const [me, setMe] = useState<any>(null);

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	useEffect(() => {
		const status = selectedFilter === "전체" ? undefined : selectedFilter;
		const sDate = startDate ? startDate : new Date(new Date().getTime() - 1000 * 60 * 60 * 365).toISOString();
		const eDate = endDate ? endDate : new Date().toISOString();

		if (!status) {
			ORDER.paginationByPartner(page, perPage, undefined, sDate, eDate).then((data) => {
				setData(data.nodes);
				setCount(data.total);
			});
		} else {
			ORDER.paginationByPartner(page, perPage, "QUOTATION_COMPLETE", sDate, eDate).then((data) => {
				setData(data.nodes);
				setCount(data.total);
			});
		}

		PARTNER.getMe().then(setMe);
	}, [page, perPage, selectedFilter, startDate, endDate]);

	const dateKeys = useMemo(() => {
		const set = new Set();
		for (const item of data) {
			set.add(format(new Date(item.createdAt), "yyyy.MM.dd"));
		}
		// @ts-ignore
		return [...set.values()];
	}, [data]);

	const getOrdersByDate = (date: string) => {
		return data.filter((item) => {
			return format(new Date(item.createdAt), "yyyy.MM.dd") === date;
		});
	};

	const filters = [
		{ label: "전체", value: undefined },
		{ label: "작성완료", value: "QUOTATION_COMPLETE" },
	];

	const [selectedDate, setSelectedDate] = useState<string | null>("전체");

	const dates = [
		{ label: "1주", value: "1주" },
		{ label: "1개월", value: "1개월" },
		{ label: "3개월", value: "3개월" },
		{ label: "전체", value: "전체" },
	];

	useEffect(() => {
		if (selectedDate === null) return;
		else if (selectedDate === "1주") {
			setStartDate(format(add(new Date(), { days: -7 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "1개월") {
			setStartDate(format(add(new Date(), { months: -1 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "3개월") {
			setStartDate(format(add(new Date(), { months: -3 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "전체") {
			setStartDate(format(add(new Date(), { months: -12 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		}
	}, [selectedDate]);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="text-success flex px-3 gap-1 mt-5 font-medium">
					<IconEarth /> 여행 주문현황
				</div>
				<div className="px-3 mt-6">
					<div className="h-[1px] w-full bg-[#dadada]"></div>
				</div>

				<div className="mt-5 font-medium text-sm text-black px-3">주문상태</div>

				<div className="flex px-3 mt-3 gap-3">
					{filters.map((item) => {
						const isSelected = selectedFilter === item.value;
						return (
							<div
								onClick={() => setSelectedFilter(item.value)}
								className={[
									"w-20 text-sm font-normal flex justify-center items-center border py-1.5 rounded-sm cursor-pointer",
									isSelected ? "border-success text-success" : "border-[#dadada] text-black",
								].join(" ")}>
								{item.label}
							</div>
						);
					})}
				</div>

				<div className="mt-5 font-medium text-sm text-black px-3">기간별 조회</div>
				<div className="px-3 flex gap-2 mt-2.5">
					<input
						value={startDate}
						onChange={(v) => setStartDate(v.target.value)}
						type="date"
						placeholder=" "
						className="rounded-sm bg-transparent text-xs font-normal text-black border border-[#dadada] px-2.5 h-[30px] cursor-pointer"
					/>
					~
					<input
						value={endDate}
						onChange={(v) => setEndDate(v.target.value)}
						type="date"
						placeholder=" "
						className="rounded-sm bg-transparent text-xs font-normal text-black border border-[#dadada] px-2.5 h-[30px] cursor-pointer"
					/>
					<div className="text-xs text-success font-normal flex-none h-[30px] w-[78px] border border-[#dadada] flex justify-center items-center">{count}건</div>
				</div>
				<div className="px-3 mt-2.5 flex gap-2.5">
					{dates.map((item) => {
						const isSelected = selectedDate === item.value;
						return (
							<div
								onClick={() => setSelectedDate(item.value)}
								className={[
									"w-20 text-xs font-normal flex justify-center items-center border py-1.5 rounded-sm cursor-pointer",
									isSelected ? "border-success text-success" : "border-[#dadada] text-black",
								].join(" ")}>
								{item.label}
							</div>
						);
					})}
				</div>

				<div className="mt-6">
					<div className="h-[1px] w-full bg-[#dadada]"></div>
				</div>

				<div>
					{dateKeys.map((v) => {
						return (
							<div className="text-sm px-3 font-medium text-black mt-7">
								{v}
								<div>
									{getOrdersByDate(v).map((order) => {
										if (order.status === "QUOTATION_COMPLETE") {
											const newOrder = { ...order };
											if (!filters) {
												newOrder.status = "QUOTATION_WAITING";
											}

											return (
												<>
													{order?.PartnerQuotations?.map((e: any) => {
														const newOrder = order;
														newOrder.PartnerQuotations = [e];
														return (
															<div
																className="mt-2.5"
																onClick={() => {
																	const anotherPartnerQuotation = order.PartnerQuotations.find((e: any) => e.partnerId !== me.id);
																	if (anotherPartnerQuotation) {
																		alert("다른 파트너의 견적입니다.");
																	} else {
																		window.location.href = "/partner/my/create-quotation?order_id=" + order.id;
																	}
																}}>
																<PartnerMyPagePartnerCard order={newOrder} />
															</div>
														);
													})}
													{!order?.PartnerQuotations?.some((e: any) => e?.partnerId === me?.id) && (
														<div
															className="mt-2.5"
															onClick={() => {
																window.location.href = "/partner/my/create-quotation?order_id=" + order.id;
															}}>
															<PartnerMyPagePartnerCard order={newOrder} />
														</div>
													)}
												</>
											);
										} else {
											return (
												<div
													className="mt-2.5"
													onClick={() => {
														const anotherPartnerQuotation = order.PartnerQuotations.find((e: any) => e.partnerId !== me.id);
														if (anotherPartnerQuotation) {
															alert("다른 파트너의 견적입니다.");
														} else {
															window.location.href = "/partner/my/create-quotation?order_id=" + order.id;
														}
													}}>
													<PartnerMyPagePartnerCard order={order} />
												</div>
											);
										}
									})}
								</div>
							</div>
						);
					})}
				</div>

				<Pagination className="mt-8" page={page} perPage={perPage} count={count} onChange={(v) => setPage(v)} />
			</div>
		</div>
	);
}
