import { useEffect, useState } from "react";
import { ORDER, ORDER_SCHEDULE, PARTNER_QUOTATION, USER } from "../../api";
import globe from "../../image/globe.svg";
import SvgIcon from "../../components/SvgIcon";

const IconBook = (
	<svg className="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M8.57133 2.40039V21.6004M17.4856 10.629H12.6856M17.4856 6.51468H12.6856M5.14276 6.51468H2.3999M5.14276 10.629H2.3999M5.14276 14.7432H2.3999M6.51419 21.6004H18.857C20.3719 21.6004 21.5999 20.3724 21.5999 18.8575V5.14325C21.5999 3.62841 20.3719 2.40039 18.857 2.40039H6.51419C4.99935 2.40039 3.77133 3.62841 3.77133 5.14325V18.8575C3.77133 20.3724 4.99935 21.6004 6.51419 21.6004Z"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
);

export default function OrderCompletedPage() {
	const [order, setOrder] = useState([]);

	useEffect(() => {
		// PARTNER_QUOTATION.getComfirmOrders().then((data: any) => {
		// 	setOrder(data);
		// });

		ORDER.getManyPaid().then((data: any) => {
			setOrder(
				data.filter((e: any) => {
					return new Date(e.endedAt).getTime() < new Date().getTime();
				})
			);
		});
	}, []);

	function calculateDuration(startedAt: string, endedAt: string) {
		const startDate = new Date(startedAt);
		const endDate = new Date(endedAt);

		// 두 날짜의 차이를 밀리초로 계산
		const differenceInTime = endDate.getTime() - startDate.getTime();

		// 밀리초를 일 수로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
		const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

		// 박 수는 일 수에서 1을 뺀 값
		return `${differenceInDays}박${differenceInDays + 1}일`;
	}

	const OrderCard = ({ node, key, quotations }: any) => {
		return (
			<>
				<div className="border border-[#dadada] p-[10px] mb-[20px] flex rounded-[2px] gap-3">
					<div
						className="flex-1"
						onClick={() => {
							window.location.href = `/mypage/myorder/${node.id}${quotations?.id ? `?quotations=${quotations.id}` : ""}`;
						}}>
						<div
							className="w-full h-[194px] bg-center bg-cover rounded-[2px] relative bg-gray-200 cursor-pointer"
							style={{
								backgroundImage: `url(${node?.PartnerQuotations?.find((e: any) => e.selectedAt)?.PartnerQuotationFiles?.find((e: any) => e.File)?.File?.url})`,
							}}>
							<div className="font-spoqa text-[10px] text-[#f5f5f5] max-w-[90%] truncate font-medium bg-[#355c7d] py-[4px] px-[9px] absolute top-[5px] left-[5px]">
								{node?.nation}·{node?.city} / {calculateDuration(node?.startedAt, node?.endedAt)}
							</div>
						</div>
					</div>
					<div className="flex-1 mt-1 flex flex-col">
						<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium">
							{node?.PartnerQuotations?.find((e: any) => e.selectedAt)?.Partner?.corporateBusinessName}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[8px] ">
							여행 형태:
							{node?.type === "RECRUITMENT" && " 모집 주문"}
							{node?.type === "ALONE" && " 단독 주문"}
							{node?.type === "BUSINESS_TRIP" && " 출장 주문"}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">국가:{node?.nation}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">도시:{node?.city}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">
							{node.numberOfRecruits}명{`${node.type !== "RECRUITMENT" ? "" : "·" + node.recruitGenderTypeText}`}
							{`${node.type !== "RECRUITMENT" ? "" : "·" + node.ageGroup}`}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">
							{new Date(node?.startedAt).toLocaleDateString().slice(0, -1)} ~ {new Date(node?.endedAt).toLocaleDateString().slice(0, -1)}
						</div>
						<div className="flex-1 flex flex-col justify-end gap-2">
							<div
								onClick={() => (window.location.href = `/travel_Order?ReorderId=${node.id}`)}
								className="h-[30px] flex justify-center items-center text-[14px] font-medium bg-[#0057FF] text-white cursor-pointer">
								재주문
							</div>
							<div
								onClick={() => alert("리뷰 남기기")}
								className="h-[30px] flex justify-center items-center text-[14px] font-medium bg-white text-[#777] border border-[#dadada] cursor-pointer">
								리뷰 남기기
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="w-full flex justify-center">
			<div className="flex justify-center max-w-[512px] w-full">
				<div className=" w-full px-3 pt-5 ">
					<div className=" border-[#dadada] pb-[23px] ">
						<div className="flex text-[14px] text-[#0057FF] font-medium gap-2 items-center">
							<SvgIcon className="w-5 h-5 bg-[#0057FF]" icon={globe} />
							완료된 여행
						</div>
					</div>

					<hr className="mb-[20px] " />
					{order?.length > 0 ? (
						order.map((node: any, i: any) => {
							return <div key={i}>{<OrderCard node={node} />}</div>;
						})
					) : (
						<>
							<div className="h-[300px] flex mt-[120px] justify-center items-center flex-col">
								<div>아직 완료된 여행이 없어요😢</div>
								<div> ✈️지금 여행을 주문 하시겠어요?</div>
								<div
									className="px-[10px] mt-[150px] w-full h-10 flex justify-center items-center text-[#0057FF] border rounded-[2px]"
									onClick={() => {
										window.location.href = "/travel_Order";
									}}>
									여행 주문하기
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
