import history from "./history";

export function login(token: string, rememberMe: boolean, isPartner: boolean) {
  if (rememberMe) {
    localStorage.setItem('token', token);
    if (isPartner) {
      localStorage.setItem('isPartner', 'true');
    }
  } else {
    sessionStorage.setItem('token', token);
    if (isPartner) {
      sessionStorage.setItem('isPartner', 'true');
    }
  }

  return true;
}

export function logout() {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');

  localStorage.removeItem('isPartner');
  sessionStorage.removeItem('isPartner');
  window.location.href = "/";
}

// 토큰을 가져오는 함수를 정의합니다.
export function getToken() {
  // 우선 localStorage에서 토큰을 시도합니다.
  let token = localStorage.getItem("token");
  // localStorage에 없을 경우, sessionStorage에서 시도합니다.
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  return token;
}

export function isPartner() {
  let isPartner = localStorage.getItem("isPartner");
  if (!isPartner) {
    isPartner = sessionStorage.getItem("isPartner");
  }
  return isPartner === 'true';
}