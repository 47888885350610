import { useEffect, useState } from "react";
import { FILE, NOTIFICATION, ORDER, PARTNER_QUOTATION } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";
import EasyPagination from "../../components/EasyPagination";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";
import { format } from "date-fns";

export default function AdminOrdersPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});

	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [filter, setFilter] = useState<any>(""); // order-start

	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'
	const [selected, setSelected] = useState<any>(null);

	useEffect(() => {
		getData();
	}, [page, filter]);

	const getData = async () => {
		if (filter === "order-start") {
			const response = await ORDER.pagination(page.page, page.perPage, "PAYMENT_COMPLETE", format(new Date(), "yyyy-MM-dd"));
			setData(response.nodes);
			setCount(response.total);
		} else {
			const response = await ORDER.pagination(page.page, page.perPage);
			setData(response.nodes);
			setCount(response.total);
		}
	};

	const columns = [
		{
			header: "ID",
			key: "id",
		},
		{
			header: "사용자",
			key: "userId",
			render: (userId: any) => (
				<button onClick={() => (window.location.href = `/admin/dashboard/users/${userId}`)} className="text-blue-500">
					{userId}
				</button>
			),
		},
		{
			header: "상태",
			key: "status",
			render: (status: any) => {
				let _status = null;
				if (status === "QUOTATION_WAITING") _status = "견적 대기";
				else if (status === "QUOTATION_COMPLETE") _status = "견적 수신";
				else if (status === "CONFIRM") _status = "견적 확정";
				else if (status === "PAYMENT_WAITING") _status = "결제 대기";
				else if (status === "PAYMENT_PARTIAL_COMPLETE") _status = "결제 부분 완료";
				else if (status === "PAYMENT_COMPLETE") _status = "결제 완료";
				else if (status === "REFUND_WAITING") _status = "환불 대기";
				else if (status === "REFUND_COMPLETE") _status = "환불 완료";
				else if (status === "REFUND_PARTIAL_COMPLETE") _status = "환불 부분 완료";

				return <div>{_status}</div>;
			},
		},
		{
			header: "상태 변경",
			key: "id",
			render: (id: any, row: any) => (
				<button
					onClick={() => {
						setSelected(row);
						setModal("update-status");
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					변경
				</button>
			),
		},
		{
			header: "인보이스 상태",
			key: "PartnerQuotations",
			render: (PartnerQuotations: any) => {
				const confirmed = PartnerQuotations.find((v: any) => v.selectedAt);

				let _status = null;
				if (confirmed?.invoiceStatus === "WATING") _status = "대기";
				else if (confirmed?.invoiceStatus === "UPLOAD_COMPLETED") _status = "업로드 완료";
				else if (confirmed?.invoiceStatus === "CHECKING") _status = "확인중";
				else if (confirmed?.invoiceStatus === "COMPLETED") _status = "완료";
				else if (confirmed?.invoiceStatus === "REJECTED") _status = "반려";

				return <div>{confirmed?.invoiceStatus ? _status : null}</div>;
			},
		},
		{
			header: "인보이스 상태변경",
			key: "PartnerQuotations",
			render: (PartnerQuotations: any) => {
				const confirmed = PartnerQuotations.find((v: any) => v.selectedAt);
				return confirmed ? (
					<button
						onClick={() => {
							setSelected(confirmed);
							setModal("update-partner-quotation-status");
						}}
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
						변경
					</button>
				) : null;
			},
		},
		{
			header: "메모",
			key: "memo",
		},
		{
			header: "메모 입력",
			key: "id",
			render: (id: any, row: any) => (
				<button
					onClick={() => {
						const memo = prompt("메모를 입력해주세요");
						if (memo) {
							ORDER.updateMemo(row.id, {
								memo,
							}).then((res) => {
								getData();
							});
						}
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					입력
				</button>
			),
		},
		{
			header: "인보이스 확인",
			key: "PartnerQuotations",
			render: (PartnerQuotations: any) => {
				const confirmed = PartnerQuotations.find((v: any) => v.selectedAt);
				return confirmed?.invoiceFileId ? (
					<button
						onClick={() => {
							FILE.findOne(confirmed.invoiceFileId).then((res) => {
								window.open(res.url, "_blank");
							});
						}}
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
						확인
					</button>
				) : null;
			},
		},

		{
			header: "이름",
			key: "name",
		},
		{
			header: "이메일",
			key: "email",
		},
		{
			header: "국가",
			key: "nation",
		},
		{
			header: "도시",
			key: "city",
		},
		{
			header: "시작날짜",
			key: "startedAt",
			render: (startedAt: any) => format(new Date(startedAt), "yyyy-MM-dd"),
		},
		{
			header: "종료날짜",
			key: "endedAt",
			render: (endedAt: any) => format(new Date(endedAt), "yyyy-MM-dd"),
		},
		{
			header: "예상비용",
			key: "expenses",
		},
		{
			header: "모집인원",
			key: "numberOfRecruits",
		},
		{
			header: "여행유형",
			key: "type",
			render: (type: any) => {
				let _type = null;
				if (type === "RECRUITMENT") _type = "모집형";
				else if (type === "ALONE") _type = "단독형";
				else if (type === "BUSINESS_TRIP") _type = "출장형";

				return <div>{_type}</div>;
			},
		},
		{
			header: "모집유형",
			key: "recruitGenderType",
			render: (recruitGenderType: any) => {
				let _recruitGenderType = "남녀무관";
				if (recruitGenderType === "Male") _recruitGenderType = "남자";
				else if (recruitGenderType === "Female") _recruitGenderType = "여자";

				return <div>{_recruitGenderType}</div>;
			},
		},
		{
			header: "상세보기",
			key: "edit",
			render: (value: any, row: any, index: any) => (
				<button onClick={() => handleDetail(row.id)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					상세
				</button>
			),
		},
		{
			header: "여행계획",
			key: "OrderSchedule",
			render: (value: any, row: any, index: any) => {
				return row.OrderSchedule?.length > 0 ? (
					<a target="_blank" href={`/order/schedule/${row.id}`} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
						여행계획 보기
					</a>
				) : (
					<button onClick={() => handelCreateSchedule(row.id)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
						여행계획 생성
					</button>
				);
			},
		},
		{
			header: "여행계획 신청여부",
			key: "requestOrderSchedule",
			render: (value: any, row: any, index: any) => {
				return row.requestOrderSchedule ? <div className="text-green-500">Y</div> : <div className="text-red-500">N</div>;
			},
		},
		{
			header: "일정표 알림",
			key: "id",
			render: (orderId: any, row: any) => {
				return (
					<button
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm"
						onClick={() => {
							NOTIFICATION.sendOrderSchedule(row.userId, orderId).then((res) => {
								alert("전송 완료");
							});
						}}>
						보내기
					</button>
				);
			},
		},
		{
			header: "결제 알림",
			key: "id",
			render: (orderId: any, row: any) => {
				return (
					<button
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm"
						onClick={() =>
							NOTIFICATION.sendPayment(row.userId, orderId).then((res) => {
								alert("전송 완료");
							})
						}>
						보내기
					</button>
				);
			},
		},
		{
			header: "여행임박알림",
			key: "id",
			render: (orderId: any, row: any) => {
				return (
					<button
						className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm"
						onClick={() =>
							NOTIFICATION.sendTripClosed(row.userId, orderId).then((res) => {
								alert("전송 완료");
							})
						}>
						보내기
					</button>
				);
			},
		},
	];

	const handelCreateSchedule = (id: number) => {
		ORDER.createSchedule(id)
			.then((res) => {
				getData();
				alert("생성 완료");
			})
			.catch((err) => {
				alert("생성 실패");
			});
	};

	const handleDetail = (id: number) => {
		window.location.href = `/admin/dashboard/orders/${id}`;
	};

	const invoiceStatusOptions = [
		{ label: "대기", value: "WAITING" },
		{ label: "업로드 완료", value: "UPLOAD_COMPLETED" },
		{ label: "확인중", value: "CHECKING" },
		{ label: "완료", value: "COMPLETED" },
		{ label: "거절", value: "REJECTED" },
	];

	const statusOptions = [
		{ label: "견적 대기", value: "QUOTATION_WAITING" },
		{ label: "견적 수신", value: "QUOTATION_COMPLETE" },
		{ label: "견적 확정", value: "CONFIRM" },
		{ label: "결제 대기", value: "PAYMENT_WAITING" },
		{ label: "결제 부분 완료", value: "PAYMENT_PARTIAL_COMPLETE" },
		{ label: "결제 완료", value: "PAYMENT_COMPLETE" },
		{ label: "환불 대기", value: "REFUND_WAITING" },
		{ label: "환불 완료", value: "REFUND_COMPLETE" },
		{ label: "환불 부분 완료", value: "REFUND_PARTIAL_COMPLETE" },
	];

	const handleUpdateInvoiceStatus = async (formData: any) => {
		await PARTNER_QUOTATION.updateInvoiceStatus(selected.id, {
			...formData,
			invoiceStatus: formData.invoiceStatus.value,
		});
		getData();
		setModal("");
	};

	const handleUpdateStatus = async (formData: any) => {
		await ORDER.updateStatus(selected.id, {
			status: formData.status.value,
		});
		getData();
		setModal("");
	};

	return (
		<div>
			<h1>여행 주문 관리</h1>

			<div className="flex justify-end">
				<div
					onClick={() => {
						if (filter === "order-start") setFilter("");
						else setFilter("order-start");
					}}
					className={[
						"border border-gray-600  px-3 py-1 rounded-sm font-normal text-sm",
						filter === "order-start" ? "bg-gray-500 text-white" : "bg-gray-200 text-black",
					].join(" ")}>
					결제 & 여행중인 주문 필터
				</div>
				{/* <button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button> */}
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable data={data} columns={columns} />
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>

				<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
					<div>추가</div>
					<div>
						{/* <FormFactory
          schema={[
            { label: "이메일", key: "email", method: "text", validation: "required" },
            { label: "비밀번호", key: "password", method: "text", validation: "required" },
            { label: "이름", key: "name", method: "text", validation: "required" },
            { label: "국가", key: "nation", method: "text", validation: "required" },
            { label: "도시", key: "city", method: "text", validation: "required" },
            { label: "사업자번호", key: "corporateBusinessName", method: "text", validation: "required" },
            { label: "주소", key: "address", method: "text", validation: "required" },
            { label: "직급", key: "position", method: "text", validation: "required" },
            {
              label: "핸드폰",
              key: "mobilePhone",
              method: "text",
              validation: (v: string) => /\d\d\d-\d\d\d\d-\d\d\d\d/.test(v),
              placeholder: "010-1234-5678",
            },
            { label: "전화번호", key: "landlinePhone", method: "text", validation: "required" },
            { label: "사업자등록번호", key: "businessNumber", method: "text", validation: "required" },
            { label: "설명", key: "description", method: "text", validation: "required" },
            { label: "사업자파일", key: "businessRegistrationFileId", method: "file", validation: "required" },
            { label: "계좌파일", key: "accountFileId", method: "file", validation: "required" },
          ]}
          onSubmit={handleCreate}
        /> */}
					</div>
				</Modal>

				{selected && (
					<Modal open={modal === "update-partner-quotation-status"} onClose={() => setModal("")} size="lg">
						<div>인보이스 상태 변경</div>
						<div className="min-h-[350px]">
							<FormFactory
								schema={[
									{
										label: "인보이스 상태",
										key: "invoiceStatus",
										method: "select",
										selectOptions: invoiceStatusOptions,
										defaultValue: invoiceStatusOptions.find((v) => v.value === selected.invoiceStatus),
									},
								]}
								onSubmit={handleUpdateInvoiceStatus}
							/>
						</div>
					</Modal>
				)}

				{selected && (
					<Modal open={modal === "update-status"} onClose={() => setModal("")} size="lg">
						<div>주문 상태 변경</div>
						<div className="min-h-[350px]">
							<FormFactory
								schema={[
									{
										label: "주문 상태",
										key: "status",
										method: "select",
										selectOptions: statusOptions,
										defaultValue: statusOptions.find((v) => v.value === selected.status),
									},
								]}
								onSubmit={handleUpdateStatus}
							/>
						</div>
					</Modal>
				)}
			</div>
		</div>
	);
}
