import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RECOMMENDATION_TRIP, PARTNER, USER } from "../../api";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";

export default function RecommendationTripDetail() {
	const { recommendation_trip_id } = useParams();
	const [data, setData] = useState<any>(null);
	const [isLike, setIsLike] = useState(false);
	const [onModal, setOnModal] = useState("");
	const [isMine, setMine] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		if (recommendation_trip_id) {
			RECOMMENDATION_TRIP.getOne(recommendation_trip_id)
				.then((data) => {
					setData(data);

					// 작성자인지 체크
					USER.getUser()
					.then((res) => {
						if (data.userId) { if (data.userId === res.id) setMine(true); }
					})
					.catch((e) => {
						PARTNER.getMe()
							.then((res) => {
								if (data.partnerId) { if (data.partnerId === res.id) setMine(true); }
							})
							.catch((e) => {});
					});
				})
				.catch(() => {});
			RECOMMENDATION_TRIP.checkLike(recommendation_trip_id)
				.then((data) => {
					setIsLike(!!data?.isLike);
				})
				.catch(() => {});
		}
	};

	const handleLike = () => {
		if (recommendation_trip_id) {
			if (isLike) {
				RECOMMENDATION_TRIP.unlike(recommendation_trip_id)
					.then(() => {
						setIsLike(false);
						RECOMMENDATION_TRIP.getOne(recommendation_trip_id).then(setData);
					})
					.catch(() => {
						alert("로그인이 필요합니다.");
					});
			} else {
				RECOMMENDATION_TRIP.like(recommendation_trip_id)
					.then(() => {
						setIsLike(true);
						RECOMMENDATION_TRIP.getOne(recommendation_trip_id).then(setData);
					})
					.catch(() => {
						alert("로그인이 필요합니다.");
					});
			}
		}
	};

	const image = useMemo(() => {
		return data?.RecommendationTripFile.find((v: any) => v.type === "RECOMMENDATION_TRIP_IMAGE")?.File.url;
	}, [data]);

	const KakaoShareButton = ({ image, title, text, url }: any) => {
		useEffect(() => {
			// @ts-ignore
			Kakao.Link.createDefaultButton({
				container: "#main_kakao",
				objectType: "feed",
				installTalk: true,
				content: {
					title,
					description: text,
					imageUrl: image,
					link: {
						webUrl: url,
						mobileWebUrl: url,
					},
				},
			});
		}, []);

		return (
			<>
				<div id="main_kakao" className="w-16 h-16">
					{IconKakao}
				</div>
				<div className="text-center text-black font-medium text-sm">카카오톡</div>
			</>
		);
	};

	const handleUpdate = async (formData: any) => {
		if (recommendation_trip_id) {
			await RECOMMENDATION_TRIP.update(+recommendation_trip_id, {
				...formData,
				RecommendationTripFile: {
					deleteMany: { AND: [] },
					create: {
						File: {
							connect: {
								id: formData.RecommendationTripFile.id,
							},
						},
						type: "RECOMMENDATION_TRIP_IMAGE",
					},
				},
			}).then((response: any) => {
				getData();
				setOnModal("");
			});
		}
	};

	const handleDelete = async () => {
		if (recommendation_trip_id) {
			await RECOMMENDATION_TRIP.delete(+recommendation_trip_id);
			window.location.href = "/ourdestination";
		}
	};

	const defaultImage = useMemo(() => {
		return data?.RecommendationTripFile?.find((file: any) => {
			return file.type === "RECOMMENDATION_TRIP_IMAGE";
		})?.File;
	}, [data]);

	return (
		<>
			<div className="flex justify-center">
				<div className="max-w-[800px] w-full">
					<div className="text-lg font-bold text-[202c4a] mt-9 px-3">{data?.name}</div>
					<div className="flex justify-between px-3 items-center mt-2.5 gap-5">
						<div className="text-[#777] text-sm font-normal">
							{data?.category1} · {data?.category2}
						</div>
						<div className="flex gap-1 flex-1 items-center justify-end" onClick={handleLike}>
							{isLike ? IconHeart : IconEmptyHeart}
							{data?.countOfLike?.toLocaleString()}
						</div>
						<div onClick={() => setOnModal("SHARE")}>{IconShare}</div>
					</div>

					<div className="border-b border-[#999] mt-9"></div>

					<div
						dangerouslySetInnerHTML={{
							__html: data?.content,
						}}
						className="px-3 text-sm font-normal mt-5">
					</div>

					<div className="border-b border-[#999] mt-9" ></div>

					{isMine && (
						<div className="flex w-full max-w-3xl justify-end mt-5 gap-4">
							<button
								onClick={() => {
									handleDelete();
								}}
								className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-md hover:bg-indigo-600 focus:outline-none">
								삭제
							</button>
							<button
								onClick={() => {
									setOnModal("update");
								}}
								className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-md hover:bg-indigo-600 focus:outline-none">
								수정
							</button>
						</div>
					)}
				</div>
			</div>

			<Modal open={onModal === "SHARE"} onClose={() => setOnModal("")}>
				<div className="flex items-center gap-1 font-medium">
					{IconShare}
					공유하기
				</div>
				<div className="flex justify-between mt-8">
					<div className="flex flex-col">
						<KakaoShareButton url={window.location.href} title={data?.name} text={data?.description} image={image} />
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16"
							onClick={() => {
								window.open(`https://twitter.com/intent/tweet?text=${data?.name}+${window.location.href}`);
							}}>
							<img src="/images/20230725081132224942 1.png" />
						</div>
						<div className="text-center text-black font-medium text-sm">X</div>
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16 flex justify-center items-center"
							onClick={() => {
								//@ts-ignore
								try {
									navigator.clipboard.writeText(window.location.href).then(() => {
										alert("복사 완료");
									});
								} catch (e) {}
							}}>
							{IconCopy}
						</div>
						<div className="text-center text-black font-medium text-sm">링크 복사</div>
						<input id="community_clup_id_copy" className="hidden" value={`${data?.name}\nhttps://www.ordermtrip.com`} />
					</div>
				</div>
				<div className="mt-5 font-bold text-white bg-success text-sm h-[30px] flex justify-center items-center">공유하기</div>
			</Modal>

			<Modal open={onModal === "update"} onClose={() => setOnModal("")} size="lg">
				<div>추천 여행 수정하기</div>

				<FormFactory
					schema={[
						{ label: "제 목", key: "name", method: "text", validation: "required", defaultValue: data?.name },
						{ label: "설 명", key: "description", method: "text", validation: "required", defaultValue: data?.description },
						{ label: "이미지", key: "RecommendationTripFile", method: "file", validation: "required", defaultValue: defaultImage },
						{ label: "내 용", key: "content", method: "wizwig", validation: "required", defaultValue: data?.content },
						{ label: "국 가", key: "category1", method: "text", validation: "required", defaultValue: data?.category1 },
						{ label: "도 시", key: "category2", method: "text", validation: "required", defaultValue: data?.category2 },
						{ label: "태 그", key: "tags", method: "text", validation: "required", defaultValue: data?.tags },
					]}
					onSubmit={handleUpdate}
				/>
			</Modal>
		</>
	);
}
const IconEmptyHeart = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M9 16.2375C8.7675 16.2375 8.5425 16.2075 8.355 16.14C5.49 15.1575 0.9375 11.67 0.9375 6.51745C0.9375 3.89245 3.06 1.76245 5.67 1.76245C6.9375 1.76245 8.1225 2.25745 9 3.14245C9.8775 2.25745 11.0625 1.76245 12.33 1.76245C14.94 1.76245 17.0625 3.89995 17.0625 6.51745C17.0625 11.6775 12.51 15.1575 9.645 16.14C9.4575 16.2075 9.2325 16.2375 9 16.2375ZM5.67 2.88745C3.6825 2.88745 2.0625 4.51495 2.0625 6.51745C2.0625 11.64 6.99 14.49 8.7225 15.0825C8.8575 15.1275 9.15 15.1275 9.285 15.0825C11.01 14.49 15.945 11.6475 15.945 6.51745C15.945 4.51495 14.325 2.88745 12.3375 2.88745C11.1975 2.88745 10.14 3.41995 9.4575 4.34245C9.2475 4.62745 8.7675 4.62745 8.5575 4.34245C7.86 3.41245 6.81 2.88745 5.67 2.88745Z"
			fill="#0E1111"
		/>
	</svg>
);

const IconHeart = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M12.33 2.32495C10.9725 2.32495 9.7575 2.98495 9 3.99745C8.2425 2.98495 7.0275 2.32495 5.67 2.32495C3.3675 2.32495 1.5 4.19995 1.5 6.51745C1.5 7.40995 1.6425 8.23495 1.89 8.99995C3.075 12.75 6.7275 14.9925 8.535 15.6075C8.79 15.6975 9.21 15.6975 9.465 15.6075C11.2725 14.9925 14.925 12.75 16.11 8.99995C16.3575 8.23495 16.5 7.40995 16.5 6.51745C16.5 4.19995 14.6325 2.32495 12.33 2.32495Z"
			fill="#D70D0D"
		/>
	</svg>
);

const IconShare = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.5 7.3125C3.56802 7.3125 2.8125 8.06802 2.8125 9C2.8125 9.93198 3.56802 10.6875 4.5 10.6875C5.43198 10.6875 6.1875 9.93198 6.1875 9C6.1875 8.06802 5.43198 7.3125 4.5 7.3125ZM1.6875 9C1.6875 7.4467 2.9467 6.1875 4.5 6.1875C6.0533 6.1875 7.3125 7.4467 7.3125 9C7.3125 10.5533 6.0533 11.8125 4.5 11.8125C2.9467 11.8125 1.6875 10.5533 1.6875 9Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.375 12.375C11.443 12.375 10.6875 13.1305 10.6875 14.0625C10.6875 14.9945 11.443 15.75 12.375 15.75C13.307 15.75 14.0625 14.9945 14.0625 14.0625C14.0625 13.1305 13.307 12.375 12.375 12.375ZM9.5625 14.0625C9.5625 12.5092 10.8217 11.25 12.375 11.25C13.9283 11.25 15.1875 12.5092 15.1875 14.0625C15.1875 15.6158 13.9283 16.875 12.375 16.875C10.8217 16.875 9.5625 15.6158 9.5625 14.0625Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.375 2.25C11.443 2.25 10.6875 3.00552 10.6875 3.9375C10.6875 4.86948 11.443 5.625 12.375 5.625C13.307 5.625 14.0625 4.86948 14.0625 3.9375C14.0625 3.00552 13.307 2.25 12.375 2.25ZM9.5625 3.9375C9.5625 2.3842 10.8217 1.125 12.375 1.125C13.9283 1.125 15.1875 2.3842 15.1875 3.9375C15.1875 5.4908 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 5.4908 9.5625 3.9375Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.9571 4.84978C11.125 5.11113 11.0493 5.45915 10.788 5.62709L6.69576 8.25678C6.43441 8.42473 6.0864 8.34901 5.91845 8.08766C5.75051 7.82631 5.82623 7.47829 6.08758 7.31035L10.1798 4.68066C10.4411 4.51272 10.7891 4.58843 10.9571 4.84978Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.91845 9.91228C6.0864 9.65093 6.43441 9.57522 6.69576 9.74316L10.788 12.3728C11.0493 12.5408 11.125 12.8888 10.9571 13.1502C10.7891 13.4115 10.4411 13.4872 10.1798 13.3193L6.08758 10.6896C5.82623 10.5216 5.75051 10.1736 5.91845 9.91228Z"
			fill="#777777"
		/>
	</svg>
);

const IconKakao = (
	<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
		<rect width="64" height="64" rx="17" fill="#FFE812" />
		<path
			d="M31.9571 13C18.7259 13 8 21.2246 8 31.37C8 37.9292 12.4841 43.6846 19.2294 46.9346C18.8625 48.1654 16.8713 54.8525 16.792 55.3778C16.792 55.3778 16.7444 55.7726 17.0072 55.9231C17.27 56.0737 17.5792 55.9567 17.5792 55.9567C18.3329 55.8543 26.3196 50.3984 27.702 49.451C29.083 49.6412 30.505 49.74 31.9571 49.74C45.1884 49.74 55.9143 41.5156 55.9143 31.37C55.9143 21.2246 45.1884 13 31.9571 13Z"
			fill="black"
		/>
		<path
			d="M19.1946 36.3428C18.4079 36.3428 17.7682 35.8079 17.7682 35.1502V27.7313H15.5424C14.7705 27.7313 14.1426 27.1826 14.1426 26.5085C14.1426 25.8343 14.7707 25.2857 15.5424 25.2857H22.8468C23.6188 25.2857 24.2467 25.8343 24.2467 26.5085C24.2467 27.1826 23.6185 27.7313 22.8468 27.7313H20.6211V35.1502C20.6211 35.8079 19.9813 36.3428 19.1946 36.3428ZM31.7028 36.3266C31.1079 36.3266 30.6529 36.1151 30.5157 35.775L29.8094 34.1562L25.4596 34.1559L24.7528 35.7759C24.6161 36.1153 24.1613 36.3266 23.5665 36.3266C23.2536 36.3269 22.9443 36.2681 22.6597 36.1542C22.2665 35.9954 21.8885 35.5588 22.3217 34.3809L25.7337 26.5185C25.9741 25.9205 26.7042 25.3044 27.6333 25.2859C28.565 25.3042 29.2951 25.9205 29.536 26.5197L32.9466 34.3787C33.3807 35.5592 33.0027 35.9961 32.6095 36.1545C32.3248 36.2681 32.0156 36.3268 31.7028 36.3266ZM29.0593 31.9441L27.6345 28.4006L26.2097 31.9441H29.0593ZM35.2423 36.1607C34.4884 36.1607 33.8752 35.647 33.8752 35.016V26.5345C33.8752 25.8458 34.5286 25.2857 35.3314 25.2857C36.1343 25.2857 36.7876 25.8458 36.7876 26.5345V33.8712H39.8188C40.5727 33.8712 41.1858 34.3849 41.1858 35.016C41.1858 35.647 40.5727 36.1607 39.8188 36.1607H35.2423ZM43.1672 36.3266C42.3805 36.3266 41.7407 35.7665 41.7407 35.0778V26.5345C41.7407 25.8458 42.3805 25.2857 43.1672 25.2857C43.9539 25.2857 44.5937 25.8458 44.5937 26.5345V29.2186L48.5735 25.7344C48.7782 25.5552 49.0594 25.4566 49.3647 25.4566C49.7208 25.4566 50.0784 25.591 50.3463 25.8254C50.5962 26.0439 50.7453 26.3251 50.7657 26.6171C50.7864 26.9116 50.6744 27.1816 50.4509 27.3774L47.2003 30.2228L50.7115 34.2952C50.9405 34.5591 51.0391 34.8923 50.9854 35.2203C50.934 35.5486 50.7347 35.8452 50.4319 36.0439C50.1852 36.2081 49.8837 36.2967 49.5739 36.296C49.1257 36.2978 48.703 36.1137 48.4344 35.7996L45.0891 31.9191L44.5941 32.3525V35.0772C44.5932 35.7668 43.9549 36.3257 43.1672 36.3266Z"
			fill="#FFE812"
		/>
	</svg>
);

const IconCopy = (
	<svg className="absolute" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="vuesax/bold/link-square">
			<g id="link-square">
				<path
					id="Vector"
					d="M51.269 6.33334H24.7323C13.2057 6.33334 6.33398 13.205 6.33398 24.7317V51.2367C6.33398 62.795 13.2057 69.6667 24.7323 69.6667H51.2373C62.764 69.6667 69.6357 62.795 69.6357 51.2683V24.7317C69.6673 13.205 62.7957 6.33334 51.269 6.33334ZM25.904 53.105C25.8407 53.105 25.7457 53.105 25.6823 53.105C22.6107 52.82 19.729 51.395 17.5757 49.115C12.509 43.795 12.509 35.15 17.5757 29.83L24.5107 22.5467C26.9806 19.95 30.3057 18.4933 33.8523 18.4933C37.399 18.4933 40.724 19.9183 43.194 22.5467C48.2607 27.8667 48.2607 36.5117 43.194 41.8317L39.7423 45.4733C38.824 46.4233 37.3357 46.455 36.3857 45.5683C35.4357 44.65 35.404 43.1617 36.2907 42.2117L39.7423 38.57C43.099 35.055 43.099 29.3233 39.7423 25.84C36.6073 22.5467 31.0973 22.5467 27.9307 25.84L20.9957 33.1233C17.639 36.6383 17.639 42.37 20.9957 45.8533C22.3573 47.31 24.194 48.1967 26.1257 48.3867C27.424 48.5133 28.374 49.685 28.2473 50.9833C28.1523 52.1867 27.1073 53.105 25.904 53.105ZM58.4257 46.2017L51.4907 53.485C49.0207 56.0817 45.6957 57.5383 42.149 57.5383C38.6023 57.5383 35.2773 56.1133 32.8073 53.485C27.7407 48.165 27.7407 39.52 32.8073 34.2L36.259 30.5583C37.1773 29.6083 38.6657 29.5767 39.6157 30.4633C40.5657 31.3817 40.5973 32.87 39.7107 33.82L36.259 37.4617C32.9023 40.9767 32.9023 46.7083 36.259 50.1917C39.394 53.485 44.904 53.5167 48.0707 50.1917L55.0057 42.9083C58.3623 39.3933 58.3623 33.6617 55.0057 30.1783C53.644 28.7217 51.8073 27.835 49.8757 27.645C48.5773 27.5183 47.6273 26.3467 47.754 25.0483C47.8807 23.75 49.0207 22.7683 50.3507 22.9267C53.4223 23.2433 56.304 24.6367 58.4573 26.9167C63.4923 32.205 63.4923 40.8817 58.4257 46.2017Z"
					fill="#65737E"
				/>
			</g>
		</g>
	</svg>
);
