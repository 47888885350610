import SvgIcon from "../components/SvgIcon";
import voteIcon from "../image/vote.svg";
import closeIcon from "../image/close.svg";
import { useEffect, useMemo, useState } from "react";
import { CHATROOMS } from "../api";
import { useParams } from "react-router-dom";

export default function VoteMain() {
	const [votes, setVotes]: any = useState();
	const { chat_room_id } = useParams();

	useEffect(() => {
		CHATROOMS.getCommunityClupVotelist(chat_room_id).then((res) => {
			setVotes(res);
		});
	}, []);

	function dateFormat(date: Date) {
		let month: any = date.getMonth() + 1;
		let day: any = date.getDate();

		return `${date.getFullYear()}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
	}

	// 활성화된 투표
	const filteredActivate = useMemo(() => {
		return votes?.filter((e: any) => new Date(e.endedAt).getTime() >= new Date().getTime()) ?? [];
	}, [votes]);

	// 지난 투표
	const filteredDeactivate = useMemo(() => {
		return votes?.filter((e: any) => new Date(e.endedAt).getTime() < new Date().getTime()) ?? [];
	}, [votes]);

	const VoteCard = ({ item }: any) => {
		const passedDate = new Date(item?.endedAt).getTime() > new Date().getTime();
		const image = item?.User?.UserFiles?.find((v: any) => v)?.File.url;

		return passedDate ? (
			<div
				className="border border-[#DADADA] px-[10px] py-[14px] cursor-pointer"
				onClick={() => {
					window.location.href = `/community/${chat_room_id}/vote/${item.id}`;
				}}>
				<div className="w-full flex justify-between items-center mb-[10px]">
					<div className="text-[16px]">{item?.title}</div>
					<div className="bg-[#0057FF] rounded-[23px] h-[15px] text-white text-[10px] px-[9px]">{passedDate ? "투표중" : "투표종료"}</div>
				</div>
				<div className="flex justify-between">
					<div className="text-[14px] font-medium">
						<div className="mb-[10px]">
							{dateFormat(new Date(item?.startedAt))} ~ {dateFormat(new Date(item?.endedAt))}
						</div>
						<div className="text-[#777777]">{item?.description}</div>
					</div>
					<div className="w-[50px] aspect-square rounded-full overflow-hidden">
						<img className="w-full h-full object-cover bg-gray-200" src={image} />
					</div>
				</div>
			</div>
		) : (
			<div
				onClick={() => {
					window.location.href = `/community/${chat_room_id}/vote/${item.id}`;
				}}
				className="border border-[#DADADA] px-[10px] py-[14px] cursor-pointer">
				<div className="w-full flex justify-between items-center mb-[10px]">
					<div className="text-[16px]">{item?.title}</div>
					<div className="bg-[#ECECEC] rounded-[23px] h-[15px] text-[#777777] text-[10px] px-[9px]"> 투표종료</div>
				</div>
				<div className="flex justify-between text-[#777777]">
					<div className="text-[14px] font-medium">
						<div className="mb-[10px]">
							{dateFormat(new Date(item?.startedAt))} ~ {dateFormat(new Date(item?.endedAt))}
						</div>
						<div className="text-[#777777]">{item?.description}</div>
					</div>
					<div className="w-[50px] aspect-square rounded-full overflow-hidden">
						<img className="w-full h-full object-cover bg-gray-200" src={image} />
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="px-[10px]">
					<div className="flex py-[20px] border-b border-[#999999] gap-[5px]">
						<SvgIcon className="w-[22px] h-[22px] bg-black" icon={voteIcon} />
						<div className="flex justify-between flex-1">
							<div className="text-[16px] text-[#0E1111] font-bold ">투표하기</div>
							<div onClick={() => window.history.back()}>
								<SvgIcon className="w-[18px] h-[18px] bg-black" icon={closeIcon} />
							</div>
						</div>
					</div>
					<div className="py-[10px] flex flex-col gap-[10px]">
						{filteredActivate.length > 0 ? (
							filteredActivate.map((e: any, i: any) => <VoteCard item={e}></VoteCard>)
						) : (
							<div className="w-full h-[200px] flex justify-center items-center text-[#777777] text-[20px]">현재 투표중인 상황이 없습니다</div>
						)}
					</div>
				</div>
				<hr />
				<div className="px-[10px] ">
					<div className="flex py-[20px] border-b border-[#999999] gap-[5px]">
						<SvgIcon className="w-[20px] h-[20px] bg-black" icon={voteIcon} />
						<div className="flex justify-between flex-1">
							<div>지난 투표 결과</div>
						</div>
					</div>
					<div className="py-[10px] flex flex-col gap-[10px]">
						{filteredDeactivate.length > 0 ? (
							filteredDeactivate.map((e: any, i: any) => {
								return <VoteCard item={e}></VoteCard>;
							})
						) : (
							<div className="w-full h-[200px] flex justify-center items-center text-[#777777] text-[20px]">지난 결과 투표가 없습니다</div>
						)}
					</div>
				</div>

				<hr />

				<div className="px-[10px] mt-[40px]">
					<div
						className="w-full h-[40px] border border-[#0057FF] box-border flex justify-center items-center text-[16px] font-bold text-[#0057FF] "
						onClick={() => {
							window.location.href = `/community/${chat_room_id}/create_vote`;
						}}>
						투표 등록하기
					</div>
				</div>
			</div>
		</div>
	);
}
