import { useParams } from "react-router-dom";
import ChatRoomCalendar from "../components/modal/ChatRoomCalendar";

export default function CalendarIndex() {
	const { chat_room_id } = useParams();

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<ChatRoomCalendar chatroomId={+(chat_room_id ?? 0)} onCancel={() => window.history.back()} />
			</div>
		</div>
	);
}
