export function login(token: string) {
  localStorage.setItem('admin-token', token);
  return true;
}

export function logout() {
  localStorage.removeItem('admin-token');
  window.location.href = '/admin'
}

export function getToken() {
  return localStorage.getItem("admin-token");
}

