import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../image/orderMadeTrip_Logo.png";
import user_square from "../image/user-square.svg";
import { PARTNER, USER } from "../api";
import { logout } from "../auth";
// import "bootstrap/dist/css/bootstrap.css";

const Header = (props: any) => {
	const [isSubNavOpen, setSubNavOpen] = useState(false);
	const [isUser, setIsUser] = useState(false);
	const [isPartner, setIsPartner] = useState(false);

	const toggleSubNav = () => {
		setSubNavOpen(!isSubNavOpen);
	};

	useEffect(() => {
		USER.getUser()
			.then((res) => {
				setIsUser(true);
			})
			.catch((e) => {
				if (e.request.status === 404) {
					alert("탈퇴한 회원입니다.");
					logout();
					return (window.location.href = "/");
				}
				PARTNER.getMe()
					.then((res) => {
						setIsPartner(true);
					})
					.catch((e) => {});
			});
	}, []);

	const navigate = useNavigate();
	const isLoggedIn = Boolean(Cookies.get("isLoggedIn")); // 로그인 상태 확인 로직
	const partnerLogin = Boolean(Cookies.get("partnerLogin")); // 파트너 여부 확인 로직

	const handleClick = () => {
		const token = window.localStorage.getItem("token") || window.sessionStorage.getItem("token");

		if (token) {
			navigate("/mypage"); // 마이페이지로 이동
		} else {
			navigate("/login"); // 로그인 페이지로 이동
		}
	};

	return (
		<header>
			<div className="headerLogoAndIcon container ">
				<a href="/">
					<img className="headerLogo" src={logo} alt="오더메이드트립 헤더 로고" />
				</a>
				<a className={`userSquare clickable ${props.activedMenu === 0 ? "blue-overlay" : ""}`} onClick={handleClick}>
					<img src={user_square} alt="마이페이지" />
				</a>
			</div>
			<div className="mainNav d-flex justify-content-center flex justify-center">
				<div className="flex xl:max-w-[1200px] w-full">
					<div className={`flex-1 col-2 headerItem cursor-pointer ${props.activedMenu === 1 ? "active" : ""}`} onClick={toggleSubNav}>
						오더메이드
						<br />
						트립
					</div>
					<div className={`flex-1 col-2 headerItem cursor-pointer ${props.activedMenu === 2 ? "active" : ""}`} onClick={toggleSubNav}>
						크루들의
						<br />
						추천여행
					</div>
					<div className={`flex-1 col-2 headerItem cursor-pointer ${props.activedMenu === 3 ? "active" : ""}`} onClick={toggleSubNav}>
						여행
						<br />
						주문하기
					</div>
					<div className={`flex-1 col-2 headerItem cursor-pointer ${props.activedMenu === 4 ? "active" : ""}`} onClick={toggleSubNav}>
						여행
						<br />
						모집하기
					</div>
					<div className={`flex-1 col-2 headerItem cursor-pointer ${props.activedMenu === 5 ? "active" : ""}`} onClick={toggleSubNav}>
						커뮤니티
						<br />
						클럽
					</div>
					<div className={`flex-1 col-2 headerItem cursor-pointer ${props.activedMenu === 6 ? "active" : ""}`} onClick={toggleSubNav}>
						고객
						<br />
						서비스
					</div>
				</div>
			</div>
			{isSubNavOpen && (
				<div className="subNav d-flex justify-content-center flex justify-center">
					<div className="container row flex xl:max-w-[1200px] xl:w-full">
						<div className="col-2 flex-1">
							<a className="headerSubitem h-6" href="/brand_story">
								브랜드 스토리
							</a>
						</div>
						<div className="col-2 flex-1">
							<a className="headerSubitem h-6" href="/ourdestination">
								우리의 목적지
							</a>
							<a className="headerSubitem h-6" href="/recommendTrip">
								추천여행 만들기
							</a>
						</div>
						<div className="col-2 flex-1">
							<a className="headerSubitem h-6" href="/travel_howToOrder">
								여행 주문방법
							</a>
							<div
								className="headerSubitem h-6 cursor-pointer"
								onClick={() => {
									if (isUser) window.location.href = "/travel_Order";
									else if (isPartner) alert("파트너는 접근할 수 없는 페이지입니다.");
									else {
										alert("로그인 후 이용해주세요");
										window.location.href = "/login";
									}
								}}>
								여행 주문하기
							</div>
						</div>
						<div className="col-2 flex-1">
							<a className="headerSubitem h-6" href="/assemble_howToUse">
								모집/참여방법
							</a>
							<div
								className="headerSubitem h-6 cursor-pointer"
								onClick={() => {
									if (isUser) window.location.href = "/assemble_make";
									else if (isPartner) alert("파트너는 접근할 수 없는 페이지입니다.");
									else {
										alert("로그인 후 이용해주세요");
										window.location.href = "/login";
									}
								}}>
								여행 모집하기
							</div>
							<div
								className="headerSubitem h-6 cursor-pointer"
								onClick={() => {
									if (isUser) window.location.href = "/assemble_participate";
									else if (isPartner) alert("파트너는 접근할 수 없는 페이지입니다.");
									else {
										alert("로그인 후 이용해주세요");
										window.location.href = "/login";
									}
								}}>
								여행 참여하기
							</div>
						</div>
						<div className="col-2 flex-1">
							<a className="headerSubitem h-6" href="/community_howToUse">
								커뮤니티 사용법
							</a>
							<div
								className="headerSubitem h-6 cursor-pointer"
								onClick={() => {
									if (isUser) window.location.href = "/community_makeCommunity";
									else if (isPartner) alert("파트너는 접근할 수 없는 페이지입니다.");
									else {
										alert("로그인 후 이용해주세요");
										window.location.href = "/login";
									}
								}}>
								커뮤니티 만들기
							</div>
							<a className="headerSubitem h-6" href="/community_recruit">
								모집 중 커뮤니티
							</a>
							<a className="headerSubitem h-6" href="/community_planning">
								계획 중 커뮤니티
							</a>
						</div>
						<div className="col-2 flex-1">
							<a className="headerSubitem h-6" href="/notice">
								공지사항
							</a>
							<a className="headerSubitem h-6" href="/event">
								이벤트
							</a>
							<a className="headerSubitem h-6" href="/faq">
								FAQ
							</a>
							<a className="headerSubitem h-6" href="/inquiry">
								1:1 문의
							</a>
							<a className="headerSubitem h-6" href="/partnerships">
								제휴 서비스
							</a>
						</div>
					</div>
				</div>
			)}
		</header>
	);
};

export default Header;
