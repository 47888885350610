import React from "react";

type CheckboxProps = {
	labelPosition?: "left" | "right";
	labelText: string;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
};

export default function InputCheckbox({ labelPosition = "right", labelText, checked, onChange, className }: CheckboxProps) {
	const checkboxId = `checkbox-${Math.random()}`;
	// 체크박스 활성화 상태에 따라 스타일을 변경하는 함수
	const styleWhenChecked = (checked: boolean) => (checked ? "border-success" : "border-gray-200");

	return (
		<div className={[labelPosition === "left" ? "flex flex-row-reverse" : "flex", className].join(" ")}>
			<input
				type="checkbox"
				id={checkboxId}
				className="form-checkbox hidden" // 숨겨진 실제 체크박스
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={checkboxId} className="flex items-center">
				<div className={`flex h-4 w-4 items-center justify-center rounded-[4px] border ${styleWhenChecked(checked)}`}>
					{checked && (
						<svg className={["relative top-[1px]"].join(" ")} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M12.0791 3.08687C12.307 3.31468 12.307 3.68402 12.0791 3.91183L5.66248 10.3285C5.43467 10.5563 5.06533 10.5563 4.83752 10.3285L1.92085 7.41183C1.69305 7.18402 1.69305 6.81468 1.92085 6.58687C2.14866 6.35906 2.51801 6.35906 2.74581 6.58687L5.25 9.09106L11.2542 3.08687C11.482 2.85906 11.8513 2.85906 12.0791 3.08687Z"
								fill="#0057FF"
							/>
						</svg>
					)}
				</div>

				<span className={["text-sm font-normal text-black", labelPosition === "right" ? "ml-2" : "mr-2"].join(" ")}>{labelText}</span>
			</label>
		</div>
	);
}
