import { useEffect, useState } from "react";
import EasyTable from "../../components/EasyTable";
import { EVENT, FRANCHISE, USER } from "../../admin_pages/api";
import EasyPagination from "../../components/EasyPagination";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";
import { DEFAULT } from "../../api";
import { id, sl } from "date-fns/locale";
import { format } from "date-fns";

export default function AdminFranchisePage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'
	const [selected, setSelected] = useState<any>(null);

	useEffect(() => {
		getData();
	}, [page]);

	const columns = [
		{
			header: "ID",
			key: "id",
		},
		{
			header: "타입",
			key: "type",
		},
		{
			header: "제목",
			key: "title",
		},
		{
			header: "내용",
			key: "content",
		},
		{
			header: "이미지",
			key: "imageFile",
			render: (imageFile: any) => (
				<a target="_blank" className="text-blue-500" href={imageFile.url}>
					{imageFile.url}
				</a>
			),
		},
		{
			header: "수정",
			key: "edit",
			render: (value: any, row: any, index: any) => (
				<button onClick={() => handleUpdateModal(value, row, index)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			),
		},
		{
			header: "삭제",
			key: "delete",
			render: (value: any, row: any) => (
				<button onClick={() => handleDelete(row.id)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
			),
		},
	];

	const schema = [];

	const handleUpdateModal = (value: any, row: any, index: any) => {
		setModal("update");
		setSelected(row);
	};

	const getData = async () => {
		const response = await FRANCHISE.pagination(page.page, page.perPage);

		setData(response.nodes);
		setCount(response.total);
	};

	const handleDelete = async (id: number) => {
		await FRANCHISE.delete(id);
		getData();
	};

	const handleCreate = async (data: any) => {
		await FRANCHISE.create({
			...data,
			imageFileId: data.imageFileId.id,
		});
		setModal("");
		getData();
	};

	const handleUpdate = async (data: any) => {
		await FRANCHISE.update(selected.id, {
			...data,
			imageFileId: data.imageFileId.id,
		});
		setSelected(null);
		setModal("");
		getData();
	};

	return (
		<div>
			<h1>제휴서비스 관리</h1>
			<div className="flex justify-end">
				<button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable data={data} columns={columns} />
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>

				<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
					<div>추가</div>
					<div>
						<FormFactory
							schema={[
								{ label: "type", key: "type", method: "text", validation: "required" },
								{ label: "제목", key: "title", method: "text", validation: "required" },
								{ label: "내용", key: "content", method: "text", validation: "required" },
								{ label: "이미지", key: "imageFileId", method: "file", validation: "required" },
								{ label: "url", key: "url", method: "text", validation: "required" },
							]}
							onSubmit={handleCreate}
						/>
					</div>
				</Modal>

				{selected && (
					<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
						<div className="mb-6">수정</div>

						<FormFactory
							schema={[
								{ label: "type", key: "type", method: "text", validation: "required", defaultValue: selected?.type },
								{ label: "제목", key: "title", method: "text", validation: "required", defaultValue: selected?.title },
								{ label: "내용", key: "content", method: "text", validation: "required", defaultValue: selected?.content },
								{ label: "이미지", key: "imageFileId", method: "file", validation: "required", defaultValue: selected?.imageFile },
								{ label: "url", key: "url", method: "text", validation: "required", defaultValue: selected?.url },
							]}
							onSubmit={handleUpdate}
						/>
					</Modal>
				)}
			</div>
		</div>
	);
}
