import { useEffect, useMemo, useState } from "react";
import { DEFAULT, FAQ, FRANCHISE, NOTICE } from "../api";
import { format, set } from "date-fns";

export default function PartnershipsPage() {
	const [franchise, setFranchise] = useState<any>([]);
	const [tabManues, setTabManues] = useState<any>(["전체"]);

	useEffect(() => {
		FRANCHISE.getMany().then((res: any) => {
			setFranchise(res);
		});
		DEFAULT.getServiceConfig().then((res) => {
			setTabManues(["전체", ...res.franchiseCategories]);
		});
	}, []);

	const [tab, setTab] = useState<any>("전체");

	const [selectedFranchise, setSelectedFranchise] = useState<any>(null);
	const handleClickNotice = (id: any) => {
		if (selectedFranchise === id) {
			setSelectedFranchise(null);
			return;
		}
		setSelectedFranchise(id);
	};

	const filtered = useMemo(() => {
		if (tab === "전체") return franchise;

		return franchise.filter((faq: any) => {
			console.log(faq.type, tab, faq.type === tab);
			return faq.type === tab;
		});
	}, [tab, franchise]);

	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full">
				<div className="text-black text-2xl font-bold mt-12 text-center">제휴사 할인</div>
				<div className="text-center text-sm font-medium mt-2.5 mb-5">
					항공권, 여행자 보험, 면세점 등
					<br />
					제휴사를 통해 다양한 혜택을 받아보세요.
				</div>

				<div className="mt-5 px-3 flex gap-4 shadow-lg overflow-x-scroll flex-nowrap scrollbar-hide">
					{tabManues.map((_tab: any) => {
						const selected = tab === _tab;
						return (
							<div
								onClick={() => setTab(_tab)}
								className={[
									"flex-none transition-all w-20 py-3.5 text-xs font-medium text-center cursor-pointer",
									selected ? "border-b border-success text-success" : "text-black",
								].join(" ")}>
								{_tab}
							</div>
						);
					})}
				</div>

				<div className="grid grid-cols-2 gap-4 mt-8 px-3">
					{filtered.map((fran: any) => {
						const image = fran.imageFile?.url;
						const selected = selectedFranchise === fran.id;
						return (
							<div className="border border-[#dadada] cursor-pointer" onClick={() => window.open(fran.url, "__blank")}>
								<div
									className="h-[77.5px] w-full bg-cover bg-center"
									style={{
										backgroundImage: `url(${image})`,
									}}></div>
								<div className="px-3">
									<div className="border border-b border-[#dadada]"></div>
								</div>

								<div className="text-black text-sm font-bold line-clamp-1 text-center px-4 mt-5">{fran.title}</div>
								<div className="text-[#424549] text-sm font-medium line-clamp-1 px-6 text-center mt-2.5">{fran.content}</div>
								<div className="flex justify-center cursor-pointer">
									<div className="text-success text-xs font-medium border border-[#dadada] rounded-[20px] py-0.5 px-2 mt-5 mb-4">자세히 보기</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
