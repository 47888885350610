export default function IconNext() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#0E1111"
        strokeWidth="1.2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.7383 15.5302L14.2583 12.0002L10.7383 8.47021" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
    </svg>
  );
}
