import { useEffect, useRef, useState } from "react";
import customerIlust from "../image/customerRegister.png";
import partnerRegister from "../image/partnerRegister.png";
import phoneIcon from "../image/phone.svg";
import { AUTH } from "../api";
import client from "../axios";

export default function RegisterSelectPage() {
	const [token, setToken] = useState<string>("");
	const popup = useRef<Window | null>(null);
	const inicisForm = useRef<HTMLFormElement>(null);

	useEffect(() => {
		AUTH.getPhoneAuthToken().then((data) => {
			setToken(data);
		});

		// @ts-ignore
		window.receiveDataFromPopup = function (data) {
			if (popup.current) {
				popup.current.close();
			}

			if (data.phone === undefined || data.userName === undefined || data.birthday === undefined) {
				alert("이미 가입된 계정입니다");
				// error handling
			} else {
				window.location.href = "/register?phone=" + data.phone + "&userName=" + data.userName + "&userBirthday=" + data.birthday;
			}
		};
	}, []);

	const handleInicisAuth = () => {
		const width = 400;
		const height = 620;
		let xPos = (window.screen.width - width) / 2;
		xPos += window.screenLeft;

		popup.current = window.open(
			"",
			"sa_popup",
			"width=" + width + ", height=" + height + ", left=" + xPos + ", menubar=yes, status=yes, titlebar=yes, resizable=yes"
		);

		if (inicisForm.current) {
			inicisForm.current.setAttribute("target", "sa_popup");
			inicisForm.current.setAttribute("post", "post");
			inicisForm.current.setAttribute("action", "https://sa.inicis.com/auth");
			inicisForm.current.submit();
		}
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="mt-4 px-3">
					{/* 이니시스용 임시 폼 */}
					<form name="saForm" className="hidden" ref={inicisForm}>
						<input type="text" name="mid" value="ordermtrip" readOnly /> mid <br />
						<input type="text" name="reqSvcCd" value="01" readOnly /> reqSvcCd <br />
						<input type="text" name="mTxId" value="test_20210625" readOnly /> mTxId <br />
						<input type="text" name="authHash" value={token} readOnly /> authHash <br />
						<input type="text" name="flgFixedUser" value="N" readOnly /> flgFixedUser
						<br />
						<input type="text" name="userName" value="홍길동" readOnly /> userName <br />
						<input type="text" name="userPhone" value="01011112222" readOnly /> userPhone <br />
						<input type="text" name="userBirth" value="19800101" readOnly /> userBirth <br />
						<input type="text" name="userHash" value="" readOnly /> userHash <br />
						<input type="text" name="reservedMsg" value="isUseToken=N" readOnly /> reservedMsg <br />
						<input type="text" name="directAgency" value="" readOnly /> directAgency <br />
						<input type="text" name="successUrl" value={`${client.defaults.baseURL}/auth/kg-inicis-hash/success`} readOnly /> successUrl <br />
						<input type="text" name="failUrl" value={`${client.defaults.baseURL}/auth/kg-inicis-hash/success`} readOnly /> failUrl <br />
						{/* <input type="text" name="successUrl" value="http://localhost:8080/auth/kg-inicis-hash/success" readOnly /> successUrl <br />
						<input type="text" name="failUrl" value="http://localhost:8080/auth/kg-inicis-hash/success" readOnly /> failUrl <br /> */}
					</form>

					{/* 사용자 회원가입 */}
					<div className="border border-[#dadada] cursor-pointer" onClick={handleInicisAuth}>
						<div>
							<div className="my-4 flex w-full justify-center text-center">
								오더 메이드 트립에 가입하고
								<br />
								다양한 친구들과 여행을 떠나보세요
							</div>
							<div className="mt- px-9">
								<img src={customerIlust} alt="customer" />
							</div>
						</div>
						<div className="bg-success mt-4 flex h-[45px] items-center justify-center gap-4 rounded-bl-sm rounded-br-sm text-sm font-bold text-white">
							<img className="w-6" src={phoneIcon} alt="phone" />
							휴대폰 본인 인증하고 가입하기
						</div>
					</div>

					{/* 파트너 회원가입 */}
					<div className="mt-2 border border-[#dadada] cursor-pointer">
						<div>
							<div className="my-4 flex w-full justify-center text-center">
								오더 메이드 트립의 파트너가 되어
								<br />
								다양한 상품을 소개해 주세요
							</div>
							<div className="mt- px-9">
								<img src={partnerRegister} alt="customer" />
							</div>
						</div>
						<div
							onClick={() => (window.location.href = "/partnerRegister")}
							className="border-success text-success mt-4 flex h-[45px] items-center justify-center gap-4 rounded-bl-sm rounded-br-sm border bg-white text-sm font-bold ">
							파트너 회원가입
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
