import { useEffect, useState } from "react";
import { KAKAO_BIZTALK_LOG } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";
import EasyPagination from "../../components/EasyPagination";
import { format } from "date-fns";

export default function AdminBizTalkLogPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});

	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [filter, setFilter] = useState<any>("");

	useEffect(() => {
		console.log(`filter: ${filter}`);
		getData();
	}, [page, filter]);

	const getData = async () => {
		if (filter === "today") {
			const response = await KAKAO_BIZTALK_LOG.pagination(page.page, page.perPage, format(new Date(), "yyyy-MM-dd"));
			setData(response.nodes);
			setCount(response.total);
		} else {
			const response = await KAKAO_BIZTALK_LOG.pagination(page.page, page.perPage);
			setData(response.nodes);
			setCount(response.total);
		}
	};

	const handleGetAndCreateResults = async () => {
		const response = await KAKAO_BIZTALK_LOG.getAndCreate();
		console.log(`code(${response['response_code']}) - msg: ${response['msg']}`);
		getData();
	};

	return (
		<div>
			<h1>알림톡 전송결과 조회</h1>
			<div className="flex justify-end">
				{/* <input
					className="py-[6px] px-[7px] bg-transparent w-full h-[30px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] font-spoqa" // Replace with actual class names for styling
					type="date"
					min={new Date().toISOString().split("T")[0]}
					value={startDate}
				/> */}
				<button onClick={() => {
						if (filter === "today") setFilter("");
						else setFilter("today");
					}}
					className={[
						"border border-gray-600  px-3 py-1 rounded-sm font-normal text-sm",
						filter === "today" ? "bg-gray-500 text-white" : "bg-gray-200 text-black",
					].join(" ")}>
					당일만 조회 필터
				</button>
				<button onClick={handleGetAndCreateResults}
					className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-md hover:bg-indigo-600 focus:outline-none">
					전송결과 조회 (비지톡)
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable
						data={data}
						columns={[
							{ header: "비즈톡-SEQ", key: "uid" },
							{ header: "SEQ", key: "msgIdx" },
							{ header: "결과코드", key: "resultCode" },
							{ header: "결과코드내용", key: "resultCodeName" },
							{ header: "전송시간", key: "requestAt" },
							{ header: "수신시간", key: "receivedAt" },
						]}
					/>
				</div>
				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
}
