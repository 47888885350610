import "./SectionCommingSoon.scss";
import mainImage from "../../image/father-and-child.png";

function SectionCommingSoon() {
	return (
		<div className="section-comming-soon">
			<div className="section-comming-soon__title">여러분을 위한 새로운 콘텐츠를 준비중입니다!</div>
			<div className="section-comming-soon__sub_title">잠시만 기다려주세요 🥰</div>

			<div className="section-comming-soon__image">
				<img src={mainImage} />
			</div>
		</div>
	);
}

export default SectionCommingSoon;
