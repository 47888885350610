import { useEffect, useMemo, useState } from "react";
import { ORDER, PARTNER, PARTNER_QUOTATION } from "../api";
import IconEarth from "../components/icon/earth";
import InputList from "../components/input/list";
import { add, format, parse } from "date-fns";
import PartnerMyPagePartnerCard from "../components/mypage/partner/card";
import Pagination from "../components/pagination";
import PartnerMyPagePartnerChatCard from "../components/mypage/partner/chatCard";

const IconReport = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10.2996 21.5998H5.4996C4.17411 21.5998 3.0996 20.5253 3.09961 19.1998L3.0997 4.79989C3.09971 3.47441 4.17422 2.3999 5.4997 2.3999H16.3C17.6255 2.3999 18.7 3.47442 18.7 4.7999V11.3999M13.9 18.1999L16.1 20.3999L20.9 15.5997M7.29998 7.1999H14.5M7.29998 10.7999H14.5M7.29998 14.3999H10.9"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export default function PartnerMyOrderStatusPage() {
	const [data, setData] = useState<any[]>([]);
	const [count, setCount] = useState(0);

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [selectedFilter, setSelectedFilter] = useState<string | undefined>("QUOTATION_WAITING");
	const [startDate, setStartDate] = useState<string>();
	const [endDate, setEndDate] = useState<string>();
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [me, setMe] = useState<any>(null);

	useEffect(() => {
		PARTNER.getMe().then((data) => setMe(data));
	}, []);

	useEffect(() => {
		const sDate = startDate ? startDate : new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365).toISOString();

		const eDate = new Date().toISOString();

		if (selectedFilter === "CONFIRM") {
			ORDER.answer(page, perPage, "답변완료", sDate, eDate).then((res) => {
				setData(res.nodes);
				setCount(res.total);
			});
		} else {
			ORDER.answer(page, perPage, "미답변", sDate, eDate).then((res) => {
				setData(res.nodes);
				setCount(res.total);
			});
		}

		// if (selectedFilter === "CONFIRM") {
		// 	PARTNER_QUOTATION.getOwnedConfirmed(page, perPage, "CONFIRM", sDate, eDate).then((res) => {
		// 		setData(
		// 			res.nodes.map((e: any) => {
		// 				return { ...e.Order, Partner: e.Partner };
		// 			})
		// 		);
		// 		setCount(res.total);
		// 	});
		// } else {
		// 	ORDER.paginationByPartner(page, perPage, selectedFilter, sDate, eDate).then((data) => {
		// 		setData(data.nodes);
		// 		setCount(data.total);
		// 	});
		// }
	}, [selectedFilter, startDate, endDate, page, perPage]);

	const dateKeys = useMemo(() => {
		const set = new Set();
		for (const item of data) {
			set.add(format(new Date(item.createdAt), "yyyy.MM.dd"));
		}
		// @ts-ignore
		const v = [...set.values()];
		v.sort((a, b) => b - a);

		return v;
	}, [data]);

	const getOrdersByDate = (date: string) => {
		return data.filter((item) => {
			return format(new Date(item.createdAt), "yyyy.MM.dd") === date;
		});
	};

	const filters = [
		// { label: "전체", value: undefined },
		{ label: "미답변", value: "QUOTATION_WAITING" },
		{ label: "답변완료", value: "CONFIRM" },
	];

	const dates = [
		{ label: "1주", value: "1주" },
		{ label: "1개월", value: "1개월" },
		{ label: "3개월", value: "3개월" },
		{ label: "전체", value: "전체" },
	];

	useEffect(() => {
		if (selectedDate === null) return;
		else if (selectedDate === "1주") {
			setStartDate(format(add(new Date(), { days: -7 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "1개월") {
			setStartDate(format(add(new Date(), { months: -1 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "3개월") {
			setStartDate(format(add(new Date(), { months: -3 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "전체") {
			setStartDate("");
			setEndDate("");
		}
	}, [selectedDate]);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="text-success flex px-3 gap-1 mt-5 font-medium">{IconReport}주문서 답변</div>
				<div className="px-3 mt-6">
					<div className="h-[1px] w-full bg-[#dadada]"></div>
				</div>

				<div className="mt-5 font-medium text-sm text-black px-3">주문상태</div>

				<div className="flex px-3 mt-3 gap-3">
					{filters.map((item) => {
						const isSelected = selectedFilter === item.value;
						return (
							<div
								onClick={() => setSelectedFilter(item.value)}
								className={[
									"w-20 text-sm font-normal flex justify-center items-center border py-1.5 rounded-sm cursor-pointer",
									isSelected ? "border-success text-success" : "border-[#dadada] text-black",
								].join(" ")}>
								{item.label}
							</div>
						);
					})}
				</div>

				<div className="mt-5 font-medium text-sm text-black px-3">기간별 조회</div>
				<div className="px-3 flex gap-2 mt-2.5">
					<input
						value={startDate}
						onChange={(v) => setStartDate(v.target.value)}
						type="date"
						placeholder=" "
						className="rounded-sm bg-transparent text-xs font-normal text-black border border-[#dadada] px-2.5 h-[30px] cursor-pointer"
					/>
					~
					<input
						value={endDate}
						onChange={(v) => setEndDate(v.target.value)}
						type="date"
						placeholder=" "
						className="rounded-sm bg-transparent text-xs font-normal text-black border border-[#dadada] px-2.5 h-[30px] cursor-pointer"
					/>
					<div className="text-xs text-success font-normal flex-none h-[30px] w-[78px] border border-[#dadada] flex justify-center items-center">{count}건</div>
				</div>
				<div className="px-3 mt-2.5 flex gap-2.5">
					{dates.map((item) => {
						const isSelected = selectedDate === item.value;
						return (
							<div
								onClick={() => setSelectedDate(item.value)}
								className={[
									"w-20 text-xs font-normal flex justify-center items-center border py-1.5 rounded-sm cursor-pointer",
									isSelected ? "border-success text-success" : "border-[#dadada] text-black",
								].join(" ")}>
								{item.label}
							</div>
						);
					})}
				</div>

				<div className="mt-6">
					<div className="h-[1px] w-full bg-[#dadada]"></div>
				</div>

				<div>
					{dateKeys.map((v) => {
						return (
							<div className="text-sm px-3 font-medium text-black mt-7">
								{v}
								<div>
									{getOrdersByDate(v).map((e) => (
										<div className="mt-2.5" onClick={() => (window.location.href = "/partner/my/create-quotation?order_id=" + e?.id)}>
											<PartnerMyPagePartnerChatCard order={e} state={selectedFilter} me={me} />
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>

				<Pagination className="mt-8" page={page} perPage={perPage} count={count} onChange={(v) => setPage(v)} />
			</div>
		</div>
	);
}
