import { useParams } from "react-router-dom";
import TypeLabel from "../../components/TypeLabel";
import InputLabel from "../../components/input/labe";
import { useEffect, useMemo, useState } from "react";
import { COMMUNITY_CLUP, USER } from "../../api";
import { format } from "date-fns";
import InputText from "../../components/input/text";
import InputCheckbox from "../../components/input/checkbox";
import Modal from "../../components/modal";

export default function CommunityClupParticipate() {
	const [data, setData] = useState<any>(null);
	const [me, setMe] = useState<any>(null);
	const { community_clup_id } = useParams();
	const [participants, setParticipants] = useState<any>([]);
	const [successModal, setSuccessModal] = useState<boolean>(false);

	useEffect(() => {
		if (!community_clup_id) {
			alert("해당 커뮤니티가 존재하지 않습니다.");
			window.location.href = "/";
			return;
		}

		COMMUNITY_CLUP.getOne(+community_clup_id).then((res: any) => {
			setData(res);
		});

		COMMUNITY_CLUP.getParticipants(+community_clup_id).then((res: any) => {
			setParticipants(res);
		});

		USER.getUser().then((res: any) => {
			setMe(res);
		});
	}, []);

	const handleSubmit = () => {
		if (!form.preferenceAlcohol) {
			alert("기호(술)를 선택해주세요.");
			return;
		}
		if (!form.preferenceSmoke) {
			alert("기호(담배)를 선택해주세요.");
			return;
		}
		if (!form.tripTypes1) {
			alert("여행 성향을 선택해주세요.");
			return;
		}
		if (!form.tripTypes2) {
			alert("여행 성향을 선택해주세요.");
			return;
		}
		if (!form.tripTypes3) {
			alert("여행 성향을 선택해주세요.");
			return;
		}
		if (!form.mbti) {
			alert("MBTI를 입력해주세요.");
			return;
		}
		if (!form.agreedAt) {
			alert("동의해주세요.");
			return;
		}
		if (!community_clup_id) {
			alert("해당 커뮤니티가 존재하지 않습니다.");
			return;
		}

		COMMUNITY_CLUP.participate(+community_clup_id, {
			alcoholPreference: form.preferenceAlcohol,
			smokingPreference: form.preferenceSmoke,
			travelStyle: [form.tripTypes1, form.tripTypes2, form.tripTypes3],
			mbti: form.mbti,
		})
			.then((res: any) => {
				setSuccessModal(true);
				window.location.href = `/community/${community_clup_id}/details`;
			})
			.catch((res) => {
				if (res.response.data.message === "이미 참가한 커뮤니티 클럽입니다.") {
					alert("이미 참가한 커뮤니티 클럽입니다.");
					window.location.href = "/";
				}
				if (res.response.data.message === "모집 인원이 초과되었습니다.") {
					alert("모집 인원이 초과되었습니다.");
					window.location.href = "/";
				}
			});
	};

	const image = useMemo(() => {
		return data?.CommunityClupFile?.find((v: any) => v.type === "PROMOTION")?.File.url ?? null;
	}, [data]);

	const leader = useMemo(() => {
		const leader = participants?.find((v: any) => v.isLeader);
		return leader;
	}, [participants]);

	const userImage = useMemo(() => {
		if (leader) {
			return leader?.User?.UserFiles?.find((v: any) => v.type === "PROFILE")?.File.url ?? null;
		}
		return null;
	}, [leader]);

	const genderText = useMemo(() => {
		if (data?.recruitGenderType === "All") {
			return "남녀무관";
		} else if (data?.recruitGenderType === "Male") {
			return "남자";
		} else if (data?.recruitGenderType === "Female") {
			return "여자";
		}
		return;
	}, [data]);

	const period = useMemo(() => {
		if (!data) {
			return "";
		}

		const start = format(new Date(data.startedAt), "yyyy.MM.dd");
		const end = format(new Date(data.endedAt), "yyyy.MM.dd");
		return `${start} ~ ${end}`;
	}, [data]);

	const [form, setForm] = useState<any>({
		preferenceAlcohol: "",
		preferenceSmoke: "",
		tripTypes1: [],
		tripTypes2: [],
		tripTypes3: [],
		mbti: "ISTJ",
		agreedAt: false,
	});

	const handleChangeForm = (key: string, v: any) => {
		setForm({
			...form,
			[key]: v,
		});
	};

	const preferenceAlcohol = [
		{ label: "가벼운 음주 선호", value: "LIGHT_PREFERENCE" },
		{ label: "음주 비선호", value: "NON_PREFERENCE" },
		{ label: "상관 없어요", value: "INDIFFERENT" },
	];

	const preferenceSmoke = [
		{ value: "LIGHT_PREFERENCE", label: "흡연 선호" },
		{ value: "NON_PREFERENCE", label: "흡연 비선호" },
		{ value: "INDIFFERENT", label: "상관 없어요" },
	];

	const tripTypes1 = [
		{ value: "휴양형", label: "휴양형" },
		{ value: "액티비티형", label: "액티비티형" },
	];
	const tripTypes2 = [
		{ value: "도시형", label: "도시형" },
		{ value: "로컬형", label: "로컬형" },
	];
	const tripTypes3 = [
		{ value: "랜드마크형", label: "랜드마크형" },
		{ value: "모험형", label: "모험형" },
	];

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg mt-5">
				<div className="flex gap-1 px-3">
					<div className="text-black font-bold">참여 여행</div>
					<div className="text-red-500 font-medium text-xs relative top-1.5">*</div>
					<div className="flex-1 flex justify-end">
						<TypeLabel type={data?.type} />
					</div>
				</div>

				<div className="px-3 mt-2.5">
					<div className="flex shadow-lg border border-[#dadada] rounded-sm gap-6">
						<div
							className="flex-none w-44 h-44"
							style={{
								backgroundImage: `url(${image})`,
							}}></div>
						<div className="flex-1 flex flex-col items-start justify-center">
							<div className="flex text-black text-[10px] font-medium items-center gap-1">
								<div
									className="w-5 h-5 rounded-full"
									style={{
										backgroundImage: `url(${userImage})`,
									}}></div>
								{leader?.User?.nickname}
							</div>

							<div className="text-[#777] font-medium text-xs mt-5">
								{data?.numberOfRecruits}명 모집중·{genderText}·{data?.ageGroup}
							</div>
							<div className="text-[#777] font-medium text-xs mt-2.5">{period}</div>
							<div className="text-success text-xs font-bold mt-5">
								{data?.participants.length ?? 0}명 / {data?.numberOfRecruits}명 모집중
							</div>
						</div>
					</div>
				</div>

				<div className="flex gap-1 px-3 mt-5">
					<div className="text-black font-bold">여행 제목</div>
				</div>
				<div className="px-3 mt-2.5">
					<div className="border-[#999] text-sm flex justify-start font-medium px-2 text-[#999] items-center rounded-sm border bg-[#ececec] h-[30px]">
						{data?.title}
					</div>
				</div>

				<div className="flex gap-1 px-3 mt-5">
					<div className="text-black font-bold">내 닉네임</div>
				</div>
				<div className="px-3 mt-2.5">
					<div className="border-[#999] text-sm flex justify-start font-medium px-2 text-[#999] items-center rounded-sm border bg-[#ececec] h-[30px]">
						{me?.nickname}
					</div>
				</div>

				<div className="flex">
					<div className="flex-1">
						<div className="flex gap-1 px-3 mt-5">
							<div className="text-black font-bold">모집 유형</div>
						</div>
						<div className="px-3 mt-2.5">
							<div className="border-[#999] text-sm flex justify-start font-medium px-2 text-[#999] items-center rounded-sm border bg-[#ececec] h-[30px]">
								{genderText}
							</div>
						</div>
					</div>

					<div className="flex-1">
						<div className="flex gap-1 px-3 mt-5">
							<div className="text-black font-bold">모집 연령</div>
						</div>
						<div className="px-3 mt-2.5">
							<div className="border-[#999] text-sm flex justify-start font-medium px-2 text-[#999] items-center rounded-sm border bg-[#ececec] h-[30px]">
								{data?.ageGroup}
							</div>
						</div>
					</div>
				</div>

				<div className="flex gap-1 px-3 mt-5 items-center">
					<div className="text-black font-bold">기호 확인하기 - 술</div>
					<div className="text-red-500 font-medium text-xs relative top-[1px]">*</div>
					<div className="text-[#777] text-xs font-medium">택 1필수 입니다.</div>
				</div>
				<div className="flex gap-4 mt-2.5 px-3">
					{preferenceAlcohol.map((v) => {
						const selected = form.preferenceAlcohol === v.value;
						return (
							<div
								onClick={() => handleChangeForm("preferenceAlcohol", v.value)}
								className={[
									"transition-all py-2 cursor-pointer flex-1 flex justify-center items-center  border   font-medium text-sm",
									selected ? "text-success border-success" : "text-black border-[#dadada]",
								].join(" ")}>
								{v.label}
							</div>
						);
					})}
				</div>

				<div className="flex gap-1 px-3 mt-5 items-center">
					<div className="text-black font-bold">기호 확인하기 - 담배</div>
					<div className="text-red-500 font-medium text-xs relative top-[1px]">*</div>
					<div className="text-[#777] text-xs font-medium">택 1필수 입니다.</div>
				</div>
				<div className="flex gap-4 mt-2.5 px-3">
					{preferenceSmoke.map((v) => {
						const selected = form.preferenceSmoke === v.value;
						return (
							<div
								onClick={() => handleChangeForm("preferenceSmoke", v.value)}
								className={[
									"transition-all py-2 cursor-pointer flex-1 flex justify-center items-center  border   font-medium text-sm",
									selected ? "text-success border-success" : "text-black border-[#dadada]",
								].join(" ")}>
								{v.label}
							</div>
						);
					})}
				</div>

				<div className="flex gap-1 px-3 mt-5 items-center">
					<div className="text-black font-bold">여행 성향</div>
					<div className="text-red-500 font-medium text-xs relative top-[1px]">*</div>
					<div className="text-[#777] text-xs font-medium">3문항 다 체크 해주셔야됩니다.</div>
				</div>
				<div className="flex gap-4 mt-2.5 px-3 items-center">
					{tripTypes1.map((v, index) => {
						const selected = form.tripTypes1 === v.value;
						return (
							<>
								<div
									onClick={() => handleChangeForm("tripTypes1", v.value)}
									className={[
										"transition-all py-2 cursor-pointer flex-1 flex justify-center items-center  border   font-medium text-sm",
										selected ? "text-success border-success" : "text-black border-[#dadada]",
									].join(" ")}>
									{v.label}
								</div>
								{index === 0 && <div className="text-black text-sm font-medium">VS</div>}
							</>
						);
					})}
				</div>

				<div className="flex gap-4 mt-2.5 px-3 items-center">
					{tripTypes2.map((v, index) => {
						const selected = form.tripTypes2 === v.value;
						return (
							<>
								<div
									onClick={() => handleChangeForm("tripTypes2", v.value)}
									className={[
										"transition-all py-2 cursor-pointer flex-1 flex justify-center items-center  border   font-medium text-sm",
										selected ? "text-success border-success" : "text-black border-[#dadada]",
									].join(" ")}>
									{v.label}
								</div>
								{index === 0 && <div className="text-black text-sm font-medium">VS</div>}
							</>
						);
					})}
				</div>

				<div className="flex gap-4 mt-2.5 px-3 items-center">
					{tripTypes3.map((v, index) => {
						const selected = form.tripTypes3 === v.value;
						return (
							<>
								<div
									onClick={() => handleChangeForm("tripTypes3", v.value)}
									className={[
										"transition-all py-2 cursor-pointer flex-1 flex justify-center items-center  border   font-medium text-sm",
										selected ? "text-success border-success" : "text-black border-[#dadada]",
									].join(" ")}>
									{v.label}
								</div>
								{index === 0 && <div className="text-black text-sm font-medium">VS</div>}
							</>
						);
					})}
				</div>

				<div className="flex gap-1 px-3 mt-5">
					<div className="text-black font-bold">나의 MBTI는?</div>
				</div>
				<div className="px-3 mt-2.5">
					<select
						value={form.mbti}
						defaultValue={"ISTJ"}
						onChange={(e) => handleChangeForm("mbti", e.target.value)}
						className="outline-none w-full border-[#999] text-sm flex justify-start font-medium px-2 placeholder:text-[#999] items-center rounded-sm border bg-transparent h-[30px]">
						<option value="ISTJ">ISTJ</option>
						<option value="ISFJ">ISFJ</option>
						<option value="INFJ">INFJ</option>
						<option value="INTJ">INTJ</option>
						<option value="ISTP">ISTP</option>
						<option value="ISFP">ISFP</option>
						<option value="INFP">INFP</option>
						<option value="INTP">INTP</option>
						<option value="ESTP">ESTP</option>
						<option value="ESFP">ESFP</option>
						<option value="ENFP">ENFP</option>
						<option value="ENTP">ENTP</option>
						<option value="ESTJ">ESTJ</option>
						<option value="ESFJ">ESFJ</option>
						<option value="ENFJ">ENFJ</option>
						<option value="ENTJ">ENTJ</option>
						<option value="모름">모름</option>
					</select>
				</div>

				<div className="flex gap-1 px-3 mt-5 items-center">
					<div className="text-black font-bold">커뮤니티 참여 안내사항 동의</div>
					<div className="text-red-500 font-medium text-xs relative top-[1px]">*</div>
				</div>
				<div className="text-black text-xs font-normal px-3 mt-2.5">
					계획형 커뮤니티는 참여인원이 함께 여행을 계획한 후<br />
					견적요청 및 주문하는 방식입니다.
					<br />
					모집 상세내용에 기재된 참여조건을 상세히 확인 후 참여 바랍니다.
				</div>
				<div className="text-black font-normal px-3 text-xs mt-2.5">※ 출발이 확정된 상품을 원할 경우 여행 참여하기 內 모집형 상품을 선택해 주세요!</div>
				<div className="px-3 mt-2.5">
					<InputCheckbox
						checked={form.agreedAt}
						labelText="확인했습니다."
						labelPosition="right"
						onChange={() => handleChangeForm("agreedAt", !form.agreedAt)}
					/>
				</div>
				<div className="px-3">
					<div onClick={handleSubmit} className="cursor-pointer bg-success rounded-sm text-bold text-white flex justify-center items-center py-2.5 mt-5">
						작성완료
					</div>
				</div>
			</div>
			<Modal open={successModal} onClose={() => (window.location.href = "/")}>
				<div
					className="flex justify-end"
					onClick={() => {
						if (data?.type === "PLANNED") {
							window.location.href = `/community/${data?.id}/chat`;
						} else if (data?.type === "RECRUITMENT") {
							window.location.href = "/";
						}
					}}>
					{IconClose}
				</div>
				<div className="text-[80px] font-bold text-center">🎊</div>
				<div className="text-center font-bold">커뮤니티 참여 신청이 완료 되었습니다😉</div>
				<div className="text-center text-sm font-medium mt-4 tracking-tighter">
					신청 후 바로 커뮤니티를 이용하실 수 있습니다
					<br />
					지금부터 크루들과 여행에 대해 소통해 보세요!
				</div>
				<div className="text-sm font-medium text-center mt-4 tracking-tighter">
					신청 하신 커뮤니티 신청 내역은 <br />
					[마이페이지 &gt; 여행 참여현황]에서 확인 가능합니다.
				</div>
			</Modal>
		</div>
	);
}

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
			fill="#999999"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
			fill="#999999"
		/>
	</svg>
);
