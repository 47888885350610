import IconNext from "../../icon/next";

const IconEarth = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M11.041 17.2637C10.4417 17.418 9.81345 17.5 9.16602 17.5C5.02388 17.5 1.66602 14.1421 1.66602 10C1.66602 5.85786 5.02388 2.5 9.16602 2.5C12.9907 2.5 16.1467 5.36283 16.608 9.0625M2.60352 12.8125H4.79102C5.65396 12.8125 6.35352 12.1129 6.35352 11.25V9.21875C6.35352 8.3558 7.05307 7.65625 7.91602 7.65625H9.47852C10.3415 7.65625 11.041 6.9567 11.041 6.09375V3.4375M15.7657 14.7048L17.9127 13.9625C18.3151 13.8233 18.3286 13.2591 17.9332 13.101L12.6102 10.9715C12.2399 10.8233 11.8695 11.1846 12.0084 11.5585L14.0361 17.0181C14.1843 17.4171 14.7483 17.4179 14.8977 17.0194L15.7657 14.7048Z"
			stroke="#0E1111"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconReport = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M8.58398 18H4.58398C3.4794 17.9999 2.58398 17.1045 2.58398 15.9999L2.58406 3.99999C2.58407 2.89542 3.4795 2 4.58406 2H13.5843C14.6889 2 15.5843 2.89543 15.5843 4V9.5M11.5843 15.1667L13.4176 17L17.4176 12.9998M6.0843 6H12.0843M6.0843 9H12.0843M6.0843 12H9.0843"
			stroke="#0E1111"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const menus = [
	{ icon: IconEarth, title: "여행 주문현황", onClick: () => (window.location.href = "/partner/my/order-status") },
	{ icon: IconReport, title: "주문서 답변", onClick: () => (window.location.href = "/partner/my/order-comment") },
];

export default function MyPagePartnerOrder() {
	return (
		<div className="px-3">
			{menus.map((menu, i) => {
				// const active = menu.isOn;
				return (
					<div onClick={menu.onClick} className={["flex cursor-pointer gap-3 px-2 py-6", i !== 0 && "border-t border-[#dadada]"].join(" ")}>
						{menu.icon}
						<div className={["text-sm font-medium text-black"].join(" ")}>{menu.title}</div>
						<div className="flex flex-1 justify-end">
							<IconNext />
						</div>
					</div>
				);
			})}
		</div>
	);
}
