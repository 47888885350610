import { useEffect, useState } from "react";
import EasyTable from "../../components/EasyTable";
import { POPUP } from "../../admin_pages/api";
import EasyPagination from "../../components/EasyPagination";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";
import { DEFAULT } from "../../api";

export default function AdminPopUpPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const [modal, setModal] = useState("update"); // 'add', 'edit', 'delete'
	const [selected, setSelected] = useState<any>(null);
	const [serviceConfig, setServiceConfig] = useState<any>(null);

	useEffect(() => {
		DEFAULT.getServiceConfig().then(setServiceConfig);
	}, []);

	useEffect(() => {
		getData();
	}, [page]);

	const columns = [
		{
			header: "ID",
			key: "id",
		},
		{
			header: "제목",
			key: "title",
		},
		{
			header: "내용",
			key: "content",
			render: (v: string) => `${v?.slice(0, 10)}...`,
		},
		{
			header: "이미지",
			key: "imageFile",
			render: (v: any) => (
				<a target="_blank" className="text-blue-500" href={v.url}>
					{v.url}
				</a>
			),
		},
		{
			header: "링크",
			key: "link",
		},
		{
			header: "팝업",
			key: "isPopup",
			render: (v: number) => (v === 1 ? "Y" : "N"),
		},
		{
			header: "수정",
			key: "edit",
			render: (value: any, row: any, index: any) => (
				<button onClick={() => handleUpdateModal(value, row, index)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			),
		},
		{
			header: "삭제",
			key: "delete",
			render: (value: any, row: any) => (
				<button onClick={() => handleDelete(row.id)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
			),
		},
	];

	const schema = [];

	const handleUpdateModal = (value: any, row: any, index: any) => {
		setModal("update");
		setSelected(row);
	};

	const getData = async () => {
		const response = await POPUP.pagination(page.page, page.perPage);

		setData(response.nodes);
		setCount(response.total);
	};

	const handleDelete = async (id: number) => {
		await POPUP.delete(id);
		getData();
	};

	const handleCreate = async (data: any) => {
		await POPUP.create({
			title: data.title,
			content: data.content,
			imageFileId: data.imageFileId.id,
			link: data.link,
			isPopup: data.isPopup.value,
		});
		setModal("");
		getData();
	};

	const handleUpdate = async (data: any) => {
		await POPUP.update(selected.id, {
			title: data.title,
			content: data.content,
			imageFileId: data.imageFileId.id,
			link: data.link,
			isPopup: data.isPopup.value,
		});
		setSelected(null);
		setModal("");
		getData();
	};

	const popUpAvailableOptions = [
		{ label: "Y", value: 1 },
		{ label: "N", value: 0 },
	];

	return (
		<div>
			<h1>메인팝업 공지사항 관리</h1>
			<div className="flex justify-end">
				<button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable data={data} columns={columns} />
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>

				<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
					<div>추가</div>
					<div>
						<FormFactory
							schema={[
								{ label: "제목", key: "title", method: "text", validation: "required" },
								{ label: "내용", key: "content", method: "textarea", validation: "required" },
								{ label: "이미지", key: "imageFileId", method: "file", validation: "required" },
								{ label: "링크", key: "link", method: "text", validation: "required" },
								{ label: "팝업", key: "isPopup",
									method: "select",
									selectOptions: [
										{ label: "Y", value: 1 },
										{ label: "N", value: 0 },
									],
									defaultValue: { label: "Y", value: 1 },
								},
							]}
							onSubmit={handleCreate}
						/>
					</div>
				</Modal>

				{selected && (
					<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
						<div className="mb-6">수정</div>

						<FormFactory
							schema={[
								{ label: "제목", key: "title", method: "text", validation: "required", defaultValue: selected.title },
								{ label: "내용", key: "content", method: "textarea", validation: "required", defaultValue: selected.content },
								{ label: "이미지", key: "imageFileId", method: "file", validation: "required", defaultValue: selected.imageFile },
								{ label: "링크", key: "link", method: "text", defaultValue: selected.link},
								{ label: "팝업", key: "isPopup",
									method: "select",
									selectOptions: [
										{ label: "Y", value: 1 },
										{ label: "N", value: 0 },
									],
									defaultValue: popUpAvailableOptions.find((v) => v.value === selected.isPopup),
								},
							]}
							onSubmit={handleUpdate}
						/>
					</Modal>
				)}
			</div>
		</div>
	);
}
