export default function IconEarth(props: any) {
	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.25 18.7165C11.5309 18.9016 10.7769 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.5896 1 18.3768 4.4354 18.9304 8.875M2.125 13.375H4.75C5.78554 13.375 6.625 12.5355 6.625 11.5V9.0625C6.625 8.02697 7.46447 7.1875 8.5 7.1875H10.375C11.4105 7.1875 12.25 6.34804 12.25 5.3125V2.125M17.9196 15.6457L20.496 14.755C20.9789 14.588 20.9951 13.911 20.5207 13.7212L14.133 11.1657C13.6887 10.988 13.2442 11.4215 13.4108 11.8702L15.8441 18.4217C16.0219 18.9005 16.6987 18.9015 16.878 18.4233L17.9196 15.6457Z"
				stroke="#0057FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
