import { useEffect, useMemo, useState } from "react";
import { COMMUNITY_CLUP } from "../../../admin_pages/api";
import EasyTable from "../../../components/EasyTable";
import EasyPagination from "../../../components/EasyPagination";
import { useParams } from "react-router-dom";
import Modal from "../../../components/modal";
import FormFactory from "../../../components/FormFactory";
import { format, parse } from "date-fns";

export default function AdminCommunityClupsIdPage() {
	const { community_clup_id } = useParams();

	const [communityClup, setCommunityClup] = useState<any>(null);
	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		if (community_clup_id) {
			COMMUNITY_CLUP.find(+community_clup_id).then((v: any) => {
				setCommunityClup(v);
			});
		}
	};

	const tripPreference = useMemo(() => {
		if (communityClup && communityClup.CommunityClupParticipant) {
			const leader = communityClup?.CommunityClupParticipant?.find((v: any) => v.userId === communityClup.userId);
			return ["ALCOHOL_" + leader.alcoholPreference, "SMOKING_" + leader.smokingPreference];
		} else {
			return [];
		}
	}, [communityClup]);

	const booleanOptions = [
		{ label: "Y", value: true },
		{ label: "N", value: false },
	];

	const quotationReceivingMethodOptions = [
		{ label: "EMAIL", value: "EMAIL" },
		{ label: "KAKAO", value: "KAKAO" },
	];

	const handleUpdate = async (formData: any) => {
		await COMMUNITY_CLUP.update(+communityClup.id, {
			...formData,
			numberOfRecruits: formData.numberOfRecruits,
			startedAt: new Date(formData.startedAt),
			endedAt: new Date(formData.endedAt),
			guideAvailability: formData.guideAvailability.value,
			quotationReceivingMethod: formData.quotationReceivingMethod.value,
			status: formData.status.value,
			type: formData.type.value,
		});
		setModal("");
		getData();
	};

	const handleDelete = async (id: number) => {
		try {
			await COMMUNITY_CLUP.delete(id);
			alert("삭제됐습니다.");
			window.history.back();
		} catch (e: any) {
			if (e.response.data.message === "참가자가 존재합니다.") {
				alert("참가자가 존재합니다.");
			}
		}
	};

	return (
		<div>
			<h1 className="text-xl">커뮤니티 클럽 {community_clup_id}</h1>
			<div className="font-bold mt-4">정보</div>
			<div className="flex flex-col max-w-3xl font-medium">
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">id</div>
					<div>{communityClup?.id}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">리더 user id</div>
					<a target="_blank" href={`/admin/dashboard/users/${communityClup?.userId}`} className="text-blue-500">
						{communityClup?.userId}
					</a>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">유형</div>
					<div>{communityClup?.type}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">상태</div>
					<div>{communityClup?.status}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">국가</div>
					<div>{communityClup?.nation}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">도시</div>
					<div>{communityClup?.city}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">시작 날짜</div>
					<div>{communityClup?.startedAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">종료 날짜</div>
					<div>{communityClup?.startedAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">예상견적</div>
					<div>{communityClup?.expenses}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">모집인원</div>
					<div>{communityClup?.numberOfRecruits}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">나이대</div>
					<div>{communityClup?.ageGroup}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행 유형</div>
					<div>{communityClup?.tripType}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">숙소</div>
					<div>{communityClup?.accommodation}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">교통</div>
					<div>{communityClup?.transportation}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">가이드 필요 여부</div>
					<div>{communityClup?.guideAvailability ? "Y" : "N"}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">가이드 설명</div>
					<div>{communityClup?.guideAvailabilityDesc}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">견적 수신 방법</div>
					<div>{communityClup?.quotationReceivingMethod}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">견적 수신 대상</div>
					<div>{communityClup?.quotationReceivingTarget}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">요청사항</div>
					<div>{communityClup?.travelRequest}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">제목</div>
					<div>{communityClup?.title}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">설명</div>
					<div>{communityClup?.description}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행 기호</div>
					<div>{tripPreference.join(",")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">방문자수</div>
					<div>{communityClup?.viewCount}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">댓글 수</div>
					<div>{communityClup?.commentCount}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">생성 날짜</div>
					<div>{communityClup?.createdAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">삭제 여부</div>
					<div>{communityClup?.deletedAt ? "Y" : "N"}</div>
				</div>
			</div>

			<div className="flex w-full max-w-3xl justify-end gap-4">
				<button
					onClick={() => {
						handleDelete(communityClup?.id);
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
				<button
					onClick={() => {
						window.location.href = `/admin/dashboard/community-clups/${community_clup_id}/chats`;
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					채팅 내역 보기
				</button>
				<button
					onClick={() => {
						setModal("update");
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			</div>

			<div className="font-bold mt-4">파일 목록</div>
			<div className="mt-4 max-w-3xl overflow-x-scroll">
				{communityClup?.CommunityClupFile ? (
					<EasyTable
						data={communityClup?.CommunityClupFile}
						columns={[
							{ header: "id", key: "id" },
							{ header: "type", key: "type" },
							{
								header: "url",
								key: "File",
								render: (File) => (
									<a target="_blank" href={File?.url} className="cursor-pointer text-blue-500">
										{File?.url}
									</a>
								),
							},
							{ header: "size", key: "File", render: (File) => File?.size },
							{ header: "name", key: "File", render: (File) => File?.name },
						]}
					/>
				) : (
					"파일이 없습니다."
				)}
			</div>

			<div className="font-bold mt-4">참가자 목록</div>
			<div className="mt-4 max-w-3xl overflow-x-scroll">
				{communityClup?.CommunityClupParticipant ? (
					<EasyTable
						data={communityClup?.CommunityClupParticipant}
						columns={[
							{ header: "id", key: "id" },
							{
								header: "userId",
								key: "userId",
								render: (userId) => (
									<button className="text-blue-500" onClick={() => window.open(`/admin/dashboard/users/${userId}`, "_blank")}>
										{userId}
									</button>
								),
							},
							{ header: "확정 여부", key: "confirmedAt", render: (confirmedAt) => (confirmedAt ? "Y" : "N") },
							{ header: "거절 여부", key: "rejectedAt", render: (rejectedAt) => (rejectedAt ? "Y" : "N") },
							{ header: "알코올 선호", key: "alcoholPreference" },
							{ header: "담배 선호", key: "smokingPreference" },
							{ header: "여행 스타일", key: "travelStyle", render: (travelStyle) => (Array.isArray(travelStyle) ? travelStyle.join(", ") : "-") },
							{ header: "MBTI", key: "mbti" },
						]}
					/>
				) : (
					"참가자가 없습니다."
				)}
			</div>
			{communityClup && (
				<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
					<div className="mb-6">수정</div>
					<FormFactory
						schema={[
							{
								label: "유형",
								key: "type",
								method: "select",
								selectOptions: [
									{ label: "RECRUITMENT", value: "RECRUITMENT" },
									{ label: "PLANNED", value: "PLANNED" },
								],
								validation: "required",
								defaultValue: { label: communityClup?.type, value: communityClup?.type },
								disabled: true,
							},
							{
								label: "상태",
								key: "status",
								method: "select",
								selectOptions: [{ label: communityClup?.status, value: communityClup?.status }],
								defaultValue: { label: communityClup?.status, value: communityClup?.status },
								disabled: true,
							},
							{ label: "국가", key: "nation", method: "text", validation: "required", defaultValue: communityClup?.nation },
							{ label: "도시", key: "city", method: "text", validation: "required", defaultValue: communityClup?.city },
							{
								label: "시작날짜",
								key: "startedAt",
								method: "date",
								validation: "required",
								defaultValue: communityClup?.startedAt && format(new Date(communityClup?.startedAt), "yyyy-MM-dd"),
							},
							{
								label: "종료날짜",
								key: "endedAt",
								method: "date",
								validation: "required",
								defaultValue: communityClup?.startedAt && format(new Date(communityClup?.endedAt), "yyyy-MM-dd"),
							},
							{ label: "비용", key: "expenses", method: "text", validation: "required", defaultValue: communityClup?.expenses },
							{ label: "모집 인원", key: "numberOfRecruits", method: "text", validation: "required", defaultValue: communityClup?.numberOfRecruits },
							{ label: "연령대", key: "ageGroup", method: "text", validation: "required", defaultValue: communityClup?.ageGroup },
							{ label: "관광", key: "tripType", method: "text", validation: "required", defaultValue: communityClup?.tripType },
							{ label: "관광", key: "tripType", method: "text", validation: "required", defaultValue: communityClup?.tripType },
							{ label: "호텔", key: "accommodation", method: "text", validation: "required", defaultValue: communityClup?.accommodation },
							{ label: "교통", key: "transportation", method: "text", validation: "required", defaultValue: communityClup?.transportation },
							{
								label: "가이드 유무",
								key: "guideAvailability",
								method: "select",
								selectOptions: booleanOptions,
								validation: "required",
								defaultValue: communityClup?.guideAvailability ? booleanOptions.find((v) => v.value === communityClup?.guideAvailability) : null,
							},
							{
								label: "가이드 설명",
								key: "guideAvailabilityDesc",
								method: "text",
								validation: "required",
								defaultValue: communityClup?.guideAvailabilityDesc,
							},
							{
								label: "견적 수신 방식",
								key: "quotationReceivingMethod",
								method: "select",
								selectOptions: quotationReceivingMethodOptions,
								validation: "required",
								defaultValue: communityClup?.quotationReceivingMethod
									? quotationReceivingMethodOptions.find((v) => v.value === communityClup?.quotationReceivingMethod)
									: null,
							},
							{
								label: "견적 수신 대상",
								key: "quotationReceivingTarget",
								method: "text",
								validation: "required",
								defaultValue: communityClup?.quotationReceivingTarget,
							},
							{ label: "요청사항", key: "travelRequest", method: "text", validation: "required", defaultValue: communityClup?.travelRequest },
							{ label: "제목", key: "title", method: "text", validation: "required", defaultValue: communityClup?.title },
							{ label: "설명", key: "description", method: "textarea", validation: "required", defaultValue: communityClup?.description },
						]}
						onSubmit={handleUpdate}
					/>
				</Modal>
			)}
		</div>
	);
}
