import { useEffect, useState } from "react";
import InputLabel from "../components/input/labe";
import InputList from "../components/input/list";
import InputText from "../components/input/text";
import InputEmail from "../components/input/email";
import GoogleMap from "../components/GoogleMap";
import CardCrewRecommend from "../components/main/CardCrewRecommend";
import { RecommendationTrip } from "../types";
import { DEFAULT, FILE, ORDER, RECOMMENDATION_TRIP, USER } from "../api";
import InputDateRange from "../components/input/date-range";
import InputListAndText from "../components/input/list-and-text";
import InputListAddText from "../components/input/list-add-text";
import InputSelect from "../components/input/select";
import InputSelects, { InputMultipleSelects } from "../components/input/selects";
import InputTextareaAndAGree from "../components/input/textarea-and-agree";
import { aW } from "@fullcalendar/core/internal-common";
import check from "../image/check.svg";
import { tr } from "date-fns/locale";
import { useParams } from "react-router-dom";
import { set } from "date-fns";

const agree1Text = `고객님이 요청하신 사항들은 현지 상황에 따라 가능여부 확인 후 견적을
발송하고있습니다. 이에 따라 견적별로 일정의 상세내용이 상이할 수 있으니,
견적 체크 시 상세히 확인 후 선택하시길 바랍니다.`;

const agree2Text = `(1) 회사는 적법한 절차와 법적 기준에 의거하여 고객의 개인정보를 수집하고 있으며, 고객의 서비스 이용에 필요한 최소한의 정보만을 수집하고 있습니다.
(2) 회사는 고객의 인권을 침해할 우려가 있는 민감한 개인정보 항목(성향, 성생활정보 등)은 수집하지 않습니다. 그러나, 당사의 중요 서비스인 여행서비스 진행을 위해 의학적 도움이 필요한 고객의 경우, 또는 고객이 자발적으로 제공한 경우에 한하여 아래의 정보를 수집하고 있습니다.`;

const agree3Text = `회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 『개인정보 보호법』 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
(1) 회사는 원활한 서비스 제공을 위해 해외 사업자에게 개인정보를 제공할 수 있으며, 이 경우 정보주체의 동의를 얻어 필요 최소한의 범위로만 제공합니다.
(2) 회사는 다음과 같이 재난, 감염병, 급박한 생명 신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다.`;

const IconPath = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0625 12.9375C13.4412 12.9375 12.9375 13.4412 12.9375 14.0625C12.9375 14.6838 13.4412 15.1875 14.0625 15.1875C14.6838 15.1875 15.1875 14.6838 15.1875 14.0625C15.1875 13.4412 14.6838 12.9375 14.0625 12.9375ZM11.8125 14.0625C11.8125 12.8199 12.8199 11.8125 14.0625 11.8125C15.3051 11.8125 16.3125 12.8199 16.3125 14.0625C16.3125 15.3051 15.3051 16.3125 14.0625 16.3125C12.8199 16.3125 11.8125 15.3051 11.8125 14.0625Z"
			fill="#0E1111"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.5 3.9375C4.5 3.62684 4.75184 3.375 5.0625 3.375H11.8125C12.5584 3.375 13.2738 3.67132 13.8012 4.19876C14.3287 4.72621 14.625 5.44158 14.625 6.1875C14.625 6.93342 14.3287 7.64879 13.8012 8.17624C13.2738 8.70368 12.5584 9 11.8125 9H5.0625C4.46576 9 3.89347 9.23705 3.47151 9.65901C3.04955 10.081 2.8125 10.6533 2.8125 11.25C2.8125 11.8467 3.04955 12.419 3.47151 12.841C3.89347 13.2629 4.46576 13.5 5.0625 13.5H12.375C12.6857 13.5 12.9375 13.7518 12.9375 14.0625C12.9375 14.3732 12.6857 14.625 12.375 14.625H5.0625C4.16739 14.625 3.30895 14.2694 2.67601 13.6365C2.04308 13.0035 1.6875 12.1451 1.6875 11.25C1.6875 10.3549 2.04308 9.49645 2.67601 8.86351C3.30895 8.23058 4.16739 7.875 5.0625 7.875H11.8125C12.2601 7.875 12.6893 7.69721 13.0057 7.38074C13.3222 7.06428 13.5 6.63505 13.5 6.1875C13.5 5.73995 13.3222 5.31072 13.0057 4.99426C12.6893 4.67779 12.2601 4.5 11.8125 4.5H5.0625C4.75184 4.5 4.5 4.24816 4.5 3.9375Z"
			fill="#0E1111"
		/>
	</svg>
);

// 주문서 작성 페이지

export default function OrderTrip() {
	const [user, setUser] = useState("");
	const [validation, setValidation] = useState(false);
	const [ticket, setTicket] = useState(false);
	const [passport, setPassport]: any = useState("");
	const [visa, setVisa]: any = useState("");
	const [ticketImg, setTicketImg]: any = useState("");
	const [receiving, setReceiving] = useState(true);

	const [tripTypeOptions, setTripTypeOptions] = useState([]);
	const [accommodationOptions, setAccommodationOptions] = useState([]);
	const [transportationOptions, setTransportationOptions] = useState([]);

	const [order, setOrder] = useState({
		userId: Number,
		name: "",
		email: "",
		nation: "",
		city: "",
		startedAt: "",
		endedAt: "",
		expenses: "",
		numberOfRecruits: 1,
		type: "RECRUITMENT",
		tripType: "",
		recruitGenderType: "",
		ageGroup: "",
		accommodation: "",
		transportation: "",
		guideAvailability: true,
		guideAvailabilityDesc: "",
		quotationReceivingMethod: "",
		quotationReceivingTarget: "",
		travelRequest: "",
		title: "",
		personalInfoAgreementAt: "",
		thirdPartyInfoAgreementAt: "",
		isIncludeAirticket: false,
		companyName: "",
	});

	const { order_id } = useParams();

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setTripTypeOptions(res?.tripTypes?.map((e: any) => ({ label: e, value: e })) || []);
			setAccommodationOptions(res?.accommodations?.map((e: any) => ({ label: e, value: e })) || []);
			setTransportationOptions(res?.transportations?.map((e: any) => ({ label: e, value: e })) || []);
		});

		ORDER?.getOne(order_id).then((data) => {
			function dateFormat(date: Date) {
				let month: any = date.getMonth() + 1;
				let day: any = date.getDate();

				return `${date.getFullYear()}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
			}

			data.startedAt = dateFormat(new Date(data.startedAt));
			data.endedAt = dateFormat(new Date(data.endedAt));
			setOrder(data);
		});
		USER.getUser().then((data) => {
			setUser(data.id);
		});
	}, []);

	useEffect(() => {
		if (
			order?.name &&
			order?.email &&
			order?.type &&
			order?.city &&
			order?.startedAt &&
			order?.endedAt &&
			order?.expenses &&
			order?.numberOfRecruits &&
			order?.tripType &&
			order?.accommodation &&
			order?.accommodation &&
			order?.transportation &&
			order?.guideAvailability &&
			order?.quotationReceivingMethod &&
			order?.title &&
			order?.personalInfoAgreementAt &&
			order?.thirdPartyInfoAgreementAt
		) {
			if (order.type === "RECRUITMENT") {
				if (order.recruitGenderType && order.ageGroup) {
					setValidation(true);
				}
			} else {
				setValidation(true);
			}
		}
	}, [order]);

	const sendOrder = async () => {
		const data = {
			name: order?.name,
			email: order?.email,
			nation: order?.nation,
			city: order?.city,
			startedAt: order?.startedAt,
			endedAt: order?.endedAt,
			expenses: order?.expenses,
			numberOfRecruits: +order?.numberOfRecruits,
			type: order?.type,
			tripType: order?.tripType,
			recruitGenderType: order?.type === "RECRUITMENT" ? order?.recruitGenderType : "All",
			ageGroup: order?.type === "RECRUITMENT" ? order?.ageGroup : "",
			accommodation: order?.accommodation,
			transportation: order?.transportation,
			guideAvailability: order?.guideAvailability,
			guideAvailabilityDesc: order?.guideAvailabilityDesc,
			quotationReceivingMethod: order?.quotationReceivingMethod,
			quotationReceivingTarget: order?.quotationReceivingTarget,
			travelRequest: order?.travelRequest,
			title: order?.title,
			personalInfoAgreementAt: order?.personalInfoAgreementAt,
			thirdPartyInfoAgreementAt: order?.thirdPartyInfoAgreementAt,
			passportImageId: passport.id,
			visaImageId: visa.id,
			airTicketImageId: ticketImg.id,
			companyName: order?.companyName,
		};

		await ORDER.editOrder(order_id, data).then((res) => {
			window.location.href = `/mypage/myorder/${order_id}`;
		});
	};

	const tripTypes = [
		{ label: "모집 주문", value: "RECRUITMENT" },
		{ label: "단독 주문", value: "ALONE" },
		{ label: "출장 주문", value: "BUSINESS_TRIP" },
	];

	const handleInput = (key: string, value: string | number | boolean) => {
		setOrder({
			...order,
			[key]: value,
		});
	};

	const handleDateChange = (startDate: string, endDate: string) => {
		setOrder({
			...order,
			startedAt: startDate,
			endedAt: endDate,
		});
	};

	const emailDomainOptions = [
		{ label: "구글", value: "gmail.com" },
		{ label: "네이버", value: "naver.com" },
		{ label: "다음", value: "daum.net" },
		// ...기타 옵션 추가 가능
	];

	// 1인 예상 경비 옵션 목록
	const costOptions = [
		{ label: "50~100", value: "50~100" },
		{ label: "100~200", value: "100~200" },
		{ label: "200~300", value: "200~300" },
		{ label: "300~400", value: "300~400" },
		{ label: "400~500", value: "400~500" },
	];

	const recruitNumberOptions = [
		{ label: "1", value: 1 },
		{ label: "2", value: 2 },
		{ label: "3", value: 3 },
		{ label: "4", value: 4 },
		{ label: "5", value: 5 },
		{ label: "6", value: 6 },
		{ label: "7", value: 7 },
		{ label: "8", value: 8 },
		{ label: "9", value: 9 },
		{ label: "10", value: 10 },
	];

	const fileUpload = (e: any, setValue: any) => {
		const reader = new FileReader();
		const file = e.target.files ? e.target.files[0] : null;

		if (file) {
			reader.onloadend = () => {};
			reader.readAsDataURL(file);
			FILE.upload(file).then((res) => {
				setValue(res);
			});
		}
	};

	const FileInputTag = ({ tag, value, essential, setValue }: any) => {
		return (
			<div className="">
				<div className="text-[16px] mb-[10px]">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="flex border w-fll h-[35px] border-[#DADADA]">
					<label
						className="w-[77px] text-[12px] font-medium border-[#DADADA] border-r flex justify-center items-center bg-[#ECECEC]"
						htmlFor={`input_${value}`}>
						파일 선택
					</label>
					<div className="text-[12px] text-[#999999] flex justify-center items-center px-[10px] font-medium ">{value || "선택된 파일이 없습니다."}</div>
				</div>
				<div className="text-[12px] text-[#999999] font-medium mt-[7px]">JPG, JPEG, PNG, PDF, ZIP 업로드 가능</div>
				<input
					id={`input_${value}`}
					onChange={(e) => {
						fileUpload(e, setValue);
					}}
					placeholder="선택된 파일이 없습니다."
					className="hidden"
					type="file"
				/>
			</div>
		);
	};

	return (
		<div className="flex justify-center items-center">
			<div className="max-w-[512px] w-full">
				<InputLabel title="여행 형태 선택" required={true} description="" className="px-3 mt-[20px]" />
				<InputList value={order?.type} list={tripTypes} onChange={(v) => handleInput("type", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

				<div className="flex px-[12px] mt-[10px] gap-[16px] ">
					<div
						onClick={() => {
							setTicket(!ticket);
						}}
						className="flex gap-[7px] items-center">
						<div className={`border w-4 border-1 aspect-square rounded-[4px] ${!ticket ? "" : "border-[#999999] bg-[#ECECEC]"}`}>
							{!ticket && <img src={check}></img>}
						</div>
						<div className="text-xs font-medium text-[#0E1111]">항공권 포함</div>
					</div>

					<div
						onClick={() => {
							setTicket(!ticket);
						}}
						className="flex gap-[7px] items-center">
						<div className={`border w-4 border-1 aspect-square rounded-[4px] ${ticket ? "" : "border-[#999999] bg-[#ECECEC]"}`}>
							{ticket && <img src={check}></img>}
						</div>
						<div className="text-xs font-medium text-[#0E1111]">항공권 미포함</div>
					</div>
				</div>

				<InputLabel title="이름" required={true} className="px-3 mt-[20px] xl:mt-[32px]" />
				<InputText value={order?.name} onChange={(v) => handleInput("name", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

				<InputLabel title="이메일" required={true} className="px-3 mt-[20px]" />
				<InputEmail value={order?.email} domainOptions={emailDomainOptions} onChange={(v) => handleInput("email", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

				<InputLabel title="국가 선택" required={true} description="국가 입력시 자동으로 지도맵이 변경됩니다." className="px-3 mt-[20px]" />
				<InputText value={order?.nation} onChange={(v) => handleInput("nation", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

				<InputLabel title="도시 선택" required={true} description="도시 연계의 경우 원하는 도시를 모두 입력해주세요." className="px-3 mt-[20px]" />
				<InputText value={order?.city} onChange={(v) => handleInput("city", v)} className="px-3 mt-[10px] xl:mt-[16px]" />
				<GoogleMap location={order?.nation} className="px-3 mt-[10px] xl:mt-[16px] h-[300px]" />

				<InputLabel leadingIcon={IconPath} title="크루들의 추천여행" required={false} className="px-3 mt-[20px]" />

				<InputLabel title="여행 기간" required={true} description="계획중인 일정을 선택해주세요." className="px-3 mt-[20px]" />
				<InputDateRange
					className="px-3 mt-[10px] xl:mt-[16px]"
					startDate={order?.startedAt}
					endDate={order?.endedAt}
					onChange={handleDateChange}
					active={true}
				/>

				<InputLabel title="1인 예상 경비" required={true} description="금액은 만원 단위이며, 중복 선택 가능합니다." className="px-3 mt-[20px]" />
				<InputListAndText
					type="text"
					text="기타"
					list={costOptions}
					value={order?.expenses}
					onChange={(v) => handleInput("expenses", v)}
					className="px-3 mt-[10px] xl:mt-[16px]"
				/>

				<InputLabel title="모집 인원" required={true} className="px-3 mt-[20px]" />
				<InputListAndText
					type="number"
					text="기타"
					list={recruitNumberOptions}
					value={order?.numberOfRecruits}
					onChange={(v) => handleInput("numberOfRecruits", v)}
					className="px-3 mt-[10px] xl:mt-[16px]"
				/>

				{order?.type === "RECRUITMENT" && (
					<div className="flex px-3 gap-3 mt-[20px]">
						<div className="flex-1">
							<InputLabel title="모집 유형" required={true} />
							<InputSelect
								value={order?.recruitGenderType}
								className="mt-[10px]"
								onChange={(v) => handleInput("recruitGenderType", v)}
								options={[
									{
										label: "남녀무관",
										value: "All",
									},
									{ label: "남자", value: "Male" },
									{ label: "여자", value: "Female" },
								]}
							/>
						</div>
						<div className="flex-1">
							<InputLabel title="모집 연령" required={true} />
							<InputSelect
								value={order?.ageGroup}
								className="mt-[10px]"
								onChange={(v) => handleInput("ageGroup", v)}
								options={[
									{ label: "20대", value: "20대" },
									{ label: "30대", value: "30대" },
									{ label: "40대", value: "40대" },
									{ label: "50대", value: "50대" },
									{ label: "60대", value: "60대" },
								]}
							/>
						</div>
					</div>
				)}

				<InputLabel title="여행 유형" required={true} description="중복 선택 가능합니다." className="px-3 mt-[20px]" />
				<InputMultipleSelects
					defaultValue={order.tripType}
					onChange={(v: any) => handleInput("tripType", v)}
					className="px-3 mt-[10px] xl:mt-[16px]"
					options={tripTypeOptions}
				/>

				<InputLabel title="교통" required={true} className="px-3 mt-[20px]" />
				<InputSelect
					value={order.transportation}
					className="px-3 mt-[10px] xl:mt-[16px]"
					onChange={(v) => handleInput("transportation", v)}
					options={transportationOptions}
				/>

				<InputLabel title="숙소" required={true} className="px-3 mt-[20px]" />
				<InputSelect
					value={order.accommodation}
					className="px-3 mt-[10px] xl:mt-[16px]"
					onChange={(v) => handleInput("accommodation", v)}
					options={accommodationOptions}
				/>

				<InputLabel title="가이드" required={true} description="요청조건 있을 경우 기타란에 입력해주세요. 중복 선택 가능" className="px-3 mt-[20px]" />
				<InputListAddText
					value={order.guideAvailability}
					subValue={order.guideAvailabilityDesc}
					list={[
						{ label: "필요", value: true },
						{ label: "불필요", value: false },
					]}
					onChange={(v: any) => handleInput("guideAvailability", v)}
					onChangeDesc={(v: any) => handleInput("guideAvailabilityDesc", v)}
					contentAlign="justify-start"
					className="px-3 mt-[10px] xl:mt-[16px]"
				/>

				<InputLabel title="견적 수신 방법" required={true} className="px-3 mt-[20px]" />
				<div className="flex justify-start gap-[10px] px-[10px] mt-[10px]">
					<div
						className={`min-w-[30px] whitespace-nowrap  justify-center transition-all text-[14px] font-normal font-spoqa tracking-[-0.32px] flex rounded-[2px] items-center py-[8px] px-[5px] gap-2 border  w-16 cursor-pointer ${
							order.quotationReceivingMethod === "EMAIL" ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0e1111] "
						}`}
						onClick={(e) => {
							handleInput("quotationReceivingMethod", "EMAIL");
						}}>
						이메일
					</div>
					<div
						className={`min-w-[30px] whitespace-nowrap  justify-center transition-all text-[14px] font-normal font-spoqa tracking-[-0.32px] flex rounded-[2px] items-center py-[8px] px-[5px] gap-2 border  w-16 cursor-pointer ${
							order.quotationReceivingMethod === "KAKAO" ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0e1111] "
						}`}
						onClick={(e) => {
							handleInput("quotationReceivingMethod", "KAKAO");
						}}>
						카카오톡
					</div>
				</div>

				<div className="px-[10px] flex mt-[10px]">
					<div className="w-[80px] h-[33px] justify-center items-center flex rounded-tl-[2px] rounded-bl-[2px] border-l border-t border-b  border-[#DADADA] text-[#0e1111] text-[14px] font-normal tracking-[-0.32px] font-spoqa  false">
						주소
					</div>
					<input
						className="bg-transparent py-[6px] px-[7px] w-full h-[33px] rounded-tr-[2px] rounded-br-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] font-spoqa  false"
						value={order.quotationReceivingTarget}
						onChange={(e) => {
							handleInput("quotationReceivingTarget", e.target.value);
						}}></input>
				</div>

				{order?.type === "BUSINESS_TRIP" && (
					<>
						<InputLabel title="기업명 / 단체명" required={false} className="px-3 mt-[20px]" />
						<InputText value={order?.companyName} onChange={(v) => handleInput("companyName", v)} className="px-3 mt-[10px] xl:mt-[16px]" />
					</>
				)}

				<InputLabel title="일차별 일정 또는 여행관련 요청사항" required={false} className="px-3 mt-[20px]" />
				<div className="text-[12px] font-spoqa font-medium text-[#777] tracking-[-0.32px] px-3 mt-[5px]">(관광지, 액티비티 문의 등)</div>
				<div className="px-3 mt-[5px]">
					<textarea
						value={order.travelRequest}
						onChange={(v: any) => handleInput("travelRequest", v.target.value)}
						className="w-full bg-transparent resize-none border-[#dadada] border rounded-[2px] font-spoqa text-[14px] h-[128px] p-[8px]"
						cols={5}
					/>
				</div>
				<InputLabel title="여행 제목" required={true} description="당신의 여행에 이름을 붙여주세요." className="px-3 mt-[20px] " />
				<InputText value={order.title} onChange={(v) => handleInput("title", v)} className="px-3 mt-[10px] xl:mt-[16px]" />
				{/* <div className="px-[10px] py-[30px] flex flex-col gap-[20px]">
					<FileInputTag
						tag="여권 정보"
						value={passport.name}
						setValue={(e: any) => {
							setPassport(e);
						}}
					/>
					<FileInputTag
						tag="비자 정보"
						value={visa.name}
						setValue={(e: any) => {
							setVisa(e);
						}}
					/>
					<FileInputTag
						tag="항공권 정보"
						value={ticketImg.name}
						setValue={(e: any) => {
							setTicketImg(e);
						}}
					/>
				</div> */}

				<div className="px-3 mt-[21px] flex justify-center">
					<button
						onClick={sendOrder}
						className={` ${
							validation ? "bg-[#0057ff] text-white" : "bg-[#DADADA] text-[#777777]"
						} border border-[#dadada] h-[40px] w-full xl:max-w-[588px] xl:h-[62px] xl:rounded-[3px] xl:text-[24px] xl:mt-[50px]`}>
						작성완료
					</button>
				</div>
			</div>
		</div>
	);
}
