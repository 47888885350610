import { useCallback, useMemo } from "react";

interface Props {
	list: {
		label: string;
		value: string;
	}[];
	value: string[];
	onChange: (value: string[]) => void;
	className?: string;
	disabled?: boolean;
}
export default function InputListCol2Multi({ list, value, onChange, className = "", disabled }: Props) {
	const handleChange = (e: any, newValue: string) => {
		e.preventDefault();

		if (value.includes(newValue)) {
			onChange(value.filter((v) => v !== newValue));
			return;
		} else {
			onChange([...value, newValue]);
		}
	};

	const getSelectedStyle = useCallback(
		(isSelected: boolean) => {
			if (disabled) {
				if (isSelected) {
					return "border-[#999] bg-[#ececec] text-[#777]";
				} else {
					return "border-[#dadada] bg-[#f6f7f9] text-[#dadada]";
				}
			}

			if (isSelected) {
				return "border-blue-500 text-blue-500";
			} else {
				return "border-[#DADADA] text-[#0e1111]";
			}
		},
		[disabled, className]
	);

	return (
		<div className={["grid grid-cols-2 gap-3", className].join(" ")}>
			{list.map((item) => {
				const isSelected = value.includes(item.value);
				return (
					<div
						key={item.value}
						className={`flex items-center justify-center gap-2 rounded-[2px] border px-[15px] py-2 transition-all ${getSelectedStyle(
							isSelected
						)} cursor-pointer`}
						onClick={(e) => handleChange(e, item.value)}>
						{/* 숨겨진 라디오 버튼 */}
						<input
							className="hidden" // Tailwind를 사용하여 라디오 버튼 숨기기
							type="radio"
							name="radio"
							id={item.value}
							value={item.value}
							checked={isSelected}
							onChange={() => {}}
						/>
						<label className="font-spoqa text-[14px] font-medium tracking-[-0.32px]" htmlFor={item.value}>
							{item.label}
						</label>
					</div>
				);
			})}
		</div>
	);
}
