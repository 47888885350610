import React, { useState, useMemo, useEffect } from "react";
import storage from "../storage";
import { POPUP } from "../../admin_pages/api";
import Carousel from "./Carousel";

const MainPopUp = () => {
  const [imageList, setImageList] = useState<any>([]);
  const [linkList, setLinkList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  useEffect(() => {
    POPUP.getList().then((data: any) => {
      const imgList = [];
      for (const item of data) {
        console.log(`image: ${item?.imageFile.url}`)
        imgList.push(item?.imageFile.url);
        linkList.push(item?.link);
      }
      setImageList(imgList);

      const today = new Date();
      const todayTime = today.getTime();
      const popUp = storage.getItem("popup", new Date(today.setDate(today.getDate() - 1)).getTime());
      if ( todayTime > popUp) {
        // console.log(`today(${todayTime}) > popUp(${popUp})`)
        storage.removeItem("popup");
        setIsOpen(true);
      } else {
        // console.log(`today(${todayTime}) <= popUp(${popUp})`)
        setIsOpen(false);
      }
    });
  }, []);
  
  const hideModal = () => {
    const today = new Date();
    storage.setItem(
      "popup",
      new Date(today.setDate(today.getDate() + 1)).getTime()
    );
    setIsOpen(false);
  };
  
  const closeModal = () => {
    setIsOpen(false);
  };    

  return (
    <>
      {isOpen ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black bg-opacity-30 backdrop-blur-sm">
            <div className="relative my-6 mx-auto max-w-3xl top-1 w-auto h-[80%] ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="w-auto m-auto pt-11">
                  <Carousel slides={imageList} links={linkList} />
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={hideModal}
                  >
                    하루동안 보지 않기
                  </button>
                  <button
                    className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={closeModal}
                  >
                    창 닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default MainPopUp;
