import { useEffect, useState } from "react";
import { RECOMMENDATION_TRIP } from "../api";
import Modal from "../components/modal";
import FormFactory from "../components/FormFactory";
import InputLabel from "../components/input/labe";

export default function RecommendTrip() {

	// const [user, setUser] = useState("");

	// useEffect(() => {
	// }, []);

	const handleCreate = async (formData: any) => {
		try {
			const recommend = await RECOMMENDATION_TRIP.create({
				...formData,
				RecommendationTripFile: {
					create: {
						type: "RECOMMENDATION_TRIP_IMAGE",
						fileId: +formData.RecommendationTripFile.id,
					},
				},
			});

			if (recommend) {
				alert("추천 여행이 등록 되었습니다!");
				window.location.href = "/ourdestination";
			}
		} catch (e) {
			alert("추천 여행 등록에 실패하였습니다.");
		}
	};

	return (
		<div className="flex justify-center items-center">
			<div className="max-w-[800px] w-full">
				<InputLabel title="추천 여행 등록하기" required={false} className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white" />

				<div>
					<FormFactory
						schema={[
							{ label: "제 목", key: "name", method: "text", validation: "required" },
							{ label: "설 명", key: "description", method: "text", validation: "required" },
							{ label: "이미지", key: "RecommendationTripFile", method: "file", validation: "required" },
							{ label: "내 용", key: "content", method: "wizwig", validation: "required" },
							{ label: "국 가", key: "category1", method: "text", validation: "required" },
							{ label: "도 시", key: "category2", method: "text", validation: "required" },
							{ label: "태 그", key: "tags", method: "text", validation: "required" },
						]}
						onSubmit={handleCreate}
					/>
				</div>
			</div>
		</div>
	);
}