import { useEffect, useMemo, useState } from "react";
import { COMMUNITY_CLUP, DEFAULT, USER } from "../api";
import { useParams } from "react-router-dom";
import { differenceInDays, format } from "date-fns";
import { WindowScrollController } from "@fullcalendar/core/internal";
import InputLabel from "../components/input/labe";
import InputText from "../components/input/text";
import InputListAndText from "../components/input/list-and-text";
import InputListAddText from "../components/input/list-add-text";
import InputSelect, { InputMultipleSelects } from "../components/input/selects";
import InputImage from "../components/input/image";
import InputDateRange from "../components/input/date-range";

function dateFormat(date: Date) {
	let month: any = date.getMonth() + 1;
	let day: any = date.getDate();

	return `${date.getFullYear()}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
}

const CommentReplyer = ({ onSubmit }: any) => {
	const [text, setText] = useState<any>("");
	const [open, setOpen] = useState<any>(false);

	return (
		<div>
			{!open && (
				<div onClick={() => setOpen(true)} className="text-success text-xs font-normal mt-4">
					{" "}
					글 달기
				</div>
			)}
			{open && (
				<div className="w-full mt-2.5">
					<div className="border border-[#dadada] rounded-sm bg-[#fff] p-3">
						<textarea
							value={text}
							onChange={(e) => setText(e.target.value)}
							placeholder="댓글을 입력해주세요"
							className="resize-none text-[#777] text-xs w-full bg-transparent outline-none"
						/>
					</div>

					<div
						className="flex justify-end"
						onClick={() => {
							onSubmit(text).then(() => setText(""));
						}}>
						<div className="text-success border border-success px-2.5 py-0.5 text-xs font-medium rounded-md mt-2">작성</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default function CommunityEditPage() {
	const [community, setCommunity] = useState<any>(null);
	const [participants, setParticipants] = useState<any>(null);
	const [comments, setComments] = useState<any>(null);
	const [text, setText] = useState<any>("");
	const [my, setMy] = useState<any>({});
	const [isConfrimed, setIsConfirmed] = useState<any>(false);
	const [tripTypeOptions, setTripTypeOptions] = useState([]);
	const [accommodationOptions, setAccommodationOptions] = useState([]);
	const [transportationOptions, setTransportationOptions] = useState([]);
	const params = useParams();

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setTripTypeOptions(res.tripTypes.map((e: any) => ({ label: e, value: e })));
			setAccommodationOptions(res.accommodations.map((e: any) => ({ label: e, value: e })));
			setTransportationOptions(res.transportations.map((e: any) => ({ label: e, value: e })));
		});

		const { community_id } = params;

		if (community_id) {
			COMMUNITY_CLUP.getOne(+community_id).then((res: any) => {
				setCommunity(res);
				console.log(res);
				setText(res.description);
			});
			COMMUNITY_CLUP.getParticipants(+community_id).then((res: any) => {
				setParticipants(res);
			});
			COMMUNITY_CLUP.getComments(+community_id).then((res: any) => {
				setComments(res);
			});
			COMMUNITY_CLUP.getMyStatus(+community_id).then((res: any) => {
				setIsConfirmed(res.isConfirmed);
			});
		}

		USER.getUser().then((res) => {
			setMy(res);
		});
	}, []);

	const handleInput = (key: string, value: string | number) => {
		setCommunity({
			...community,
			[key]: value,
		});
	};

	const handleDateChange = (startDate: string, endDate: string) => {
		setCommunity({
			...community,
			startedAt: startDate,
			endedAt: endDate,
		});
	};

	const handleClickCreateComment = () => {
		const { community_id } = params;

		if (community_id) {
			COMMUNITY_CLUP.createComment(+community_id, text).then((res: any) => {
				COMMUNITY_CLUP.getComments(+community_id).then((res: any) => {
					setComments(res);
				});
				setText("");
			});
		}
	};

	const handleClickCreateReply = async (parentId: any, comment: string) => {
		const { community_id } = params;

		if (community_id) {
			COMMUNITY_CLUP.createComment(+community_id, comment, parentId).then((res: any) => {
				COMMUNITY_CLUP.getComments(+community_id).then((res: any) => {
					setComments(res);
				});
				setText("");
			});
		}
	};

	const createdAt = useMemo(() => {
		if (community?.createdAt) {
			return format(new Date(community?.createdAt), "yyyy-MM-dd");
		} else {
			return "";
		}
	}, [community]);

	const recruitGenderType = useMemo(() => {
		if (community) {
			if (community?.order?.recruitGenderType === "Male") {
				return "남성";
			} else if (community?.order?.recruitGenderType === "Female") {
				return "여성";
			} else {
				return "성별 무관";
			}
		} else {
			return "";
		}
	}, [community]);

	const type = useMemo(() => {
		if (community) {
			if (community?.type === "RECRUITMENT") {
				return "모집형";
			} else {
				return "계획형";
			}
		} else {
			return "";
		}
	}, [community]);

	const periodString = useMemo(() => {
		if (community) {
			try {
				const startedAt = format(new Date(community?.order?.startedAt), "yyyy-MM-dd");
				const endedAt = format(new Date(community?.order?.endedAt), "yyyy-MM-dd");
				const period = differenceInDays(new Date(community?.order?.endedAt), new Date(community?.order?.startedAt));
				return `${startedAt} ~ ${endedAt} (${period}박 ${period + 1}일)`;
			} catch (e) {
				return "";
			}
		} else {
			return "";
		}
	}, [community]);

	const tripStyles = useMemo(() => {
		if (community?.tripStyles) {
			return community?.tripStyles.map((v: any) => `#${v}`).join(" ");
		}
		return "";
	}, [community]);

	const tripPreferences = useMemo(() => {
		if (community?.tripPreferences) {
			return community?.tripPreferences.map((v: any) => `#${v}`).join(" ");
		}
		return "";
	}, [community]);

	const image = useMemo(() => {
		if (community?.CommunityClupFile) {
			const communityClupFiles = community?.CommunityClupFile;
			const promotion = communityClupFiles.find((v: any) => v.type === "PROMOTION");

			return promotion?.File.url;
		}
		return "";
	}, [community]);

	const recruitNumberOptions = [
		{ label: "1", value: 1 },
		{ label: "2", value: 2 },
		{ label: "3", value: 3 },
		{ label: "4", value: 4 },
		{ label: "5", value: 5 },
		{ label: "6", value: 6 },
		{ label: "7", value: 7 },
		{ label: "8", value: 8 },
		{ label: "9", value: 9 },
		{ label: "10", value: 10 },
	];

	const costOptions = [
		{ label: "50~100", value: "50~100" },
		{ label: "100~200", value: "100~200" },
		{ label: "200~300", value: "200~300" },
		{ label: "300~400", value: "300~400" },
		{ label: "400~500", value: "400~500" },
	];

	return (
		<div className="flex justify-center">
			<div className="w-full flex justify-center flex-col items-center ">
				<div
					className="h-[230px] bg-cover bg-center w-full"
					style={{
						backgroundImage: `url(${image})`,
					}}></div>
				<div>
					<div className="mt-[20px] px-[12px] ">{community?.title}</div>
					<div className="px-3 mt-[5px] flex justify-between ">
						<div className=" text-[14px] font-normal text-[#777]">
							{createdAt} · 조회수 {community?.viewCount} · 댓글 {community?.commentCount}
						</div>
						<div>{IconShare}</div>
					</div>

					<div className="px-[12px] pt-[20px]">
						<div className="border border-[#dadada] grid gap-[20px] grid-cols-3 px-[14px] py-[10px]  ">
							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">국가</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.nation}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">도시</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.city}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">인원</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.numberOfRecruits}명</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">동행 연령대</span>
								<span className="text-xs text-[#0e1111] font-medium ">{community?.ageGroup}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">동행 성별</span>
								<span className="text-xs text-[#0e1111] font-medium ">{recruitGenderType}</span>
							</div>

							<div className="flex gap-[5px]">
								<span className="text-xs text-[#777] font-normal ">모집유형</span>
								<span className="text-xs text-[#0e1111] font-medium ">{type}</span>
							</div>

							<div className="flex gap-[5px] col-span-3">
								<span className="text-xs text-[#777] font-normal ">여행기간</span>
								<span className="text-xs text-[#0e1111] font-medium ">{periodString}</span>
							</div>

							<div className="flex gap-[5px] col-span-3">
								<span className="text-xs text-[#777] font-normal ">여행기호</span>
								<span className="text-xs text-[#0e1111] font-medium ">{tripStyles}</span>
							</div>

							<div className="flex gap-[5px] col-span-3">
								<span className="text-xs text-[#777] font-normal ">여행성향</span>
								<span className="text-xs text-[#0e1111] font-medium ">{tripPreferences}</span>
							</div>
						</div>
					</div>

					<div className="h-[1px] w-full border border-[#999] mt-[20px]"></div>

					<div className="px-3 mt-[20px]">함께하는 동행자들</div>
					<div className=" px-3 mt-[10px]">
						<div className="px-[14px]  py-[10px]  flex flex-col gap-[10px]  border border-[#dadada] rounded-[2px] bg-[#f6f7f9]">
							{(participants ?? []).map((participant: any) => {
								const user = participant.User;
								const file = user?.UserFiles?.find((file: any) => file.type === "PROFILE");
								const age = Math.floor(user?.birthday ? differenceInDays(new Date(), new Date(user?.birthday)) / 365 : 0);

								return (
									<div className="flex gap-[5px] ">
										<div className="flex-none w-[50px] h-[50px]   bg-gray-300 rounded-full">
											{file?.url && <img className="w-full" src={file.url} alt="" />}
										</div>
										<div className="flex flex-col justify-center gap-[2.5px] ">
											<div className=" text-[#0e1111] text-[14px] font-medium flex items-center gap-[2px] ">
												{user?.name}
												{participant?.isLeader && IconLeader}
											</div>
											<div className="flex gap-[10px]">
												<div className="flex text-[#777]  text-xs font-normal items-center gap-[4px]">
													{IconGender}
													{user?.genderText}
												</div>

												<div className="flex text-[#777]  text-xs font-normal items-center gap-[4px]">
													{IconAge}
													{age}
												</div>

												<div className="flex text-[#777]  text-xs font-normal items-center gap-[4px]">
													{IconParty}
													{participant?.count === 0 ? "첫 동행" : `${participant?.count + 1}번째 동행`}
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					<div className="h-[1px] w-full border border-[#999] mt-[20px] "></div>

					<InputLabel title="국가 선택" required={true} className="px-3 mt-[20px]" />
					<InputText value={community?.nation} onChange={(v) => handleInput("nation", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

					<InputLabel title="도시 선택" required={true} className="px-3 mt-[20px]" />
					<InputText value={community?.city} onChange={(v) => handleInput("city", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

					<InputLabel title="여행 제목" required={true} className="px-3 mt-[20px]" />
					<InputText value={community?.title} onChange={(v) => handleInput("title", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

					<InputLabel title="1인 예상 경비" required={true} description="금액은 만원 단위이며, 중복 선택 가능합니다." className="px-3 mt-[20px]" />
					<InputListAndText
						text="기타"
						type="text"
						list={costOptions}
						value={community?.expenses}
						onChange={(v) => handleInput("expenses", v)}
						className="px-3 mt-[10px] xl:mt-[16px]"
					/>

					<InputLabel title="여행 기간" required={true} description="계획중인 일정을 선택해주세요." className="px-3 mt-[20px] " />
					{community?.startedA}
					<InputDateRange
						className="px-3 mt-[10px] xl:mt-[16px]"
						startDate={dateFormat(new Date(community?.startedAt))}
						endDate={dateFormat(new Date(community?.endedAt))}
						onChange={handleDateChange}
						active={true}
					/>

					<InputLabel title="모집 인원" required={true} className="px-3 mt-[20px]" />
					<InputListAndText
						text="기타"
						type="number"
						list={recruitNumberOptions}
						value={community?.numberOfRecruits}
						onChange={(v) => handleInput("numberOfRecruits", v)}
						className="px-3 mt-[10px] xl:mt-[16px]"
					/>

					<div className="flex px-3 gap-3 mt-[20px]">
						<div className="flex-1">
							<InputLabel title="모집 유형" required={true} />
							<InputSelect
								value={community?.recruitGenderType}
								className="mt-[10px]"
								onChange={(v) => handleInput("recruitGenderType", v)}
								options={[
									{
										label: "남녀무관",
										value: "All",
									},
									{ label: "남자", value: "Male" },
									{ label: "여자", value: "Female" },
								]}
							/>
						</div>
						<div className="flex-1">
							<InputLabel title="모집 연령" required={true} />
							<InputSelect
								value={community?.ageGroup}
								className="mt-[10px]"
								onChange={(v) => handleInput("ageGroup", v)}
								options={[
									{ label: "20대", value: "20대" },
									{ label: "30대", value: "30대" },
									{ label: "40대", value: "40대" },
									{ label: "50대", value: "50대" },
									{ label: "60대", value: "60대" },
								]}
							/>
						</div>
					</div>

					<InputLabel title="여행 유형" required={true} description="중복 선택 가능합니다." className="px-3 mt-[20px]" />
					<InputMultipleSelects
						value={community?.tripType}
						onChange={(v: any) => handleInput("tripType", v)}
						className="px-3 mt-[10px] xl:mt-[16px]"
						options={tripTypeOptions}
					/>

					<InputLabel title="숙소" required={true} className="px-3 mt-[20px]" />
					<InputSelect
						value={community?.accommodation}
						className="px-3  mt-[10px] xl:mt-[16px]"
						onChange={(v) => handleInput("accommodation", v)}
						options={accommodationOptions}
					/>

					<InputLabel title="교통" required={true} className="px-3 mt-[20px]" />
					<InputSelect
						value={community?.transportation}
						className="px-3 mt-[10px] xl:mt-[16px]"
						onChange={(v) => handleInput("transportation", v)}
						options={transportationOptions}
					/>

					<InputLabel title="가이드" required={true} description="요청조건 있을 경우 기타란에 입력해주세요. 중복 선택 가능" className="px-3 mt-[20px]" />
					<InputListAddText
						value={community?.guideAvailability}
						subValue={community?.guideAvailabilityDesc}
						list={[
							{ label: "필요", value: true },
							{ label: "불필요", value: false },
						]}
						onChange={(v: any) => handleInput("guideAvailability", v)}
						onChangeDesc={(v: any) => handleInput("guideAvailabilityDesc", v)}
						contentAlign="justify-start"
						className="px-3 mt-[10px] xl:mt-[16px]"
					/>

					<InputLabel title="홍보용 이미지" required={false} description="📸 " className="px-3 mt-[20px] " />
					<InputImage
						value={image}
						onChange={(v) => {
							handleInput("imageFileId", v);
						}}
						className="px-3 mt-[10px]"
					/>
					<div className="px-3 mt-[10px] text-[#777] text-[12px] font-medium font-spoqa">이미지 JPG, JPEG, PNG 업로드 가능</div>

					<div className="px-3 mt-[20px] ">여행 소개</div>
					<div className="px-3 mt-[10px] ">
						<textarea
							value={community?.description}
							onChange={(v: any) => handleInput("description", v.target.value)}
							className="border border-[#dadada] w-full h-[200px] resize-none py-[13px] px-[12px] text-xs  "
							placeholder=""
						/>
					</div>

					<hr className="w-full mt-[10px] mb-[10px] border-1 border-[#999999]" />

					<div
						className="text-[16px] mx-[10px] font-bold text-white bg-[#0057FF] rounded-[2px] py-[10px] flex justify-center items-center"
						onClick={() => {
							if (community?.userId !== my.id) {
								alert("작성자만 수정할 수 있습니다.");
								return;
							}

							COMMUNITY_CLUP.update(params.community_id, {
								nation: community.nation,
								city: community.city,
								startedAt: new Date(community.startedAt),
								endedAt: new Date(community.endedAt),
								expenses: community.expenses,
								numberOfRecruits: community.numberOfRecruits,
								recruitGenderType: community.recruitGenderType,
								ageGroup: community.ageGroup,
								tripType: community.tripType,
								accommodation: community.accommodation,
								transportation: community.transportation,
								guideAvailability: community.guideAvailability,
								guideAvailabilityDesc: community.guideAvailabilityDesc,
								quotationReceivingMethod: community.quotationReceivingMethod,
								quotationReceivingTarget: community.quotationReceivingTarget,
								travelRequest: community.travelRequest,
								title: community.title,
								description: community.description,
								imageFileId: community.imageFileId,
								alcoholPreference: community.alcoholPreference,
								smokingPreference: community.smokingPreference,
								travelStyle: community.travelStyle,
								mbti: community.mbti,
							}).then((res) => {
								window.location.href = `/community/${params.community_id}/details`;
							});
						}}>
						수정 완료
					</div>
				</div>
			</div>
		</div>
	);
}

const IconGender = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 5.6875C5.8269 5.6875 5.28125 6.23315 5.28125 6.90625C5.28125 7.57935 5.8269 8.125 6.5 8.125C7.1731 8.125 7.71875 7.57935 7.71875 6.90625C7.71875 6.23315 7.1731 5.6875 6.5 5.6875ZM4.46875 6.90625C4.46875 5.78442 5.37817 4.875 6.5 4.875C7.62183 4.875 8.53125 5.78442 8.53125 6.90625C8.53125 8.02808 7.62183 8.9375 6.5 8.9375C5.37817 8.9375 4.46875 8.02808 4.46875 6.90625Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.50002 8.9375C6.09008 8.9375 5.68577 9.03295 5.3191 9.21628C4.95244 9.39961 4.63349 9.66579 4.38752 9.99375C4.2529 10.1732 3.99827 10.2096 3.81877 10.075C3.63928 9.94038 3.6029 9.68574 3.73752 9.50625C4.05917 9.07739 4.47625 8.7293 4.95574 8.48956C5.43522 8.24981 5.96394 8.125 6.50002 8.125C7.0361 8.125 7.56482 8.24981 8.04431 8.48956C8.52379 8.7293 8.94088 9.07739 9.26252 9.50625C9.39714 9.68574 9.36077 9.94038 9.18127 10.075C9.00178 10.2096 8.74714 10.1732 8.61252 9.99375C8.36656 9.66579 8.04761 9.39961 7.68095 9.21628C7.31428 9.03295 6.90997 8.9375 6.50002 8.9375Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.1562 1.21875C10.605 1.21875 10.9688 1.58252 10.9688 2.03125V10.9688C10.9688 11.4175 10.605 11.7812 10.1562 11.7812H2.84375C2.39502 11.7812 2.03125 11.4175 2.03125 10.9688V2.03125C2.03125 1.58252 2.39502 1.21875 2.84375 1.21875L10.1562 1.21875ZM10.1562 10.9688V2.03125L2.84375 2.03125V10.9688H10.1562Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.46875 3.25C4.46875 3.02563 4.65063 2.84375 4.875 2.84375H8.125C8.34937 2.84375 8.53125 3.02563 8.53125 3.25C8.53125 3.47437 8.34937 3.65625 8.125 3.65625H4.875C4.65063 3.65625 4.46875 3.47437 4.46875 3.25Z"
			fill="#777777"
		/>
	</svg>
);

const IconShare = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.5 7.3125C3.56802 7.3125 2.8125 8.06802 2.8125 9C2.8125 9.93198 3.56802 10.6875 4.5 10.6875C5.43198 10.6875 6.1875 9.93198 6.1875 9C6.1875 8.06802 5.43198 7.3125 4.5 7.3125ZM1.6875 9C1.6875 7.4467 2.9467 6.1875 4.5 6.1875C6.0533 6.1875 7.3125 7.4467 7.3125 9C7.3125 10.5533 6.0533 11.8125 4.5 11.8125C2.9467 11.8125 1.6875 10.5533 1.6875 9Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.375 12.375C11.443 12.375 10.6875 13.1305 10.6875 14.0625C10.6875 14.9945 11.443 15.75 12.375 15.75C13.307 15.75 14.0625 14.9945 14.0625 14.0625C14.0625 13.1305 13.307 12.375 12.375 12.375ZM9.5625 14.0625C9.5625 12.5092 10.8217 11.25 12.375 11.25C13.9283 11.25 15.1875 12.5092 15.1875 14.0625C15.1875 15.6158 13.9283 16.875 12.375 16.875C10.8217 16.875 9.5625 15.6158 9.5625 14.0625Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.375 2.25C11.443 2.25 10.6875 3.00552 10.6875 3.9375C10.6875 4.86948 11.443 5.625 12.375 5.625C13.307 5.625 14.0625 4.86948 14.0625 3.9375C14.0625 3.00552 13.307 2.25 12.375 2.25ZM9.5625 3.9375C9.5625 2.3842 10.8217 1.125 12.375 1.125C13.9283 1.125 15.1875 2.3842 15.1875 3.9375C15.1875 5.4908 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 5.4908 9.5625 3.9375Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.9571 4.85027C11.125 5.11162 11.0493 5.45963 10.788 5.62758L6.69576 8.25727C6.43441 8.42521 6.0864 8.3495 5.91845 8.08815C5.75051 7.8268 5.82623 7.47878 6.08758 7.31084L10.1798 4.68115C10.4411 4.5132 10.7891 4.58892 10.9571 4.85027Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.91845 9.91277C6.0864 9.65142 6.43441 9.5757 6.69576 9.74365L10.788 12.3733C11.0493 12.5413 11.125 12.8893 10.9571 13.1506C10.7891 13.412 10.4411 13.4877 10.1798 13.3198L6.08758 10.6901C5.82623 10.5221 5.75051 10.1741 5.91845 9.91277Z"
			fill="#777777"
		/>
	</svg>
);

const IconLeader = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path
			d="M11.1802 12.6527H4.82016C4.54016 12.6527 4.28683 12.4727 4.19349 12.2127L1.42016 4.44604C1.20016 3.82604 1.90683 3.29937 2.43349 3.67937L5.10016 5.58604C5.45349 5.83938 5.96016 5.68604 6.11349 5.27938L7.37349 1.91938C7.58683 1.33938 8.40683 1.33938 8.62016 1.91938L9.88016 5.27938C10.0335 5.69271 10.5335 5.83938 10.8935 5.58604L13.5602 3.67937C14.0935 3.29937 14.7935 3.83271 14.5735 4.44604L11.8002 12.2127C11.7135 12.4727 11.4602 12.6527 11.1802 12.6527Z"
			fill="#202C4A"
		/>
		<path
			d="M11.3332 14.666H4.6665C4.39317 14.666 4.1665 14.4393 4.1665 14.166C4.1665 13.8927 4.39317 13.666 4.6665 13.666H11.3332C11.6065 13.666 11.8332 13.8927 11.8332 14.166C11.8332 14.4393 11.6065 14.666 11.3332 14.666Z"
			fill="#F5B826"
		/>
		<path
			d="M9.66683 9.83398H6.3335C6.06016 9.83398 5.8335 9.60732 5.8335 9.33398C5.8335 9.06065 6.06016 8.83398 6.3335 8.83398H9.66683C9.94016 8.83398 10.1668 9.06065 10.1668 9.33398C10.1668 9.60732 9.94016 9.83398 9.66683 9.83398Z"
			fill="#F5B826"
		/>
	</svg>
);

const IconAge = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 2.03125C4.03198 2.03125 2.03125 4.03198 2.03125 6.5C2.03125 8.96802 4.03198 10.9688 6.5 10.9688C8.96802 10.9688 10.9688 8.96802 10.9688 6.5C10.9688 4.03198 8.96802 2.03125 6.5 2.03125ZM1.21875 6.5C1.21875 3.58325 3.58325 1.21875 6.5 1.21875C9.41675 1.21875 11.7812 3.58325 11.7812 6.5C11.7812 9.41675 9.41675 11.7812 6.5 11.7812C3.58325 11.7812 1.21875 9.41675 1.21875 6.5Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 4.46875C5.60254 4.46875 4.875 5.19629 4.875 6.09375C4.875 6.99121 5.60254 7.71875 6.5 7.71875C7.39746 7.71875 8.125 6.99121 8.125 6.09375C8.125 5.19629 7.39746 4.46875 6.5 4.46875ZM4.0625 6.09375C4.0625 4.74756 5.15381 3.65625 6.5 3.65625C7.84619 3.65625 8.9375 4.74756 8.9375 6.09375C8.9375 7.43994 7.84619 8.53125 6.5 8.53125C5.15381 8.53125 4.0625 7.43994 4.0625 6.09375Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.50048 8.53125C5.9003 8.53125 5.31185 8.69744 4.80034 9.01141C4.28883 9.32537 3.87426 9.77484 3.60256 10.31C3.50099 10.5101 3.25647 10.5899 3.05642 10.4883C2.85636 10.3868 2.77651 10.1422 2.87808 9.94219C3.2177 9.27324 3.73592 8.7114 4.37531 8.31895C5.01469 7.92649 5.75026 7.71875 6.50048 7.71875C7.2507 7.71875 7.98627 7.92649 8.62565 8.31895C9.26503 8.7114 9.78325 9.27324 10.1229 9.94219C10.2244 10.1422 10.1446 10.3868 9.94454 10.4883C9.74448 10.5899 9.49996 10.5101 9.39839 10.31C9.1267 9.77484 8.71212 9.32537 8.20061 9.01141C7.68911 8.69744 7.10065 8.53125 6.50048 8.53125Z"
			fill="#777777"
		/>
	</svg>
);

const IconParty = (
	<svg className=" " xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 5.48438C5.60254 5.48438 4.875 6.21191 4.875 7.10938C4.875 8.00684 5.60254 8.73438 6.5 8.73438C7.39746 8.73438 8.125 8.00684 8.125 7.10938C8.125 6.21191 7.39746 5.48438 6.5 5.48438ZM4.0625 7.10938C4.0625 5.76318 5.15381 4.67188 6.5 4.67188C7.84619 4.67188 8.9375 5.76318 8.9375 7.10938C8.9375 8.45557 7.84619 9.54688 6.5 9.54688C5.15381 9.54688 4.0625 8.45557 4.0625 7.10938Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.1346 6.57458C10.768 6.39129 10.3637 6.29619 9.9538 6.29688C9.72944 6.29725 9.54725 6.11567 9.54688 5.89131C9.5465 5.66694 9.72808 5.48476 9.95245 5.48438C10.4889 5.48348 11.0181 5.60795 11.4979 5.84786C11.9778 6.08777 12.3949 6.43648 12.716 6.86617C12.8504 7.04589 12.8135 7.30047 12.6338 7.43479C12.4541 7.56911 12.1995 7.5323 12.0652 7.35259C11.8199 7.02429 11.5012 6.75787 11.1346 6.57458Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.04622 6.29688C2.63637 6.29619 2.23202 6.39129 1.86544 6.57458C1.49885 6.75787 1.18017 7.02429 0.934807 7.35259C0.800488 7.5323 0.545911 7.56911 0.366193 7.43479C0.186476 7.30047 0.149673 7.04589 0.283992 6.86617C0.605144 6.43648 1.02226 6.08777 1.50208 5.84786C1.98189 5.60795 2.51113 5.48348 3.04758 5.48438C3.27194 5.48476 3.45352 5.66694 3.45315 5.89131C3.45277 6.11567 3.27059 6.29725 3.04622 6.29688Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.50029 9.54883C5.96667 9.54883 5.44378 9.6987 4.99118 9.98137C4.53859 10.264 4.17448 10.6682 3.94034 11.1477C3.8419 11.3493 3.59865 11.4329 3.39703 11.3345C3.19542 11.236 3.11179 10.9928 3.21023 10.7911C3.51115 10.1749 3.9791 9.65553 4.56078 9.29224C5.14246 8.92895 5.81448 8.73633 6.50029 8.73633C7.18609 8.73633 7.85811 8.92895 8.43979 9.29224C9.02147 9.65553 9.48943 10.1749 9.79034 10.7911C9.88879 10.9928 9.80515 11.236 9.60354 11.3345C9.40193 11.4329 9.15868 11.3493 9.06023 11.1477C8.82609 10.6682 8.46199 10.264 8.00939 9.98137C7.55679 9.6987 7.0339 9.54883 6.50029 9.54883Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.34761 3.08506C3.12354 3.02761 2.88774 3.03512 2.66778 3.1067C2.44782 3.17828 2.25277 3.31098 2.10542 3.4893C1.95807 3.66762 1.86452 3.88418 1.83568 4.1137C1.80684 4.34321 1.84392 4.57619 1.94258 4.78542C2.04123 4.99464 2.19739 5.17147 2.39281 5.29524C2.58823 5.41901 2.81484 5.48461 3.04616 5.48437C3.27052 5.48415 3.45259 5.66585 3.45282 5.89021C3.45305 6.11458 3.27135 6.29665 3.04698 6.29687C2.66145 6.29727 2.28377 6.18793 1.95807 5.98165C1.63237 5.77536 1.3721 5.48065 1.20768 5.13194C1.04325 4.78324 0.981458 4.39493 1.02952 4.01241C1.07758 3.62989 1.23351 3.26894 1.47908 2.97175C1.72466 2.67455 2.04974 2.45338 2.41635 2.33408C2.78296 2.21478 3.17595 2.20227 3.5494 2.29802C3.92285 2.39377 4.26134 2.59382 4.52532 2.87479C4.7893 3.15577 4.96786 3.50607 5.04015 3.88476C5.08222 4.10515 4.93766 4.31791 4.71728 4.35998C4.49689 4.40205 4.28413 4.2575 4.24206 4.03711C4.19869 3.80989 4.09155 3.59971 3.93316 3.43113C3.77477 3.26254 3.57168 3.14251 3.34761 3.08506Z"
			fill="#777777"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.3318 3.1067C10.1118 3.03512 9.87604 3.02761 9.65197 3.08506C9.4279 3.14251 9.2248 3.26254 9.06642 3.43113C8.90803 3.59971 8.80089 3.80989 8.75752 4.03711C8.71545 4.2575 8.50269 4.40205 8.2823 4.35998C8.06191 4.31791 7.91736 4.10515 7.95943 3.88476C8.03171 3.50607 8.21028 3.15577 8.47426 2.87479C8.73824 2.59382 9.07673 2.39377 9.45018 2.29802C9.82363 2.20227 10.2166 2.21478 10.5832 2.33408C10.9498 2.45338 11.2749 2.67455 11.5205 2.97175C11.7661 3.26894 11.922 3.62989 11.9701 4.01241C12.0181 4.39493 11.9563 4.78324 11.7919 5.13194C11.6275 5.48065 11.3672 5.77536 11.0415 5.98165C10.7158 6.18793 10.3381 6.29727 9.95259 6.29687C9.72823 6.29665 9.54653 6.11458 9.54675 5.89021C9.54698 5.66585 9.72905 5.48415 9.95342 5.48437C10.1847 5.48461 10.4113 5.41901 10.6068 5.29524C10.8022 5.17147 10.9583 4.99464 11.057 4.78542C11.1557 4.57619 11.1927 4.34321 11.1639 4.1137C11.1351 3.88418 11.0415 3.66762 10.8942 3.4893C10.7468 3.31098 10.5518 3.17828 10.3318 3.1067Z"
			fill="#777777"
		/>
	</svg>
);
