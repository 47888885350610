import IconNoti from "../../../image/old-notification.svg";
import IconBell from "../../../image/new-notification.svg";
import IconNext from "../../icon/next";
import { useEffect, useState } from "react";
import Modal from "../../modal";
import { PARTNER, NOTIFICATION } from "../../../api";
import { log } from "console";
import MyPageOrderNotification from "../../modal/MyPageOrderClup";
import newNoti from "../../../image/new-notification.svg";
import noti from "../../../image/old-notification.svg";

const menus = [
	{ title: "여권정보 등록알림", to: "123", isOn: true, type: "REGISTER_AIR_TICKET" },
	{ title: "결제 완료", to: "123", isOn: true, type: "ORDER_PAYMENT_COMPLETE" },
	{ title: "주문서 리플", to: "123", isOn: true, type: "REPLY_PARTNER_QUOTATION_CHAT_BY_USER" },
	{ title: "여행 출발 임박", to: "123", isOn: true, type: "ORDER_START_DATE_CLOSED" },
	{ title: "주문 확정", to: "123", isOn: true, type: "CONFIRM_PARTNER_QUOTATION" },
	{ title: "주문 인입", to: "123", isOn: true, type: "CREATE_ORDER" },
];

export default function MyPagePartnerNotification() {
	const [partner, setPartner]: any = useState({});
	const [notifications, setNotifications] = useState<any>([]);
	const [notification, setNotification] = useState<any>([]);
	const [modal, setModal] = useState<any>(false);

	useEffect(() => {
		PARTNER.getMe()
			.then((res) => {
				setPartner(res);
				PARTNER.getPartnerNotification(res?.id)
					.then((res) => {
						setNotifications(res.data);
					})
					.catch((e) => {});
			})
			.catch((e) => {});
	}, []);

	const handleDoneNotification = () => {
		setModal(null);
		if (partner && partner.id) {
			PARTNER.getPartnerNotification(partner?.id).then((res) => {
				setNotifications(res);
			});
		}
	};

	return (
		<div className="px-3">
			{notifications.map((notification: any, i: number) => {
				let type: any = "ETC";
				if (notification.referenceType === "REGISTER_AIR_TICKET") {
					type = "REGISTER_AIR_TICKET";
				} else if (notification.referenceType === "ORDER_PAYMENT_COMPLETE") {
					type = "ORDER_PAYMENT_COMPLETE";
				} else if (notification.referenceType === "REPLY_PARTNER_QUOTATION_CHAT_BY_USER") {
					type = "REPLY_PARTNER_QUOTATION_CHAT_BY_USER";
				} else if (notification.referenceType === "ORDER_START_DATE_CLOSED") {
					type = "ORDER_START_DATE_CLOSED";
				} else if (notification.referenceType === "CONFIRM_PARTNER_QUOTATION") {
					type = "CONFIRM_PARTNER_QUOTATION";
				} else if (notification.referenceType === "CREATE_ORDER") {
					type = "CREATE_ORDER";
				}
				return (
					<div
						className={["flex gap-3 px-2 py-6 cursor-pointer", i !== 0 && "border-t border-[#dadada]"].join(" ")}
						onClick={async () => {
							await NOTIFICATION.read(notification.id);

							setModal(type);
							setNotification(notification);
							setNotifications(
								notifications.map((item: any) => {
									if (item.id === notification.id) {
										item.isRead = true;
									}
									return item;
								})
							);
						}}>
						<img src={notification.isRead ? noti : newNoti} />
						<div className={["text-sm font-medium", notification.isRead ? noti : newNoti ? "text-black" : "text-[#777]"].join(" ")}>{notification.title}</div>
						<div className="flex flex-1 justify-end cursor-pointer">
							<IconNext />
						</div>
					</div>
				);
			})}

			<Modal open={modal === "REGISTER_AIR_TICKET"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="여권정보 등록알림" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "ORDER_PAYMENT_COMPLETE"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="결제 완료" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "REPLY_PARTNER_QUOTATION_CHAT_BY_USER"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="주문서 리플" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "ORDER_START_DATE_CLOSED"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="여행 출발 임박" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "CONFIRM_PARTNER_QUOTATION"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="주문 확정" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "CREATE_ORDER"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="주문 인입" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "ETC"} onClose={() => setModal(null)}>
				<div className="flex justify-end cursor-pointer" onClick={() => setModal(null)}>
					{IconClose}
				</div>

				<div>{notification?.title}</div>

				<div className="text-sm font-normal">{notification?.content}</div>

				<div
					onClick={() => setModal(null)}
					className="cursor-pointer mt-8 rounded-sm bg-success flex justify-center items-center text-sm text-white font-bold py-1.5">
					확인
				</div>
			</Modal>
		</div>
	);
}

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);
