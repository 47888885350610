import { useEffect, useState } from "react";
import InputLabel from "../components/input/labe";
import InputList from "../components/input/list";
import InputText from "../components/input/text";
import InputDateRange from "../components/input/date-range";
import InputListAndText from "../components/input/list-and-text";
import InputSelect from "../components/input/select";
import InputListVS from "../components/input/list-vs";
import InputImage from "../components/input/image";
import InputTextarea from "../components/input/textarea";
import InputTextareaAndAGree from "../components/input/textarea-and-agree";
import InputListFlex1 from "../components/input/list-flex-1";
import InputListAddText from "../components/input/list-add-text";
import { COMMUNITY_CLUP, DEFAULT, ORDER, PARTNER_QUOTATION, USER } from "../api";
import GoogleMap from "../components/GoogleMap";
import CardCrewRecommend from "../components/main/CardCrewRecommend";
import { useSearchParams } from "react-router-dom";
import { InputMultipleSelects } from "../components/input/selects";
import { accommodations } from "../components/GetGlobalConfig";
import Modal from "../components/modal";
import { format, isAfter } from "date-fns";
import { log } from "console";

const agreeText1 = `모집형 모집은 팩리더님의 일정에 다른 유저들이 참여하여 모집인원 충족 시 출발이 확정되는 방식입니다.
해당 상품 커뮤니티에 참여를 요청할 경우 팩리더님의 승인 후 입장 가능하기 때문에
 시스템 내 알림을 통해 참여요청 여부를 확인하시길바랍니다.`;

const agreeText2 =
	"계획형 모집은 참여 크루와 함께 모든 일정을 상의 후 주문하는 방식으로 현재 선택항목은 확정내용이 아닙니다. 계획이 완성되면 [여행주문_단독형] 주문을 통해 견적을 받아볼 수 있습니다.";

const InputTag = ({ tag, value, essential, className, desc, onChange }: any) => {
	return (
		<div className={`w-full mb-[20px] px-[10px] mt-[10px] ${className}`}>
			<div className="w-ful text-[16px] font-medium relative">
				{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				{desc && <span className="text-[12px] font-medium ml-[20px] text-[#777777]">해당 제목으로 커뮤니티에 표시됩니다.</span>}
			</div>
			<input
				defaultValue={value}
				onChange={onChange}
				className="w-full min-h-[30px] flex items-center mt-[10px] border border-[#DADADA] text-[#999999] font-medium px-[8px]"></input>
		</div>
	);
};

export default function CommunityMakeRecruit() {
	const [communityType, setCommunityType] = useState("PLANNED");
	const [selectOrder, setSelectOrder]: any = useState();
	const [searchParams, setSearchParams] = useSearchParams();
	const [receiving, setReceiving] = useState(true);

	const [tripTypeOptions, setTripTypeOptions] = useState([]);
	const [accommodationOptions, setAccommodationOptions] = useState([]);
	const [transportationOptions, setTransportationOptions] = useState([]);
	const [orders, setOrders] = useState([]);
	const [modal, setModal] = useState("");
	const [agree, setAgree] = useState<boolean>(false);

	useEffect(() => {
		const order_id = searchParams.get("order_id");

		if (order_id) setCommunityType("RECRUITMENT");

		const tab = searchParams.get("tab");
		if (tab) setCommunityType(tab);
	}, []);

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setTripTypeOptions(res.tripTypes.map((e: any) => ({ label: e, value: e })));
			setAccommodationOptions(res.accommodations.map((e: any) => ({ label: e, value: e })));
			setTransportationOptions(res.transportations.map((e: any) => ({ label: e, value: e })));
		});
		const order_id = searchParams.get("order_id");

		if (communityType === "PLANNED") return;

		USER.getMyOrders().then((res) => {
			const filter_item = res.filter((e: any) => {
				const quotation = e.PartnerQuotations.find((e: any) => e.selectedAt);
				console.log(e, e.status, format(new Date(e.startedAt), "yyyy-MM-dd"), isAfter(new Date(e.startedAt), new Date()));
				return quotation && e.status === "PAYMENT_COMPLETE" && isAfter(new Date(e.startedAt), new Date()) && e.type === "RECRUITMENT";
			});
			setOrders(filter_item);

			if (order_id) {
				const getOrder = filter_item.find((e: any) => e.id == order_id);

				if (getOrder) {
					getOrder.startedAt = dateFormat(new Date(getOrder?.startedAt));
					getOrder.endedAt = dateFormat(new Date(getOrder?.endedAt));

					setSelectOrder(getOrder);

					return;
				}
			}

			if (filter_item.length) {
				// if (filter_item[0]?.startedAt) filter_item[0].startedAt = dateFormat(new Date(filter_item?.[0]?.startedAt));
				// if (filter_item[0]?.endedAt) filter_item[0].endedAt = dateFormat(new Date(filter_item?.[0]?.endedAt));

				console.log(filter_item?.[0]);
				setSelectOrder(filter_item?.[0]);
			} else {
				setCommunityType("PLANNED");
				alert("견적 확정된 모집형 주문서가 없어 계획형 켜뮤니티 생성만 가능합니다.");
			}
		});
	}, [communityType]);

	useEffect(() => {
		if (selectOrder) {
			order.title = selectOrder.title;
		}
	}, [selectOrder]);

	function dateFormat(date: Date) {
		let month: any = date.getMonth() + 1;
		let day: any = date.getDate();

		return `${date.getFullYear()}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
	}

	const [order, setOrder] = useState({
		type: "",
		name: "",
		title: "",
		nation: "",
		city: "",
		startedAt: "",
		endedAt: "",
		expenses: "50~100",
		numberOfRecruits: 0,
		recruitGenderType: "",
		ageGroup: "",
		tripType: "",
		accommodation: "",
		transportation: "",
		guideAvailability: true,
		guideAvailabilityDesc: "",
		quotationReceivingMethod: "EMAIL",
		quotationReceivingTarget: "",
		travelRequest: "",
		description: "",
		imageFileId: 0,
		alcoholPreference: "LIGHT_PREFERENCE",
		smokingPreference: "LIGHT_PREFERENCE",
		travelStyle: [] as any,
		mbti: "ISTJ",
	});

	const handleInput = (key: string, value: string | number) => {
		setOrder({
			...order,
			[key]: value,
		});
	};

	const handleDateChange = (startDate: string, endDate: string) => {
		setOrder({
			...order,
			startedAt: startDate,
			endedAt: endDate,
		});
	};

	const submit = () => {
		if (!order.title) {
			alert("여행 제목을 입력해주세요.");
			return;
		}
		if (!order.alcoholPreference) {
			alert("음주 여부를 입력해주세요.");
			return;
		}
		if (!order.smokingPreference) {
			alert("담배 여부를 입력해주세요.");
			return;
		}
		if (!order.travelStyle.length) {
			alert("여행 성향을 입력해주세요.");
			return;
		}
		if (!order.mbti) {
			alert("MBTI를 입력해주세요.");
			return;
		}
		if (!agree) {
			alert("안내 동의를 확인해주세요.");
			return;
		}
		if (!order.imageFileId) {
			alert("홍보용 이미지를 확인해주세요.");
			return;
		}
		if (!order.description) {
			alert("여행 소개를 입력해주세요.");
			return;
		}

		if (communityType === "RECRUITMENT") {
			const data = {
				type: communityType,
				orderId: selectOrder.id,
				title: order.title,
				alcoholPreference: order.alcoholPreference,
				smokingPreference: order.smokingPreference,
				travelStyle: order.travelStyle,
				promotionImageId: order.imageFileId,
				mbti: order.mbti,
				description: order.description,
			};

			COMMUNITY_CLUP.sendCommunityRecruiment(data).then((res) => {
				alert("커뮤니티가 생성되었습니다!");
				window.location.href = "/community_recruit";
			});
		} else if (communityType === "PLANNED") {
			if (!order.nation) {
				alert("국가를 선택해주세요.");
				return;
			}
			if (!order.city) {
				alert("도시를 선택해주세요.");
				return;
			}
			if (!order.startedAt) {
				alert("여행 시작 날짜를 선택해주세요.");
				return;
			}
			if (!order.endedAt) {
				alert("여행 종료 날짜를 선택해주세요.");
				return;
			}
			if (!order.numberOfRecruits) {
				alert("모입 인원을 입력해주세요.");
				return;
			}
			if (!order.recruitGenderType) {
				alert("모집 유형을 입력해주세요.");
				return;
			}
			if (!order.ageGroup) {
				alert("모집 연령을 입력해주세요.");
				return;
			}
			if (!order.tripType) {
				alert("여행유형을 입력해주세요.");
				return;
			}
			if (!order.accommodation) {
				alert("숙소를 입력해주세요.");
				return;
			}
			if (order.guideAvailability === undefined) {
				alert("가이드 필요 여부를 입력해주세요.");
				return;
			}
			if (!order.quotationReceivingMethod) {
				alert("견전 수신 방법을 입력해주세요.");
				return;
			}
			if (!order.quotationReceivingTarget) {
				alert("견적 수신 받을 곳을 입력해주세요.");
				return;
			}

			const data = {
				type: communityType,
				nation: order.nation,
				status: "RECRUITMENT",
				city: order.city,
				startedAt: order.startedAt,
				endedAt: order.endedAt,
				expenses: order.expenses,
				numberOfRecruits: order.numberOfRecruits,
				recruitGenderType: order.recruitGenderType,
				ageGroup: order.ageGroup,
				tripType: order.tripType,
				accommodation: order.accommodation,
				transportation: order.transportation,
				guideAvailability: order.guideAvailability,
				guideAvailabilityDesc: order.guideAvailabilityDesc,
				quotationReceivingMethod: order.quotationReceivingMethod,
				quotationReceivingTarget: order.quotationReceivingTarget,
				travelRequest: order.travelRequest,
				title: order.title,
				description: order.description,
				imageFileId: order.imageFileId,
				alcoholPreference: order.alcoholPreference,
				smokingPreference: order.smokingPreference,
				travelStyle: order.travelStyle,
				mbti: order.mbti,
			};

			COMMUNITY_CLUP.sendCommunityPlanned(data)
				.then((res) => {
					alert("커뮤니티가 생성되었습니다!");

					window.location.href = "/community_planning";
				})
				.catch((err) => {});
		}
	};

	const tripTypes = [
		{ label: "모집형", value: "RECRUITMENT" },
		{ label: "계획형", value: "PLANNED" },
	];

	const recruitNumberOptions = [
		{ label: "1", value: 1 },
		{ label: "2", value: 2 },
		{ label: "3", value: 3 },
		{ label: "4", value: 4 },
		{ label: "5", value: 5 },
		{ label: "6", value: 6 },
		{ label: "7", value: 7 },
		{ label: "8", value: 8 },
		{ label: "9", value: 9 },
		{ label: "10", value: 10 },
	];

	const tabaoOptions = [
		{ label: "흡연 선호", value: "LIGHT_PREFERENCE" },
		{ label: "흡연 비선호", value: "NON_PREFERENCE" },
		{ label: "상관 없어요", value: "INDIFFERENT" },
	];

	const alcoholOptions = [
		{ label: "가벼운 음주 선호", value: "LIGHT_PREFERENCE" },
		{ label: "음주 비선호", value: "NON_PREFERENCE" },
		{ label: "상관 없어요", value: "INDIFFERENT" },
	];

	const costOptions = [
		{ label: "50~100", value: "50~100" },
		{ label: "100~200", value: "100~200" },
		{ label: "200~300", value: "200~300" },
		{ label: "300~400", value: "300~400" },
		{ label: "400~500", value: "400~500" },
	];

	const TypeSelect = () => {
		return (
			<>
				<div className="flex gap-[12px] w-full px-[10px] mt-[10px]">
					<div
						className={`w-[85px] h-[33px] flex justify-center text-[14px] font-medium items-center border rounded-[2px] cursor-pointer ${
							communityType === "RECRUITMENT" ? "border-[#0057FF] text-[#0057FF] " : "boder-[#DADADA] text-[#DADADA]"
						}  `}
						onClick={() => {
							setCommunityType("RECRUITMENT");
						}}>
						모집형
					</div>
					<div
						className={`w-[85px] h-[33px] flex justify-center text-[14px] font-medium items-center border rounded-[2px] cursor-pointer ${
							communityType === "PLANNED" ? "border-[#0057FF] text-[#0057FF] " : "boder-[#DADADA] text-[#DADADA]"
						}  `}
						onClick={() => {
							setCommunityType("PLANNED");
						}}>
						계획형
					</div>
				</div>
			</>
		);
	};

	const VersusTag = ({ value1, value2 }: any) => {
		return (
			<div className="px-[10px] text-[14px] font-medium">
				<div className="flex justify-between items-center w-full gap-[15px]">
					<div
						className={`flex-1 h-[33px] border flex justify-center items-center cursor-pointer ${
							order.travelStyle.includes(value1) ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
						}`}
						onClick={() => {
							setOrder({
								...order,
								travelStyle: [...order.travelStyle.filter((e: any) => e !== value2), value1],
							});
						}}>
						{value1}
					</div>
					<div className="text-[#0E1111]">VS</div>
					<div
						className={`flex-1 h-[33px] border flex justify-center items-center cursor-pointer ${
							order.travelStyle.includes(value2) ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
						}`}
						onClick={() => {
							setOrder({
								...order,
								travelStyle: [...order.travelStyle.filter((e: any) => e !== value1), value2],
							});
						}}>
						{value2}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex justify-center items-center">
			<div className="max-w-[512px] w-full">
				<InputLabel title="여행 형태 선택" required={true} description="어떤 여행을 떠나실건가요?" className="px-3 mt-[20px] xl:mt-[50px]" />
				<TypeSelect />

				{(communityType === "RECRUITMENT" && (
					<>
						<InputLabel
							title="상품 선택"
							action={
								<div
									onClick={() => setModal("change")}
									className="cursor-pointer flex-1 flex items-center justify-end text-[#424549] font-spoqa text-[12px] font-medium ">
									다른 상품으로 변경하기
									<svg className="xl:w-[28px] xl:h-[28px]" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9ZM9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875Z"
											fill="#424549"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.9075 7.73815C12.1384 7.94597 12.1572 8.30164 11.9493 8.53255L9.4181 11.345C9.31142 11.4636 9.15946 11.5313 9 11.5313C8.84053 11.5313 8.68857 11.4636 8.58189 11.345L6.05064 8.53255C5.84282 8.30164 5.86154 7.94597 6.09245 7.73815C6.32336 7.53033 6.67903 7.54905 6.88685 7.77996L9 10.1279L11.1131 7.77996C11.321 7.54905 11.6766 7.53033 11.9075 7.73815Z"
											fill="#424549"
										/>
									</svg>
								</div>
							}
							required={true}
							className="px-3 mt-[20px] "
						/>
						<div className="px-3 mt-[10px]">
							<div
								className="w-full flex gap-[15px] xl:p-[10px]"
								style={{
									boxShadow: "0px 0px 8px 0px rgba(14, 17, 17, 0.20)",
								}}>
								<div
									className="flex-1 h-[186px] bg-center bg-cover flex justify-center items-center text-[#999] text-sm"
									style={{
										backgroundImage: `url(${
											selectOrder?.PartnerQuotations?.find((e: any) => e.selectedAt)?.PartnerQuotationFiles.find((e: any) => e.type === "TRAVLE_REQUEST")?.File
												?.url
										})`,
									}}></div>
								<div className="flex-1 flex flex-col h-full py-[10px]">
									<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium xl:text-[16px]">{selectOrder?.title}</div>
									<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[8px]   ">
										여행 형태: {(selectOrder?.type === "ALONE" && "단독 주문") || (selectOrder?.type === "RECRUITMENT" && "모집 주문")}
									</div>
									<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]   ">국가: {selectOrder?.nation}</div>
									<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]   ">도시: {selectOrder?.city}</div>
									<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]   ">
										{selectOrder?.numberOfRecruits}명{`${selectOrder?.type !== "RECRUITMENT" ? "" : "·" + (selectOrder?.recruitGenderTypeText ?? "")}`}
										{`${selectOrder?.type !== "RECRUITMENT" ? "" : "·" + (selectOrder?.ageGroup ?? "")}`}
									</div>
									<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]  ">
										{selectOrder?.startedAt ? dateFormat(new Date(selectOrder?.startedAt)) : ""} ~{" "}
										{selectOrder?.endedAt ? format(new Date(selectOrder?.endedAt), "yyyy-MM-dd") : ""}
									</div>
									<div className="px-[11px] text-end font-bold text-[#0e1111] text-[12px] mt-[11px] tracking-[-0.32px] ">
										{selectOrder?.PartnerQuotations.find((v: any) => v.selectedAt)?.finalQutotePrice}원{" "}
									</div>
								</div>
							</div>
						</div>
						<InputTag
							tag="여행 제목"
							value={selectOrder?.title}
							essential={true}
							desc={true}
							onChange={(e: any) => {
								handleInput("title", e.target.value);
							}}
						/>

						<InputLabel title="여행 기간" required={true} description="계획중인 일정을 선택해주세요." className="px-3 mt-[20px] " />
						{selectOrder?.startedA}
						<InputDateRange
							className="px-3 mt-[10px] xl:mt-[16px] "
							startDate={dateFormat(new Date(selectOrder?.startedAt))}
							endDate={dateFormat(new Date(selectOrder?.endedAt))}
							onChange={handleDateChange}
							active={false}
						/>
						<div className="flex px-3 gap-3 mt-[20px] ">
							<div className="flex-1">
								<InputLabel title="모집 인원" />
								<div className={`flex gap-3 mt-[10px]`}>
									<div className="py-[6px] px-[7px] w-full h-[32px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] bg-white appearance-none">
										{selectOrder?.numberOfRecruits}
									</div>
								</div>
							</div>
						</div>

						<div className="flex px-3 gap-3 mt-[20px] ">
							<div className="flex-1">
								<InputLabel title="모집 유형" />
								<div className={`flex gap-3 mt-[10px]`}>
									<div className="py-[6px] px-[7px] w-full h-[32px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] bg-white appearance-none">
										{selectOrder?.recruitGenderTypeText}
									</div>
								</div>
							</div>
							<div className="flex-1">
								<InputLabel title="모집 연령" />
								<div className={`flex gap-3 mt-[10px]`}>
									<div className="py-[6px] px-[7px] w-full h-[32px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] bg-white appearance-none">
										{selectOrder?.ageGroup}
									</div>
								</div>
							</div>
						</div>
					</>
				)) ||
					(communityType === "PLANNED" && (
						<>
							<InputLabel title="국가 선택" required={true} description="국가 입력시 자동으로 지도맵이 변경됩니다." className="px-3 mt-[20px]" />
							<InputText value={order?.nation} onChange={(v) => handleInput("nation", v)} className="px-3 mt-[10px] xl:mt-[16px]" />

							<InputLabel title="도시 선택" required={true} description="도시 연계의 경우 원하는 도시를 모두 입력해주세요." className="px-3 mt-[20px]" />
							<InputText value={order?.city} onChange={(v) => handleInput("city", v)} className="px-3 mt-[10px] xl:mt-[16px]" />
							<GoogleMap location={`${order?.nation}`} className="px-3 mt-[10px] xl:mt-[16px] h-[300px]" />

							<InputTag
								tag="여행 제목"
								value={order?.title}
								essential={true}
								desc={true}
								onChange={(e: any) => {
									handleInput("title", e.target.value);
								}}
							/>

							<InputLabel title="여행 기간" required={true} description="계획중인 일정을 선택해주세요." className="px-3 mt-[20px]" />
							<InputDateRange
								className="px-3 mt-[10px] xl:mt-[16px]"
								startDate={order?.startedAt}
								endDate={order?.endedAt}
								onChange={handleDateChange}
								active={true}
							/>

							<InputLabel title="1인 예상 경비" required={true} description="금액은 만원 단위이며, 중복 선택 가능합니다." className="px-3 mt-[20px]" />
							<InputListAndText
								text="기타"
								type="text"
								list={costOptions}
								value={order?.expenses}
								onChange={(v) => handleInput("expenses", v)}
								className="px-3 mt-[10px] xl:mt-[16px]"
							/>

							<InputLabel title="모집 인원" required={true} className="px-3 mt-[20px]" />
							<InputListAndText
								text="기타"
								type="number"
								list={recruitNumberOptions}
								value={order?.numberOfRecruits}
								onChange={(v) => handleInput("numberOfRecruits", v)}
								className="px-3 mt-[10px] xl:mt-[16px]"
							/>

							<div className="flex px-3 gap-3 mt-[20px]">
								<div className="flex-1">
									<InputLabel title="모집 유형" required={true} />
									<InputSelect
										value={order?.recruitGenderType}
										className="mt-[10px]"
										onChange={(v) => handleInput("recruitGenderType", v)}
										options={[
											{
												label: "남녀무관",
												value: "All",
											},
											{ label: "남자", value: "Male" },
											{ label: "여자", value: "Female" },
										]}
									/>
								</div>
								<div className="flex-1">
									<InputLabel title="모집 연령" required={true} />
									<InputSelect
										value={order?.ageGroup}
										className="mt-[10px]"
										onChange={(v) => handleInput("ageGroup", v)}
										options={[
											{ label: "20대", value: "20대" },
											{ label: "30대", value: "30대" },
											{ label: "40대", value: "40대" },
											{ label: "50대", value: "50대" },
											{ label: "60대", value: "60대" },
										]}
									/>
								</div>
							</div>

							<InputLabel title="여행 유형" required={true} description="중복 선택 가능합니다." className="px-3 mt-[20px]" />
							<InputMultipleSelects
								value={order?.tripType}
								onChange={(v: any) => handleInput("tripType", v)}
								className="px-3 mt-[10px] xl:mt-[16px]"
								options={tripTypeOptions}
							/>

							<InputLabel title="숙소" required={true} className="px-3 mt-[20px]" />
							<InputSelect
								value={order?.accommodation}
								className="px-3  mt-[10px] xl:mt-[16px]"
								onChange={(v) => handleInput("accommodation", v)}
								options={accommodationOptions}
							/>

							<InputLabel title="교통" required={true} className="px-3 mt-[20px]" />
							<InputSelect
								value={order?.transportation}
								className="px-3 mt-[10px] xl:mt-[16px]"
								onChange={(v) => handleInput("transportation", v)}
								options={transportationOptions}
							/>

							<InputLabel title="가이드" required={true} description="요청조건 있을 경우 기타란에 입력해주세요. 중복 선택 가능" className="px-3 mt-[20px]" />
							<InputListAddText
								value={order?.guideAvailability}
								subValue={order?.guideAvailabilityDesc}
								list={[
									{ label: "필요", value: true },
									{ label: "불필요", value: false },
								]}
								onChange={(v: any) => handleInput("guideAvailability", v)}
								onChangeDesc={(v: any) => handleInput("guideAvailabilityDesc", v)}
								contentAlign="justify-start"
								className="px-3 mt-[10px] xl:mt-[16px]"
							/>
						</>
					))}

				<InputLabel title="기호 확인하기 - 술" required={true} description="택 1 필수 입니다." className="px-3 mt-[20px] " />
				<InputListFlex1
					value={order?.alcoholPreference}
					list={alcoholOptions}
					onChange={(v) => handleInput("alcoholPreference", v)}
					className="px-3 mt-[10px] xl:mt-[16px]"
				/>

				<InputLabel title="기호 확인하기 - 담배" required={true} description="택 1 필수 입니다." className="px-3 mt-[20px] " />
				<InputListFlex1
					value={order?.smokingPreference}
					list={tabaoOptions}
					onChange={(v) => handleInput("smokingPreference", v)}
					className="px-3 mt-[10px] xl:mt-[16px]"
				/>

				<InputLabel title="여행 성향" required={true} description="3문항 다 체크 해주셔야합니다." className="px-3 mt-[20px] " />
				<div className="my-[10px] flex flex-col gap-[10px]">
					<VersusTag value1="휴양형" value2="액티비티형" />
					<VersusTag value1="도시형" value2="로컬형" />
					<VersusTag value1="랜드마크형" value2="모험형" />
				</div>

				{communityType === "PLANNED" && (
					<>
						<InputLabel title="견적 수신 방법" required={true} className="px-3 mt-[20px]" />
						<InputListAddText
							value={order?.quotationReceivingMethod}
							subValue={order?.quotationReceivingTarget}
							list={[
								{ label: "이메일", value: "EMAIL" },
								{ label: "카카오톡", value: "KAKAO" },
							]}
							onChange={(v: any) => handleInput("quotationReceivingMethod", v)}
							onChangeDesc={(v: any) => handleInput("quotationReceivingTarget", v)}
							contentAlign="justify-start"
							className="px-3 mt-[10px] xl:mt-[16px]"
						/>
					</>
				)}

				<div className="px-[10px] mt-[10px]">
					<InputLabel title="나의 MBTI는?" required={true} />
					<InputSelect
						value={order?.mbti}
						className="mt-[10px]"
						onChange={(v) => handleInput("mbti", v)}
						options={[
							{ label: "ISTJ", value: "ISTJ" },
							{ label: "ISFJ", value: "ISFJ" },
							{ label: "INFJ", value: "INFJ" },
							{ label: "INTJ", value: "INTJ" },
							{ label: "ISTP", value: "ISTP" },
							{ label: "ISFP", value: "ISFP" },
							{ label: "INFP", value: "INFP" },
							{ label: "INTP", value: "INTP" },
							{ label: "ESTP", value: "ESTP" },
							{ label: "ESFP", value: "ESFP" },
							{ label: "ENFP", value: "ENFP" },
							{ label: "ENTP", value: "ENTP" },
							{ label: "ESTJ", value: "ESTJ" },
							{ label: "ESFJ", value: "ESFJ" },
							{ label: "ENFJ", value: "ENFJ" },
							{ label: "ENTJ", value: "ENTJ" },
							{ label: "모름", value: "모름" },
						]}
					/>
				</div>

				<InputLabel title="홍보용 이미지" required={false} description="📸 " className="px-3 mt-[20px] " />
				<InputImage
					onChange={(v) => {
						handleInput("imageFileId", v);
					}}
					className="px-3 mt-[10px]"
				/>
				<div className="px-3 mt-[10px] text-[#777] text-[12px] font-medium font-spoqa">이미지 JPG, JPEG, PNG 업로드 가능</div>

				<InputLabel title="여행 소개" required={true} description="게시글에 등록되는 여행 소개글입니다." className="px-3 mt-[20px] " />
				<InputTextarea value={order?.description} onChange={(v: any) => handleInput("description", v)} />

				{communityType === "RECRUITMENT" ? (
					<>
						<InputLabel title="모집형 안내 동의" required={true} className="px-3 mt-[20px] " />
						<InputTextareaAndAGree
							text={agreeText1}
							onChange={(e: boolean) => {
								setAgree(e);
							}}
						/>
					</>
				) : (
					<>
						<InputLabel title="계획형 안내 동의" required={true} className="px-3 mt-[20px] " />
						<InputTextareaAndAGree
							text={agreeText2}
							onChange={(e: boolean) => {
								setAgree(e);
							}}
						/>
					</>
				)}
				<div className="px-3 mt-[21px] flex justify-center">
					<button
						className="text-white bg-[#0057ff] border border-[#dadada] h-[40px] w-full"
						onClick={() => {
							submit();
						}}>
						생성하기
					</button>
				</div>
			</div>

			<Modal open={modal === "change"} onClose={() => setModal("")}>
				<div>다른 상품으로 변경하기</div>
				{orders.map((order: any) => {
					const conformedQuotation = order?.PartnerQuotations.find((e: any) => e.selectedAt);
					let tripType = "";
					if (order?.type === "ALONE") tripType = "단독 주문";
					else if (order?.type === "RECRUITMENT") tripType = "모집 주문";
					else if (order?.type === "PLANNED") tripType = "계획 주문";

					const startedAt = format(new Date(order?.startedAt), "yyyy-MM-dd");
					const endedAt = format(new Date(order?.endedAt), "yyyy-MM-dd");

					return (
						<div
							className=" border-b-[#dadada] border-b py-2 cursor-pointer"
							onClick={() => {
								setSelectOrder(order);
								setModal("");
							}}>
							<div className="text-[10px] text-success">{conformedQuotation?.Partner?.corporateBusinessName}</div>
							<div className="text-xs">여행 형태: {tripType}</div>
							<div className="text-xs">국가: {order?.nation}</div>
							<div className="text-xs">도시: {order?.city}</div>
							<div className="text-xs">
								{order?.ageGroup} {order?.recruitGenderTypeText}
							</div>
							<div className="text-xs">
								{startedAt} ~ {endedAt}
							</div>
						</div>
					);
				})}
			</Modal>
		</div>
	);
}
