import { useEffect, useState } from "react";
import { FILE, PARTNER, PARTNER_QUOTATION } from "../../api";
import { set } from "date-fns";
import Modal from "../modal";
import MyPagePartnerProfile from "../modal/MyPagePartnerProfile";
import IconUpload from "../icon/upload";
import MyPagePartnerAccount from "../modal/MyPagePartnerAccount";
import MyPageChangeText from "../modal/MyPageChangeText";
import MyPageChangePassword from "../modal/MyPageChangePassword";
import IconLock from "../icon/lock";
import MyPagePartnerNotification from "./partner/notification";
import MyPagePartnerOrder from "./partner/order";
import MyPagePartnerOrderConfirm from "./partner/order-confirm";
import { logout } from "../../auth";
import SvgIcon from "../SvgIcon";

const IconCompany = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M10.5266 19.2022H2.81368C1.75108 19.2022 0.880859 18.3503 0.880859 17.306V3.71219C0.880859 1.3122 2.66711 0.222128 4.85641 1.29388L8.92357 3.29082C9.80296 3.72135 10.5266 4.86638 10.5266 5.83737V19.2022Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.2021 12.8441V16.3067C19.2021 18.2853 18.2861 19.2013 16.3075 19.2013H10.5273V8.59375L10.9579 8.68535L15.08 9.61054L16.9395 10.0228C18.1487 10.2884 19.138 10.9113 19.193 12.6701C19.2021 12.725 19.2021 12.78 19.2021 12.8441Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M4.11328 7.29297H7.29189" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M4.11328 10.957H7.29189" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path
			d="M15.0791 9.61122V12.5608C15.0791 13.6967 14.154 14.6219 13.0181 14.6219C11.8822 14.6219 10.957 13.6967 10.957 12.5608V8.68604L15.0791 9.61122Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.1911 12.6713C19.1361 13.7523 18.2384 14.6225 17.1392 14.6225C16.0033 14.6225 15.0781 13.6973 15.0781 12.5614V9.61182L16.9377 10.024C18.1468 10.2897 19.1361 10.9126 19.1911 12.6713Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconLocation = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
			stroke="#0E1111"
			strokeWidth="1.2"
		/>
		<path
			d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
			stroke="#0E1111"
			strokeWidth="1.2"
		/>
	</svg>
);

const IconEarth = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M9.99935 18.3332C14.6017 18.3332 18.3327 14.6022 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M6.66667 2.5H7.5C5.875 7.36667 5.875 12.6333 7.5 17.5H6.66667" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M12.5 2.5C14.125 7.36667 14.125 12.6333 12.5 17.5" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path
			d="M2.5 13.3333V12.5C7.36667 14.125 12.6333 14.125 17.5 12.5V13.3333"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M2.5 7.5C7.36667 5.875 12.6333 5.875 17.5 7.5" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
	</svg>
);

const IconCall = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M18.3077 15.2748C18.3077 15.5748 18.241 15.8832 18.0993 16.1832C17.9577 16.4832 17.7744 16.7665 17.5327 17.0332C17.1244 17.4832 16.6743 17.8082 16.166 18.0165C15.666 18.2248 15.1244 18.3332 14.541 18.3332C13.691 18.3332 12.7827 18.1332 11.8243 17.7248C10.866 17.3165 9.90768 16.7665 8.95768 16.0748C7.99935 15.3748 7.09102 14.5998 6.22435 13.7415C5.36602 12.8748 4.59102 11.9665 3.89935 11.0165C3.21602 10.0665 2.66602 9.1165 2.26602 8.17484C1.86602 7.22484 1.66602 6.3165 1.66602 5.44984C1.66602 4.88317 1.76602 4.3415 1.96602 3.8415C2.16602 3.33317 2.48268 2.8665 2.92435 2.44984C3.45768 1.92484 4.04102 1.6665 4.65768 1.6665C4.89102 1.6665 5.12435 1.7165 5.33268 1.8165C5.54935 1.9165 5.74102 2.0665 5.89102 2.28317L7.82435 5.00817C7.97435 5.2165 8.08268 5.40817 8.15768 5.5915C8.23268 5.7665 8.27435 5.9415 8.27435 6.09984C8.27435 6.29984 8.21602 6.49984 8.09935 6.6915C7.99102 6.88317 7.83268 7.08317 7.63268 7.28317L6.99935 7.9415C6.90768 8.03317 6.86602 8.1415 6.86602 8.27484C6.86602 8.3415 6.87435 8.39984 6.89102 8.4665C6.91602 8.53317 6.94102 8.58317 6.95768 8.63317C7.10768 8.90817 7.36602 9.2665 7.73268 9.69984C8.10768 10.1332 8.50768 10.5748 8.94102 11.0165C9.39102 11.4582 9.82435 11.8665 10.266 12.2415C10.6993 12.6082 11.0577 12.8582 11.341 13.0082C11.3827 13.0248 11.4327 13.0498 11.491 13.0748C11.5577 13.0998 11.6243 13.1082 11.6993 13.1082C11.841 13.1082 11.9493 13.0582 12.041 12.9665L12.6743 12.3415C12.8827 12.1332 13.0827 11.9748 13.2743 11.8748C13.466 11.7582 13.6577 11.6998 13.866 11.6998C14.0243 11.6998 14.191 11.7332 14.3743 11.8082C14.5577 11.8832 14.7494 11.9915 14.9577 12.1332L17.716 14.0915C17.9327 14.2415 18.0827 14.4165 18.1743 14.6248C18.2577 14.8332 18.3077 15.0415 18.3077 15.2748Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			stroke-miterlimit="10"
		/>
	</svg>
);

const IconPhone = (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
		<path
			d="M15 5.25V13.75C15 17.15 14.125 18 10.625 18H5.375C1.875 18 1 17.15 1 13.75V5.25C1 1.85 1.875 1 5.375 1H10.625C14.125 1 15 1.85 15 5.25Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M9.75 3.9751H6.25" stroke="#0E1111" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
		<path
			d="M8.00078 15.5349C8.74982 15.5349 9.35703 14.945 9.35703 14.2174C9.35703 13.4898 8.74982 12.8999 8.00078 12.8999C7.25175 12.8999 6.64453 13.4898 6.64453 14.2174C6.64453 14.945 7.25175 15.5349 8.00078 15.5349Z"
			stroke="#0E1111"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconCamera = (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
		<path
			d="M4.78813 15.5832H12.2115C14.1665 15.5832 14.9456 14.3861 15.0377 12.9269L15.406 7.07609C15.5052 5.54609 14.2869 4.24984 12.7498 4.24984C12.3177 4.24984 11.921 4.00192 11.7227 3.61942L11.2127 2.59234C10.8869 1.94775 10.0369 1.4165 9.31438 1.4165H7.69229C6.96271 1.4165 6.11271 1.94775 5.78688 2.59234L5.27688 3.61942C5.07854 4.00192 4.68188 4.24984 4.24979 4.24984C2.71271 4.24984 1.49438 5.54609 1.59354 7.07609L1.96188 12.9269C2.04688 14.3861 2.83313 15.5832 4.78813 15.5832Z"
			stroke="#F5F5F5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M7.4375 5.6665H9.5625" stroke="#F5F5F5" strokeLinecap="round" stroke-linejoin="round" />
		<path
			d="M8.49935 12.7502C9.76727 12.7502 10.8014 11.716 10.8014 10.4481C10.8014 9.18016 9.76727 8.146 8.49935 8.146C7.23143 8.146 6.19727 9.18016 6.19727 10.4481C6.19727 11.716 7.23143 12.7502 8.49935 12.7502Z"
			stroke="#F5F5F5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconProfile = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M18.1394 21.6198C17.2594 21.8798 16.2194 21.9998 14.9994 21.9998H8.99937C7.77937 21.9998 6.73937 21.8798 5.85938 21.6198C6.07937 19.0198 8.74937 16.9697 11.9994 16.9697C15.2494 16.9697 17.9194 19.0198 18.1394 21.6198Z"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.5799 10.58C15.5799 12.56 13.9799 14.17 11.9999 14.17C10.0199 14.17 8.41992 12.56 8.41992 10.58C8.41992 8.60002 10.0199 7 11.9999 7C13.9799 7 15.5799 8.60002 15.5799 10.58Z"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconNotification = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M12.0206 2.91016C8.71058 2.91016 6.02058 5.60016 6.02058 8.91016V11.8002C6.02058 12.4102 5.76058 13.3402 5.45058 13.8602L4.30058 15.7702C3.59058 16.9502 4.08058 18.2602 5.38058 18.7002C9.69058 20.1402 14.3406 20.1402 18.6506 18.7002C19.8606 18.3002 20.3906 16.8702 19.7306 15.7702L18.5806 13.8602C18.2806 13.3402 18.0206 12.4102 18.0206 11.8002V8.91016C18.0206 5.61016 15.3206 2.91016 12.0206 2.91016Z"
			stroke="#202C4A"
			strokeWidth="1.5"
			stroke-miterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z"
			stroke="#202C4A"
			strokeWidth="1.5"
			stroke-miterlimit="10"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.0195 19.0601C15.0195 20.7101 13.6695 22.0601 12.0195 22.0601C11.1995 22.0601 10.4395 21.7201 9.89953 21.1801C9.35953 20.6401 9.01953 19.8801 9.01953 19.0601"
			stroke="#202C4A"
			strokeWidth="1.5"
			stroke-miterlimit="10"
		/>
	</svg>
);

const IconOrderStatus = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M5.90219 17H18.0922C19.9922 17 20.9922 16 20.9922 14.1V2H2.99219V14.1C3.00219 16 4.00219 17 5.90219 17Z"
			stroke="#202C4A"
			strokeWidth="1.2"
			stroke-miterlimit="10"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M2 2H22" stroke="#202C4A" strokeWidth="1.2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M8 22L12 20V17" stroke="#202C4A" strokeWidth="1.2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
		<path d="M16 22L12 20" stroke="#202C4A" strokeWidth="1.2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
		<path
			d="M7.5 11L10.65 8.37C10.9 8.16 11.23 8.22 11.4 8.5L12.6 10.5C12.77 10.78 13.1 10.83 13.35 10.63L16.5 8"
			stroke="#202C4A"
			strokeWidth="1.2"
			stroke-miterlimit="10"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconHand = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M23 7.27238H20M20 7.27238H19C17 4.96033 14 2.64392 12 4.95827M20 7.27238V16.5288M12 4.95827L8.99956 8.43133C8.9202 8.52318 8.88052 8.56911 8.84859 8.60976C8.15499 9.4927 8.15528 10.8411 8.84927 11.7236C8.88121 11.7642 8.92098 11.8102 9.00031 11.902C9.07967 11.9937 9.11936 12.0396 9.15449 12.0766C9.91743 12.8788 11.0824 12.8786 11.8451 12.0761C11.8802 12.0391 11.9199 11.9932 11.9992 11.9014L12.9996 10.7439M12 4.95827C10 2.64392 7 4.96041 5 7.27246H4M1 7.27246H4M4 7.27246V16.5288M20 16.5288V20H23M20 16.5288H17.1716M15 13.0577L16.5 14.7932C16.5796 14.8854 16.6195 14.9315 16.6515 14.9723C17.3449 15.8549 17.3449 17.2027 16.6515 18.0854C16.6195 18.1262 16.5796 18.1723 16.5 18.2644C16.4204 18.3565 16.3805 18.4027 16.3453 18.4397C15.5824 19.242 14.4176 19.242 13.6547 18.4397C13.6195 18.4027 13.5796 18.3565 13.5 18.2644L13 17.6859C12.4548 18.3168 12.1821 18.6322 11.888 18.8008C11.3285 19.1217 10.6715 19.1217 10.112 18.8008C9.81788 18.6322 9.54525 18.3168 9 17.6859C8.31085 18.7491 6.89563 18.6014 6.38197 17.4127L6 16.5288H4M4 16.5288V20H1"
			stroke="#202C4A"
			strokeWidth="1.2"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const menues = [
	{ name: "프로필", icon: IconProfile },
	{ name: "알림", icon: IconNotification },
	{ name: "주문현황", icon: IconOrderStatus },
	{ name: "주문확정", icon: IconHand },
];

enum ModalStatus {
	ACCOUNT_CHANGE,
	BUSINESS_REGISTER,
	PASSWORD_CHANGE,
	WITHDRAW,
	NATION_CHANGE,
	CITY_CHANGE,
	LOCATION_CHANGE,
}

export default function MyPagePartner() {
	const [modal, setModal] = useState<ModalStatus | null>(null);

	const [partner, setPartner] = useState<any>({
		PartnerFiles: [],
		PartnerRequest: [],
		address: "",
		businessNumber: "",
		city: "",
		corporateBusinessName: "",
		description: "",
		email: "",
		id: "",
		landlinePhone: "",
		mobilePhone: "",
		name: "",
		nation: "",
		position: "",
		profileImageUrl: "",
		profileFile: null,
	});

	const [activeMenu, setActiveMenu] = useState("프로필");
	const [nation, setNation] = useState("");
	const [city, setCity] = useState("");
	const [address, setAddress] = useState("");

	const [countQuotations, setCountQuotations] = useState(0);

	useEffect(() => {
		PARTNER.getMe()
			.then((data: any) => {
				setPartner(data);

				setNation(data.nation);
				setCity(data.city);
				setAddress(data.address);
			})
			.catch((error) => {
				window.location.href = "/";
			});

		PARTNER_QUOTATION.getPartnerOwnedCount().then((data: any) => {
			setCountQuotations(data.count);
		});
	}, [modal]);

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;

		// 파일을 읽고 미리보기 URL을 설정합니다.
		if (file) {
			FILE.upload(file).then(async (res) => {
				await PARTNER.uploadProfile(res.id);
				alert("프로필 사진이 변경되었습니다.");
				PARTNER.getMe().then((data: any) => {
					setPartner(data);
				});
			});
		}
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="mt-8 flex justify-between px-3">
					<div className="flex items-center gap-3.5">
						<div
							className="h-12 w-12 overflow-hidden rounded-full bg-cover bg-center"
							style={{
								backgroundImage: `url(${partner.profileFile?.url})`,
							}}></div>
						<div>
							<div className="font-bold text-black">{partner.corporateBusinessName}</div>
							<div className="text-xs font-medium text-black">등록된 패키지 상품 {countQuotations}개가 있습니다.</div>
						</div>
					</div>
					<div className="flex items-center justify-center" onClick={logout}>
						<div className="flex-none border border-[#dadada] px-3.5 py-1 text-xs text-[#777] cursor-pointer">로그아웃</div>
					</div>
				</div>

				<div className="mb-2.5 mt-10 w-full border-t border-[#999]"></div>

				<div className="relative top-1 z-0 flex gap-2 px-3">
					{menues.map((menu, index) => (
						<div
							onClick={() => setActiveMenu(menu.name)}
							className={[
								"flex h-20 w-16 flex-col items-center justify-center gap-2.5 cursor-pointer",
								menu.name === activeMenu
									? "rounded-tl-[10px] rounded-tr-[10px] border-b border-l-2 border-r-2 border-t-2 border-[#dadada] bg-[#F6F7F9] shadow-lg"
									: "",
							].join(" ")}>
							<div>{menu.icon}</div>
							<div className={["text-sm font-medium", menu.name === activeMenu ? "text-success" : "text-[#202C4A]"].join(" ")}>{menu.name}</div>
						</div>
					))}
				</div>
				<div className="relative z-10 px-3">
					<div className="z-10 min-h-[400px] border-2 border-[#dadada] bg-[#F6F7F9] shadow-lg">
						{activeMenu === "프로필" && (
							<div className="mb-8">
								<div className="mt-2 px-3.5 font-bold">계정관리</div>

								<div className="mt-5 flex items-center gap-3.5 px-3.5">
									<label className="relative">
										<input type="file" className="hidden" onChange={handleImageChange} />
										<div
											className="relative h-[90px] w-[90px] overflow-hidden rounded-full bg-cover bg-center"
											style={{
												backgroundImage: `url(${partner.profileFile?.url})`,
											}}></div>
										<div className="absolute bottom-1 right-1 flex h-6 w-6 items-center justify-center rounded-full bg-[#999]">{IconCamera}</div>
									</label>

									<div>
										<div className="font-bold text-black">{partner.corporateBusinessName}</div>
										<div className="mt-2 font-bold text-black ">{partner.name}</div>
									</div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									{IconPhone}
									<span className="text-sm font-medium text-black">연락처(M)</span>
									<span className="text-sm font-medium text-black">{partner.mobilePhone}</span>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									{IconCall}
									<span className="text-sm font-medium text-black">연락처(T)</span>
									<span className="text-sm font-medium text-black">{partner.landlinePhone}</span>
								</div>

								<div className="px-3">
									<div className="mb-2.5 mt-5 w-full border-t border-[#dadada]"></div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									<IconLock />
									<span className="text-sm font-medium text-black">비밀번호</span>
									<div className="flex flex-1 justify-end" onClick={() => setModal(ModalStatus.PASSWORD_CHANGE)}>
										<div className="rounded-sm border border-[#dadada] px-3.5 py-1 text-sm font-normal text-[#777] cursor-pointer">수정</div>
									</div>
								</div>

								<div className="px-3">
									<div className="mb-2.5 mt-5 w-full border-t border-[#dadada]"></div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									{IconEarth}
									<span className="text-sm font-medium text-black">국가</span>
									<span className="text-sm font-medium text-black">{partner.nation}</span>
									<div className="flex flex-1 justify-end" onClick={() => setModal(ModalStatus.NATION_CHANGE)}>
										<div className="rounded-sm border border-[#dadada] px-3.5 py-1 text-sm font-normal text-[#777] cursor-pointer">수정</div>
									</div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									{IconLocation}
									<span className="text-sm font-medium text-black">도시</span>
									<span className="text-sm font-medium text-black">{partner.city}</span>
									<div className="flex flex-1 justify-end" onClick={() => setModal(ModalStatus.CITY_CHANGE)}>
										<div className="rounded-sm border border-[#dadada] px-3.5 py-1 text-sm font-normal text-[#777] cursor-pointer">수정</div>
									</div>
								</div>

								<div className="px-3">
									<div className="mb-2.5 mt-5 w-full border-t border-[#dadada]"></div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									{IconCall}
									<span className="text-sm font-medium text-black">사업자번호</span>
									<span className="text-sm font-medium text-black">{partner.businessNumber}</span>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									<IconUpload />
									<span className="text-sm font-medium text-black">통장사본/은행코드</span>
								</div>
								<div className="mt-2.5 flex items-center justify-between px-4">
									<div className="ml-8 text-sm font-medium text-black">통장사본파일명.JPG</div>
									<div className="flex flex-1 justify-end" onClick={() => setModal(ModalStatus.ACCOUNT_CHANGE)}>
										<div className="rounded-sm border border-[#dadada] px-3.5 py-1 text-sm font-normal text-[#777] cursor-pointer">수정</div>
									</div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									<IconUpload />
									<span className="text-sm font-medium text-black">사업자등록증</span>
								</div>
								<div className="mt-2.5 flex items-center justify-between px-4">
									<div className="ml-8 text-sm font-medium text-black">사업자등록증파일명.JPG</div>
									<div className="flex flex-1 justify-end" onClick={() => setModal(ModalStatus.BUSINESS_REGISTER)}>
										<div className="rounded-sm border border-[#dadada] px-3.5 py-1 text-sm font-normal text-[#777] cursor-pointer">수정</div>
									</div>
								</div>

								<div className="mt-5 flex items-center justify-start gap-3 px-4">
									{IconCompany}
									<span className="text-sm font-medium text-black">사업장주소</span>
								</div>
								<div className="mt-2.5 flex items-center justify-between px-4">
									<div className="ml-8 text-sm font-medium text-black">{partner.address}</div>
									<div className="flex flex-1 justify-end" onClick={() => setModal(ModalStatus.LOCATION_CHANGE)}>
										<div className="rounded-sm border border-[#dadada] px-3.5 py-1 text-sm font-normal text-[#777] cursor-pointer">수정</div>
									</div>
								</div>
							</div>
						)}
						{activeMenu === "알림" && <MyPagePartnerNotification />}
						{activeMenu === "주문현황" && <MyPagePartnerOrder />}
						{activeMenu === "주문확정" && <MyPagePartnerOrderConfirm />}
					</div>

					{activeMenu === "프로필" && (
						<div className="mt-5 flex">
							<div className="border-[#dadada] bg-[#dadada] px-2 py-1 text-xs font-normal text-[#777] cursor-pointer">회원탈퇴</div>
						</div>
					)}
				</div>
				{modal === ModalStatus.PASSWORD_CHANGE && <MyPageChangePassword value="123" onChange={() => {}} onClose={() => setModal(null)} />}
				{/* {modal === ModalStatus.NATION_CHANGE && (
					<MyPageChangeText value={nation} title={<>{IconEarth} 국가 수정</>} onChange={setNation} onClose={() => setModal(null)} onClick={(e) => {
						PARTNER.changeProfile({ nation: nation }).then(res => 
						{
							setModal(null)
							}
						)
					}}/>
				)} */}
				<Modal open={modal === ModalStatus.NATION_CHANGE} onClose={() => setModal(null)}>
					<div>
						<div className="flex gap-[5px] mb-[20px]">{IconEarth}국가 수정</div>

						<input
							className="border border-[#DADADA] w-full px-[5px] text-[14px] font-normal h-[30px]"
							defaultValue={nation}
							value={nation}
							onChange={(v) => {
								setNation(v.target.value);
							}}></input>

						<div
							onClick={() => {
								PARTNER.changeProfile({ nation: nation }).then((res) => {
									setModal(null);
								});
							}}
							className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
							확인
						</div>
						<div
							onClick={() => setModal(null)}
							className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
							취소
						</div>
					</div>
				</Modal>
				<Modal open={modal === ModalStatus.CITY_CHANGE} onClose={() => setModal(null)}>
					<div>
						<div className="flex gap-[5px] mb-[20px]">{IconEarth}도시 수정</div>

						<input
							className="border border-[#DADADA] w-full px-[5px] text-[14px] font-normal h-[30px]"
							defaultValue={city}
							value={city}
							onChange={(v) => {
								setCity(v.target.value);
							}}></input>

						<div
							onClick={() => {
								PARTNER.changeProfile({ city: city }).then((res) => {
									setModal(null);
								});
							}}
							className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
							확인
						</div>
						<div
							onClick={() => setModal(null)}
							className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
							취소
						</div>
					</div>
				</Modal>
				{modal === ModalStatus.ACCOUNT_CHANGE && <MyPagePartnerAccount onClose={() => setModal(null)} />}
				{modal === ModalStatus.BUSINESS_REGISTER && <MyPagePartnerProfile onClose={() => setModal(null)} />}
				<Modal open={modal === ModalStatus.LOCATION_CHANGE} onClose={() => setModal(null)}>
					<div>
						<div className="flex gap-[5px] mb-[20px]">{IconEarth}사업장 주소 수정</div>

						<input
							className="border border-[#DADADA] w-full px-[5px] text-[14px] font-normal h-[30px]"
							defaultValue={address}
							value={address}
							onChange={(v) => {
								setAddress(v.target.value);
							}}></input>

						<div
							onClick={() => {
								PARTNER.changeProfile({ address: address }).then((res) => {
									setModal(null);
								});
							}}
							className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
							확인
						</div>
						<div
							onClick={() => setModal(null)}
							className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
							취소
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
}
