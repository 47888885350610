import React, { useEffect, useMemo, useState } from "react";
import { ListFormat } from "typescript";

interface Props {
	data: {
		id: string | number;
		imageUrl: string;
		title: string;
		nation: string;
		category1: string | null;
		category2: string | null;
		countOfLike: number;
		description: string;
	};
	className?: string;
	onClick?: () => void;
}

const IconHeart = () => {
	return (
		<svg className="xl:w-[16px] xl:relative xl:bottom-[1px]" width="12" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="vuesax/bold/heart">
				<g id="heart">
					<path
						id="Vector"
						d="M8.05515 2.36523C7.1855 2.36523 6.40714 2.78805 5.92187 3.43668C5.4366 2.78805 4.65824 2.36523 3.78859 2.36523C2.31355 2.36523 1.11719 3.56641 1.11719 5.05105C1.11719 5.62281 1.20848 6.15132 1.36703 6.6414C2.12617 9.04374 4.46605 10.4803 5.62398 10.8743C5.78734 10.932 6.0564 10.932 6.21976 10.8743C7.37769 10.4803 9.71757 9.04374 10.4767 6.6414C10.6353 6.15132 10.7266 5.62281 10.7266 5.05105C10.7266 3.56641 9.53019 2.36523 8.05515 2.36523Z"
						fill="#D70D0D"
					/>
				</g>
			</g>
		</svg>
	);
};

function CardCrewRecommend({ data, className, onClick }: Props) {
	const label = useMemo(() => {
		if (data.category1) {
			return `${data.category1} · ${data.category2}`;
		}

		return `${data.nation}`;
	}, [data]);

	const description = useMemo(() => {
		return data.description.split("\n").map((line: string, index: number) => {
			return (
				<span key={index}>
					{line}
					<br />
				</span>
			);
		});
	}, [data]);

	return (
		<div
			style={{
				filter: "drop-shadow(0px 0px 8px rgba(14, 17, 17, 0.05))",
			}}
			className={["h-[226px] relative border border-[#dadada] xl:h-[353px] cursor-pointer", className].join(" ")}
			onClick={onClick}>
			<div
				className="h-[139px] w-full bg-center bg-cover relative xl:h-[218px]"
				style={{
					backgroundImage: `url(${data.imageUrl})`,
				}}>
				<div className="absolute left-[5px] top-[5px] bg-[#355C7D] text-[#F5F5F5] text-[7.687px] font-medium font-spoqa tracking-[-0.205px] rounded-[1.281px] px-[5px] py-[2px] xl:text-[12px]">
					{label}
				</div>
				<div
					className="absolute left-0 bottom-0 flex gap-[5px] items-center justify-center text-[10px] w-[65px] font-medium text-[#0E1111] bg-[#F5F5F5] bg-opacity-60 h-[18px] rounded-tr-[8.65px] xl:text-[14px] xl:w-[102px] xl:h-[29px]"
					style={{
						boxShadow: "0px -1.92187px 2.5625px 0px rgba(0, 0, 0, 0.25)",
					}}>
					<IconHeart />
					{data.countOfLike.toLocaleString()}
				</div>
			</div>
			<div className="px-[5px] text-[#0E1111] font-spoqa text-[14px] font-bold tracking-[-0.205px] truncate mt-[15px] xl:mt-[23px] xl:text-[20px] xl:px-[8px]">
				{data.title}
			</div>
			<div className="font-normal mt-2 px-[5px] text-[12px] font-spoqa text-[#0e1111] tracking-[-0.205px] line-clamp-2 leading-4 xl:px-[8px] xl:text-[18px] xl:leading-tight">
				{description}
			</div>
		</div>
	);
}

export default CardCrewRecommend;
