import { useEffect, useState } from "react";

interface Props {
	value: string;
	onChange: (value: string) => void;
	className?: string;
	disabled?: boolean;
}

export default function InputBirthday(props: Props) {
	const [data, setData] = useState({
		year: "",
		month: "",
		day: "",
	});

	useEffect(() => {
		const { year, month, day } = data;
		const value = `${year}-${month}-${day}`;
		props.onChange(value);
	}, [data]);

	useEffect(() => {
		try {
			const [year, month, day] = props.value.split("-");

			data.year = year ?? "";
			data.month = month ?? "";
			data.day = day ?? "";
		} catch (e) {}
	}, [props.value]);

	const handleChange = (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
		setData({
			...data,
			[key]: event.target.value,
		});
	};

	return (
		<div className={["flex gap-3 text-[#777777]", props.className].join(" ")}>
			<input
				className="h-[30px]  w-full rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777]"
				type="text"
				maxLength={4}
				value={data.year}
				onChange={(e) => handleChange("year", e)}
				disabled={props.disabled ?? false}
			/>
			/
			<input
				className="h-[30px]  w-full rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777]"
				type="text"
				maxLength={2}
				value={data.month}
				onChange={(e) => handleChange("month", e)}
				disabled={props.disabled ?? false}
			/>
			/
			<input
				className="h-[30px]  w-full rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777]"
				type="text"
				maxLength={2}
				value={data.day}
				onChange={(e) => handleChange("day", e)}
				disabled={props.disabled ?? false}
			/>
		</div>
	);
}
