import React, { useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import { EditorProvider, FloatingMenu, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Tiptap from "./tiptap";
import { FILE } from "../../api";
const extensions = [StarterKit];

export interface FormFactorySchema {
	key: string;
	label: string;
	method: "text" | "password" | "select" | "email" | "radio" | "select-multi" | "textarea" | "wizwig" | "file" | "date";
	selectOptions?: { value: string | number | boolean; label: string }[];
	validation?: ((value: any) => boolean) | "required";
	defaultValue?: any;
	disabled?: boolean;
	placeholder?: string;
}

interface Props {
	schema: FormFactorySchema[];
	onSubmit: (formData: any) => void;
}

const FormFactory = ({ schema, onSubmit }: Props) => {
	const [formData, setFormData] = useState<Record<string, any>>({});
	const [errors, setErrors] = useState<Record<string, any>>({});

	const handleInputChange = (key: string, value: any) => {
		const newFormData = { ...formData, [key]: value };
		setFormData(newFormData);
	};

	useEffect(() => {}, [formData]);

	const handleFileChange = (key: string, e: any) => {
		if (!e?.target?.files && e.target.files.length > 0) {
			return;
		}

		const [file] = e.target.files;

		if (!file) {
			return;
		}

		FILE.upload(file).then((res) => {
			const newFormData = { ...formData, [key]: res };
			setFormData(newFormData);
		});
	};

	useEffect(() => {
		const newFormData: Record<string, any> = {};
		for (const field of schema) {
			newFormData[field.key] = field.defaultValue;
		}
		setFormData(newFormData);
	}, []);

	function checkValidation() {
		const _errors: Record<string, string> = {};
		for (const field of schema) {
			if (field.validation === "required") {
				if (!formData[field.key]) {
					_errors[field.key] = `${field.label}은(는) 필수 입력 사항입니다.`;
				}
			} else {
				if (field.validation && !field.validation(formData[field.key])) {
					_errors[field.key] = `${field.label}이 올바르지 않습니다.`;
				}
			}
		}
		setErrors(_errors);
		if (Object.keys(_errors).length === 0) {
			return true;
		}
		return false;
	}

	const submit = (e: any) => {
		e.preventDefault();

		if (checkValidation()) {
			onSubmit(formData);
		}
	};

	const renderFormField = (field: FormFactorySchema) => {
		switch (field.method) {
			case "text":
			case "email":
			case "date":
			case "password":
				return (
					<div className="flex px-1 py-0.5">
						<div className="flex justify-left items-center w-[80px] mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}</div>
						<input
							className={["shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light", field.disabled ? " bg-gray-500 text-gray-400" : ""].join(" ")}
							type={field.method}
							value={formData[field.key] || ""}
							disabled={field.disabled}
							placeholder={field.placeholder}
							onChange={(e) => handleInputChange(field.key, e.target.value)}
						/>
					</div>
				);

			case "select":
				return (
					<div className="flex px-1 py-0.5">
						<div className="flex justify-left items-center w-[80px] mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}</div>
						<ReactSelect
							className="w-full bg-transparent py-2 text-sm"
							options={field.selectOptions}
							value={formData[field.key] || ""}
							isDisabled={field.disabled}
							onChange={(e) => handleInputChange(field.key, e)}
						/>
					</div>
				);
			case "select-multi":
				return (
					<div className="flex px-1 py-0.5">
						<div className="flex justify-left items-center w-[80px] mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}</div>
						<ReactSelect
							isMulti={true}
							className="w-full bg-transparent py-2 text-sm"
							options={field.selectOptions}
							value={formData[field.key] || ""}
							isDisabled={field.disabled}
							onChange={(e) => handleInputChange(field.key, e)}
						/>
					</div>
				);
			case "textarea":
				return (
					<div className="flex px-1 py-0.5 ">
						<div className="flex justify-left items-center w-[80px] mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}</div>
						<textarea
							className="w-full bg-transparent py-2 h-40 text-sm"
							value={formData[field.key] || ""}
							disabled={field.disabled}
							onChange={(e) => handleInputChange(field.key, e.target.value)}
						/>
					</div>
				);

			case "wizwig":
				return (
					<div className="flex px-1 py-0.5">
						<div className="flex justify-left items-center w-[80px] mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}</div>
						<Tiptap content={field.defaultValue} onChange={(e: any) => handleInputChange(field.key, e)} />
					</div>
				);

			case "file":
				return (
					<label className="flex px-1 py-0.5">
						<div className="flex justify-left items-center w-[80px] mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}</div>
						<input type="file" className="hidden" onChange={(e) => handleFileChange(field.key, e)} />
						<div className={["flex-1 underline decoration-solid ", formData[field.key] ? "text-blue-500" : "text-gray-700"].join(" ")}>
							{typeof formData[field.key] === "string" ? formData[field.key] : ""}
							{formData[field.key] ? formData[field.key].name : "클릭하여 파일 업로드"}
						</div>
					</label>
				);

			default:
				return null;
		}
		// method: "text" | "password" | "select" | "email" | "radio" | "checkbox" | "select-multi" | "textarea";
	};

	return (
		<form className="space-y-4" onSubmit={submit}>
			{schema.map((field) => (
				<div>
					<div key={field.key}>{renderFormField(field)}</div>
					{errors[field.key] && <div className="text-red-500 text-xs mt-1">{errors[field.key]}</div>}
				</div>
			))}
			<div className="flex justify-end">
				<button className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-md hover:bg-indigo-600 focus:outline-none" type="submit">
					완 료
				</button>
			</div>
		</form>
	);
};

export default FormFactory;
