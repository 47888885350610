import React, { useEffect, useMemo, useState } from "react";
// import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "./ourDestination.scss";
import OurDestinationCard from "./ourDestinationCard";
import searchIcon from "../image/search.svg";

// 카드 이미지 데이터, 테스트용
import ourdest1 from "../image/exampleImage/ourdest1.png";
import ourdest2 from "../image/exampleImage/ourdest2.png";
import ourdest3 from "../image/exampleImage/ourdest3.png";
import ourdest4 from "../image/exampleImage/ourdest4.png";
import ourdest5 from "../image/exampleImage/ourdest5.png";
import ourdest6 from "../image/exampleImage/ourdest6.png";
import { DEFAULT, RECOMMENDATION_TRIP } from "../api";
import Pagination from "../components/pagination";

const OurDestination = () => {
	// 카테고리 데이터

	const [cardData, setCardData] = useState([]);
	const [count, setCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const [searchText, setSearchText] = useState("");

	const [img, setImg]: any = useState({});
	const categories = useMemo(() => {
		const _categories = ["전체"];
		cardData.forEach((data: any) => {
			if (!_categories.includes(data.category1)) {
				_categories.push(data.category1);
			}
			if (!_categories.includes(data.category2)) {
				_categories.push(data.category2);
			}
		});
		return _categories;
	}, [cardData]);

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setImg(res);
		});
	}, []);

	useEffect(() => {
		RECOMMENDATION_TRIP.getMany(currentPage, 100).then((data) => {
			setCount(data.total);
			setCardData(data.nodes);
		});
	}, []);

	const [selectedCategory, setSelectedCategory] = useState(categories[0]);

	const filterd = useMemo(() => {
		if (search.length > 0) {
			return cardData
				.filter((data: any) => {
					return data?.category1?.includes(search) || data?.category2?.includes(search);
				})
				.slice(currentPage * 8 - 8, currentPage * 8);
		}

		if (selectedCategory === "전체") {
			return cardData.slice(currentPage * 8 - 8, currentPage * 8);
		}

		return cardData
			.filter((data: any) => data.category1 === selectedCategory || data.category2 === selectedCategory)
			.slice(currentPage * 8 - 8, currentPage * 8);
	}, [selectedCategory, cardData, currentPage, search]);

	const handleClick = (category: string) => {
		setSelectedCategory(category);
	};

	return (
		<div className="flex justify-center">
			<div className="flex flex-col items-center w-full max-w-[1200px]">
				<div className="relative h-56 w-full overflow-hidden">
					<div
						className="w-full h-full bg-center bg-cover blur-sm scale-125"
						style={{
							backgroundImage: `url(${img?.ourDestinationImageUrl})`,
						}}></div>
					<div
						dangerouslySetInnerHTML={{ __html: img?.ourDestinationText }}
						className="absolute inset-0 w-full h-full flex justify-center items-center flex-col"></div>
				</div>

				<ul className="categories container">
					{categories.map((category, index) => (
						<li
							className={`text-xs font-medium h-12 flex-none w-20 flex justify-center items-center whitespace-nowrap ${
								category === selectedCategory ? "border-b border-success text-success" : ""
							}`}
							key={index}
							onClick={() => handleClick(category)}>
							{category}
						</li>
					))}
				</ul>
				<div className="container px-4 mb-4">
					<div className="flex justify-between items-center">
						<div id="recommendedScheduleWrapper" className="text-[14px] col-6 flex-[1_0_0%] flex">
							<div className="text-black text-sm font-medium">추천 일정</div>
							<div id="recommendedScheduleCount" className="text-[#0057FF]">
								({cardData.length})
							</div>
						</div>
						<div className="">
							<div className="border px-2.5 py-2 flex rounded-sm border-[#dadada]">
								<input
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									className="text-xs text-[#999] outline-none"
									type="search"
									placeholder="검색..."
								/>
								<img className="w-4 h-4 search-icon" alt="Search" src={searchIcon} />
							</div>
						</div>
					</div>
				</div>

				{/* 본문 영역의 시작 */}
				<div className="w-full flex justify-center">
					<div className="xl:max-w-[1200px] max-w-[512px] w-full grid grid-cols-2 xl:grid-cols-4 gap-[15px] px-[12px]">
						{filterd.map((data: any, index: number) => (
							<OurDestinationCard key={index} data={data} categories={categories} />
						))}
					</div>
				</div>
				{/* 본문 영역의 끝 */}

				<div className="flex justify-center">
					<Pagination className="mt-8" page={currentPage} perPage={8} count={count} onChange={(v) => setCurrentPage(v)} />
				</div>
			</div>
		</div>
	);
};

export default OurDestination;
