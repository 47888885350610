import { useEffect, useMemo, useState } from "react";
import IconUpload from "../icon/upload";
import InputImage from "../input/image";
import Modal from "../modal";
import { FILE, PARTNER } from "../../api";

interface Props {
	onClose: () => void;
}

export default function MyPagePartnerAccount({ onClose }: Props) {
	const [imageId, setImageId] = useState<number | null>(null);
	const [partner, setPartner] = useState<any>(null);

	useEffect(() => {
		PARTNER.getMe()
			.then((data: any) => {
				setPartner(data);
			})
			.catch((error) => {});
	}, []);

	const image = useMemo(() => {
		if (partner) {
			return partner.PartnerFiles?.find((link: any) => link.type === "BANK_ACCOUNT")?.File?.url;
		}
	}, [partner]);
	const width = window.innerWidth;

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const reader = new FileReader();
		const file = e.target.files ? e.target.files[0] : null;

		// 파일을 읽고 미리보기 URL을 설정합니다.
		if (file) {
			FILE.upload(file).then((res) => {
				PARTNER.changeAccount(res.id).then(() => {
					alert("업로드 완료");
					onClose();
				});
			});
		}
	};

	return (
		<Modal onClose={onClose} open={true} size={width > 600 ? "lg" : "md"}>
			<div className="flex gap-1">
				<IconUpload />
				통장사본/은행코드
			</div>
			<div>현재 등록된 통장사본</div>
			{image ? (
				<div
					onClick={() => {
						window.open(image, "_blank");
					}}
					className="h-[200px] my-4 w-full bg-cover bg-center relative"
					style={{
						backgroundImage: `url(${image})`,
					}}>
					<div className="absolute right-4 bottom-4 rounded-full w-6 h-6 bg-white">{IconDownload}</div>
				</div>
			) : (
				<div>등록된 이미지가 없습니다</div>
			)}

			<div className=" mt-1 text-sm font-bold tracking-[-2.22px] text-black">최초 등록 이후 변경할 경우, 관리자 승인 시 최종 수정반영됩니다</div>
			<div className="mt-1 text-xs font-medium text-[#777]">이미지 JPG, JPEG, PNG, PDF 업로드 가능</div>
			<label
				// onClick={async () => {
				// 	if (imageId === null) {
				// 		return alert("이미지를 업로드해주세요.");
				// 	}

				// 	try {
				// 		await PARTNER.changeBusinessRegistration(imageId);
				// 		alert("업로드 완료!");

				// 		onClose();
				// 	} catch (error) {
				// 		alert("error");
				// 		onClose();
				// 	}
				// }}
				className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
				<input type="file" className="hidden" onChange={handleImageChange} />
				업로드 하기
			</label>
			<div onClick={onClose} className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
				취소
			</div>
		</Modal>
	);
}

const IconDownload = (
	<svg
		className="h-6 w-6"
		clip-rule="evenodd"
		fill-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="m6.864 3.424c.502-.301 1.136.063 1.136.642 0 .264-.138.509-.365.644-2.476 1.486-4.135 4.197-4.135 7.292 0 4.691 3.808 8.498 8.498 8.498s8.497-3.807 8.497-8.498c0-3.093-1.656-5.803-4.131-7.289-.225-.136-.364-.38-.364-.644 0-.582.635-.943 1.137-.642 2.91 1.748 4.858 4.936 4.858 8.575 0 5.519-4.479 9.998-9.997 9.998s-9.998-4.479-9.998-9.998c0-3.641 1.951-6.83 4.864-8.578zm.831 8.582s2.025 2.021 3.779 3.774c.147.147.339.22.53.22.192 0 .384-.073.531-.22 1.753-1.752 3.779-3.775 3.779-3.775.145-.145.217-.336.217-.526 0-.192-.074-.384-.221-.531-.292-.293-.766-.294-1.056-.004l-2.5 2.499v-10.693c0-.414-.336-.75-.75-.75s-.75.336-.75.75v10.693l-2.498-2.498c-.289-.289-.762-.286-1.054.006-.147.147-.221.339-.222.531 0 .19.071.38.215.524z"
			fill-rule="nonzero"
		/>
	</svg>
);
