import React, { useEffect, useState } from "react";

interface SelectProps {
	value: string;
	onChange: (value: string) => void;
	options: Array<{ value: string; label: string }>;
	className?: string;
}

export default function InputSelect({ value, onChange, options, className }: SelectProps) {
	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange(event.target.value);
	};

	return (
		<div className={`flex gap-3 ${className}`}>
			<select
				className="py-[6px] px-[7px] w-full h-[32px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] bg-white appearance-none cursor-pointer"
				defaultValue={value}
				value={value}
				onChange={handleChange}>
				<option value="" selected disabled hidden>
					선택해주세요
				</option>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
}

interface MultipleSelectsProps {
	options: Array<{ value: string; label: string }>;
	className?: string;
}

export function InputMultipleSelects({ options, className, onChange = () => {}, defaultValue }: any) {
	const [selects, setSelects] = useState<Array<{ id: number; value: string }>>([
		{ id: 0, value: "" }, // Start with one empty select
	]);

	useEffect(() => {
		const data = Object.values(selects)
			.map((e) => e.value)
			.join(", ");
		onChange(data);
	}, [selects]);

	const addSelect = () => {
		setSelects([...selects, { id: selects.length, value: options[0].value }]);
	};

	const removeSelect = (index: number) => {
		setSelects(selects.filter((select, _index) => index !== _index));
	};

	const updateSelect = (id: number, value: string) => {
		setSelects(selects.map((select) => (select.id === id ? { ...select, value } : select)));
	};

	return (
		<div className={["flex flex-col", className].join(" ")}>
			{selects.map((select, index) => (
				<div className="flex items-center gap-3 ">
					<InputSelect
						key={`${select.id}_${index}`}
						value={select.value || defaultValue}
						onChange={(value) => {
							updateSelect(select.id, value);
						}}
						options={options}
						className={["flex-1", index > 0 ? "mt-2" : undefined].join(" ")} // Add margin-top if it's not the first select
					/>
					{index === selects.length - 1 ? (
						<button
							onClick={addSelect}
							className="w-[30px] flex justify-center items-center flex-none py-[6px] px-[7px] border border-[#DADADA] bg-white text-[#0e1111] text-[14px] rounded-[2px]">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.00004 2.66602C8.36823 2.66602 8.66671 2.96449 8.66671 3.33268V12.666C8.66671 13.0342 8.36823 13.3327 8.00004 13.3327C7.63185 13.3327 7.33337 13.0342 7.33337 12.666V3.33268C7.33337 2.96449 7.63185 2.66602 8.00004 2.66602Z"
									fill="#999999"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.66663 8.00065C2.66663 7.63246 2.9651 7.33398 3.33329 7.33398H12.6666C13.0348 7.33398 13.3333 7.63246 13.3333 8.00065C13.3333 8.36884 13.0348 8.66732 12.6666 8.66732H3.33329C2.9651 8.66732 2.66663 8.36884 2.66663 8.00065Z"
									fill="#999999"
								/>
							</svg>
						</button>
					) : (
						<button className="border border-[#dadada] text-red-500 w-[30px] h-full flex justify-center flex-none" onClick={() => removeSelect(index)}>
							X
						</button>
					)}
				</div>
			))}
		</div>
	);
}
