import { useEffect, useMemo, useState } from "react";
import { ORDER, PARTNER_QUOTATION } from "../../../api";
import IconEarth from "../../../components/icon/earth";
import InputList from "../../../components/input/list";
import { add, format, parse } from "date-fns";
import PartnerMyPagePartnerCard from "../../../components/mypage/partner/card";
import Pagination from "../../../components/pagination";
import PartnerMyPagePartnerQuotationCard from "../../../components/mypage/partner/card-quotation";
import PartnerMyPagePartnerQuotationCompleteCard from "../../../components/mypage/partner/card-complete";
import PartnerMyPagePartnerQuotationReservationCard from "../../../components/mypage/partner/card-reservation";

const IconMarker = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M11.9984 9.5999V17.5999M8.39844 13.8907C4.88024 14.484 2.39844 15.9212 2.39844 17.5999C2.39844 19.809 6.6965 21.5999 11.9984 21.5999C17.3004 21.5999 21.5984 19.809 21.5984 17.5999C21.5984 15.9212 19.1166 14.484 15.5984 13.8907M15.1984 5.5999C15.1984 7.36721 13.7657 8.7999 11.9984 8.7999C10.2311 8.7999 8.79844 7.36721 8.79844 5.5999C8.79844 3.83259 10.2311 2.3999 11.9984 2.3999C13.7657 2.3999 15.1984 3.83259 15.1984 5.5999Z"
			stroke="#0057FF"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export default function PartnerMyCompletePage() {
	const [data, setData] = useState<any[]>([]);
	const [count, setCount] = useState(0);

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [selectedFilter, setSelectedFilter] = useState<boolean | undefined>(undefined);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	useEffect(() => {
		const status = undefined;
		const sDate = startDate ? startDate : undefined;
		const eDate = endDate ? endDate : undefined;

		PARTNER_QUOTATION.getOwnedReservation().then((data) => {
			setData(data);
			setCount(data.length);
		});
	}, [page, perPage, selectedFilter, startDate, endDate]);

	const dateKeys = useMemo(() => {
		const set = new Set();
		for (const item of data) {
			set.add(format(new Date(item.createdAt), "yyyy.MM.dd"));
		}
		// @ts-ignore
		return [...set.values()];
	}, [data]);

	const getList = (date: string) => {
		return data.filter((item) => {
			return format(new Date(item.createdAt), "yyyy.MM.dd") === date;
		});
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="text-success flex px-3 gap-1 mt-5 font-medium">{IconMarker} 예약 현황보기</div>
				<div className="px-3 mt-6">
					<div className="h-[1px] w-full bg-[#dadada]"></div>
				</div>

				<div className="min-h-[350px]">
					{dateKeys.map((v) => {
						return (
							<div className="text-sm px-3 font-medium text-black mt-7">
								{v}
								<div>
									{getList(v).map((quotation) => (
										<div className="mt-2.5">
											<PartnerMyPagePartnerQuotationReservationCard quotation={quotation} />
										</div>
									))}
								</div>
							</div>
						);
					})}
					{dateKeys.length === 0 && <div className="text-sm px-3 font-medium text-black mt-7">예약 현황이 없습니다.</div>}
				</div>
			</div>
		</div>
	);
}
