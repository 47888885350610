import { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { isBrowser } from 'react-device-detect';
import "./myp_myOrderDetail.scss";
import orderListIcon from "../image/book.svg";
import noCoverImage from "../image/noimage.svg";
import arrow from "../image/arrow-right-line.svg";
import danger from "../image/danger.svg";
import check from "../image/check-rect.svg";
import kakao from "../image/kakao-pay.svg";
import radio from "../image/radio.svg";
import plus from "../image/plus.svg";
import crown from "../image/crown.svg";

import samplePassport from "../image/noimage.svg";
import sampleVisa from "../image/noimage.svg";
import sampleTicket from "../image/noimage.svg";

import { UserOrder } from "../types";
import { calculateStayDays, formatDateISOStringToDate, formatGender, formatTripType, getLowestQuotationPrice } from "../components/CommonFunction";
import { COMMUNITY_CLUP, FILE, ORDER, PARTNER_QUOTATION } from "../api";
import GoogleMap from "../components/GoogleMap";
import InputImage from "../components/input/image";
import SvgIcon from "../components/SvgIcon";
import { Document as PDFDocument, Page as PDFPage } from "react-pdf";
import Modal from "../components/modal";

const InputCommentBox = ({ tag, value, onChange }: any) => {
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onChange(event.target.value);
	};

	return (
		<div className="w-full mt-[-20px] mb-[30px]">
			<div className="flex gap-[5px] text-[16px] font-bold text-[#0057FF]">
				<div>
					<SvgIcon className="w-[24px] aspect-square bg-[#0057FF]" icon={arrow} />
				</div>
				<div>{tag}</div>
			</div>

			<textarea
				value={value}
				onChange={handleChange}
				className="w-full felx itme h-[120px] mt-[5px] bg-white border-2 border-[#0057FF] resize-none rounded-[2px]"></textarea>
		</div>
	);
};

export default function MypageMyOrderDetail() {
	// URL 매개변수 받아오기
	const { order_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [item, setItem]: any = useState("");
	const [quotations, setQuotations]: any = useState("");
	const [passport, setPassport]: any = useState("");
	const [visa, setVisa]: any = useState("");
	const [ticket, setTicket]: any = useState("");
	const [onModal, setOnModal]: any = useState(false);
	const [payment, setPayment]: any = useState("KAKAOPAY");
	const [passData, setPassData]: any = useState("");
	const [orderChat, setOrderChat]: any = useState([]);
	const [participants, setParticipants]: any = useState({});
	const [leader, setLeader]: any = useState([]);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [userNum, setUserNum]: any = useState("");
	const [userFiles, setUserFiles]: any = useState({});

	const [orderChatData, setOrderChatData]: any = useState({
		accommodation: "",
		transportation: "",
		guide: "",
		travelRequest: "",
	});

	useEffect(() => {
		let quotationsId = searchParams.get("quotations");

		ORDER.getMyOrderById(order_id).then(async (data: any) => {
			setItem(data);

			setParticipants(
				data.OrderParticipant.map((e: any) => {
					return {
						name: e.name,
						phone: e.phone,
						passportFileId: e.passportFileId,
						visaFileId: e.visaFileId,
						airTicketFileId: e.airTicketFileId,
						isLeader: e.isLeader,
					};
				})
			);

			if (!quotationsId) quotationsId = data.PartnerQuotations?.find((e: any) => e.selectedAt)?.id;
			const quotationsById = data.PartnerQuotations.find((e: any) => e.id === Number(quotationsId));
			setQuotations(quotationsById);
			if (quotationsById?.deletedAt) {
				alert("삭제된 견적서입니다.");
				window.history.back();
			}

			if (quotationsById) {
				await PARTNER_QUOTATION.getQuotationsById(quotationsById.id).then((res) => {
					setOrderChat(res.PartnerQuotationChats);
				});
			}
		});
	}, [searchParams]);

	useEffect(() => {
		if (item.status === "QUOTATION_COMPLETE") {
			const quotations_id = searchParams.get("quotations");
			if (!quotations_id) {
				const id = item.PartnerQuotations.find((e: any) => e.id).id;
				if (!id) return;
				setSearchParams(`?quotations=${id}`);
			}
		}
	}, [item]);

	const confirmOrder = () => {
		// if (item.type === "RECRUITMENT" && !passport.id && !visa.id && !ticket.id) return alert("여권, 항공권, 비자중 하나 이상 입력해주세요.");
		// if (item.type !== "RECRUITMENT" && Object.values(participants).find((e: any) => !e.passportFileId && !e.visaFileId && !e.airTicketFileId)) {
		// 	return alert("인원정보에 여권, 항공권, 비자중 하나 이상 입력해 주세요.");
		// }

		const data = {
			participantsForBusiness: Object.values(participants),
			passportImageId: passport.id,
			visaImageId: visa.id,
			airTicketImageId: ticket.id,
		};

		try {
			ORDER.confirmOrder(order_id, quotations.id, data)
				.then((res) => {
					window.location.href = "/mypage/order/payment";
				})
				.catch((e) => {
					alert("주문서 확정에 오류가 발생하였습니다.");
				});
		} catch (e) {
			alert("주문서 확정에 오류가 발생하였습니다.");
			window.history.back();
		}
	};

	useMemo(() => {
		setUserFiles({
			visa: item.OrderFiles?.find((e: any) => e.type === "VISA")?.fileId || null,
			passport: item.OrderFiles?.find((e: any) => e.type === "PASSPORT")?.fileId || null,
			airTicket: item.OrderFiles?.find((e: any) => e.type === "AIR_TICKET")?.fileId || null,
		});

		console.log(userFiles);
	}, [item]);

	const addParticipants = (i: any, value: any, type: any, isLeader: boolean) => {
		setParticipants({
			...participants,
			[i]: { ...participants[i], [type]: value, isLeader: isLeader },
		});
	};

	const addParticipantsFile = (i: any, value: any, type: any) => {
		setParticipants({
			...participants,
			[i]: { ...participants[i], [type]: value },
		});
	};

	const sendOrderCaht = () => {
		ORDER.editedParticipants(order_id, {
			participantsForBusiness: Object.values(participants),
			passportImageId: passport.id,
			visaImageId: visa.id,
			airTicketImageId: ticket.id,
		})
			.then((res) => {})
			.catch((err) => {});

		try {
			PARTNER_QUOTATION.sendQuotationChat(quotations.id, {
				accommodation: orderChatData.accommodation,
				transportation: orderChatData.transportation,
				guide: orderChatData.guide,
				travelRequest: orderChatData.travelRequest,
				initialQuotePrice: `${quotations.initialQuotePrice}`,
			}).then((res) => {
				alert("견적요청사항이 수정되었습니다.");
				window.location.href = `/mypage/myorder/${order_id}?quotations=${quotations.id}`;
			});
		} catch {
			alert("견적서 수정에 실패하였습니다.");
		}
	};

	const handleInput = (key: string, value: string | number | boolean) => {
		setOrderChatData({
			...orderChatData,
			[key]: value,
		});
	};

	const isActivate = () => {
		if (item?.type === "RECRUITMENT") return true;

		if (Object.entries(participants).length === 0) {
			return false;
		}

		if (!readyToSubmit) {
			return false;
		}
		return true;
	};

	const travelRequestImage = useMemo(() => {
		if (!quotations) {
			return "";
		}
		const travelRequest = quotations?.PartnerQuotationFiles?.find((v: any) => v.type === "TRAVLE_REQUEST");
		return travelRequest?.File;
	}, [quotations]);

	const fileUpload = (e: any, type: any) => {
		const reader = new FileReader();
		const file = e.target.files ? e.target.files[0] : null;

		if (file) {
			reader.onloadend = () => {};
			reader.readAsDataURL(file);
			FILE.upload(file).then((res) => {
				addParticipantsFile(userNum, res.id, type);
			});
		}
	};

	const oneFileUpload = (e: any, setValue: Function) => {
		const reader = new FileReader();
		const file = e.target.files ? e.target.files[0] : null;

		if (file) {
			reader.onloadend = () => {};
			reader.readAsDataURL(file);
			FILE.upload(file).then((res) => {
				setValue(res);
			});
		}
	};

	const onFile = (id: any) => {
		FILE.getFile(id).then((res) => {
			window.open(res.url, "_self ");
		});
	};

	const paymentOrder = () => {
		const data = { paymentMethod: payment };

		ORDER.paymentOrder(order_id, data).then((res) => {
			if (payment === "KAKAOPAY") {
				console.log(`isBrowser (${isBrowser}), PC - [${res.redirectInfo.paymentUrlPc}],  Mobile - [${res.redirectInfo.paymentUrlMobile}]`);
				if (isBrowser) {
					window.open(res.redirectInfo.paymentUrlPc, "_self");
				} else {
					window.open(res.redirectInfo.paymentUrlMobile, "_self");
				}
			} else {
				alert(`${res.redirectInfo.bankAccount} 로 입금해 주세요.`);

				window.location.href = `/payment-complete?orderId=${order_id}&pg_token=__BANK`;
			}
		});
	};

	const Tag = ({ tag, value, essential, className }: any) => {
		return (
			<div className={`flex w-full mb-[30px] ${className}`}>
				<div className="w-[30%] text-[16px] font-medium relative">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="text-[16px] text-[#777777] flex-1 break-all">{value || ""}</div>
			</div>
		);
	};

	const InputTag = ({ tag, value, essential, className }: any) => {
		return (
			<div className={`w-full mb-[30px] ${className}`}>
				<div className="w-[30%] text-[16px] font-medium relative">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="w-full min-h-[40px] mt-[10px] border bg-[#ECECEC] border-[#999999] text-[#999999] font-medium px-[8px]">{value} </div>
			</div>
		);
	};

	const SeleteInputTag = ({ tag, value, textValue, list, essential, className }: any) => {
		return (
			<div className={`w-full mb-[30px] ${className}`}>
				<div className="w-[30%] text-[16px] font-medium relative">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="flex gap-[10px] mt-[10px]">
					{list?.map((e: any, i: any) => (
						<div
							key={i}
							className={`border text-[14px] font-normal w-[85px] h-[33px] flex justify-center items-center ${
								e.value === value ? "border-[#0057FF] text-[#0057FF]" : "text-[#999999] "
							}`}>
							{e.name}
						</div>
					))}
				</div>

				<textarea
					disabled
					className="w-full mt-4 min-h-[100px] resize-none flex items-center border bg-[#ECECEC] border-[#999999] text-[#999999] font-medium px-[8px]"
					defaultValue={textValue}></textarea>
			</div>
		);
	};

	const TextInput = ({ tag, value, desc, essential, className }: any) => {
		const textareaToHTML = (text: string) => {
			return text?.replace(/\n/g, "<br />");
		};

		return (
			<div className={`w-full mb-[30px] ${className}`}>
				<div className="w-full text-[16px] font-medium relative mb-[5px]">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="w-full text-[12px] font-medium mb-[10px]">{desc}</div>
				<div className="flex items-center mt-[10px]">
					<div
						dangerouslySetInnerHTML={{ __html: textareaToHTML(value) }}
						className="w-full h-[130px] overflow-y-scroll border bg-[#ECECEC] border-[#999999] text-[#999999] p-[10px] resize-none font-medium"></div>
				</div>
			</div>
		);
	};

	const CommentBox = ({ tag, value, type }: any) => {
		return (
			<div className="w-full mt-[-20px] mb-[30px]">
				<div className={`flex gap-[5px] text-[16px] font-bold  ${type === "user" ? "text-[#347A43]" : "text-[#355C7D]"}`}>
					<div>
						<SvgIcon className={`w-[24px] aspect-square ${type === "user" ? "bg-[#347A43]" : "bg-[#355C7D]"}`} icon={arrow} />
					</div>
					<div>{type === "user" ? "주문서 수정사항" : tag}</div>
				</div>

				<textarea
					disabled
					defaultValue={value}
					className={`w-full min-h-[100px] leading-8 mt-[5px] break-words bg-[#ECECEC] border-2 ${
						type === "user" ? "border-[#347A43]" : "border-[#DADADA]"
					} resize-none rounded-[2px]`}></textarea>
			</div>
		);
	};

	const FileInputTag = ({ tag, value, essential }: any) => {
		const type = () => {
			switch (passData) {
				case "여권":
					return "passportFileId";
				case "비자":
					return "visaFileId";
				case "항공권":
					return "airTicketFileId";
				default:
					return "passportFileId";
			}
		};

		return (
			<div className="">
				<div className="text-[16px] mb-[10px]">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="flex border w-fll h-[35px] border-[#DADADA]">
					<label
						className="w-[77px] text-[12px] font-medium border-[#DADADA] border-r flex justify-center items-center bg-[#ECECEC]"
						htmlFor={`input_${value}`}>
						파일 선택
					</label>
					<div className="text-[12px] text-[#999999] flex justify-center items-center px-[10px] font-medium ">
						{participants?.[userNum]?.[type()] || "선택된 파일이 없습니다."}
					</div>
				</div>
				<div className="text-[12px] text-[#999999] font-medium mt-[7px]">JPG, JPEG, PNG, PDF, ZIP 업로드 가능</div>
				<input
					id={`input_${value}`}
					onChange={(e) => {
						fileUpload(e, type());
					}}
					placeholder={`${participants?.[userNum]?.[type()]}` || "선택된 파일이 없습니다."}
					className="hidden"
					type="file"
				/>
			</div>
		);
	};

	const OneFileInputTag = ({ tag, type, value, essential, setValue }: any) => {
		return (
			<div className="">
				<div className="text-[16px] mb-[10px]">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="flex border w-fll h-[35px] border-[#DADADA]">
					<label className="w-[77px] text-[12px] font-medium border-[#DADADA] border-r flex justify-center items-center bg-[#ECECEC]" htmlFor={`input_${type}`}>
						파일 선택
					</label>
					<div className="text-[12px] text-[#999999] flex justify-center items-center px-[10px] font-medium ">{value || "선택된 파일이 없습니다."}</div>
				</div>
				<div className="text-[12px] text-[#999999] font-medium mt-[7px]">JPG, JPEG, PNG, PDF, ZIP 업로드 가능</div>
				<input
					id={`input_${type}`}
					onChange={(e) => {
						oneFileUpload(e, setValue);
					}}
					className="hidden"
					type="file"
				/>
			</div>
		);
	};

	const ConfirmModal = () => {
		return (
			<>
				<div className="flex flex-col items-center">
					<img className="w-[40px] aspect-square mb-[20px] " src={check} />
					<div className="text-[20px] font-bold text-[#0057FF] mb-[20px]">견적서 확정 완료</div>
					<div className="text-[14px] font-bold mb-[10px]">선택하신 견적서가 확정 되었습니다.</div>
					<div className="text-[14px] font-medium">이후 현지 파트너까지 확정 후 현지 수배가 이루어지며</div>
					<div className="text-[14px] font-medium">현지 파트너의 확정은 알림을 통해 공유해 드리겠습니다.</div>
				</div>

				<hr className="my-[30px] w-full border-[#DADADA]" />

				<div className="flex flex-col items-center">
					<img className="w-[40px] aspect-square mb-[20px] " src={danger} />
					<div className="text-[20px] font-bold text-[#FF0000] mb-[20px]">주의</div>
					<div className="text-[14px] font-bold mb-[10px]">견적서 확정 이후부터 취소료 규정이 적용됩니다.</div>
					<div className="text-[14px] font-medium">예약 하신 상품의 변경/취소가 있을경우,</div>
					<div className="text-[14px] font-medium">취소료 규정을 확인 후 접수 부탁드립니다.</div>
				</div>

				<div
					className="bg-[#0057FF] w-full h-[30px] flex justify-center items-center text-white mt-[30px] cursor-pointer"
					onClick={() => {
						confirmOrder();
					}}>
					확인
				</div>
				<div
					className="bg-[#F6F7F9] border border-[#DADADA] w-full h-[30px] flex justify-center items-center text-[#424549] mt-[10px] cursor-pointer"
					onClick={() => setOnModal(false)}>
					취소
				</div>
			</>
		);
	};

	const Paymant = () => {
		return (
			<>
				<div className="my-[30px] border-t border-b border-[#DADADA] py-[30px]">
					<div className="mb-[20px] text-[16px] font-bold">결제 방법</div>
					<div className="flex gap-[30px] ">
						<div className="flex gap-[10px] items-center">
							{payment === "KAKAOPAY" ? (
								<img src={radio}></img>
							) : (
								<div
									className="rounded-full border w-[24px] aspect-square border-[#999999] bg-[#ECECEC] cursor-pointer"
									onClick={() => {
										setPayment("KAKAOPAY");
									}}></div>
							)}
							<img src={kakao} />
						</div>
						<div className="flex gap-[10px] items-center">
							{payment === "BANK_TRANSFER" ? (
								<img src={radio}></img>
							) : (
								<div
									className="rounded-full border w-[24px] aspect-square border-[#999999] bg-[#ECECEC] cursor-pointer"
									onClick={() => {
										setPayment("BANK_TRANSFER");
									}}></div>
							)}
							<div className="w-[97px] h-[40px] rounded-[37px] flex justify-center items-center bg-[#202C4A] text-white">계좌이체</div>
						</div>
					</div>
				</div>
				<div
					className="text-[16px] font-bold text-white bg-[#0057FF] rounded-[2px] py-[10px] flex justify-center items-center cursor-pointer"
					onClick={() => {
						paymentOrder();
					}}>
					결제하기
				</div>
			</>
		);
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-[512px]">
				<div className="py-[20px] px-[10px] flex gap-[5px] text-[#0057FF] items-center">
					<SvgIcon icon={orderListIcon} className="bg-[#0057FF] w-[24px] aspect-square"></SvgIcon>
					<div>내 주문서 상세보기</div>
				</div>

				<hr className=" border-[#DADADA] mx-[10px]" />

				<div className="w-full p-[10px] text-[13px] font-medium  my-[20px]">
					<div className="w-full p-[10px] flex gap-[10px] border border-[#DADADA] rounded-[2px]">
						<div
							className="w-[50%] bg-center bg-cover aspect-[16/12] bg-gray-200 flex justify-center items-center"
							style={{
								backgroundImage: `url(${
									item?.PartnerQuotations?.find((e: any) => e.selectedAt)?.PartnerQuotationFiles?.find((e: any) => e.type === "TRAVLE_REQUEST")?.File?.url
								})`,
							}}>
							<div className="text-[#999] text-xs">
								{item?.PartnerQuotations?.find((e: any) => e.selectedAt)?.PartnerQuotationFiles?.find((e: any) => e.type === "TRAVLE_REQUEST")?.File?.url
									? ""
									: "이미지 없음"}
							</div>
						</div>
						<div className="flex flex-col justify-around">
							<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium ">{quotations?.Partner?.name}</div>
							<div>
								여행 형태:
								{item.type === "ALONE" && "단독 주문"}
								{item.type === "RECRUITMENT" && "모집 주문"}
								{item.type === "BUSINESS_TRIP" && "출장 주문"}
							</div>
							<div>국가: {item?.nation}</div>
							<div>도시: {item?.city}</div>
							<div>
								{item?.numberOfRecruits}명 · {item?.recruitGenderTypeText ?? ""} · {item?.ageGroup ?? ""}
							</div>
							<div>{`${new Date(item?.startedAt).toLocaleDateString().slice(0, -1)} - ${new Date(item?.endedAt).toLocaleDateString().slice(0, -1)}`}</div>
						</div>
					</div>
				</div>

				<hr className="border-[#DADADA] mx-[10px]" />

				<div className="px-[10px]">
					<Tag
						tag="여행 형태 선택"
						className="mt-[20px]"
						value={(item.type === "ALONE" && "단독 주문") || (item.type === "RECRUITMENT" && "모집 주문") || (item.type === "BUSINESS_TRIP" && "출장 주문")}
						essential={true}
					/>

					<Tag tag="항공권 포함 여부" value={item?.isIncludeAirticket ? "포함" : "미포함"} essential={true} />
					<Tag tag="이름" value={item?.name} essential={true} />
					<Tag tag="이메일" value={item?.email} essential={true} />
					<Tag tag="국가 선택" value={item?.nation} essential={true} />
					<Tag tag="도시 선택" value={item?.city} essential={true} />
					<GoogleMap className="h-[300px] w-full mb-[30px]" location={item?.nation} />
					<Tag
						tag="여행 기간"
						value={`${new Date(item?.startedAt).toLocaleDateString().slice(0, -1)} - ${new Date(item?.endedAt).toLocaleDateString().slice(0, -1)}`}
						essential={true}
					/>
					<Tag tag="1인 예상 경비" value={item?.expenses} essential={true} />
					{item.type === "RECRUITMENT" && (
						<>
							<Tag tag="모집 유형" value={item?.recruitGenderTypeText} essential={true} />
							<Tag tag="모집 연령" value={item?.ageGroup} essential={true} />
						</>
					)}
					<Tag tag="모집 인원" value={`${item?.numberOfRecruits}명`} essential={true} />
					<InputTag tag="여행 유형" value={item?.tripType} essential={true} />
					<InputTag tag="숙소" value={item?.accommodation} essential={true} />
					{(item.status === "QUOTATION_COMPLETE" || item.status === "CONFIRM") && (
						<>
							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.accommodation || ""} type={d.userId ? "user" : "partner"} />;
							})}
						</>
					)}
					{item.status === "QUOTATION_COMPLETE" && (
						<InputCommentBox
							tag="수정사항"
							value={orderChatData.accommodation}
							onChange={(v: any) => {
								handleInput("accommodation", v);
							}}
						/>
					)}

					<InputTag tag="교통" value={item?.transportation} essential={true} />
					{(item.status === "QUOTATION_COMPLETE" || item.status === "CONFIRM") && (
						<>
							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.transportation || ""} type={d.userId ? "user" : "partner"} />;
							})}
						</>
					)}
					{item.status === "QUOTATION_COMPLETE" && (
						<InputCommentBox
							tag="수정사항"
							value={orderChatData.transportation}
							onChange={(v: any) => {
								handleInput("transportation", v);
							}}
						/>
					)}

					<SeleteInputTag
						tag="가이드"
						value={item?.guideAvailability}
						textValue={item?.guideAvailabilityDesc}
						list={[
							{ name: "필요", value: true },
							{ name: "불필요", value: false },
						]}
						essential={true}
					/>
					{(item.status === "QUOTATION_COMPLETE" || item.status === "CONFIRM") && (
						<>
							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.guide || ""} type={d.userId ? "user" : "partner"} />;
							})}
						</>
					)}
					{item.status === "QUOTATION_COMPLETE" && (
						<InputCommentBox
							tag="수정사항"
							value={orderChatData.guide}
							onChange={(v: any) => {
								handleInput("guide", v);
							}}
						/>
					)}

					<SeleteInputTag
						tag="견적 수신방법"
						value={item?.quotationReceivingMethod}
						textValue={item?.quotationReceivingTarget}
						list={[
							{ name: "이메일", value: "EMAIL" },
							{ name: "카카오", value: "KAKAO" },
						]}
						essential={true}
					/>

					<TextInput tag="일차별 일정 또는 여행관련 요청사항" desc="(관광지, 액티비티 문의 등)" value={item.travelRequest} />
					{(item.status === "QUOTATION_COMPLETE" || item.status === "CONFIRM") && (
						<>
							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.travelRequest || ""} type={d.userId ? "user" : "partner"} />;
							})}
						</>
					)}
					{item.status === "QUOTATION_COMPLETE" && (
						<InputCommentBox
							tag="수정사항"
							value={orderChatData.travelRequest}
							onChange={(v: any) => {
								handleInput("travelRequest", v);
							}}
						/>
					)}
					{travelRequestImage &&
						(travelRequestImage.extension === "png" ||
							travelRequestImage.extension === "jpg" ||
							travelRequestImage.extension === "jpeg" ||
							travelRequestImage.extension === "gif") && (
							<div className="h-[200px] bg-center bg-cover mb-4" style={{ backgroundImage: `url(${travelRequestImage?.url})` }}></div>
						)}

					{travelRequestImage && (
						<a href={travelRequestImage?.url} target="_blank" className="flex text-blue-500">
							<div className="pr-12 truncate flex-1">{travelRequestImage?.name}</div>
							<div className="flex-none">다운로드</div>
						</a>
					)}

					{item.type === "BUSINESS_TRIP" && <InputTag tag="기업/단체 명" value={item?.companyName} essential={true} />}

					<InputTag tag="여행 제목" value={item?.title} essential={true} />
				</div>

				<hr />

				{item.type !== "RECRUITMENT" && item.status === "QUOTATION_COMPLETE" && (
					<div className="w-full text-[16px] font-bold relative px-[10px] py-[30px]">
						<div className="flex items-center gap-[5px]">
							<div>인원정보</div>
							<div className="text-[10px] text-[#FF0000]">*</div>
							<div className="text-[#777777] text-[12px] font-medium">지정한 인원수만큼 모든 인원을 작성해주세요</div>
						</div>

						<div className="flex flex-col gap-[10px] mt-[22px]">
							{Array(item.numberOfRecruits)
								.fill("")
								.map((e, i) => {
									return (
										<>
											<div className="flex gap-[8px] h-[30px] relative">
												<input
													className="text-[14px] font-medium px-[10px] border border-[#DADADA] w-[120px] bg-[#F6F7F9] rounded-[2px]"
													defaultValue={participants?.[i]?.name}
													onChange={(e) => {
														addParticipants(i, e.target.value, "name", i === 0 ? true : false);
													}}
													placeholder={i === 0 ? "대표자 이름" : "동행자 이름"}></input>
												<input
													className="text-[14px] font-medium px-[10px] flex-1 bg-[#F6F7F9] rounded-[2px] border border-[#DADADA]"
													defaultValue={participants?.[i]?.phone}
													onChange={(e) => {
														addParticipants(i, e.target.value, "phone", i === 0 ? true : false);
													}}
													placeholder={"휴대폰 번호"}></input>
												<div
													className={`w-[30px] flex justify-center items-center cursor-pointer ${item.numberOfRecruits - 1 === i ? "border border-[#DADADA]" : ""}`}
													onClick={() => {
														setItem({ ...item, numberOfRecruits: item.numberOfRecruits + 1 });
													}}>
													{item.numberOfRecruits - 1 === i && <SvgIcon className="w-[16px] aspect-square bg-[#999999]" icon={plus} />}
												</div>

												{i == 0 && <img className="absolute top-[6px] left-[95px]" src={crown} />}
											</div>

											<div className="flex gap-[16px] w-full">
												<div
													className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
														participants?.[i]?.passportFileId ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
													}`}
													onClick={() => {
														setUserNum(i);
														setPassData("여권");
													}}>
													여권
												</div>
												<div
													className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
														participants?.[i]?.visaFileId ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
													}`}
													onClick={() => {
														setUserNum(i);
														setPassData("비자");
													}}>
													비자
												</div>
												<div
													className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
														participants?.[i]?.airTicketFileId ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
													}`}
													onClick={() => {
														setUserNum(i);
														setPassData("항공권");
													}}>
													항공권
												</div>
											</div>
										</>
									);
								})}
						</div>

						{/* <div className="flex items-center gap-[5px] mt-[30px] flex-wrap">
								<div>여권/비자/항공권 정보</div>
								<div className="text-[10px] text-[#FF0000]">*</div>
								<div className="text-[#777777] text-[12px] font-medium">해당 정보 사본 파일 업로드 / 지원 파일 zip형식</div>
							</div> */}
					</div>
				)}

				{item.type !== "RECRUITMENT" && (item.status === "CONFIRM" || item.status === "PAYMENT_COMPLETE") && (
					<div className="w-full text-[16px] font-bold relative px-[10px] py-[30px]">
						<div className="flex items-center gap-[5px]">
							<div>인원정보</div>
							<div className="text-[10px] text-[#FF0000]">*</div>
							<div className="text-[#777777] text-[12px] font-medium">작성한 인원정보를 확인해주세요</div>
						</div>

						<div className="flex flex-col gap-[10px] mt-[20px]">
							{item?.OrderParticipant?.sort((a: any, b: any) => b.isLeader - a.isLeader).map((e: any, i: any) => {
								return (
									<>
										<div className="flex gap-[8px] h-[30px] relative">
											<div className="text-[14px] flex items-center font-medium px-[10px] border border-[#DADADA] w-[120px] bg-[#F6F7F9] rounded-[2px]">
												{e.name}
											</div>
											<div className="text-[14px] flex items-center font-medium px-[10px] flex-1 bg-[#F6F7F9] rounded-[2px] border border-[#DADADA]">
												{e.phone}
											</div>

											{i == 0 && <img className="absolute top-[6px] left-[95px]" src={crown} />}
										</div>
										<div className="flex gap-[16px] mb-4 w-full">
											<div
												className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
													e?.passportFileId ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
												}`}
												onClick={() => {
													if (e?.passportFileId) return onFile(e.passportFileId);
												}}>
												여권
											</div>
											<div
												className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
													e?.visaFileId ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
												}`}
												onClick={() => {
													if (e?.visaFileId) return onFile(e.visaFileId);
												}}>
												비자
											</div>
											<div
												className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
													e?.airTicketFileId ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
												}`}
												onClick={() => {
													if (e?.airTicketFileId) return onFile(e.airTicketFileId);
												}}>
												항공권
											</div>
										</div>
									</>
								);
							})}
						</div>
					</div>
				)}

				<Modal
					open={passData}
					onClose={() => {
						setPassData(null);
					}}>
					<div>
						<div className="flex gap-[5px] mb-[20px]">
							<div>{passData}</div>
						</div>

						<FileInputTag tag={`${passData} 정보`} value={passData === "여권" ? passport.name : passData === "비자" ? visa.name : ticket.name} />

						<div
							onClick={() => {
								setPassData(null);
							}}
							className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white cursor-pointer">
							확인
						</div>
						<div
							onClick={() => setPassData(null)}
							className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black cursor-pointer">
							취소
						</div>
					</div>
				</Modal>

				{item.status === "QUOTATION_COMPLETE" && item.type === "RECRUITMENT" && (
					<div className="px-[10px] py-[30px] flex flex-col gap-[20px]">
						<OneFileInputTag
							tag="여권 정보"
							type="passport"
							value={passport.name}
							setValue={(e: any) => {
								setPassport(e);
							}}
						/>
						<OneFileInputTag
							tag="비자 정보"
							type="visa"
							value={visa.name}
							setValue={(e: any) => {
								setVisa(e);
							}}
						/>
						<OneFileInputTag
							tag="항공권 정보"
							type="airTicket"
							value={ticket.name}
							setValue={(e: any) => {
								setTicket(e);
							}}
						/>
					</div>
				)}

				{item.type === "RECRUITMENT" && (item.status === "CONFIRM" || item.status === "PAYMENT_COMPLETE") && (
					<div className="w-full text-[16px] font-bold relative px-[10px] py-[30px]">
						<div className="flex items-center gap-[5px] mb-4">
							<div>인원정보</div>
						</div>
						<div className="flex gap-[16px] mb-4 w-full">
							<div
								className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
									userFiles?.passport ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
								}`}
								onClick={() => {
									if (userFiles?.passport) return onFile(userFiles?.passport);
								}}>
								여권
							</div>
							<div
								className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
									userFiles?.visa ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
								}`}
								onClick={() => {
									if (userFiles?.visa) return onFile(userFiles?.visa);
								}}>
								비자
							</div>
							<div
								className={`h-[33px] flex-1 flex justify-center items-center  border  rounded-[2px] text-[14px] font-normal cursor-pointer ${
									userFiles?.airTicket ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
								}`}
								onClick={() => {
									if (userFiles?.airTicket) return onFile(userFiles?.airTicket);
								}}>
								항공권
							</div>
						</div>
					</div>
				)}

				<hr />

				{item.status === "QUOTATION_COMPLETE" ||
					(item.status === "CONFIRM" && (
						<div className="flex flex-col justify-center px-[10px] py-[30px]">
							<div>기타 비고</div>
							<div className="text-[12px] font-medium mt-[10px] ">
								<span className="font-extrabold">·</span> 상품, 결제 등의 문의는 1:1 문의를 통해 문의해 주세요.
							</div>
						</div>
					))}

				{item.status === "QUOTATION_COMPLETE" && (item.type === "BUSINESS_TRIP" || item.type === "ALONE") && (
					<div className="px-[10px] mb-4">
						<div className="">
							<div className="">
								<h4>
									동의사항 <span className="text-red-600 text-xs font-medium">*</span>
								</h4>
							</div>
						</div>
						<div className="text-neutral-950 mt-4 text-xs font-medium font-['Spoqa Han Sans Neo']">
							예약인원과 입력된 정보수가 일치하지 않을 경우, 원활한 예약 진행을 위해
							<br />
							보완 요청을 드릴 수 있습니다.
							<br />
							입력하신 정보를 다시 한 번 확인해 주시기 바랍니다.
						</div>
						<div className="flex w-full justify-end text-neutral-950 text-sm font-medium">
							<input id="" type="checkbox" checked={readyToSubmit} onChange={() => setReadyToSubmit(!readyToSubmit)} />
							<label id="" htmlFor="">
								확인했습니다.
							</label>
						</div>
					</div>
				)}

				<hr />

				<div className="px-[10px]">
					{(item.status === "QUOTATION_COMPLETE" || item.status === "CONFIRM") && (
						<>
							<div className="flex justify-between items-center h-[83px]">
								<div className="text-[16px] font-bold ">총 견적 비용</div>
								<div className="text-[#0057FF] text-[18px]">{quotations?.finalQutotePrice?.toLocaleString("ko-KR")}원</div>
							</div>
						</>
					)}

					{item.status === "QUOTATION_COMPLETE" && (
						<>
							<button
								type="button"
								disabled={!isActivate()}
								className={[
									" w-full text-[16px] text-white flex justify-center items-center h-[40px] cursor-pointer",
									isActivate() ? "bg-[#0057FF]" : "bg-gray-500",
								].join(" ")}
								onClick={() => {
									setOnModal(true);
								}}>
								견적서 확정하기
							</button>
							<div
								className="bg-[#0057FF] text-[16px] text-white mt-[20px] flex justify-center items-center h-[40px] cursor-pointer"
								onClick={() => {
									sendOrderCaht();
								}}>
								답변 작성하기
							</div>
							<div
								className="flex text-[16px] justify-center items-center h-[40px] mt-[20px] border cursor-pointer"
								onClick={() => {
									if (window.confirm("주문을 취소할 경우 주문내역이 삭제되며 복구가 불가합니다")) {
										PARTNER_QUOTATION.cancel(+(searchParams.get("quotations") ?? 0)).then(() => {
											alert("취소되었습니다.");
											window.location.href = "/mypage/myorder";
										});
									}
								}}>
								견적서 취소 요청
							</div>
						</>
					)}
					{item.status === "QUOTATION_WAITING" && (
						<>
							<div
								className="bg-[#0057FF] cursor-pointer text-[16px] text-white flex justify-center items-center h-[40px] "
								onClick={() => {
									window.location.href = `/mypage/myorder`;
								}}>
								확인
							</div>
							<div
								className="flex text-[16px] cursor-pointer justify-center items-center h-[40px] mt-[20px] border"
								onClick={() => {
									window.location.href = `/mypage/order/edit/${order_id}`;
								}}>
								주문서 수정하기
							</div>
						</>
					)}

					{item.status === "CONFIRM" && <Paymant />}
				</div>
				<Modal
					open={onModal}
					size="lg"
					onClose={() => {
						setOnModal(false);
					}}>
					<ConfirmModal></ConfirmModal>
				</Modal>
			</div>
		</div>
	);
}
