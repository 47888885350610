export default function NotFound() {
  return(
    <div className="container text-center mt-5 pt-5 pb-2 disallowDrag">
      <h2>요청하신 결과를 찾을 수 없습니다.</h2>
      <p>
        상단의 주소가 정확한지 확인해주세요.<br />
      </p>
      <p>
        이러한 현상이 지속될 경우<br />
        고객센터에 알려주시면 신속히 해결해드리겠습니다.
      </p>
      <br />
      <button type="button" className="btn btn-primary linkButton mt-2 mb-5" onClick={() => window.location.href = "/"}>메인 화면으로 돌아가기</button>
      <q className="d-block" style={{color: "#999999", lineHeight: "160%", fontSize: "calc(0.8rem + 0.4vw)"}}>
        여행을 요리처럼 쉽게 주문하고,<br />
        새로운 친구들과 함께 하는<br />
        <strong>지속 가능한 커뮤니티 여행 플랫폼</strong>
      </q>
    </div>
  );
}