import "./omt_brandStory.scss";
import brandStoryImage1 from "../image/brandStory/brandStoryImage1.jpg";
import brandStoryImage2 from "../image/brandStory/brandStoryImage2.jpg";
import brandStoryImage3 from "../image/brandStory/brandStoryImage3.jpg";
import brandStoryImage4 from "../image/brandStory/brandStoryImage4.jpg";

export default function OrderMadeTripBrandStory() {
	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full">
				<div className="h-[1px] w-full bg-[#dadada] mt-12 flex justify-center relative">
					<div className="text-lg text-black bg-white absolute -bottom-3.5 px-8">브랜드 스토리</div>
				</div>

				<div className="px-3">
					<div className="mt-8">
						<img src={brandStoryImage1} alt="brandStoryImage1" className="w-full" />
					</div>
					<div className="text-success text-center mt-8">About Us</div>
					<div className="text-[#434549] text-center mt-2">"여행도 요리처럼"</div>
					<div className="text-center text-black font-normal text-sm mt-4">
						여행은 새로운 경험과 일상에서 탈출을 느끼게 하여 우리의 행복을 높여줍니다.
						<br />
						편하고, 재미있고, 즐길 수 있는 여행이 될수록 보다 완벽한 만족을 느낄 수 있습니다.
						<br />
						<br />
						오더 메이트 트립은 이러한 여행의 본질을 중심으로 하여 시작 되었습니다.
						<br />
						여행을 요리처럼 편하게 주문하여 만들고 여행을 함께 할 동반자를 찾고,
						<br />
						함께 출발하는 여행을 만들기 위해 오더 메이드 트립을 만들게 되었습니다.
						<br />
					</div>
				</div>

				<div className="border-b border-[#dadada] my-12"></div>

				<div className="px-3">
					<div className="mt-8">
						<img src={brandStoryImage2} alt="brandStoryImage2" className="w-full" />
					</div>
					<div className="text-success text-center mt-8">Issue</div>
					<div className="text-[#434549] text-center mt-2">"나와 우리를 위한 패키지로"</div>
					<div className="text-center text-black font-normal text-sm mt-4">
						패키지 여행은 저렴한 가격과 정보를 찾는데 소요되는 시간의 절약, <br />
						언어에 대한 불편함 해소 등 정말 좋은 점이 많습니다.
						<br />
						<br />
						하지만 불특정 다수와 함께 해야 하는 여행의 불편함, 개인 일정의 불가, <br />
						원하지 않는 쇼핑 및 옵션 등 우리는 이러한 불편함을 해소하고 <br />
						함께 만들어 가는 여행을 만들기 위하여 서비스를 준비하였습니다.
						<br />
					</div>
				</div>

				<div className="border-b border-[#dadada] my-12"></div>
				<div className="px-3">
					<div className="mt-8">
						<img src={brandStoryImage3} alt="brandStoryImage3" className="w-full" />
					</div>
					<div className="text-success text-center mt-8">Our Service</div>
					<div className="text-[#434549] text-center mt-2">"혼자여도, 함께여도"</div>
					<div className="text-center text-black font-normal text-sm mt-4">
						우리는 여러분이 알려주시는 다양하고 이색적인 여정을 <br />
						전 세계 파트너 여행사들과 함께 고민하고 만들어 드립니다.
						<br />
						<br />
						여행이 완성되면 나와 비슷한 연령, 성별, 1인 여행객 등 <br />
						원하는 동반자를 정하여 함께 할 동반자를 저희와 함께 모집을 합니다.
						<br />
						<br />
						여행 시작 전부터 크루와 소통 할 수 있는 커뮤니티를 통하여 <br />
						여행 전부터 동반자 분들이 함께 할 수 있도록 준비해 드립니다.
						<br />
					</div>
				</div>

				<div className="border-b border-[#dadada] my-12"></div>

				<div className="px-3">
					<div className="mt-8">
						<img src={brandStoryImage4} alt="brandStoryImage4" className="w-full" />
					</div>
					<div className="text-success text-center mt-8">Our Value</div>
					<div className="text-[#434549] text-center mt-2">"새로운 친구를 만나는 또 다른 방법"</div>
					<div className="text-center text-black font-normal text-sm mt-4">
						우리는 사회라는 새로운 여정에 발을 딛는 순간 <br />
						새로운 친구를 만나는 과정에 대한 어려움을 알고 있습니다.
						<br />
						<br />
						이제부터 오더 메이드 트립을 통해 여행을 함께 만들 친구와
						<br />
						함께 여행을 즐길 친구를 어렵지 않게 만날 수 있습니다.
						<br />
						새로운 친구와 여행의 추억을 쌓으며 더욱 특별한 우정을 만들어 보세요.
						<br />
						<br />
						우리는 여행과 우정에 대한 새로운 경험과 가치를 제공합니다.
						<br />
					</div>
				</div>
				<div className="border-b border-[#dadada] my-12"></div>
				<div className="px-3 text-lg text-center">
					"지금 시작합니다. 여러분의 여행을" <span className="text-base font-normal">- Order Made Trip</span>
				</div>
			</div>
		</div>
	);
}
