import axios from "axios";
import Cookies from "js-cookie";

import { TravelPostProps, UserOrder, PartnerOrder, PartnerQuotationFileProps, RecommendationTrip } from "../types";
import sampleProfileImage from "../image/defaultProfile.svg";

export const calculateStayDays = (startDateStr: string | null | undefined, endDateStr: string | null | undefined): number | undefined => {
	if (startDateStr && endDateStr) {
		const startDate = new Date(startDateStr);
		const endDate = new Date(endDateStr);

		const diffInMs = endDate.getTime() - startDate.getTime();
		let diffInDays = Math.floor(diffInMs / 86400000);

		if (diffInMs % 86400000 !== 0) {
			diffInDays += 1;
		}
		return diffInDays;
	}
	return undefined;
};

export const calculateStayDaysString = (startDateStr: string | null | undefined, endDateStr: string | null | undefined): string | undefined => {
	if (startDateStr && endDateStr) {
		const startDate = new Date(startDateStr);
		const endDate = new Date(endDateStr);

		const diffInMs = endDate.getTime() - startDate.getTime();
		let diffInDays = Math.floor(diffInMs / 86400000);

		if (diffInMs % 86400000 !== 0) {
			diffInDays += 1;
		}

		if (diffInDays === 0) {
			return "당일치기";
		}
		return `${diffInDays}박 ${diffInDays + 1}일`;
	}
	return undefined;
};

export const calculateDDay = (startDateStr: string | null | undefined, endDateStr: string | null | undefined): number | undefined => {
	if (startDateStr && endDateStr) {
		const startDate = new Date(startDateStr);
		const endDate = new Date(endDateStr);

		// 디데이 형식으로 계산하기 위함
		startDate.setHours(0, 0, 0, 0);
		endDate.setHours(0, 0, 0, 0);

		const diffInMs = endDate.getTime() - startDate.getTime();
		const diffInDays = Math.floor(diffInMs / 86400000);

		return diffInDays;
	}
	return undefined;
};

export const formatDateISOStringToDate = (isoString: string | undefined): string => {
	if (!isoString) {
		return "";
	}
	const date = new Date(isoString);
	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
};

export const formatDateStringToISOString = (dateString: string | undefined): string => {
	if (!dateString) {
		return "";
	}
	const [year, month, day] = dateString.split("-").map((str) => parseInt(str, 10));
	const date = new Date(year, month - 1, day);
	return date.toISOString();
};

export const formatGender = (genderGroupName: string | undefined): string => {
	switch (genderGroupName) {
		case "Male":
			return "남성";
		case "Female":
			return "여성";
		case "All":
			return "남녀무관";
		default:
			return genderGroupName || "";
	}
};

export const formatTripType = (trip_type: string | undefined): string => {
	switch (trip_type) {
		case "RECRUITMENT":
			return "모집형";
		case "BUSINESS_TRIP":
			return "출장형";
		case "ALONE":
			return "단독형";
		default:
			return trip_type || "";
	}
};

export const formatPartnerOrderStatus = (order_status: string | undefined): string => {
	switch (order_status) {
		case "QUOTATION_WAITING":
			return "답변서 대기중";
		case "QUOTATION_COMPLETE":
			return "답변서 작성 완료";
		case "CONFIRM":
			return "주문 확정됨";
		case "PAYMENT_COMPLETE":
			return "결제 완료";
		default:
			return order_status || "";
	}
};

export const formatUserOrderStatus = (order_status: string | undefined): string => {
	switch (order_status) {
		case "QUOTATION_WAITING":
			return "견적 책정중";
		case "QUOTATION_COMPLETE":
			return "견적 책정 완료";
		case "CONFIRM":
			return "결제 대기중";
		case "PAYMENT_COMPLETE":
			return "결제 완료됨";
		default:
			return order_status || "";
	}
};

export function getLowestQuotationPrice(order?: UserOrder): string {
	if (!order || !order.PartnerQuotations || order.PartnerQuotations.length === 0) {
		return "- ";
	}

	const { PartnerQuotations } = order;

	const lowestPrice = PartnerQuotations.reduce((min, quote) => {
		return quote.finalQuotePrice < min ? quote.finalQuotePrice : min;
	}, PartnerQuotations[0].finalQuotePrice);

	return new Intl.NumberFormat("ko-KR", { style: "currency", currency: "KRW" }).format(lowestPrice);
}

export function getGroupLeaderProfile(travelPost: TravelPostProps): { groupLeaderProfileUrl: string; groupLeaderNickname: string } {
	const groupLeader = travelPost.participants?.find((participant) => participant.isGroupLeader === true);

	if (groupLeader) {
		const profileFile = groupLeader.UserFiles.find((file) => file.type === "PROFILE");
		const groupLeaderProfileUrl = profileFile ? profileFile.File.url : sampleProfileImage;
		const groupLeaderNickname = groupLeader.nickname;

		return {
			groupLeaderProfileUrl,
			groupLeaderNickname,
		};
	}

	return {
		groupLeaderProfileUrl: `url(${sampleProfileImage})`,
		groupLeaderNickname: "",
	};
}

export function searchFileFromPartnerOrder(partnerOrder: PartnerOrder, type: string): PartnerQuotationFileProps[] {
	return partnerOrder.PartnerQuotationFiles.filter((file) => file.type === type);
}

export function getMatchingFile(node: RecommendationTrip): string | null {
	if (!node.RecommendationTripFile || node.RecommendationTripFile.length === 0) {
		return null;
	}

	const sortedFiles = node.RecommendationTripFile.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

	return sortedFiles[0].File.url;
}
