import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { getToken } from "../auth";

interface CheckLoginProps {
	children: React.ReactNode;
	roles: ("PARTNER" | "USER" | "ADMIN")[];
}

const CheckLogin: React.FC<CheckLoginProps> = ({ children, roles = ["USER"] }) => {
	useEffect(() => {
		const token = getToken();
		if (token) {
			const decoded = jwtDecode(token) as any;
			if (roles.indexOf(decoded.role) === -1) {
				if (decoded.role === "PARTNER") {
					alert("파트너는 접근할 수 없는 페이지입니다.");
					window.location.href = "/";
					return;
				}

				window.location.href = `/login?redirect=${window.location.href}`;
			}
		} else {
			window.location.href = `/login?redirect=${window.location.href}`;
		}
	}, []);

	return <>{children}</>;
};

export default CheckLogin;
