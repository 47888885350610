import { useEffect, useState } from "react";
import { DEFAULT } from "../../api";

function MainHeader() {
	const [img, setImg]: any = useState({});

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setImg(res);
		});
	}, []);

	return (
		<div
			className="intro-banner bg-cover bg-no-repeat bg-center"
			style={{
				backgroundImage: `url(${img?.mainBannerImageUrl})`,
			}}>
			<div className="overlay"></div>
			<div className="banner-content py-5">
				<div dangerouslySetInnerHTML={{ __html: img?.mainBannerText }}></div>
			</div>
		</div>
	);
}

export default MainHeader;
