import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { isBrowser } from 'react-device-detect';
import "./myp_myOrderDetail.scss";
import orderListIcon from "../image/book.svg";
import noCoverImage from "../image/noimage.svg";
import arrow from "../image/arrow-right-line.svg";
import danger from "../image/danger.svg";
import check from "../image/check-rect.svg";
import kakao from "../image/kakao-pay.svg";
import radio from "../image/radio.svg";
import wallet from "../image/wallet.svg";

import samplePassport from "../image/noimage.svg";
import sampleVisa from "../image/noimage.svg";
import sampleTicket from "../image/noimage.svg";

import { UserOrder } from "../types";
import { calculateStayDays, formatDateISOStringToDate, formatGender, formatTripType, getLowestQuotationPrice } from "../components/CommonFunction";
import { FILE, ORDER } from "../api";
import GoogleMap from "../components/GoogleMap";
import InputImage from "../components/input/image";
import SvgIcon from "../components/SvgIcon";
import Modal from "../components/modal";

export default function MypageCommunityPayed() {
	// URL 매개변수 받아오기
	const { order_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [item, setItem]: any = useState("");
	const [quotations, setQuotations]: any = useState("");
	const [passport, setPassport]: any = useState("");
	const [visa, setVisa]: any = useState("");
	const [ticket, setTicket]: any = useState("");
	const [onModal, setOnModal]: any = useState(false);
	const [payment, setPayment]: any = useState("KAKAOPAY");

	useEffect(() => {
		const quotationsId = searchParams.get("quotations");

		ORDER.getMyOrderById(order_id).then((data: any) => {
			const quotationsById = data.PartnerQuotations.find((e: any) => e.id === Number(quotationsId));
			setItem(data);
			setQuotations(quotationsById);
		});
	}, []);

	const confirmOrder = () => {
		const data = { participantsForBusiness: [], passportImageId: passport.id, visaImageId: visa.id, airTicketImageId: ticket.id };

		ORDER.confirmOrder(order_id, quotations.id, data).then((res) => {
			window.location.href = "/mypage/order/payment";
		});
	};

	const fileUpload = (e: any, setValue: any) => {
		const reader = new FileReader();
		const file = e.target.files ? e.target.files[0] : null;

		if (file) {
			reader.onloadend = () => {};
			reader.readAsDataURL(file);
			FILE.upload(file).then((res) => {
				setValue(res);
			});
		}

		FILE.upload(file).then((res) => {});
	};

	const paymentOrder = async () => {
		const data = { paymentMethod: payment };
		await ORDER.editOrder(order_id, {
			passportImageId: passport.id,
			visaImageId: visa.id,
			airTicketImageId: ticket.id,
		});

		await ORDER.paymentOrder(order_id, data).then((res) => {
			if (!res) {
				alert("결제에 실패하였습니다.");
				return;
			}

			if (data.paymentMethod === "BANK_TRANSFER") {
				alert(`${res.redirectInfo.bankAccount} 로 입금해 주세요.`);
				window.location.href = `/payment-complete?orderId=${order_id}&pg_token=__BANK`;
			} else {
				console.log(`isBrowser (${isBrowser}), PC - [${res.redirectInfo.paymentUrlPc}],  Mobile - [${res.redirectInfo.paymentUrlMobile}]`);
				if (isBrowser) {
					window.open(res.redirectInfo.paymentUrlPc, "_self");
				} else {
					window.open(res.redirectInfo.paymentUrlMobile, "_self");
				}
			}
		});
	};

	const Tag = ({ tag, value, essential, className }: any) => {
		return (
			<div className={`flex w-full mb-[30px] ${className}`}>
				<div className="w-[30%] text-[16px] font-medium relative">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="text-[16px] text-[#777777] flex-1 break-all">{value || ""}</div>
			</div>
		);
	};

	const InputTag = ({ tag, value, essential, className }: any) => {
		return (
			<div className={`w-full mb-[30px] ${className}`}>
				<div className="w-[30%] text-[16px] font-medium relative">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div defaultValue={value} className="w-full min-h-[40px] mt-[10px] border bg-[#ECECEC] border-[#999999] text-[#999999] font-medium px-[8px]">
					{value}{" "}
				</div>
			</div>
		);
	};

	const SeleteInputTag = ({ tag, value, textValue, list, essential, className }: any) => {
		return (
			<div className={`w-full mb-[30px] ${className}`}>
				<div className="w-[30%] text-[16px] font-medium relative">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="flex gap-[10px] mt-[10px]">
					{list?.map((e: any, i: any) => (
						<div
							key={i}
							className={`border text-[14px] font-normal w-[85px] h-[33px] flex justify-center items-center ${
								e.value === value ? "border-[#0057FF] text-[#0057FF]" : "text-[#999999] "
							}`}>
							{e.name}
						</div>
					))}
				</div>
				<div className="flex items-center mt-[10px]">
					<div className="w-[85px] h-[40px] border-t border-l border-b bg-[#ECECEC] border-[#999999] text-[#999999] flex justify-center items-center">기타</div>
					<div className="flex-1 h-[40px] flex items-center border bg-[#ECECEC] border-[#999999] text-[#999999] font-medium px-[8px]"> {textValue}</div>
				</div>
			</div>
		);
	};

	const TextInput = ({ tag, value, desc, essential, className }: any) => {
		return (
			<div className={`w-full mb-[30px] ${className}`}>
				<div className="w-full text-[16px] font-medium relative mb-[5px]">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="w-full text-[12px] font-medium mb-[10px]">{desc}</div>
				<div className="flex items-center mt-[10px]">
					<div className="w-full h-[130px] border bg-[#ECECEC] border-[#999999] text-[#999999] p-[10px] resize-none overflow-scroll"> {value}</div>
				</div>
			</div>
		);
	};

	const CommentBox = ({ tag, value }: any) => {
		return (
			<div className="w-full mt-[-20px] mb-[30px]">
				<div className="flex gap-[5px] text-[16px] font-bold text-black">
					<div>
						<SvgIcon className="w-[24px] aspect-square bg-black" icon={arrow} />
					</div>
					<div>{tag}</div>
				</div>

				<div className="w-full h-[120px] mt-[5px] bg-white border-2 border-[#999999] resize-none rounded-[2px]">{value}</div>
			</div>
		);
	};

	const FileInputTag = ({ tag, value, essential, setValue }: any) => {
		return (
			<div className="">
				<div className="text-[16px] mb-[10px]">
					{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
				</div>
				<div className="flex border w-fll h-[35px] border-[#DADADA]">
					<label
						className="w-[77px] text-[12px] font-medium border-[#DADADA] border-r flex justify-center items-center bg-[#ECECEC]"
						htmlFor={`input_${value}`}>
						파일 선택
					</label>
					<div className="text-[12px] text-[#999999] flex justify-center items-center px-[10px] font-medium ">{value || "선택된 파일이 없습니다."}</div>
				</div>
				<div className="text-[12px] text-[#999999] font-medium mt-[7px]">JPG, JPEG, PNG, PDF, ZIP 업로드 가능</div>
				<input
					id={`input_${value}`}
					onChange={(e) => {
						fileUpload(e, setValue);
					}}
					placeholder="선택된 파일이 없습니다."
					className="hidden"
					type="file"
				/>
			</div>
		);
	};

	const ConfirmModal = () => {
		return (
			<>
				<div className="flex flex-col items-center">
					<img className="w-[40px] aspect-square mb-[20px] " src={check} />
					<div className="text-[20px] font-bold text-[#0057FF] mb-[20px]">견적서 확정 완료</div>
					<div className="text-[14px] font-bold mb-[10px]">선택하신 견적서가 확정 되었습니다.</div>
					<div className="text-[14px] font-medium">이후 현지 파트너까지 확정 후 현지 수배가 이루어지며</div>
					<div className="text-[14px] font-medium">현지 파트너의 확정은 알림을 통해 공유해 드리겠습니다.</div>
				</div>

				<hr className="my-[30px] w-full border-[#DADADA]" />

				<div className="flex flex-col items-center">
					<img className="w-[40px] aspect-square mb-[20px] " src={danger} />
					<div className="text-[20px] font-bold text-[#FF0000] mb-[20px]">주의</div>
					<div className="text-[14px] font-bold mb-[10px]">견적서 확정 이후부터 취소료 규정이 적용됩니다.</div>
					<div className="text-[14px] font-medium">예약 하신 상품의 변경/취소가 있을경우,</div>
					<div className="text-[14px] font-medium">취소료 규정을 확인 후 접수 부탁드립니다.</div>
				</div>

				<div
					className="bg-[#0057FF] w-full h-[30px] flex justify-center items-center text-white mt-[30px] cursor-pointer"
					onClick={() => {
						confirmOrder();
					}}>
					확인
				</div>
				<div
					className="bg-[#F6F7F9] border border-[#DADADA] w-full h-[30px] flex justify-center items-center text-[#424549] mt-[10px] cursor-pointer"
					onClick={() => setOnModal(false)}>
					취소
				</div>
			</>
		);
	};

	const Paymant = () => {
		return (
			<>
				<div className="my-[30px] border-t border-b border-[#DADADA] py-[30px]">
					<div className="mb-[20px] text-[16px] font-bold">결제 방법</div>
					<div className="flex gap-[30px] ">
						<div className="flex gap-[10px] items-center">
							{payment === "KAKAOPAY" ? (
								<img src={radio}></img>
							) : (
								<div
									className="rounded-full border w-[24px] aspect-square border-[#999999] bg-[#ECECEC] cursor-pointer"
									onClick={() => {
										setPayment("KAKAOPAY");
									}}></div>
							)}
							<img src={kakao} />
						</div>
						<div className="flex gap-[10px] items-center">
							{payment === "BANK_TRANSFER" ? (
								<img src={radio}></img>
							) : (
								<div
									className="rounded-full border w-[24px] aspect-square border-[#999999] bg-[#ECECEC] cursor-pointer"
									onClick={() => {
										setPayment("BANK_TRANSFER");
									}}></div>
							)}
							<div className="w-[97px] h-[40px] rounded-[37px] flex justify-center items-center bg-[#202C4A] text-white">계좌이체</div>
						</div>
					</div>
				</div>
				<div
					className="text-[16px] font-bold text-white bg-[#0057FF] rounded-[2px] py-[10px] flex justify-center items-center cursor-pointer"
					onClick={() => {
						paymentOrder();
					}}>
					결제하기
				</div>
			</>
		);
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-[512px]">
				<div className="py-[20px] px-[10px] flex gap-[5px] text-[#0057FF] items-center">
					<SvgIcon icon={wallet} className="bg-[#0057FF] w-[24px] aspect-square"></SvgIcon>
					<div>참여 결제/결제 취소</div>
				</div>

				<hr className=" border-[#DADADA] mx-[10px]" />

				<div className="w-full p-[10px] text-[13px] font-medium  my-[20px]">
					<div className="w-full p-[10px] flex gap-[10px] border border-[#DADADA] rounded-[2px]">
						<div className="w-[50%] aspect-[16/12] bg-gray-200 flex justify-center items-center">
							<div className="text-[#999] text-xs">이미지 없음</div>
						</div>
						<div className="flex flex-col justify-around">
							<div>여행 형태: {(item.type === "ALONE" && "단독 주문") || (item.type === "RECRUITMENT" && "모집 주문")}</div>
							<div>국가: {item?.nation}</div>
							<div>도시: {item?.city}</div>
							<div>
								{item?.numberOfRecruits}명 · {item?.recruitGenderTypeText} · {item?.ageGroup}
							</div>
							<div>{`${new Date(item?.startedAt).toLocaleDateString().slice(0, -1)} - ${new Date(item?.endedAt).toLocaleDateString().slice(0, -1)}`}</div>
						</div>
					</div>
				</div>

				<hr className="border-[#DADADA] mx-[10px]" />

				<div className="px-[10px]">
					<Tag
						tag="여행 형태 선택"
						className="mt-[20px]"
						value={(item.type === "ALONE" && "단독 주문") || (item.type === "RECRUITMENT" && "모집 주문")}
						essential={true}
					/>
					<Tag tag="항공권 포함 여부" value={item?.isIncludeAirticket ? "포함" : "미포함"} essential={true} />
					<Tag tag="이름" value={item?.name} essential={true} />
					<Tag tag="이메일" value={item?.email} essential={true} />
					<Tag tag="국가 선택" value={item?.nation} essential={true} />
					<Tag tag="도시 선택" value={item?.city} essential={true} />
					<GoogleMap className="h-[300px] w-full mb-[30px]" location={item?.nation} />
					<Tag
						tag="여행 기간"
						value={`${new Date(item?.startedAt).toLocaleDateString().slice(0, -1)} - ${new Date(item?.endedAt).toLocaleDateString().slice(0, -1)}`}
						essential={true}
					/>
					<Tag tag="1인 예상 경비" value={item?.expenses} essential={true} />
					<Tag tag="모집 유형" value={item?.recruitGenderTypeText} essential={true} />
					<Tag tag="모집 연령" value={item?.ageGroup} essential={true} />
					<Tag tag="모집 인원" value={`${item?.numberOfRecruits}명`} essential={true} />
					<InputTag tag="여행 유형" value={item?.numberOfRecruits} essential={true} />
					<InputTag tag="숙소" value={item?.accommodation} essential={true} />
					{item.status === "QUOTATION_COMPLETE" ||
						(item.status === "CONFIRM" && quotations?.accommodation && <CommentBox tag="숙소 답변" value={quotations?.accommodation} />)}

					<InputTag tag="교통" value={item?.transportation} essential={true} />
					{item.status === "QUOTATION_COMPLETE" ||
						(item.status === "CONFIRM" && quotations?.transportation && <CommentBox tag="교통 답변" value={quotations?.transportation} />)}

					<SeleteInputTag
						tag="가이드"
						value={item?.guideAvailability}
						textValue={item?.guideAvailabilityDesc}
						list={[
							{ name: "필요", value: true },
							{ name: "불필요", value: false },
						]}
						essential={true}
					/>
					{item.status === "QUOTATION_COMPLETE" ||
						(item.status === "CONFIRM" && quotations?.guide && <CommentBox tag="가이드 답변" value={quotations?.guide} />)}

					<SeleteInputTag
						tag="견적 수신방법"
						value={item?.quotationReceivingMethod}
						textValue={item?.quotationReceivingTarget}
						list={[
							{ name: "이메일", value: "EMAIL" },
							{ name: "카카오", value: "KAKAO" },
						]}
						essential={true}
					/>

					<TextInput tag="일차별 일정 또는 여행관련 요청사항" desc="(관광지, 액티비티 문의 등)" value={item.travelRequest} />
					{item.status === "QUOTATION_COMPLETE" ||
						(item.status === "CONFIRM" && quotations?.travelRequest && <CommentBox tag="요청사항 답변" value={quotations?.travelRequest} />)}

					<InputTag tag="여행 제목" value={item?.title} essential={true} />
				</div>

				<hr />

				<div className="px-[10px] py-[30px] flex flex-col gap-[20px]">
					<FileInputTag
						tag="여권 정보"
						value={passport.name}
						setValue={(e: any) => {
							setPassport(e);
						}}
					/>
					<FileInputTag
						tag="비자 정보"
						value={visa.name}
						setValue={(e: any) => {
							setVisa(e);
						}}
					/>
					<FileInputTag
						tag="항공권 정보"
						value={ticket.name}
						setValue={(e: any) => {
							setTicket(e);
						}}
					/>
				</div>

				<hr />

				<div className="flex flex-col justify-center px-[10px] py-[30px]">
					<div>기타 비고</div>
					<div className="text-[12px] font-medium mt-[10px] ">
						<span className="font-extrabold">·</span> 상품, 결제 등의 문의는 1:1 문의를 통해 문의해 주세요.
					</div>
				</div>

				<hr />

				<div className="px-[10px]">
					<div className="flex justify-between items-center h-[83px]">
						<div className="text-[16px] font-bold ">총 견적 비용</div>
						<div className="text-[#0057FF] text-[18px]">{Math.round(item?.PartnerQuotations?.[0]?.finalQutotePrice || 0).toLocaleString("KR-ko")}원</div>
					</div>
					<Paymant />
				</div>
				<Modal
					open={onModal}
					onClose={() => {
						setOnModal(false);
					}}>
					<ConfirmModal></ConfirmModal>
				</Modal>
			</div>
		</div>
	);
}
