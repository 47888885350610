import OrderBannerImage from "../image/order_howToUse/0_.png";
import OrderHTUImage1 from "../image/order_howToUse/0.png";
import OrderHTUImage2 from "../image/order_howToUse/1.png";
import OrderHTUImage3 from "../image/order_howToUse/2.png";
import OrderHTUImage4 from "../image/order_howToUse/3.png";
import OrderHTUImage5 from "../image/order_howToUse/4.png";

import DesktopImage0 from "../image/order_howToUse/desktop_0.png";
import DesktopImage1 from "../image/order_howToUse/desktop_1.png";
import DesktopImage2 from "../image/order_howToUse/desktop_2.png";
import DesktopImage3 from "../image/order_howToUse/desktop_3.png";
import DesktopImage4 from "../image/order_howToUse/desktop_4.png";
import DesktopImage5 from "../image/order_howToUse/desktop_5.png";

const IconInfo = ({ className }: any) => {
	return (
		<svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13 23.8327C18.9583 23.8327 23.8333 18.9577 23.8333 12.9993C23.8333 7.04102 18.9583 2.16602 13 2.16602C7.04166 2.16602 2.16666 7.04102 2.16666 12.9993C2.16666 18.9577 7.04166 23.8327 13 23.8327Z"
				stroke="#0057FF"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path d="M13 8.66602V14.0827" stroke="#0057FF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
			<path d="M12.994 17.334H13.0038" stroke="#0057FF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
		</svg>
	);
};

// 코드가 짧으므로, 별도의 파일을 만들지 않기 위해 인라인 스타일 적용
export default function OrderHowToUse() {
	return (
		<div className="flex justify-center items-center">
			<div className="pt-10 flex flex-col max-w-lg xl:max-w-[1200px] w-full">
				<div className="xl:border-b xl:border-[#999999] xl:px-20">
					<img className="xl:hidden" src={OrderBannerImage} alt="banner" />
					<img className="hidden xl:block" src={DesktopImage0} alt="banner" />
				</div>
				<div className="border-b border-[#999999] xl:flex xl:justify-center">
					<img className="xl:hidden" src={OrderHTUImage1} alt="banner" />
					<img className="hidden xl:block w-[793px]" src={DesktopImage1} alt="banner" />
				</div>
				<div className="border-b border-[#999999] xl:flex xl:justify-center">
					<img className="xl:hidden" src={OrderHTUImage2} alt="banner" />
					<img className="hidden xl:block w-[793px]" src={DesktopImage2} alt="banner" />
				</div>
				<div className="border-b border-[#999999] xl:flex xl:justify-center">
					<img className="xl:hidden" src={OrderHTUImage3} alt="banner" />
					<img className="hidden xl:block w-[793px]" src={DesktopImage3} alt="banner" />
				</div>
				<div className="border-b border-[#999999] xl:flex xl:justify-center">
					<img className="xl:hidden" src={OrderHTUImage4} alt="banner" />
					<img className="hidden xl:block w-[793px]" src={DesktopImage4} alt="banner" />
				</div>
				<div className="border-b border-[#999999] xl:flex xl:justify-center">
					<img className="xl:hidden" src={OrderHTUImage5} alt="banner" />
					<img className="hidden xl:block w-[793px]" src={DesktopImage5} alt="banner" />
				</div>
				<div className="h-[429px] bg-[#ECECEC] border-b border-[#999999]">
					<div className="px-[13px] pt-[31px] flex gap-[10px] items-center">
						<IconInfo className="xl:w-[34px] xl:h-[34px]" />
						<p className="font-spoqa text-xl font-bold text-[#0057FF] xl:text-2xl">참고사항</p>
					</div>
					<div className="px-[25px] text-[#0e1111] font-spoqa text-[18px] font-bold mt-4 xl:text-xl">여행유형 구분</div>
					<div className="px-[25px] text-[#0e1111] font-spoqa text-[16px] font-bold mt-[10px] xl:text-[18px]">- 모집형</div>
					<div className="px-[25px] text-[#0e1111] font-spoqa text-[16px] font-medium mt-[5px] tracking-[-1.04px] leading-5 xl:text-[16px]">
						여행 주문 및 견적 선택 후 커뮤니티를 통해 여행을 함께 할 크루를 모집하여 여행을 진행하는 참여형 여행
					</div>

					<div className="px-[25px] text-[#0e1111] font-spoqa text-[16px] font-bold mt-[20px] xl:text-[18px]">- 단독형</div>
					<div className="px-[25px] text-[#0e1111] font-spoqa text-[16px] font-medium mt-[5px] tracking-[-1.04px] leading-5 xl:text-[16px]">
						여행 주문서에 작성한 인원으로만 여행을 진행하는 단독형 여행
					</div>

					<div className="px-[25px] text-[#0e1111] font-spoqa text-[16px] font-bold mt-[20px] xl:text-[18px]">- 출장형</div>
					<div className="px-[25px] text-[#0e1111] font-spoqa text-[16px] font-medium mt-[5px] tracking-[-1.04px] leading-5 xl:text-[16px]">
						기업 출장 / 워크샵 / 인센티브 등 기업용 여행
					</div>
				</div>
			</div>
		</div>
	);
}
