import { useEffect, useMemo, useState } from "react";
import { FILE, ORDER, PARTNER, PARTNER_QUOTATION } from "../../../api";
import PartnerMyPagePartnerCard from "./card";
import GoogleMap from "../../GoogleMap";
import { differenceInCalendarDays, format } from "date-fns";
import SvgIcon from "../../SvgIcon";
import arrow from "../../../image/arrow-right-line.svg";

const IconReport = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10.2996 21.5998H5.4996C4.17411 21.5998 3.0996 20.5253 3.09961 19.1998L3.0997 4.79989C3.09971 3.47441 4.17422 2.3999 5.4997 2.3999H16.3C17.6255 2.3999 18.7 3.47442 18.7 4.7999V11.3999M13.9 18.1999L16.1 20.3999L20.9 15.5997M7.29998 7.1999H14.5M7.29998 10.7999H14.5M7.29998 14.3999H10.9"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconArrow = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
			stroke="#0057FF"
			strokeWidth="2"
			stroke-miterlimit="10"
			strokeLinecap="round"
			stroke-linejoin="round"
		/>
		<path d="M3.5 12H20.33" stroke="#0057FF" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
	</svg>
);

const TextInput = ({ tag, value, desc, essential, className }: any) => {
	const textareaToHTML = (text: string) => {
		return text?.replace(/\n/g, "<br />");
	};

	return (
		<div className={`w-full ${className}`}>
			<div className="w-full text-[16px] font-medium relative mb-[5px]">
				{tag} {essential && <span className="text-[#FF0000] ml-[4px] absolute top-[5px] text-[12px]">*</span>}
			</div>
			<div className="w-full text-[12px] font-medium mb-[10px]">{desc}</div>
			<div className="flex items-center mt-[10px]">
				<div
					dangerouslySetInnerHTML={{ __html: textareaToHTML(value) }}
					className="w-full min-h-[40px] border bg-[#ECECEC] border-[#999999] text-[#999999] p-[10px] resize-none font-medium break-words"></div>
			</div>
		</div>
	);
};

const InputCommentBox = ({ tag, value, onChange }: any) => {
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onChange(event.target.value);
	};

	return (
		<div className="w-full mb-[30p] mt-[10px]">
			<div className="flex gap-[5px] text-[16px] font-bold text-[#0057FF]">
				<div>
					<SvgIcon className="w-[24px] aspect-square bg-[#0057FF]" icon={arrow} />
				</div>
				<div>{tag}</div>
			</div>

			<textarea
				value={value}
				onChange={handleChange}
				className="w-full felx itme px-2 py-2.5 h-[120px] mt-[5px] bg-white border-2 border-[#0057FF] resize-none rounded-[2px]"></textarea>
		</div>
	);
};

export default function MyPagePartnerCreateQuotation() {
	const [order, setOrder] = useState<any>(null);
	const [orderChat, setOrderChat]: any = useState([]);
	const [quotation_id, setQuotation_id]: any = useState();

	const [file, setFile] = useState<any>(null);

	const [data, setData] = useState({
		accommodation: "",
		transportation: "",
		guide: "",
		travelRequest: "",
		initialQuotePrice: "0",
		partnerCommission: "0",
	});

	const formatNumber = (value: string) => {
		// 숫자만 입력받기 위해 정규식을 사용하여 숫자가 아닌 모든 문자를 제거합니다.
		const number = value.replace(/\D/g, "");
		// 숫자를 천 단위로 구분하여 포맷합니다.
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const handleChangePrice = (event: any) => {
		const { value } = event.target;
		const formattedPrice = formatNumber(value);
		// 변환된 값을 state에 저장합니다.
		setData({
			...data,
			initialQuotePrice: formattedPrice,
		});
	};

	const handleInput = (key: string, value: string | number | boolean) => {
		setData({
			...data,
			[key]: value,
		});
	};

	const finalPrice = useMemo(() => {
		if (!data.initialQuotePrice || !data.partnerCommission) return 0;
		const price = +data.initialQuotePrice.replace(/,/g, "");
		const commission = price * +data.partnerCommission * 0.01;
		const partnerCommission = price * 0.1;
		return Math.round(price + commission + partnerCommission);
	}, [data]);

	const price = useMemo(() => {
		if (!data.initialQuotePrice || !data.partnerCommission) return 0;
		const price = +data.initialQuotePrice.replace(/,/g, "");
		return price;
	}, [data]);

	const handleChange = (key: string, event: any) => {
		const { value } = event.target;
		setData({
			...data,
			[key]: value,
		});
	};

	const handleSubmit = async () => {
		if (!data.accommodation && !data.transportation && !data.guide && !data.travelRequest) return alert("견적서 답변을 입력해주세요.");
		if (Number(data.initialQuotePrice) < 10000) return alert("견적가를 1만원 이상으로 책정해주세요.");

		await PARTNER_QUOTATION.create(order.id, +data.initialQuotePrice.replace(/,/g, ""), +data.partnerCommission, file?.id)
			.then(async (res) => {
				if (res) {
					await PARTNER_QUOTATION.sendQuotationChat(res.id, {
						accommodation: data.accommodation,
						transportation: data.transportation,
						guide: data.guide,
						travelRequest: data.travelRequest,
					})
						.then((res_1) => {
							alert("견적서를 제출했습니다.");
							window.location.href = "/partner/my/order-status";
						})
						.catch((e: any) => {
							alert("주문서 작성에 실패하였습니다.");
						});
				}
			})
			.catch((e: any) => {
				if (e.response.data.statusCode === 400) {
					alert("주문서에 이미 견적이 확정되었습니다.");
					window.location.href = "/partner/my/order-status";
				} else {
					alert("주문서 작성에 실패하였습니다.");
				}
			});
	};

	const sendComment = async () => {
		if (!data.accommodation && !data.transportation && !data.guide && !data.travelRequest) return alert("견적서 답변을 입력해주세요.");
		if (Number(data.initialQuotePrice.replace(/,/g, "")) < 10000 || !Number(data.initialQuotePrice.replace(/,/g, ""))) return alert("견적가를 확인해주세요.");

		try {
			await PARTNER_QUOTATION.sendQuotationChat(quotation_id, {
				accommodation: data.accommodation,
				transportation: data.transportation,
				guide: data.guide,
				travelRequest: data.travelRequest,
				initialQuotePrice: data.initialQuotePrice.replace(/,/g, ""),
				partnerCommission: `${data.partnerCommission}`,
			}).then((res) => {
				alert("견적서를 제출했습니다.");
				window.location.href = "/partner/my/order-comment";
			});
		} catch {
			alert("주문서를 다시 확인해주세요");
		}
	};

	const [myQuotation, setMyQuotation] = useState<any>(null);
	const travelRequestImage = useMemo(() => {
		if (!myQuotation) {
			return "";
		}
		const travelRequest = myQuotation?.PartnerQuotationFiles?.find((v: any) => v.type === "TRAVLE_REQUEST");
		return travelRequest?.File;
	}, [myQuotation]);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		const orderId = searchParams.get("order_id");
		if (isNaN(Number(orderId)) || !orderId) {
			alert("잘못된 접근입니다.");
			window.history.back();
			return;
		}

		ORDER.getOne(+orderId).then(async (res: any) => {
			const me = await PARTNER.getMe();

			const myOrder = res?.PartnerQuotations?.some((e: any) => e?.partnerId === me?.id);
			if (!myOrder) {
				res.status = "QUOTATION_WAITING";
			}
			setOrder(res);

			const myQuotation = res.PartnerQuotations.find((item: any) => item.partnerId === me.id);

			console.log(myQuotation?.deletedAt);
			if (myQuotation?.deletedAt) {
				alert("삭제된 견적서입니다.");
				window.history.back();
			}

			if (myQuotation) {
				setMyQuotation(myQuotation);
				setQuotation_id(myQuotation?.id);
				setData({
					accommodation: myQuotation.accommodation,
					transportation: myQuotation.transportation,
					guide: myQuotation.guideAvailability,
					travelRequest: myQuotation.travelRequest,
					initialQuotePrice: `${myQuotation.initialQuotePrice}`,
					partnerCommission: `${myQuotation.partnerCommission}`,
				});
			}

			if (myQuotation) {
				await PARTNER_QUOTATION.getQuotationsById(myQuotation.id).then((res) => {
					setOrderChat(res.PartnerQuotationChats);
				});
			}
		});
	}, []);

	const rangeText = useMemo(() => {
		if (!order) return "";
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${startedAt} ~ ${endedAt} · ${differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt)) + 1}일`;
	}, [order]);

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;

		// 파일을 읽고 미리보기 URL을 설정합니다.
		if (file) {
			FILE.upload(file).then((res) => {
				setFile(res);
			});
		}
	};

	const CommentBox = ({ tag, value, type }: any) => {
		return (
			<div className="w-full mt-[10px]">
				<div className={`flex gap-[5px] text-[16px] font-bold  ${type === "user" ? "text-[#347A43]" : "text-[#355C7D]"}`}>
					<div>
						<SvgIcon className={`w-[24px] aspect-square ${type === "user" ? "bg-[#347A43]" : "bg-[#355C7D]"}`} icon={arrow} />
					</div>
					<div>{type === "user" ? "주문서 수정사항" : tag}</div>
				</div>

				<div
					className={`w-full min-h-[100px] px-2 py-2.5 mt-[5px] break-words bg-[#ECECEC] border-2 ${
						type === "user" ? "border-[#347A43]" : "border-[#DADADA]"
					} resize-none rounded-[2px]`}>
					{value}
				</div>
			</div>
		);
	};

	return (
		<>
			{order ? (
				<div className="flex justify-center">
					<div className="w-full max-w-lg">
						<div className="text-success flex px-3 gap-1 mt-5 font-medium">{IconReport} 주문서 답변</div>

						<div className="mt-6 px-3">
							<div className="h-[1px] w-full bg-[#dadada]"></div>
						</div>

						<div className="px-2.5">{order && <PartnerMyPagePartnerCard order={order} />}</div>

						<div className="mt-6 px-3">
							<div className="h-[1px] w-full bg-[#dadada]"></div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								여행 형태 선택<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">
								{order.type === "RECRUITMENT" && "모집 주문"}
								{order.type === "ALONE" && "단독 주문"}
								{order.type === "BUSINESS_TRIP" && "출장 주문"}
							</div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								항공권 포함 여부<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">{order.isIncludeAirticket ? "포함" : "미포함"}</div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								이름<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">{order.name}</div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								이메일<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">{order.email}</div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								국가 선택<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">{order.nation}</div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								도시 선택<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">{order.city}</div>
						</div>

						<div className="px-3 h-[300px] mt-2.5">
							<GoogleMap className="h-full" location={order.nation + " " + order.city} />
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								여행 기간<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="tracking-[-1px] whitespace-nowrap flex-1 text-[#777] font-normal">{rangeText}</div>
						</div>

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								1인 예상 경비<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal">{order.expenses}</div>
						</div>

						{order.type === "RECRUITMENT" && (
							<>
								<div className="px-3 mt-5 flex">
									<div className="w-32 flex gap-1 font-medium text-black">
										모집 유형<span className="text-red-500 relative -bottom-1">*</span>
									</div>
									<div className="flex-1 text-[#777] font-normal">{order.recruitGenderTypeText}</div>
								</div>

								<div className="px-3 mt-5 flex">
									<div className="w-32 flex gap-1 font-medium text-black">
										모집 연령<span className="text-red-500 relative -bottom-1">*</span>
									</div>
									<div className="flex-1 text-[#777] font-normal"> {order.ageGroup}</div>
								</div>
							</>
						)}

						<div className="px-3 mt-5 flex">
							<div className="w-32 flex gap-1 font-medium text-black">
								모집 인원<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex-1 text-[#777] font-normal"> {order.numberOfRecruits}명</div>
						</div>

						<div className="px-3 mt-5 flex flex-col">
							<div className="w-32 flex gap-1 font-medium text-black">
								여행 유형<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="mt-2.5 px-2 py-2.5 text-[#777] bg-[#ececec] border border-[#777] rounded-sm font-medium">{order.tripType}</div>
						</div>

						<div className="px-3 mt-5 flex flex-col">
							<div className="w-32 flex gap-1 font-medium text-black">
								여행 숙소<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="mt-2.5 px-2 py-2.5 text-[#777] bg-[#ececec] border border-[#777] rounded-sm font-medium">{order.accommodation}</div>

							{orderChat?.map((d: any) => {
								return d?.accommodation !== "" && <CommentBox tag="기존 견적서 답변" value={d?.accommodation || ""} type={d.userId ? "user" : "partner"} />;
							})}
							{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && (
								<InputCommentBox
									tag={order.status === "QUOTATION_COMPLETE" ? "수정사항" : "답변 작성"}
									value={data.accommodation}
									onChange={(v: any) => {
										handleInput("accommodation", v);
									}}
								/>
							)}
						</div>

						<div className="px-3 mt-5 flex flex-col">
							<div className="w-32 flex gap-1 font-medium text-black">
								교통<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="mt-2.5 px-2 py-2.5 text-[#777] bg-[#ececec] border border-[#777] rounded-sm font-medium">{order.transportation}</div>

							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.transportation || ""} type={d.userId ? "user" : "partner"} />;
							})}
							{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && (
								<InputCommentBox
									tag={order.status === "QUOTATION_COMPLETE" ? "수정사항" : "답변 작성"}
									value={data.transportation}
									onChange={(v: any) => {
										handleInput("transportation", v);
									}}
								/>
							)}
						</div>

						<div className="px-3 mt-5 flex flex-col">
							<div className="w-32 flex gap-1 font-medium text-black">
								가이드<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex gap-[10px] mt-[10px]">
								{[
									{ name: "필요", value: true },
									{ name: "불필요", value: false },
								]?.map((e: any, i: any) => (
									<button
										key={i}
										className={`border-2 text-[14px] font-normal w-[85px] h-[40px] flex justify-center items-center ${
											e.value === order.guideAvailability ? "border-[#0057FF] text-[#0057FF]" : "text-[#999999] "
										}`}>
										{e.name}
									</button>
								))}
							</div>

							<div className="mt-2.5 px-2 py-2.5 min-h-[100px] resize-none text-[#777] bg-[#ececec] border border-[#777] rounded-sm font-medium">
								{order?.guideAvailabilityDesc}
							</div>
							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.guide || ""} type={d.userId ? "user" : "partner"} />;
							})}
							{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && (
								<InputCommentBox
									tag={order.status === "QUOTATION_COMPLETE" ? "수정사항" : "답변 작성"}
									value={data.guide}
									onChange={(v: any) => {
										handleInput("guide", v);
									}}
								/>
							)}
						</div>

						<div className="px-3 mt-5 flex flex-col">
							<div className="w-32 flex gap-1 font-medium text-black">
								견적 수신방법<span className="text-red-500 relative -bottom-1">*</span>
							</div>
							<div className="flex gap-[10px] mt-[10px]">
								{[
									{ name: "이메일", value: "EMAIL" },
									{ name: "카카오", value: "KAKAO" },
								]?.map((e: any, i: any) => (
									<button
										key={i}
										className={`border-2 text-[14px] font-normal w-[85px] h-[40px] flex justify-center items-center ${
											e.value === order.quotationReceivingMethod ? "border-[#0057FF] text-[#0057FF]" : "text-[#999999] "
										}`}>
										{e.name}
									</button>
								))}
							</div>
							<div className="mt-2.5 px-2 py-2.5  min-h-[40px] text-[#777] bg-[#ececec] border border-[#777] rounded-sm font-medium">
								{order.quotationReceivingTarget}
							</div>
						</div>

						<div className="px-3 mt-5 flex flex-col">
							<TextInput tag="일차별 일정 또는 여행관련 요청사항" desc="(관광지, 액티비티 문의 등)" value={order.travelRequest} />
							{orderChat?.map((d: any) => {
								return <CommentBox tag="기존 견적서 답변" value={d?.travelRequest || ""} type={d.userId ? "user" : "partner"} />;
							})}
							{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && (
								<InputCommentBox
									tag={order.status === "QUOTATION_COMPLETE" ? "수정사항" : "답변 작성"}
									value={data.travelRequest}
									onChange={(v: any) => {
										handleInput("travelRequest", v);
									}}
								/>
							)}
							<div className="mt-2.5 gap-1 flex">
								<div className="border border-[#dadada] rounded-sm flex-1 text-[#999]  flex justify-start px-2 items-center font-medium text-xs">
									{file ? file.name : "이미지 JPG, JPENG, PNG, PDF 업로드 가능"}
								</div>
								{file && (
									<div className="text-red-500 flex items-center px-3 cursor-pointer" onClick={() => setFile(null)}>
										X
									</div>
								)}

								<label className="border border-[#dadada] font-medium px-4 py-1 text-success">
									업로드
									<input type="file" className="hidden" onChange={handleImageChange} />
								</label>
							</div>
						</div>

						{file && (
							<div className="px-3 mt-4">
								{travelRequestImage &&
									(travelRequestImage.extension === "png" ||
										travelRequestImage.extension === "jpg" ||
										travelRequestImage.extension === "jpeg" ||
										travelRequestImage.extension === "gif") && (
										<div className="h-[200px] bg-center bg-cover mb-4" style={{ backgroundImage: `url(${travelRequestImage?.url})` }}></div>
									)}

								<a href={travelRequestImage?.url} target="_blank" className="flex text-blue-500">
									<div className="pr-12 truncate">{travelRequestImage?.name}</div>
									<div className="flex-none">다운로드</div>
								</a>
							</div>
						)}
						{order.type === "BUSINESS_TRIP" && (
							<div className="px-3 mt-5 flex flex-col">
								<div className="flex gap-1 font-medium text-black">기업명 / 단체명</div>
								<div className="mt-2.5 px-2 py-2.5 text-[#777] bg-[#ececec] border border-[#777] min-h-[40px] rounded-sm font-medium">{order.companyName}</div>
							</div>
						)}

						<div className="px-3 mt-5 flex flex-col">
							<div className="flex gap-1 font-medium text-black">여행 제목</div>
							<div className="mt-2.5 px-2 py-2.5 text-[#777] bg-[#ececec] border border-[#777] min-h-[40px] rounded-sm font-medium">{order.title}</div>
						</div>

						{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && (
							<>
								<div className="mt-6">
									<div className="h-[1px] w-full bg-[#dadada]"></div>
								</div>

								<div className="px-3 mt-5 flex flex-col">
									<div className="w-32 flex gap-1 font-medium text-black">
										견적가<span className="text-red-500 relative -bottom-1">*</span>
									</div>

									<div className="flex mt-2.5 items-center gap-4">
										<div>공급가</div>

										<div className="flex-1  h-10 border border-success p-2.5 flex items-center">
											<input
												value={data.initialQuotePrice}
												onChange={handleChangePrice}
												className=" text-black font-medium outline-none flex-1 text-right bg-white"
											/>
											원
										</div>
									</div>

									<div className="flex mt-2.5 items-center gap-4">
										<div>수익률</div>

										<div className="flex-1  h-10 border border-success p-2.5 flex items-center">
											<input
												value={data.partnerCommission}
												maxLength={2}
												onChange={(e) => handleChange("partnerCommission", e)}
												className=" text-black font-medium outline-none flex-1 text-right bg-white"
											/>
											%<span className="text-[#777] text-sm font-medium">({(price * +data.partnerCommission * 0.01).toLocaleString()}원)</span>
										</div>
									</div>

									<div className="flex mt-2.5 items-center gap-4">
										<div>파트너 수수료</div>

										<div className="flex-1 h-10 border border-[#999] bg-[#ececec] p-2.5 flex items-center">
											<input value="10%" className=" text-black font-medium outline-none flex-1 text-right bg-transparent w-10" disabled />
											<span className="text-[#777] text-sm font-medium">({(price * 0.1).toLocaleString()}원)</span>
										</div>
									</div>
								</div>
							</>
						)}

						<div className="mt-6 px-3">
							<div className="h-[1px] w-full bg-[#dadada]"></div>
						</div>

						{order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING" ? (
							<div className="px-3 mt-5 flex">
								<div className="w-32 flex gap-1 font-medium text-black">최종 견적가</div>
								<div className="flex-1 text-success text-right font-bold text-lg">{finalPrice.toLocaleString()}원</div>
							</div>
						) : (
							<div className="px-3 mt-5 flex">
								<div className="w-32 flex gap-1 font-medium text-black">최종 견적가</div>
								<div className="flex-1 text-success text-right font-bold text-lg">{order?.PartnerQuotations?.[0]?.finalQutotePrice}원</div>
							</div>
						)}

						<div className="mt-5 px-3">
							{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && !orderChat?.length && (
								<button
									onClick={handleSubmit}
									type="submit"
									className={["flex h-10 w-full bg-success items-center justify-center rounded-sm text-sm font-bold text-white"].join(" ")}>
									견적서 제출하기
								</button>
							)}

							{(order.status === "QUOTATION_COMPLETE" || order.status === "QUOTATION_WAITING") && orderChat?.length !== 0 && (
								<button
									onClick={sendComment}
									type="submit"
									className={[" mt-2.5 flex h-10 w-full bg-success items-center justify-center rounded-sm text-sm font-bold text-white"].join(" ")}>
									견적서 수정하기
								</button>
							)}

							<button
								type="submit"
								onClick={() => window.history.back()}
								className={[
									"mt-2.5 flex h-10 w-full bg-white items-center justify-center rounded-sm text-sm font-bold text-black border border-[#dadada]",
								].join(" ")}>
								닫기
							</button>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}
