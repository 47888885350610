import { differenceInCalendarDays, format } from "date-fns";
import { useMemo, useState } from "react";
import Modal from "../../modal";
import { FILE, PARTNER_QUOTATION } from "../../../api";

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconReciept = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V8.5M9 9.5V8.5M9 9.5H11.0001M9 9.5C7.88279 9.49998 7.00244 9.62616 7.0001 10.8325C6.99834 11.7328 7.00009 12 9.00009 12C11.0001 12 11.0001 12.2055 11.0001 13.1667C11.0001 13.889 11 14.5 9 14.5M9 15.5V14.5M9 14.5L7.0001 14.5M14 10H17M14 20L16.025 19.595C16.2015 19.5597 16.2898 19.542 16.3721 19.5097C16.4452 19.4811 16.5147 19.4439 16.579 19.399C16.6516 19.3484 16.7152 19.2848 16.8426 19.1574L21 15C21.5523 14.4477 21.5523 13.5523 21 13C20.4477 12.4477 19.5523 12.4477 19 13L14.8426 17.1574C14.7152 17.2848 14.6516 17.3484 14.601 17.421C14.5561 17.4853 14.5189 17.5548 14.4903 17.6279C14.458 17.7102 14.4403 17.7985 14.405 17.975L14 20Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

interface Props {
	quotation: any;
	onConfirm: () => void;
}

export default function PartnerMyPagePartnerQuotationCompleteCard({ quotation, onConfirm = () => {} }: Props) {
	const [modal, setModal] = useState<string | null>(null);
	const order = useMemo(() => {
		return quotation.Order;
	}, [quotation]);

	const rangeText = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt))}박 ${
			differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt)) + 1
		}일`;
	}, [order]);

	const rangeText2 = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${startedAt} ~ ${endedAt}`;
	}, [order]);

	const handleConfirm = () => {
		PARTNER_QUOTATION.confirmQuotation(quotation.id).then(() => {
			alert("견적 확정 완료");
			onConfirm();
		});
	};

	return (
		<div className="border border-[#dadada] rounded-sm flex p-2.5 gap-3">
			<div
				onClick={() => (window.location.href = `/partner/my/create-quotation?order_id=${order.id}`)}
				className={["relative bg-cover bg-center flex-none w-[171px]", "h-[194px] cursor-pointer"].join(" ")}
				style={{
					backgroundImage: `url(/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg)`,
				}}>
				<div className="absolute left-1 top-1 text-white font-medium text-[10px] px-2 py-0.5 rounded-sm bg-[#355c7d]">
					{order.nation}/{rangeText}
				</div>
				<div className="absolute text-white flex justify-center items-center py-1 w-full bg-[#202C4A] bg-opacity-70 left-0 bottom-0">
					{order.status === "QUOTATION_WAITING" && "답변서 대기중"}
					{order.status === "QUOTATION_COMPLETE" && <span className="text-[#F5B826]">답변서 작성완료</span>}
				</div>
			</div>
			<div className="flex-1 text-black text-[13px] font-medium pt-2 flex flex-col gap-1">
				<div className="truncate max-w-[142px]">
					여행 형태:&nbsp;
					{order.type === "RECRUITMENT" && " 모집 주문"}
					{order.type === "ALONE" && " 단독 주문"}
					{order.type === "BUSINESS_TRIP" && " 출장 주문"}
				</div>
				<div className="flex justify-between pr-7 max-w-[142px] truncate">
					<div className="whitespace-nowrap">국가: {order.nation}</div>
					<div className="whitespace-nowrap">도시: {order.city}</div>
				</div>
				<div>
					{order.numberOfRecruits}명·{order.guideAvailability ? "가이드필요" : "가이드필요없음"}
				</div>
				<div className="truncate max-w-[142px]">
					{order.accommodation}·{order.transportation}
				</div>
				<div className="tracking-[-1px]">{rangeText2}</div>
				<div>
					<button onClick={handleConfirm} className="bg-success w-full h-[30px] text-white text-sm flex justify-center items-center cursor-pointer">
						주문 확정하기
					</button>
				</div>
				<div className="mt-1">
					<button
						onClick={() => (window.location.href = `/order/schedule/${order.id}`)}
						className="bg-white border border-[#dadada] w-full h-[30px] text-[#777] text-sm flex justify-center items-center">
						일정표 확인하기
					</button>
				</div>
			</div>
		</div>
	);
}
