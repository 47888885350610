import React, { useMemo } from "react";

interface Props {
	page: number;
	perPage: number;
	count: number;
	onChange: (page: number) => void;
	className?: string;
}

export default function Pagination(props: Props) {
	const pages = useMemo(() => {
		const pages = [];
		const pageCount = Math.ceil(props.count / props.perPage);
		// 시작 페이지를 계산 (최소값은 1)
		let startPage = Math.max(props.page - 2, 1);
		// 종료 페이지를 계산 (최대값은 전체 페이지 수)
		let endPage = Math.min(startPage + 4, pageCount);

		// 마지막 페이지 근처에서 시작 페이지를 조정
		if (endPage === pageCount) {
			startPage = Math.max(endPage - 4, 1);
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(i);
		}
		return pages;
	}, [props.page, props.count, props.perPage]);

	return (
		<div className={props.className}>
			<div className="flex justify-center gap-5">
				<button
					disabled={props.page === 1}
					className="  rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
					onClick={() => props.onChange(Math.max(1, props.page - 1))}>
					<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M9.70646 2.48104C9.88951 2.6641 9.88951 2.9609 9.70646 3.14396L5.35041 7.5L9.70646 11.856C9.88951 12.0391 9.88951 12.3359 9.70646 12.519C9.5234 12.702 9.2266 12.702 9.04354 12.519L4.35604 7.83146C4.17299 7.6484 4.17299 7.3516 4.35604 7.16854L9.04354 2.48104C9.2266 2.29799 9.5234 2.29799 9.70646 2.48104Z"
							fill="#999999"
						/>
					</svg>
				</button>
				{pages.map((page) => (
					<button
						key={page}
						className={`rounded-md text-sm   hover:bg-gray-50 ${props.page === page ? "font-bold text-success" : " text-gray-700 font-normal"}`}
						onClick={() => props.onChange(page)}>
						{page}
					</button>
				))}
				<button
					disabled={props.page === Math.ceil(props.count / props.perPage)}
					className="  rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
					onClick={() => props.onChange(Math.min(props.page + 1, Math.ceil(props.count / props.perPage)))}>
					<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.29354 2.48104C5.4766 2.29799 5.7734 2.29799 5.95646 2.48104L10.644 7.16854C10.827 7.3516 10.827 7.6484 10.644 7.83146L5.95646 12.519C5.7734 12.702 5.4766 12.702 5.29354 12.519C5.11049 12.3359 5.11049 12.0391 5.29354 11.856L9.64959 7.5L5.29354 3.14396C5.11049 2.9609 5.11049 2.6641 5.29354 2.48104Z"
							fill="#999999"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
}
