import { useEffect, useState, useMemo } from "react";
import EasyTable from "../../components/EasyTable";
import { USER } from "../../admin_pages/api";
import EasyPagination from "../../components/EasyPagination";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";
import { DEFAULT as ADMIN_DEFAULT } from "../../admin_pages/api";

export default function AdminUsersPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [search, setSearch] = useState("");

	const [modal, setModal] = useState("update"); // 'add', 'edit', 'delete'

	useEffect(() => {
		getData();
	}, [page]);

	const columns = [
		{
			header: "ID",
			key: "id",
			render: (id: any) => (
				<button onClick={() => (window.location.href = `/admin/dashboard/users/${id}`)} className="text-blue-500">
					{id}
				</button>
			),
		},
		{
			header: "이름",
			key: "name",
		},
		{
			header: "닉네임",
			key: "nickname",
		},
		{
			header: "이메일",
			key: "email",
			render: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
		},
		{
			header: "전화번호",
			key: "phone",
		},
		{
			header: "성별",
			key: "genderText",
		},
		{
			header: "생일",
			key: "birthday",
			render: (birthday: string) => new Date(birthday).toLocaleDateString("ko-KR"),
		},
		{
			header: "MBTI",
			key: "mbti",
		},
		{
			header: "마케팅(Y)",
			key: "marketingAgreement",
			render: (date: string) => (date ? "Y" : "N"),
		},
		{
			header: "정보",
			key: "info",
			render: (info: string[]) => (info ?? []).join(", "),
		},
		{
			header: "여행 취향",
			key: "tripInfo",
			render: (tripInfo: string[]) => (tripInfo ?? []).join(", "),
		},
		{
			header: "삭제 여부",
			key: "deletedAt",
			render: (deletedAt: string) => `${deletedAt ? "Y" : "N"}`,
		},
		{
			header: "비밀번호 초기화",
			key: "id",
			render: (id: string) => (
				<button
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm"
					onClick={() => {
						alert('"123456789"로 초기화됩니다.');
						USER.resetPassword(+id);
					}}>
					초기화
				</button>
			),
		},
	];

	const getData = async () => {
		const response = await USER.paginate(page.page, page.perPage, search);

		setData(response.nodes);
		setCount(response.total);
	};

	// const filterd = useMemo(() => {
	// 	const currentData = currentPage * page.perPage;
	// 	if (search.length === 0) {
	// 		return data.slice(currentData - page.perPage, currentData);
	// 	}
	// 	else {
	// 		return data
	// 			.filter((data: any) => {
	// 				return data?.name?.includes(search) || data?.nickname?.includes(search) || data?.email?.includes(search);
	// 			})
	// 			.slice(currentData - page.perPage, currentData);
	// 	}
	// }, [data, currentPage, search]);

	const handleCreate = async (data: any) => {
		await USER.create(
			data.email,
			data.nickname,
			data.password,
			data.name,
			data.birthday,
			data.gender.value,
			data.phone,
			data.tripInfo.map((v: any) => v.value),
			data.info.map((v: any) => v.value),
			data.mbti.value,
			new Date(),
			new Date(),
			data.marketingAgreement?.value ? new Date() : null
		);
		setModal("");
		getData();
	};

	const handleGetUsers = async () => {
		getData();
	};

	return (
		<div>
			<h1>사용자 관리</h1>
			<div className="flex justify-end gap-4">
				<div className="border px-2.5 py-1 flex rounded-sm border-[#dadada]">
					<input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className="w-52 text-sm text-[#999] outline-none"
						type="search"
						placeholder="필터: 이름/닉네임/이메일/핸드폰"
					/>
					<button onClick={handleGetUsers}
						className="px-2 py-1 bg-indigo-500 text-sm text-white font-semibold rounded-md hover:bg-indigo-600 focus:outline-none">
						조회
					</button>
				</div>
				<button
					onClick={() =>
						ADMIN_DEFAULT.logUserExcel().then((res) => {
							window.open(res, "_blank");
						})
					}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					엑셀 다운로드
				</button>
				<button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable data={data} columns={columns} />
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>

				<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
					<div>추가</div>
					<div>
						<FormFactory
							schema={[
								{ label: "이메일", key: "email", method: "text", validation: "required" },
								{ label: "닉네임", key: "nickname", method: "text", validation: "required" },
								{ label: "이름", key: "name", method: "text", validation: "required" },
								{ label: "비밀번호", key: "password", method: "text", validation: "required" },
								{ label: "생년월일", key: "birthday", method: "text", placeholder: "1990-01-01", validation: (v: string) => /\d\d\d\d-\d\d-\d\d/.test(v) },
								{
									label: "성별",
									key: "gender",
									method: "select",
									selectOptions: [
										{ label: "남자", value: "Male" },
										{ label: "여자", value: "Female" },
									],
								},
								{
									label: "전화번호",
									key: "phone",
									method: "text",
									validation: (v: string) => /\d\d\d-\d\d\d\d-\d\d\d\d/.test(v),
									placeholder: "010-1234-5678",
								},
								{
									label: "여행성향",
									key: "tripInfo",
									method: "select-multi",
									validation: "required",
									selectOptions: [
										{ label: "휴양형", value: "휴양형" },
										{ label: "액티비티형", value: "액티비티형" },
										{ label: "도시형", value: "도시형" },
										{ label: "로컬형", value: "로컬형" },
										{ label: "랜드마크형", value: "랜드마크형" },
										{ label: "모험형", value: "모험형" },
									],
								},
								{
									label: "기호",
									key: "info",
									method: "select-multi",
									validation: "required",
									selectOptions: [
										{ label: "가벼운 음주 좋아요", value: "가벼운 음주 좋아요" },
										{ label: "흡연 괜찮아요", value: "흡연 괜찮아요" },
										{ label: "둘 다 상관없어요", value: "둘 다 상관없어요" },
										{ label: "둘 다 싫어요", value: "둘 다 싫어요" },
									],
								},
								{
									label: "MBTI",
									key: "mbti",
									method: "select",
									validation: "required",
									selectOptions: [
										{ label: "ISTJ", value: "ISTJ" },
										{ label: "ISFJ", value: "ISFJ" },
										{ label: "INFJ", value: "INFJ" },
										{ label: "INTJ", value: "INTJ" },
										{ label: "ISTP", value: "ISTP" },
										{ label: "ISFP", value: "ISFP" },
										{ label: "INFP", value: "INFP" },
										{ label: "INTP", value: "INTP" },
										{ label: "ESTP", value: "ESTP" },
										{ label: "ESFP", value: "ESFP" },
										{ label: "ENFP", value: "ENFP" },
										{ label: "ENTP", value: "ENTP" },
										{ label: "ESTJ", value: "ESTJ" },
										{ label: "ESFJ", value: "ESFJ" },
										{ label: "ENFJ", value: "ENFJ" },
										{ label: "ENTJ", value: "ENTJ" },
										{ label: "모름", value: "모름" },
									],
								},
								{
									label: "마케팅 동의",
									key: "marketingAgreement",
									method: "select",
									selectOptions: [
										{ label: "동의", value: true },
										{ label: "비동의", value: false },
									],
								},
							]}
							onSubmit={handleCreate}
						/>
					</div>
				</Modal>
			</div>
		</div>
	);
}
