import { useState } from "react";
import { Link } from 'react-router-dom';
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";

export default function Carousel({ slides, links }) {
  let [current, setCurrent] = useState(0);

  let previousSlide = () => {
    if (current === 0) setCurrent(slides.length - 1);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  let link = (event) => {
    var pos_prev   = getPosition( document.querySelector("#popup-prev") );
    var pos_next   = getPosition( document.querySelector("#popup-next") );
    var pos_circle = getPosition( document.querySelector("#popup-circle") );

    var isClickLink = true;
    if ((pos_prev.x + 30) < event.clientX && event.clientX < (pos_next.x - 10)) {
      console.log(`click link between prev and next of button`);
    }
    else {
      isClickLink = false;
    }

    if ((pos_circle.y - 10) > event.clientY) {
      console.log(`click link above circle of button`);
    }
    else {
      isClickLink = false;
    }

    if (isClickLink) {
      console.log('click the link');
      if (links[current]) {
        window.location.replace(links[current]);
      }
    }
  };

  let getPosition = (el) => {
    var xPos = 0;
    var yPos = 0;

    while (el) {
      if (el.tagName == "BODY") {
        var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        var yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += (el.offsetLeft - xScroll + el.clientLeft);
        yPos += (el.offsetTop - yScroll + el.clientTop);
      } else {
        xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
        yPos += (el.offsetTop - el.scrollTop + el.clientTop);
      }

      el = el.offsetParent;
    }
    return {
      x: xPos,
      y: yPos
    };
  }

  return (
    <div className="overflow-hidden relative"
      onClick={link}
    >
      <div className={`flex transition ease-out duration-40`}
        style={{
          transform: `translateX(-${current * 100}%)`,
        }}
      >
        {slides.map((s) => {
          return <img src={s} alt=""/>;
        })}
      </div>

      <div className="absolute top-0 h-full w-full justify-between items-center flex text-red-300 px-2 text-3xl">
        <button id="popup-prev" onClick={previousSlide}>
          <BsFillArrowLeftCircleFill />
        </button>
        <button id="popup-next" onClick={nextSlide}>
          <BsFillArrowRightCircleFill />
        </button>
      </div>

      <div id="popup-circle" className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
        {slides.map((s, i) => {
          return (
            <div
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + i}
              className={`rounded-full w-5 h-5 cursor-pointer  ${
                i === current ? "bg-white" : "bg-gray-500"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}