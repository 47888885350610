import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PARTNER_QUOTATION } from "../../../admin_pages/api";

export default function AdminPartnerQuotationsIdPage() {
	const { partner_quotation_id } = useParams();

	const [partnerQuotation, setPartnerQuotation] = useState<any>(null);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		if (partner_quotation_id) {
			PARTNER_QUOTATION.getOne(+partner_quotation_id).then((data) => {
				setPartnerQuotation(data);
			});
		}
	};

	return (
		<div>
			<h1 className="text-xl">파트너 견적 {partner_quotation_id}</h1>
			<div className="font-bold mt-4">정보</div>
			<div className="flex flex-col max-w-3xl font-medium">
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">id</div>
					<div>{partnerQuotation?.id}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">파트너 ID</div>
					<div>{partnerQuotation?.partnerId}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">주문 ID</div>
					<div>{partnerQuotation?.orderId}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">인보이스 상태</div>
					<div>{partnerQuotation?.invoiceStatus}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">확정날짜</div>
					<div>{partnerQuotation?.selectedAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">파트너 확정날짜</div>
					<div>{partnerQuotation?.partnerConfirmedAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">공급가</div>
					<div>{partnerQuotation?.initialQuotePrice}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">파트너 수수료</div>
					<div>{partnerQuotation?.partnerCommission}%</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">서비스 수수료</div>
					<div>{partnerQuotation?.serviceCommission}%</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">최종가</div>
					<div>{partnerQuotation?.finalQutotePrice}</div>
				</div>
			</div>
		</div>
	);
}
