import { useEffect, useState } from "react";
import { COMMUNITY_CLUP, DEFAULT } from "../api";
import MainCardCommunityClup from "../components/main/CardCommunityClup";
import { format } from "date-fns";
import TypeLabel from "../components/TypeLabel";

export default function AssembleParticipate() {
	const [data, setData] = useState<any[]>([]);
	const [img, setImg]: any = useState({});

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setImg(res);
		});
	}, []);

	useEffect(() => {
		COMMUNITY_CLUP.getMany("", 1, 50).then((res) => {
			setData(res.nodes);
		});
	}, []);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg xl:max-w-[1200px]">
				<div className="relative w-full h-[230px]  overflow-hidden">
					<div
						className="absolute inset-0 bg-center bg-cover w-full h-full blur-sm scale-125"
						style={{
							backgroundImage: `url(${img?.travelParticipationImageUrl})`,
						}}></div>
					<div
						dangerouslySetInnerHTML={{ __html: img?.travelParticipationText }}
						className="flex flex-col z-10 relative h-full items-center justify-center"></div>
				</div>

				<div className="px-3 mt-5">
					<div className="flex items-center text-[#202c4a] font-bold gap-1">
						{IconHand}
						모집중인 여정
					</div>

					<div className="text-[10px] font-medium mt-2.5">📢 여정을 떠나기 전에 미리 알아둬야하는 중요포인트!</div>
					<div className="flex text-black text-[9px] font-normal gap-1 items-center mt-2.5">
						<TypeLabel type="RECRUITMENT" /> 여행일정 및 상품가가 확정된 상품으로 모집인원 충족 시 출발이 확정되는 상품
					</div>
					<div className="flex text-black text-[9px] font-normal gap-1 items-center mt-2.5">
						<TypeLabel type="PLANNED" /> 여행일정 및 상품가가 미지정된 상품으로 모집인원이
						<br />
						함께 일정을 계획 및 견적 채택 후 출발하는 상품
					</div>

					<div
						className="flex justify-center items-center text-success rounded-sm border border-[#dadada] font-bold py-3 mt-4 cursor-pointer"
						onClick={() => (window.location.href = "/assemble_howToUse")}>
						모집/참여하기 이용방법
					</div>
					<div className="mt-8 grid grid-cols-2 gap-4">
						<div className="mt-8 grid grid-cols-1 gap-4 xl:grid-cols-2">
							{data
								.filter((e: any) => e.type === "RECRUITMENT")
								.slice(0, 2)
								.map((node) => {
									const images = node.CommunityClupFile.map((v: any) => v.File.url) as string[];
									const leader = node.participants.find((participant: any) => participant.isGroupLeader === true);
									const leaderImage = leader?.UserFiles.find((file: any) => file.type === "PROFILE")?.File.url;
									const startDate = format(new Date(node.startedAt), "yyyy.MM.dd");
									const endDate = format(new Date(node.endedAt), "yyyy.MM.dd");

									return (
										<MainCardCommunityClup
											data={{
												age: node.ageGroup,
												countComments: node.commentCount,
												countViews: node.viewCount,
												currentMember: node.participants?.length ?? 0,
												description: node.description,
												genderType: node.recruitGenderTypeText,
												id: node.id,
												images,
												maxMember: node.numberOfRecruits,
												nation: node.nation,
												orderType: node.type,
												startDate,
												endDate,
												title: node.title,
												tripType: node.tripType,
												userImage: leaderImage,
												userNickname: leader?.nickname,
											}}
											onClick={() => (window.location.href = `/community/${node.id}/details`)}
										/>
									);
								})}
						</div>
						<div className="mt-8 grid grid-cols-1 gap-4 xl:grid-cols-2">
							{data
								.filter((e: any) => e.type === "PLANNED")
								.slice(0, 2)
								.map((node) => {
									const images = node.CommunityClupFile.map((v: any) => v.File.url) as string[];
									const leader = node.participants.find((participant: any) => participant.isGroupLeader === true);
									const leaderImage = leader?.UserFiles.find((file: any) => file.type === "PROFILE")?.File.url;
									const startDate = format(new Date(node.startedAt), "yyyy.MM.dd");
									const endDate = format(new Date(node.endedAt), "yyyy.MM.dd");

									return (
										<MainCardCommunityClup
											data={{
												age: node.ageGroup,
												countComments: node.commentCount,
												countViews: node.viewCount,
												currentMember: node.participants?.length ?? 0,
												description: node.description,
												genderType: node.recruitGenderTypeText,
												id: node.id,
												images,
												maxMember: node.numberOfRecruits,
												nation: node.nation,
												orderType: node.type,
												startDate,
												endDate,
												title: node.title,
												tripType: node.tripType,
												userImage: leaderImage,
												userNickname: leader?.nickname,
											}}
											onClick={() => (window.location.href = `/community/${node.id}/details`)}
										/>
									);
								})}
						</div>
					</div>

					<div className="mt-8 grid grid-cols-2 gap-4 xl:grid-cols-4">
						<div
							onClick={() => {
								if (data.filter((e: any) => e.type === "RECRUITMENT").length === 0) {
									alert("주문이 없습니다. 계획 커뮤니티로 이동합니다.");
									window.location.href = "/community_planning";
								} else {
									window.location.href = "/community_recruit";
								}
							}}
							className="cursor-pointer border  border-[#dadada] rounded-sm text-success font-bold text-sm flex justify-center items-center py-3 xl:col-start-1 xl:col-end-3">
							모집 더 보러가기
						</div>
						<div
							onClick={() => (window.location.href = "/community_planning")}
							className="cursor-pointer border border-[#dadada] rounded-sm text-success font-bold text-sm flex justify-center items-center py-3 xl:col-start-3 xl:col-end-5">
							계획 더 보러가기
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const IconHand = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.6524 6.32421C10.0554 6.09098 10.5322 6.02029 10.9855 6.12658C11.4388 6.23287 11.8345 6.50813 12.0918 6.89618C12.0983 6.90599 12.1045 6.91599 12.1103 6.92618L13.3081 9.00466C14.037 10.2956 14.2269 11.8221 13.8363 13.2522C13.4458 14.6823 12.5065 15.9005 11.2226 16.6417C9.93878 17.383 8.41419 17.5875 6.98038 17.2107C5.54657 16.8339 4.31947 15.9064 3.5659 14.6297L3.56312 14.625L0.961584 10.1179C0.841568 9.90989 0.763638 9.68016 0.732366 9.44204C0.701095 9.20391 0.717031 8.96197 0.779264 8.73C0.841498 8.49804 0.948811 8.28061 1.09507 8.09012C1.24134 7.89963 1.42369 7.73982 1.63172 7.6198C1.83975 7.49979 2.06938 7.42192 2.3075 7.39065C2.54562 7.35938 2.78756 7.37531 3.01953 7.43755C3.25149 7.49978 3.46892 7.60709 3.65941 7.75336C3.8499 7.89962 4.00971 8.08197 4.12973 8.29C4.28497 8.55909 4.19268 8.90308 3.92359 9.05832C3.6545 9.21357 3.31051 9.12128 3.15527 8.85219C3.10908 8.77213 3.04758 8.70195 2.97427 8.64566C2.90096 8.58937 2.81728 8.54807 2.72801 8.52412C2.63874 8.50017 2.54562 8.49404 2.45398 8.50607C2.36234 8.51811 2.27397 8.54807 2.19391 8.59426C2.11385 8.64045 2.04367 8.70195 1.98738 8.77526C1.93109 8.84857 1.88979 8.93225 1.86584 9.02152C1.84189 9.1108 1.83575 9.20391 1.84779 9.29555C1.85982 9.38715 1.88976 9.47548 1.93592 9.55551C1.93589 9.55548 1.93594 9.55555 1.93592 9.55551L4.53607 14.0601C5.13904 15.0803 6.12011 15.8215 7.2663 16.1227C8.41335 16.4241 9.63302 16.2605 10.6601 15.6675C11.6872 15.0745 12.4386 14.0999 12.7511 12.9558C13.0633 11.8125 12.9118 10.5922 12.3296 9.5599L11.1457 7.5054C11.0466 7.36273 10.8981 7.26161 10.7287 7.22188C10.5543 7.181 10.3709 7.20818 10.2159 7.29789C10.0609 7.3876 9.94602 7.53306 9.89462 7.70461C9.84321 7.87615 9.8592 8.06084 9.93932 8.221C10.0783 8.49884 9.96574 8.83674 9.6879 8.97572C9.41007 9.11471 9.07217 9.00215 8.93318 8.72431C8.72488 8.30789 8.68331 7.82769 8.81696 7.38168C8.95061 6.93566 9.24942 6.55745 9.6524 6.32421Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.51648 4.42347C3.7546 4.3922 3.99655 4.40813 4.22851 4.47037C4.46047 4.5326 4.6779 4.63991 4.86839 4.78618C5.05882 4.93239 5.21859 5.11467 5.33859 5.32261C5.33855 5.32253 5.33863 5.32268 5.33859 5.32261L8.01047 9.94917C8.16583 10.2182 8.07369 10.5622 7.80467 10.7176C7.53565 10.8729 7.19162 10.7808 7.03625 10.5118L4.36438 5.88522C4.31819 5.80516 4.25656 5.73477 4.18325 5.67848C4.10994 5.62219 4.02626 5.58089 3.93699 5.55694C3.84772 5.53299 3.75461 5.52686 3.66297 5.53889C3.57132 5.55092 3.48295 5.58089 3.40289 5.62708C3.32283 5.67327 3.25265 5.73477 3.19636 5.80808C3.14007 5.88139 3.09877 5.96507 3.07482 6.05434C3.05087 6.14362 3.04474 6.23673 3.05677 6.32837C3.0688 6.41993 3.09872 6.50823 3.14484 6.58823L5.81672 11.2148C5.97208 11.4838 5.87994 11.8278 5.61092 11.9832C5.3419 12.1386 4.99787 12.0464 4.8425 11.7774L2.17063 7.15085C2.05061 6.94282 1.97262 6.71298 1.94135 6.47485C1.91008 6.23673 1.92602 5.99479 1.98825 5.76282C2.05048 5.53086 2.1578 5.31343 2.30406 5.12294C2.45032 4.93245 2.63268 4.77264 2.8407 4.65262C3.04873 4.53261 3.27836 4.45474 3.51648 4.42347Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.59657 4.35731C6.0167 4.11492 6.51591 4.04936 6.98438 4.17505C7.45284 4.30074 7.85219 4.60738 8.09458 5.02751L11.0477 10.1463C11.2029 10.4153 11.1107 10.7593 10.8416 10.9146C10.5725 11.0698 10.2285 10.9775 10.0732 10.7084L7.12012 5.58969C7.02684 5.428 6.87315 5.31 6.69286 5.26162C6.51257 5.21325 6.32044 5.23848 6.15875 5.33177C5.99707 5.42505 5.87906 5.57874 5.83069 5.75903C5.78232 5.93932 5.80755 6.13144 5.90083 6.29313C6.05607 6.56222 5.96378 6.90621 5.69469 7.06145C5.4256 7.2167 5.08161 7.12441 4.92637 6.85532C4.68399 6.43519 4.61843 5.93598 4.74411 5.46751C4.8698 4.99904 5.17644 4.59969 5.59657 4.35731Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.22548 2.55027C4.64561 2.30789 5.14482 2.24233 5.61328 2.36801C6.08175 2.4937 6.4811 2.80034 6.72349 3.22047L6.7238 3.22102L7.44802 4.47961C7.60296 4.74888 7.51028 5.09276 7.24102 5.2477C6.97176 5.40264 6.62787 5.30996 6.47293 5.0407L5.74903 3.78266C5.74898 3.78258 5.74907 3.78273 5.74903 3.78266C5.65573 3.62109 5.50197 3.50293 5.32176 3.45459C5.14147 3.40622 4.94935 3.43145 4.78766 3.52473C4.62598 3.61801 4.50797 3.7717 4.45959 3.95199C4.41136 4.13179 4.43632 4.32336 4.52898 4.48478L4.6967 4.7713C4.85364 5.03941 4.76352 5.38397 4.49542 5.54091C4.22732 5.69785 3.88275 5.60774 3.72581 5.33963L3.55527 5.04829C3.31288 4.62816 3.24734 4.12894 3.37302 3.66047C3.49871 3.19201 3.80534 2.79265 4.22548 2.55027Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.6796 3.93128C11.9177 3.90001 12.1596 3.91594 12.3916 3.97818C12.6236 4.04041 12.841 4.14772 13.0315 4.29399C13.2219 4.44019 13.3817 4.62246 13.5017 4.83038C13.5016 4.8303 13.5017 4.83047 13.5017 4.83038L14.6974 6.8984C15.3388 8.01322 15.5793 9.31399 15.379 10.5844C15.1786 11.8549 14.5494 13.0185 13.596 13.8818C13.3658 14.0903 13.01 14.0727 12.8015 13.8424C12.593 13.6121 12.6106 13.2564 12.8409 13.0479C13.6039 12.357 14.1074 11.4259 14.2677 10.4092C14.428 9.3927 14.2357 8.35196 13.7226 7.45994C13.7225 7.45977 13.7224 7.4596 13.7223 7.45942L12.5276 5.3933C12.4814 5.31324 12.4196 5.24258 12.3463 5.18629C12.273 5.13 12.1893 5.0887 12.1001 5.06475C12.0108 5.0408 11.9177 5.03467 11.8261 5.0467C11.7344 5.05874 11.646 5.0887 11.566 5.13489C11.4859 5.18108 11.4157 5.24259 11.3594 5.3159C11.3032 5.38921 11.2619 5.47289 11.2379 5.56216C11.214 5.65143 11.2078 5.74454 11.2199 5.83618C11.2319 5.92782 11.2619 6.0162 11.308 6.09626C11.4633 6.36535 11.371 6.70934 11.1019 6.86458C10.8328 7.01982 10.4888 6.92753 10.3336 6.65844C10.2136 6.45042 10.1357 6.22079 10.1044 5.98267C10.0732 5.74455 10.0891 5.5026 10.1513 5.27064C10.2136 5.03868 10.3209 4.82124 10.4671 4.63075C10.6134 4.44026 10.7958 4.28045 11.0038 4.16043C11.2118 4.04042 11.4414 3.96255 11.6796 3.93128Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.98231 2.262C7.40244 2.01962 7.90165 1.95406 8.37012 2.07975C8.8385 2.20541 9.23779 2.51196 9.48019 2.93197L11.47 6.37728C11.6254 6.6463 11.5333 6.99033 11.2643 7.1457C10.9952 7.30107 10.6512 7.20894 10.4958 6.93993L8.50599 3.49462C8.41271 3.33293 8.25889 3.21469 8.0786 3.16632C7.89831 3.11795 7.70619 3.14318 7.5445 3.23646C7.38281 3.32974 7.2648 3.48343 7.21643 3.66372C7.16806 3.84401 7.19329 4.03614 7.28657 4.19782C7.44181 4.46691 7.34952 4.8109 7.08043 4.96615C6.81134 5.12139 6.46735 5.0291 6.31211 4.76001C6.06973 4.33988 6.00417 3.84067 6.12986 3.3722C6.25554 2.90374 6.56218 2.50438 6.98231 2.262Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.5757 0.250976C12.8759 0.330901 13.0545 0.639055 12.9746 0.939258L12.6863 2.02207C12.6064 2.32227 12.2982 2.50084 11.998 2.42092C11.6978 2.341 11.5192 2.03284 11.5992 1.73264L11.8874 0.649826C11.9674 0.349623 12.2755 0.171052 12.5757 0.250976Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.8852 1.32561C15.1395 1.50416 15.2008 1.85499 15.0223 2.10922L14.3754 3.03031C14.1969 3.28454 13.846 3.34589 13.5918 3.16735C13.3376 2.98881 13.2762 2.63798 13.4548 2.38375L14.1016 1.46266C14.2802 1.20843 14.631 1.14707 14.8852 1.32561Z"
			fill="#202C4A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.6744 3.115C16.853 3.36923 16.7916 3.72006 16.5374 3.8986L15.6163 4.54548C15.3621 4.72402 15.0112 4.66266 14.8327 4.40843C14.6542 4.15421 14.7155 3.80337 14.9697 3.62483L15.8908 2.97796C16.1451 2.79942 16.4959 2.86077 16.6744 3.115Z"
			fill="#202C4A"
		/>
	</svg>
);
