import Modal from "../components/modal";
import Inqury1 from "../image/inqury-1.png";
import Inqury2 from "../image/inqury-2.png";
import FaqQRCode from "../image/faq-qrcode.svg";
import { useState } from "react";

export default function InquiryPage() {
	const [modal, setModal] = useState<any>(false);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg flex flex-col items-center">
				<div className="text-black text-2xl font-bold text-center mt-12">궁금한게 있다면 물어보세요!</div>
				<div className="text-black text-sm font-medium text-center mt-2.5">
					사이테 이용에 필요한 기본정보는
					<br />
					[고객서비스]-[FAQ]를 통해 먼저 확인해 주세요
				</div>

				<div className="px-3 mt-6 cursor-pointer" onClick={() => setModal(true)}>
					<img src={Inqury1} />
				</div>

				<div className="px-3 mt-10 cursor-pointer" onClick={() => window.open("http://pf.kakao.com/_bLUZG/chat", "__blank")}>
					<img src={Inqury2} />
				</div>
			</div>

			<Modal open={modal} onClose={() => setModal(false)}>
				<div>
					<div className="text-black text-xl font-bold text-center">
						오더메이드트립
						<br />
						카카오톡 채팅 QR코드 보기
					</div>
					<div className="px-3 mt-4">
						<div className="border-b border-[#dadada]"></div>
					</div>

					<div className="mt-4 flex justify-center">
						<img src={FaqQRCode} />
					</div>

					<div className="px-3 mt-10 cursor-pointer" onClick={() => setModal(false)}>
						<div className="rounded-sm py-1.5 text-sm border border-[#dadada] text-center text-[#424549]">닫기</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
