import React, { useEffect, useState } from "react";
import { FILE } from "../../api";

interface InputImageProps {
	value?: string | null;
	onChange: (id: number) => void;
	className?: string;
	bgColor?: string;
}

export default function InputImage({ value, onChange, className = "", bgColor = "bg-white" }: InputImageProps) {
	// 이미지 URL을 위한 상태를 추가합니다.
	const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

	useEffect(() => {
		if (value) {
			setImagePreviewUrl(value);
		}
	}, [value]);

	// 파일을 선택하면 실행될 핸들러 함수입니다.
	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const reader = new FileReader();
		const file = e.target.files ? e.target.files[0] : null;

		// 파일을 읽고 미리보기 URL을 설정합니다.
		if (file) {
			reader.onloadend = () => {
				setImagePreviewUrl(reader.result as string);
			};
			reader.readAsDataURL(file);
			FILE.upload(file).then((res) => {
				onChange(res.id);
			});
		}
	};

	return (
		<div className={`flex items-center ${className}`}>
			<label className="relative h-[180px] flex-1 overflow-hidden rounded-[2px] border border-[#dadada] transition-all xl:h-[388px] xl:w-[133px] cursor-pointer">
				<input className="hidden" type="file" name="file" id="file" onChange={handleImageChange} />
				{imagePreviewUrl ? (
					// 이미지가 선택되었다면 미리보기를 꽉 차게 보여줍니다.
					<img src={imagePreviewUrl} alt="Preview" className="absolute inset-0 h-full w-full object-cover" />
				) : (
					// 사용자가 이미지를 선택하도록 유도하는 부분입니다.
					<div className="absolute inset-0 flex flex-col items-center justify-center bg-white">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M6.80662 2.0371C6.96118 1.80527 7.22137 1.66602 7.5 1.66602H12.5C12.7786 1.66602 13.0388 1.80527 13.1934 2.0371L14.6127 4.16602H17.5C18.163 4.16602 18.7989 4.42941 19.2678 4.89825C19.7366 5.36709 20 6.00297 20 6.66602V15.8327C20 16.4957 19.7366 17.1316 19.2678 17.6004C18.7989 18.0693 18.163 18.3327 17.5 18.3327H2.5C1.83696 18.3327 1.20107 18.0693 0.732233 17.6004C0.263392 17.1316 0 16.4957 0 15.8327V6.66602C0 6.00297 0.263392 5.36709 0.732233 4.89825C1.20107 4.42941 1.83696 4.16602 2.5 4.16602H5.38735L6.80662 2.0371ZM7.94599 3.33268L6.52671 5.4616C6.37215 5.69343 6.11196 5.83268 5.83333 5.83268H2.5C2.27899 5.83268 2.06702 5.92048 1.91074 6.07676C1.75446 6.23304 1.66667 6.445 1.66667 6.66602V15.8327C1.66667 16.0537 1.75446 16.2657 1.91074 16.4219C2.06702 16.5782 2.27899 16.666 2.5 16.666H17.5C17.721 16.666 17.933 16.5782 18.0893 16.4219C18.2455 16.2657 18.3333 16.0537 18.3333 15.8327V6.66602C18.3333 6.445 18.2455 6.23304 18.0893 6.07676C17.933 5.92048 17.721 5.83268 17.5 5.83268H14.1667C13.888 5.83268 13.6278 5.69343 13.4733 5.4616L12.054 3.33268H7.94599Z"
									fill="#777777"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.9987 8.33268C8.61799 8.33268 7.4987 9.45197 7.4987 10.8327C7.4987 12.2134 8.61799 13.3327 9.9987 13.3327C11.3794 13.3327 12.4987 12.2134 12.4987 10.8327C12.4987 9.45197 11.3794 8.33268 9.9987 8.33268ZM5.83203 10.8327C5.83203 8.5315 7.69751 6.66602 9.9987 6.66602C12.2999 6.66602 14.1654 8.5315 14.1654 10.8327C14.1654 13.1339 12.2999 14.9993 9.9987 14.9993C7.69751 14.9993 5.83203 13.1339 5.83203 10.8327Z"
									fill="#777777"
								/>
							</svg>
						</svg>
						<label className="mt-2.5 cursor-pointer font-spoqa text-[12px] font-medium tracking-[-0.32px] xl:text-[20px]" htmlFor="file">
							이미지 추가하기
						</label>
					</div>
				)}
				<label className="hidden" htmlFor="file">
					파일 선택
				</label>
			</label>
		</div>
	);
}
