import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import "./mem_customerRegister.scss";
import okLogo from "../image/o.svg";
import noLogo from "../image/x.svg";
import InputLabel from "../components/input/labe";
import InputEmail from "../components/input/email";
import InputText from "../components/input/text";
import InputPassword from "../components/input/password";
import InputBirthday from "../components/input/birthday";
import InputList from "../components/input/list";
import InputListFlex1 from "../components/input/list-flex-1";
import InputListCol2 from "../components/input/list-col-2-multi";
import InputCheckbox from "../components/input/checkbox";
import { AUTH, FILE, PARTNER, USER } from "../api";
// @ts-ignore
import { debounce } from "lodash";
import { emailDomainOptions } from "../contants";
import InputImage from "../components/input/image";
import InputTextarea from "../components/input/textarea";
import Modal from "../components/modal";

function parseDate(dateStr: string) {
	// 'YYYYMMDD' 형태의 문자열을 'YYYY-MM-DD'로 변환
	return dateStr.replace(/^(\d{4})(\d{2})(\d{2})$/, (match, year, month, day) => `${year}-${month}-${day}`);
}

function formatPhoneNumber(phoneNumber: string) {
	// 전화번호를 'XXX-XXXX-XXXX' 형식으로 변환합니다.
	var cleaned = ("" + phoneNumber).replace(/\D/g, ""); // 숫자가 아닌 문자를 제거합니다.
	var match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/); // 정규표현식으로 그룹을 나눕니다.
	if (match) {
		return [match[1], match[2], match[3]].join("-"); // 나눈 그룹을 '-'로 연결합니다.
	}
	return null; // 형식에 맞지 않는 번호인 경우 null을 반환합니다.
}

interface NewUserData {
	email: string;
	password: string;
	confirmPassword?: string; // 전송하면 안 됨
	nation: string;
	city: string;
	corporateBusinessName: string;
	address: string;
	landlinePhone: string;
	businessNumber: string;
	businessRegistrationFileId: number;
	accountFileId: number;
	name: string;
	position: string;
	mobilePhone: string;
	description: string;
}

const CustomerRegister = () => {
	const [businessRegistrationFileId, setBusinessRegistrationFileId] = useState<string>("");
	const [accountFileId, setAccountFileId] = useState<string>("");
	const [modal, setModal] = useState<string>("");

	const [newUserData, setNewUserData] = useState<NewUserData>({
		email: "",
		password: "",
		confirmPassword: "", // 전송하면 안 됨
		nation: "",
		city: "",
		corporateBusinessName: "",
		address: "",
		landlinePhone: "",
		businessNumber: "",
		businessRegistrationFileId: parseInt(businessRegistrationFileId),
		accountFileId: parseInt(accountFileId),
		name: "",
		position: "",
		mobilePhone: "",
		description: "",
	});

	const [errors, setErrors] = useState({
		email: "",
		nickname: "",
	});

	const [disableds, setDisableds] = useState({
		email: false,
		phone: false,
		userName: false,
		userBirthday: false,
	});

	// 중복검사 로직
	useEffect(() => {
		debounce(() => {
			PARTNER.checkEmail(newUserData.email)
				.then((response) => {
					return setErrors({
						...errors,
						email: "",
					});
				})
				.catch((e) => {
					if (e.response.status === 409) {
						return setErrors({
							...errors,
							email: "이미 사용중인 이메일입니다.",
						});
					}
				});
		}, 500)();
	}, [newUserData.email]);

	// const genderOptions = [
	// 	{ label: "남자", value: "Male" },
	// 	{ label: "여자", value: "Female" },
	// ];

	const handleChange = (key: string, value: any) => {
		setNewUserData({
			...newUserData,
			[key]: value,
		});
	};

	const checkPassword = useCallback((value: string) => {
		if (value.length < 8) {
			return false;
		} else if (!/[a-zA-Z]/.test(value) || !/[0-9]/.test(value)) {
			return false;
		}
		return true;
	}, []);

	const confirmPassword = useCallback(
		(value: string) => {
			if (value === "") {
				return false;
			} else if (value === newUserData.password) {
				return true;
			}
			return false;
		},
		[newUserData.password]
	);

	const isDisabled = useMemo(() => {
		const requiredFields: (keyof NewUserData)[] = [
			"email",
			"password",
			"confirmPassword",
			"name",
			"nation",
			"city",
			"corporateBusinessName",
			"address",
			"position",
			"landlinePhone",
			"mobilePhone",
			"businessNumber",
			"businessRegistrationFileId",
			"accountFileId",
		];

		for (const field of requiredFields) {
			if (newUserData[field] === "" || newUserData[field] === null || newUserData[field] === undefined) {
				return true;
			}
		}

		for (const error of Object.values(errors)) {
			if (error !== "") {
				return true;
			}
		}
		return false;
	}, [newUserData, errors]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		const phone = newUserData.mobilePhone;

		try {
			const response = await AUTH.partnerSignup(
				newUserData.email,
				newUserData.password,
				newUserData.name,
				newUserData.nation,
				newUserData.city,
				newUserData.corporateBusinessName,
				newUserData.address,
				newUserData.position,
				phone,
				newUserData.landlinePhone,
				newUserData.businessNumber,
				newUserData.description,
				newUserData.businessRegistrationFileId,
				newUserData.accountFileId
			);
			setModal("succes");
			// window.location.href = "/login";
		} catch (e) {
			alert("회원가입에 문제가 발생하였습니다.");
		}
	};
	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="mt-14 text-center text-xl font-bold">오더 메이드 트립 [파트너] 회원가입</div>
				<form onSubmit={handleSubmit} className="mt-14 px-3">
					<InputLabel title="이메일" required={true} />
					<InputEmail
						disabled={disableds.email}
						domainOptions={emailDomainOptions}
						value={newUserData.email}
						onChange={(v) => handleChange("email", v)}
						className="mt-2.5"
					/>
					{errors.email !== "" && <div className="flex items-center text-[10px] font-medium text-red-500">{errors.email}</div>}
					<InputLabel title="비밀번호" required={true} className="mt-5" />
					<InputPassword
						showConfirmText={checkPassword(newUserData.password)}
						confirmText="사용가능한 비밀번호입니다."
						unConfirmText="사용가능이 불가능한 비밀번호입니다."
						value={newUserData.password}
						className="mt-2.5"
						onChange={(v) => handleChange("password", v)}
					/>
					<InputLabel title="비밀번호확인" required={true} className="mt-5" />
					<InputPassword
						showConfirmText={confirmPassword(newUserData.confirmPassword ?? "")}
						confirmText="비밀번호가 일치합니다."
						unConfirmText="비밀번호가 일치하지않습니다."
						value={newUserData.confirmPassword ?? ""}
						className="mt-2.5"
						onChange={(v) => handleChange("confirmPassword", v)}
					/>
					<InputLabel title="이름" required={true} className="mt-5" />
					<InputText value={newUserData.name} onChange={(v) => handleChange("name", v)} className="mt-2.5" />
					<div className="flex justify-between gap-4">
						<div className="flex-1">
							<InputLabel title="국가" required={true} className="mt-5" />
							<InputText value={newUserData.nation} onChange={(v) => handleChange("nation", v)} className="mt-2.5" />
						</div>

						<div className="flex-1">
							<InputLabel title="도시" required={true} className="mt-5" />
							<InputText value={newUserData.city} onChange={(v) => handleChange("city", v)} className="mt-2.5" />
						</div>
					</div>
					<InputLabel title="법인사업자명" required={true} className="mt-5" />
					<InputText value={newUserData.corporateBusinessName} onChange={(v) => handleChange("corporateBusinessName", v)} className="mt-2.5" />
					<InputLabel title="사업장 주소" required={true} className="mt-5" />
					<InputText value={newUserData.address} onChange={(v) => handleChange("address", v)} className="mt-2.5" />
					<InputLabel title="직급/직책" required={true} className="mt-5" />
					<InputText value={newUserData.position} onChange={(v) => handleChange("position", v)} className="mt-2.5" />
					<InputLabel title="연락처 (M)" required={true} className="mt-5" />
					<InputText value={newUserData.mobilePhone} onChange={(v) => handleChange("mobilePhone", v)} className="mt-2.5" />
					<InputLabel title="연락처 (T)" required={true} className="mt-5" />
					<InputText value={newUserData.landlinePhone} onChange={(v) => handleChange("landlinePhone", v)} className="mt-2.5" />
					<InputLabel title="사업자번호" required={true} className="mt-5" />
					<InputText value={newUserData.businessNumber} onChange={(v) => handleChange("businessNumber", v)} className="mt-2.5" />
					<InputLabel title="사업자등록증" required={true} className="mt-5" />
					<InputImage onChange={(v) => handleChange("businessRegistrationFileId", v)} className="mt-2.5" />
					<InputLabel title="통장사본/은행코드" required={true} className="mt-5" />
					<InputImage onChange={(v) => handleChange("accountFileId", v)} className="mt-2.5" />
					<InputLabel title="회사소개" className="mt-5" />
					<InputTextarea value={newUserData.description} onChange={(v: any) => handleChange("description", v)} className="mt-2.5" />
					<div className="mt-10">
						<button
							disabled={isDisabled}
							type="submit"
							className="bg-success flex h-10 w-full items-center justify-center rounded-sm text-sm font-bold text-white disabled:bg-[#777]">
							회원가입
						</button>
					</div>
				</form>
			</div>

			<Modal
				open={modal === "succes"}
				onClose={() => {
					window.location.href = "/login";
				}}>
				<div
					className="flex justify-end"
					onClick={() => {
						window.location.href = "/login";
					}}>
					{IconClose}
				</div>
				<div className="text-center text-[80px]">🎊</div>
				<div className="font-bold text-black text-center">회원가입이 완료 되었습니다🤗</div>
				<div className="text-sm font-medium text-center leading-8 mt-6">
					지금부터 파트너분들의 다양한 여행을
					<br />
					오더 메이드 트립 크루에게 소개해주세요!
				</div>
			</Modal>
		</div>
	);
};

export default CustomerRegister;

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);
