// @ts-nocheck

import "./tiptap.scss";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Image from "@tiptap/extension-image";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useCallback } from "react";
import { FILE } from "../../api";

const MenuBar = () => {
	const { editor } = useCurrentEditor({});

	const addImage = useCallback(
		async (e) => {
			if (e.target?.files && e.target?.files?.length === 0) {
				return "";
			}
			const [file] = e.target.files;

			const uploaded = await FILE.upload(file);

			if (uploaded?.url) {
				editor.chain().focus().setImage({ src: uploaded.url }).run();
			}
		},
		[editor]
	);

	// console.log(editor);

	if (!editor) {
		return null;
	}

	return (
		<>
			<div class="m-1 flex items-center gap-0.5 rounded-lg bg-gray-100 p-1">
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleBold().run()}
					disabled={!editor.can().chain().focus().toggleBold().run()}
					className={(editor.isActive("bold") ? "is-active" : "") + " outline-none focus:outline-none border-r border-gray-200 w-10 h-10 hover:text-indigo-500 active:bg-gray-50"}>
					B
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleItalic().run()}
					disabled={!editor.can().chain().focus().toggleItalic().run()}
					className={(editor.isActive("italic") ? "is-active" : "") + " outline-none focus:outline-none border-r border-gray-200 w-10 h-10 hover:text-indigo-500 active:bg-gray-50"}>
					<i>I</i>
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleStrike().run()}
					disabled={!editor.can().chain().focus().toggleStrike().run()}
					className={(editor.isActive("strike") ? "is-active" : "") + " outline-none focus:outline-none border-r border-gray-200 w-10 h-10 mr-1 hover:text-indigo-500 active:bg-gray-50"}>
					<s>S</s>
				</button>
				<button type="button"
					onClick={() => editor.chain().focus().setParagraph().run()}
					className={(editor.isActive("paragraph") ? "is-active" : "") + " outline-none focus:outline-none border-r border-gray-200 w-10 h-10 mr-1 hover:text-indigo-500 active:bg-gray-50"}>
					P
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
					className={(editor.isActive("heading", { level: 1 }) ? "is-active" : "") + " outline-none focus:outline-none border-r border-gray-200 w-10 h-10 mr-1 hover:text-indigo-500 active:bg-gray-50"}>
					h1
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
					className={(editor.isActive("heading", { level: 2 }) ? "is-active" : "") + " outline-none focus:outline-none border-r border-gray-200 w-10 h-10 mr-1 hover:text-indigo-500 active:bg-gray-50"}>
					h2
				</button>
				<button type="button"
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					className={(editor.isActive("bulletList") ? "is-active" : "") + " flex-none"}>
					<svg
						strokeWidth="1.5"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						color="currentColor"
						className="h-5 w-5"
					>
						<path
						d="M8 6L20 6"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						></path>
						<path
						d="M4 6.01L4.01 5.99889"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						></path>
						<path
						d="M4 12.01L4.01 11.9989"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						></path>
						<path
						d="M4 18.01L4.01 17.9989"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						></path>
						<path
						d="M8 12L20 12"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						></path>
						<path
						d="M8 18L20 18"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						></path>
					</svg>
				</button>
				{/* <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
					En
				</button> */}
				<button type="button" onClick={() => editor.chain().focus().setColor("red").run()}
					className={["flex-none h-6 w-6 bg-red-500", "outline-none focus:outline-none border-l border-r border-gray-200 w-10 h-10 hover:text-indigo-500 active:bg-gray-50"].join(" ")}></button>
				<button type="button" onClick={() => editor.chain().focus().setColor("black").run()}
					className={["flex-none h-6 w-6 bg-black", "outline-none focus:outline-none border-l border-r border-gray-200 w-10 h-10 hover:text-indigo-500 active:bg-gray-50"].join(" ")}></button>
				{/* <button type="button" onClick={() => editor.chain().focus().setColor("white").run()}
					className={["flex-none h-5 w-6 bg-white", "outline-none focus:outline-none border-l border-r border-gray-200 w-10 h-10 hover:text-indigo-500 active:bg-gray-50"].join(" ")}></button> */}

				<label type="button" className="flex-none">
					<svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
					<input type="file" className="hidden" onChange={addImage} />
				</label>
			</div>
		</>
	);
};

const extensions = [
	Color.configure({ types: [TextStyle.name, ListItem.name] }),
	TextStyle.configure({ types: [ListItem.name] }),
	StarterKit.configure({
		bulletList: {
			keepMarks: true,
			keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
		orderedList: {
			keepMarks: true,
			keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
	}),
	Image.configure({
		inline: true,
	}),
];

export default ({ onChange, content = "" }: any) => {
	return (
		<div key={content} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light">
			<EditorProvider
				onUpdate={(editor) => (onChange ? onChange(editor.editor.getHTML()) : null)}
				editorProps={{
					attributes: {
						class: "min-h-[300px] w-full",
					},
				}}
				slotBefore={<MenuBar />}
				extensions={extensions}
				content={content}></EditorProvider>
		</div>
	);
};
