// src/axios.js
import { getToken } from "./auth";
import history from "./history";
import axios from "axios";

const client = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	// baseURL: "http://localhost:8080",
});

client.interceptors.request.use(
	(config) => {
		const token = getToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			history.push("/");
		}
		Promise.reject(error);
	}
);

export default client;
