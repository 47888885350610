import { useEffect, useState } from "react";
import { NOTICE } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";
import { DEFAULT } from "../../api";

export default function AdminNoticePage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const [modal, setModal] = useState("update"); // 'add', 'edit', 'delete'
	const [selected, setSelected] = useState<any>(null);
	const [tabMenues, setTabMenues] = useState<any>(null);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			const result = res.noticeCategories.reduce((acc: any, cur: any) => {
				acc.push({
					label: cur,
					value: cur,
				});

				return acc;
			}, []);

			setTabMenues([...result]);
		});
	}, []);

	const getData = () => {
		NOTICE.pagination(page.page, page.perPage).then((res) => {
			setData(res.nodes);
			setCount(res.count);
		});
	};

	const handleCreate = (formData: any) => {
		NOTICE.create({
			...formData,
		}).then((res) => {
			getData();
			setModal("");
		});
	};

	const handleUpdate = (formData: any) => {
		NOTICE.update(selected.id, {
			...formData,
			id: selected.id,
		}).then((res) => {
			getData();
			setModal("");
		});
	};

	const handleUpdateModal = (row: any) => {
		setSelected(row);
		setModal("update");
	};

	const handleDelete = (id: any) => {
		NOTICE.delete(id).then((res) => {
			getData();
		});
	};

	return (
		<div>
			<h1>공지사항 관리</h1>
			<div className="flex justify-end">
				<button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable
						data={data}
						columns={[
							{
								header: "ID",
								key: "id",
							},
							{
								header: "타입",
								key: "type",
							},
							{
								header: "제목",
								key: "title",
							},
							{
								header: "내용",
								key: "content",
								render: (value: any, row: any) => <div>{value.slice(0, 20)}</div>,
							},
							{
								header: "생성일",
								key: "createdAt",
							},
							{
								header: "수정일",
								key: "updatedAt",
							},
							{
								header: "수정",
								key: "update",
								render: (value: any, row: any, index: any) => (
									<button onClick={() => handleUpdateModal(row)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
										수정
									</button>
								),
							},
							{
								header: "삭제",
								key: "id",
								render: (id: any, row: any) => (
									<button onClick={() => handleDelete(id)} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
										삭제
									</button>
								),
							},
						]}
					/>
				</div>

				<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
					<div>추가</div>
					<div>
						<FormFactory
							schema={[
								{
									label: "타입",
									key: "type",
									method: "text",
									validation: "required",
								},
								{ label: "제목", key: "title", method: "text", validation: "required" },
								{ label: "내용", key: "content", method: "textarea", validation: "required" },
							]}
							onSubmit={handleCreate}></FormFactory>
					</div>
				</Modal>
				{selected && (
					<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
						<div className="mb-6">수정</div>
						<div>
							<FormFactory
								schema={[
									{
										label: "타입",
										key: "type",
										method: "text",
										validation: "required",
										defaultValue: tabMenues.find((option: any) => option.value === selected.type),
									},
									{ label: "제목", key: "title", method: "text", validation: "required", defaultValue: selected.title },
									{ label: "내용", key: "content", method: "textarea", validation: "required", defaultValue: selected.content },
								]}
								onSubmit={handleUpdate}
							/>
						</div>
					</Modal>
				)}
			</div>
		</div>
	);
}
