import { useEffect, useState } from "react";
import { DEFAULT } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";

export default function AdminVisitorsPage() {
	const [log, setLog] = useState<any>([]);
	useEffect(() => {
		DEFAULT.logCount().then((data) => {
			setLog(data);
		});
	}, []);

	return (
		<div>
			<h1>방문자 조회</h1>
			<div className="flex justify-end gap-4">
				<button
					onClick={() =>
						DEFAULT.logCountExcel().then((res) => {
							window.open(res, "_blank");
						})
					}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					엑셀 다운로드
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable
						data={log}
						columns={[
							{ header: "날짜", key: "date" },
							{ header: "방문자 수", key: "list" },
						]}
					/>
				</div>
			</div>
		</div>
	);
}
