import AssemblyBannerImage from "../image/assemble_howToUse/0.png";
import AssemblyHTUImage1 from "../image/assemble_howToUse/1.png";
import AssemblyHTUImage2 from "../image/assemble_howToUse/2.png";
import AssemblyHTUImage3 from "../image/assemble_howToUse/3.png";
import AssemblyHTUImage4 from "../image/assemble_howToUse/4.png";
import AssemblyHTUImage5 from "../image/assemble_howToUse/5.png";


// 코드가 짧으므로, 별도의 파일을 만들지 않기 위해 인라인 스타일 적용
export default function AssembleHowToUse() {
  return (
    <div className="flex w-full justify-center">

    <div className="container text-center disallowDrag flex flex-col items-center">
      <div className="p-3" style={{objectFit: "contain"}}>
        <img src={AssemblyBannerImage} style={{width: "100%", maxWidth: "35rem", objectFit: "contain"}} alt="커뮤니티 모집 배너 이미지" />
      </div>
      <hr style={{width: "100%", maxWidth: "35rem", display: "inline-block"}} />
      <div className="p-3" style={{objectFit: "contain"}}>
        <img src={AssemblyHTUImage1} style={{width: "100%", maxWidth: "35rem", objectFit: "contain"}} alt="커뮤니티 모집 도움말 이미지 #1" />
      </div>
      <hr style={{width: "100%", maxWidth: "35rem", display: "inline-block"}} />
      <div className="p-3" style={{objectFit: "contain"}}>
        <img src={AssemblyHTUImage2} style={{width: "100%", maxWidth: "35rem", objectFit: "contain"}} alt="커뮤니티 모집 도움말 이미지 #2" />
      </div>
      <hr style={{width: "100%", maxWidth: "35rem", display: "inline-block"}} />
      <div className="p-3" style={{objectFit: "contain"}}>
        <img src={AssemblyHTUImage3} style={{width: "100%", maxWidth: "35rem", objectFit: "contain"}} alt="커뮤니티 모집 도움말 이미지 #3" />
      </div>
      <hr style={{width: "100%", maxWidth: "35rem", display: "inline-block"}} />
      <div className="p-3" style={{objectFit: "contain"}}>
        <img src={AssemblyHTUImage4} style={{width: "100%", maxWidth: "35rem", objectFit: "contain"}} alt="커뮤니티 모집 도움말 이미지 #4" />
      </div>
      <hr style={{width: "100%", maxWidth: "35rem", display: "inline-block"}} />
      <div className="p-3" style={{objectFit: "contain"}}>
        <img src={AssemblyHTUImage5} style={{width: "100%", maxWidth: "35rem", objectFit: "contain"}} alt="커뮤니티 모집 도움말 이미지 #5" />
      </div>
    </div>
    </div>
  );
}