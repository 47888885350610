import { useEffect, useMemo, useState } from "react";
import { COMMUNITY_CLUP, NOTIFICATION, ORDER } from "../../api";

export default function MyPageUserNotification({ notification, onDone, title, onClose }: any) {
	const [order, setOrder] = useState<any>({});
	useEffect(() => {
		if (notification.referenceId) {
			COMMUNITY_CLUP.getOne(notification.referenceId).then((res: any) => {
				setOrder(res);
			});
		}
	}, [notification]);

	function calculateDuration(startedAt: string, endedAt: string) {
		const startDate = new Date(startedAt);
		const endDate = new Date(endedAt);

		// 두 날짜의 차이를 밀리초로 계산
		const differenceInTime = endDate.getTime() - startDate.getTime();

		// 밀리초를 일 수로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
		const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

		// 박 수는 일 수에서 1을 뺀 값
		return `${differenceInDays}박${differenceInDays + 1}일`;
	}

	// const handleAccept = async () => {
	// 	try {
	// 		await NOTIFICATION.read(notification.id);
	// 	} catch (e) {}

	// 	onDone();
	// };

	const handleReject = async () => {
		// try {
		// 	await NOTIFICATION.read(notification.id);
		// 	await COMMUNITY_CLUP.rejectParticipant(participant.communityClupId, notification.referenceId);
		// } catch (e) {}

		onDone();
	};

	return (
		<>
			<div
				className="flex justify-end"
				onClick={() => {
					onClose();
				}}>
				{IconClose}
			</div>
			<div
				className="text-success text-[10px] font-bold flex justify-end"
				onClick={async () => {
					window.location.href = `/community/${notification.referenceId}/details`;
				}}>
				상세글 보기
			</div>
			<div className="flex items-center gap-1.5">
				<div>{IconMessage}</div>
				<div className="flex flex-col">
					<div className="text-xs text-black font-medium">{title}</div>
					<div className="font-bold text-black"></div>
				</div>
			</div>

			<div className="border border-[#dadada] p-[10px] mb-[20px] flex rounded-[2px] gap-3">
				<div className="flex-1" onClick={() => {}}>
					<div
						className="w-full h-full bg-center bg-cover rounded-[2px] bg-gray-200 relative"
						style={{
							// 주문에 이미지가 있을 수 없음
							backgroundImage: `url(${order?.CommunityClupFile?.[0]?.File?.url || "none"})`,
						}}>
						{/* <div className="font-spoqa text-[10px] truncate break-normal text-[#f5f5f5] font-medium bg-[#355c7d] py-[4px] px-[9px] absolute top-[5px] left-[5px] max-w-[90%]">
								{order.nation}·{order.city}/{calculateDuration(order.startedAt, order.endedAt)}
							</div> */}
						<div className="flex justify-center items-center w-full h-full absolute inset-0 text-xs text-[#999]">
							{order?.CommunityClupFile?.[0]?.File?.url ? "" : "이미지 없음"}
						</div>
					</div>
				</div>
				<div className="flex-1 mt-1 flex flex-col">
					<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[8px] ">
						여행 형태:&nbsp;
						{order.type === "RECRUITMENT" && " 모집 주문"}
						{order.type === "ALONE" && " 단독 주문"}
						{order.type === "BUSINESS_TRIP" && " 출장 주문"}
					</div>
					<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">국가:&nbsp;{order.nation}</div>
					<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">도시:&nbsp;{order.city}</div>
					<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">
						{order.numberOfRecruits}명{`${order.type !== "RECRUITMENT" ? "" : "·" + order.recruitGenderTypeText}`}
						{`${order.type !== "RECRUITMENT" ? "" : "·" + order.ageGroup}`}
					</div>
					<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">
						{new Date(order.startedAt).toLocaleDateString().slice(0, -1)} ~ {new Date(order.endedAt).toLocaleDateString().slice(0, -1)}
					</div>
				</div>
			</div>

			<div
				onClick={async () => {
					window.location.href = `/community/${notification.referenceId}/details`;
				}}
				className="cursor-pointer mt-8 rounded-sm bg-success flex justify-center items-center text-sm text-white font-bold py-1.5">
				확인 하기
			</div>
			{/* 
			<div
				onClick={handleReject}
				className="cursor-pointer rounded-sm mt-2.5 border border-[#dadada] text-sm font-bold text-black flex justify-center py-1.5 items-center">
				거절
			</div> */}
		</>
	);
}

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconMessage = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M22 6.25V11.35C22 12.62 21.58 13.69 20.83 14.43C20.09 15.18 19.02 15.6 17.75 15.6V17.41C17.75 18.09 16.99 18.5 16.43 18.12L15.46 17.48C15.55 17.17 15.59 16.83 15.59 16.47V12.4C15.59 10.36 14.23 9 12.19 9H5.39999C5.25999 9 5.13 9.01002 5 9.02002V6.25C5 3.7 6.7 2 9.25 2H17.75C20.3 2 22 3.7 22 6.25Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.59 12.4V16.47C15.59 16.83 15.55 17.17 15.46 17.48C15.09 18.95 13.87 19.87 12.19 19.87H9.47L6.45 21.88C6 22.19 5.39999 21.86 5.39999 21.32V19.87C4.37999 19.87 3.53 19.53 2.94 18.94C2.34 18.34 2 17.49 2 16.47V12.4C2 10.5 3.18 9.19002 5 9.02002C5.13 9.01002 5.25999 9 5.39999 9H12.19C14.23 9 15.59 10.36 15.59 12.4Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconGender = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.5 5.68753C5.8269 5.68753 5.28125 6.23318 5.28125 6.90628C5.28125 7.57938 5.8269 8.12503 6.5 8.12503C7.1731 8.12503 7.71875 7.57938 7.71875 6.90628C7.71875 6.23318 7.1731 5.68753 6.5 5.68753ZM4.46875 6.90628C4.46875 5.78445 5.37817 4.87503 6.5 4.87503C7.62183 4.87503 8.53125 5.78445 8.53125 6.90628C8.53125 8.02811 7.62183 8.93753 6.5 8.93753C5.37817 8.93753 4.46875 8.02811 4.46875 6.90628Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.50002 8.93753C6.09008 8.93753 5.68577 9.03298 5.3191 9.21631C4.95244 9.39964 4.63349 9.66583 4.38752 9.99378C4.2529 10.1733 3.99827 10.2097 3.81877 10.075C3.63928 9.94041 3.6029 9.68577 3.73752 9.50628C4.05917 9.07742 4.47625 8.72933 4.95574 8.48959C5.43522 8.24984 5.96394 8.12503 6.50002 8.12503C7.0361 8.12503 7.56482 8.24984 8.04431 8.48959C8.52379 8.72933 8.94088 9.07742 9.26252 9.50628C9.39714 9.68577 9.36077 9.94041 9.18127 10.075C9.00178 10.2097 8.74714 10.1733 8.61252 9.99378C8.36656 9.66583 8.04761 9.39964 7.68095 9.21631C7.31428 9.03298 6.90997 8.93753 6.50002 8.93753Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.1562 1.21878C10.605 1.21878 10.9688 1.58255 10.9688 2.03128V10.9688C10.9688 11.4175 10.605 11.7813 10.1562 11.7813H2.84375C2.39502 11.7813 2.03125 11.4175 2.03125 10.9688V2.03128C2.03125 1.58255 2.39502 1.21878 2.84375 1.21878L10.1562 1.21878ZM10.1562 10.9688V2.03128L2.84375 2.03128V10.9688H10.1562Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.46875 3.25003C4.46875 3.02566 4.65063 2.84378 4.875 2.84378H8.125C8.34937 2.84378 8.53125 3.02566 8.53125 3.25003C8.53125 3.4744 8.34937 3.65628 8.125 3.65628H4.875C4.65063 3.65628 4.46875 3.4744 4.46875 3.25003Z"
			fill="#777777"
		/>
	</svg>
);

const IconAge = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.5 2.03128C4.03198 2.03128 2.03125 4.03201 2.03125 6.50003C2.03125 8.96805 4.03198 10.9688 6.5 10.9688C8.96802 10.9688 10.9688 8.96805 10.9688 6.50003C10.9688 4.03201 8.96802 2.03128 6.5 2.03128ZM1.21875 6.50003C1.21875 3.58328 3.58325 1.21878 6.5 1.21878C9.41675 1.21878 11.7812 3.58328 11.7812 6.50003C11.7812 9.41678 9.41675 11.7813 6.5 11.7813C3.58325 11.7813 1.21875 9.41678 1.21875 6.50003Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.5 4.46878C5.60254 4.46878 4.875 5.19632 4.875 6.09378C4.875 6.99124 5.60254 7.71878 6.5 7.71878C7.39746 7.71878 8.125 6.99124 8.125 6.09378C8.125 5.19632 7.39746 4.46878 6.5 4.46878ZM4.0625 6.09378C4.0625 4.74759 5.15381 3.65628 6.5 3.65628C7.84619 3.65628 8.9375 4.74759 8.9375 6.09378C8.9375 7.43997 7.84619 8.53128 6.5 8.53128C5.15381 8.53128 4.0625 7.43997 4.0625 6.09378Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.50048 8.53098C5.9003 8.53098 5.31185 8.69717 4.80034 9.01113C4.28883 9.3251 3.87426 9.77457 3.60256 10.3097C3.50099 10.5098 3.25647 10.5896 3.05642 10.4881C2.85636 10.3865 2.77651 10.142 2.87808 9.94191C3.2177 9.27296 3.73592 8.71113 4.37531 8.31867C5.01469 7.92622 5.75026 7.71848 6.50048 7.71848C7.2507 7.71848 7.98627 7.92622 8.62565 8.31867C9.26503 8.71113 9.78325 9.27296 10.1229 9.94191C10.2244 10.142 10.1446 10.3865 9.94454 10.4881C9.74448 10.5896 9.49996 10.5098 9.39839 10.3097C9.1267 9.77457 8.71212 9.3251 8.20061 9.01113C7.68911 8.69717 7.10065 8.53098 6.50048 8.53098Z"
			fill="#777777"
		/>
	</svg>
);

const IconPeople = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.5 5.48441C5.60254 5.48441 4.875 6.21194 4.875 7.10941C4.875 8.00687 5.60254 8.73441 6.5 8.73441C7.39746 8.73441 8.125 8.00687 8.125 7.10941C8.125 6.21194 7.39746 5.48441 6.5 5.48441ZM4.0625 7.10941C4.0625 5.76321 5.15381 4.67191 6.5 4.67191C7.84619 4.67191 8.9375 5.76321 8.9375 7.10941C8.9375 8.4556 7.84619 9.54691 6.5 9.54691C5.15381 9.54691 4.0625 8.4556 4.0625 7.10941Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.1346 6.57461C10.768 6.39132 10.3637 6.29622 9.9538 6.29691C9.72944 6.29728 9.54725 6.1157 9.54688 5.89134C9.5465 5.66697 9.72808 5.48478 9.95245 5.48441C10.4889 5.48351 11.0181 5.60798 11.4979 5.84789C11.9778 6.08779 12.3949 6.4365 12.716 6.8662C12.8504 7.04592 12.8135 7.30049 12.6338 7.43481C12.4541 7.56913 12.1995 7.53233 12.0652 7.35261C11.8199 7.02432 11.5012 6.7579 11.1346 6.57461Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.04622 6.29691C2.63637 6.29622 2.23202 6.39132 1.86544 6.57461C1.49885 6.7579 1.18017 7.02432 0.934807 7.35261C0.800488 7.53233 0.545911 7.56913 0.366193 7.43481C0.186476 7.30049 0.149673 7.04592 0.283992 6.8662C0.605144 6.4365 1.02226 6.08779 1.50208 5.84789C1.98189 5.60798 2.51113 5.48351 3.04758 5.48441C3.27194 5.48478 3.45352 5.66697 3.45315 5.89134C3.45277 6.1157 3.27059 6.29728 3.04622 6.29691Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.49931 9.54819C5.96569 9.54819 5.4428 9.69806 4.99021 9.98073C4.53761 10.2634 4.1735 10.6675 3.93937 11.147C3.84092 11.3486 3.59767 11.4323 3.39606 11.3338C3.19444 11.2354 3.11081 10.9921 3.20926 10.7905C3.51017 10.1742 3.97812 9.65489 4.5598 9.2916C5.14148 8.9283 5.8135 8.73569 6.49931 8.73569C7.18512 8.73569 7.85714 8.9283 8.43882 9.2916C9.0205 9.65489 9.48845 10.1742 9.78936 10.7905C9.88781 10.9921 9.80418 11.2354 9.60256 11.3338C9.40095 11.4323 9.1577 11.3486 9.05926 11.147C8.82512 10.6675 8.46101 10.2634 8.00841 9.98073C7.55582 9.69806 7.03293 9.54819 6.49931 9.54819Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.34761 3.08509C3.12354 3.02764 2.88774 3.03515 2.66778 3.10673C2.44782 3.17831 2.25277 3.31101 2.10542 3.48933C1.95807 3.66765 1.86452 3.88421 1.83568 4.11373C1.80684 4.34324 1.84392 4.57622 1.94258 4.78545C2.04123 4.99467 2.19739 5.1715 2.39281 5.29527C2.58823 5.41904 2.81484 5.48464 3.04616 5.48441C3.27052 5.48418 3.45259 5.66588 3.45282 5.89024C3.45305 6.11461 3.27135 6.29668 3.04698 6.2969C2.66145 6.2973 2.28377 6.18796 1.95807 5.98168C1.63237 5.7754 1.3721 5.48068 1.20768 5.13197C1.04325 4.78327 0.981458 4.39496 1.02952 4.01244C1.07758 3.62992 1.23351 3.26897 1.47908 2.97178C1.72466 2.67458 2.04974 2.45341 2.41635 2.33411C2.78296 2.21481 3.17595 2.2023 3.5494 2.29805C3.92285 2.3938 4.26134 2.59385 4.52532 2.87482C4.7893 3.1558 4.96786 3.5061 5.04015 3.8848C5.08222 4.10518 4.93766 4.31794 4.71728 4.36001C4.49689 4.40208 4.28413 4.25753 4.24206 4.03714C4.19869 3.80992 4.09155 3.59974 3.93316 3.43116C3.77477 3.26257 3.57168 3.14254 3.34761 3.08509Z"
			fill="#777777"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.3328 3.10673C10.1128 3.03515 9.87702 3.02764 9.65295 3.08509C9.42887 3.14254 9.22578 3.26257 9.06739 3.43116C8.90901 3.59974 8.80187 3.80992 8.75849 4.03714C8.71643 4.25753 8.50366 4.40208 8.28328 4.36001C8.06289 4.31794 7.91834 4.10518 7.9604 3.8848C8.03269 3.5061 8.21126 3.1558 8.47524 2.87482C8.73921 2.59385 9.0777 2.3938 9.45116 2.29805C9.82461 2.2023 10.2176 2.21481 10.5842 2.33411C10.9508 2.45341 11.2759 2.67458 11.5215 2.97178C11.767 3.26897 11.923 3.62992 11.971 4.01244C12.0191 4.39496 11.9573 4.78327 11.7929 5.13197C11.6284 5.48068 11.3682 5.7754 11.0425 5.98168C10.7168 6.18796 10.3391 6.2973 9.95357 6.2969C9.7292 6.29668 9.5475 6.11461 9.54773 5.89024C9.54796 5.66588 9.73003 5.48418 9.95439 5.48441C10.1857 5.48464 10.4123 5.41904 10.6077 5.29527C10.8032 5.1715 10.9593 4.99467 11.058 4.78545C11.1566 4.57622 11.1937 4.34324 11.1649 4.11373C11.136 3.88421 11.0425 3.66765 10.8951 3.48933C10.7478 3.31101 10.5527 3.17831 10.3328 3.10673Z"
			fill="#777777"
		/>
	</svg>
);

const IconHeart = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M18.334 14.3334C18.334 15.0834 18.1257 15.7917 17.7507 16.3917C17.059 17.55 15.7923 18.3334 14.334 18.3334C12.8757 18.3334 11.6006 17.55 10.9173 16.3917C10.5506 15.7917 10.334 15.0834 10.334 14.3334C10.334 12.125 12.1257 10.3334 14.334 10.3334C16.5423 10.3334 18.334 12.125 18.334 14.3334Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M12.7754 14.3334L13.7587 15.3167L15.8921 13.35" stroke="#0E1111" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M18.3327 7.24167C18.3327 8.88334 17.9077 10.3333 17.241 11.5916C16.5077 10.8166 15.4743 10.3334 14.3327 10.3334C12.1243 10.3334 10.3327 12.125 10.3327 14.3333C10.3327 15.3583 10.7243 16.2917 11.3577 17C11.0493 17.1417 10.766 17.2583 10.516 17.3417C10.2327 17.4417 9.76602 17.4417 9.48268 17.3417C7.06602 16.5167 1.66602 13.075 1.66602 7.24167C1.66602 4.66667 3.74102 2.58331 6.29935 2.58331C7.80768 2.58331 9.15768 3.31668 9.99935 4.44168C10.841 3.31668 12.191 2.58331 13.6993 2.58331C16.2577 2.58331 18.3327 4.66667 18.3327 7.24167Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconA = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M4.10156 16.9L5.57657 18.0417C5.76823 18.2334 6.19323 18.325 6.4849 18.325H8.29323C8.86823 18.325 9.4849 17.9 9.62657 17.325L10.7682 13.85C11.0099 13.1833 10.5766 12.6083 9.8599 12.6083H7.95156C7.66823 12.6083 7.42656 12.3666 7.47656 12.0333L7.71823 10.5083C7.8099 10.0833 7.52656 9.59998 7.10156 9.45831C6.71823 9.31665 6.24323 9.50833 6.05157 9.79167L4.10156 12.7"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-miterlimit="10"
		/>
		<path
			d="M1.66797 16.9001V12.2334C1.66797 11.5667 1.9513 11.325 2.61797 11.325H3.09297C3.75963 11.325 4.04297 11.5667 4.04297 12.2334V16.9001C4.04297 17.5667 3.75963 17.8084 3.09297 17.8084H2.61797C1.9513 17.8084 1.66797 17.5751 1.66797 16.9001Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.902 3.09996L14.427 1.95829C14.2353 1.76663 13.8103 1.67499 13.5186 1.67499H11.7103C11.1353 1.67499 10.5186 2.09995 10.377 2.67495L9.2353 6.14998C8.99364 6.81665 9.42697 7.39164 10.1436 7.39164H12.052C12.3353 7.39164 12.577 7.63336 12.527 7.96669L12.2853 9.49166C12.1936 9.91666 12.477 10.4 12.902 10.5417C13.2853 10.6833 13.7603 10.4916 13.952 10.2083L15.902 7.29999"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-miterlimit="10"
		/>
		<path
			d="M18.3359 3.09996V7.7666C18.3359 8.43327 18.0526 8.67501 17.3859 8.67501H16.9109C16.2443 8.67501 15.9609 8.43327 15.9609 7.7666V3.09996C15.9609 2.43329 16.2443 2.19165 16.9109 2.19165H17.3859C18.0526 2.19165 18.3359 2.42496 18.3359 3.09996Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconB = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M14.166 15.3584H10.8327L7.12434 17.825C6.57434 18.1917 5.83268 17.8001 5.83268 17.1334V15.3584C3.33268 15.3584 1.66602 13.6917 1.66602 11.1917V6.19169C1.66602 3.69169 3.33268 2.02502 5.83268 2.02502H14.166C16.666 2.02502 18.3327 3.69169 18.3327 6.19169V11.1917C18.3327 13.6917 16.666 15.3584 14.166 15.3584Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.0005 9.46667V9.29171C10.0005 8.72504 10.3505 8.42503 10.7005 8.18336C11.0422 7.95003 11.3838 7.65004 11.3838 7.10004C11.3838 6.33337 10.7672 5.71667 10.0005 5.71667C9.23383 5.71667 8.61719 6.33337 8.61719 7.10004"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M9.9969 11.4584H10.0044" stroke="#0E1111" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);
