import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ORDER } from "../../../admin_pages/api";
import EasyTable from "../../../components/EasyTable";
import FormFactory from "../../../components/FormFactory";
import Modal from "../../../components/modal";
import { format } from "date-fns";

export default function AdminOrdersIdPage() {
	const { order_id } = useParams();

	const [order, setOrder] = useState<any>(null);
	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		if (order_id) {
			ORDER.findOne(+order_id).then((v: any) => {
				setOrder(v);
			});
		}
	};

	const tripTypeOptions = [
		{ label: "모집형", value: "RECRUITMENT" },
		{ label: "단독형", value: "ALONE" },
		{ label: "출장형", value: "BUSINESS_TRIP" },
	];

	const recruitGenderTypeOptions = [
		{ label: "남자", value: "Male" },
		{ label: "여자", value: "Female" },
		{ label: "남여무관", value: "All" },
	];

	const booleanOptions = [
		{ label: "Y", value: true },
		{ label: "N", value: false },
	];

	const quotationReceivingMethodOptions = [
		{ label: "EMAIL", value: "EMAIL" },
		{ label: "KAKAO", value: "KAKAO" },
	];

	const handleUpdate = async (formData: any) => {
		const response = await ORDER.update(+order.id, {
			...formData,
			guideAvailability: formData.guideAvailability.value,
			quotationReceivingMethod: formData.quotationReceivingMethod.value,
			recruitGenderType: formData.recruitGenderType.value,
			type: formData.type.value,
		});
		setModal("");
		getData();
	};

	const handleDelete = async (id: number) => {
		try {
			const response = await ORDER.delete(id);
			alert("삭제됐습니다.");
			window.history.back();
		} catch (e: any) {
			alert(e.response.data.message);
		}
	};

	return (
		<div>
			<h1 className="text-xl">여행 주문 {order_id}</h1>
			<div className="font-bold mt-4">정보</div>
			<div className="flex flex-col max-w-3xl font-medium">
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">id</div>
					<div>{order?.id}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">userId</div>
					<div>{order?.userId}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">상태</div>
					<div>{order?.status}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">항공권 포함 여부</div>
					<div>{order?.isIncludeAirticket ? "Y" : "N"}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">이름</div>
					<div>{order?.name}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">이메일</div>
					<div>{order?.email}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">국가</div>
					<div>{order?.nation}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">도시</div>
					<div>{order?.city}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">시작 날짜</div>
					<div>{order?.startedAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">종료 날짜</div>
					<div>{order?.endedAt}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">예상 비용</div>
					<div>{order?.expenses}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">모집 인원</div>
					<div>{order?.numberOfRecruits}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">주문 유형</div>
					<div>{order?.type}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">모집 유형</div>
					<div>{order?.recruitGenderType}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">나이대</div>
					<div>{order?.ageGroup}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행 유형</div>
					<div>{order?.tripType}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">숙소</div>
					<div>{order?.accommodation}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">교통</div>
					<div>{order?.transportation}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">가이드 필요 유무</div>
					<div>{order?.guideAvailability ? "Y" : "N"}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">가이드 설명</div>
					<div>{order?.guideAvailabilityDesc}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">견적 수신 방법</div>
					<div>{order?.quotationReceivingMethod}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">견적 수신 대상</div>
					<div>{order?.quotationReceivingTarget}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">요청</div>
					<div>{order?.travelRequest}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">제목</div>
					<div>{order?.title}</div>
				</div>
			</div>

			<div className="flex w-full max-w-3xl justify-end gap-4">
				<button
					onClick={() => {
						handleDelete(order.id);
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
				<button
					onClick={() => {
						setModal("update");
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			</div>

			<div className="font-bold mt-4">견적 목록</div>
			<div className="mt-4 max-w-3xl overflow-x-scroll">
				{order?.PartnerQuotations ? (
					<EasyTable
						data={order?.PartnerQuotations}
						columns={[
							{ header: "id", key: "id" },
							{ header: "partnerId", key: "partnerId" },
							{ header: "사용자 확정 날짜", key: "selectedAt" },
							{ header: "파트너 컴펌 여부", key: "partnerConfirmedAt" },
							{
								header: "상세보기",
								key: "id",
								render: (id: any) => (
									<button
										onClick={() => (window.location.href = `/admin/dashboard/partner-quotations/${id}`)}
										className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
										상세보기
									</button>
								),
							},
						]}
					/>
				) : (
					"견적이 없습니다."
				)}
			</div>

			{order && (
				<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
					<div className="mb-6">수정</div>
					<FormFactory
						schema={[
							{ label: "이름", key: "name", method: "text", validation: "required", defaultValue: order?.name },
							{ label: "이메일", key: "email", method: "email", validation: "required", defaultValue: order?.email },
							{ label: "국가", key: "nation", method: "text", validation: "required", defaultValue: order?.nation },
							{ label: "도시", key: "city", method: "text", validation: "required", defaultValue: order?.city },
							{
								label: "시작날짜",
								key: "startedAt",
								method: "date",
								validation: "required",
								defaultValue: order?.startedAt && format(new Date(order?.startedAt), "yyyy-MM-dd"),
							},
							{
								label: "종료날짜",
								key: "endedAt",
								method: "date",
								validation: "required",
								defaultValue: order?.startedAt && format(new Date(order?.endedAt), "yyyy-MM-dd"),
							},
							{ label: "비용", key: "expenses", method: "text", validation: "required", defaultValue: order?.expenses },
							{ label: "모집 인원", key: "numberOfRecruits", method: "text", validation: "required", defaultValue: order?.numberOfRecruits },
							{
								label: "주문 유형",
								key: "type",
								method: "select",
								selectOptions: tripTypeOptions,
								validation: "required",
								defaultValue: order?.type ? tripTypeOptions.find((v) => v.value === order?.type) : null,
							},
							{
								label: "모집 유형",
								key: "recruitGenderType",
								method: "select",
								selectOptions: recruitGenderTypeOptions,
								validation: "required",
								defaultValue: order?.recruitGenderType ? recruitGenderTypeOptions.find((v) => v.value === order?.recruitGenderType) : null,
							},
							{ label: "연령대", key: "ageGroup", method: "text", validation: "required", defaultValue: order?.ageGroup },
							{ label: "관광", key: "tripType", method: "text", validation: "required", defaultValue: order?.tripType },
							{ label: "호텔", key: "accommodation", method: "text", validation: "required", defaultValue: order?.accommodation },
							{ label: "교통", key: "transportation", method: "text", validation: "required", defaultValue: order?.transportation },

							{
								label: "가이드 유무",
								key: "guideAvailability",
								method: "select",
								selectOptions: booleanOptions,
								validation: "required",
								defaultValue: order?.guideAvailability ? booleanOptions.find((v) => v.value === order?.guideAvailability) : null,
							},
							{ label: "가이드 설명", key: "guideAvailabilityDesc", method: "text", validation: "required", defaultValue: order?.guideAvailabilityDesc },
							{
								label: "견적 수신 방식",
								key: "quotationReceivingMethod",
								method: "select",
								selectOptions: quotationReceivingMethodOptions,
								validation: "required",
								defaultValue: order?.quotationReceivingMethod ? quotationReceivingMethodOptions.find((v) => v.value === order?.quotationReceivingMethod) : null,
							},
							{
								label: "견적 수신 대상",
								key: "quotationReceivingTarget",
								method: "text",
								validation: "required",
								defaultValue: order?.quotationReceivingTarget,
							},

							{ label: "요청사항", key: "travelRequest", method: "text", validation: "required", defaultValue: order?.travelRequest },
							{ label: "제목", key: "title", method: "text", validation: "required", defaultValue: order?.title },
						]}
						onSubmit={(formData) => {
							handleUpdate(formData);
						}}
					/>
				</Modal>
			)}
		</div>
	);
}
