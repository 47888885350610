interface SelectProps {
	value: string | number;
	onChange: (value: string) => void;
	options: Array<{ value: string; label: string }>;
	className?: string;
}

export default function InputSelect({ value, onChange, options, className }: SelectProps) {
	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange(event.target.value);
	};

	return (
		<div className={`flex gap-3 ${className}`}>
			<select
				className="py-[6px] px-[7px] w-full h-[32px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] bg-white appearance-none cursor-pointer"
				value={value}
				onChange={handleChange}>
				<option value="" disabled hidden>
					선택해주세요
				</option>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
}
