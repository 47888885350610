import { useEffect, useState } from "react";
import IconUpload from "../icon/upload";
import InputImage from "../input/image";
import Modal from "../modal";
import InputText from "../input/text";
import IconLock from "../icon/lock";
import InputPassword from "../input/password";
import { PARTNER } from "../../api";

interface Props {
	value: string;
	onClose: () => void;
	onChange: (v: string) => void;
}

export default function MyPageChangePassword({ value, onClose, onChange }: Props) {
	const [password, setPassword] = useState({
		current: "",
		new: "",
		confirm: "",
	});

	const validatePassword = (password: string) => {
		// length >= 8
		if (password.length < 8) return false;

		// include number + alphabet
		const number = /[0-9]/;
		const alphabet = /[a-zA-Z]/;
		if (!number.test(password) || !alphabet.test(password)) return false;

		return true;
	};

	const [internalValue, setInternalValue] = useState("");

	useEffect(() => {
		setInternalValue(value);
	}, [value]);

	const handleChangePassword = async () => {
		try {
			await PARTNER.changePassword(password.current, password.new);
			alert("비밀번호 변경 완료");
			onClose();
		} catch (e) {
			alert("비밀번호 변경 실패");
		}
	};

	return (
		<Modal onClose={onClose} open={true}>
			<div className="flex gap-1 text-black">
				<IconLock /> 비밀번호 변경
			</div>
			<div className="mt-5">
				<div className="text-sm text-black">현재 비밀번호</div>
				<InputPassword
					className="mt-2.5"
					confirmText="사용가능한 비밀번호입니다."
					unConfirmText="비밀번호를 입력해주세요."
					showConfirmText={validatePassword(password.current)}
					value={password.current}
					onChange={(v) => setPassword({ ...password, current: v })}
					placeholder="현재 비밀번호"
				/>
			</div>

			<div className="mt-5">
				<div className="text-sm text-black">새 비밀번호</div>
				<InputPassword
					className="mt-2.5"
					confirmText="사용가능한 비밀번호입니다."
					unConfirmText="비밀번호를 입력해주세요."
					showConfirmText={validatePassword(password.new)}
					value={password.new}
					onChange={(v) => setPassword({ ...password, new: v })}
					placeholder="새 비밀번호"
				/>
			</div>

			<div className="mt-5">
				<div className="text-sm text-black">새 비밀번호 확인</div>
				<InputPassword
					className="mt-2.5"
					confirmText="비밀번호가 일치합니다."
					unConfirmText="비밀번호가 일치하지 않습니다."
					showConfirmText={password.new === password.confirm}
					value={password.confirm}
					onChange={(v) => setPassword({ ...password, confirm: v })}
					placeholder="새 비밀번호 확인"
				/>
			</div>
			<div onClick={handleChangePassword} className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
				확인
			</div>
			<div onClick={onClose} className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
				취소
			</div>
		</Modal>
	);
}
