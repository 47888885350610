import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DEFAULT, ORDER, ORDER_SCHEDULE, PARTNER, USER } from "../../api";
import { format, isSameDay, parseISO } from "date-fns";
import GoogleMap from "../../components/GoogleMap";
import Modal from "../../components/modal";
import axios from "axios";
import GoogleMapWithPoi from "../../components/GoogleMapWithPoI";
import { getToken } from "../../admin_pages/auth";

const KakaoShareButton = ({ image, title, text, url }: any) => {
	useEffect(() => {
		// @ts-ignore
		Kakao.Link.createDefaultButton({
			container: "#main_kakao",
			objectType: "feed",
			installTalk: true,
			content: {
				title,
				description: text,
				imageUrl: image,
				link: {
					webUrl: url,
					mobileWebUrl: url,
				},
			},
		});
	}, []);

	return (
		<>
			<div id="main_kakao" className="w-16 h-16">
				{IconKakao}
			</div>
			<div className="text-center text-black font-medium text-sm">카카오톡</div>
		</>
	);
};

export default function OrderSchedulePage() {
	const [onModal, setOnModal] = useState<any>();
	const [order, setOrder] = useState<any>({});
	const [latlng, setLatlng] = useState<any>({
		lat: 37.5665,
		lng: 126.978,
	});
	const [me, setMe] = useState<any>({});
	const [text, setText] = useState("");
	const [pois, setPois] = useState<any[]>([]);
	const [selectedDate, setSelectedDate] = useState<any>(null);
	const [selectedPoi, setSelectedPoi] = useState<any>(null);
	const [search, setSearch] = useState<any>(null);
	const [isPartner, setIsPartner] = useState<boolean>(false);
	const [admin, setAdmin] = useState<any>(null);

	const [orderSchedule, setOrderSchedule] = useState<any>(null);

	const [modal, setModal] = useState("");

	const params = useParams();

	useEffect(() => {
		axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${order.nation + " " + search}&key=AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o`)
			.then(async (v) => {
				if (v.data.status === "OK") {
					const location = v.data.results[0].geometry.location;

					const response = await DEFAULT.getGoogleNearbySearch(location.lat, location.lng, 2000, "AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o");
					setPois(response.results ?? []);
				} else {
					setPois([]);
				}
			});
	}, [search]);

	useEffect(() => {
		const orderId = params.order_id;

		if (!orderId) {
			alert("잘못된 접근입니다.");
			window.location.href = "/";
			return;
		}

		console.log(getToken());
		if (getToken()) {
			setAdmin(true);
		}

		ORDER_SCHEDULE.create(+orderId).then((res) => {
			setOrderSchedule(res);
			console.log(res);
		});
		ORDER.getOne(+orderId).then((data: any) => {
			setOrder(data);
		});

		PARTNER.getMe()
			.then((data: any) => {
				setIsPartner(!!data);
			})
			.catch((e) => {});

		USER.getUser()
			.then((data: any) => {
				console.log(data);
				setMe(data);
			})
			.catch((e) => {});
	}, []);

	useEffect(() => {
		axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${order.nation}&key=AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o`).then((v) => {
			if (v.data.status === "OK") {
				const location = v.data.results[0].geometry.location;
				setLatlng(location);
			}
		});
	}, [order]);

	const startDate = useMemo(() => {
		if (!order?.startedAt) {
			return "";
		}

		return format(new Date(order.startedAt), "yyyy.MM.dd");
	}, [order]);

	const endDate = useMemo(() => {
		if (!order?.endedAt) {
			return "";
		}

		return format(new Date(order.endedAt), "yyyy.MM.dd");
	}, [order]);

	const dates = useMemo(() => {
		if (!order?.startedAt || !order?.endedAt) {
			return [];
		}

		const start = new Date(order.startedAt);
		const end = new Date(order.endedAt);

		const dates = [];

		for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
			dates.push(new Date(d));
		}

		return dates;
	}, [order]);

	const show = useMemo(() => {
		if (admin) {
			return true;
		}

		if (isPartner) {
			return false;
		}

		if (me && me.id === order.userId) {
			return true;
		}

		return false;
	}, [isPartner, me, order, admin]);

	const handleSearch = useCallback(async () => {
		try {
			const _latlng = await axios
				.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${order.nation}&key=AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o`)
				.then((v) => {
					if (v.data.status === "OK") {
						const location = v.data.results[0].geometry.location;
						return location;
					}
				});
			const response = await DEFAULT.getGoogleNearbySearch(_latlng.lat, _latlng.lng, 2000, "AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o");

			setPois(response.results ?? []);
		} catch (e) {
			alert("근처의 관광지를 불러오는데 실패했습니다. 다시 시도해주세요.");
		}
	}, [latlng, order]);

	const deleteIndex = async (index: any) => {
		setOrderSchedule({
			...orderSchedule,
			OrderScheduleItem: [
				...orderSchedule.OrderScheduleItem.filter((v: any, i: number) => {
					return i !== index;
				}),
			],
		});
	};

	const handleAppendSchedule = async () => {
		setModal("");
		setOrderSchedule({
			...orderSchedule,
			OrderScheduleItem: [
				...orderSchedule.OrderScheduleItem.map((v: any) => {
					return {
						description: v.description,
						startedAt: v.startedAt,
						endedAt: v.endedAt,
						latitude: v.latitude,
						longitude: v.longitude,
						id: 0,
						label: v.label,
						title: v.title,
					};
				}),
				{
					description: "",
					startedAt: selectedDate.toISOString(),
					endedAt: selectedDate,
					latitude: selectedPoi.geometry.location.lat,
					longitude: selectedPoi.geometry.location.lng,
					id: 0,
					label: "string",
					title: selectedPoi.name,
				},
			],
		});

		setSearch("");
		// await ORDER_SCHEDULE.update(orderSchedule.id, {
		// 	items: [
		// 		...orderSchedule.OrderScheduleItem.map((v: any) => {
		// 			return {
		// 				description: v.description,
		// 				startedAt: v.startedAt,
		// 				endedAt: v.endedAt,
		// 				latitude: v.latitude,
		// 				longitude: v.longitude,
		// 				id: 0,
		// 				label: v.label,
		// 				title: v.title,
		// 			};
		// 		}),
		// 		{
		// 			description: "",
		// 			startedAt: selectedDate,
		// 			endedAt: selectedDate,
		// 			latitude: selectedPoi.geometry.location.lat,
		// 			longitude: selectedPoi.geometry.location.lng,
		// 			id: 0,
		// 			label: "string",
		// 			title: selectedPoi.name,
		// 		},
		// 	],
		// });
		// const orderId = params.order_id;
		// if (orderId) {
		// 	ORDER_SCHEDULE.create(+orderId).then(setOrderSchedule);
		// }
	};

	const handleSendAppendSchedule = async () => {
		await ORDER_SCHEDULE.update(orderSchedule.id, {
			items: [
				...orderSchedule.OrderScheduleItem.map((v: any) => {
					return {
						description: v.description,
						startedAt: v.startedAt,
						endedAt: v.endedAt,
						latitude: v.latitude,
						longitude: v.longitude,
						id: 0,
						label: v.label,
						title: v.title,
					};
				}),
			],
		});
		alert("저장되었습니다.");
	};

	const getGooglePhotoUrl = (photoReference: string) => {
		return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photoReference}&key=AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o`;
	};

	const renderPois = useMemo(() => {
		return dates.map((date: any, index) => {
			const dateStr = format(date, "MM.dd");

			const filtered =
				orderSchedule?.OrderScheduleItem?.filter((item: any) => {
					const target = parseISO(item.startedAt);
					return isSameDay(date, target);
				}) ?? [];

			return (
				<div className="px-3">
					<div className="flex items-center text-black font-bold gap-1">
						{IconCompass} {index + 1}일째 <span className="text-sm text-[#777] font-medium">{dateStr}</span>
					</div>
					{filtered.length > 0 && (
						<div className="h-[262px] mt-3">
							<GoogleMapWithPoi
								className="h-full"
								location={order.nation}
								pois={filtered.map((v: any) => {
									return {
										lat: v.latitude,
										lng: v.longitude,
									};
								})}
							/>
						</div>
					)}
					{filtered.length > 0 && (
						<div className="mt-5 gap-5 flex flex-col">
							{filtered.map((v: any, index: any) => {
								return (
									<div className="border border-[#dadada] rounded-[30px] flex py-2.5 px-4 gap-5 items-center">
										<div className="w-5 h-5 border-2 border-success rounded-full text-success text-sm font-medium flex justify-center items-center">
											{index + 1}
										</div>
										<div className="text-black font-medium line-clamp-1">{v.title}</div>
										{show && (
											<div onClick={() => deleteIndex(index)} className="text-red-500 flex-1 text-right cursor-pointer">
												삭제
											</div>
										)}
									</div>
								);
							})}
						</div>
					)}
					{show && filtered.length > 0 && (
						<div
							onClick={() => {
								setModal("SEARCH");
								handleSearch();
								setSelectedDate(date);
							}}
							className="border border-[#dadada]  rounded-[30px] flex py-2.5 px-4 gap-5 justify-center items-center font-normal text-sm mt-5 cursor-pointer">
							일정추가
						</div>
					)}
					{filtered.length === 0 && (
						<div
							onClick={() => {
								if (!show) {
									return false;
								}

								setModal("SEARCH");
								handleSearch();
								setSelectedDate(date);
							}}
							className="h-[100px] bg-[#ececec] text-[#777] text-sm font-medium flex justify-center items-center mt-3 cursor-pointer">
							일정이 없습니다.
						</div>
					)}
				</div>
			);
		});
	}, [orderSchedule, dates, me, admin]);
	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="mt-5">
					<div className="px-3 flex gap-1 text-success font-medium text-sm">{IconCalendar}일정표 제작</div>
					<div className="relative h-36 bg-cover bg-center overflow-hidden mt-5">
						<div
							className="absolute inset-0 bg-cover bg-center blur-sm scale-125"
							style={{ backgroundImage: "url('/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg')" }}></div>
						<div className="relative z-10 font-bold text-white w-full h-full flex justify-center items-center flex-col">
							<div className="px-16 line-clamp-2">{order?.title}</div>
							<div className="flex gap-4 mt-6 text-sm text-white font-medium">
								<span className="flex gap-2.5">
									{startDate}
									{IconCalendar2}
								</span>
								~
								<span className="flex gap-2.5">
									{endDate}
									{IconCalendar2}
								</span>
							</div>

							<div className="absolute right-3 top-12 cursor-pointer cursor-pointer" onClick={() => setOnModal("SHARE")}>
								{IconShare}
							</div>
						</div>
					</div>
					<div className=" text-black flex">
						<div className="flex gap-2.5 text-black flex-1 justify-center items-center h-20 font-medium">
							{IconEarth} {order.nation}
						</div>
						<div className="flex gap-2.5 text-black flex-1 justify-center items-center h-20 font-medium">
							{IconLocation} {order.city}
						</div>
					</div>
					<div className="px-3">
						<div className="w-full border border-[#dadada]"></div>
					</div>
					<div className="flex flex-col gap-8 mt-8">{renderPois}</div>
					<div className="px-3 mt-8">
						<div className=" w-full h-[1px] bg-[#dadada]"></div>
					</div>
					{show && (
						<div className="px-3 mt-8">
							<div onClick={handleSendAppendSchedule} className="w-full bg-success text-white font-bold flex justify-center items-center h-10 cursor-pointer">
								일정 등록하기
							</div>
						</div>
					)}
				</div>
			</div>
			<Modal open={onModal === "SHARE"} onClose={() => setOnModal("")}>
				<div className="flex items-center gap-1 font-medium">
					{IconShare}
					공유하기
				</div>
				<div className="flex justify-between mt-8">
					<div className="flex flex-col">
						<KakaoShareButton
							url={window.location.href}
							title={order?.title}
							text={order.nation + order.city}
							image={"https://www.ordermtrip.com/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg"}
						/>
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16 cursor-pointer"
							onClick={() => {
								window.open(`https://twitter.com/intent/tweet?text=${order?.title}+${window.location.href}`);
							}}>
							<img src="/images/20230725081132224942 1.png" />
						</div>
						<div className="text-center text-black font-medium text-sm">X</div>
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16 flex justify-center items-center cursor-pointer"
							onClick={() => {
								//@ts-ignore
								try {
									navigator.clipboard.writeText(window.location.href).then(() => {
										alert("복사 완료");
									});
								} catch (e) {}
							}}>
							{IconCopy}
						</div>
						<div className="text-center text-black font-medium text-sm">링크 복사</div>
						<input id="community_clup_id_copy" className="hidden" value={`${order?.title}\nhttps://www.ordermtrip.com`} />
					</div>
				</div>
				<div className="mt-5 font-bold text-white bg-success text-sm h-[30px] flex justify-center items-center">공유하기</div>
			</Modal>
			<Modal
				open={modal === "SEARCH"}
				onClose={() => {
					setModal("");
					setSearch("");
				}}>
				<div className="h-[300px] overflow-y-scroll scrollbar-hide">
					<div className="text-[#202c4a] text-lg font-bold">근처의 관광지</div>
					<div className="w-full border mt-2">
						<input value={search} placeholder="검색..." className="w-full px-2 py-1 text-sm" onChange={(e) => setSearch(e?.target.value)} />
					</div>
					{pois?.length === 0 && "검색 결과가 없습니다."}
					{pois.map((poi) => {
						return (
							<div
								className="py-4 border-b border-[#dedede] cursor-pointer"
								onClick={() => {
									setSelectedPoi(poi);
									setModal("DETAIL");
								}}>
								<div className="text-black text-sm font-medium">{poi.name}</div>
								<div className="text-[#777] text-xs font-medium">{poi.vicinity}</div>
							</div>
						);
					})}
				</div>
			</Modal>

			<Modal open={modal === "DETAIL"} onClose={() => setModal("")}>
				{(() => {
					const photo = selectedPoi?.photos?.[0];
					if (!selectedPoi) {
						return <></>;
					}

					// @ts-ignore
					const ratingStarArray = [1, 2, 3, 4, 5].map((v) => {
						if (v <= selectedPoi.rating) {
							return IconStar;
						} else {
							return IconStar2;
						}
					});
					return (
						<div className="h-[380px] overflow-y-scroll scrollbar-hide">
							<div
								className="h-44 bg-gray-200"
								style={{
									backgroundImage: photo ? `url(${getGooglePhotoUrl(photo.photo_reference)})` : "",
								}}>
								<div className="w-full h-full text-gray-500 font-normal text-sm flex justify-center items-center">이미지 없음</div>
							</div>

							<div className="text-black mt-4 flex items-center">
								<div>
									<div className="line-clamp-1 w-32 flex-none">{selectedPoi?.name}</div>
								</div>

								<div className="flex">{ratingStarArray}</div>
								<div className="text-[#777] text-xs font-medium ml-2">({selectedPoi.rating})</div>
							</div>

							<div className="border-b border-[#dadada] my-5"></div>

							<div className="flex gap-4 text-sm font-medium text-black">
								{IconLocation}
								{selectedPoi?.vicinity}
							</div>

							<div onClick={handleAppendSchedule} className="bg-success text-white text-lg py-4 text-center mt-8 rounded-sm cursor-pointer">
								일정표에 추가하기
							</div>
						</div>
					);
				})()}
			</Modal>
		</div>
	);
}

const IconStar = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			d="M6.22147 0.962979C6.30323 0.905716 6.40063 0.875 6.50045 0.875V10.5712C6.40913 10.5712 6.3197 10.5973 6.24264 10.6463L3.4946 12.3865C3.04342 12.6736 2.48092 12.2459 2.61569 11.7185L3.40671 8.60133C3.42984 8.50811 3.42616 8.41024 3.3961 8.31902C3.36603 8.22779 3.31081 8.14692 3.23678 8.08571L0.588346 5.87672C0.242643 5.58961 0.418424 5.00954 0.869596 4.98024L4.32663 4.75758C4.42095 4.75209 4.51164 4.71925 4.5876 4.66306C4.66356 4.60687 4.72151 4.52977 4.75436 4.44118L6.04342 1.19508C6.07755 1.10128 6.13971 1.02024 6.22147 0.962979Z"
			fill="#0057FF"
		/>
		<path
			d="M6.77853 0.962979C6.69677 0.905716 6.59937 0.875 6.49955 0.875V10.5712C6.59087 10.5712 6.6803 10.5973 6.75736 10.6463L9.5054 12.3865C9.95658 12.6736 10.5191 12.2459 10.3843 11.7185L9.59329 8.60133C9.57016 8.50811 9.57384 8.41024 9.6039 8.31902C9.63397 8.22779 9.68919 8.14692 9.76322 8.08571L12.4117 5.87672C12.7574 5.58961 12.5816 5.00954 12.1304 4.98024L8.67337 4.75758C8.57905 4.75209 8.48836 4.71925 8.4124 4.66306C8.33644 4.60687 8.27849 4.52977 8.24564 4.44118L6.95658 1.19508C6.92245 1.10128 6.86029 1.02024 6.77853 0.962979Z"
			fill="#0057FF"
		/>
	</svg>
);

const IconStar2 = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			d="M6.22147 0.962979C6.30323 0.905716 6.40063 0.875 6.50045 0.875V10.5712C6.40913 10.5712 6.3197 10.5973 6.24264 10.6463L3.4946 12.3865C3.04342 12.6736 2.48092 12.2459 2.61569 11.7185L3.40671 8.60133C3.42984 8.50811 3.42616 8.41024 3.3961 8.31902C3.36603 8.22779 3.31081 8.14692 3.23678 8.08571L0.588346 5.87672C0.242643 5.58961 0.418424 5.00954 0.869596 4.98024L4.32663 4.75758C4.42095 4.75209 4.51164 4.71925 4.5876 4.66306C4.66356 4.60687 4.72151 4.52977 4.75436 4.44118L6.04342 1.19508C6.07755 1.10128 6.13971 1.02024 6.22147 0.962979Z"
			fill="#DADADA"
		/>
		<path
			d="M6.77853 0.962979C6.69677 0.905716 6.59937 0.875 6.49955 0.875V10.5712C6.59087 10.5712 6.6803 10.5973 6.75736 10.6463L9.5054 12.3865C9.95658 12.6736 10.5191 12.2459 10.3843 11.7185L9.59329 8.60133C9.57016 8.50811 9.57384 8.41024 9.6039 8.31902C9.63397 8.22779 9.68919 8.14692 9.76322 8.08571L12.4117 5.87672C12.7574 5.58961 12.5816 5.00954 12.1304 4.98024L8.67337 4.75758C8.57905 4.75209 8.48836 4.71925 8.4124 4.66306C8.33644 4.60687 8.27849 4.52977 8.24564 4.44118L6.95658 1.19508C6.92245 1.10128 6.86029 1.02024 6.77853 0.962979Z"
			fill="#DADADA"
		/>
	</svg>
);

const IconSearch = (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
		<path
			d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
			stroke="#999999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M13.75 13.75L12.5 12.5" stroke="#999999" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconCompass = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M15.9054 8.29583L13.7604 15.2075C13.247 16.8483 10.9462 16.8758 10.4054 15.2442L9.7637 13.3467C9.58953 12.8242 9.17703 12.4025 8.65453 12.2375L6.74786 11.5958C5.12536 11.055 5.15286 8.73583 6.7937 8.24083L13.7054 6.08666C15.062 5.67416 16.3362 6.94833 15.9054 8.29583Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.2487 20.1667H13.7487C18.332 20.1667 20.1654 18.3333 20.1654 13.75V8.25001C20.1654 3.66668 18.332 1.83334 13.7487 1.83334H8.2487C3.66536 1.83334 1.83203 3.66668 1.83203 8.25001V13.75C1.83203 18.3333 3.66536 20.1667 8.2487 20.1667Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconCalendar = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M18.2763 5.63334L15.4679 16.9084C15.2679 17.75 14.5179 18.3333 13.6513 18.3333H2.70125C1.44291 18.3333 0.542927 17.1 0.917927 15.8916L4.42625 4.62504C4.66792 3.84171 5.39293 3.29997 6.2096 3.29997H16.4596C17.2513 3.29997 17.9096 3.7833 18.1846 4.44997C18.3429 4.8083 18.3763 5.21667 18.2763 5.63334Z"
			stroke="#0057FF"
			stroke-width="1.5"
			stroke-miterlimit="10"
		/>
		<path
			d="M13.332 18.3333H17.3154C18.3904 18.3333 19.232 17.425 19.157 16.35L18.332 5"
			stroke="#0057FF"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M8.06641 5.31667L8.93308 1.71671" stroke="#0057FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M13.6484 5.32501L14.4318 1.70837" stroke="#0057FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M6.41797 10H13.0846" stroke="#0057FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M5.58203 13.3333H12.2487" stroke="#0057FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconCalendar2 = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path d="M7.33203 1.83334V4.58334" stroke="#F5F5F5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M14.668 1.83334V4.58334" stroke="#F5F5F5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.20703 8.3325H18.7904" stroke="#F5F5F5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M19.25 7.79167V15.5833C19.25 18.3333 17.875 20.1667 14.6667 20.1667H7.33333C4.125 20.1667 2.75 18.3333 2.75 15.5833V7.79167C2.75 5.04167 4.125 3.20834 7.33333 3.20834H14.6667C17.875 3.20834 19.25 5.04167 19.25 7.79167Z"
			stroke="#F5F5F5"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M14.3869 12.5583H14.3951" stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M14.3869 15.3083H14.3951" stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M10.9963 12.5583H11.0045" stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M10.9963 15.3083H11.0045" stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.60174 12.5583H7.60997" stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.60174 15.3083H7.60997" stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconShare = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.5 7.3125C3.56802 7.3125 2.8125 8.06802 2.8125 9C2.8125 9.93198 3.56802 10.6875 4.5 10.6875C5.43198 10.6875 6.1875 9.93198 6.1875 9C6.1875 8.06802 5.43198 7.3125 4.5 7.3125ZM1.6875 9C1.6875 7.4467 2.9467 6.1875 4.5 6.1875C6.0533 6.1875 7.3125 7.4467 7.3125 9C7.3125 10.5533 6.0533 11.8125 4.5 11.8125C2.9467 11.8125 1.6875 10.5533 1.6875 9Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.375 12.375C11.443 12.375 10.6875 13.1305 10.6875 14.0625C10.6875 14.9945 11.443 15.75 12.375 15.75C13.307 15.75 14.0625 14.9945 14.0625 14.0625C14.0625 13.1305 13.307 12.375 12.375 12.375ZM9.5625 14.0625C9.5625 12.5092 10.8217 11.25 12.375 11.25C13.9283 11.25 15.1875 12.5092 15.1875 14.0625C15.1875 15.6158 13.9283 16.875 12.375 16.875C10.8217 16.875 9.5625 15.6158 9.5625 14.0625Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.375 2.25C11.443 2.25 10.6875 3.00552 10.6875 3.9375C10.6875 4.86948 11.443 5.625 12.375 5.625C13.307 5.625 14.0625 4.86948 14.0625 3.9375C14.0625 3.00552 13.307 2.25 12.375 2.25ZM9.5625 3.9375C9.5625 2.3842 10.8217 1.125 12.375 1.125C13.9283 1.125 15.1875 2.3842 15.1875 3.9375C15.1875 5.4908 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 5.4908 9.5625 3.9375Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.9561 4.84981C11.124 5.11116 11.0483 5.45918 10.787 5.62712L6.69479 8.25681C6.43344 8.42476 6.08542 8.34904 5.91748 8.08769C5.74953 7.82634 5.82525 7.47833 6.0866 7.31038L10.1788 4.68069C10.4401 4.51275 10.7882 4.58846 10.9561 4.84981Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.91748 9.91231C6.08542 9.65096 6.43344 9.57525 6.69479 9.74319L10.787 12.3729C11.0483 12.5408 11.124 12.8888 10.9561 13.1502C10.7882 13.4115 10.4401 13.4873 10.1788 13.3193L6.0866 10.6896C5.82525 10.5217 5.74953 10.1737 5.91748 9.91231Z"
			fill="#F5F5F5"
		/>
	</svg>
);

const IconEarth = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M10.9987 20.1667C16.0613 20.1667 20.1654 16.0626 20.1654 11C20.1654 5.93738 16.0613 1.83333 10.9987 1.83333C5.93609 1.83333 1.83203 5.93738 1.83203 11C1.83203 16.0626 5.93609 20.1667 10.9987 20.1667Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.33411 2.75H8.25078C6.46328 8.10333 6.46328 13.8967 8.25078 19.25H7.33411"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M13.75 2.75C15.5375 8.10333 15.5375 13.8967 13.75 19.25" stroke="#0E1111" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M2.75 14.6667V13.75C8.10333 15.5375 13.8967 15.5375 19.25 13.75V14.6667"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M2.75 8.25C8.10333 6.4625 13.8967 6.4625 19.25 8.25" stroke="#0E1111" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconLocation = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M11.0006 12.3108C12.5802 12.3108 13.8606 11.0304 13.8606 9.45084C13.8606 7.8713 12.5802 6.59084 11.0006 6.59084C9.42109 6.59084 8.14062 7.8713 8.14062 9.45084C8.14062 11.0304 9.42109 12.3108 11.0006 12.3108Z"
			stroke="#0E1111"
			stroke-width="1.2"
		/>
		<path
			d="M3.32018 7.7825C5.12602 -0.155836 16.8868 -0.146669 18.6835 7.79166C19.7377 12.4483 16.841 16.39 14.3018 18.8283C12.4593 20.6067 9.54435 20.6067 7.69268 18.8283C5.16268 16.39 2.26602 12.4392 3.32018 7.7825Z"
			stroke="#0E1111"
			stroke-width="1.2"
		/>
	</svg>
);

const IconKakao = (
	<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
		<rect width="64" height="64" rx="17" fill="#FFE812" />
		<path
			d="M31.9571 13C18.7259 13 8 21.2246 8 31.37C8 37.9292 12.4841 43.6846 19.2294 46.9346C18.8625 48.1654 16.8713 54.8525 16.792 55.3778C16.792 55.3778 16.7444 55.7726 17.0072 55.9231C17.27 56.0737 17.5792 55.9567 17.5792 55.9567C18.3329 55.8543 26.3196 50.3984 27.702 49.451C29.083 49.6412 30.505 49.74 31.9571 49.74C45.1884 49.74 55.9143 41.5156 55.9143 31.37C55.9143 21.2246 45.1884 13 31.9571 13Z"
			fill="black"
		/>
		<path
			d="M19.1946 36.3428C18.4079 36.3428 17.7682 35.8079 17.7682 35.1502V27.7313H15.5424C14.7705 27.7313 14.1426 27.1826 14.1426 26.5085C14.1426 25.8343 14.7707 25.2857 15.5424 25.2857H22.8468C23.6188 25.2857 24.2467 25.8343 24.2467 26.5085C24.2467 27.1826 23.6185 27.7313 22.8468 27.7313H20.6211V35.1502C20.6211 35.8079 19.9813 36.3428 19.1946 36.3428ZM31.7028 36.3266C31.1079 36.3266 30.6529 36.1151 30.5157 35.775L29.8094 34.1562L25.4596 34.1559L24.7528 35.7759C24.6161 36.1153 24.1613 36.3266 23.5665 36.3266C23.2536 36.3269 22.9443 36.2681 22.6597 36.1542C22.2665 35.9954 21.8885 35.5588 22.3217 34.3809L25.7337 26.5185C25.9741 25.9205 26.7042 25.3044 27.6333 25.2859C28.565 25.3042 29.2951 25.9205 29.536 26.5197L32.9466 34.3787C33.3807 35.5592 33.0027 35.9961 32.6095 36.1545C32.3248 36.2681 32.0156 36.3268 31.7028 36.3266ZM29.0593 31.9441L27.6345 28.4006L26.2097 31.9441H29.0593ZM35.2423 36.1607C34.4884 36.1607 33.8752 35.647 33.8752 35.016V26.5345C33.8752 25.8458 34.5286 25.2857 35.3314 25.2857C36.1343 25.2857 36.7876 25.8458 36.7876 26.5345V33.8712H39.8188C40.5727 33.8712 41.1858 34.3849 41.1858 35.016C41.1858 35.647 40.5727 36.1607 39.8188 36.1607H35.2423ZM43.1672 36.3266C42.3805 36.3266 41.7407 35.7665 41.7407 35.0778V26.5345C41.7407 25.8458 42.3805 25.2857 43.1672 25.2857C43.9539 25.2857 44.5937 25.8458 44.5937 26.5345V29.2186L48.5735 25.7344C48.7782 25.5552 49.0594 25.4566 49.3647 25.4566C49.7208 25.4566 50.0784 25.591 50.3463 25.8254C50.5962 26.0439 50.7453 26.3251 50.7657 26.6171C50.7864 26.9116 50.6744 27.1816 50.4509 27.3774L47.2003 30.2228L50.7115 34.2952C50.9405 34.5591 51.0391 34.8923 50.9854 35.2203C50.934 35.5486 50.7347 35.8452 50.4319 36.0439C50.1852 36.2081 49.8837 36.2967 49.5739 36.296C49.1257 36.2978 48.703 36.1137 48.4344 35.7996L45.0891 31.9191L44.5941 32.3525V35.0772C44.5932 35.7668 43.9549 36.3257 43.1672 36.3266Z"
			fill="#FFE812"
		/>
	</svg>
);

const IconCopy = (
	<svg className="absolute" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="vuesax/bold/link-square">
			<g id="link-square">
				<path
					id="Vector"
					d="M51.269 6.33334H24.7323C13.2057 6.33334 6.33398 13.205 6.33398 24.7317V51.2367C6.33398 62.795 13.2057 69.6667 24.7323 69.6667H51.2373C62.764 69.6667 69.6357 62.795 69.6357 51.2683V24.7317C69.6673 13.205 62.7957 6.33334 51.269 6.33334ZM25.904 53.105C25.8407 53.105 25.7457 53.105 25.6823 53.105C22.6107 52.82 19.729 51.395 17.5757 49.115C12.509 43.795 12.509 35.15 17.5757 29.83L24.5107 22.5467C26.9806 19.95 30.3057 18.4933 33.8523 18.4933C37.399 18.4933 40.724 19.9183 43.194 22.5467C48.2607 27.8667 48.2607 36.5117 43.194 41.8317L39.7423 45.4733C38.824 46.4233 37.3357 46.455 36.3857 45.5683C35.4357 44.65 35.404 43.1617 36.2907 42.2117L39.7423 38.57C43.099 35.055 43.099 29.3233 39.7423 25.84C36.6073 22.5467 31.0973 22.5467 27.9307 25.84L20.9957 33.1233C17.639 36.6383 17.639 42.37 20.9957 45.8533C22.3573 47.31 24.194 48.1967 26.1257 48.3867C27.424 48.5133 28.374 49.685 28.2473 50.9833C28.1523 52.1867 27.1073 53.105 25.904 53.105ZM58.4257 46.2017L51.4907 53.485C49.0207 56.0817 45.6957 57.5383 42.149 57.5383C38.6023 57.5383 35.2773 56.1133 32.8073 53.485C27.7407 48.165 27.7407 39.52 32.8073 34.2L36.259 30.5583C37.1773 29.6083 38.6657 29.5767 39.6157 30.4633C40.5657 31.3817 40.5973 32.87 39.7107 33.82L36.259 37.4617C32.9023 40.9767 32.9023 46.7083 36.259 50.1917C39.394 53.485 44.904 53.5167 48.0707 50.1917L55.0057 42.9083C58.3623 39.3933 58.3623 33.6617 55.0057 30.1783C53.644 28.7217 51.8073 27.835 49.8757 27.645C48.5773 27.5183 47.6273 26.3467 47.754 25.0483C47.8807 23.75 49.0207 22.7683 50.3507 22.9267C53.4223 23.2433 56.304 24.6367 58.4573 26.9167C63.4923 32.205 63.4923 40.8817 58.4257 46.2017Z"
					fill="#65737E"
				/>
			</g>
		</g>
	</svg>
);
