import "./CardRecruitmentOrder.scss";
import CrownIcon from "../../image/crown.svg";
import { differenceInDays, parse } from "date-fns";

interface Props {
	data: {
		id: number;
		title: string;
		orderType: string | undefined;
		description: string;
		userNickname: string | undefined;
		nation: string;
		startDate: string;
		endDate: string;
		genderType: string;
		age: string;
		maxMember: number;
		currentMember: number;
		tripType: string;
		images: string[];
		userIcon: string;
	};
}

function MainCardRecruitmentOrder({ data }: Props) {
	const transformGenderType = (genderType: string) => {
		if (genderType === "ANY") {
			return "남녀무관";
		}
	};

	const transformNightsAndDays = (startedAt: string, endedAt: string) => {
		const startDate = parse(startedAt, "yyyy-MM-dd", new Date());
		const endDate = parse(endedAt, "yyyy-MM-dd", new Date());

		const totalDays = differenceInDays(endDate, startDate);
		const nights = totalDays;
		const days = totalDays + 1; // 박의 수에 1을 더하면 일의 수를 얻을 수 있습니다.

		return `${nights}박 ${days}일`;
	};

	const transformRemainingDays = (endedAt: string) => {
		const today = new Date().getTime();
		const endDate = new Date(endedAt).getTime();

		return Math.round((today - endDate) / (1000 * 60 * 60 * 24));
	};

	const transformOrderType = (orderType: string | undefined) => {
		if (orderType === "RECRUITMENT") {
			return "모집형";
		} else if (orderType === "PLANNED") {
			return "계획형";
		}
	};

	function dateFormat(date: Date) {
		let month: any = date.getMonth() + 1;
		let day: any = date.getDate();

		return `${date.getFullYear()}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
	}

	return (
		<div className="card-order" onClick={() => (window.location.href = `/community/${data.id}/details`)}>
			<div className="text-[12px] font-bold flex gap-[5px] xl:text-[14px]">
				<img className="w-[16px] aspect-square xl:w-[22px]" src={CrownIcon} alt="crown" />
				{data.userNickname}
			</div>
			<div className="card-order__image rounded-[2px]" style={{ backgroundImage: `url(${data.images[0]})` }}>
				{/* <div className="card-order__image__overlay">
					{data.nation}/{transformNightsAndDays(data.startDate, data.endDate)}
				</div> */}
			</div>
			<div className="text-[14px] font-bold mb-[10px]  line-clamp-2 mt-[10px] xl:text-[20px]">{data.title}</div>
			<div className="text-[12px] font-normal xl:text-[18px]  text-[#424549]">
				{[`${data.currentMember}/${data.maxMember} 모집중`, `${data.genderType ?? ""}`, `${data.age ?? ""}`].join("·")}
			</div>
			<div className="text-[12px] font-normal text-[#424549] mt-[10px] xl:text-[18px] xl:mt-[8px]">{data.tripType}</div>
			<div className="text-[10px] text-[#777777] mt-[10px] font-normal xl:text-[14px] xl:mt-[8px]">
				{dateFormat(new Date(data.startDate))} ~ {dateFormat(new Date(data.endDate))}
			</div>
			<div className=" mt-[10px] xl:text-[20px] flex">
				<div
					className={`text-[10px] px-[7px] h-[19px] xl:h-[24px] font-bold bg-[#202C4A] rounded-full flex justify-center items-center xl:text-[14px] xl:font-medium  ${
						transformRemainingDays(data.startDate) >= -10 ? "text-[#F5B826]" : "text-white"
					}`}>
					{transformOrderType(data.orderType)} / D{transformRemainingDays(data.startDate)}
				</div>
			</div>
		</div>
	);
}

export default MainCardRecruitmentOrder;
