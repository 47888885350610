import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { getToken, logout } from "../../admin_pages/auth";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/solid"; // Heroicons를 설치해야 합니다.
import { useState } from "react";
import FormFactory, { FormFactorySchema } from "../../components/FormFactory";

export default function AdminDashboardPage() {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		if (!getToken()) {
			alert("로그인이 필요합니다.");
			window.location.href = "/admin";
		}
	}, []);

	const menus = [
		{ to: "/admin/dashboard/users", name: "사용자 관리" },
		{ to: "/admin/dashboard/partners", name: "파트너 관리" },
		{ to: "/admin/dashboard/orders", name: "주문 관리" },
		{ to: "/admin/dashboard/community-clups", name: "커뮤니티 관리" },
		{ to: "/admin/dashboard/payments", name: "결제 관리" },
		{ to: "/admin/dashboard/system", name: "시스템 관리" },
		{ to: "/admin/dashboard/events", name: "이벤트" },
		{ to: "/admin/dashboard/franchises", name: "제휴서비스" },
		{ to: "/admin/dashboard/faqs", name: "FAQ 관리" },
		{ to: "/admin/dashboard/recommend-trips", name: "추천여행 관리" },
		{ to: "/admin/dashboard/notifications", name: "알림 조회" },
		{ to: "/admin/dashboard/notices", name: "공지사항 관리" },
		{ to: "/admin/dashboard/popup", name: "메인팝업 관리" },
		{ to: "/admin/dashboard/visitors", name: "방문자 조회" },
		{ to: "/admin/dashboard/biztalklog", name: "알림톡 전송결과 조회" },
	];

	// const schema: FormFactorySchema[] = [
	// 	{ key: "name", label: "이름", method: "text", validation: "required" },
	// 	{ key: "email", label: "이메일", method: "email", validation: "required" },
	// 	{
	// 		key: "tripType",
	// 		label: "여행유형",
	// 		method: "select",
	// 		selectOptions: [
	// 			{ label: "일반여행", value: "일반여행" },
	// 			{ label: "우정여행", value: "우정여행" },
	// 		],
	// 		validation: "required",
	// 	},
	// 	{
	// 		key: "labels",
	// 		method: "select-multi",
	// 		label: "라벨",
	// 		selectOptions: [
	// 			{ label: "가족여행", value: "가족여행" },
	// 			{ label: "커플여행", value: "커플여행" },
	// 			{ label: "우정여행", value: "우정여행" },
	// 			{ label: "단체여행", value: "단체여행" },
	// 		],
	// 		validation: "required",
	// 	},
	// 	{ key: "description", label: "설명", method: "textarea", validation: "required" },
	// 	{ key: "description2", label: "설명2", method: "wizwig", validation: "required" },
	// ];

	return (
		<div className="flex h-screen bg-bg">
			{/* Sidebar */}
			<div className={`bg-gray-800 flex-none ${sidebarOpen ? "block" : "hidden"} lg:block lg:w-1/5 xl:w-1/6`}>
				<div className="flex justify-between p-4 border-b border-gray-800">
					<h2 className="text-white">여행을만들다 관리자</h2>
				</div>
				<nav className="flex flex-col p-4">
					{/* 메뉴 아이템 링크 예제 */}
					{menus.map((menu) => {
						return (
							<Link to={menu.to} className="text-white py-2 hover:bg-gray-700 font-normal">
								{menu.name}
							</Link>
						);
					})}
					{/* 메뉴 아이템 더 추가 */}
					<div
						onClick={() => {
							logout();
							window.location.href = "/admin";
						}}
						className="flex-1 cursor-pointer flex items-end text-white mt-8 border-t border-gray-200 pt-4">
						로그아웃
					</div>
				</nav>
			</div>

			{/* Content area */}
			<div className="flex-1 p-10 overflow-scroll">
				<button onClick={() => setSidebarOpen(true)}>
					<Bars3Icon className="h-5 w-5 text-black lg:hidden" />
				</button>
				{/* <FormFactory
					schema={schema}
					onChange={(data) => {
					}}
				/> */}
				<Outlet />
			</div>
		</div>
	);
}
