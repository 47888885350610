import React, { useEffect, useMemo, useState } from "react";

interface EmailDomainOption {
	label: string;
	value: string;
}

interface Props {
	value: string;
	onChange: (value: string) => void;
	className?: string;
	domainOptions: EmailDomainOption[];
	autoCheck?: boolean;
	disabled?: boolean;
}

export default function InputEmail({ value, onChange, className = "", domainOptions, disabled }: Props) {
	const [localPart, setLocalPart] = useState("");
	const [domain, setDomain] = useState(domainOptions[0].value);
	const [isCustomDomain, setIsCustomDomain] = useState(false);

	useEffect(() => {
		try {
			const [localPart, domain] = value.split("@");
			setLocalPart(localPart ?? "");
			setDomain(domain ?? "");
		} catch (e) {}
	}, [value]);

	const handleLocalPartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalPart(event.target.value);
		onChange(event.target.value + "@" + domain);
	};

	const handleDomainChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedOption = domainOptions.find((option) => option.value === event.target.value);
		const newDomain = selectedOption ? selectedOption.value : "";

		if (event.target.value === "custom") {
			setIsCustomDomain(true);
			setDomain("");
			onChange(localPart + "@" + ""); // Set domain to empty if 'custom' is selected
		} else {
			setIsCustomDomain(false);
			setDomain(newDomain);
			onChange(localPart + "@" + newDomain);
		}
	};

	const handleCustomDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDomain = event.target.value;
		setDomain(newDomain);
		onChange(localPart + "@" + newDomain);
	};

	return (
		<div className="flex flex-col">
			<div className={["flex items-center gap-3", className].join(" ")}>
				<input
					className="h-[30px] w-full bg-transparent rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777]"
					type="text"
					placeholder="이메일 앞자리"
					value={localPart}
					onChange={handleLocalPartChange}
					disabled={disabled}
				/>
				<span className="text-[14px]">@</span>

				<input
					className="h-[30px] w-full bg-transparent rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777]"
					type="text"
					placeholder="도메인 직접 입력"
					value={domain}
					onChange={handleCustomDomainChange}
					disabled={disabled}
				/>

				<select
					className="h-[30px] rounded-[2px] border border-[#DADADA] bg-white px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777]"
					value={domain}
					onChange={handleDomainChange}
					disabled={disabled}>
					<option value="custom">직접 입력</option>
					{domainOptions.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}
