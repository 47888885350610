interface Props {
	leadingIcon?: any;
	title: string;
	required?: boolean;
	description?: string;
	action?: any;
	className?: string;
}

export default function InputLabel(props: Props) {
	return (
		<div className={["gap-[5px] h-[32px] flex items-center ", props.className].join(" ")}>
			{props.leadingIcon}
			<span className="text-[16px] font-spoqa text-[#0e1111] font-bold tracking-[-0.32px] ">{props.title}</span>
			{props.required && <span className="text-[#F00] text-[12px] font-medium tracking-[-0.32px] relative top-[1px]">*</span>}
			{props.description && <span className="text-[#777] font-spoqa text-[12px] font-medium tracking-[-0.32px]">{props.description}</span>}
			{props.action}
		</div>
	);
}
