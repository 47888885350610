import { differenceInCalendarDays, format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { PARTNER } from "../../../api";

interface Props {
	order: any;
}

export default function PartnerMyPagePartnerCard({ order }: Props) {
	const rangeText = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt))}박 ${
			differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt)) + 1
		}일`;
	}, [order]);

	const rangeText2 = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${startedAt} ~ ${endedAt}`;
	}, [order]);

	return (
		<div className="border border-[#dadada] rounded-sm flex p-2.5 gap-3 cursor-pointer">
			<div
				className={["relative bg-cover bg-center flex-none w-[171px]"].join(" ")}
				style={{
					backgroundImage: `url(/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg)`,
				}}>
				<div className="absolute left-1 top-1 text-white font-medium text-[10px] px-2 py-0.5 rounded-sm bg-[#355c7d]">
					{order.nation}/{rangeText}
				</div>
				<div className="absolute text-white flex justify-center items-center py-1 w-full bg-[#202C4A] bg-opacity-70 left-0 bottom-0">
					{order.status === "QUOTATION_WAITING" && "답변서 대기중"}
					{order.status === "QUOTATION_COMPLETE" && <span className="text-[#F5B826]">답변서 작성완료</span>}
					{order.status === "CONFIRM" && <span className="text-[#F5B826]">견적서 확정</span>}
					{order.status === "PAYMENT_WAITING" && <span className="text-[#F5B826]">결제 대기중</span>}
					{order.status === "PAYMENT_COMPLETE" && <span className="text-[#F5B826]">결제 완료</span>}
				</div>
			</div>
			<div className="flex-1 text-black text-[13px] font-medium py-2 flex flex-col gap-1">
				<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium ">
					{order.PartnerQuotations?.find((e: any) => e.Partner)?.Partner?.corporateBusinessName}
				</div>
				<div>
					여행 형태:&nbsp;
					{order.type === "RECRUITMENT" && "모집 주문"}
					{order.type === "ALONE" && "단독 주문"}
					{order.type === "BUSINESS_TRIP" && "출장 주문"}
				</div>
				<div className="flex justify-between pr-7 max-w-[142px] truncate">
					<div className="whitespace-nowrap">국가: {order.nation}</div>
					<div className="whitespace-nowrap">&nbsp;도시: {order.city}</div>
				</div>
				<div>
					{order.numberOfRecruits}명·{order.guideAvailability ? "가이드필요" : "가이드필요없음"}
				</div>
				<div className="truncate max-w-[142px]">
					{order.accommodation}
					{order.transportation ? "·" + order.transportation : ""}
				</div>
				<div className="tracking-[-1px]">{rangeText2}</div>
			</div>
		</div>
	);
}
