import { useEffect, useState } from "react";
import EasyTable from "../../components/EasyTable";
import { RECOMMENDATION_TRIP } from "../../admin_pages/api";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";

export default function AdminRecommendTripsPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [modal, setModal] = useState("update"); // 'add', 'edit', 'delete'

	useEffect(() => {
		getData();
	}, [page]);

	const getData = async () => {
		const response = await RECOMMENDATION_TRIP.pagination(page.page, page.perPage);

		setData(response);
	};

	const columns = [
		{ header: "ID", key: "id" },
		{ header: "제목", key: "name" },
		{ header: "설명", key: "description" },
		{
			header: "이미지",
			key: "RecommendationTripFile",
			render: (File: any) => {
				const file = File.find((f: any) => f.type === "RECOMMENDATION_TRIP_IMAGE");
				return file ? (
					<a className="text-blue-500" target="_blank" href={file?.File?.url}>
						{file?.File?.url?.slice(0, 20)}...
					</a>
				) : (
					"-"
				);
			},
		},
		{ header: "내용", key: "content", render: (v: string) => `${v?.slice(0, 20)}...` },
		{ header: "category1", key: "category1" },
		{ header: "category2", key: "category2" },
		{ header: "국가", key: "nation" },
		{ header: "tags", key: "tags" },
		{ header: "좋아요 수", key: "countOfLike" },
		{ header: "댓글 수", key: "countOfComment" },
		{
			header: "상세",
			key: "id",
			render: (v: number) => (
				<a href={`/admin/dashboard/recommend-trips/${v}`} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					상세
				</a>
			),
		},
	];

	const handleCreate = (formData: any) => {
		RECOMMENDATION_TRIP.create({
			...formData,
			RecommendationTripFile: {
				create: {
					type: "RECOMMENDATION_TRIP_IMAGE",
					fileId: +formData.RecommendationTripFile.id,
				},
			},
		});
		setModal("");
		getData();
	};

	return (
		<div>
			<h1>추천 여행 관리</h1>
			<div className="flex justify-end">
				<button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button>
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable data={data} columns={columns} />
				</div>
			</div>

			<Modal open={modal === "add"} onClose={() => setModal("")} size="lg">
				<div>추가</div>
				<div>
					<FormFactory
						schema={[
							{ label: "제 목", key: "name", method: "text", validation: "required" },
							{ label: "설 명", key: "description", method: "text", validation: "required" },
							{ label: "이미지", key: "RecommendationTripFile", method: "file", validation: "required" },
							{ label: "내 용", key: "content", method: "wizwig", validation: "required" },
							{ label: "국 가", key: "category1", method: "text", validation: "required" },
							{ label: "도 시", key: "category2", method: "text", validation: "required" },
							{ label: "태 그", key: "tags", method: "text", validation: "required" },
						]}
						onSubmit={handleCreate}
					/>
				</div>
			</Modal>
		</div>
	);
}
