export default function IconUpload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15 15L15 11.5M5 15L5 11.5M5.5 8L3.5 8C2.67158 8 2 8.67157 2 9.5L2 16.5C2 17.3284 2.67158 18 3.5 18L16.5 18C17.3284 18 18 17.3284 18 16.5L18 9.5C18 8.67157 17.3284 8 16.5 8L14.5 8M12.8292 4.82861L10.0008 2.00018M10.0008 2.00018L7.36292 4.63805M10.0008 2.00018L10.0007 7.78545M8 13C8 11.8954 8.89543 11 10 11C11.1046 11 12 11.8954 12 13C12 14.1046 11.1046 15 10 15C8.89543 15 8 14.1046 8 13Z"
        stroke="#0E1111"
        strokeWidth="1.2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
