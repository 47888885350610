import client from "./axios";
import { RecommendationTrip, TravelPostProps } from "./types";

export const AUTH = {
  async loginUser(email: string, password: string): Promise<{ accessToken: string }> {
    return client
      .post<{ accessToken: string }>("/auth/user/signin", {
        email,
        password,
      })
      .then((res) => res.data);
  },
  async loginPartner(email: string, password: string): Promise<{ accessToken: string }> {
    return client
      .post<{ accessToken: string }>("/auth/partner/signin", {
        email,
        password,
      })
      .then((res) => res.data);
  },
  // 휴대폰 본인인증 토큰
  async getPhoneAuthToken() {
    return client.get("/auth/kg-inicis-hash").then((res) => res.data);
  },
  async userSignup(
    oauthType: string,
    oauthId: string,
    email: string,
    nickname: string,
    password: string,
    name: string,
    birthday: string,
    gender: string,
    phone: string,
    tripInfo: string[],
    info: string[],
    mbti: string,
    agreeMarketingAgreement: boolean
  ) {
    return client
      .post("/auth/user/signup", {
        oauthType,
        oauthId,
        email,
        nickname,
        password,
        name,
        birthday,
        gender,
        phone,
        tripInfo,
        info,
        mbti,
        termsOfServiceAgreement: new Date(),
        privacyPolicyAgreement: new Date(),
        marketingAgreement: agreeMarketingAgreement ? new Date() : null,
        ageAgreement: new Date(),
      })
      .then((res) => res.data);
  },
  async partnerSignup(
    email: string,
    password: string,
    name: string,
    nation: string,
    city: string,
    corporateBusinessName: string,
    address: string,
    position: string,
    mobilePhone: string,
    landlinePhone: string,
    businessNumber: string,
    description: string,
    businessRegistrationFileId: number,
    accountFileId: number
  ) {
    return client
      .post("/auth/partner/signup", {
        email,
        password,
        name,
        nation,
        city,
        corporateBusinessName,
        address,
        position,
        mobilePhone,
        landlinePhone,
        businessNumber,
        description,
        businessRegistrationFileId,
        accountFileId,
      })
      .then((res) => res.data);
  },
  findUserEmail(name: string, phone: string) {
    return client
      .get("/auth/user/find-email", {
        params: {
          name,
          phone,
        },
      })
      .then((res) => res.data);
  },
  findPartnerEmail(name: string, phone: string) {
    return client
      .get("/auth/partner/find-email", {
        params: {
          name,
          phone,
        },
      })
      .then((res) => res.data);
  },
  findUserPassword(email: string, name: string, phone: string) {
    return client
      .get("/auth/user/find-password", {
        params: {
          email,
          name,
          phone,
        },
      })
      .then((res) => res.data);
  },
  findPartnerPassword(email: string, name: string, phone: string) {
    return client
      .get("/auth/partner/find-password", {
        params: {
          email,
          name,
          phone,
        },
      })
      .then((res) => res.data);
  },
};

export const USER = {
  async getUser() {
    return client.get("/users/me").then((res) => res.data);
  },
  async getUserById(id: any) {
    return client.get(`/users/users/${id}`).then((res) => res.data);
  },

  async checkEmail(email: string) {
    return client
      .get("/users/email/check", {
        params: {
          email,
        },
      })
      .then((res) => res.data);
  },
  async checkNickname(nickname: string) {
    return client
      .get("/users/nickname/check", {
        params: {
          nickname,
        },
      })
      .then((res) => res.data);
  },

  async getMyOrders() {
    return client.get("/users/me/orders").then((res) => res.data);
  },

  async changePassword(data: any) {
    return client.patch("/auth/user/password", data).then((res) => res.data);
  },

  async changeMyProfile(data: any) {
    return client.patch("/users/me", data).then((res) => res.data);
  },
  async uploadProfile(fileId: number) {
    return client
      .patch("/users/me/profile-image", {
        fileId,
      })
      .then((res) => res.data);
  },
};

export const FILE = {
  async upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return client
      .post("/files/upload", formData)
      .then((res) => res.data)
      .catch((e) => {
        alert("파일 사이즈가 너무 큽니다.");
        return null;
      });
  },

  async getFile(id: any) {
    return client.get(`/files/${id}`).then((res) => res.data);
  },
};

export const PARTNER = {
  async checkEmail(email: string) {
    return client
      .get("/partners/email/check", {
        params: {
          email,
        },
      })
      .then((res) => res.data);
  },
  async getMe() {
    return client.get("/partners/me").then((res) => res.data);
  },
  async uploadProfile(fileId: number) {
    return client
      .patch("/partners/profile", {
        fileId,
      })
      .then((res) => res.data);
  },
  async changePassword(currentPassword: string, newPassword: string) {
    return client
      .patch("/auth/partner/password", {
        currentPassword,
        newPassword,
      })
      .then((res) => res.data);
  },
  async changeProfile(data: any) {
    return client.put("/partners/me", data).then((res) => res.data);
  },
  async changeAccount(fileId: number) {
    return client.post("/partners/requests/account", {
      fileId,
    });
  },
  async changeBusinessRegistration(fileId: number) {
    return client.post("/partners/requests/business-registration", {
      fileId,
    });
  },
  async getPartnerNotification(partner_id: number) {
    return client.get(`/notifications/partners/${partner_id}`);
  },
};

export const PARTNER_QUOTATION = {
  async create(orderId: number, initialQuotePrice: number, partnerCommission: number, travelFileId: number) {
    return client
      .post("/partner-quotations", {
        orderId,
        initialQuotePrice,
        partnerCommission,
        travelFileId,
      })
      .then((res) => res.data);
  },
  async getOwnedInvoices(request?: boolean, startedAt?: string, endedAt?: string) {
    return client
      .get("/partner-quotations/partners/owned/invoices", {
        params: {
          request,
          startedAt,
          endedAt,
        },
      })
      .then((res) => res.data);
  },
  async getOwnedConfirmed(page: number, perPage: number, status: string, startedAt: string | undefined, endedAt: string | undefined) {
    return client
      .get(`/partner-quotations/partners/owned?page=${page}&perPage=${perPage}&status=${status}&startedAt=${startedAt}&endedAt=${endedAt}`)
      .then((res) => res.data);
  },
  async getOwnedUnconfirmed() {
    return client.get("/partner-quotations/partners/owned/unconfirmed").then((res) => res.data);
  },
  async getOwnedReservation() {
    return client.get("/partner-quotations/partners/owned/reservation").then((res) => res.data);
  },
  async updateInvoice(id: number, invoiceFileId: number) {
    return client
      .post(`/partner-quotations/${id}/invoice`, {
        fileId: invoiceFileId,
      })
      .then((res) => res.data);
  },
  async requestPayment(id: number) {
    return client.patch(`/partner-quotations/${id}/request-payment`).then((res) => res.data);
  },
  async confirmQuotation(id: number) {
    return client.post(`/partner-quotations/${id}/confirm`, {}).then((res) => res.data);
  },
  async getComfirmOrders() {
    return client.get("/partner-quotations/user/owned/confirms").then((res) => res.data);
  },
  async getPartnerOwnedCount() {
    return client.get("/partner-quotations/partners/owned/count").then((res) => res.data);
  },
  async sendTravelAlaram(partnerQuotationId: number) {
    return client.post(`/partner-quotations/${partnerQuotationId}/travel-alarm`).then((res) => res.data);
  },
  async sendRequestPayment(partnerQuotationId: number) {
    return client.patch(`/partner-quotations/${partnerQuotationId}/request-payment`).then((res) => res.data);
  },
  async getStatistics(partnerId: number, startedAt?: string, endedAt?: string) {
    return client.get(`/partner-quotations/partners/${partnerId}/statistics`, {
      params: {
        startedAt,
        endedAt,
      },
    }).then((res) => res.data);
  },
  async getChatOrder() {
    return client.get(`/partner-quotations/partners/owned/chats`).then((res) => res.data);
  },
  async sendQuotationChat(partnerQuotationId: number, data: any) {
    return client.post(`/partner-quotations/${partnerQuotationId}/send-chat`, data).then((res) => res.data);
  },
  async getQuotationsById(partnerQuotationId: number) {
    return client.get(`/partner-quotations/${partnerQuotationId}`).then((res) => res.data);
  },
  async cancel(id: number) {
    return client.post(`/partner-quotations/${id}/cancel`).then((res) => res.data);
  }
};

export const ORDER = {
  async paginationByPartner(page = 1, perPage = 10, status?: string, startDate?: string, endDate?: string) {
    return client
      .get("/orders", {
        params: {
          page,
          perPage,
          status,
          startDate,
          endDate,
        },
      })
      .then((res) => res.data);
  },
  async answer(page = 1, perPage = 10, state = '미답변', startDate?: string, endDate?: string) {
    return client
      .get("/orders/answer/pagination", {
        params: {
          page,
          perPage,
          state,
          startDate,
          endDate,
        },
      })
      .then((res) => res.data);

  },
  async getOne(id: any) {
    return client.get("/orders/" + id).then((res) => res.data);
  },
  async getParticipants(id: any) {
    return client.get(`/orders/${id}/participants`).then((res) => res.data);
  },
  async getCommunityPartcipants(id: any) {
    return client.get(`/orders/${id}/community-clup/participatns`).then((res) => res.data);
  },
  async sendOrder(data: object) {
    return await client
      .post(`/orders`, data)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },

  async editOrder(id: any, data: object) {
    return await client
      .patch(`/orders/${id}`, data)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },

  async getMyOrderById(id: any) {
    return client.get(`/orders/${id}`).then((res) => res.data);
  },

  async confirmOrder(orderId: any, quotationId: any, data: any) {
    return client
      .post(`/orders/${orderId}/quotations/${quotationId}/confirm`, data)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },

  async paymentOrder(id: any, data: any) {
    return client
      .post(`/orders/${id}/payment`, data)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  async getManyPaid() {
    return client.get(`/orders/my/paid`).then((res) => res.data);
  },
  async paymentComplete(order_id: any, payment_id: any, pg_token: any) {
    return client.get(`/orders/${order_id}/payment/${payment_id}/kakao-complete?pg_token=${pg_token}`).then((res) => res.data);
  },
  async paymentCencel(order_id: any, payment_id: any) {
    return client.get(`/orders/${order_id}/payment/${payment_id}/kakao-cancel`).then((res) => res.data);
  },
  async paymentFail(order_id: any, payment_id: any) {
    return client.get(`/orders/${order_id}/payment/${payment_id}/kakao-fail`).then((res) => res.data);
  },
  async requestSchedule(orderId: number) {
    return client.post(`/orders/${orderId}/request-schedule`).then((res) => res.data);
  },
  async editedParticipants(orderId: any, data: any) {
    return client.put(`/orders/${orderId}/participants`, data).then((res) => res.data);
  },
  async getCommunityChildren() {
    return client.get(`/orders/community/children`).then((res) => res.data);
  }
};

export const RECOMMENDATION_TRIP = {
  async getMany(page = 1, perPage = 10) {
    return await client.get(`/recommendation-trip?page=${page}&perPage=${perPage}`).then((res) => res.data);
  },

  async getOne(id: string) {
    return await client.get(`/recommendation-trip/${id}`).then((res) => res.data);
  },
  async checkLike(id: string) {
    return await client.get(`/recommendation-trip/${id}/check-like`).then((res) => res.data);
  },
  async like(id: string) {
    return await client.post(`/recommendation-trip/${id}/like`).then((res) => res.data);
  },
  async unlike(id: string) {
    return await client.post(`/recommendation-trip/${id}/unlike`).then((res) => res.data);
  },
  async create(data: any) {
    return await client.post('/recommendation-trip', data).then((res) => res.data);
  },
  async update(id: number, data: any) {
    return await client.put(`/recommendation-trip/${id}`, data).then((res) => res.data);
  },
  async delete(id: number) {
    return await client.delete(`/recommendation-trip/${id}`).then((res) => res.data);
  },
};

export const NOTICE = {
  getMany() {
    return client.get("/notices").then((res) => res.data);
  },
};

export const FRANCHISE = {
  getMany() {
    return client.get("/franchises").then((res) => res.data);
  },
};

export const FAQ = {
  getMany() {
    return client.get("/faqs").then((res) => res.data);
  },
};

export const EVENT = {
  getMany() {
    return client.get("/event").then((res) => res.data);
  },
  getOne(id: any) {
    return client.get(`/event/${id}`).then((res) => res.data);
  },
};
interface CommunityClupGetManyFilter {
  numberOfRecruits?: string;
  tripType?: string[];
  ageGroup?: string;
  recruitGenderType?: string;
}

interface CommunityClupParticipate {
  alcoholPreference: string;
  smokingPreference: string;
  travelStyle: string[];
  mbti: string;
}

export const COMMUNITY_CLUP = {
  async getMany(type: "RECRUITMENT" | "PLANNED" | "", page = 1, perPage = 8, search?: string, filter?: CommunityClupGetManyFilter): Promise<any> {
    const tripType = filter?.tripType;

    return client
      .get("/community-clups", {
        params: {
          page,
          perPage,
          type: type === "" ? undefined : type,
          search,
          ...filter,
          tripType: tripType ? tripType.join(",") : undefined,
        },
      })
      .then((res) => res.data);
  },
  async deleteComment(communityClupId: number, commentId: number) {
    return client.delete(`/community-clups/${communityClupId}/comments/${commentId}`).then((res) => res.data);
  },
  async sendCommunityRecruiment(data: any) {
    return await client.post(`/community-clups/recruiment`, data).then((res) => res.data);
  },
  async sendCommunityPlanned(data: any) {
    return await client.post(`/community-clups/planned`, data).then((res) => res.data);
  },
  async getOne(id: number) {
    return client.get(`/community-clups/${id}`).then((res) => res.data);
  },

  async accompanyCommunityClup(id: any) {
    return client.post(`/community-clups/${id}`).then((res) => res.data);
  },
  async withdrawCommunityClup(id: any) {
    return client.get(`/community-clups/${id}/my/withdraw`).then((res) => res.data);
  },
  async transferOwner(id: number, userId: number) {
    return client
      .post(`/community-clups/${id}/my/transfer-owner`, {
        userId,
      })
      .then((res) => res.data);
  },
  async getParticipants(id: number) {
    return client.get(`/community-clups/${id}/participants`).then((res) => res.data);
  },
  async getComments(id: number) {
    return client.get(`/community-clups/${id}/comments`).then((res) => res.data);
  },
  async createComment(id: number, content: string, parentId?: number) {
    return client.post(`/community-clups/${id}/comments`, {
      content,
      parentId,
    });
  },
  async participate(id: string | number, data: CommunityClupParticipate) {
    return client.post(`/community-clups/${id}/participate`, data).then((res) => res.data);
  },
  async getMyStatus(id: string | number) {
    return client.get(`/community-clups/${id}/my`).then((res) => res.data);
  },
  async getOneParticipant(participantId: number) {
    return client.get(`/community-clups/participants/${participantId}`).then((res) => res.data);
  },
  async confirmParticipant(communityClupId: number, participantId: number) {
    return client.patch(`/community-clups/${communityClupId}/participants/${participantId}/accept`).then((res) => res.data);
  },
  async rejectParticipant(communityClupId: number, participantId: number) {
    return client.patch(`/community-clups/${communityClupId}/participants/${participantId}/reject`).then((res) => res.data);
  },
  async getMyCommunity() {
    return client.get(`/community-clups/my`).then((res) => res.data);
  },
  async getMyParticipatedCommunity() {
    return client.get(`/community-clups/my/participated`).then((res) => res.data);
  },
  async editMyCommunity(communityClupId: any, data: any) {
    return client.patch(`/community-clups/${communityClupId}/update-description`, data).then((res) => res.data);
  },
  async confirmCommunityClup(id: any) {
    return client.post(`/community-clups/${id}/participate-confirm`).then((res) => res.data);
  },
  async myConfirmedCommunityClup() {
    return client.get("/community-clups/my/participated-confirmed").then((res) => res.data);
  },
  async connectOrder(id: any, orderId: any) {
    return client.post(`/community-clups/${id}/connect-order/${orderId}`).then((res) => res.data);
  },
  async update(id: any, data: any) {
    return client.patch(`/community-clups/${id}/update`, data).then((res) => res.data);
  }
};

type MessageType = "TEXT" | "POLL_NOTICE" | "SCHEDULE" | "IMAGE";
interface MessageSendParams {
  pollId?: number;
  scheduleId?: number;
}

interface ScheduleCreateParams {
  title: string;
  color: string;
  startedAt: Date;
  endedAt: Date;
  location: string;
  description: string;
}

export const CHATROOMS = {
  async getMessages(chatroomId: number) {
    return client.get(`/chat-rooms/${chatroomId}/messages`).then((res) => res.data);
  },
  async sendMessage(chatroomId: number | string, content: string, type: MessageType = "TEXT", params?: MessageSendParams) {
    return client
      .post(`/chat-rooms`, {
        chatRoomId: +chatroomId,
        content,
        type,
        ...params,
      })
      .then((res) => res.data);
  },
  async getCommuntyClup(chatroomId: number) {
    return client.get(`/chat-rooms/${chatroomId}/community-clubs`).then((res) => res.data);
  },
  async createSchedule(chatroomId: number, params: ScheduleCreateParams) {
    return client.post(`/chat-rooms/${chatroomId}/schedules`, params).then((res) => res.data);
  },
  async getSchedules(chatroomId: number) {
    return client.get(`/chat-rooms/${chatroomId}/schedules`).then((res) => res.data);
  },

  async getCommunityClupVotelist(chatroomId: any) {
    return client.get(`/chat-rooms/${chatroomId}/polls`).then((res) => res.data);
  },

  async createCommunityClupVote(chatroomId: any, data: any) {
    return client.post(`/chat-rooms/${chatroomId}/polls`, data).then((res) => res.data);
  },

  async getCommunityClupVoteById(chatroomId: any, pollId: any) {
    return client.get(`/chat-rooms/${chatroomId}/polls/${pollId}`).then((res) => res.data);
  },
  async vote(chatroomId: number, pollId: number, option: string) {
    return client.post(`/chat-rooms/${chatroomId}/polls/${pollId}/votes`, { option }).then((res) => res.data);
  },
  async getPollComments(chatroomId: number, pollId: number) {
    return client.get(`/chat-rooms/${chatroomId}/polls/${pollId}/comments`).then((res) => res.data);
  },
  async sendPollComment(chatroomId: number, pollId: number, content: string) {
    return client.post(`/chat-rooms/${chatroomId}/polls/${pollId}/comments`, { content }).then((res) => res.data);
  },
  async editPollComment(chatroomId: number, pollId: number, commentId: number, content: string, authorId: number, referenceType: string, referenceId: number) {
    return client
      .patch(`/chat-rooms/${chatroomId}/polls/${pollId}/comments/${commentId}`, { content, authorId, referenceType, referenceId })
      .then((res) => res.data);
  },
  async deletePollComment(chatRoomId: number, commentId: number) {
    return client.delete(`/chat-rooms/${chatRoomId}/comments/${commentId}`).then((res) => res.data);
  },
};

interface OrderScheduleUpdateParams {
  id: number;
  latitude: number;
  longitude: number;
  label: string;
  title: string;
  description: string;
  startedAt: Date;
  endedAt: Date;
}

export const ORDER_SCHEDULE = {
  create(orderId: number) {
    return client.post(`/order-schedule`, { orderId: `${orderId}` }).then((res) => res.data);
  },
  getSchedules(orderScheduleId: number) {
    return client.get(`/order-schedule/${orderScheduleId}`, {}).then((res) => res.data);
  },
  update(orderScheduleId: number, data: { items: OrderScheduleUpdateParams[] }) {
    return client.put(`/order-schedule/${orderScheduleId}`, data).then((res) => res.data);
  },
};

export const NOTIFICATION = {
  async getManyByUser(userId: number) {
    return client.get(`/notifications/users/${userId}`).then((res) => res.data);
  },
  async read(notificationId: number) {
    return client.patch(`/notifications/${notificationId}/read`).then((res) => res.data);
  },
};

export const DEFAULT = {
  log() {
    client.get("/log").then((res) => res.data);
  },
  async urlToZip(urls: string[]) {
    return client
      .post("/url-to-zip", {
        urls,
      })
      .then((res) => res.data);
  },
  async getServiceConfig() {
    return client.get("/service-config").then((res) => res.data);
  },
  async getGoogleNearbySearch(lat: string, lng: string, radius = 2000, key: string) {
    return client
      .get("/google-nearbysearch", {
        params: {
          lat,
          lng,
          radius,
          key,
        },
      })
      .then((res) => res.data);
  },
};

export const PAYMENT = {
  async requestRefund(payment_id: any) {
    return client.post(`/payments/${payment_id}/request-refund`, { reason: '참여 결제/결제 취소 페이지에서 ' }).then((res) => res.data);
  },
};
