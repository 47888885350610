import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { COMMUNITY_CLUP } from "../../../../admin_pages/api";
import EasyTable from "../../../../components/EasyTable";

// @Get('community-clubs/:communityClupId/chats')
export default function AdminCommunityClupsIdChatPage() {
	const { community_clup_id } = useParams();
	const [community, setCommunity] = useState<any>(null);
	const [data, setData] = useState<any>(null);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		if (community_clup_id) {
			COMMUNITY_CLUP.getChatRoom(+community_clup_id).then((v: any) => {
				setData(v);
			});
			COMMUNITY_CLUP.find(+community_clup_id).then((res: any) => {
				setCommunity(res);
			});
		}
	};

	const chatRoom = useMemo(() => {
		try {
			return data?.ChatRoom[0];
		} catch (e) {
			return null;
		}
	}, [data]);

	const [message, setMessage] = useState("");

	const sendMsg = (e: any) => {
		e.preventDefault();
		if (community_clup_id) {
			COMMUNITY_CLUP.getChatRoomAndSendMessage(+community_clup_id, chatRoom?.id, message).then((res) => {
				setMessage("");
				getData(); // 재조회
			});
		}
	};

	return (
		<div>
			<div>
				<h1 className="text-xl">커뮤니티 클럽 채팅 {community_clup_id}</h1>
				<div className="font-bold mt-4">채팅 내역</div>
				<div className="mt-4 max-w-3xl overflow-x-scroll">
					{data?.ChatRoom ? (
						<EasyTable
							data={chatRoom?.messages}
							columns={[
								{ header: "ID", key: "id" },
								{
									header: "사용자 id",
									key: "userId",
									render: (userId: any) => (
										<a className="text-blue-500" href={`/admin/dashboard/users/${userId}`}>
											{userId}
										</a>
									),
								},
								{ header: "타입", key: "type" },
								{ header: "내용", key: "content" },
								{
									header: "보낸 시간",
									key: "createdAt",
								},
								{
									header: "삭제 여부",
									key: "deletedAt",
									render: (deletedAt: any) => (deletedAt ? "Y" : "N"),
								},
								// {
								//   header: '삭제',
								//   key: 'delete',
								//   render: ()
								// }
							]}
						/>
					) : (
						"데이터가 없습니다."
					)}
				</div>
				<div className="mt-4 max-w-3xl overflow-x-scroll">
					<span className="font-bold mt-4">메세지: </span>
					<form onSubmit={sendMsg} className="flex flex-1 border rounded-[12px] border-[#dadada] h-[40px] items-center px-[4px]">
						<input value={message} onChange={(v) => setMessage(v.target.value)} className="w-full flex-1 bg-transparent px-[6px] py-[2px]" />
						<button type="submit">{IconUpArrow}</button>
					</form>
				</div>

				<div className="font-bold mt-4">투표 내역</div>
				<div className="mt-4 max-w-3xl overflow-x-scroll">
					{chatRoom ? (
						<EasyTable
							data={chatRoom?.ChatRoomPoll}
							columns={[
								{ header: "ID", key: "id" },
								{ header: "제목", key: "title" },
								{ header: "설명", key: "description" },
								{ header: "시작 날짜", key: "startedAt" },
								{ header: "종료 날짜", key: "endedAt" },
							]}
						/>
					) : (
						"데이터가 없습니다."
					)}
				</div>

				<div className="font-bold mt-4">일정 내역</div>
				<div className="mt-4 max-w-3xl overflow-x-scroll">
					{chatRoom ? (
						<EasyTable
							data={chatRoom?.ChatRoomSchedule}
							columns={[
								{ header: "ID", key: "id" },
								{ header: "제목", key: "title" },
								{ header: "색상", key: "color" },
								{ header: "시작 날짜", key: "startedAt" },
								{ header: "종료 날짜", key: "endedAt" },
								{ header: "위치", key: "location" },
							]}
						/>
					) : (
						"데이터가 없습니다."
					)}
				</div>
			</div>
		</div>
	);
}

const IconUpArrow = (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="arrow-square-right">
			<g id="Icon">
				<path d="M18.75 13.75L15 10L11.25 13.75" fill="#0057FF" />
				<path
					d="M9 25C7.59987 25 6.8998 25 6.36502 24.7275C5.89462 24.4878 5.51217 24.1054 5.27248 23.635C5 23.1002 5 22.4001 5 21L5 9C5 7.59987 5 6.8998 5.27248 6.36502C5.51217 5.89462 5.89462 5.51217 6.36502 5.27248C6.8998 5 7.59987 5 9 5L21 5C22.4001 5 23.1002 5 23.635 5.27248C24.1054 5.51217 24.4878 5.89462 24.7275 6.36502C25 6.8998 25 7.59987 25 9L25 21C25 22.4001 25 23.1002 24.7275 23.635C24.4878 24.1054 24.1054 24.4878 23.635 24.7275C23.1002 25 22.4001 25 21 25L9 25Z"
					fill="#0057FF"
				/>
				<path
					d="M18.75 13.75L15 10M15 10L11.25 13.75M15 10L15 20M25 21L25 9C25 7.59987 25 6.8998 24.7275 6.36502C24.4878 5.89462 24.1054 5.51217 23.635 5.27248C23.1002 5 22.4001 5 21 5L9 5C7.59987 5 6.8998 5 6.36502 5.27248C5.89462 5.51217 5.51217 5.89462 5.27248 6.36502C5 6.8998 5 7.59987 5 9L5 21C5 22.4001 5 23.1002 5.27248 23.635C5.51217 24.1054 5.89462 24.4878 6.36502 24.7275C6.8998 25 7.59987 25 9 25L21 25C22.4001 25 23.1002 25 23.635 24.7275C24.1054 24.4878 24.4878 24.1054 24.7275 23.635C25 23.1002 25 22.4001 25 21Z"
					stroke="#F5F5F5"
					strokeWidth="2"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</g>
	</svg>
);
