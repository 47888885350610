import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EVENT } from "../api";
import { format } from "date-fns";

export default function EventDetail() {
	const { event_id } = useParams();
	const [data, setData] = useState<any>(null);

	useEffect(() => {
		if (event_id) {
			EVENT.getOne(event_id).then((data) => {
				setData(data);
			});
		}
	}, []);

	const dateString = useMemo(() => {
		if (!data) return "";
		const start = new Date(data?.startedAt);
		const end = new Date(data?.endedAt);
		const formatedStart = format(start, "yyyy-MM-dd");
		const formatedEnd = format(end, "yyyy-MM-dd");

		if (formatedStart === formatedEnd) {
			return formatedStart;
		} else {
			return `${formatedStart} ~ ${formatedEnd}`;
		}
	}, [data]);

	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full">
				<div className="text-lg font-bold text-[202c4a] mt-9 px-3">{data?.title}</div>
				<div className="px-3 text-right text-gray-500 mt-4">{dateString}</div>
				<div className="border-b border-[#999] mt-9"></div>
				<div
					onClick={() => {
						window.open(data?.url, "_blank");
					}}>
					<img src={data?.imageFile?.url} />
				</div>

				<div
					dangerouslySetInnerHTML={{
						__html: data?.content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
					}}
					className="px-3 text-sm font-normal mt-5"></div>
			</div>
		</div>
	);
}
