import { useEffect, useState } from "react";
import { PAYMENT } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";
import EasyPagination from "../../components/EasyPagination";

export default function AdminPaymentPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});

	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'

	useEffect(() => {
		getData();
	}, [page]);

	const getData = async () => {
		const response = await PAYMENT.pagination(page.page, page.perPage);

		setData(response.nodes);
		setCount(response.total);
	};

	return (
		<div>
			<h1>결제 관리</h1>
			<div className="flex justify-end">
				{/* <button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button> */}
			</div>
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable
						data={data}
						columns={[
							{ header: "ID", key: "id" },
							{
								header: "주문 id",
								key: "orderId",
								render: (orderId: any) => (
									<a className="text-blue-500" href={`/admin/dashboard/orders/${orderId}`}>
										{orderId}
									</a>
								),
							},
							{ header: "결제 방법", key: "method" },
							{ header: "카카오 id", key: "kakaoTid" },
							{ header: "결제 대금", key: "amount" },
							// PENDING: 'PENDING',
							// COMPLETED: 'COMPLETED',
							// CANCELED: 'CANCELED',
							// FAILED: 'FAILED',
							// REFUND_REQUESTED: 'REFUND_REQUESTED',
							// REFUNDED: 'REFUNDED',
							// REFUND_REJECTED: 'REFUND_REJECTED'
							{
								header: "상태",
								key: "status",
								render: (status) => {
									let _status = null;
									if (status === "PENDING") _status = "결제중";
									else if (status === "COMPLETED") _status = "결제완료";
									else if (status === "CANCELED") _status = "결제취소";
									else if (status === "FAILED") _status = "결제실패";
									else if (status === "REFUND_REQUESTED") _status = "환불요청";
									else if (status === "REFUNDED") _status = "환불완료";
									else if (status === "REFUND_REJECTED") _status = "환불거절";
									return <div>{_status}</div>;
								},
							},
							{
								header: "계좌 결제 승인",
								key: "status",
								render: (status: any, row: any) => {
									return (
										status === "PENDING" &&
										row.method === "BANK_TRANSFER" && (
											<button
												onClick={async () => {
													await PAYMENT.confirmBankTransfer(row.id);
													await getData();
												}}
												className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
												승인
											</button>
										)
									);
								},
							},
							{ header: "환불 이유", key: "refundReason" },
							{ header: "환불 완료 날짜", key: "refundedAt" },
							{
								header: "환불 처리",
								key: "status",
								render: (status: any, row: any) =>
									status === "REFUND_REQUESTED" && (
										<>
											<div
												onClick={() => {
													PAYMENT.refundComplete(row.id).then(() => {
														getData();
													});
												}}
												className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
												환불승인
											</div>
											<div
												onClick={() => {
													PAYMENT.regundReject(row.id).then(() => {
														getData();
													});
												}}
												className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
												환불거절
											</div>
										</>
									),
							},
						]}
					/>
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
}
