import React from "react";

type Column = {
	header: string;
	key: string;
	render?: (data: any, row: any, index: number) => React.ReactNode;
};

type TableProps = {
	data: any[];
	columns: Column[];
};

const EasyTable: React.FC<TableProps> = ({ data, columns }) => {
	// Default render function for text
	const defaultRender = (data: any) => data;

	return (
		<table className="min-w-full leading-normal">
			<thead>
				<tr>
					{columns.map((col) => (
						<th
							key={col.key}
							className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider whitespace-nowrap">
							{col.header}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, rowIndex) => (
					<tr key={rowIndex}>
						{columns.map((col) => {
							const renderFn = col.render || defaultRender; // Use provided render function or default
							return (
								<td key={`${col.key}-${rowIndex}`} className="px-5 py-2 border-b border-gray-200 bg-white text-sm whitespace-nowrap font-normal">
									{renderFn(row[col.key], row, rowIndex)}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default EasyTable;
