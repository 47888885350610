import { useEffect, useMemo, useState } from "react";
import { COMMUNITY_CLUP, USER } from "../api";
import marker from "../image/marker-circle.svg";
import SvgIcon from "../components/SvgIcon";
import user3Icon from "../image/userThree.svg";
import notebookIcon from "../image/Notebook.svg";

const IconBook = (
	<svg className="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M8.57133 2.40039V21.6004M17.4856 10.629H12.6856M17.4856 6.51468H12.6856M5.14276 6.51468H2.3999M5.14276 10.629H2.3999M5.14276 14.7432H2.3999M6.51419 21.6004H18.857C20.3719 21.6004 21.5999 20.3724 21.5999 18.8575V5.14325C21.5999 3.62841 20.3719 2.40039 18.857 2.40039H6.51419C4.99935 2.40039 3.77133 3.62841 3.77133 5.14325V18.8575C3.77133 20.3724 4.99935 21.6004 6.51419 21.6004Z"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
);

export default function MypageParticipationList() {
	const [communities, setCommunities] = useState([]);
	const [myConfirmCommunities, setMyConfirmCommunities]: any = useState();
	const [communityImg, setCommunityImg] = useState([]);
	const [me, setMe] = useState("");

	useEffect(() => {
		USER.getUser().then((res) => {
			setMe(res.id);

			COMMUNITY_CLUP.getMyParticipatedCommunity().then((data: any) => {
				setCommunities(
					data
						.filter((e: any) => e.userId !== res.id)
						.sort((a: any, b: any) => {
							return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
						})
				);
			});
		});
	}, []);

	const accompany = (id: any) => {
		COMMUNITY_CLUP.confirmCommunityClup(id)
			.then((res) => {
				COMMUNITY_CLUP.getMyParticipatedCommunity().then((data: any) => {
					const _id = data.find((e: any) => e.id === id)?.order.Children[0].id;

					if (_id) return (window.location.href = `/community/${_id}/pay`);
				});
			})
			.catch((e) => {
				alert("확정된 주문서가 없습니다.");
			});
	};

	const withdraw = (id: any) => {
		COMMUNITY_CLUP.withdrawCommunityClup(id)
			.then((res) => {
				alert("커뮤니티 클럽에서 탈퇴되었습니다.");
				window.location.reload();
			})
			.catch((e) => {
				alert("커뮤니티 클럽에서 탈퇴에 실패하였습니다.");
			});
	};

	function calculateDuration(startedAt: string, endedAt: string) {
		const startDate = new Date(startedAt);
		const endDate = new Date(endedAt);

		// 두 날짜의 차이를 밀리초로 계산
		const differenceInTime = endDate.getTime() - startDate.getTime();

		// 밀리초를 일 수로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
		const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

		// 박 수는 일 수에서 1을 뺀 값
		return `${differenceInDays}박${differenceInDays + 1}일`;
	}

	const OrderCard = ({ node, key, quotations = {} }: any) => {
		const partner = useMemo(() => {
			return node?.order?.PartnerQuotations?.find((v: any) => v.selectedAt)?.Partner;
		}, [node]);

		return (
			<>
				<div key={key} className="border border-[#dadada] p-[10px] mb-[20px] flex rounded-[2px] gap-3">
					<div
						className="flex-1"
						onClick={() => {
							window.location.href = `/community/${node.id}/details`;
						}}>
						<div
							className="w-full h-[194px] bg-center bg-cover rounded-[2px] relative cursor-pointer"
							style={{
								backgroundImage: `url(${node?.CommunityClupFile?.[0].File.url})`,
							}}>
							<div className="absolute w-full top-[5px] left-[5px]">
								<div className="font-spoqa text-[10px] truncate break-normal text-[#f5f5f5] font-medium bg-[#355c7d] py-[4px] px-[9px] absolute top-[5px] left-[5px] max-w-[90%]">
									{node.nation}·{node.city}/{calculateDuration(node.startedAt, node.endedAt)}
								</div>

								{node.type === "PLANNED" ? (
									<div className="absolute top-8 left-1 flex justify-start w-fit bg-[#A870B1] text-white text-[10px] font-medium px-[7px] py-[4px] gap-[5px] rounded-[2px] items-center  ">
										<img src={notebookIcon} />
										계획
									</div>
								) : (
									<div className=" bg-[#F67280] absolute top-8 left-1 flex w-fit text-white text-[10px] font-medium px-[7px] py-[4px] gap-[5px] rounded-[2px] items-center  ">
										<img src={user3Icon} />
										모집
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="flex-1 mt-1 flex flex-col">
						<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium ">{partner?.corporateBusinessName}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[8px] ">
							여행 형태:
							{node.type === "RECRUITMENT" && " 모집 주문"}
							{node.type === "ALONE" && " 단독 주문"}
							{node.type === "BUSINESS_TRIP" && " 출장 주문"}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">국가: {node.nation}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">도시: {node.city}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">
							{node.numberOfRecruits}명·{node.recruitGenderTypeText}·{node.ageGroup}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">
							{new Date(node.startedAt).toLocaleDateString().slice(0, -1)} ~ {new Date(node.endedAt).toLocaleDateString().slice(0, -1)}
						</div>
						<div className="flex-1 flex flex-col justify-end">
							{node.order?.Children?.find((e: any) => e?.userId === me)?.status === "PAYMENT_COMPLETE" ? (
								<div
									className={"h-[30px] flex justify-center items-center text-[14px] font-medium text-white bg-[#0057FF] mb-[5px]"}
									onClick={() => {
										window.location.href = `/community/${node.id}/details`;
									}}>
									참여 완료
								</div>
							) : (
								<div
									className={"h-[30px] flex justify-center items-center text-[14px] font-medium text-white bg-[#0057FF] mb-[5px] cursor-pointer"}
									onClick={() => {
										// window.location.href = `/community/${node.order.Children[0].id}/pay`
										if (node.type === "RECRUITMENT") {
											accompany(node.id);
										} else {
											alert("모집 주문만 참여 가능합니다.");
										}
									}}>
									참여 확정
								</div>
							)}
							<div
								onClick={() => {
									withdraw(node.id);
								}}
								className={[
									"h-[30px] flex justify-center items-center text-[14px] font-medium cursor-pointer",
									// node.status === "RECRUITMENT" && "text-white bg-[#0057FF]",
									node.status === "RECRUITMENT" && "text-[#777777] border border-[#DADADA] bg-white",
								].join(" ")}>
								참여 취소
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="w-full flex justify-center">
			<div className="flex justify-center max-w-[512px] w-full">
				<div className=" w-full px-3 pt-5 ">
					<div className="border-b border-[#dadada] pb-[23px] ">
						<div className="flex text-[14px] text-[#0057FF] font-medium gap-2 items-center ">
							<SvgIcon className="w-[24px] aspect-square bg-[#0057FF]" icon={marker} />
							참여 현황
						</div>
					</div>

					<div className="py-[20px]">
						{communities?.length ? (
							communities.map((node: any, i: any) => {
								return (
									<>
										<OrderCard node={node} key={i}></OrderCard>
									</>
								);
							})
						) : (
							<>
								<div className="h-[300px] flex mt-[150px] justify-center items-center flex-col">
									<div>아직 참여한 내역이 없어요😢</div>
									<div> ✈️지금 다른 커뮤니티에 참여하시겠어요?</div>
									<div
										className="px-[10px] mt-[150px] w-full h-10 flex justify-center items-center text-[#0057FF] border rounded-[2px]"
										onClick={() => {
											window.location.href = "/assemble_participate";
										}}>
										여행 참여하기
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
