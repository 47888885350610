import mammoth from "mammoth";
import { useEffect, useState } from "react";

export default function InternalTripPage() {
	const [docContent, setDocContent] = useState<string | null>(null);

	useEffect(() => {
		// 공개 URL을 사용하여 docx 파일 불러오기
		const docFilePath = "/policies/국내여행표준약관.docx";

		fetch(docFilePath)
			.then((response) => response.arrayBuffer())
			.then((arrayBuffer) => {
				mammoth
					.convertToHtml({ arrayBuffer: arrayBuffer })
					.then((result) => {
						setDocContent(result.value);
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error("Failed to fetch the docx file", err);
			});
	}, []);

	return (
		<div
			className="font-normal px-3 py-4 xl:py-8 xl:px-12"
			dangerouslySetInnerHTML={{
				__html: docContent || "",
			}}></div>
	);
}
