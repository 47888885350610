import { JSXElementConstructor, useEffect, useMemo, useState } from "react";
import email from "../../image/email.svg";
import cake from "../../image/cake.svg";
import heart from "../../image/heart-tick.svg";
import lock from "../../image/lock.svg";
import mobile from "../../image/mobile.svg";
import like from "../../image/like-dislike.svg";
import message from "../../image/message-question.svg";
import profile from "../../image/user-square.svg";
import notificationIcon from "../../image/notification.svg";
import participate from "../../image/participate.svg";
import receipt from "../../image/receipt.svg";
import notebook from "../../image/book.svg";
import wallet from "../../image/wallet.svg";
import globe from "../../image/globe.svg";
import markerCircle from "../../image/marker-circle.svg";
import marker from "../../image/marker.svg";
import board from "../../image/menu-board.svg";
import newNoti from "../../image/new-notification.svg";
import noti from "../../image/old-notification.svg";
import arrow from "../../image/arrow-square-right.svg";
import { DEFAULT, FILE, NOTIFICATION, USER } from "../../api";
import { logout } from "../../auth";
import Modal from "../modal";
import MyPageCommunityClupNotification from "../modal/MyPageUserNotification";
import UserChangePassword from "../modal/userChangePasseword";
import SvgIcon from "../SvgIcon";
import MyPageOrderNotification from "../modal/MyPageOrderClup";
import MyPageCommunityClupIdNotification from "../modal/MyPageCommunityClupId";

export default function MyPageUser() {
	const _menu: any[] = [
		{ icon: profile, tag: "프로필", page: "user" },
		{ icon: notificationIcon, tag: "알림", page: "notification" },
		{ icon: receipt, tag: "주문", page: "receipt" },
		{ icon: participate, tag: "참여", page: "participate" },
	];

	const [page, setPage] = useState("user");
	const [user, setUser]: any = useState("");
	const [birthday, setBirthday] = useState("");
	const [notifications, setNotifications] = useState<any>([]);
	const [notification, setNotification] = useState<any>([]);
	const [modal, setModal] = useState<any>(false);
	const [tripInfo, setTripInfo] = useState<any>([]);
	const [info, setInfo] = useState<any>([]);
	const [mbti, setMbti] = useState<any>([]);
	const [serviceConfig, setServiceConfig] = useState<any>(null);

	useEffect(() => {
		DEFAULT.getServiceConfig().then((res) => {
			setServiceConfig(res);
		});

		USER.getUser()
			.then((data) => {
				setUser(data);
				const birthday: string = data?.birthday?.split("-");
				setBirthday(`${birthday[0]}년 ${birthday[1]}월 ${birthday[2]}일`);

				setTripInfo(data.tripInfo);
				setInfo(data.info);
				setMbti(data.mbti);
			})
			.catch((e) => {
				if (e.statusCode === 404) {
					alert("탈퇴된 사용자 입니다.");
				}

				window.localStorage.removeItem("token");
				window.sessionStorage.removeItem("token");
				return (window.location.href = "/");
			});
	}, [modal]);

	useEffect(() => {
		if (user && user.id) {
			NOTIFICATION.getManyByUser(user.id).then((res) => {
				setNotifications(res);
			});
		}
	}, [user]);

	const handleDoneNotification = () => {
		setModal(null);
		if (user && user.id) {
			NOTIFICATION.getManyByUser(user.id).then((res) => {
				setNotifications(res);
			});
		}
	};

	const changeMyProfile = (type: any, value: any) => {
		USER.changeMyProfile({
			[type]: value,
		}).then((res) => {
			setModal(null);
		});
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;

		// 파일을 읽고 미리보기 URL을 설정합니다.
		if (file) {
			FILE.upload(file).then(async (res) => {
				await USER.uploadProfile(res.id);
				alert("프로필 사진이 변경되었습니다.");
				USER.getUser().then((data: any) => {
					setUser(data);
				});
			});
		}
	};

	const getNotification = async (type: any) => {
		setNotification(notifications.find((notification: any) => notification.referenceType === `${type}`));
		const data = notifications.find((notification: any) => notification.referenceType === `${type}`);
		if (!data) return alert("알림이 없습니다.");
		await NOTIFICATION.read(data?.id);
		if (data) {
			setModal(`${type}`);
		} else {
			alert("신청된 내역이 없습니다.");
		}
	};

	enum ModalStatus {
		ACCOUNT_CHANGE,
		BUSINESS_REGISTER,
		PASSWORD_CHANGE,
		WITHDRAW,
		NATION_CHANGE,
		CITY_CHANGE,
	}

	function TagList({ tag, value, btn, go, icon, multi = false, onClick = () => {} }: any) {
		return (
			<>
				<div className="flex justify-start gap-[8px] text-[#0E1111] text-[14px] font-medium mb-[20px] ">
					<div className="w-[20px] h-[20px] flex items-center justify-center">
						<img src={icon} />
					</div>
					<div className="w-[64px] box-border">{tag}</div>
					<div className="flex flex-1 justify-between box-border">
						{multi ? (
							<div className="flex-1 flex flex-wrap w-full gap-[5px]">
								{value?.map((e: any, i: any) => (
									<div key={i} className="">
										{" "}
										{`#${e}`}{" "}
									</div>
								))}
							</div>
						) : (
							<div className="flex-1 ">{value}</div>
						)}
						<div
							className={`w-[50px] h-[25px] text-xs text-[#777777] font-normal cursor-pointer ${
								btn && "border border-[#DADADA]"
							} flex justify-center items-center`}>
							{btn && (
								<div
									onClick={() => {
										onClick();
									}}>
									수정
								</div>
							)}
							{go && <img src={arrow} />}
						</div>
					</div>
				</div>
			</>
		);
	}

	const VersusTag = ({ value1, value2 }: any) => {
		const selectValue = (value: any) => {
			if (tripInfo.includes(value)) {
				setTripInfo([...tripInfo.filter((e: any) => e !== value)]);
			} else {
				setTripInfo([...tripInfo, value]);
			}
		};

		return (
			<div className="px-[10px] text-[14px] font-medium">
				<div className="flex justify-between items-center w-full gap-[10px]">
					<div
						className={`flex-1 h-[33px] border flex justify-center items-center ${
							tripInfo.includes(value1) ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
						}`}
						onClick={() => {
							selectValue(value1);
						}}>
						{value1}
					</div>
					VS
					<div
						className={`flex-1 h-[33px] border flex justify-center items-center ${
							tripInfo.includes(value2) ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
						}`}
						onClick={() => {
							selectValue(value2);
						}}>
						{value2}
					</div>
				</div>
			</div>
		);
	};

	const SelectTag = ({ value1, value2 }: any) => {
		const selectValue = (value: any) => {
			if (info.includes(value)) {
				setInfo([...info.filter((e: any) => e !== value)]);
			} else {
				setInfo([...info, value]);
			}

			info.includes(value);
		};

		return (
			<div className="px-[10px] text-[14px] font-medium">
				<div className="flex justify-between items-center w-full gap-[10px]">
					<div
						className={`flex-1 h-[33px] border flex justify-center items-center ${
							info.includes(value1) ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
						}`}
						onClick={() => {
							selectValue(value1);
						}}>
						{value1}
					</div>
					<div
						className={`flex-1 h-[33px] border flex justify-center items-center ${
							info.includes(value2) ? "border-[#0057FF] text-[#0057FF]" : "border-[#DADADA] text-[#0E1111]"
						}`}
						onClick={() => {
							selectValue(value2);
						}}>
						{value2}
					</div>
				</div>
			</div>
		);
	};

	function ReceiptTag({ tag, icon, onClick, alert = false }: any) {
		return (
			<>
				<div onClick={onClick} className="flex justify-start gap-[8px] text-[#0E1111] text-[14px] font-medium mb-[20px] cursor-pointer ">
					{alert ? <img className="w-5 h-5" src={icon} /> : <SvgIcon className="w-5 h-5 bg-black" icon={icon} />}
					<div className="w-[20px] h-[20px] flex items-center justify-center"></div>
					<div className="flex-1 flex justify-between">
						<div className="flex-1 box-border">{tag}</div>
						<div className="flex justify-between box-border">
							<img className="w-[24px]" src={arrow} />
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className="flex flex-col items-center">
			<div className="w-full box-border max-w-[512px]">
				<div className="py-[34px] px-[12px] border-b border-[#999999] flex items-center">
					<div className="w-[50px] aspect-square rounded-full bg-gray-400 overflow-hidden">
						<img className="w-full h-full object-cover" src={user.profileImageUrl}></img>
					</div>
					<div className="flex-1 pl-[14px]">
						<div className="text-[16px] font-bold">{user.nickname}</div>
						<div className="text-xs font-medium ">{user.name}</div>
					</div>

					<div
						onClick={logout}
						className="w-[70px] h-[25px] text-xs text-[#777777] font-normal border border-[#DADADA] flex justify-center items-center mr-[10px] cursor-pointer">
						로그아웃
					</div>
				</div>

				<div className="mt-[20px] mx-[14px]">
					<div className="relative top-1 z-0 flex gap-2">
						{_menu.map((e, i) => {
							return (
								<div
									className={`flex flex-col items-center justify-center gap-[10px] w-16 h-20  cursor-pointer ${
										page === e.page ? "border-r border-t border-l border-[#DADADA]  rounded-t-[10px] shadow-lg" : ""
									}`}
									onClick={() => {
										setPage(e.page);
									}}
									key={i}>
									<div
										className={`w-[24px] aspect-square  ${page === e.page ? "bg-[#0057FF]" : "bg-black"}`}
										style={{ maskImage: `url(${e.icon})`, WebkitMaskImage: `url(${e.icon})` }}></div>
									<div className={`${page === e.page ? "text-[#0057FF]" : "text-black"} text-[14px] font-medium`}>{e.tag}</div>
								</div>
							);
						})}
					</div>

					{page === "user" && (
						<div className="relative z-10 px-[14px] border border-[#DADADA] bg-white shadow-lg">
							<div className="h-[60px] flex items-center">계정관리</div>

							<div className="flex items-center gap-[24px] mb-[20px]">
								<label className="relative cursor-pointer">
									<div className="w-[90px] aspect-square rounded-full bg-gray-200 overflow-hidden">
										<img className="w-full h-full object-cover" src={user.profileImageUrl} alt="" />
									</div>
									<div className="absolute rounded-full flex justify-center items-center right-0 bottom-0 h-6 w-6 bg-[#999]">{IconCamera}</div>
									<input id="imgChange" className="hidden" type="file" onChange={handleImageChange} />
								</label>

								<div className="text-[16px] font-bold flex flex-col gap-[8px] flex-1">
									<div className="h-[20px]">{user.name}</div>
									<div className="h-[20px]">{user.nickname}</div>
								</div>
								<label
									htmlFor="imgChange"
									className="w-[50px] h-[25px] text-xs text-[#777777] font-normal border border-[#DADADA] flex justify-center items-center cursor-pointer">
									수정
								</label>
							</div>

							<div className="border-b">
								<TagList tag="이메일" value={user.email || ""} btn={false} onClick={() => {}} icon={email} />
								<TagList tag="생년월일" value={birthday || ""} btn={false} onClick={() => {}} icon={cake} />
								<TagList tag="전화번호" value={user.phone || ""} btn={false} onClick={() => {}} icon={mobile} />
							</div>

							<div className="mt-[20px] border-b">
								<TagList tag="비밀번호" value="" btn={true} onClick={() => setModal("PASSWORD_CHANGE")} icon={lock} />
							</div>

							<div className="mt-[20px] border-b">
								<TagList
									tag="여행 성향"
									value={user.tripInfo}
									multi={true}
									btn={true}
									onClick={() => {
										setModal("TRIPINFO_CHANGE");
									}}
									icon={heart}
								/>
							</div>

							<div className="mt-[20px] border-b">
								<TagList
									tag="기호"
									value={user.info}
									multi={true}
									btn={true}
									onClick={() => {
										setModal("INFO_CHANGE");
									}}
									icon={like}
								/>
							</div>

							<div className="mt-[20px]">
								<TagList
									tag="내 MBTI"
									value={user.mbti}
									btn={true}
									onClick={() => {
										setModal("MBTI_CHANGE");
									}}
									icon={message}
								/>
							</div>
						</div>
					)}

					{page === "notification" && (
						<div className="px-[14px] border relative z-10 bg-white ">
							{notifications.map((notification: any) => {
								let type: any = "ETC";
								if (notification.referenceType === "MY_COMMUNITY_CLUP_PARTICIPANT") {
									type = "MY_COMMUNITY_CLUP_PARTICIPANT";
								} else if (notification.referenceType === "REQUEST_COMMUNITY_CLUP_PARTICIPANT_RESULT") {
									type = "REQUEST_COMMUNITY_CLUP_PARTICIPANT_RESULT";
								} else if (notification.referenceType === "CREATE_ORDER_SCHEDULE") {
									type = "CREATE_ORDER_SCHEDULE";
								} else if (notification.referenceType === "REQUEST_PARTNER_QUOTATION_PAYMENT") {
									type = "REQUEST_PARTNER_QUOTATION_PAYMENT";
								} else if (notification.referenceType === "REQUEST_TRIP_CLOSED") {
									type = "REQUEST_TRIP_CLOSED";
								}
								return (
									<div className="mt-[20px] border-b">
										<ReceiptTag
											tag={notification?.title}
											onClick={async () => {
												await NOTIFICATION.read(notification.id);
												setNotification(notification);
												setNotifications(notifications.map((e: any) => (e.id === notification.id ? { ...e, isRead: true } : e)));
												setModal(type);
											}}
											icon={notification.isRead ? noti : newNoti}
											alert={true}
										/>
									</div>
								);
							})}
						</div>
					)}

					{page === "receipt" && (
						<div className="px-[14px] border relative z-10 bg-white">
							<div className="mt-[20px] border-b">
								<ReceiptTag
									tag="내 주문서"
									onClick={() => {
										window.location.href = "/mypage/myorder";
									}}
									icon={notebook}
								/>
							</div>
							<div className="mt-[20px] border-b">
								<ReceiptTag
									tag="주문서 결제/결제취소"
									onClick={() => {
										window.location.href = "/mypage/order/payment";
									}}
									icon={wallet}
								/>
							</div>
							<div className="mt-[20px]">
								<ReceiptTag
									tag="완료된 여행"
									onClick={() => {
										window.location.href = "/my/order/completed";
									}}
									icon={globe}
								/>
							</div>
						</div>
					)}

					{page === "participate" && (
						<div className="px-[14px] border relative z-10 bg-white">
							<div className="mt-[20px] border-b">
								<ReceiptTag
									tag="나의 모집"
									onClick={() => {
										window.location.href = "/mypage/community";
									}}
									icon={marker}
								/>
							</div>
							<div className="mt-[20px] border-b">
								<ReceiptTag
									tag="참여 현황"
									onClick={() => {
										window.location.href = "/mypage/community/attend";
									}}
									icon={markerCircle}
								/>
							</div>
							<div className="mt-[20px] border-b">
								<ReceiptTag
									tag="참여 결제/결제취소"
									onClick={() => {
										window.location.href = "/mypage/community/payed";
									}}
									icon={wallet}
								/>
							</div>
							<div className="mt-[20px] border-b">
								<ReceiptTag
									tag="일정표 확인"
									onClick={() => {
										window.location.href = "/mypage/schedule";
									}}
									icon={board}
								/>
							</div>
							<div className="mt-[20px]">
								<ReceiptTag
									tag="완료된 여행"
									onClick={() => {
										window.location.href = "	/mypage/completed";
									}}
									icon={globe}
								/>
							</div>
						</div>
					)}
				</div>
			</div>

			<Modal open={modal === "MY_COMMUNITY_CLUP_PARTICIPANT"} onClose={() => setModal(null)}>
				<MyPageCommunityClupNotification
					title="커뮤니티 클럽 참가 신청"
					notification={notification}
					onDone={handleDoneNotification}
					onClose={() => setModal(null)}
				/>
			</Modal>
			<Modal open={modal === "REQUEST_COMMUNITY_CLUP_PARTICIPANT_RESULT"} onClose={() => setModal(null)}>
				<MyPageCommunityClupIdNotification title="모집 참가 신청" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "CREATE_ORDER_SCHEDULE"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="일정 확인 요청" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "REQUEST_PARTNER_QUOTATION_PAYMENT"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="결제 요청" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "REQUEST_TRIP_CLOSED"} onClose={() => setModal(null)}>
				<MyPageOrderNotification title="여행 임박 알림" notification={notification} onDone={handleDoneNotification} onClose={() => setModal(null)} />
			</Modal>
			<Modal open={modal === "ETC"} onClose={() => setModal(null)}>
				<div className="flex justify-end" onClick={() => setModal(null)}>
					{IconClose}
				</div>

				<div>{notification?.title}</div>

				<div className="text-sm font-normal">{notification?.content}</div>

				<div
					onClick={() => setModal(null)}
					className="cursor-pointer mt-8 rounded-sm bg-success flex justify-center items-center text-sm text-white font-bold py-1.5">
					확인
				</div>
			</Modal>
			{modal === "PASSWORD_CHANGE" && <UserChangePassword value="" onClose={() => setModal(null)} onChange={() => {}} />}

			<Modal open={modal === "TRIPINFO_CHANGE"} onClose={() => setModal(null)}>
				<div>
					<div className="flex gap-[5px] mb-[20px]">
						<SvgIcon className="w-[20px] h-[20px] bg-black" icon={heart}>
							{" "}
						</SvgIcon>
						<div>여행 성향 변경</div>
					</div>
					<div className="mb-[10px]">중복선택 가능</div>

					<div className="flex flex-col gap-[10px] mb-[20px]">
						<VersusTag value1="휴양형" value2="액티비티형" />
						<VersusTag value1="도시형" value2="로컬형" />
						<VersusTag value1="랜드마크형" value2="모험형" />
					</div>

					<div
						onClick={() => {
							changeMyProfile("tripInfo", tripInfo);
							setModal(null);
						}}
						className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
						확인
					</div>
					<div onClick={() => setModal(null)} className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
						취소
					</div>
				</div>
			</Modal>

			<Modal open={modal === "INFO_CHANGE"} onClose={() => setModal(null)}>
				<div>
					<div className="flex gap-[5px] mb-[20px]">
						<SvgIcon className="w-[20px] h-[20px] bg-black" icon={heart}>
							{" "}
						</SvgIcon>
						<div>기호 변경</div>
					</div>
					<div className="mb-[10px]">중복선택 가능</div>

					<div className="flex flex-col gap-[10px] mb-[20px]">
						<SelectTag value1="가벼운 음주 좋아요" value2="흡연 괜찮아요" />
						<SelectTag value1="둘 다 상관없어요" value2="둘 다 싫어요" />
					</div>

					<div
						onClick={() => {
							changeMyProfile("info", info);
						}}
						className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
						확인
					</div>
					<div onClick={() => setModal(null)} className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
						취소
					</div>
				</div>
			</Modal>

			<Modal open={modal === "MBTI_CHANGE"} onClose={() => setModal(null)}>
				<div>
					<div className="flex gap-[5px] mb-[20px]">
						<SvgIcon className="w-[20px] h-[20px] bg-black" icon={heart}>
							{" "}
						</SvgIcon>
						<div>내 MBTI</div>
					</div>

					<select
						className="border border-[#DADADA] w-full px-[5px] text-[14px] font-normal h-[30px]"
						defaultValue={mbti}
						value={mbti}
						onChange={(v) => {
							setMbti(v.target.value);
						}}>
						<option value="ISTJ">ISTJ</option>
						<option value="ISFJ">ISFJ</option>
						<option value="INFJ">INFJ</option>
						<option value="INTJ">INTJ</option>
						<option value="ISTP">ISTP</option>
						<option value="ISFP">ISFP</option>
						<option value="INFP">INFP</option>
						<option value="INTP">INTP</option>
						<option value="ESTP">ESTP</option>
						<option value="ESFP">ESFP</option>
						<option value="ENFP">ENFP</option>
						<option value="ENTP">ENTP</option>
						<option value="ESTJ">ESTJ</option>
						<option value="ESFJ">ESFJ</option>
						<option value="ENFJ">ENFJ</option>
						<option value="ENTJ">ENTJ</option>
						<option value="모름">모름</option>
					</select>

					<div
						onClick={() => {
							changeMyProfile("mbti", mbti);
						}}
						className="mt-[30px] flex h-[30px] items-center justify-center bg-success text-sm font-bold text-white">
						확인
					</div>
					<div onClick={() => setModal(null)} className="mt-2.5 flex h-[30px] items-center justify-center border border-[#dadada] text-sm font-bold text-black">
						취소
					</div>
				</div>
			</Modal>

			{/* { modal === "INFO_CHANGE" && <UserChangeChange value={notification} onClose={() => setModal(null)} onChange={() => { }} />} */}
		</div>
	);
}

const IconCamera = (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
		<path
			d="M4.78813 15.5834H12.2115C14.1665 15.5834 14.9456 14.3863 15.0377 12.9271L15.406 7.07627C15.5052 5.54627 14.2869 4.25002 12.7498 4.25002C12.3177 4.25002 11.921 4.0021 11.7227 3.6196L11.2127 2.59252C10.8869 1.94794 10.0369 1.41669 9.31438 1.41669H7.69229C6.96271 1.41669 6.11271 1.94794 5.78688 2.59252L5.27688 3.6196C5.07854 4.0021 4.68188 4.25002 4.24979 4.25002C2.71271 4.25002 1.49438 5.54627 1.59354 7.07627L1.96188 12.9271C2.04688 14.3863 2.83313 15.5834 4.78813 15.5834Z"
			stroke="#F5F5F5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M7.4375 5.66669H9.5625" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M8.49935 12.75C9.76727 12.75 10.8014 11.7159 10.8014 10.448C10.8014 9.18004 9.76727 8.14587 8.49935 8.14587C7.23143 8.14587 6.19727 9.18004 6.19727 10.448C6.19727 11.7159 7.23143 12.75 8.49935 12.75Z"
			stroke="#F5F5F5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);
