import { differenceInCalendarDays, format } from "date-fns";
import { useMemo, useState } from "react";
import Modal from "../../modal";
import { DEFAULT, ORDER, PARTNER_QUOTATION } from "../../../api";
import { FILE } from "../../../admin_pages/api";

import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconReciept = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V8.5M9 9.5V8.5M9 9.5H11.0001M9 9.5C7.88279 9.49998 7.00244 9.62616 7.0001 10.8325C6.99834 11.7328 7.00009 12 9.00009 12C11.0001 12 11.0001 12.2055 11.0001 13.1667C11.0001 13.889 11 14.5 9 14.5M9 15.5V14.5M9 14.5L7.0001 14.5M14 10H17M14 20L16.025 19.595C16.2015 19.5597 16.2898 19.542 16.3721 19.5097C16.4452 19.4811 16.5147 19.4439 16.579 19.399C16.6516 19.3484 16.7152 19.2848 16.8426 19.1574L21 15C21.5523 14.4477 21.5523 13.5523 21 13C20.4477 12.4477 19.5523 12.4477 19 13L14.8426 17.1574C14.7152 17.2848 14.6516 17.3484 14.601 17.421C14.5561 17.4853 14.5189 17.5548 14.4903 17.6279C14.458 17.7102 14.4403 17.7985 14.405 17.975L14 20Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

async function downloadImage(url: string) {
	try {
		const response = await axios.get(url, {
			responseType: "blob",
		});
		return response.data;
	} catch (error) {
		console.error("이미지 다운로드 중 오류 발생:", error);
	}
}

const Row = ({ name, isLeader, phone, passport, visa, airticket }: any) => {
	return (
		<div className="flex text-xs font-normal items-center gap-1">
			<div className="w-10">{name}</div>
			<div className="w-4 flex-none flex justify-center">{isLeader ? IconCrown : null}</div>
			<div>{phone}</div>
			<div className="flex justify-end flex-1 gap-2">
				<div
					onClick={() =>
						passport &&
						FILE.findOne(passport).then((res) => {
							window.open(res.url, "_blank");
						})
					}
					className={[passport ? "text-success border border-[#dadada]" : "text-[#777] bg-[#ececec] border border-[#999]", "cursor-pointer py-1 px-2"].join(
						" "
					)}>
					여권
				</div>
				<div
					onClick={() =>
						visa &&
						FILE.findOne(visa).then((res) => {
							window.open(res.url, "_blank");
						})
					}
					className={[visa ? "text-success border border-[#dadada]" : "text-[#777] bg-[#ececec] border border-[#999]", "cursor-pointer py-1 px-2"].join(" ")}>
					비자
				</div>
				<div
					onClick={() =>
						airticket &&
						FILE.findOne(airticket).then((res) => {
							window.open(res.url, "_blank");
						})
					}
					className={[airticket ? "text-success border border-[#dadada]" : "text-[#777] bg-[#ececec] border border-[#999]", "cursor-pointer py-1 px-2"].join(
						" "
					)}>
					항공권
				</div>
			</div>
		</div>
	);
};

interface Props {
	quotation: any;
}

export default function PartnerMyPagePartnerQuotationReservationCard({ quotation }: Props) {
	const [modal, setModal] = useState<string | null>(null);
	const order = useMemo(() => {
		return quotation.Order;
	}, [quotation]);

	const [orderParticipants, setOrderParticipants] = useState<any[]>([]);
	const [participants, setParticipants] = useState<any[]>([]);

	const rangeText = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt))}박 ${
			differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt)) + 1
		}일`;
	}, [order]);

	const rangeText2 = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${startedAt} ~ ${endedAt}`;
	}, [order]);
	const dday = useMemo(() => {
		const today = new Date();
		const end = new Date(order.startedAt);
		const diff = differenceInCalendarDays(end, today);
		return diff;
	}, [order]);

	const handleSendTravelAlarm = () => {
		PARTNER_QUOTATION.sendTravelAlaram(quotation.id).then((res) => {
			alert("여행 임박 알림을 보냈습니다.");
		});
	};

	const handleSendRequestPayment = () => {
		PARTNER_QUOTATION.sendRequestPayment(quotation.id).then((res) => {
			alert("결제 요청을 보냈습니다.");
		});
	};

	const leader = useMemo(() => {
		if (participants.length === 0) {
			console.log("ledaer#1");
			return null;
		}

		if (!participants.find((v) => v.userId === v?.CommunityClup?.userId)) {
			console.log("ledaer#2");
			return null;
		}
		console.log("ledaer#3");

		return true;
	}, [participants, order]);

	return (
		<div className="flex flex-col border border-[#dadada] rounded-sm">
			<div className=" flex p-2.5 gap-3">
				<div
					className={["relative bg-cover bg-center flex-none w-[171px]", "h-[128px]"].join(" ")}
					style={{
						backgroundImage: `url(/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg)`,
					}}>
					<div className="absolute left-1 top-1 text-white font-medium text-[10px] px-2 py-0.5 rounded-sm bg-[#355c7d]">
						{order.nation}/{rangeText}
					</div>
					<div className="absolute text-white flex justify-center items-center py-1 w-full bg-[#202C4A] bg-opacity-70 left-0 bottom-0">
						{dday > 0 && <span className="text-[#F5B826]">여행까지 D-{dday}</span>}
						{dday === 0 && <span className="text-[#F5B826]">출발 완료</span>}
					</div>
				</div>
				<div className="flex-1 text-black text-[13px] font-medium pt-2 flex flex-col gap-1">
					<div className="truncate max-w-[142px]">
						여행 형태:&nbsp;
						{order.type === "RECRUITMENT" && "모집 주문"}
						{order.type === "ALONE" && "단독 주문"}
						{order.type === "BUSINESS_TRIP" && "출장 주문"}
					</div>
					<div className="flex justify-between pr-7 max-w-[142px] truncate">
						<div className="whitespace-nowrap">국가: {order.nation}</div>
						<div className="whitespace-nowrap">도시: {order.city}</div>
					</div>
					<div>
						{order.numberOfRecruits}명·{order.guideAvailability ? "가이드필요" : "가이드필요없음"}
					</div>
					<div className="truncate max-w-[142px]">
						{order.accommodation}·{order.transportation}
					</div>
					<div className="tracking-[-1px] ">{rangeText2}</div>
				</div>
			</div>

			<div className="grid grid-cols-2 gap-y-2.5 gap-x-4 p-3">
				<div
					onClick={async () => {
						setParticipants(await ORDER.getCommunityPartcipants(order.id));
						const participants = await ORDER.getParticipants(order.id);
						setOrderParticipants(participants);
						setModal("reservation");
					}}
					className="cursor-pointer border font-medium text-xs text-success flex justify-center py-1.5 items-center border-[#dadada] ">
					예약 정보확인
				</div>
				<div
					onClick={() => (window.location.href = `/order/schedule/${quotation.orderId}`)}
					className="cursor-pointer border font-medium text-xs text-success flex justify-center py-1.5 items-center border-[#dadada] ">
					일정표 확인하기
				</div>
				<div
					onClick={handleSendTravelAlarm}
					className="cursor-pointer border font-medium text-xs text-success flex justify-center py-1.5 items-center border-[#dadada] ">
					여행 임박 알림보내기
				</div>
				<div
					onClick={() => {
						if (order.status === "CONFIRM") {
							handleSendRequestPayment();
						} else {
							alert("이미 결제된 상품 입니다.");
						}
					}}
					className={[
						"cursor-pointer border font-medium border-[#dadada] text-xs flex justify-center py-1.5 items-center ",
						order.status === "CONFIRM" ? "text-success " : "text-[#777] bg-gray-100",
					].join(" ")}>
					결제 요청
				</div>
			</div>

			{/* const Row = ({name, isLeader, phone, passport, visa, airticket}: any) => { */}
			<Modal
				open={modal === "reservation"}
				onClose={() => {
					setModal("");
				}}>
				<div className="font-bold">예약 정보 확인</div>
				<div className="flex flex-col gap-2">
					{/* <Row
						name={order?.User?.name}
						isLeader={true}
						phone={order?.User?.phone}
						passport={order?.OrderFiles?.find((link: any) => link.type === "PASSPORT")}
						visa={order?.OrderFiles?.find((link: any) => link.type === "VISA")}
						airticket={order?.OrderFiles?.find((link: any) => link.type === "AIR_TICKET")}
					/> */}

					{order.type !== "RECRUITMENT" &&
						orderParticipants?.map((participant: any) => {
							return (
								<Row
									name={participant?.name}
									isLeader={participant.isLeader}
									phone={participant?.phone}
									passport={participant?.passportFileId}
									visa={participant?.visaFileId}
									airticket={participant?.airTicketFileId}
								/>
							);
						})}

					{order.type === "RECRUITMENT" && (
						<Row
							name={order?.User?.name}
							isLeader={true}
							phone={order?.User?.phone}
							passport={order?.OrderFiles?.find((link: any) => link.type === "PASSPORT")?.fileId}
							visa={order?.OrderFiles?.find((link: any) => link.type === "VISA")?.fileId}
							airticket={order?.OrderFiles?.find((link: any) => link.type === "AIR_TICKET")?.fileId}
						/>
					)}

					{order.type === "RECRUITMENT" &&
						removeDuplicates(participants ?? [])
							?.filter((v: any) => v?.userId !== v?.CommunityClup?.userId)
							.map((participant: any) => {
								const isLeader = participant?.userId === participant?.CommunityClup?.userId;
								const passport = participant?.myOrder?.OrderFiles?.find((link: any) => link.type === "PASSPORT")?.fileId;
								const visa = participant?.myOrder?.OrderFiles?.find((link: any) => link.type === "VISA")?.fileId;
								const airticket = participant?.myOrder?.OrderFiles?.find((link: any) => link.type === "AIR_TICKET")?.fileId;
								return (
									<Row
										name={participant?.User?.name}
										isLeader={isLeader}
										phone={participant?.User?.phone}
										passport={passport}
										visa={visa}
										airticket={airticket}
									/>
								);
							})}
				</div>

				<div
					onClick={async () => {
						const urls = await Promise.all(
							orderParticipants
								.flatMap((participant) => {
									return [participant?.passportFileId, participant?.visaFileId, participant?.airTicketFileId];
								})
								.filter(Boolean)
								.map((v) => FILE.findOne(v))
						);

						try {
							const res = await DEFAULT.urlToZip(urls.map((url) => url?.url).filter(Boolean));
							window.open(res, "_blank");
						} catch (error) {
							console.error("Error during file download:", error);
						}
					}}
					className="cursor-pointer text-white flex justify-center items-center bg-success py-1.5 mt-8">
					전체 파일 다운로드
				</div>
				<div
					className="cursor-pointer text-[#424549] border border-[#dadadaa] flex justify-center items-center bg-white py-1.5 mt-2.5"
					onClick={() => setModal("")}>
					취소
				</div>
			</Modal>
		</div>
	);
}

const IconCrown = (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path
			d="M11.1802 12.6532H4.82016C4.54016 12.6532 4.28683 12.4732 4.19349 12.2132L1.42016 4.44653C1.20016 3.82653 1.90683 3.29986 2.43349 3.67986L5.10016 5.58653C5.45349 5.83986 5.96016 5.68653 6.11349 5.27986L7.37349 1.91986C7.58683 1.33986 8.40683 1.33986 8.62016 1.91986L9.88016 5.27986C10.0335 5.6932 10.5335 5.83986 10.8935 5.58653L13.5602 3.67986C14.0935 3.29986 14.7935 3.8332 14.5735 4.44653L11.8002 12.2132C11.7135 12.4732 11.4602 12.6532 11.1802 12.6532Z"
			fill="#202C4A"
		/>
		<path
			d="M11.3346 14.6665H4.66797C4.39464 14.6665 4.16797 14.4398 4.16797 14.1665C4.16797 13.8932 4.39464 13.6665 4.66797 13.6665H11.3346C11.608 13.6665 11.8346 13.8932 11.8346 14.1665C11.8346 14.4398 11.608 14.6665 11.3346 14.6665Z"
			fill="#F5B826"
		/>
		<path
			d="M9.66536 9.8335H6.33203C6.0587 9.8335 5.83203 9.60683 5.83203 9.3335C5.83203 9.06016 6.0587 8.8335 6.33203 8.8335H9.66536C9.9387 8.8335 10.1654 9.06016 10.1654 9.3335C10.1654 9.60683 9.9387 9.8335 9.66536 9.8335Z"
			fill="#F5B826"
		/>
	</svg>
);

function removeDuplicates(participants: any) {
	const unique = {} as any;
	participants.forEach((participant: any) => {
		unique[participant.userId] = participant;
	});
	return Object.values(unique);
}
