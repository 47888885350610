import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RECOMMENDATION_TRIP } from "../../../admin_pages/api";
import EasyTable from "../../../components/EasyTable";
import Modal from "../../../components/modal";
import FormFactory from "../../../components/FormFactory";

export default function AdminRecommendTripsIdPage() {
	const { recommendation_trip_id } = useParams();
	const [data, setData] = useState<any>(null);
	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		if (!recommendation_trip_id) return;

		RECOMMENDATION_TRIP.findOne(+recommendation_trip_id).then((response: any) => {
			setData(response);
		});
	};

	const handleUpdate = async (formData: any) => {
		await RECOMMENDATION_TRIP.update(data.id, {
			...formData,
			RecommendationTripFile: {
				deleteMany: { AND: [] },
				create: {
					File: {
						connect: {
							id: formData.RecommendationTripFile.id,
						},
					},
					type: "RECOMMENDATION_TRIP_IMAGE",
				},
			},
		}).then((response: any) => {
			getData();
			setModal("");
		});
	};

	const handleDelete = async (id: number) => {
		await RECOMMENDATION_TRIP.delete(data.id);
		window.location.href = "/admin/dashboard/recommend-trips";
	};

	const defaultImage = useMemo(() => {
		return data?.RecommendationTripFile?.find((file: any) => {
			return file.type === "RECOMMENDATION_TRIP_IMAGE";
		})?.File;
	}, [data]);

	return (
		<div>
			<h1>추천 여행 {recommendation_trip_id}</h1>
			<div className="font-bold mt-4">정보</div>
			<div className="flex flex-col max-w-3xl font-medium">
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">id</div>
					<div>{data?.id}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">제목</div>
					<div>{data?.name}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">설명</div>
					<div>{data?.description}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">내용</div>
					<div>{data?.content}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">태그</div>
					<div>{data?.tags}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">좋아요 수</div>
					<div>{data?.countOfLike}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">댓글 수</div>
					<div>{data?.countOfComment}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">생성날짜</div>
					<div>{data?.createdAt}</div>
				</div>
			</div>

			<div className="flex w-full max-w-3xl justify-end gap-4">
				<button
					onClick={() => {
						handleDelete(data.id);
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
				<button
					onClick={() => {
						setModal("update");
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			</div>

			<div className="font-bold mt-4">파일 목록</div>
			<div className="mt-4 max-w-3xl overflow-x-scroll">
				{data?.RecommendationTripFile ? (
					<EasyTable
						data={data?.RecommendationTripFile}
						columns={[
							{ header: "id", key: "id" },
							{ header: "type", key: "type" },
							{ header: "url", key: "File", render: (File) => <button onClick={() => window.open(File?.url, "__blank")}>{File?.url}</button> },
							{ header: "name", key: "File", render: (File) => <div>{File?.name}</div> },
							{ header: "size", key: "File", render: (File) => <div>{File?.size}</div> },
						]}
					/>
				) : (
					"파일이 없습니다."
				)}
			</div>

			<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
				<div>수정</div>

				<FormFactory
					schema={[
						{ label: "제 목", key: "name", method: "text", validation: "required", defaultValue: data?.name },
						{ label: "설 명", key: "description", method: "text", validation: "required", defaultValue: data?.description },
						{ label: "이미지", key: "RecommendationTripFile", method: "file", validation: "required", defaultValue: defaultImage },
						{ label: "내 용", key: "content", method: "wizwig", validation: "required", defaultValue: data?.content },
						{ label: "국 가", key: "category1", method: "text", validation: "required", defaultValue: data?.category1 },
						{ label: "도 시", key: "category2", method: "text", validation: "required", defaultValue: data?.category2 },
						{ label: "태 그", key: "tags", method: "text", validation: "required", defaultValue: data?.tags },
					]}
					onSubmit={handleUpdate}
				/>
			</Modal>
		</div>
	);
}
