// @ts-nocheck

import React, { useRef, useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";

export default function GoogleMap({ location, className = "" }) {
	const googleMapRef = useRef(null);
	const googleMap = useRef(null);

	// 지도를 초기화합니다. 기본 위치로 서울을 설정합니다.
	useEffect(() => {
		googleMap.current = new window.google.maps.Map(googleMapRef.current, {
			zoom: 5,
			center: { lat: 37.5665, lng: 126.978 },
			disableDefaultUI: true,
		});
	}, []);

	// 위치로 지도의 위치를 변경하는 함수입니다.
	const moveToLocation = async (query) => {
		try {
			const { location, zoomLevel } = await getCoordinates(query);
			googleMap.current.panTo(new window.google.maps.LatLng(location.lat, location.lng));
			googleMap.current.setZoom(zoomLevel);
		} catch (error) {
			console.error("Error finding location: ", error);
		}
	};

	const debouncedMoveToLocation = useCallback(debounce(moveToLocation, 500), []);

	useEffect(() => {
		if (location) {
			debouncedMoveToLocation(location);
		}
	}, [location, debouncedMoveToLocation]);

	return (
		<div className={className}>
			<div ref={googleMapRef} className="w-full h-full" />
		</div>
	);
}

// 주소로 좌표를 검색하는 함수입니다.
const getCoordinates = async (query) => {
	const response = await fetch(
		// TODO 키 교체
		// `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=AIzaSyD5eK-zT8fqMv4P7yHa_XG7sygt6KSIwVY`
		// `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=AIzaSyD5eK-zT8fqMv4P7yHa_XG7sygt6KSIwVY`
		`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=AIzaSyBN2gnw7kWm0jbSbxZo4nIyTmf3ftgOO4o`
	);
	const data = await response.json();
	if (data.results.length > 0) {
		const location = data.results[0].geometry.location;
		const types = data.results[0].types;
		const zoomLevel = determineZoomLevel(types);
		return { location, zoomLevel };
	} else {
		throw new Error("Location not found", query);
	}
};

// 타입 배열을 검사하여 줌 레벨을 결정하는 함수입니다.
const determineZoomLevel = (types) => {
	if (types.includes("locality")) {
		return 10; // 도시 레벨
	} else if (types.includes("sublocality") || types.includes("neighborhood")) {
		return 13; // 구 또는 동네 레벨
	} else if (types.includes("route")) {
		return 15; // 도로 레벨
	} else if (types.includes("street_address")) {
		return 17; // 구체적인 주소 레벨
	}
	return 8; // 기본 줌 레벨
};
