// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";

const MODAL_STYLES = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#FFF",
	boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
	width: "100%",
	zIndex: 1000,
};

const OVERLAY_STYLES = {
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(0, 0, 0, .4)",
	zIndex: 1000,
};

function Modal({ open, children, onClose, size = "md" }: any) {
	if (!open) return null;

	const maxW = {
		sm: "max-w-[350px]",
		md: "max-w-[350px] max-h-[500px]",
		lg: "max-w-[700px] max-h-[700px]",
		xl: "max-w-[900px]",
	};

	return ReactDOM.createPortal(
		<>
			<div onClick={onClose} style={OVERLAY_STYLES} />
			<div className={["max-w-[350px] max-h-[500px] overflow-y-auto px-3.5 py-6", maxW[size]].join(" ")} style={MODAL_STYLES}>
				{children}
			</div>
		</>,
		document.getElementById("portal")
	);
}

export default Modal;
