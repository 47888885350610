import { differenceInCalendarDays, format } from "date-fns";
import { useMemo } from "react";

interface Props {
	order: any;
}

export default function PartnerMyPagePartnerChatCard({ order, state, me }: any) {
	const rangeText = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt))}박 ${
			differenceInCalendarDays(new Date(order.endedAt), new Date(order.startedAt)) + 1
		}일`;
	}, [order]);

	const rangeText2 = useMemo(() => {
		const startedAt = format(new Date(order.startedAt), "yyyy.MM.dd");
		const endedAt = format(new Date(order.endedAt), "yyyy.MM.dd");

		return `${startedAt} ~ ${endedAt}`;
	}, [order]);

	const statusText = useMemo(() => {
		if (!me) {
			return "";
		}
		const myQuotation = order.PartnerQuotations?.find((v: any) => v.partnerId === me.id);

		if (!myQuotation) {
			return "";
		} else {
			try {
				const lastChat = myQuotation?.PartnerQuotationChats?.[myQuotation?.PartnerQuotationChats?.length - 1];
				if (lastChat.partnerId === me.id) {
					return "답변 완료";
				} else {
					return "답변 대기중";
				}
			} catch (e) {
				return "답변 대기중";
			}
		}
	}, [order, me]);

	return (
		<div className="border border-[#dadada] rounded-sm flex p-2.5 gap-3 ">
			<div
				className={["relative bg-cover bg-center flex-none w-[171px] cursor-pointer", "w-[50%]"].join(" ")}
				style={{
					backgroundImage: `url(/temp/28a294d808bf909dfb7438dcfb19b8cb.jpeg)`,
				}}>
				<div className="absolute left-1 top-1 text-white font-medium text-[10px] px-2 py-0.5 rounded-sm bg-[#355c7d]">
					{order.nation}/{rangeText}
				</div>
				{statusText.length > 0 && <div className="w-full bg-opacity-75 text-center absolute bottom-0 bg-black text-[#F5B826] py-1">{statusText}</div>}
			</div>
			<div className="flex-1 text-black text-[13px] font-medium py-2 flex flex-col gap-1">
				<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium ">{order.Partner?.corporateBusinessName}</div>
				<div>
					여행 형태:&nbsp;
					{order.type === "RECRUITMENT" && " 모집 주문"}
					{order.type === "ALONE" && " 단독 주문"}
					{order.type === "BUSINESS_TRIP" && " 출장 주문"}
				</div>
				<div className="flex max-w-[142px] gap-1 sm:max-w-none sm:gap-4 pr-7 truncate">
					<div className="whitespace-nowrap">국가: {order.nation}</div>
					<div className="whitespace-nowrap">&nbsp;도시: {order.city}</div>
				</div>
				<div>
					{order.numberOfRecruits}명·{order.guideAvailability ? "가이드필요" : "가이드필요없음"}
				</div>
				<div className="truncate max-w-[142px]">
					{order.accommodation}·{order.transportation}
				</div>
				<div className="tracking-[-1px]">{rangeText2}</div>
				<div className="bg-[#0057FF] flex justify-center items-center text-white h-[30px] cursor-pointer">
					{state === "CONFIRM" ? "답변 완료" : "답변 작성"}
				</div>
				<div className="border border-[#DADADA] flex justify-center items-center text-[#777777] h-[30px] cursor-pointer">주문서 상세보기</div>
			</div>
		</div>
	);
}
