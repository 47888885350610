import { useEffect, useState } from "react";
import InputLabel from "./input/labe";
import InputListAndText from "./input/list-add-text";
import InputSelect from "./input/selects";
import InputListCol2Multi from "./input/list-col-2-multi";

export default function CommunityClupFilter({ onChange }: any) {
	const [selectOrder, setSelectOrder] = useState<any>({
		numberOfRecruits: null,
		recruitGenderType: null,
		ageGroup: null,
		tripType: [],
		preference: [],
	});

	const recruitNumberOptions = [
		{ label: "1", value: "1" },
		{ label: "2", value: "2" },
		{ label: "3", value: "3" },
		{ label: "4", value: "4" },
		{ label: "5", value: "5" },
		{ label: "6", value: "6" },
		{ label: "7", value: "7" },
		{ label: "8", value: "8" },
		{ label: "9", value: "9" },
		{ label: "10", value: "10" },
	];

	const travelOptions = [
		{ label: "휴양형", value: "휴양형" },
		{ label: "액티비티형", value: "액티비티형" },
		{ label: "도시형", value: "도시형" },
		{ label: "로컬형", value: "로컬형" },
		{ label: "랜드마크형", value: "랜드마크형" },
		{ label: "모험형", value: "모험형" },
	];

	const preferenceOptions = [
		{ label: "가벼운 음주 좋아요", value: "가벼운 음주 좋아요" },
		{ label: "흡연 괜찮아요", value: "흡연 괜찮아요" },
		{ label: "둘 다 상관 없어요", value: "둘 다 상관 없어요" },
		{ label: "둘 다 싫어요", value: "둘 다 싫어요" },
	];

	const handleChange = (key: string, value: any) => {
		setSelectOrder({
			...selectOrder,
			[key]: value,
		});
	};

	const handleChangeList = (key: string, value: any) => {
		if (selectOrder[key] && selectOrder[key].includes(value)) {
			setSelectOrder({
				...selectOrder,
				[key]: selectOrder[key].filter((v: any) => v !== value),
			});
			return;
		}

		setSelectOrder({
			...selectOrder,
			[key]: value,
		});
	};

	return (
		<div className="w-full border-b border-[#cecece] mb-4 pb-4">
			<InputLabel title="모집 인원" className="mt-[20px] xl:mt-[32px]" />
			<InputListAndText
				list={recruitNumberOptions}
				value={selectOrder.numberOfRecruits}
				subValue={""}
				onChange={(v) => handleChange("numberOfRecruits", v)}
				onChangeDesc={(v) => {}}
				contentAlign="justify-around"
				className="mt-[10px] xl:mt-[16px]"
			/>

			<div className="flex gap-3 mt-[20px] xl:mt-[32px]">
				<div className="flex-1">
					<InputLabel title="모집 유형" required={true} />
					<InputSelect
						value={selectOrder.recruitGenderType}
						className="mt-[10px]"
						onChange={(v) => handleChange("recruitGenderType", v)}
						options={[
							{
								label: "남녀무관",
								value: "All",
							},
							{ label: "남자", value: "Male" },
							{ label: "여자", value: "Female" },
						]}
					/>
				</div>
				<div className="flex-1">
					<InputLabel title="모집 연령" required={true} />
					<InputSelect
						value={selectOrder.ageGroup}
						className="mt-[10px]"
						onChange={(v) => handleChange("ageGroup", v)}
						options={[
							{ label: "20대", value: "20대" },
							{ label: "30대", value: "30대" },
							{ label: "40대", value: "40대" },
							{ label: "50대", value: "50대" },
							{ label: "60대", value: "60대" },
						]}
					/>
				</div>
			</div>

			<InputLabel title="여행 성향" className="mt-5" />
			<InputListCol2Multi value={selectOrder.tripType} list={travelOptions} onChange={(v: any) => handleChangeList("tripType", v)} className="mt-2.5" />

			{/* TODO 서버문제로 임시제거  */}
			{/* <InputLabel title="기호 선택" className="mt-5" />
			<InputListCol2Multi value={selectOrder.preference} list={preferenceOptions} onChange={(v: any) => handleChangeList("preference", v)} className="mt-2.5" /> */}

			<button onClick={() => onChange(selectOrder)} className="w-full bg-success rounded-sm flex justify-center itmes-center font-bold text-white py-2.5 mt-10">
				설정
			</button>
		</div>
	);
}
