import client from "./axios";

export const AUTH = {
  login(email: string, password: string) {
    return client.post('/auth/admin/signin', {
      email,
      password
    }).then(res => res.data)
  }
}

export const USER = {
  paginate(page: number, perPage: number, search: string) {
    return client.get('/admin/users', {
      params: {
        page,
        perPage: perPage,
        search
      }
    }).then(res => res.data)
  },
  findOne(id: number) {
    return client.get(`/admin/users/${id}`).then(res => res.data)
  },
  create(
    email: string,
    nickname: string,
    password: string,
    name: string,
    birthday: string,
    gender: string,
    phone: string,
    tripInfo: string[],
    info: string[],
    mbti: string,
    termsOfServiceAgreement: Date,
    privacyPolicyAgreement: Date,
    marketingAgreement?: Date | null,
    ageAgreement?: Date
  ) {
    return client.post('/admin/users', {
      oauthType: 'NORMAL',
      email,
      nickname,
      password,
      name,
      birthday,
      gender,
      phone,
      tripInfo,
      info,
      mbti,
      termsOfServiceAgreement,
      privacyPolicyAgreement,
      marketingAgreement,
      ageAgreement,
    })
  },
  update(
    id: number,
    nickname: string,
    name: string,
    birthday: string,
    gender: string,
    phone: string,
    tripInfo: string[],
    info: string[],
    mbti: string,
    termsOfServiceAgreement: Date,
    privacyPolicyAgreement: Date,
    marketingAgreement?: Date,
    ageAgreement?: Date
  ) {
    return client.put(`/admin/users/${id}`, {
      nickname,
      name,
      birthday,
      gender,
      phone: `${phone}`,
      tripInfo,
      info,
      mbti,
      termsOfServiceAgreement,
      privacyPolicyAgreement,
      marketingAgreement,
      ageAgreement,
    })

  },
  delete(id: number) {
    return client.delete(`/admin/users/${id}`)
  },
  resetPassword(id: number) {
    return client.post(`/auth/user/reset-password`, {
      userId: id
    })
  }
}

export const PARTNER = {
  paginate(page: number, perPage: number, search: string) {
    return client.get('/admin/partners', {
      params: {
        page,
        perPage: perPage,
        search
      }
    }).then(res => res.data)
  },
  create(
    email: string,
    password: string,
    name: string,
    nation: string,
    city: string,
    corporateBusinessName: string,
    address: string,
    position: string,
    mobilePhone: string,
    landlinePhone: string,
    businessNumber: string,
    description: string,
    businessRegistrationFileId: number,
    accountFileId: number
  ) {
    return client.post('/admin/partners', {
      email,
      password,
      name,
      nation,
      city,
      corporateBusinessName,
      address,
      position,
      mobilePhone,
      landlinePhone,
      businessNumber,
      description,
      businessRegistrationFileId,
      accountFileId
    })
  },
  update(
    id: number,
    email: string,
    password: string,
    name: string,
    nation: string,
    city: string,
    corporateBusinessName: string,
    address: string,
    position: string,
    mobilePhone: string,
    landlinePhone: string,
    businessNumber: string,
    description: string,
    businessRegistrationFileId: number,
    accountFileId: number
  ) {
    return client.put(`/admin/partners/${id}`, {
      email,
      password,
      name,
      nation,
      city,
      corporateBusinessName,
      address,
      position,
      mobilePhone,
      landlinePhone,
      businessNumber,
      description,
      businessRegistrationFileId,
      accountFileId
    })
  },
  delete(id: number) {
    return client.delete(`/admin/partners/${id}`)
  },
  confirm(id: number, requestId: number) {
    return client.put(`/admin/partners/${id}/approve/${requestId}`)
  },
  resetPassword(id: number) {
    return client.post(`/auth/partner/reset-password`, {
      partnerId: id
    })
  }
}

export const EVENT = {
  pagination(page: number, perPage: number) {
    return client.get('/admin/events', {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },
  create(data: any) {
    return client.post('/admin/events', data).then(res => res.data)
  },
  update(id: number, data: any) {
    return client.put(`/admin/events/${id}`, data)
  },
  delete(id: number) {
    return client.delete(`/admin/events/${id}`)
  }
}

export const POPUP = {
  pagination(page: number, perPage: number) {
    return client.get('/admin/popup', {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },
  getList() {
    return client.get('/admin/popup/list',).then(res => res.data)
  },
  create(data: any) {
    console.log(`title: ${data.title}, isPopup: ${data.isPopup}`)
    return client.post('/admin/popup', data).then(res => res.data)
  },
  update(id: number, data: any) {
    console.log(`title: ${data.title}, isPopup: ${data.isPopup}`)
    return client.put(`/admin/popup/${id}`, data)
  },
  delete(id: number) {
    return client.delete(`/admin/popup/${id}`)
  }
}

export const FILE = {
  async findOne(id: number) {
    return client.get(`/files/${id}`).then(res => res.data)
  }
}

export const ORDER = {
  async pagination(page: number, perPage: number, status?: string, startedAt?: string) {
    return client.get('/admin/orders', {
      params: {
        page,
        perPage: perPage,
        status,
        startDate: startedAt,

      }
    }).then(res => res.data)
  },
  async findOne(id: number) {
    return client.get(`/admin/orders/${id}`).then(res => res.data)
  },
  async update(
    id: number,
    data: any
  ) {
    return client.put(`/admin/orders/${id}`, data)
  },
  async delete(id: number) {
    return client.delete(`/orders/${id}`)
  },
  async createSchedule(orderId: number) {
    return client.post(`/order-schedule`, {
      orderId: `${orderId}`
    }).then(res => res.data)
  },
  async updateStatus(
    id: number,
    data: any
  ) {
    return client.post(`/admin/orders/${id}/update-status`, data)
  },
  async updateMemo(
    id: number,
    data: any
  ) {
    return client.put(`/admin/orders/${id}/memo`, data)
  },
}

export const PARTNER_QUOTATION = {
  async updateInvoiceStatus(id: number, data: any) {
    return client.patch(`admin/partner-quotations/${id}/invoice-status`, data)
  },
  getOne(id: number) {
    return client.get(`partner-quotations/${id}/admin`).then(res => res.data)
  }
}

type MessageType = "TEXT" | "POLL_NOTICE" | "SCHEDULE" | "IMAGE";
interface MessageSendParams {
  pollId?: number;
  scheduleId?: number;
}

export const COMMUNITY_CLUP = {
  async pagination(page: number, perPage: number) {
    return client.get('/admin/community-clubs', {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },
  async getChatRoom(id: number) {
    return client.get(`/admin/community-clubs/${id}/chats`).then(res => res.data)
  },
  async getChatRoomAndSendMessage(id: number, chatroomId: number | string, content: string, type: MessageType = "TEXT", params?: MessageSendParams) {
    console.log(`getChatRoomAndSendMessage ==> communityClupId: ${id}, chatRoomId: ${chatroomId}`);
    return client
      .post(`/admin/community-clubs/${id}/chats`, {
        chatRoomId: +chatroomId,
        content,
        type,
        ...params,
      })
      .then((res) => res.data);
  },
  async find(id: number) {
    return client.get(`/admin/community-clubs/${id}`).then(res => res.data)
  },
  async update(
    id: number,
    data: any
  ) {
    return client.put(`/admin/community-clubs/${id}`, data)
  },
  delete(id: number) {
    return client.delete(`/admin/community-clubs/${id}`)
  }
}

export const PAYMENT = {
  async pagination(page: number, perPage: number) {
    return client.get(`/admin/payments`, {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },
  async findOne(id: number) {
    return client.get(`/admin/payments/${id}`).then(res => res.data)
  },
  async update(
    id: number,
    data: any
  ) {
    return client.put(`/admin/payments/${id}`, data)
  },
  async refundComplete(id: number) {
    return client.post(`/admin/payments/${id}/refund`)
  },
  async regundReject(id: number) {
    return client.post(`/admin/payments/${id}/refund-reject`)
  },
  async confirmBankTransfer(id: number) {
    return client.post(`/admin/payments/${id}/complete`)
  }
}

export const DEFAULT = {
  async serviceConfig() {
    return client.get('/service-config').then(res => res.data)
  },
  async updateServiceConfig(
    data: any
  ) {
    return client.post('/service-config', data)
  },
  async logCount() {
    return client.get('/log/count').then(res => res.data)
  },

  async logCountExcel() {
    return client.get('/log/count-excel').then(res => res.data)
  },

  async logUserExcel() {
    return client.get('/log/user-excel').then(res => res.data)
  },
}

export const FRANCHISE = {
  async pagination(page: number, perPage: number) {
    return client.get(`/admin/franchises`, {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },

  async create(data: any) {
    return client.post('/admin/franchises', data)
  },

  async update(
    id: number,
    data: any
  ) {
    return client.put(`/admin/franchises/${id}`, data)
  },

  async delete(id: number) {
    return client.delete(`/admin/franchises/${id}`)
  },
}

export const FAQ = {
  async pagination(page: number, perPage: number) {
    return client.get(`/admin/faqs`, {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },
  async create(data: any) {
    return client.post('/admin/faqs', data)
  },
  async update(id: number, data: any) {
    return client.put(`/admin/faqs/${id}`, data)
  },
  async delete(id: number) {
    return client.delete(`/admin/faqs/${id}`)
  },
}

export const RECOMMENDATION_TRIP = {
  async pagination(page: number, perPage: number) {
    return client.get(`/admin/recommend-trip`, {
      params: {
        page,
        perPage: perPage
      }
    }).then(res => res.data)
  },
  async findOne(id: number) {
    return client.get(`/admin/recommend-trip/${id}`).then(res => res.data)
  },
  async create(data: any) {
    return client.post('/admin/recommend-trip', data)
  },
  async update(id: number, data: any) {
    return client.put(`/admin/recommend-trip/${id}`, data)
  },
  async delete(id: number) {
    return client.delete(`/admin/recommend-trip/${id}`)
  },
}

export const NOTIFICATION = {
  async pagination(page: number, perPage: number) {
    return client.get('/admin/notifications', {
      params: {
        page,
        perPage,
      }
    }).then(res => res.data)
  },
  sendOrderSchedule(userId: number, orderId: number) {
    return client.post(`/admin/notifications/users/${userId}/send-order-schedule`, {
      orderId,
    })
  },
  sendPayment(userId: number, orderId: number) {
    return client.post(`/admin/notifications/users/${userId}/send-payemnt`, {
      orderId,
    })
  },
  sendTripClosed(userId: number, orderId: number) {
    return client.post(`/admin/notifications/users/${userId}/send-trip-closed`, {
      orderId,
    })
  },
}

export const NOTICE = {
  async pagination(page: number, perPage: number) {
    return client.get('/admin/notices', {
      params: {
        page,
        perPage,
      }
    }).then(res => res.data)
  },
  async findOne(id: number) {
    return client.get(`/admin/notices/${id}`).then(res => res.data)
  },

  // model Notice {
  //   id        Int        @id @default(autoincrement())
  //   type      NoticeType
  //   title     String
  //   content   String     @db.Text
  //   createdAt DateTime   @default(now())
  //   updatedAt DateTime   @updatedAt
  //   deletedAt DateTime?
  // }
  async create(data: any) {
    return client.post('/admin/notices', data)
  },
  async update(id: number, data: any) {
    return client.put(`/admin/notices/${id}`, data)
  },
  async delete(id: number) {
    return client.delete(`/admin/notices/${id}`)
  },
}

export const KAKAO_BIZTALK_LOG = {
  async pagination(page: number, perPage: number, startDate?: string) {
    return client.get(`/admin/kakao-biztalk-log`, {
      params: {
        page,
        perPage: perPage,
        startDate,
      }
    }).then(res => res.data)
  },
  async findOne(id: number) {
    return client.get(`/admin/kakao-biztalk-log/${id}`).then(res => res.data)
  },
  async getAndCreate() {
    return client.get('/kakao-biztalk/get-result-all').then(res => res.data)
  },
  async update(id: number, data: any) {
    return client.put(`/admin/kakao-biztalk-log/${id}`, data)
  },
  async delete(id: number) {
    return client.delete(`/admin/kakao-biztalk-log/${id}`)
  },
}