import React, { useState, useEffect } from 'react';
import xIcon from "../image/x.svg";
import kakaoImage from "../image/kakaotalkLogo_round.svg";

export default function FloatingButton() {
  const [showPopup, setShowPopup] = useState(false);

  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};

const setCookie = (name: string, value: string, days: number): void => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
};

  useEffect(() => {
    const visited = getCookie('visited');
    
    if (!visited) {
      setShowPopup(true);
    }
  }, []);

  const handleClick = () => {
    window.open('http://pf.kakao.com/_bLUZG/chat', '_blank');
};

  const handleClosePopup = () => {
    setCookie('visited', 'true', 1);  // 1일 동안 유지되는 쿠키 설정
    setShowPopup(false);
  };

  return (
    <>
    <div className="floating-button-container">
      {showPopup && (
        <div className="popup">
          <div className="text-wrapper">
            <div className="popup-title">궁금한 건 채팅으로 문의하세요</div>
            <div className="popup-subTitle">아래 버튼을 클릭하면 카카오톡 채팅으로 연결됩니다.</div>
          </div>
          <button onClick={handleClosePopup} className="close-popup-btn"><img src={xIcon} /></button>
        </div>
      )}
      <img className="floating-button" src={kakaoImage} onClick={handleClick}/>      
    </div>
    </>
  );
};
