import SvgIcon from "../components/SvgIcon";
import voteIcon from "../image/vote.svg";
import closeIcon from "../image/close.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { CHATROOMS, USER } from "../api";
import { useParams } from "react-router-dom";
import { format, isAfter } from "date-fns";

export default function VoteDetail() {
	const [selectItems, setSelectItems]: any = useState([]);
	const [vote, setVote]: any = useState();
	const { chat_room_id } = useParams();
	const { vote_id } = useParams();
	const [comments, setComments] = useState<any>([]);
	const [text, setText] = useState("");
	const [me, setMe] = useState<any>();

	useEffect(() => {
		if (chat_room_id && vote_id) {
			USER.getUser().then((res) => {
				setMe(res);
			});
			CHATROOMS.getCommunityClupVoteById(chat_room_id, vote_id).then(setVote);
			CHATROOMS.getPollComments(+chat_room_id, +vote_id).then(setComments);
		}
	}, []);

	const alreadyVote = useMemo(() => {
		if (vote) {
			return vote.votes.some((v: any) => v.userId === me?.id);
		}
		return false;
	}, [vote, me]);

	const isDone = useMemo(() => {
		if (vote) {
			return isAfter(new Date(), new Date(vote?.endedAt));
		}
		return false;
	}, [vote]);

	useEffect(() => {}, [selectItems]);

	function dateFormat(date: Date) {
		let month: any = date.getMonth() + 1;
		let day: any = date.getDate();

		return `${date.getFullYear()}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
	}

	const isActivate = () => {
		if (vote?.isAllowMultiple) {
			return selectItems.length > 0;
		} else {
			return selectItems.length === 1;
		}
	};

	const selectItem = (value: any) => {
		if (vote.isAllowMultiple) {
			if (selectItems.includes(value)) {
				setSelectItems([...selectItems.filter((e: any) => e !== value)]);
			} else {
				setSelectItems([...selectItems, value]);
			}
		} else {
			if (selectItems.includes(value)) {
				setSelectItems([]);
			} else {
				setSelectItems([value]);
			}
		}
	};

	const VoteList = ({ value, done }: any) => {
		const map = new Map();
		if (vote) {
			for (const option of vote?.options ?? []) {
				map.set(option, 0);
			}

			for (const _vote of vote?.votes) {
				try {
					const selects = JSON.parse(_vote.option) as string[];
					for (const select of selects) {
						map.set(select, map.get(select) + 1);
					}
				} catch (e) {}
			}
		}

		return (
			<div
				className={`border border-[#DADADA] rounded-[10px] flex px-[10px] py-[13px] items-center gap-[15px] ${done && "bg-gray-100"}`}
				onClick={() => {
					if (!done) {
						selectItem(value);
					}
				}}>
				<div className={`w-[15px] aspect-square rounded-full flex justify-center items-center border overflow-hidden p-[1px]`}>
					<div className={`w-full h-full rounded-full ${selectItems.includes(value) && "bg-[#0057FF]"}`}></div>
				</div>
				<div className="flex justify-between flex-1">
					<div className="text-[14px] font-medium">{value}</div>
					<div className="text-[12px] font-medium text-[#999999] ">{map?.get(value)}명</div>
				</div>
			</div>
		);
	};

	const handleOnSubmit = async () => {
		if (chat_room_id && vote_id) {
			await CHATROOMS.vote(+chat_room_id, +vote_id, JSON.stringify(selectItems)).then((res) => {});
			window.history.back();
		}
	};

	const handleSend = async () => {
		if (chat_room_id && vote_id) {
			await CHATROOMS.sendPollComment(+chat_room_id, +vote_id, text);
			await CHATROOMS.getPollComments(+chat_room_id, +vote_id).then(setComments);
			setText("");
		}
	};

	const handleEdit = async (content: string, commentId: number, authorId: number, referenceType: string, referenceId: number) => {
		if (chat_room_id && vote_id) {
			await CHATROOMS.editPollComment(+chat_room_id, +vote_id, +commentId, content, authorId, referenceType, referenceId);
			await CHATROOMS.getPollComments(+chat_room_id, +vote_id).then(setComments);
		}
	};
	const handleDelete = async (commentId: number) => {
		if (chat_room_id && vote_id) {
			await CHATROOMS.deletePollComment(+chat_room_id, +commentId);
			await CHATROOMS.getPollComments(+chat_room_id, +vote_id).then(setComments);
		}
	};

	const url = vote?.User.UserFiles?.find((file: any) => file)?.File?.url;

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="px-[10px] py-[20px]">
					<div className="border-b pb-[20px] border-[#999999]">
						<div className="flex mb-[10px] gap-[5px]">
							<SvgIcon className="w-[22px] h-[22px] bg-black" icon={voteIcon} />
							<div className="flex justify-between flex-1">
								<div className="text-[16px] text-[#0E1111] font-bold ">{vote?.title || ""}</div>
								<button onClick={() => window.history.back()}>
									<SvgIcon className="w-[18px] h-[18px] bg-black" icon={closeIcon} />
								</button>
							</div>
						</div>

						<div className="ml-[27px]">
							<div className="text-[14px] font-medium">
								<div className="mb-[10px]">
									{dateFormat(new Date(vote?.startedAt)) || ""} ~ {dateFormat(new Date(vote?.endedAt)) || ""}
								</div>
								<div className="text-[#777777]">
									{`${(vote?.isAllowMultiple && "중복 선택 가능 · ") || ""} ${(vote?.isAnonymous && "익명 투표 · ") || ""} 현재 투표자 ${vote?.votes.length}명`}
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="flex mt-[20px] gap-[20px]">
							<div className="w-[34px] bg-gray-200 rounded-full aspect-square overflow-hidden">
								<img className="w-full h-full object-cover" src={url}></img>
							</div>
							<div className="flex-1 text-[14px] font-medium break-keep">{vote?.description}</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="px-[10px] pt-[20px] pb-[30px]  bo">
					<div className="flex flex-col gap-[10px] mb-[20px]">
						{vote?.options &&
							vote.options.map((v: any, i: any) => {
								return <VoteList value={v} done={alreadyVote}></VoteList>;
							})}
					</div>

					{isDone ? (
						<div className="w-full h-[40px] border border-[#DADADA] flex justify-center bg-gray-200 items-center text-[16px] font-bold text-[#777777] ">
							투표종료
						</div>
					) : alreadyVote ? (
						<div className="w-full h-[40px] border border-[#DADADA] flex justify-center bg-gray-200 items-center text-[16px] font-bold text-[#777777] ">
							투표완료
						</div>
					) : (
						<div
							onClick={handleOnSubmit}
							className={[
								"w-full h-[40px] border flex justify-center items-center text-[16px] font-bold",
								isActivate() ? "text-success border-success" : "text-[#777777]",
							].join(" ")}>
							투표하기
						</div>
					)}
				</div>
				<hr />

				<div className="px-[10px] pt-[20px] pb-[30px]  bo">
					<div>
						<div>댓글({comments.length})</div>
						<div>
							{comments.map((comment: any) => {
								return (
									<Comment
										comment={comment}
										handleEdit={handleEdit}
										handleDelete={handleDelete}
										reset={() => {
											// @ts-ignore
											CHATROOMS.getPollComments(+chat_room_id, +vote_id).then(setComments);
										}}
									/>
								);
							})}
							<div></div>
						</div>
						<div className="mt-5 w-full h-10 border flex items-center bg-[#F6F7F9] border-[#DADADA] text-[12px] font-normal text-[#777777] px-[10px]">
							<input
								value={text}
								onChange={(e) => setText(e.target.value)}
								className="w-full h-full bg-transparent outline-none"
								placeholder="댓글을 입력해주세요"></input>
							<div onClick={handleSend} className="flex-none text-success text-xs">
								작성
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function Comment({ comment, handleEdit, reset, handleDelete }: any) {
	const userImage = comment.user?.UserFiles?.find((file: any) => file.type === "PROFILE")?.File?.url;
	const createdAt = format(new Date(comment.createdAt), "yyyy-MM-dd a hh:mm");
	const btn = useRef(null);
	const content = useRef(null);
	const [origin, setOrigin] = useState(false);
	const [opened, setOpened] = useState(false);
	useEffect(() => {
		const windowOnClick = (e: any) => {
			if (btn.current && btn.current === e.target) return;
			if (!opened) return;
			setOpened(false);
		};
		window.addEventListener("click", windowOnClick);

		return () => {
			window.removeEventListener("click", windowOnClick);
		};
	});

	const [mode, setMode] = useState("READ");
	return (
		<div className="flex gap-2.5 border-b border-[#dadada] pb-5 pt-5">
			<div
				className="flex-none w-11 h-11 rounded-full bg-gray-200 bg-center bg-cover"
				style={{
					backgroundImage: `url(${userImage})`,
				}}></div>
			<div className="flex-1">
				<div className="text-black font-medium text-sm flex gap-2.5">
					{comment?.user?.nickname}
					<span className="text-[#777] text-[10px] font-normal">{createdAt}</span>
				</div>
				<div
					ref={content}
					className={["text-black text-xs font-normal mt-1", mode === "EDIT" && "border border-[#dadada]"].join(" ")}
					contentEditable={mode === "EDIT"}>
					{comment.content}
				</div>
				{mode === "EDIT" && (
					<div className="text-right flex justify-end text-xs font-normal text-blue-500 gap-1 mt-2">
						<div
							// content: string, commentId: number, authorId: number, referenceType: string, referenceId: number
							onClick={() => {
								// @ts-ignore
								const _content = (content.current.innerText = content.current.innerText.trim());
								handleEdit(_content, comment.id, comment.authorId, comment.referenceType, comment.referenceId);
								setMode("READ");
							}}>
							수정
						</div>
						<div
							onClick={() => {
								// @ts-ignore
								content.current.innerText = comment.content;
								setMode("READ");
							}}>
							취소
						</div>
					</div>
				)}
			</div>
			<div className="relative">
				<div
					onClick={() => {
						setOpened(!opened);
					}}
					className="flex-none mr-2.5">
					{
						<svg ref={btn} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.66797 7.99996C6.66797 7.26358 7.26492 6.66663 8.0013 6.66663C8.73768 6.66663 9.33464 7.26358 9.33464 7.99996C9.33464 8.73634 8.73768 9.33329 8.0013 9.33329C7.26492 9.33329 6.66797 8.73634 6.66797 7.99996Z"
								fill="#777777"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.332 7.99996C11.332 7.26358 11.929 6.66663 12.6654 6.66663C13.4017 6.66663 13.9987 7.26358 13.9987 7.99996C13.9987 8.73634 13.4017 9.33329 12.6654 9.33329C11.929 9.33329 11.332 8.73634 11.332 7.99996Z"
								fill="#777777"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2 7.99996C2 7.26358 2.59695 6.66663 3.33333 6.66663C4.06971 6.66663 4.66667 7.26358 4.66667 7.99996C4.66667 8.73634 4.06971 9.33329 3.33333 9.33329C2.59695 9.33329 2 8.73634 2 7.99996Z"
								fill="#777777"
							/>
						</svg>
					}
				</div>
				{opened && (
					<div className="text-xs text-black font-normal border border-[#dadada] px-4 py-2.5 rounded-sm absolute bg-[#fff] w-[90px] flex flex-col items-center gap-2.5 right-2">
						<div
							onClick={() => {
								setOrigin(comment.content);
								setMode("EDIT");
							}}>
							수정하기
						</div>
						<div onClick={() => handleDelete(comment.id)}>삭제하기</div>
					</div>
				)}
			</div>
		</div>
	);
}
