import Logo from "../image/orderMadeTrip_Logo.png";

const Footer = () => {
	return (
		<footer className="px-3 flex justify-center xl:pb-14">
			<div className="max-w-lg xl:flex xl:max-w-7xl xl:items-end w-full xl:justify-between">
				<div className="">
					<div className="mt-9">
						<img src={Logo} className="w-[125px] xl:w-[182px]" />
					</div>

					<div className="mt-4 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div onClick={() => (window.open('/policy/terms-of-use', '이용약관', 'width=700px,height=800px,scrollbars=yes' ))}>이용약관</div>
						<div className="border-r border-[#f5b826]"></div>
						<div onClick={() => (window.open('/policy/privacy', '개인정보처리방침', 'width=700px,height=800px,scrollbars=yes' ))}>개인정보처리방침</div>
					</div>

					<div className="mt-4 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>㈜여행을만들다</div>
						<div className="border-r border-[#f5b826]"></div>
						<div>대표: 백민우</div>
						<div className="border-r border-[#f5b826]"></div>
						<div>개인정보보호책임자: 김도영</div>
					</div>

					<div className="mt-3 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>사업자등록번호: 272-87-02448</div>
						<div className="border-r border-[#f5b826]"></div>
						<div>통신판매업신고: 제2024-안양만안-379호</div>
					</div>

					<div className="mt-3 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>관공사업등록번호: 제2024-000003호</div>
					</div>

					<div className="mt-3 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>제휴 및 파트너 문의: info@makeatrip.co.kr</div>
					</div>
				</div>
				<div className="">
					<div className="mt-3 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>전화: 02-6953-6697 (토,일, 공휴일 휴무)</div>
						<div className="border-r border-[#f5b826]"></div>
						<div>통화 가능 시간: 오전 10시 ~ 오후 5시</div>
					</div>

					<div className="mt-3 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>본사: 경기도 안양시 만안구 안양로 111, 경기벤처연성대학교센터13층 1314호</div>
					</div>

					<div className="mt-3 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>서울지점: 서울특별시 강서구 마곡동 757 두산더랜드파크 A동 804호</div>
					</div>

					<div className="mt-5 flex gap-3 text-[10px] text-black font-normal xl:text-sm">
						<div>Copyright © 2023 오더메이드트립. All Rights Reserved.</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
