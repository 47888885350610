import {
	addDays,
	addMonths,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	isAfter,
	isBefore,
	isEqual,
	isSameDay,
	isSameMonth,
	parseISO,
	set,
	startOfMonth,
	startOfWeek,
	subMonths,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { CHATROOMS } from "../../api";
import Modal from "../modal";

const KakaoShareButton = ({ image, title, text, url }: any) => {
	useEffect(() => {
		// @ts-ignore
		Kakao.Link.createDefaultButton({
			container: "#main_kakao",
			objectType: "feed",
			installTalk: true,
			content: {
				title,
				description: text,
				imageUrl: image,
				link: {
					webUrl: url,
					mobileWebUrl: url,
				},
			},
		});
	}, []);

	return (
		<>
			<div id="main_kakao" className="w-16 h-16">
				{IconKakao}
			</div>
			<div className="text-center text-black font-medium text-sm">카카오톡</div>
		</>
	);
};

export default function ChatRoomCalendar({ chatroomId, onCancel }: any) {
	const [events, setEvents] = useState<any>([]);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [calendarRows, setCalendarRows] = useState<any>([]);

	const [status, setStatus] = useState("calendar"); // calendar, register
	const [shareModal, setShareModal] = useState(false);

	const [form, setForm] = useState({
		title: "",
		startDate: new Date().toISOString().substring(0, 16),
		endDate: addDays(new Date(), 1).toISOString().substring(0, 16),
		location: "",
		description: "",
		color: "",
	});
	const colors = ["#0257FF", "#F5B827", "#202C4A", "#347A43", "#A62C2C", "#8E3962", "#D95830", "#345C7D", "#F67280", "#A870B1", "#8A6233", "#000000"];

	useEffect(() => {
		const firstDayOfMonth = startOfMonth(currentDate);
		const lastDayOfMonth = endOfMonth(currentDate);
		const startDay = startOfWeek(firstDayOfMonth, { weekStartsOn: 0 });
		const endDay = endOfWeek(lastDayOfMonth, { weekStartsOn: 0 });

		const days = eachDayOfInterval({ start: startDay, end: endDay });
		const rows: any = [];
		let row: any = [];
		days.forEach((day, index) => {
			row.push(day);
			if ((index + 1) % 7 === 0) {
				rows.push(row);
				row = [];
			}
		});

		setCalendarRows(rows);
	}, [currentDate]);

	const isActive = useMemo(() => {
		if (form.title.length === 0) {
			return false;
		} else if (form.startDate.length === 0) {
			return false;
		} else if (form.endDate.length === 0) {
			return false;
		} else if (form.location.length === 0) {
			return false;
		} else if (form.description.length === 0) {
			return false;
		} else if (form.color.length === 0) {
			return false;
		}
		return true;
	}, [form]);

	useEffect(() => {
		CHATROOMS.getSchedules(chatroomId).then((res) => {
			setEvents(res);
		});
	}, []);

	const dateString = useMemo(() => {
		return format(currentDate, "yyyy년 MM월");
	}, [currentDate]);

	// 요일 헤더를 렌더링합니다.
	const renderDayNames = () => {
		const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		return (
			<div className="grid grid-cols-7 px-3">
				{dayNames.map((dayName) => (
					<div key={dayName} className="py-2 text-center font-medium text-black">
						{dayName}
					</div>
				))}
			</div>
		);
	};

	const handleChangeForm = (key: string, value: any) => {
		setForm({
			...form,
			[key]: value,
		});
	};

	const handleSubmit = () => {
		if (form.title === "") {
			alert("일정 제목을 입력해주세요.");
			return;
		}

		if (form.startDate === "") {
			alert("시작일을 입력해주세요.");
			return;
		}

		if (form.endDate === "") {
			alert("종료일을 입력해주세요.");
			return;
		}

		if (form.location === "") {
			alert("장소를 입력해주세요.");
			return;
		}

		if (form.description === "") {
			alert("설명을 입력해주세요.");
			return;
		}

		if (form.color === "") {
			alert("색상을 선택해주세요.");
			return;
		}

		CHATROOMS.createSchedule(chatroomId, {
			title: form.title,
			color: form.color,
			startedAt: parseISO(form.startDate),
			endedAt: parseISO(form.endDate),
			location: form.location,
			description: form.description,
		}).then((res) => {
			CHATROOMS.getSchedules(chatroomId).then((res) => {
				setEvents(res);
				setStatus("calendar");
			});
		});
	};

	const currentDateEvents = useMemo(() => {
		return events.filter((event: any) => {
			return (
				(isBefore(currentDate, new Date(event.endedAt)) || isSameDay(currentDate, new Date(event.endedAt))) &&
				(isAfter(currentDate, new Date(event.startedAt)) || isSameDay(currentDate, new Date(event.startedAt)))
			);
		});
	}, [currentDate, events]);

	return (
		<div className="w-full h-full bg-white overflow-y-scroll">
			<div className="flex items-center gap-1 px-3 mt-5">
				{IconCalendar}
				<div className="font-bold text-black">크루 일정 확인하기</div>

				<div className="flex-1 flex justify-end" onClick={onCancel}>
					{IconClose}
				</div>
			</div>

			<div className="px-3 mt-5">
				<div className="border-b border-[#999]"></div>
			</div>

			{status === "calendar" && (
				<>
					<div className="px-3 mt-5">
						<div className="border border-[#dadada] rounded-sm pb-5">
							<div className="flex justify-between px-5 pt-6 pb-5">
								<div className="font-bold">{dateString}</div>
								<div className="flex-1 justify-end flex gap-2">
									<div onClick={() => setCurrentDate(addMonths(currentDate, -1))}>{IconLeft}</div>
									<div onClick={() => setCurrentDate(addMonths(currentDate, 1))}>{IconRight}</div>
								</div>
							</div>
							{renderDayNames()}
							<div className="px-3 mb-4">
								<div className="border-b border-[#e5e5e5]"></div>
							</div>
							{calendarRows.map((week: any, weekIdx: number) => (
								<div key={weekIdx} className="grid grid-cols-7 px-4">
									{week.map((day: any, dayIdx: number) => {
										const isCurrentMonth = isSameMonth(day, currentDate);

										const dayEvents = [];
										for (const event of events) {
											if (
												(isBefore(day, new Date(event.endedAt)) || isSameDay(day, new Date(event.endedAt))) &&
												(isAfter(day, new Date(event.startedAt)) || isSameDay(day, new Date(event.startedAt)))
											) {
												dayEvents.push(event);
											}
										}
										return (
											<div key={dayIdx} className="py-2 text-center">
												<div className="w-full flex justify-center">
													<div
														onClick={() => setCurrentDate(day)}
														className={[
															"h-[38px] w-[38px] flex justify-center items-center rounded-full font-normal text-center cursor-pointer",
															isCurrentMonth ? "text-black" : "text-[#999]",
															currentDate.getDate() === day.getDate() ? "bg-slate-200" : "",
														].join(" ")}>
														{format(day, "d")}
													</div>
												</div>

												<div className="flex flex-col gap-1.5">
													{dayEvents.map((event: any, eventIdx: number) => (
														<div
															key={eventIdx}
															className="h-[3px] rounded-[2px]"
															style={{
																backgroundColor: event.color,
															}}></div>
													))}
												</div>
											</div>
										);
									})}
								</div>
							))}
						</div>
					</div>

					<div className="px-3 mt-5">
						<div className="border-b border-[#999]"></div>
					</div>

					{currentDateEvents.map((v: any) => {
						const startString = format(new Date(v.startedAt), "a hh:mm");
						const endString = format(new Date(v.endedAt), "a hh:mm");
						const userImage = v.User?.UserFiles?.find((file: any) => file.type === "PROFILE")?.File.url;

						return (
							<div className="px-3 mt-4 flex pb-5 border-b border-[#dadada]">
								<div className="flex flex-col justify-center items-center w-16 border-r-[3px] border-success">
									<div className="flex justify-center text-sm font-mediums">{currentDate.getMonth() + 1}월</div>
									<div className="rounded-full w-11 h-11 bg-success mt-0.5 flex justify-center items-center font-bold text-white">{currentDate.getDate()}</div>
								</div>
								<div className="flex flex-col flex-1 ml-3 gap-2.5 justify-center my-0.5">
									<div className="font-medium">{v.title}</div>
									<div className="flex text-sm font-medium">
										{IconTime}
										<div className="ml-1">
											{startString} ~ {endString}
										</div>
									</div>
									<div className="flex text-[#777] text-sm font-medium">
										{IconLocation}
										<div className="ml-1">{v.location}</div>
									</div>
								</div>
								<div className="flex justify-center items-center">
									<div
										className="w-[50px] h-[50px] rounded-full bg-center bg-cover"
										style={{
											backgroundImage: `url(${userImage})`,
										}}></div>
								</div>
							</div>
						);
					})}
					{currentDateEvents.length === 0 && (
						<div className="px-3 mt-4 flex pb-5 border-b border-[#dadada]">
							<div className="flex flex-col justify-center items-center w-16 border-r-[3px] border-[#dadada]">
								<div className="flex justify-center text-sm font-mediums">{currentDate.getMonth() + 1}월</div>
								<div className="rounded-full w-11 h-11 bg-[#dadada] mt-0.5 flex justify-center items-center font-bold text-white">{currentDate.getDate()}</div>
							</div>
							<div className="flex flex-col flex-1 ml-3 gap-2.5 justify-center my-0.5">
								<div className="text-sm font-medium text-[#999]">등록된 일정이 없습니다.</div>
							</div>
						</div>
					)}

					<div className="flex px-3 gap-2 mb-8 mt-8">
						<div
							onClick={() => setShareModal(true)}
							className="flex-1 border py-2.5 flex justify-center items-center border-success rounded-sm text-success font-bold">
							일정 공유하기
						</div>
						<div
							onClick={() => setStatus("register")}
							className="flex-1 border py-2.5 flex justify-center items-center border-success rounded-sm text-success font-bold">
							일정 등록하기
						</div>
					</div>
				</>
			)}

			{status === "register" && (
				<div className="px-3">
					<div className="px-1.5 flex items-center gap-4 h-16 border-b border-[#dadada]">
						<div className="w-4 h-4 rounded-full bg-success"></div>
						<div className="text-lg text-black font-medium flex">
							<input
								value={form.title}
								onChange={(e) => handleChangeForm("title", e.target.value)}
								placeholder="일정 제목"
								className="w-full outline-none flex-1 bg-white"
							/>
						</div>
					</div>

					<div className="px-1.5 flex items-center gap-4 h-16 border-b border-[#dadada]">
						<div className="w-4">{IconTimer}</div>
						<label className="text-black font-medium flex flex-1">
							<input
								value={form.startDate}
								onChange={(e) => handleChangeForm("startDate", e.target.value)}
								type="datetime-local"
								className="flex-1 outline-none bg-white"
							/>
						</label>
					</div>

					<div className="px-1.5 flex items-center gap-4 h-16 border-b border-[#dadada]">
						<div className="w-4"></div>
						<label className="text-black font-medium flex flex-1">
							<input
								value={form.endDate}
								onChange={(e) => handleChangeForm("endDate", e.target.value)}
								type="datetime-local"
								className="flex-1 outline-none bg-white"
							/>
						</label>
					</div>

					<div className="px-1.5 flex items-center gap-4 h-16 border-b border-[#dadada]">
						<div className="w-4">{IconLocation}</div>
						<div className="text-black font-medium">장소</div>
						<div className="flex-1 flex justify-end font-medium">
							<input value={form.location} onChange={(e) => handleChangeForm("location", e.target.value)} className="w-full outline-none text-right bg-white" />
						</div>
					</div>

					<div className="px-1.5 flex items-center gap-4 h-16 border-b border-[#dadada]">
						<div className="w-4">{IconDescription}</div>
						<div className="text-black font-medium">추가 설명</div>
						<div className="flex-1 flex justify-end font-medium">
							<input
								value={form.description}
								onChange={(e) => handleChangeForm("description", e.target.value)}
								className="w-full outline-none text-right bg-white"
							/>
						</div>
					</div>

					<div className="px-1.5 flex items-center gap-4 h-16 border-b">
						<div className="w-4">{IconColor}</div>
						<div className="text-black font-medium">일정 색상</div>
					</div>

					<div className="grid grid-cols-6 gap-5 mt-5">
						{colors.map((color) => {
							const isSelected = form.color === color;
							return (
								<div className="flex justify-center items-center" onClick={() => handleChangeForm("color", color)}>
									<div
										className={["w-[30px] h-[30px] rounded-full", isSelected ? "border-[3px] border-success" : ""].join(" ")}
										style={{
											backgroundColor: color,
										}}></div>
								</div>
							);
						})}
					</div>

					<div
						className={[
							"text-[#999] font-bold border border-[#dadada] flex justify-center items-center py-2.5 my-8",
							isActive && "border-success text-success",
						].join(" ")}
						onClick={handleSubmit}>
						일정 등록하기
					</div>
				</div>
			)}
			<Modal open={shareModal} onClose={() => setShareModal(false)}>
				<div className="flex justify-end" onClick={() => setShareModal(false)}>
					{IconClose}
				</div>
				<div className="flex items-center gap-1 font-bold text-black">{IconShare}일정 공유하기</div>
				<div className="flex mt-8 justify-between">
					<div className="flex flex-col">
						<KakaoShareButton
							url={window.location.href}
							title={format(currentDate, "MM월 dd일")}
							text={currentDateEvents?.length > 0 ? currentDateEvents[0].description : ""}
							image={"https://www.ordermtrip.com/favicon.png"}
						/>
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16"
							onClick={() => {
								window.open(`https://twitter.com/intent/tweet?text=${currentDateEvents?.length > 0 ? currentDateEvents[0].description : ""}`);
							}}>
							<img src="/images/20230725081132224942 1.png" />
						</div>
						<div className="text-center text-black font-medium text-sm">X</div>
					</div>

					<div className="flex flex-col">
						<div
							className="w-16 h-16 flex justify-center items-center"
							onClick={() => {
								//@ts-ignore
								try {
									navigator.clipboard
										.writeText(`${currentDateEvents?.length > 0 ? currentDateEvents[0].description : ""}\nhttps://www.ordermtrip.com`)
										.then(() => {
											alert("복사 완료");
										});
								} catch (e) {}
							}}>
							{IconCopy}
						</div>
						<div className="text-center text-black font-medium text-sm">링크 복사</div>
						<input
							id="community_clup_id_copy"
							className="hidden"
							value={`${currentDateEvents?.length > 0 ? currentDateEvents[0].description : ""}\nhttps://www.ordermtrip.com`}
						/>
					</div>
				</div>

				<div
					onClick={() => {
						CHATROOMS.sendMessage(chatroomId, JSON.stringify(currentDateEvents), "SCHEDULE");
						alert("공유됐습니다.");
					}}
					className="bg-success mt-5 rounded-sm py-1.5 text-sm font-bold text-white text-center">
					채팅방 공유하기
				</div>
			</Modal>
		</div>
	);
}

const IconChange = (
	<svg className="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path d="M13.723 18.654l-3.61 3.609c-2.316 2.315-6.063 2.315-8.378 0-1.12-1.118-1.735-2.606-1.735-4.188 0-1.582.615-3.07 1.734-4.189l4.866-4.865c2.355-2.355 6.114-2.262 8.377 0 .453.453.81.973 1.089 1.527l-1.593 1.592c-.18-.613-.5-1.189-.964-1.652-1.448-1.448-3.93-1.51-5.439-.001l-.001.002-4.867 4.865c-1.5 1.499-1.5 3.941 0 5.44 1.517 1.517 3.958 1.488 5.442 0l2.425-2.424c.993.284 1.791.335 2.654.284zm.161-16.918l-3.574 3.576c.847-.05 1.655 0 2.653.283l2.393-2.389c1.498-1.502 3.94-1.5 5.44-.001 1.517 1.518 1.486 3.959 0 5.442l-4.831 4.831-.003.002c-1.438 1.437-3.886 1.552-5.439-.002-.473-.474-.785-1.042-.956-1.643l-.084.068-1.517 1.515c.28.556.635 1.075 1.088 1.528 2.245 2.245 6.004 2.374 8.378 0l4.832-4.831c2.314-2.316 2.316-6.062-.001-8.377-2.317-2.321-6.067-2.313-8.379-.002z" />
	</svg>
);

const IconTwitter = (
	<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
		<rect width="64" height="64" rx="17" fill="#1DA1F2" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M50.8613 16.7102C49.6991 17.4432 47.0307 18.5084 45.7147 18.5084V18.5105C44.212 16.9649 42.0964 16 39.7502 16C35.1933 16 31.4982 19.6383 31.4982 24.1233C31.4982 24.7464 31.5716 25.3543 31.7071 25.9372H31.7058C25.5244 25.7774 18.764 22.7269 14.6942 17.5058C12.192 21.7703 14.3573 26.5131 17.1969 28.2412C16.2249 28.3134 14.4356 28.1309 13.5933 27.3218C13.5369 30.1522 14.9191 33.9025 19.9591 35.263C18.9884 35.7772 17.2702 35.6297 16.5231 35.5203C16.7853 37.9097 20.1836 41.0333 23.8996 41.0333C22.5751 42.5417 17.592 45.2777 12 44.4073C15.7978 46.6824 20.224 48 24.9089 48C38.2222 48 48.5613 37.3762 48.0049 24.2703C48.0027 24.2559 48.0027 24.2415 48.0013 24.2257C48.0027 24.192 48.0049 24.1583 48.0049 24.1233C48.0049 24.0826 48.0013 24.0441 48 24.0047C49.212 23.1886 50.8382 21.7449 52 19.8448C51.3262 20.2102 49.3049 20.9419 47.424 21.1235C48.6311 20.482 50.4196 18.381 50.8613 16.7102Z"
			fill="white"
		/>
	</svg>
);

const IconKakao = (
	<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
		<rect width="64" height="64" rx="17" fill="#FFE812" />
		<path
			d="M31.9571 13C18.7259 13 8 21.2246 8 31.37C8 37.9292 12.4841 43.6846 19.2294 46.9346C18.8625 48.1654 16.8713 54.8525 16.792 55.3778C16.792 55.3778 16.7444 55.7726 17.0072 55.9231C17.27 56.0737 17.5792 55.9567 17.5792 55.9567C18.3329 55.8543 26.3196 50.3984 27.702 49.451C29.083 49.6412 30.505 49.74 31.9571 49.74C45.1884 49.74 55.9143 41.5156 55.9143 31.37C55.9143 21.2246 45.1884 13 31.9571 13Z"
			fill="black"
		/>
		<path
			d="M19.1946 36.3428C18.4079 36.3428 17.7682 35.8079 17.7682 35.1502V27.7312H15.5424C14.7705 27.7312 14.1426 27.1826 14.1426 26.5084C14.1426 25.8343 14.7707 25.2856 15.5424 25.2856H22.8468C23.6188 25.2856 24.2467 25.8343 24.2467 26.5084C24.2467 27.1826 23.6185 27.7312 22.8468 27.7312H20.6211V35.1502C20.6211 35.8079 19.9813 36.3428 19.1946 36.3428ZM31.7028 36.3266C31.1079 36.3266 30.6529 36.1151 30.5157 35.775L29.8094 34.1561L25.4596 34.1559L24.7528 35.7758C24.6161 36.1153 24.1613 36.3266 23.5665 36.3266C23.2536 36.3268 22.9443 36.2681 22.6597 36.1542C22.2665 35.9954 21.8885 35.5587 22.3217 34.3809L25.7337 26.5184C25.9741 25.9205 26.7042 25.3044 27.6333 25.2859C28.565 25.3042 29.2951 25.9205 29.536 26.5197L32.9466 34.3786C33.3807 35.5592 33.0027 35.996 32.6095 36.1544C32.3248 36.2681 32.0156 36.3268 31.7028 36.3266ZM29.0593 31.9441L27.6345 28.4006L26.2097 31.9441H29.0593ZM35.2423 36.1607C34.4884 36.1607 33.8752 35.647 33.8752 35.0159V26.5345C33.8752 25.8457 34.5286 25.2856 35.3314 25.2856C36.1343 25.2856 36.7876 25.8457 36.7876 26.5345V33.8712H39.8188C40.5727 33.8712 41.1858 34.3849 41.1858 35.0159C41.1858 35.647 40.5727 36.1607 39.8188 36.1607H35.2423ZM43.1672 36.3266C42.3805 36.3266 41.7407 35.7665 41.7407 35.0777V26.5345C41.7407 25.8457 42.3805 25.2856 43.1672 25.2856C43.9539 25.2856 44.5937 25.8457 44.5937 26.5345V29.2186L48.5735 25.7344C48.7782 25.5552 49.0594 25.4565 49.3647 25.4565C49.7208 25.4565 50.0784 25.591 50.3463 25.8253C50.5962 26.0439 50.7453 26.3251 50.7657 26.6171C50.7864 26.9116 50.6744 27.1815 50.4509 27.3774L47.2003 30.2228L50.7115 34.2952C50.9405 34.5591 51.0391 34.8923 50.9854 35.2203C50.934 35.5486 50.7347 35.8452 50.4319 36.0439C50.1852 36.2081 49.8837 36.2966 49.5739 36.296C49.1257 36.2978 48.703 36.1136 48.4344 35.7996L45.0891 31.9191L44.5941 32.3524V35.0771C44.5932 35.7667 43.9549 36.3256 43.1672 36.3266Z"
			fill="#FFE812"
		/>
	</svg>
);

const IconShare = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.5 8.9375C4.36091 8.9375 3.4375 9.86091 3.4375 11C3.4375 12.1391 4.36091 13.0625 5.5 13.0625C6.63909 13.0625 7.5625 12.1391 7.5625 11C7.5625 9.86091 6.63909 8.9375 5.5 8.9375ZM2.0625 11C2.0625 9.10152 3.60152 7.5625 5.5 7.5625C7.39848 7.5625 8.9375 9.10152 8.9375 11C8.9375 12.8985 7.39848 14.4375 5.5 14.4375C3.60152 14.4375 2.0625 12.8985 2.0625 11Z"
			fill="#0E1111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.125 15.125C13.9859 15.125 13.0625 16.0484 13.0625 17.1875C13.0625 18.3266 13.9859 19.25 15.125 19.25C16.2641 19.25 17.1875 18.3266 17.1875 17.1875C17.1875 16.0484 16.2641 15.125 15.125 15.125ZM11.6875 17.1875C11.6875 15.289 13.2265 13.75 15.125 13.75C17.0235 13.75 18.5625 15.289 18.5625 17.1875C18.5625 19.086 17.0235 20.625 15.125 20.625C13.2265 20.625 11.6875 19.086 11.6875 17.1875Z"
			fill="#0E1111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.125 2.75C13.9859 2.75 13.0625 3.67341 13.0625 4.8125C13.0625 5.95159 13.9859 6.875 15.125 6.875C16.2641 6.875 17.1875 5.95159 17.1875 4.8125C17.1875 3.67341 16.2641 2.75 15.125 2.75ZM11.6875 4.8125C11.6875 2.91402 13.2265 1.375 15.125 1.375C17.0235 1.375 18.5625 2.91402 18.5625 4.8125C18.5625 6.71098 17.0235 8.25 15.125 8.25C13.2265 8.25 11.6875 6.71098 11.6875 4.8125Z"
			fill="#0E1111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.3925 5.92755C13.5978 6.24697 13.5052 6.67232 13.1858 6.87759L8.18425 10.0917C7.86483 10.2969 7.43948 10.2044 7.23421 9.88495C7.02894 9.56552 7.12149 9.14017 7.44091 8.9349L12.4425 5.72084C12.7619 5.51557 13.1873 5.60812 13.3925 5.92755Z"
			fill="#0E1111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.23421 12.115C7.43948 11.7956 7.86483 11.7031 8.18425 11.9083L13.1858 15.1224C13.5052 15.3277 13.5978 15.753 13.3925 16.0724C13.1873 16.3919 12.7619 16.4844 12.4425 16.2792L7.44091 13.0651C7.12149 12.8598 7.02894 12.4345 7.23421 12.115Z"
			fill="#0E1111"
		/>
	</svg>
);

const IconColor = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M19.9911 3.61168C18.5794 7.13168 15.0411 11.9167 12.0803 14.2908L10.2744 15.7392C10.0453 15.9042 9.81609 16.0508 9.55943 16.1517C9.55943 15.9867 9.55026 15.8033 9.52276 15.6292C9.42193 14.8592 9.07359 14.1442 8.45943 13.53C7.83609 12.9067 7.07526 12.54 6.29609 12.4392C6.11276 12.43 5.92943 12.4117 5.74609 12.43C5.84693 12.1458 6.00276 11.88 6.19526 11.66L7.62526 9.85418C9.99026 6.89334 14.7936 3.33668 18.3044 1.93418C18.8453 1.73251 19.3678 1.87918 19.6978 2.21834C20.0461 2.55751 20.2111 3.08001 19.9911 3.61168Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.55954 16.1517C9.55954 17.16 9.17454 18.1225 8.45037 18.8558C7.89121 19.415 7.13037 19.8 6.22287 19.9192L3.96787 20.1667C2.73954 20.3042 1.68537 19.2592 1.83204 18.0125L2.07954 15.7575C2.29954 13.75 3.97704 12.4667 5.75537 12.43C5.9387 12.4208 6.1312 12.43 6.30537 12.4392C7.08454 12.54 7.84537 12.8975 8.4687 13.53C9.08287 14.1442 9.4312 14.8592 9.53204 15.6292C9.5412 15.8033 9.55954 15.9775 9.55954 16.1517Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.0526 13.2641C13.0526 10.8716 11.1093 8.92831 8.7168 8.92831"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconDescription = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M19.25 4.8125H2.75C2.37417 4.8125 2.0625 4.50083 2.0625 4.125C2.0625 3.74917 2.37417 3.4375 2.75 3.4375H19.25C19.6258 3.4375 19.9375 3.74917 19.9375 4.125C19.9375 4.50083 19.6258 4.8125 19.25 4.8125Z"
			fill="#0E1111"
		/>
		<path
			d="M11.4308 9.39584H2.75C2.37417 9.39584 2.0625 9.08418 2.0625 8.70834C2.0625 8.33251 2.37417 8.02084 2.75 8.02084H11.4308C11.8067 8.02084 12.1183 8.33251 12.1183 8.70834C12.1183 9.08418 11.8158 9.39584 11.4308 9.39584Z"
			fill="#0E1111"
		/>
		<path
			d="M19.25 13.9792H2.75C2.37417 13.9792 2.0625 13.6675 2.0625 13.2917C2.0625 12.9158 2.37417 12.6042 2.75 12.6042H19.25C19.6258 12.6042 19.9375 12.9158 19.9375 13.2917C19.9375 13.6675 19.6258 13.9792 19.25 13.9792Z"
			fill="#0E1111"
		/>
		<path
			d="M11.4308 18.5625H2.75C2.37417 18.5625 2.0625 18.2508 2.0625 17.875C2.0625 17.4992 2.37417 17.1875 2.75 17.1875H11.4308C11.8067 17.1875 12.1183 17.4992 12.1183 17.875C12.1183 18.2508 11.8158 18.5625 11.4308 18.5625Z"
			fill="#0E1111"
		/>
	</svg>
);

const IconTimer = (
	<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M20.1673 11C20.1673 16.06 16.0607 20.1667 11.0007 20.1667C5.94065 20.1667 1.83398 16.06 1.83398 11C1.83398 5.94 5.94065 1.83333 11.0007 1.83333C16.0607 1.83333 20.1673 5.94 20.1673 11Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.4002 13.915L11.5585 12.2192C11.0635 11.9258 10.6602 11.22 10.6602 10.6425V6.88417"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconPeople = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path
			d="M8.39635 9.96421C8.30469 9.95504 8.19469 9.95504 8.09385 9.96421C5.91219 9.89087 4.17969 8.10337 4.17969 5.90337C4.17969 3.65754 5.99469 1.83337 8.24969 1.83337C10.4955 1.83337 12.3197 3.65754 12.3197 5.90337C12.3105 8.10337 10.578 9.89087 8.39635 9.96421Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.043 3.66669C16.8213 3.66669 18.2513 5.10585 18.2513 6.87502C18.2513 8.60752 16.8763 10.0192 15.1622 10.0834C15.0888 10.0742 15.0063 10.0742 14.9238 10.0834"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.81414 13.3467C1.59581 14.8317 1.59581 17.2517 3.81414 18.7275C6.33497 20.4142 10.4691 20.4142 12.99 18.7275C15.2083 17.2425 15.2083 14.8225 12.99 13.3467C10.4783 11.6692 6.34414 11.6692 3.81414 13.3467Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.8125 18.3334C17.4725 18.1959 18.0958 17.93 18.6092 17.5359C20.0392 16.4634 20.0392 14.6942 18.6092 13.6217C18.105 13.2367 17.4908 12.98 16.84 12.8334"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconLocation = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M9.00016 10.0725C10.2925 10.0725 11.3402 9.02486 11.3402 7.73252C11.3402 6.44017 10.2925 5.39252 9.00016 5.39252C7.70781 5.39252 6.66016 6.44017 6.66016 7.73252C6.66016 9.02486 7.70781 10.0725 9.00016 10.0725Z"
			stroke="#777777"
			stroke-width="1.2"
		/>
		<path
			d="M2.71527 6.3675C4.19277 -0.127498 13.8153 -0.119998 15.2853 6.375C16.1478 10.185 13.7778 13.41 11.7003 15.405C10.1928 16.86 7.80777 16.86 6.29277 15.405C4.22277 13.41 1.85277 10.1775 2.71527 6.3675Z"
			stroke="#777777"
			stroke-width="1.2"
		/>
	</svg>
);

const IconTime = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.7827 11.385L9.45766 9.99751C9.05266 9.75751 8.72266 9.18001 8.72266 8.70751V5.63251"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.7827 11.385L9.45766 9.99751C9.05266 9.75751 8.72266 9.18001 8.72266 8.70751V5.63251"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9Z"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.7827 11.385L9.45766 9.99751C9.05266 9.75751 8.72266 9.18001 8.72266 8.70751V5.63251"
			stroke="#0E1111"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const IconCalendar = (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path d="M7.33203 1.83335V4.58336" stroke="#0E1111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M14.668 1.83335V4.58336" stroke="#0E1111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.20703 8.33251H18.7904" stroke="#0E1111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M19.25 7.79167V15.5833C19.25 18.3333 17.875 20.1667 14.6667 20.1667H7.33333C4.125 20.1667 2.75 18.3333 2.75 15.5833V7.79167C2.75 5.04167 4.125 3.20833 7.33333 3.20833H14.6667C17.875 3.20833 19.25 5.04167 19.25 7.79167Z"
			stroke="#0E1111"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M10.9963 12.5583H11.0045" stroke="#0E1111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.60369 12.5583H7.61192" stroke="#0E1111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.60369 15.3083H7.61192" stroke="#0E1111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconClose = (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path d="M3.75 3.75L14.2493 14.2493" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M3.75071 14.2493L14.25 3.75" stroke="#424549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
);

const IconLeft = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_1341_6702)">
			<path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#0E1111" />
		</g>
		<defs>
			<clipPath id="clip0_1341_6702">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
const IconRight = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_1341_6705)">
			<path d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z" fill="#0E1111" />
		</g>
		<defs>
			<clipPath id="clip0_1341_6705">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

const IconCopy = (
	<svg className="absolute" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="vuesax/bold/link-square">
			<g id="link-square">
				<path
					id="Vector"
					d="M51.269 6.33334H24.7323C13.2057 6.33334 6.33398 13.205 6.33398 24.7317V51.2367C6.33398 62.795 13.2057 69.6667 24.7323 69.6667H51.2373C62.764 69.6667 69.6357 62.795 69.6357 51.2683V24.7317C69.6673 13.205 62.7957 6.33334 51.269 6.33334ZM25.904 53.105C25.8407 53.105 25.7457 53.105 25.6823 53.105C22.6107 52.82 19.729 51.395 17.5757 49.115C12.509 43.795 12.509 35.15 17.5757 29.83L24.5107 22.5467C26.9806 19.95 30.3057 18.4933 33.8523 18.4933C37.399 18.4933 40.724 19.9183 43.194 22.5467C48.2607 27.8667 48.2607 36.5117 43.194 41.8317L39.7423 45.4733C38.824 46.4233 37.3357 46.455 36.3857 45.5683C35.4357 44.65 35.404 43.1617 36.2907 42.2117L39.7423 38.57C43.099 35.055 43.099 29.3233 39.7423 25.84C36.6073 22.5467 31.0973 22.5467 27.9307 25.84L20.9957 33.1233C17.639 36.6383 17.639 42.37 20.9957 45.8533C22.3573 47.31 24.194 48.1967 26.1257 48.3867C27.424 48.5133 28.374 49.685 28.2473 50.9833C28.1523 52.1867 27.1073 53.105 25.904 53.105ZM58.4257 46.2017L51.4907 53.485C49.0207 56.0817 45.6957 57.5383 42.149 57.5383C38.6023 57.5383 35.2773 56.1133 32.8073 53.485C27.7407 48.165 27.7407 39.52 32.8073 34.2L36.259 30.5583C37.1773 29.6083 38.6657 29.5767 39.6157 30.4633C40.5657 31.3817 40.5973 32.87 39.7107 33.82L36.259 37.4617C32.9023 40.9767 32.9023 46.7083 36.259 50.1917C39.394 53.485 44.904 53.5167 48.0707 50.1917L55.0057 42.9083C58.3623 39.3933 58.3623 33.6617 55.0057 30.1783C53.644 28.7217 51.8073 27.835 49.8757 27.645C48.5773 27.5183 47.6273 26.3467 47.754 25.0483C47.8807 23.75 49.0207 22.7683 50.3507 22.9267C53.4223 23.2433 56.304 24.6367 58.4573 26.9167C63.4923 32.205 63.4923 40.8817 58.4257 46.2017Z"
					fill="#65737E"
				/>
			</g>
		</g>
	</svg>
);
