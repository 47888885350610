import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Cookies from "js-cookie";
import "./index.scss";
// import "../node_modules/bootstrap/dist/css/bootstrap.css";

// 각 페이지 Import
import Header from "./components/Header";
import Footer from "./components/Footer";
import FloatingButton from "./components/FloatChatButton";
import OurDestination from "./pages/OurDestination";
import RecommendTrip from "./pages/RecommendTrip";
import Login from "./pages/mem_login";
import CustomerRegister from "./pages/mem_customerRegister";
import PartnerCustomerRegister from "./pages/mem_partnerCustomerRegister";
import RegisterSelect from "./pages/mem_registerSelect";
import Notice from "./pages/srv_notice";
import FAQ from "./pages/srv_faq";
import Inquiry from "./pages/srv_inquiry";
import Partnerships from "./pages/srv_patnerships";
import Event from "./pages/srv_event";
import CommunityHowToUse from "./pages/cmu_howToUse";
import CommunityRecruit from "./pages/cmu_recruit";
import CommunityPlanning from "./pages/cmu_planning";
import CommunityDetailPage from "./pages/cmu_detailPage";
import CommunityChat from "./pages/cmu_chat";
import OrderMadeTripBrandStory from "./pages/omt_brandStory";
import CommunityMakeRecruit from "./pages/cmu_makeCommunity";
import AssembleHowToUse from "./pages/asm_howToUse";
import NotFound from "./components/notFound";
import AssembleMake from "./pages/asm_make";
import AssembleParticipate from "./pages/asm_participate";
import MyPage from "./pages/myp_mypage";
import MypageMyOrder from "./pages/myp_myOrder";
import MypageMyOrderDetail from "./pages/myp_myOrderDetail";
import MypageMyOrderPayment from "./pages/myp_myOrderPayList";
import MypageMyOrderEdit from "./pages/myp_myOrderEdit";
import MypageMyCommunity from "./pages/myp_myCommunity";
import OrderTrip from "./pages/ord_orderTrip";
import OrderHowTo from "./pages/ord_howToOrder";
import AdminIndex from "./admin_pages/admin_index";
import CheckLogin from "./components/CheckLogin";
import { MainPage } from "./main";
import InicisAuthPopup from "./pages/mem_inicisAuthPopup";
import CheckUserLogin from "./components/CheckUserLogin";
import PartnerMyOrderStatusPage from "./pages/partner/my/order-status";
import MyPagePartnerCreateQuotation from "./components/mypage/partner/create-quotation";
import PartnerMyInvoicePage from "./pages/partner/my/invoice";
import PartnerMyComplete from "./pages/partner/my/complete";
import PartnerMyReservation from "./pages/partner/my/reservation";
import OrderSchedulePage from "./pages/order/schedule";
import CommunityClupParticipate from "./pages/community-clup/participate";
import VoteMain from "./pages/cmu_voteMain";
import VoteDetail from "./pages/cmu_voteDetail";
import CreateVote from "./pages/cmu_createVote";
import AdminDashboardPage from "./pages/admin/dashboard";
import AdminUsersPage from "./pages/admin/users";
import AdminPartnersPage from "./pages/admin/partners";
import AdminOrdersPage from "./pages/admin/orders";
import AdminCommunityClupsPage from "./pages/admin/community-clups";
import AdminPaymentPage from "./pages/admin/payments";
import AdminSystemPage from "./pages/admin/system";
import TermsOfPolicyPage from "./pages/terms/terms-of-policy";
import ExternalTripPage from "./pages/terms/external-trip";
import InternalTripPage from "./pages/terms/internal-trip";
import PrivacyPage from "./pages/terms/privacy";
import PaymentCompletePage from "./pages/payment/complete";
import RecommendationTripDetail from "./pages/recommendation-trip/detail";
import OrderCompletedPage from "./pages/order/completed";
import CommunityEditPage from "./pages/cmu_communityEdit";
import MypageParticipationList from "./pages/myp_communityAttendList";
import MypagescheduleList from "./pages/myp_scheduleList";
import MypageCompletedList from "./pages/myp_completedOrder";
import MypageCommunityPayed from "./pages/mcu_communityPayed";
import MypageCommunityPayedList from "./pages/myp_communityPayList";
import PartnerMySaleStatusPage from "./pages/partner/my/sale-status";
import AdminEventsPage from "./pages/admin/events";
import AdminFranchisesPage from "./pages/admin/franchises";
import PartnerCommentOrder from "./pages/mypp_commentOrder";
import AdminOrdersIdPage from "./pages/admin/orders/id";
import AdminPartnersIdPage from "./pages/admin/partners/id";
import AdminUsersIdPage from "./pages/admin/users/id";
import AdminCommunityClupsIdPage from "./pages/admin/community-clups/id";
import AdminCommunityClupsIdChatPage from "./pages/admin/community-clups/id/chat";
import AdminEventsIdPage from "./pages/admin/events/id";
import AdminFaqPage from "./pages/admin/faq";
import AdminRecommendTripsPage from "./pages/admin/recommend-trips";
import AdminRecommendTripsIdPage from "./pages/admin/recommend-trips/id";
import AdminNotificationsPage from "./pages/admin/notifications";
import AdminNoticePage from "./pages/admin/notice";
import AdminNoticeIndexPage from "./pages/admin/notices";
import CalendarIndex from "./pages/calendar";
import { DEFAULT } from "./api";
import AdminVisitorsPage from "./pages/admin/visitors";
import AdminBizTalkLogPage from "./pages/admin/biztalklog";
import EventDetail from "./pages/event_detail";
import AdminPartnerQuotationsIdPage from "./pages/admin/partner-quotations/id";
import AdminPopUpPage from "./pages/admin/popup";

const IndexPage = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get("isLoggedIn") === "true");
	const [partnerLogin, setPartnerLogin] = useState(Cookies.get("partnerLogin") === "true");

	useEffect(() => {
		const checkLoginStatus = () => {
			setIsLoggedIn(Cookies.get("isLoggedIn") === "true");
			setPartnerLogin(Cookies.get("partnerLogin") === "true");
		};

		// 쿠키 값이 변경될 때마다 확인
		window.addEventListener("storage", checkLoginStatus);
		DEFAULT.log();

		return () => {
			// 컴포넌트 언마운트 시 이벤트 리스너 제거
			window.removeEventListener("storage", checkLoginStatus);
		};
	}, []);

	function IAMWeb() {
		window.location.replace('https://ordermtrip-landing.imweb.me/');
		return null;
	}

	return (
		<Router>
			<Routes>
				<Route path="/policy/terms-of-use" element={<TermsOfPolicyPage />} />
				<Route path="/policy/external-trip" element={<ExternalTripPage />} />
				<Route path="/policy/internal-trip" element={<InternalTripPage />} />
				<Route path="/policy/privacy" element={<PrivacyPage />} />

				{/* <Route
					path="/"
					// element={ window.location.href='https://inatest.imweb.me/' }
					element={ <IAMWeb /> }
				/> */}
				<Route
					path="/"
					element={
						<>
							<Header activedMenu={-1 as number} />
							<MainPage />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/ourdestination"
					element={
						<>
							<Header activedMenu={2 as number} />
							<OurDestination />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/recommendTrip"
					element={
						<CheckLogin roles={["USER", "PARTNER"]}>
							<Header activedMenu={2 as number} />
							<RecommendTrip />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/login"
					element={
						<>
							<Header activedMenu={0 as number} />
							<Login />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/register"
					element={
						<>
							<Header activedMenu={0 as number} />
							<CustomerRegister />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/partnerRegister"
					element={
						<>
							<Header activedMenu={0 as number} />
							<PartnerCustomerRegister />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/registerSelect"
					element={
						<>
							<Header activedMenu={0 as number} />
							<RegisterSelect />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/notice"
					element={
						<>
							<Header activedMenu={6 as number} />
							<Notice />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/faq"
					element={
						<>
							<Header activedMenu={6 as number} />
							<FAQ />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/inquiry"
					element={
						<>
							<Header activedMenu={6 as number} />
							<Inquiry />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/partnerships"
					element={
						<>
							<Header activedMenu={6 as number} />
							<Partnerships />
							<Footer />
							<FloatingButton />
						</>
					}
				/>

				<Route
					path="/event"
					element={
						<>
							<Header activedMenu={6 as number} />
							<Event />
							<Footer />
							<FloatingButton />
						</>
					}
				/>

				<Route
					path="/event/:event_id"
					element={
						<>
							<Header activedMenu={6 as number} />
							<EventDetail />
							<Footer />
							<FloatingButton />
						</>
					}
				/>

				<Route
					path="/community_howToUse"
					element={
						<>
							<Header activedMenu={5 as number} />
							<CommunityHowToUse />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/community_recruit"
					element={
						<>
							<Header activedMenu={5 as number} />
							<CommunityRecruit />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/community_planning"
					element={
						<>
							<Header activedMenu={5 as number} />
							<CommunityPlanning />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/community/:community_id/details"
					element={
						// <CheckLogin roles={["USER"]}>
						<>
							<Header activedMenu={5 as number} />
							<CommunityDetailPage />
							<Footer />
							<FloatingButton />
						</>
						// </CheckLogin>
					}
				/>
				<Route
					path="/community/:chat_room_id/chat"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={5 as number} />
							<CommunityChat />
						</CheckLogin>
					}
				/>
				<Route
					path="/brand_story"
					element={
						<>
							<Header activedMenu={1 as number} />
							<OrderMadeTripBrandStory />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/community_makeCommunity"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={5 as number} />
							<CommunityMakeRecruit />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/assemble_howToUse"
					element={
						<>
							<Header activedMenu={4 as number} />
							<AssembleHowToUse />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/assemble_make"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={4 as number} />
							<AssembleMake />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/assemble_participate"
					element={
						<>
							<Header activedMenu={4 as number} />
							<AssembleParticipate />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/my/order/completed"
					element={
						<>
							<Header activedMenu={0 as number} />
							<OrderCompletedPage />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/mypage"
					element={
						<>
							<CheckLogin roles={["USER", "PARTNER"]}>
								<Header activedMenu={0 as number} />
								<MyPage />
								<Footer />
							</CheckLogin>
						</>
					}
				/>
				<Route
					path="/partner/my/order-comment"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<PartnerCommentOrder />
								<Footer />
							</CheckLogin>
						</>
					}
				/>

				<Route
					path="/registerSelect/_popup"
					element={
						<>
							<InicisAuthPopup />
						</>
					}
				/>

				<Route
					path="/mypage/myOrder"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageMyOrder />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/mypage/myOrder/:order_id"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageMyOrderDetail />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/payment-complete"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<PaymentCompletePage />
							<Footer />
						</CheckLogin>
					}
				/>
				<Route
					path="/mypage/order/payment"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageMyOrderPayment />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/mypage/order/edit/:order_id"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageMyOrderEdit />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/mypage/community"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageMyCommunity />
							<Footer />
						</CheckLogin>
					}
				/>

				<Route
					path="/mypage/community/attend"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageParticipationList />
							<Footer />
						</CheckLogin>
					}
				/>

				<Route
					path="/mypage/community/payed"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageCommunityPayedList />
							<Footer />
						</CheckLogin>
					}
				/>

				<Route
					path="/mypage/completed"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageCompletedList />
							<Footer />
						</CheckLogin>
					}
				/>

				<Route
					path="/mypage/schedule"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypagescheduleList />
							<Footer />
						</CheckLogin>
					}
				/>

				<Route
					path="/community/:order_id/pay"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={0 as number} />
							<MypageCommunityPayed />
							<Footer />
						</CheckLogin>
					}
				/>

				<Route
					path="/travel_howToOrder"
					element={
						<>
							<Header activedMenu={3 as number} />
							<OrderHowTo />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/recommend/:recommendation_trip_id"
					element={
						<>
							<Header activedMenu={3 as number} />
							<RecommendationTripDetail />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
				<Route
					path="/travel_Order"
					element={
						<CheckLogin roles={["USER"]}>
							<Header activedMenu={3 as number} />
							<OrderTrip />
							<Footer />
							<FloatingButton />
						</CheckLogin>
					}
				/>
				<Route
					path="/partner/my/order-status"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<PartnerMyOrderStatusPage />
								<Footer />
							</CheckLogin>
						</>
					}
				/>
				<Route
					path="/partner/my/create-quotation"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<MyPagePartnerCreateQuotation />
								<Footer />
							</CheckLogin>
						</>
					}
				/>
				<Route
					path="/partner/my/invoice"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<PartnerMyInvoicePage />
								<Footer />
							</CheckLogin>
						</>
					}
				/>
				<Route
					path="/partner/my/complete"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<PartnerMyComplete />
								<Footer />
							</CheckLogin>
						</>
					}
				/>
				<Route
					path="/partner/my/reservation"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<PartnerMyReservation />
								<Footer />
							</CheckLogin>
						</>
					}
				/>
				<Route
					path="/partner/my/sale-status"
					element={
						<>
							<CheckLogin roles={["PARTNER"]}>
								<Header activedMenu={0 as number} />
								<PartnerMySaleStatusPage />
								<Footer />
							</CheckLogin>
						</>
					}
				/>

				{/* 일정표 제작 */}
				<Route
					path="/order/schedule/:order_id"
					element={
						<>
							<Header activedMenu={0 as number} />
							<OrderSchedulePage />
							<Footer />
						</>
					}
				/>

				{/* 커뮤니티 클럽 참가 폼 */}
				<Route
					path="/community/:community_clup_id/participate"
					element={
						<>
							<Header activedMenu={0 as number} />
							<CommunityClupParticipate />
							<Footer />
						</>
					}
				/>

				<Route
					path="/community/:community_id/edit"
					element={
						<>
							<Header activedMenu={0 as number} />
							<CommunityEditPage />
							<Footer />
						</>
					}
				/>

				<Route
					path="/community/:chat_room_id/calendar"
					element={
						<>
							<Header activedMenu={0 as number} />
							<CalendarIndex />
						</>
					}
				/>

				{/* 커뮤니티 클럽 투표 리스트 폼 */}
				<Route
					path="/community/:chat_room_id/vote"
					element={
						<>
							<Header activedMenu={0 as number} />
							<VoteMain />
							<Footer />
						</>
					}
				/>

				{/* 커뮤니티 클럽 투표 리스트 폼 */}
				<Route
					path="/community/:chat_room_id/create_vote"
					element={
						<>
							<Header activedMenu={0 as number} />
							<CreateVote />
							<Footer />
						</>
					}
				/>

				{/* 커뮤니티 클럽 투표 세부내용 폼 */}
				<Route
					path="/community/:chat_room_id/vote/:vote_id"
					element={
						<>
							<Header activedMenu={0 as number} />
							<VoteDetail />
							<Footer />
						</>
					}
				/>

				{/* ========== [ Admin 페이지 부분의 시작 ] ========== */}
				<Route
					path="/admin"
					element={
						<>
							<AdminIndex />
						</>
					}
				/>
				<Route
					path="/admin/dashboard"
					element={
						<>
							<AdminDashboardPage />
						</>
					}>
					<Route path="/admin/dashboard/users" element={<AdminUsersPage />} />
					<Route path="/admin/dashboard/users/:user_id" element={<AdminUsersIdPage />} />

					<Route path="/admin/dashboard/partners" element={<AdminPartnersPage />} />
					<Route path="/admin/dashboard/partners/:partner_id" element={<AdminPartnersIdPage />} />

					<Route path="/admin/dashboard/orders" element={<AdminOrdersPage />} />
					<Route path="/admin/dashboard/orders/:order_id" element={<AdminOrdersIdPage />} />
					<Route path="/admin/dashboard/partner-quotations/:partner_quotation_id" element={<AdminPartnerQuotationsIdPage />} />
					<Route path="/admin/dashboard/community-clups" element={<AdminCommunityClupsPage />} />
					<Route path="/admin/dashboard/community-clups/:community_clup_id" element={<AdminCommunityClupsIdPage />} />
					<Route path="/admin/dashboard/community-clups/:community_clup_id/chats" element={<AdminCommunityClupsIdChatPage />} />

					<Route path="/admin/dashboard/payments" element={<AdminPaymentPage />} />

					<Route path="/admin/dashboard/system" element={<AdminSystemPage />} />

					<Route path="/admin/dashboard/franchises" element={<AdminFranchisesPage />} />
					<Route path="/admin/dashboard/franchises/franchise_id" element={<AdminEventsIdPage />} />

					<Route path="/admin/dashboard/events" element={<AdminEventsPage />} />

					<Route path="/admin/dashboard/faqs" element={<AdminFaqPage />} />
					<Route path="/admin/dashboard/notices" element={<AdminNoticePage />} />
					<Route path="/admin/dashboard/notices/:notice_id" element={<AdminNoticeIndexPage />} />

					<Route path="/admin/dashboard/recommend-trips" element={<AdminRecommendTripsPage />} />
					<Route path="/admin/dashboard/recommend-trips/:recommendation_trip_id" element={<AdminRecommendTripsIdPage />} />

					<Route path="/admin/dashboard/notifications" element={<AdminNotificationsPage />} />
					<Route path="/admin/dashboard/visitors" element={<AdminVisitorsPage />} />
					<Route path="/admin/dashboard/biztalklog" element={<AdminBizTalkLogPage />} />
					<Route path="/admin/dashboard/popup" element={<AdminPopUpPage />} />
				</Route>

				{/* ========== [ Admin 페이지 부분의 끝 ] ========== */}

				{/* 상기 지정된 URL이 아닌 경우 (≒HTTP 404)*/}
				<Route
					path="*"
					element={
						<>
							<Header />
							<NotFound />
							<Footer />
							<FloatingButton />
						</>
					}
				/>
			</Routes>
		</Router>
	);
};

// ReactDOM.render(<IndexPage />, document.getElementById("root"));  // React 17 이하
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<IndexPage />);
