import React, { useEffect, useState } from "react";

interface Props {
	startDate: string;
	endDate: string;
	onChange: (startDate: string, endDate: string) => void;
	className?: string;
	active: boolean;
}

export default function InputDateRange({ startDate, endDate, onChange, className, active }: Props) {
	const [duration, setDuration] = useState("");

	const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value, endDate);
		calculateDuration(event.target.value, endDate);
	};

	const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(startDate, event.target.value);
		calculateDuration(startDate, event.target.value);
	};

	useEffect(() => {
		calculateDuration(startDate, endDate);
	}, [startDate]);

	const calculateDuration = (start: string, end: string) => {
		const startDate = new Date(start);
		const endDate = new Date(end);
		const timeDiff = endDate.getTime() - startDate.getTime();
		if (timeDiff >= 0) {
			const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));
			setDuration(`${daysDiff}박 ${daysDiff + 1}일`);
		} else {
			setDuration("");
		}
	};

	return (
		<div className={`flex items-center ${className}`}>
			<input
				disabled={!active}
				className="py-[6px] px-[7px] bg-transparent w-full h-[30px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] font-spoqa cursor-pointer" // Replace with actual class names for styling
				type="date"
				min={new Date().toISOString().split("T")[0]}
				value={startDate}
				onChange={handleStartDateChange}
			/>
			<span className="mx-1">~</span>
			<input
				disabled={!active}
				className="py-[6px] px-[7px] bg-transparent w-full h-[30px] rounded-[2px] border border-[#DADADA] text-[#0e1111] text-[14px] font-medium tracking-[-0.32px] font-spoqa cursor-pointer" // Replace with actual class names for styling
				type="date"
				min={startDate || new Date().toISOString().split("T")[0]}
				value={endDate}
				onChange={handleEndDateChange}
			/>

			<div
				className="text-[#0057FF] flex justify-center items-center whitespace-nowrap ml-2 w-16 xl:w-[151px] py-[6px] px-[7px] h-[30px] rounded-[2px] border border-[#DADADA] text-[14px] font-medium tracking-[-0.32px] font-spoqa" // Replace with actual class names for styling
			>
				{duration}
			</div>
		</div>
	);
}
