import React, { useEffect, useState } from "react";
import { ListFormat } from "typescript";
import heartFill from "../image/heart-fill.svg";
import heart from "../image/heart.svg";

const OurDestinationCard = ({ data, categories }: any) => {
	return (
		<div
			onClick={() => {
				window.location.href = `/recommend/${data.id}`;
			}}
			className="w-full relative border border-[#DADADA] rounded-[2px]  shadow-[0_0_8px_0_#0E111133]">
			<div className="w-full relative bg-cover box-border aspect-square" style={{ backgroundImage: `url(${data.RecommendationTripFile[0].File.url})` }}>
				<div className="absolute bottom-0 left-0 bg-white/60 w-[70px] h-[20px] text-[10px] font-medium rounded-tr-[15px] shadow-[0_-3px_4px_0_#00000040] flex justify-start pl-2 gap-[5px] items-center">
					<img className="pb-[2px]" src={data.favorited ? heartFill : heart} />
					<div>{data.countOfLike} </div>
				</div>
			</div>
			<div className="absolute top-[5px] left-[5px] text-[10px] font-medium px-[9px] py-[4px] bg-[#3866D9] text-white">
				{data.category1}·{data.category2}
			</div>
			<div className="px-2 py-[6px]">
				<div className="text-[14px] font-medium truncate mb-[6px]">{data.name}</div>
				<div className="text-xs font-normal line-clamp-2 break-keep">{data.description}</div>
				<div className=""></div>
			</div>
		</div>
	);
};

export default OurDestinationCard;
