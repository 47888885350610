import { useEffect, useState } from "react";
import { DEFAULT, FILE } from "../../admin_pages/api";
import Modal from "../../components/modal";
import FormFactory from "../../components/FormFactory";

export default function AdminSystemPage() {
	const [data, setData] = useState<any>(null);
	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'

	useEffect(() => {
		DEFAULT.serviceConfig().then(async (v) => {
			setData(v);
		});
	}, []);

	const handleUpdate = async (data: any) => {
		await DEFAULT.updateServiceConfig({
			paymentBankAccounts: data.paymentBankAccounts,
			serviceCommission: data.serviceCommission,
			companyContact: data.companyContact,
			tripTypes: Array.isArray(data.tripTypes) ? data.tripTypes : data.tripTypes.split(","),
			transportations: Array.isArray(data.transportations) ? data.transportations : data.transportations.split(","),
			accommodations: Array.isArray(data.accommodations) ? data.accommodations : data.accommodations.split(","),

			franchiseCategories: Array.isArray(data.franchiseCategories) ? data.franchiseCategories : data.franchiseCategories.split(","),
			noticeCategories: Array.isArray(data.noticeCategories) ? data.noticeCategories : data.noticeCategories.split(","),
			faqCategories: Array.isArray(data.faqCategories) ? data.faqCategories : data.faqCategories.split(","),
			eventCategories: Array.isArray(data.eventCategories) ? data.eventCategories : data.eventCategories.split(","),

			mainBannerImageUrl: typeof data.mainBannerImageUrl === "string" ? data.mainBannerImageUrl : data.mainBannerImageUrl.url,
			ourDestinationImageUrl: typeof data.mainBannerImageUrl === "string" ? data.mainBannerImageUrl : data.ourDestinationImageUrl.url,
			travelParticipationImageUrl: typeof data.mainBannerImageUrl === "string" ? data.mainBannerImageUrl : data.travelParticipationImageUrl.url,
			travelRecruitmentImageUrl: typeof data.mainBannerImageUrl === "string" ? data.mainBannerImageUrl : data.travelRecruitmentImageUrl.url,

			mainBannerText: data.mainBannerText,
			ourDestinationText: data.ourDestinationText,
			travelParticipationText: data.travelParticipationText,
			travelRecruitmentText: data.travelRecruitmentText,
		});
		setModal("");
		DEFAULT.serviceConfig().then((v) => {
			setData(v);
		});
	};

	return (
		<div>
			<h1 className="text-xl">서비스 관리</h1>
			<div className="font-bold mt-4">정보</div>
			<div className="flex flex-col max-w-3xl font-medium">
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">은행 계좌</div>
					<div>{data?.paymentBankAccounts}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">플랫폼 수수료</div>
					<div>{data?.serviceCommission}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">회사대표 전화번호</div>
					<div>{data?.companyContact}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행 유형</div>
					<div>{data?.tripTypes?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">교통</div>
					<div>{data?.transportations?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">숙소</div>
					<div>{data?.accommodations?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">제휴서비스 카테고리</div>
					<div>{data?.franchiseCategories?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">공지사항 카테고리</div>
					<div>{data?.noticeCategories?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">FAQ 카테고리</div>
					<div>{data?.faqCategories?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">이벤트 카테고리</div>
					<div>{data?.eventCategories?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">메인배너</div>
					<a className="text-blue-500" href={data?.mainBannerImageUrl} target="_blank">
						{data?.mainBannerImageUrl}
					</a>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">목적지배너</div>
					<a className="text-blue-500" href={data?.ourDestinationImageUrl} target="_blank">
						{data?.ourDestinationImageUrl}
					</a>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행참여배너</div>
					<a className="text-blue-500" href={data?.travelParticipationImageUrl} target="_blank">
						{data?.travelParticipationImageUrl}
					</a>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행모집배너</div>
					<a className="text-blue-500" href={data?.travelRecruitmentImageUrl} target="_blank">
						{data?.travelRecruitmentImageUrl}
					</a>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">메인배너 텍스트</div>
					<div>{data?.mainBannerText}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">목적지배너 텍스트</div>
					<div>{data?.ourDestinationText}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행참여배너 텍스트</div>
					<div>{data?.travelParticipationText}</div>
				</div>
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행모집배너 텍스트</div>
					<div>{data?.travelRecruitmentText}</div>
				</div>
			</div>

			<div className="flex w-full max-w-3xl justify-end gap-4">
				<button
					onClick={() => {
						setModal("update");
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			</div>

			<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
				<div className="mb-6">수정</div>
				<div>여행 타입, 교통, 숙소는 "," 로 구분하여 입력해주세요.</div>
				<FormFactory
					schema={[
						{ label: "계좌", key: "paymentBankAccounts", method: "text", validation: "required", defaultValue: data?.paymentBankAccounts },
						{ label: "수수료", key: "serviceCommission", method: "text", validation: "required", defaultValue: data?.serviceCommission },
						{ label: "회사대표전화번호", key: "companyContact", method: "text", validation: "required", defaultValue: data?.companyContact },
						{ label: "여행 타입", key: "tripTypes", method: "textarea", validation: "required", defaultValue: data?.tripTypes },
						{ label: "교통", key: "transportations", method: "textarea", validation: "required", defaultValue: data?.transportations },
						{ label: "숙소", key: "accommodations", method: "textarea", validation: "required", defaultValue: data?.accommodations },

						{ label: "제휴서비스C", key: "franchiseCategories", method: "textarea", validation: "required", defaultValue: data?.franchiseCategories },
						{ label: "공지사항C", key: "noticeCategories", method: "textarea", validation: "required", defaultValue: data?.noticeCategories },
						{ label: "FAQC", key: "faqCategories", method: "textarea", validation: "required", defaultValue: data?.faqCategories },
						{ label: "이벤트C", key: "eventCategories", method: "textarea", validation: "required", defaultValue: data?.eventCategories },

						{ label: "메인배너", key: "mainBannerImageUrl", method: "file", validation: "required", defaultValue: data?.mainBannerImageUrl },
						{ label: "목적지배너", key: "ourDestinationImageUrl", method: "file", validation: "required", defaultValue: data?.ourDestinationImageUrl },
						{
							label: "여행참여배너",
							key: "travelParticipationImageUrl",
							method: "file",
							validation: "required",
							defaultValue: data?.travelParticipationImageUrl,
						},
						{ label: "여행모집배너", key: "travelRecruitmentImageUrl", method: "file", validation: "required", defaultValue: data?.travelRecruitmentImageUrl },
						{ label: "메인배너 텍스트", key: "mainBannerText", method: "wizwig", validation: "required", defaultValue: data?.mainBannerText },
						{ label: "목적지배너 텍스트", key: "ourDestinationText", method: "wizwig", validation: "required", defaultValue: data?.ourDestinationText },
						{
							label: "여행참여배너 텍스트",
							key: "travelParticipationText",
							method: "wizwig",
							validation: "required",
							defaultValue: data?.travelParticipationText,
						},
						{
							label: "여행모집배너 텍스트",
							key: "travelRecruitmentText",
							method: "wizwig",
							validation: "required",
							defaultValue: data?.travelRecruitmentText,
						},
					]}
					onSubmit={handleUpdate}
				/>
			</Modal>
		</div>
	);
}
