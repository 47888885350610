import { useEffect, useState } from "react";
import { COMMUNITY_CLUP } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";
import EasyPagination from "../../components/EasyPagination";

export default function AdminCommunityClupsPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});

	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'
	const [selected, setSelected] = useState<any>(null);

	useEffect(() => {
		getData();
	}, [page]);

	const getData = async () => {
		const response = await COMMUNITY_CLUP.pagination(page.page, page.perPage);
		setData(response.nodes);
		setCount(response.total);
	};

	return (
		<div>
			<h1 className="text-xl">커뮤니티 클럽 관리</h1>
			<div className="flex justify-end">
				{/* <button onClick={() => setModal("add")} className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					추가
				</button> */}
			</div>

			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable
						data={data}
						columns={[
							{
								header: "id",
								key: "id",
								render: (id) => (
									<button className="text-blue-500 cursor-pointer" onClick={() => (window.location.href = `/admin/dashboard/community-clups/${id}`)}>
										{id}
									</button>
								),
							},
							{ header: "type", key: "type" },
							{ header: "status", key: "status" },
							{
								header: "userId",
								key: "userId",
								render: (userId) => (
									<button onClick={() => (window.location.href = `/admin/dashboard/users/${userId}`)} className="cursor-pointer text-blue-500">
										{userId}
									</button>
								),
							},
							{
								header: "orderId",
								key: "orderId",
								render: (orderId) => (
									<button onClick={() => (window.location.href = `/admin/dashboard/orders/${orderId}`)} className="cursor-pointer text-blue-500">
										{orderId}
									</button>
								),
							},
							{ header: "국가", key: "nation" },
							{ header: "도시", key: "city" },
							{ header: "시작날짜", key: "startedAt" },
							{ header: "종료날짜", key: "endedAt" },
							{ header: "예상 비용", key: "expenses" },
							{ header: "모집 인원", key: "numberOfRecruits" },
							{ header: "모집 유형", key: "recruitGenderType" },
							{ header: "삭제 여부", key: "deletedAt", render: (deletedAt) => (deletedAt ? "Y" : "N") },
							{
								header: "상세보기",
								key: "id",
								render: (id) => (
									<button
										onClick={() => window.open(`/admin/dashboard/community-clups/${id}`, "__blank")}
										className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
										상세보기
									</button>
								),
							},
						]}
					/>
				</div>

				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
}
