import { useEffect, useMemo, useState } from "react";
import { ORDER, PARTNER_QUOTATION } from "../../../api";
import IconEarth from "../../../components/icon/earth";
import InputList from "../../../components/input/list";
import { add, format, parse } from "date-fns";
import PartnerMyPagePartnerCard from "../../../components/mypage/partner/card";
import Pagination from "../../../components/pagination";
import PartnerMyPagePartnerQuotationCard from "../../../components/mypage/partner/card-quotation";
import PartnerMyPagePartnerQuotationCompleteCard from "../../../components/mypage/partner/card-complete";

const IconReceipt = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V8.5M9 9.5V8.5M9 9.5H11.0001M9 9.5C7.88279 9.49998 7.00244 9.62616 7.0001 10.8325C6.99834 11.7328 7.00009 12 9.00009 12C11.0001 12 11.0001 12.2055 11.0001 13.1667C11.0001 13.889 11 14.5 9 14.5M9 15.5V14.5M9 14.5L7.0001 14.5M14 10H17M14 20L16.025 19.595C16.2015 19.5597 16.2898 19.542 16.3721 19.5097C16.4452 19.4811 16.5147 19.4439 16.579 19.399C16.6516 19.3484 16.7152 19.2848 16.8426 19.1574L21 15C21.5523 14.4477 21.5523 13.5523 21 13C20.4477 12.4477 19.5523 12.4477 19 13L14.8426 17.1574C14.7152 17.2848 14.6516 17.3484 14.601 17.421C14.5561 17.4853 14.5189 17.5548 14.4903 17.6279C14.458 17.7102 14.4403 17.7985 14.405 17.975L14 20Z"
			stroke="#0057FF"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export default function PartnerMyCompletePage() {
	const [data, setData] = useState<any[]>([]);
	const [count, setCount] = useState(0);

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [selectedFilter, setSelectedFilter] = useState<boolean | undefined>(undefined);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	useEffect(() => {
		PARTNER_QUOTATION.getOwnedUnconfirmed().then((data) => {
			setData(data);
			setCount(data.length);
		});
	}, [page, perPage, selectedFilter, startDate, endDate]);

	const handleConfirm = () => {
		PARTNER_QUOTATION.getOwnedUnconfirmed().then((data) => {
			setData(data);
			setCount(data.length);
		});
	};
	const dateKeys = useMemo(() => {
		const set = new Set();
		for (const item of data) {
			set.add(format(new Date(item.createdAt), "yyyy.MM.dd"));
		}
		// @ts-ignore
		return [...set.values()];
	}, [data]);

	const getList = (date: string) => {
		return data.filter((item) => {
			return format(new Date(item.createdAt), "yyyy.MM.dd") === date;
		});
	};

	const filters = [
		{ label: "전체", value: undefined },
		{ label: "요청 전", value: false },
		{ label: "요청 후", value: true },
	];

	const [selectedDate, setSelectedDate] = useState<string | null>(null);

	const dates = [
		{ label: "1주", value: "1주" },
		{ label: "1개월", value: "1개월" },
		{ label: "3개월", value: "3개월" },
		{ label: "전체", value: "전체" },
	];

	useEffect(() => {
		if (selectedDate === null) return;
		else if (selectedDate === "1주") {
			setStartDate(format(add(new Date(), { days: -7 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "1개월") {
			setStartDate(format(add(new Date(), { months: -1 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "3개월") {
			setStartDate(format(add(new Date(), { months: -3 }), "yyyy-MM-dd"));
			setEndDate(format(new Date(), "yyyy-MM-dd"));
		} else if (selectedDate === "전체") {
			setStartDate("");
			setEndDate("");
		}
	}, [selectedDate]);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="text-success flex px-3 gap-1 mt-5 font-medium">{IconReceipt} 주문 확정하기</div>
				<div className="px-3 mt-6">
					<div className="h-[1px] w-full bg-[#dadada]"></div>
				</div>

				<div>
					{dateKeys.map((v) => {
						return (
							<div className="text-sm px-3 font-medium text-black mt-7">
								{v}
								<div>
									{getList(v).map((quotation) => (
										<div className="mt-2.5">
											<PartnerMyPagePartnerQuotationCompleteCard quotation={quotation} onConfirm={() => handleConfirm()} />
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
