import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import "./main.scss";
import "./pages/asm_participate.scss";
import axios from "axios";
import Cookies from "js-cookie";
import recruitIcon from "./image/recruitIcon.svg";
import user3Icon from "./image/userThree.svg";
import notebookIcon from "./image/Notebook.svg";
import viewIcon from "./image/Eye.svg";
import commentIcon from "./image/ChatTeardropText.svg";
import handleClapping from "./image/handle_clapping.svg";
import communityClubIcon from "./image/community_club.svg";
import recommendTrip from "./image/AirplaneTilt.svg";
import moreIcon from "./image/upper-information-header-more.svg";
import confettiIcon from "./image/confetti.svg";

import { RecommendationTrip, TravelPostProps } from "./types";
import { calculateStayDaysString, formatDateISOStringToDate, formatGender, formatTripType, getGroupLeaderProfile } from "./components/CommonFunction";
import OurDestinationCard from "./pages/ourDestinationCard";
import client from "./axios";
import Spacer from "./components/Spacer";
import { COMMUNITY_CLUP, RECOMMENDATION_TRIP } from "./api";
import CardCrewRecommend from "./components/main/CardCrewRecommend";
import OMTSubTitle from "./components/OMTSubTitle";
import MainCardRecruitmentOrder from "./components/main/CardRecruitmentOrder";
import OMTTab from "./components/OMTTab";
import MainCardCommunityClup from "./components/main/CardCommunityClup";
import SectionCommingSoon from "./components/main/SectionCommingSoon";
import MainHeader from "./components/main/Header";
import { login } from "./auth";
import MainPopUp from "./components/modal/MainPopUp";
import { pdfjs } from "react-pdf";

// @ts-ignore
Kakao.init("0ef0f4f55e87bbfe64b4005b3a6f47f9");
// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

interface CardData {
	imageUrl: string;
	tag: string;
	title: string;
	description: string;
	rating: number;
	favorited: boolean;
}

interface OurDestinationCardProps {
	data: CardData;
	categories: string[];
}

const RecommendationTripList = () => {
	const navigate = useNavigate();
	const [recommendTripData, setRecommendTripData] = useState<RecommendationTrip[]>([]);

	// 소셜 로그인 토큰값 설정
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const accessToken = searchParams.get("accessToken");

		if (accessToken) {
			login(accessToken, false, false);
		}
	}, [navigate]);

	useEffect(() => {
		const redirect = window.sessionStorage.getItem("redirect");
		if (redirect) {
			window.sessionStorage.removeItem("redirect");
			window.location.href = redirect;
		}

		RECOMMENDATION_TRIP.getMany().then((data) => setRecommendTripData(data.nodes));
	}, []);

	return (
		<>
			{recommendTripData.slice(0, 8).map((trip) => (
				<CardCrewRecommend
					key={trip.id}
					data={{
						description: trip?.description ?? "",
						id: trip.id,
						nation: trip.nation,
						category1: trip.category1,
						category2: trip.category2,
						countOfLike: trip.countOfLike,
						imageUrl: trip.RecommendationTripFile[0]?.File?.url || "",
						title: trip.name,
					}}
					onClick={() => {
						window.location.href = `/recommend/${trip.id}`;
					}}
				/>
			))}
		</>
	);
};

export function MainPage() {
	// (모집중인 여정/커뮤니티 클럽) 서버로부터 정보 받아오기
	const [recruitmentData, setRecruitmentData] = useState<TravelPostProps[]>([]);
	const [plannedData, setPlannedData] = useState<TravelPostProps[]>([]);
	const [allData, setAlldata] = useState<TravelPostProps[]>([]);
	const [filter, setFilter] = useState<TravelPostProps[]>([]);
	const [currentfilter, setCurrentFilter] = useState<any>("");
	useEffect(() => {
		COMMUNITY_CLUP.getMany("RECRUITMENT").then((data) => {
			setRecruitmentData(
				data?.nodes.filter((e: any) => {
					return new Date(e.startedAt).getTime() > new Date().getTime() - 1000 * 60 * 60 * 24;
				}) ?? []
			);
		});

		COMMUNITY_CLUP.getMany("PLANNED").then((data) => {
			setPlannedData(data?.nodes ?? []);
		});

		COMMUNITY_CLUP.getMany("").then((data) => {
			setAlldata(data?.nodes ?? []);
			setFilter(data?.nodes ?? []);
		});
	}, []);

	const filterItem = (item: string) => {
		setCurrentFilter(item);
		switch (item) {
			case "전체":
				setFilter(allData);
				break;
			case "모집":
				setFilter(recruitmentData);
				break;
			case "계획":
				setFilter(plannedData);
				break;
		}
	};
	// (모집중인 여정/커뮤니티 클럽) 카테고리 매핑
	const categoryMapping: { [key: string]: string } = {
		전체: "전체",
		모집: "RECRUITMENT",
		계획: "PLANNED",
	};

	return (
		<>
			<div className="flex w-full flex-col items-center ">
				<MainHeader />
				<div className="px-[12px] flex flex-col items-center">
					<div className="container mt-[20px] xl:mt-[50px] pb-[20px] xl:pb-[50px] flex justify-center flex-col">
						<OMTSubTitle
							title={
								<span className="xl:mb-[40px]">
									<img src={recommendTrip} alt="크루들의 추천여행" /> 크루들의 추천여행
								</span>
							}
							sub={
								<div onClick={() => (window.location.href = "/ourdestination")} className="button cursor-pointer xl:mb-[40px]">
									더보기
									<img src={moreIcon} alt="더보기" />
								</div>
							}
						/>

						<div className="mt-4">
							<div className="w-100 grid-card m-0 p-0">
								<RecommendationTripList />
							</div>
						</div>
					</div>

					<hr className="w-full border-1 border-[#999999]" />

					<div className="container mt-[20px] xl:mt-[50px] pb-[20px] xl:pb-[50px]">
						<OMTSubTitle
							title={
								<h4 className="flex gap-2 xl:mb-[22px]">
									<img src={handleClapping} alt="다른 메이트들의 모집중인 여정 아이콘" /> 다른 메이트들의 모집중인 여정
								</h4>
							}
							sub={
								<div onClick={() => (window.location.href = "/community_recruit")} className="button cursor-pointer xl:mb-[22px]">
									더보기
									<img src={moreIcon} alt="더보기" />
								</div>
							}
						/>

						<div className="mt-4">
							<div className="w-100 grid-card m-0 p-0">
								{recruitmentData.slice(0, 4).map((v, i) => {
									return (
										<MainCardRecruitmentOrder
											data={{
												currentMember: v.participants?.length ?? 0,
												title: v.title,
												tripType: v.tripType,
												userNickname: v.participants?.filter((e) => e.id === v.userId)[0]?.nickname || "",
												description: v.description ?? "",
												endDate: v.endedAt,
												id: v.id,
												maxMember: v.numberOfRecruits,
												genderType: v.recruitGenderTypeText,
												age: v.ageGroup,
												nation: v.nation,
												startDate: v.startedAt,
												orderType: v.type,
												userIcon: v.user?.profile,
												images: v.CommunityClupFile?.map((v) => v.File.url) ?? [],
											}}
											key={i}
										/>
									);
								})}
							</div>
						</div>
					</div>

					<hr className="w-full border-1 border-[#999999]" />

					<div className="container mt-[20px] xl:mt-[50px] pb-[20px] xl:pb-[50px]">
						<OMTSubTitle
							title={
								<h4 className="flex gap-2">
									<img src={confettiIcon} alt="다른 메이트들의 모집중인 여정 아이콘" /> 커뮤니티 클럽
								</h4>
							}
							sub={
								<div
									onClick={() => (currentfilter === "계획" ? (window.location.href = "/community_planning") : (window.location.href = "/community_recruit"))}
									className="button cursor-pointer">
									더보기
									<img src={moreIcon} alt="더보기" />
								</div>
							}
						/>
						<OMTTab
							tabs={[
								{
									value: "전체",
									node: <span>전체</span>,
								},
								{
									value: "모집",
									node: (
										<span>
											<img src={user3Icon} alt="user_three" /> 모집
										</span>
									),
								},
								{
									value: "계획",
									node: (
										<span>
											<img src={notebookIcon} alt="notebook" /> 계획
										</span>
									),
								},
							]}
							onChange={(tab) => {
								filterItem(tab);
							}}
						/>

						<div className="mt-4">
							<div className="w-100 grid-card m-0 p-0">
								{(filter ?? []).slice(0, 4).map((v, i) => {
									return (
										<MainCardCommunityClup
											data={{
												currentMember: v.participants?.length ?? 0,
												userImage: v.participants?.find((e) => e.id === v.userId)?.UserFiles[0]?.File.url,
												countViews: v.viewCount ?? 0,
												countComments: v.commentCount ?? 0,
												title: v.title,
												tripType: v.tripType,
												userNickname: v.participants?.find((e) => e.id === v.userId)?.nickname,
												description: v.description ?? "",
												endDate: v.endedAt,
												id: v.id,
												maxMember: v.numberOfRecruits,
												genderType: v.recruitGenderTypeText,
												age: v.ageGroup,
												nation: v.nation,
												startDate: v.startedAt,
												orderType: v.type,
												images: v.CommunityClupFile?.map((v) => v.File.url) ?? [],
											}}
											key={i}
											onClick={() => {
												window.location.href = `/community/${v.id}/details`;
											}}
										/>
									);
								})}
							</div>
						</div>
					</div>

					<hr className="w-full border-1 border-[#999999]" />

					<div className="container my-4">
						<OMTSubTitle
							title={
								<h4 className="flex gap-[5px]">
									<img src={confettiIcon} alt="Coming Soon 아이콘" /> Coming Soon
								</h4>
							}
							sub={<div className="button flex w-full justify-center"></div>}
						/>
						<div className="flex w-full justify-center">
							<SectionCommingSoon />
						</div>
					</div>
				</div>
			</div>
			<MainPopUp />
		</>
	);
}
