interface Props {
	value: string;
	onChange: (value: string) => void;
	className?: string;
	disabled?: boolean;
	placeholder?: string;
}

export default function InputText(props: Props) {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(event.target.value);
	};

	return (
		<div className={["flex gap-3", props.className].join(" ")}>
			<input
				className="h-[30px] bg-transparent  w-full rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111] disabled:bg-[#ECECEC] disabled:text-[#777] "
				type="text"
				value={props.value}
				placeholder={props.placeholder}
				onChange={handleChange}
				disabled={props.disabled ?? false}
			/>
		</div>
	);
}
