const IconRecruitment = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.5 5.48438C5.60254 5.48438 4.875 6.21191 4.875 7.10938C4.875 8.00684 5.60254 8.73438 6.5 8.73438C7.39746 8.73438 8.125 8.00684 8.125 7.10938C8.125 6.21191 7.39746 5.48438 6.5 5.48438ZM4.0625 7.10938C4.0625 5.76318 5.15381 4.67188 6.5 4.67188C7.84619 4.67188 8.9375 5.76318 8.9375 7.10938C8.9375 8.45557 7.84619 9.54688 6.5 9.54688C5.15381 9.54688 4.0625 8.45557 4.0625 7.10938Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.1346 6.57458C10.768 6.39129 10.3637 6.29619 9.9538 6.29688C9.72944 6.29725 9.54725 6.11567 9.54688 5.89131C9.5465 5.66694 9.72808 5.48476 9.95245 5.48438C10.4889 5.48348 11.0181 5.60795 11.4979 5.84786C11.9778 6.08777 12.3949 6.43648 12.716 6.86617C12.8504 7.04589 12.8135 7.30047 12.6338 7.43479C12.4541 7.56911 12.1995 7.5323 12.0652 7.35259C11.8199 7.02429 11.5012 6.75787 11.1346 6.57458Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.04622 6.29688C2.63637 6.29619 2.23202 6.39129 1.86544 6.57458C1.49885 6.75787 1.18017 7.02429 0.934807 7.35259C0.800488 7.5323 0.545911 7.56911 0.366193 7.43479C0.186476 7.30047 0.149673 7.04589 0.283992 6.86617C0.605144 6.43648 1.02226 6.08777 1.50208 5.84786C1.98189 5.60795 2.51113 5.48348 3.04758 5.48438C3.27194 5.48476 3.45352 5.66694 3.45315 5.89131C3.45277 6.11567 3.27059 6.29725 3.04622 6.29688Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.50029 9.54819C5.96667 9.54819 5.44378 9.69806 4.99118 9.98073C4.53859 10.2634 4.17448 10.6675 3.94034 11.147C3.8419 11.3486 3.59865 11.4323 3.39703 11.3338C3.19542 11.2354 3.11179 10.9921 3.21023 10.7905C3.51115 10.1742 3.9791 9.65489 4.56078 9.2916C5.14246 8.9283 5.81448 8.73569 6.50029 8.73569C7.18609 8.73569 7.85811 8.9283 8.43979 9.2916C9.02147 9.65489 9.48943 10.1742 9.79034 10.7905C9.88879 10.9921 9.80515 11.2354 9.60354 11.3338C9.40193 11.4323 9.15868 11.3486 9.06023 11.147C8.82609 10.6675 8.46199 10.2634 8.00939 9.98073C7.55679 9.69806 7.0339 9.54819 6.50029 9.54819Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.34761 3.08506C3.12354 3.02761 2.88774 3.03512 2.66778 3.1067C2.44782 3.17828 2.25277 3.31098 2.10542 3.4893C1.95807 3.66762 1.86452 3.88418 1.83568 4.1137C1.80684 4.34321 1.84392 4.57619 1.94258 4.78542C2.04123 4.99464 2.19739 5.17147 2.39281 5.29524C2.58823 5.41901 2.81484 5.48461 3.04616 5.48437C3.27052 5.48415 3.45259 5.66585 3.45282 5.89021C3.45305 6.11458 3.27135 6.29665 3.04698 6.29687C2.66145 6.29727 2.28377 6.18793 1.95807 5.98165C1.63237 5.77536 1.3721 5.48065 1.20768 5.13194C1.04325 4.78324 0.981458 4.39493 1.02952 4.01241C1.07758 3.62989 1.23351 3.26894 1.47908 2.97175C1.72466 2.67455 2.04974 2.45338 2.41635 2.33408C2.78296 2.21478 3.17595 2.20227 3.5494 2.29802C3.92285 2.39377 4.26134 2.59382 4.52532 2.87479C4.7893 3.15577 4.96786 3.50607 5.04015 3.88476C5.08222 4.10515 4.93766 4.31791 4.71728 4.35998C4.49689 4.40205 4.28413 4.2575 4.24206 4.03711C4.19869 3.80989 4.09155 3.59971 3.93316 3.43113C3.77477 3.26254 3.57168 3.14251 3.34761 3.08506Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.3318 3.1067C10.1118 3.03512 9.87604 3.02761 9.65197 3.08506C9.4279 3.14251 9.2248 3.26254 9.06642 3.43113C8.90803 3.59971 8.80089 3.80989 8.75752 4.03711C8.71545 4.2575 8.50269 4.40205 8.2823 4.35998C8.06191 4.31791 7.91736 4.10515 7.95943 3.88476C8.03171 3.50607 8.21028 3.15577 8.47426 2.87479C8.73824 2.59382 9.07673 2.39377 9.45018 2.29802C9.82363 2.20227 10.2166 2.21478 10.5832 2.33408C10.9498 2.45338 11.2749 2.67455 11.5205 2.97175C11.7661 3.26894 11.922 3.62989 11.9701 4.01241C12.0181 4.39493 11.9563 4.78324 11.7919 5.13194C11.6275 5.48065 11.3672 5.77536 11.0415 5.98165C10.7158 6.18793 10.3381 6.29727 9.95259 6.29687C9.72823 6.29665 9.54653 6.11458 9.54675 5.89021C9.54698 5.66585 9.72905 5.48415 9.95342 5.48437C10.1847 5.48461 10.4113 5.41901 10.6068 5.29524C10.8022 5.17147 10.9583 4.99464 11.057 4.78542C11.1557 4.57619 11.1927 4.34321 11.1639 4.1137C11.1351 3.88418 11.0415 3.66762 10.8942 3.4893C10.7468 3.31098 10.5518 3.17828 10.3318 3.1067Z"
			fill="#F5F5F5"
		/>
	</svg>
);

const IconPlanned = (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.28125 5.6875C5.28125 5.46313 5.46313 5.28125 5.6875 5.28125H8.9375C9.16187 5.28125 9.34375 5.46313 9.34375 5.6875C9.34375 5.91187 9.16187 6.09375 8.9375 6.09375H5.6875C5.46313 6.09375 5.28125 5.91187 5.28125 5.6875Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.28125 7.3125C5.28125 7.08813 5.46313 6.90625 5.6875 6.90625H8.9375C9.16187 6.90625 9.34375 7.08813 9.34375 7.3125C9.34375 7.53687 9.16187 7.71875 8.9375 7.71875H5.6875C5.46313 7.71875 5.28125 7.53687 5.28125 7.3125Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.625 2.4375C1.625 1.98877 1.98877 1.625 2.4375 1.625H10.5625C11.0112 1.625 11.375 1.98877 11.375 2.4375V10.5625C11.375 11.0112 11.0112 11.375 10.5625 11.375H2.4375C1.98877 11.375 1.625 11.0112 1.625 10.5625V2.4375ZM10.5625 2.4375H2.4375V10.5625H10.5625V2.4375Z"
			fill="#F5F5F5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.0625 1.625C4.28687 1.625 4.46875 1.80688 4.46875 2.03125V10.9688C4.46875 11.1931 4.28687 11.375 4.0625 11.375C3.83813 11.375 3.65625 11.1931 3.65625 10.9688V2.03125C3.65625 1.80688 3.83813 1.625 4.0625 1.625Z"
			fill="#F5F5F5"
		/>
	</svg>
);

export default function TypeLabel({ type }: any) {
	return (
		<div
			className={[
				"flex justify-center px-1.5 py-1 rounded-sm gap-1 text-white text-[10px] font-medium items-center",
				type === "RECRUITMENT" ? "bg-[#f67280]" : "bg-[#a870b1]",
			].join(" ")}>
			{type === "RECRUITMENT" ? IconRecruitment : IconPlanned}
			{type === "RECRUITMENT" ? "모집" : "계획"}
		</div>
	);
}
