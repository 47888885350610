import { useEffect } from "react";

const InicisAuthPopup: React.FC = () => {
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		const phone = searchParams.get("userPhone");
		const userName = searchParams.get("userName");
		const birthday = searchParams.get("userBirthday");

		if (window.opener && typeof window.opener.receiveDataFromPopup === "function") {
			window.opener.receiveDataFromPopup({
				phone,
				userName,
				birthday,
			});
		}
	}, []);

	return <></>;
};

export default InicisAuthPopup;
