export default function IconLock() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5 8.33317V6.6665C5 3.90817 5.83333 1.6665 10 1.6665C14.1667 1.6665 15 3.90817 15 6.6665V8.33317"
        stroke="#0E1111"
        strokeWidth="1.2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z"
        stroke="#0E1111"
        strokeWidth="1.2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.166 18.3335H5.83268C2.49935 18.3335 1.66602 17.5002 1.66602 14.1668V12.5002C1.66602 9.16683 2.49935 8.3335 5.83268 8.3335H14.166C17.4993 8.3335 18.3327 9.16683 18.3327 12.5002V14.1668C18.3327 17.5002 17.4993 18.3335 14.166 18.3335Z"
        stroke="#0E1111"
        strokeWidth="1.2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
