import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { USER } from "../../../admin_pages/api";
import EasyTable from "../../../components/EasyTable";
import Modal from "../../../components/modal";
import FormFactory from "../../../components/FormFactory";
import { DEFAULT } from "../../../api";

export default function AdminUsersIdPage() {
	const { user_id } = useParams();

	const [user, setUser] = useState<any>(null);
	const [modal, setModal] = useState(""); // 'add', 'edit', 'delete'
	const [serviceConfig, setServiceConfig] = useState<any>(null);

	useEffect(() => {
		DEFAULT.getServiceConfig().then(setServiceConfig);
	}, []);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		if (user_id) {
			USER.findOne(+user_id).then((v: any) => {
				setUser(v);
			});
		}
	};

	const handleUpdate = async (data: any) => {
		await USER.update(
			user.id,
			data.nickname,
			data.name,
			data.birthday,
			data.gender.value,
			data.phone,
			data.tripInfo.map((v: any) => v.value),
			data.info.map((v: any) => v.value),
			data.mbti,
			new Date(),
			new Date(),
			data.marketingAgreement?.value ? new Date() : undefined
		);
		setModal("");
		getData();
	};

	const handleDelete = async (id: number) => {
		await USER.delete(id);
		window.history.back();
	};

	return (
		<div>
			<h1 className="text-xl">사용자 {user_id}</h1>
			<div className="font-bold mt-4">정보</div>
			<div className="flex flex-col max-w-3xl font-medium">
				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">id</div>
					<div>{user?.id}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">로그인 유형</div>
					<div>{user?.oauthType}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">oauth id</div>
					<div>{user?.oauthId}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">이메일</div>
					<div>{user?.email}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">닉네임</div>
					<div>{user?.nickname}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">이름</div>
					<div>{user?.name}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">생녕월일</div>
					<div>{user?.birthday}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">성별</div>
					<div>{user?.gender}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">전화번호</div>
					<div>{user?.phone}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">여행 성향</div>
					<div>{user?.tripInfo?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">기호</div>
					<div>{user?.info?.join(", ")}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">MBTI</div>
					<div>{user?.mbti}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">마케팅 동의 날짜</div>
					<div>{user?.marketingAgreement}</div>
				</div>

				<div className="flex border border-gray-200">
					<div className="w-36 flex-none bg-gray-200">삭제 여부</div>
					<div>{user?.deletedAt ? "Y" : "N"}</div>
				</div>
			</div>

			<div className="flex w-full max-w-3xl justify-end gap-4">
				<button
					onClick={() => {
						handleDelete(user.id);
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					삭제
				</button>
				<button
					onClick={() => {
						setModal("update");
					}}
					className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">
					수정
				</button>
			</div>

			<div className="font-bold mt-4">파일 목록</div>
			<div className="mt-4 max-w-3xl overflow-x-scroll">
				{user?.UserFiles ? (
					<EasyTable
						columns={[
							{ header: "id", key: "id" },
							{ header: "type", key: "type" },
							{ header: "url", key: "File", render: (File) => <button onClick={() => window.open(File?.url, "__blank")}>{File?.url}</button> },
							{ header: "name", key: "File", render: (File) => <div>{File?.name}</div> },
							{ header: "size", key: "File", render: (File) => <div>{File?.size}</div> },
						]}
						data={user?.UserFiles}
					/>
				) : (
					"파일이 없습니다."
				)}
			</div>

			<div className="font-bold mt-4">커뮤니티 참가 목록</div>
			<div className="mt-4 max-w-3xl overflow-x-scroll">
				{user?.CommunityClupParticipant ? (
					<EasyTable
						columns={[
							{ header: "id", key: "id" },
							{
								header: "커뮤니티 클럽 id",
								key: "communityClupId",
								render: (communityClupId) => (
									<button className="text-blue-500" onClick={() => (window.location.href = `/admin/dashboard/community-clups/${communityClupId}`)}>
										{communityClupId}
									</button>
								),
							},
							{ header: "승인 여부", key: "confirmedAt", render: (confirmedAt) => <div>{confirmedAt ? "Y" : "N"}</div> },
							{ header: "거절 여부", key: "rejectedAt", render: (rejectedAt) => <div>{rejectedAt ? "Y" : "N"}</div> },
							{ header: "술", key: "alcoholPreference" },
							{ header: "담배", key: "smokingPreference" },
							{ header: "여행 스타일", key: "travelStyle", render: (travelStyle) => <div>{Array.isArray(travelStyle) ? travelStyle?.join(", ") : "-"}</div> },
							{ header: "MBTI", key: "mbti" },
						]}
						data={user?.CommunityClupParticipant}
					/>
				) : (
					"커뮤니티 참가 목록이 없습니다."
				)}
			</div>

			<Modal open={modal === "update"} onClose={() => setModal("")} size="lg">
				<div className="mb-6">유저 수정</div>

				<FormFactory
					schema={[
						{ label: "닉네임", key: "nickname", method: "text", validation: "required", defaultValue: user?.nickname },
						{ label: "이름", key: "name", method: "text", validation: "required", defaultValue: user?.name },
						// { label: "비밀번호", key: "password", method: "text", validation: "required", defaultValue: user?.password },
						{
							label: "생년월일",
							key: "birthday",
							method: "text",
							placeholder: "1990-01-01",
							validation: (v) => /\d\d\d\d-\d\d-\d\d/.test(v),
							defaultValue: user?.birthday,
						},
						{
							label: "성별",
							key: "gender",
							method: "select",
							selectOptions: [
								{ label: "남자", value: "Male" },
								{ label: "여자", value: "Female" },
							],
							defaultValue: { label: user?.gender, value: user?.gender },
						},
						{
							label: "전화번호",
							key: "phone",
							method: "text",
							validation: (v) => /\d\d\d-\d\d\d\d-\d\d\d\d/.test(v),
							placeholder: "010-1234-5678",
							defaultValue: user?.phone,
						},
						{
							label: "여행 유형",
							key: "tripInfo",
							method: "select-multi",
							validation: "required",
							selectOptions: [
								{ label: "휴양형", value: "휴양형" },
								{ label: "액티비티형", value: "액티비티형" },
								{ label: "도시형", value: "도시형" },
								{ label: "로컬형", value: "로컬형" },
								{ label: "랜드마크형", value: "랜드마크형" },
								{ label: "모험형", value: "모험형" },
							],
							defaultValue: user?.tripInfo?.map((v: string) => ({ label: v, value: v })),
						},
						{
							label: "기호",
							key: "info",
							method: "select-multi",
							validation: "required",
							selectOptions: [
								{ label: "가벼운 음주 좋아요", value: "가벼운 음주 좋아요" },
								{ label: "흡연 괜찮아요", value: "흡연 괜찮아요" },
								{ label: "둘 다 상관없어요", value: "둘 다 상관없어요" },
								{ label: "둘 다 싫어요", value: "둘 다 싫어요" },
							],
							defaultValue: user?.info?.map((v: string) => ({ label: v, value: v })),
						},
						{
							label: "MBTI",
							key: "mbti",
							method: "text",
							validation: "required",
							defaultValue: user?.mbti,
						},
						{
							label: "마케팅 동의",
							key: "marketingAgreement",
							method: "select",
							selectOptions: [
								{ label: "동의", value: true },
								{ label: "비동의", value: false },
							],
							defaultValue: user?.marketingAgreement ? { label: "동의", value: true } : { label: "비동의", value: false },
						},
					]}
					onSubmit={handleUpdate}
				/>
			</Modal>
		</div>
	);
}
