import { useEffect, useState } from "react";
import { NOTIFICATION } from "../../admin_pages/api";
import EasyTable from "../../components/EasyTable";
import EasyPagination from "../../components/EasyPagination";

export default function AdminNotificationsPage() {
	const [page, setPage] = useState({
		page: 1,
		perPage: 50,
	});

	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	useEffect(() => {
		getData();
	}, [page]);

	const getData = async () => {
		const response = await NOTIFICATION.pagination(page.page, page.perPage);
		setData(response.nodes);
		setCount(response.total);
	};

	return (
		<div>
			<h1>사용자 알림 조회</h1>
			{/* <div className="flex justify-end">
				<button className="border border-gray-600 bg-gray-200 px-3 py-1 rounded-sm font-normal text-sm">추가</button>
			</div> */}
			<div>
				<div className="mt-4 w-full overflow-x-scroll">
					<EasyTable
						data={data}
						columns={[
							{ header: "ID", key: "id" },
							{
								header: "대상",
								key: "userId",
								render: (_, row) => (
									<div>
										{row.userId ? (
											<a className="text-blue-500" href={`/admin/dashboard/users/${row.userId}`}>
												사용자({row.userId})
											</a>
										) : (
											<div>파트너</div>
										)}
									</div>
								),
							},
							{ header: "알림 타입", key: "type" },
							{ header: "알림 타입", key: "referenceType" },
							{ header: "제목", key: "title" },
							{ header: "내용", key: "content" },
							// { header: "링크", key: "link" },
							{ header: "읽음", key: "isRead", render: (isRead) => <div>{isRead ? "읽음" : "읽지 않음"}</div> },
							{ header: "발송날짜", key: "createdAt" },
						]}
					/>
				</div>
				<div className="mt-4">
					<EasyPagination
						{...page}
						count={count}
						onChange={(_page) =>
							setPage({
								...page,
								page: _page,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
}
