import React from "react";

type PaginationProps = {
	page: number;
	perPage: number;
	count: number;
	onChange: (page: number) => void;
};

const EasyPagination: React.FC<PaginationProps> = ({ page, perPage, count, onChange }) => {
	const totalPages = Math.ceil(count / perPage);
	const firstPage = Math.max(1, page - 2);
	const lastPage = Math.min(totalPages, page + 2);

	const changePage = (newPage: number) => {
		if (newPage >= 1 && newPage <= totalPages) {
			onChange(newPage);
		}
	};

	const pages = [];
	for (let i = firstPage; i <= lastPage; i++) {
		pages.push(i);
	}

	return (
		<div className="flex justify-center">
			<button
				onClick={() => changePage(page - 1)}
				disabled={page === 1}
				className={`px-3 py-1 font-normal text-sm mx-1 ${page === 1 ? "text-gray-500" : "text-gray-700 hover:bg-gray-300"}`}>
				Previous
			</button>
			{pages.map((pageNum) => (
				<button
					key={pageNum}
					onClick={() => changePage(pageNum)}
					className={`px-3 py-1 font-normal text-sm mx-1 ${page === pageNum ? "text-blue-600" : "text-gray-700 hover:bg-gray-300"}`}>
					{pageNum}
				</button>
			))}
			<button
				onClick={() => changePage(page + 1)}
				disabled={page === totalPages}
				className={`px-3 py-1 font-normal text-sm mx-1 ${page === totalPages ? "text-gray-500" : "text-gray-700 hover:bg-gray-300"}`}>
				Next
			</button>
		</div>
	);
};

export default EasyPagination;
