import { useEffect, useMemo, useState } from "react";
import { ORDER } from "../../api";
import { format, parseISO } from "date-fns";

export default function PaymentCompletePage() {
	const searchParams = new URLSearchParams(window.location.search);

	const [order, setOrder] = useState<any>(null);
	const [complate, setComplate] = useState<any>(false);

	useEffect(() => {
		if (!searchParams.get("orderId")) {
			alert("잘못된 접근입니다.");
			window.location.href = "/";
		} else {
			ORDER.getOne(searchParams.get("orderId")).then((data: any) => {
				setOrder(data);
				const pg_token = searchParams.get("pg_token");
				if (!pg_token) {
					setComplate(false);
					alert("결제에 실패하였습니다. 다시 시도해주세요.");
					window.location.href = "/mypage/order/payment";
				}
				setComplate(true);
			});
		}
	}, []);

	const type = useMemo(() => {
		if (order) {
			if (order.type === "RECRUITMENT") {
				return "모집주문";
			}

			if (order.type === "ALONE") {
				return "단독주문";
			}

			if (order.type === "BUSINESS_TRIP") {
				return "출장주문";
			}
		}
		return "";
	}, [order]);

	const rangeString = useMemo(() => {
		if (order) {
			const start = parseISO(order?.startedAt);
			const end = parseISO(order?.endedAt);

			return `${format(start, "yyyy.MM.dd")} ~ ${format(end, "yyyy.MM.dd")}`;
		}
		return "";
	}, [order]);

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-lg">
				<div className="px-3 mt-5 text-success text-sm font-medium flex items-center">
					{Icon}
					주문서 취소요청/확정
				</div>
				<div className="px-3 mt-6">
					<div className="border-b border-[#dadada]"></div>
				</div>

				{!complate && (
					<>
						<div className="text-black text-xl font-bold mt-5 text-center">결제중 입니다.</div>
						<div className="text-black text-sm font-medium mt-5 text-center">잠시만 기다려주세요.</div>
					</>
				)}

				{complate && (
					<>
						<div className="text-black text-xl font-bold mt-5 text-center">결제가 완료되었습니다.</div>
						<div className="text-black text-sm font-medium mt-5 text-center">일정표를 제작하거나, 요청하실 수 있습니다</div>
						<div className="text-black text-xs font-medium mt-5 text-center">
							일정표 제작하기 : 나만의 일정표 만들기
							<br />
							일정표 요청하기 : 오더메이드트립에 일정표 요청하기
						</div>

						<div className="px-3">
							<div className="border-[#dadada] border rounded-sm mt-5 flex p-2.5 gap-3">
								<div className="relative w-[171px] h-[128px] flex-none bg-gray-200">
									<div className="z-10 absolute left-1 top-1 text-white text-[10px] px-2 py-1 font-medium bg-[#355c7d]">그리스/7박8일</div>
								</div>
								<div className="text-[13px] font-medium flex flex-col justify-center gap-2">
									<div>여행형태: {type}</div>
									<div>국가: {order?.nation}</div>
									<div>도시: {order?.city}</div>
									<div>
										{order.numberOfRecruits}명{`${order.type !== "RECRUITMENT" ? "" : "·" + order.recruitGenderTypeText}`}
										{`${order.type !== "RECRUITMENT" ? "" : "·" + order.ageGroup}`}
									</div>
									<div>{rangeString}</div>
								</div>
							</div>
						</div>

						<div className="px-3 mt-5">
							<div
								onClick={() => {
									window.location.href = `/order/schedule/${order?.id}`;
								}}
								className="border border-[#dadada] py-2.5 flex justify-center items-center text-success font-bold">
								일정표 제작하기
							</div>
							<div
								onClick={async () => {
									await ORDER.requestSchedule(order?.id);
									alert("일정표 요청이 완료되었습니다.");
									window.location.href = "/";
								}}
								className="mt-2.5 border border-[#dadada] py-2.5 flex justify-center items-center text-success font-bold">
								일정표 요청하기
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

const Icon = (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M21.5984 16.56V17.31C22.0126 17.31 22.3484 16.9742 22.3484 16.56H21.5984ZM21.5984 11.28H22.3484C22.3484 10.8658 22.0126 10.53 21.5984 10.53V11.28ZM7.27287 6.32912C6.92444 6.55311 6.82356 7.01714 7.04755 7.36557C7.27154 7.714 7.73558 7.81487 8.084 7.59088L7.27287 6.32912ZM14.3984 2.64L14.9904 2.17954C14.7521 1.87314 14.3194 1.79921 13.9929 2.00912L14.3984 2.64ZM17.1664 7.42046C17.4207 7.74742 17.8919 7.80632 18.2189 7.55201C18.5459 7.29771 18.6048 6.82651 18.3504 6.49954L17.1664 7.42046ZM21.5984 15.81H18.4784V17.31H21.5984V15.81ZM18.4784 12.03H21.5984V10.53H18.4784V12.03ZM20.8484 11.28V16.56H22.3484V11.28H20.8484ZM16.5884 13.92C16.5884 12.8762 17.4346 12.03 18.4784 12.03V10.53C16.6062 10.53 15.0884 12.0478 15.0884 13.92H16.5884ZM18.4784 15.81C17.4346 15.81 16.5884 14.9638 16.5884 13.92H15.0884C15.0884 15.7922 16.6062 17.31 18.4784 17.31V15.81ZM8.084 7.59088L14.804 3.27088L13.9929 2.00912L7.27287 6.32912L8.084 7.59088ZM13.8064 3.10045L17.1664 7.42046L18.3504 6.49954L14.9904 2.17954L13.8064 3.10045ZM3.35844 7.71H19.6784V6.21H3.35844V7.71ZM19.6784 20.61H3.35844V22.11H19.6784V20.61ZM3.14844 20.4V7.92H1.64844V20.4H3.14844ZM3.35844 20.61C3.24246 20.61 3.14844 20.516 3.14844 20.4H1.64844C1.64844 21.3444 2.41403 22.11 3.35844 22.11V20.61ZM19.8884 20.4C19.8884 20.516 19.7944 20.61 19.6784 20.61V22.11C20.6228 22.11 21.3884 21.3444 21.3884 20.4H19.8884ZM19.6784 7.71C19.7944 7.71 19.8884 7.80402 19.8884 7.92H21.3884C21.3884 6.97559 20.6228 6.21 19.6784 6.21V7.71ZM3.35844 6.21C2.41403 6.21 1.64844 6.97559 1.64844 7.92H3.14844C3.14844 7.80402 3.24246 7.71 3.35844 7.71V6.21ZM19.8884 17.28V20.4H21.3884V17.28H19.8884ZM19.8884 7.92V10.44H21.3884V7.92H19.8884Z"
			fill="#0057FF"
		/>
	</svg>
);
