import { useEffect, useState } from "react";
import { ORDER, PARTNER_QUOTATION, PAYMENT, USER } from "../api";
import { formatGender } from "../components/CommonFunction";

const IconBook = (
	<svg className="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M8.57133 2.40039V21.6004M17.4856 10.629H12.6856M17.4856 6.51468H12.6856M5.14276 6.51468H2.3999M5.14276 10.629H2.3999M5.14276 14.7432H2.3999M6.51419 21.6004H18.857C20.3719 21.6004 21.5999 20.3724 21.5999 18.8575V5.14325C21.5999 3.62841 20.3719 2.40039 18.857 2.40039H6.51419C4.99935 2.40039 3.77133 3.62841 3.77133 5.14325V18.8575C3.77133 20.3724 4.99935 21.6004 6.51419 21.6004Z"
			stroke="#0057FF"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
);

export default function MypageMyOrder() {
	const [order, setOrder] = useState([]);

	useEffect(() => {
		PARTNER_QUOTATION.getComfirmOrders().then((data: any) => {
			setOrder(
				data.filter((e: any) => {
					if (e.Order.status === "PAYMENT_COMPLETE") {
						return new Date(e.Order.endedAt).getTime() > new Date().getTime();
					} else {
						return new Date(e.Order.startedAt).getTime() > new Date().getTime() - 1000 * 60 * 60 * 24;
					}
				})
			);
		});
	}, []);

	function calculateDuration(startedAt: string, endedAt: string) {
		const startDate = new Date(startedAt);
		const endDate = new Date(endedAt);

		// 두 날짜의 차이를 밀리초로 계산
		const differenceInTime = endDate.getTime() - startDate.getTime();

		// 밀리초를 일 수로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
		const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

		// 박 수는 일 수에서 1을 뺀 값
		return `${differenceInDays}박${differenceInDays + 1}일`;
	}

	const cencelPaymant = (payment_id: any) => {
		if (!payment_id) return alert("다시 확인 해주세요");
		PAYMENT.requestRefund(payment_id).then(() => {
			alert("결제 취소 요청이 완료되었습니다.");
			window.location.reload();
		});
	};

	const OrderCard = ({ node, key, quotations }: any) => {
		return (
			<>
				<div className="border border-[#dadada] p-[10px] mb-[20px] flex rounded-[2px] gap-3">
					<div className="flex-1 ">
						<div
							className="w-full h-[194px] bg-gray-200 text-xs flex justify-center items-center bg-center bg-cover rounded-[2px] relative text-[#999] cursor-pointer"
							onClick={() => {
								window.location.href = `/mypage/myorder/${node.id}`;
							}}
							style={{
								backgroundImage: `url(${quotations?.PartnerQuotationFiles?.find((e: any) => e.File)?.File?.url})`,
							}}>
							<div className="font-spoqa text-[10px] text-[#f5f5f5] max-w-[90%] truncate font-medium bg-[#355c7d] py-[4px] px-[9px] absolute top-[5px] left-[5px]">
								{node?.nation}·{node?.city} / {calculateDuration(node?.startedAt, node?.endedAt)}
							</div>
						</div>
					</div>
					<div className="flex-1 mt-1 flex flex-col">
						<div className="text-[#0057FF] font-spoqa text-[10px] tracking-[-0.32px] font-medium">{quotations?.Partner?.corporateBusinessName}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[8px] ">
							여행 형태:
							{node?.type === "RECRUITMENT" && " 모집 주문"}
							{node?.type === "ALONE" && " 단독 주문"}
							{node?.type === "BUSINESS_TRIP" && " 출장 주문"}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">국가:{node?.nation}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">도시:{node?.city}</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px] ">
							{node.numberOfRecruits}명{`${node.type !== "RECRUITMENT" ? "" : "·" + formatGender(node.recruitGenderType)}`}
							{`${node.type !== "RECRUITMENT" ? "" : "·" + node.ageGroup}`}
						</div>
						<div className="text-[#0e1111] text-[12px] font-medium tracking-[-0.32px] mt-[5px]">
							{new Date(node?.startedAt).toLocaleDateString().slice(0, -1)} ~ {new Date(node?.endedAt).toLocaleDateString().slice(0, -1)}
						</div>
						<div className="flex-1 flex flex-col justify-end gap-1">
							{(node.status === "PAYMENT_COMPLETE" || node.status === "CONFIRM") && (
								<button className="h-[30px] flex justify-center items-center text-[14px] font-medium bg-[#0057FF] text-white">
									{node.status === "PAYMENT_COMPLETE" ? "결제완료" : quotations.finalQutotePrice.toLocaleString("KR-ko") + "원"}
								</button>
							)}

							{(node.status === "PAYMENT_WAITING" || node.status === "PAYMENT_PARTIAL_COMPLETE") && (
								<button
									className="h-[30px]  flex justify-center items-center text-[14px] font-medium border border-[#DADADA] text-[#777777] cursor-pointer"
									onClick={() => {
										cencelPaymant(node.Payment?.find((e: any) => e.status === "COMPLETED")?.id);
									}}>
									결제 승인 대기중
								</button>
							)}

							{node.status === "PAYMENT_COMPLETE" && (
								<button
									className="h-[30px]  flex justify-center items-center text-[14px] font-medium border border-[#DADADA] text-[#777777] cursor-pointer"
									onClick={() => {
										cencelPaymant(node.Payment?.find((e: any) => e.status === "COMPLETED")?.id);
									}}>
									결제 취소
								</button>
							)}

							{node.status === "REFUND_WAITING" && node.Payment.find((e: any) => e.status === "REFUND_REQUESTED") && (
								<button
									className="h-[30px]  flex justify-center items-center text-[14px] font-medium border border-[#DADADA] text-[#777777] cursor-pointer"
									onClick={() => {}}>
									결제 취소 대기중
								</button>
							)}

							{node.status === "REFUND_WAITING" && node.Payment.find((e: any) => e.status === "REFUNDED") && (
								<button
									className="h-[30px]  flex justify-center items-center text-[14px] font-medium border border-[#DADADA] text-[#777777] cursor-pointer"
									onClick={() => {}}>
									환불 완료
								</button>
							)}

							{node.status === "REFUND_WAITING" && node.Payment.find((e: any) => e.status === "REFUND_REJECTED") && (
								<button
									className="h-[30px]  flex justify-center items-center text-[14px] font-medium border border-[#DADADA] text-[#777777] cursor-pointer"
									onClick={() => {}}>
									환불 거절
								</button>
							)}

							{(node.status === "REFUND_COMPLETE" || node.status === "REFUND_PARTIAL_COMPLETE") && (
								<button
									className="h-[30px]  flex justify-center items-center text-[14px] font-medium border border-[#DADADA] text-[#777777] cursor-pointer"
									onClick={() => {}}>
									결제취소 완료
								</button>
							)}
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="w-full flex justify-center">
			<div className="flex justify-center max-w-[512px] w-full">
				<div className=" w-full px-3 pt-5 ">
					<div className=" border-[#dadada] pb-[23px] ">
						<div className="flex text-[14px] text-[#0057FF] font-medium gap-2 items-center">
							{IconBook}
							주문서 취소요청/확정
						</div>
					</div>

					<hr className="mb-[20px] " />

					{order?.length ? (
						order.map((node: any, i: any) => {
							return (
								<div key={i}>
									<>
										<OrderCard node={node.Order} quotations={node} />
										{node.Order.status === "PAYMENT_COMPLETE" && (
											<div
												className="w-full border border-[#DADADA] my-[20px] flex justify-center items-center h-[40px] text-[#0057FF] cursor-pointer"
												onClick={() => {
													window.location.href = `/order/schedule/${node.Order.id}`;
												}}>
												일정표 보러가기
											</div>
										)}
										{node.Order.status === "CONFIRM" && (
											<div
												className="w-full border border-[#DADADA] my-[20px] flex justify-center items-center h-[40px] text-[#0057FF] cursor-pointer"
												onClick={() => {
													window.location.href = `/mypage/myorder/${node.Order.id}?quotations=${node.id}`;
												}}>
												결제하기
											</div>
										)}
										<hr className="mb-[20px] " />
									</>
								</div>
							);
						})
					) : (
						<>
							<div className="h-[300px] flex mt-[120px] justify-center items-center flex-col">
								<div>아직 주문한 내역이 없어요😢</div>
								<div> ✈️지금 여행을 주문 하시겠어요?</div>
								<div
									className="px-[10px] mt-[120px] w-full h-10 flex justify-center items-center text-[#0057FF] border rounded-[2px] cursor-pointer"
									onClick={() => {
										window.location.href = "/travel_Order";
									}}>
									여행 주문하기
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
