import { useState } from "react";

export default function InputTextarea({ className, text, onChange }: any) {
	
	const handleChange = (event:any) => {
		onChange(event.target.value);
	};

	const [agree, setAgree] = useState(false);

	return (
		<div className="mt-[10px]  xl:mt-[16px]">
			<div className="px-3 mt-[5px]">
				<textarea
					onChange={handleChange}
					className="w-full font-normal border-[#dadada] border rounded-[2px] font-spoqa h-[128px] p-[8px] text-[11px] text-[#425449]"
					cols={5}
					value={text}
				/>
			</div>
		</div>
	);
}
