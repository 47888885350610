import { useState } from "react";

interface Props {
	value: string;
	confirmText: string;
	unConfirmText: string;
	showConfirmText?: boolean;
	placeholder?: string;
	onChange: (value: string) => void;
	className?: string;
}

const IconSuccess = (
	<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
		<g clip-path="url(#clip0_420_2288)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5ZM0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6Z"
				fill="#0057FF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_420_2288">
				<rect width="12" height="12" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

const IconFail = (
	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.9126 3.08751C11.1404 3.31532 11.1404 3.68466 10.9126 3.91247L3.91256 10.9125C3.68475 11.1403 3.31541 11.1403 3.0876 10.9125C2.8598 10.6847 2.8598 10.3153 3.0876 10.0875L10.0876 3.08751C10.3154 2.85971 10.6848 2.85971 10.9126 3.08751Z"
			fill="#FF0000"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.0876 3.08751C3.31541 2.85971 3.68475 2.85971 3.91256 3.08751L10.9126 10.0875C11.1404 10.3153 11.1404 10.6847 10.9126 10.9125C10.6848 11.1403 10.3154 11.1403 10.0876 10.9125L3.0876 3.91247C2.8598 3.68466 2.8598 3.31532 3.0876 3.08751Z"
			fill="#FF0000"
		/>
	</svg>
);

const IconEye = (
	<svg
		className="h-4 w-4 fill-current text-[#777]"
		clipRule="evenodd"
		fillRule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		stroke="2"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zm-8.411-4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z"
			fillRule="nonzero"
		/>
	</svg>
);
const IconEyeSlash = (
	<svg
		className="h-4 w-4 fill-current text-[#777]"
		clipRule="evenodd"
		fillRule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		stroke="2"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="m17.069 6.546 2.684-2.359c.143-.125.32-.187.497-.187.418 0 .75.34.75.75 0 .207-.086.414-.254.562l-16.5 14.501c-.142.126-.319.187-.496.187-.415 0-.75-.334-.75-.75 0-.207.086-.414.253-.562l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 1.815 0 3.536.593 5.071 1.546zm2.319 1.83c.966.943 1.803 2.014 2.474 3.117.092.156.138.332.138.507s-.046.351-.138.507c-2.068 3.403-5.721 6.493-9.864 6.493-1.297 0-2.553-.313-3.729-.849l1.247-1.096c.795.285 1.626.445 2.482.445 3.516 0 6.576-2.622 8.413-5.5-.595-.932-1.318-1.838-2.145-2.637zm-3.434 3.019c.03.197.046.399.046.605 0 2.208-1.792 4-4 4-.384 0-.756-.054-1.107-.156l1.58-1.389c.895-.171 1.621-.821 1.901-1.671zm-.058-3.818c-1.197-.67-2.512-1.077-3.898-1.077-3.465 0-6.533 2.632-8.404 5.5.853 1.308 1.955 2.567 3.231 3.549l1.728-1.519c-.351-.595-.553-1.289-.553-2.03 0-2.208 1.792-4 4-4 .925 0 1.777.315 2.455.843zm-2.6 2.285c-.378-.23-.822-.362-1.296-.362-1.38 0-2.5 1.12-2.5 2.5 0 .36.076.701.213 1.011z"
			fillRule="nonzero"
		/>
	</svg>
);

export default function InputPassword(props: Props) {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(event.target.value);
	};

	const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

	return (
		<div className="flex flex-col">
			<div className={["relative flex gap-3", props.className].join(" ")}>
				<input
					className="h-[30px]  w-full rounded-[2px] border border-[#DADADA] px-[7px] py-[6px] font-spoqa text-[14px] font-medium tracking-[-0.32px] text-[#0e1111]"
					type={isShowPassword ? "text" : "password"}
					value={props.value}
					placeholder={props.placeholder}
					onChange={handleChange}
				/>
				<div className="absolute right-2 top-1.5" onClick={() => setIsShowPassword(!isShowPassword)}>
					{isShowPassword ? IconEye : IconEyeSlash}
				</div>
			</div>
			{props.showConfirmText ? (
				<div className="mt-1 flex items-center gap-0.5 text-[10px] font-medium">
					{IconSuccess}
					{props.confirmText}
				</div>
			) : (
				<div className="mt-1 flex items-center gap-0.5 text-[10px] font-medium">
					{IconFail}
					{props.unConfirmText}
				</div>
			)}
		</div>
	);
}
