import "./OMTSubTitle.scss";
import { ReactNode } from "react";

interface Props {
	title: ReactNode | string;
	sub: ReactNode | string;
}

function OMTSubTitle({ title, sub }: Props) {
	return (
		<div className="omt-sub-title">
			<div className="omt-sub-title_title">{title}</div>
			<div className="omt-sub-title_sub">{sub}</div>
		</div>
	);
}

export default OMTSubTitle;
