import { useState } from "react";
import { AUTH } from "./api";
import { login } from "./auth";

export default function AdminIndexPage() {
	// 상태 관리를 위한 useState 훅 사용
	const [username, setUsername] = useState("admin@example.com");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");

	// 로그인 처리 함수
	const handleLogin = async (e: any) => {
		e.preventDefault();
		setMessage("로그인 중...");

		const response = await AUTH.login(username, password);
		try {
			setMessage("로그인 성공!");
			login(response.accessToken);
			window.location.href = "/admin/dashboard";
		} catch (error: any) {
			// 오류 처리
			setMessage("로그인 실패: " + error?.message);
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-bg">
			<div className="max-w-md w-full">
				<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleLogin}>
					<div className="mb-4">
						<label className="block text-black text-sm font-bold mb-2" htmlFor="username">
							사용자 이름
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-black"
							id="username"
							type="text"
							placeholder="사용자 이름"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>
					<div className="mb-6">
						<label className="block text-black text-sm font-bold mb-2" htmlFor="password">
							비밀번호
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-black mb-3"
							id="password"
							type="password"
							placeholder="**********"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="flex items-center justify-between">
						<button className="bg-black hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded" type="submit">
							로그인
						</button>
					</div>
					{message && <p className="text-center text-red-500 text-xs mt-4">{message}</p>}
				</form>
			</div>
		</div>
	);
}
