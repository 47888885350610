import "./cmu_howToUse.scss";
import mojip from "../image/mojip.png";
import plan from "../image/plan.png";

export default function CommunityHowToUse() {
	return (
		<div className="flex w-full justify-center">
			<div className="w-full max-w-lg">
				<div className="text-center font-medium mt-12">여행계획은 내가 짜뒀으니, 나랑 같이 가자!</div>
				<div className="text-xl text-center mt-2">"모집 커뮤니티"</div>

				<div className="px-3">
					<img src={mojip} />
				</div>

				<div className="flex justify-center">
					<div className="px-5 font-medium text-black text-sm flex flex-col gap-5">
						<div>✔ 여행 모집/참여에서 여행계획 보기!</div>
						<div>✔ 내가 주문한 상품 중 크루가 되고싶은 상품을 Click!</div>
						<div>✔ 인원, 성별, 연령, 성향 등 내가 원하는 크루 조건 입력!</div>
						<div>✔ 업로드 후 모집시작! 내가 바로 모두를 이끄는 팩리더!</div>
						<div>✔ 커뮤니티 참여를 요청한 크루는 팩리더의 승인이 필요</div>
						<div>✔ 새로운 크루와 함께 재밌는 여행을 떠나보자!</div>
					</div>
				</div>

				<div className="flex justify-center">
					<div
						onClick={() => (window.location.href = "/community_makeCommunity?tab=RECRUITMENT")}
						className="bg-success rounded-sm h-10 font-bold text-white max-w-[369px] px-3 w-full flex justify-center items-center mt-10 cursor-pointer">
						모집하러 가기
					</div>
				</div>

				<div className="text-center font-medium mt-12">모든건 우리의 계획대로! A to Z 모든걸 함께 하는</div>
				<div className="text-xl text-center mt-2">"계획 커뮤니티"</div>

				<div className="px-3">
					<img src={plan} />
				</div>

				<div className="flex justify-center">
					<div className="px-5 font-medium text-black text-sm flex flex-col gap-5">
						<div>✔ 여행 모집/참여에서 여행계획 만들기</div>
						<div>✔ 가고싶은 여행지와 일정을 작성 후 업로드하기</div>
						<div>✔ 이 일정에 관심이 있다면 그 누구든 참여 가능!</div>
						<div>✔ 크루와 함께 일정을 만들었다면 단독여행 주문하기</div>
						<div>✔ 다양한 견적 중 우리 크루에 맞는 견적을 체택해보자!</div>
						<div>✔ 새로운 크루와 함께 완벽한 여행을 떠나보자!</div>
					</div>
				</div>

				<div className="flex justify-center">
					<div
						onClick={() => (window.location.href = "/community_makeCommunity")}
						className="bg-success rounded-sm h-10 font-bold text-white max-w-[369px] px-3 w-full flex justify-center items-center mt-10 cursor-pointer">
						계획하러 가기
					</div>
				</div>
			</div>
		</div>
	);
}
