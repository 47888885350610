interface Props {
	list: {
		label: string;
		value: string;
	}[];
	value: string;
	onChange: (value: string) => void;
	className?: string;
}
export default function InputList({ list, value, onChange, className = "" }: Props) {
	const handleChange = (newValue: string) => {
		// 호출된 onChange 함수로 새 값을 전달합니다.
		onChange(newValue);
	};

	return (
		<div className={["flex gap-3", className].join(" ")}>
			{list.map((item) => {
				const isSelected = value === item.value;
				return (
					<div
						key={item.value}
						className={` justify-center transition-all flex rounded-[2px] items-center py-2 px-[15px] gap-2 border ${
							isSelected ? " border-blue-500 text-blue-500" : "border-[#DADADA] text-[#0e1111]"
						} cursor-pointer`}
						onClick={() => handleChange(item.value)}>
						{/* 숨겨진 라디오 버튼 */}
						<input
							className="hidden" // Tailwind를 사용하여 라디오 버튼 숨기기
							type="radio"
							name="radio"
							id={item.value}
							value={item.value}
							checked={isSelected}
							onChange={() => handleChange(item.value)}
						/>
						<label className="text-[14px] font-medium tracking-[-0.32px] font-spoqa cursor-pointer" htmlFor={item.value}>
							{item.label}
						</label>
					</div>
				);
			})}
		</div>
	);
}
